import { LOCALES } from 'src/i18n/locales';
import { TLocaleId } from 'src/i18n';

const italianLangTranslations: { [key: string]: { [idKey in TLocaleId]: string } } = {
  [LOCALES['Italiano (Italian)']]: {
    'MursionPortal.SimAttendance.Tooltip.Error': 'Session was unable to be completed due to technical difficulties on the Learner’s side. (if the session was able to be completed after an error this session should be marked complete and not error).',// TODO add translations
    'SessionReports.ErrorOnLoadingVideo': 'Per visualizzare la registrazione, utilizzare un computer portatile o desktop.',
    'MursionPortal.ConfirmationModal.Project.InvitationsConfirmationText': 'Siete sicuri di voler invitare <strong>tutti gli studenti assegnati</strong> che non hanno ancora completato gli scenari del progetto? Durante la notte, riceveranno un\'e-mail di invito per la programmazione.',
    'MursionPortal.ConfirmationModal.Scenario.InvitationsConfirmationText': 'Siete sicuri di voler invitare <strong>tutti gli studenti assegnati</strong> che non hanno ancora completato questo scenario? Durante la notte, riceveranno un\'e-mail di invito per la programmazione.',
    'Clients.Modal.Label.VideoRecordingDataRetentionDays.Tooltip': 'Numero di giorni in cui il cliente desidera conservare i dati della registrazione video. Dopo un certo numero di giorni, le registrazioni video verranno eliminate.',
    'Settings.SSO.CopyScenarioLink': 'Copiare il link di invito allo scenario',
    'MursionPortal.Label.VideoRecordingSharing': 'Condivisione o download dei video delle sessioni da parte degli utenti',
    'MursionPortal.Status.Capitalized.LateCancelled': 'ANNULLATO_IN RITARDO',
    'MursionPortal.Status.Capitalized.EarlyCancelled': 'ANNULLATO_IN ANTICIPO',
    'MursionPortal.Status.Abbreviation.LateCancelled': 'LC',
    'MursionPortal.Status.Abbreviation.Cancelled': 'C',
    'MursionPortal.Text.Congratulation': 'Congratulazioni!',
    'MursionPortal.Text.PasswordChanged': 'La password è stata modificata',
    'MursionPortal.Text.PasswordShouldBeSame': 'Le nuove password devono corrispondere.',
    'Filters.LateRescheduled': 'Riprogrammata in ritardo',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Scheduled': 'Prossime sessioni programmate che non sono ancora state completate (incluse quelle in corso)',
    'MursionPortal.Checkbox.EnableSmartMetrics': 'Abilita l\'analisi vocale dello studente della sessione',
    'Dashboard.SimulationTable.Column.TimesLateRescheduled': 'Volte riprogrammata in ritardo',
    'Dashboard.SimulationTable.ColumnTooltip.TimesLateRescheduled': 'Volte in cui questa simulazione è stata riprogrammata in ritardo',
    'SessionReports.Label.CopyVideoLink': 'Copia link video',
    'Login.LoginAgain': 'Effettua nuovamente l\'accesso',
    'Dashboard.VideosTable.Column.SessionStart': 'Inizio sessione',
    'Filters.Date.LastSevenDays': 'La scorsa settimana',
    'Filters.Date.NextSevenDays': 'La prossima settimana',
    'Filters.Date.ThisYear': 'Quest\'anno',
    'Mursion.Portal.Status.LearnerMissed': 'Persa Studente',
    'Mursion.Portal.Status.SystemCancelled': 'Annullata',
    'Mursion.Portal.Status.LateCancelled': 'Annullata in ritardo',
    'Mursion.Portal.Status.EarlierCancelled': 'Annullata in anticipo',
    // 'MursionPortal.Dashboard.Chart.label.LearnerStatus.LearnerCompleted': 'Completata',
    'MursionPortal.Dashboard.UpcomingSessions': 'Prossime sessioni',
    'MursionPortal.Label.Opened': 'Aperta',
    'MursionPortal.Label.ActedOn': 'Attivata',
    'MursionPortal.Label.Unread': 'Non letta',
    'MursionPortal.Label.Timeline': 'Sequenza temporale del progetto',
    'Projects.ProjectInfo.Label.TimelineUtc': 'Sequenza temporale del progetto (UTC)',
    'MursionPortal.ScenarioInfo.Header.Description': 'Descrizione',
    'MursionPortal.TimelineUTC': 'Sequenza temporale del progetto<code>{word}</code> (UTC)',
    'MursionPortal.Label.ChangePassword': 'Modifica della password',
    'MursionPortal.Label.ChangeYourPassword': 'Modificare la password',
    'MursionPortal.Label.NewChangePassword': 'Digitare la nuova password',
    'MursionPortal.Label.OldPassword': 'Digitare la password attuale',
    'MursionPortal.Label.RepeatChangePassword': 'Riscrivere la nuova password',
    'MursionPortal.Prompt.SessionScheduled': 'La <code>sessione</code> è stata annullata!',
    'MursionPortal.Status.Booked': 'Prenotata',
    'MursionPortal.Status.Pending': 'In attesa',
    'MursionPortal.Completed': 'Completata',
    'MursionPortal.Status.Missed': 'Persa',
    'MursionPortal.Status.Cancelled': 'Annullata',
    'MursionPortal.Status.LateCancelled': 'Annullata in ritardo',
    'MursionPortal.Status.EarlyCancelled': 'Annullata in anticipo',
    'MursionPortal.Status.LicenseCancelled': 'Annullata',
    'MursionPortal.Status.Error': 'Errore',
    'MursionPortal.ConversationalFlow.label.zoom.all': 'Tutti',
    'Clients.ImportUsers.DownloadErrorsButton': 'Scarica gli errori',
    'Mursion.Portal.Edit.Team.DownloadErrorButton': 'Scarica gli errori',
    'MursionPortal.Dashboard.NextOnProject': 'Scenari da programmare',
    'MursionPortal.Dashboard.NextOnProject.Footer.EndDate': 'Avete tempo fino al {endDate} per completare questo scenario.',
    'MursionPortal.Dashboard.NextOnProject.Footer.CompletionRate': 'Avete tempo fino al {endDate} per completare fino a {completionRate} volte.',
    'MursionPortal.Dashboard.NothingPlanned.text1': 'Non c\'è nulla da recuperare! Quando avrete',
    'MursionPortal.Dashboard.NothingPlanned.text2': 'qualcosa da programmare, verrà visualizzato qui.',
    'MursionPortal.Learner.Missed': 'PERSA STUDENTE',
    'MursionPortal.AriaImage.Attribute.Next': 'Prossimo:',
    'MursionPortal.AriaImage.Attribute.Close': 'Chiudi:',
    'MursionPortal.AriaImage.Attribute.Back': 'Indietro:',
    'MursionPortal.AriaImage.Attribute.Delete': 'Annulla:',
    'MursionPortal.AriaImage.Attribute.Remove': 'Rimovi',
    'MursionPortal.AriaImage.Attribute.Document': 'Documento',
    'MursionPortal.AriaImage.Attribute.Video': 'Video',
    'MursionPortal.AriaImage.Attribute.DeleteDocument': 'Annullare il documento',
    'MursionPortal.ScenarioInfo.CompletionRate': '{completionRate} volte',
    'MursionPortal.Button.RefreshPage': 'Aggiorna la pagina',
    'MursionPortal.Prompt.CalendarUpdatedOutdatedNotification': 'Se siete sicuri di voler chiudere, la pagina del calendario non sarà aggiornata.',
    'Reports.SessionListHeader.Label.AverageTime': 'Tempo medio <code>in ogni sessione</code>',
    'MursionPortal.Users.Archive.HasSessions': 'Non è possibile archiviare il Simulation Specialist poiché sono previste una o più sessioni per questo Simulation Specialist. Si prega di scambiare o cambiare Sim Specialist.',
    'MursionPortal.Label.InactiveSIMCertification.Tooltip': 'Da usare se c\'è un Sim che è certificato per questa simulazione, ma che non è disponibile per nuove simulazioni.',
    'MursionPortal.Label.InactiveSIMCertification': 'Certificazione Sim inattiva',
    'Dashboard.Filters.FilterBySessionDate': 'FILTRARE PER DATE DELLE SESSIONI',
    'Dashboard.LeftPane.CategoryHeader.Emails': 'E-MAIL',
    'Projects.ProjectInfo.Label.CancellationDeadline.Tooltip': 'Questo è il numero di giorni/ore/minuti in cui uno studente può cancellarsi, una volta che si è registrato per una simulazione all\'interno del progetto. Se gli studenti si cancellano entro la scadenza, la sessione sarà considerata completa ai fini della fatturazione.',
    'Projects.ProjectInfo.Checkbox.AllowSessionRecording': 'Consenti registrazione della sessione',
    'MursionPortal.Label.SendRecordedVideosTo': 'Invia i video registrati a:',
    'MursionPortal.Checkbox.AllowLearnerToRefuseRecording': 'Consenti allo studente di partecipare alla sessione se non acconsente alla registrazione',
    'Projects.ProjectInfo.SchedulingInterval': 'Intervallo di programmazione',
    'Projects.ProjectInfo.SchedulingInterval.Tooltip': 'Consente di programmare con una cadenza diversa dalla durata della sessione.',
    'Projects.ProjectInfo.SchedulingIntervalLength': 'Lunghezza dell\'intervallo di programmazione',
    'Projects.ProjectInfo.SchedulingIntervalLength.tooltip': 'Determina la frequenza con cui i blocchi di pianificazione vengono visualizzati da uno studente. Se impostato su 15, gli studenti vedranno blocchi di programmazione a intervalli di 15 minuti. Non imposta la durata della sessione, ma solo la frequenza delle sessioni.',
    'Projects.ProjectInfo.DeliveryMode.tooltip': 'La piattaforma utilizzata dagli studenti. Impostare su ML3 se si desidera attivare il software di connessione diretta Mursion Magic e/o ML3. ML3z = Zoom. Mursion Meet = Google Meet.',
    'MursionPortal.Label.WebOnly': 'Solo Web',
    'MursionPortal.Label.DesktopOnly': 'Solo desktop',
    'MursionPortal.Label.DesktopAndWeb': 'Desktop e Web',
    'MursionPortal.EmailCategory.CallsToAction': 'Inviti all\'azione',
    'MursionPortal.Dashboard.EmailAnalytics.Label.Sent': 'Inviate',
    'MursionPortal.Dashboard.EmailAnalytics.Label.AllEmails': 'Tutte le e-mail',
    'MursionPortal.Dashboard.EmailAnalytics.Label.Trends': 'Tendenze',
    'MursionPortal.Dashboard.EmailAnalytics.Label.CategorySummary': 'Riassunti delle categorie',
    'MursionPortal.Dashboard.EmailAnalytics.Label.RegistrationEmail': 'E-mail di registrazione',
    'MursionPortal.Dashboard.EmailAnalytics.Label.CallToActionEmail': 'E-mail con invito all\'azione',
    'MursionPortal.Dashboard.EmailAnalytics.Label.SchedulingEmail': 'Programmazione delle e-mail',
    'MursionPortal.Dashboard.EmailAnalytics.Label.ReSchedulingEmail': 'Riprogrammazione delle e-mail',
    'MursionPortal.Dashboard.EmailAnalytics.Label.VideoEmail': 'E-mail video',
    'MursionPortal.Dashboard.EmailAnalytics.Label.EmailSent': 'E-mail inviate',
    'MursionPortal.Dashboard.EmailAnalytics.Info.EmailAnalytics': 'L\'analisi delle e-mail fornisce una panoramica di come gli studenti interagiscono con le <link>comunicazioni e-mail di Mursion</link>. Le e-mail possono rientrare in una delle tre categorie seguenti',
    'MursionPortal.Dashboard.EmailAnalytics.Info.EmailOpened': 'Uno studente ha aperto una o più e-mail inviategli.',
    'MursionPortal.Dashboard.EmailAnalytics.Info.EmailActedOn': 'Uno studente ha aperto l\'e-mail e ha cliccato su uno o più link, come i link <strong>Programma</strong> o <strong>Partecipa alla simulazione</strong>.',
    'MursionPortal.Dashboard.EmailAnalytics.Info.Unread': 'Uno studente non ha aperto l\'e-mail. Se c\'è un gran numero di e-mail non lette, assicuratevi che i vostri studenti le stiano ricevendo. Verificare con il team IT che <color>no-reply@mursion.com</color> non sia bloccato dai filtri antispam dell\'organizzazione.',
    'MursionPortal.Dashboard.Tooltip.RegisterEmail': 'E-mail a un Utente che lo invita a creare un Account Mursion',
    'MursionPortal.Dashboard.Tooltip.CallToActionEmail': 'E-mail di promemoria inviate dall\'Account Owner dalla pagina del Progetto o dello Scenario.',
    'MursionPortal.Dashboard.Tooltip.SchedulingEmail': 'E-mail Specifiche per le sessioni. E-mail di Invito a programmare e di Partecipazione alle sessioni',
    'MursionPortal.Dashboard.Tooltip.ReschedulingEmail': 'E-mail che invitano uno studente a Riprogrammare dopo una simulazione persa o annullata',
    'MursionPortal.Dashboard.Tooltip.VideoEmail': 'E-mail che avvisano uno studente che è disponibile un video della sua simulazione',
    'MursionPortal.Dashboard.EmailCategoryFilter': 'Categoria e-mail',
    'MursionPortal.Dashboard.EmailCategoryFilter.Search': 'Ricerca categorie e-mail...',
    'MursionPortal.Dashboard.EmailTypeFilter.SessionRescheduled': 'Sessione riprogrammata',
    'MursionPortal.Dashboard.EmailTypeFilter.Search': 'Ricerca tipi di e-mail...',
    'MursionPortal.Text.NoImageAvailable': 'Nessuna immagine disponibile',
    'Mursion.Portal.Edit.Team.Instruction.ExportFile': 'Fare clic su <strong>Scaricare le adesioni attuali</strong> per aggiornare le assegnazioni dei team per gli studenti (<strong>edit_learner_team_memberships.xlsx</strong>).',
    'Mursion.Portal.Edit.Team.DownloadExcelTemplate': 'Scarica le adesioni attuali',
    'MursionPortal.Project.SameDay': 'In giornata',
    'MursionPortal.Project.CancelationDeadline.Validation': 'Il termine di annullamento deve essere superiore a 0.',
    'ClientUserTable.AddNewUsers.RolesHeading': 'È possibile aggiungere un solo tipo di utente alla volta. Selezionare il tipo di utente che si desidera aggiungere.',
    'ClientUserTable.AddNewUsers.Roles.AccountOwner.Tooltip': 'L\'Account Owner è l\'admin principale dell\'intera organizzazione.',
    'ClientUserTable.AddNewUsers.Roles.Facilitator.Tooltip': 'Un Facilitator è un admin a livello di team che gestisce uno o più team specifici.',
    'ClientUserTable.AddNewUsers.Roles.Learner.Tooltip': 'Uno Studente è chiunque partecipi a una simulazione Mursion. Questa sarà la maggior parte della vostra base di utenti.',
    'ClientUserTable.AddNewUsers.Heading': 'Aggiungi nuovi utenti',
    'ClientUserTable.AddNewUsers.Roles.Heading': 'Assegnare un ruolo agli utenti',
    'ClientUserTable.AddNewUsers.ImportUsers': 'Completare i passaggi seguenti per un massimo di 500 utenti. Per importare più di 500 utenti, utilizzare la funzione "<code>Importa utenti</code>".',
    'ClientUserTable.AddNewAttributes.label': 'Aggiungere gli attributi dell\'utente',
    'ClientUserTable.AddNewAttributes.Header.Text': 'Si noti che questi attributi saranno applicati a tutti gli utenti di questo elenco.',
    'ClientUserTable.UserAuthentication.label': 'Autenticazione utente',
    'ClientUserTable.NewUsersAdded.Heading': 'Nuovi utenti aggiunti!',
    'ClientUserTable.AddNewUsers.AssignTeams.Heading': 'Assegnare i team agli utenti',
    'ClientUserTable.AddNewUsers.AssignTeams.CreateTeam': 'Gli Studenti e i Facilitator devono essere assegnati a un team. Se non avete ancora creato un team, <code>fate clic qui.</code>',
    'ClientUserTable.AddNewUsers.AssignTeams.SelectTeams': 'Selezionare il/i team',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Validation': 'Si prega di aggiungere un numero di utenti pari o inferiore a 500. Per importare più di 500 utenti è possibile utilizzare la funzione "Importa utenti".',
    'ClientUserTable.AddNewUsers.AssignTeams.Validation': 'È necessario selezionare almeno un team.',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Heading': 'Aggiungere gli indirizzi e-mail degli utenti',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.SubHeading': 'È possibile aggiungere un\'e-mail alla volta o incollare un elenco. Le e-mail devono essere separate da uno spazio, una virgola o un punto e virgola.',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Placeholder': 'Digitare o incollare qui gli indirizzi e-mail.',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.MaximumUsersAllowed': '{maximumUsersAllowed} utenti alla volta',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Total': 'Totale',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Duplicate': 'Duplicato',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.AlreadyExists': 'Esiste già',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Invalid': 'Non valido',
    'ClientUserTable.AddNewUsers.AddNewAttributes.SignLanguageInterpreter': 'Interprete della lingua dei segni',
    'ClientUserTable.AssignTeams.TotalUsers': 'Si sta per aggiungere <code>{users} nuovo {role}</code>',
    'ClientUserTable.AssignTeams.AddAndUpdateUsers': 'Si sta per aggiungere <code>{users} nuovo {role}</code> e aggiornare <code>{existingUsers} esistente {role}</code>',
    'ClientUserTable.AssignTeams.UpdateUsers': 'Si sta per aggiornare il <code>{existingUsers} esistente {role}</code>',
    'ClientUserTable.AssignTeams.NumberOfTeams': 'Verrà assegnato a <code>{numberOfTeams} team</code>',
    'ClientUserTable.Confirmation.Heading': 'Confermare prima di aggiungere',
    'MursionPortal.InviteToSchedule.Confirmation.Text': 'Per saperne di più: <code>Invito a programmare</code>',
    'MursionPortal.AccountOwnerGuides.Confirmation.Text': 'Guide per l\'Account Owner',
    'MursionPortal.FacilitatorGuides.Confirmation.Text': 'Guide per i Facilitator',
    'MursionPortal.Users.Confirmation.Text': 'I vostri utenti sono stati aggiunti al portale Mursion.',
    'MursionPortal.Confirmation.Header.Text.BackToUsers': 'Torna a Utenti',
    'MursionPortal.Users.Next.Steps.Text': 'Prossimi passaggi',
    'MursionPortal.Users.StepOne.Text': 'Passaggio 1:',
    'MursionPortal.Users.StepTwo.Text': 'Passaggio 2:',
    'MursionPortal.Users.StepThree.Text': 'Passaggio 3:',
    'MursionPortal.Users.ThatIt.Text': 'Questo è tutto!',
    'MursionPortal.Users.InviteToScheduleLearner.Text': 'Invitare gli Studenti a Programmare',
    'MursionPortal.Users.LearnersLink.Text': 'Potete inviare questo link ai vostri studenti',
    'MursionPortal.Users.InviteToSchedule.ProjectorScenario.Text': 'Utilizzare il pulsante <strong>Invita a programmare</strong> su qualsiasi progetto o scenario.',
    'MursionPortal.Users.CopyInviteLink.Text': 'Copiare il link dell\'invito',
    'MursionPortal.Users.ShareContent.Text': 'Condividere i contenuti di supporto con gli Admin',
    'MursionPortal.Users.AOAndFacilitators.Responsibilities.Text': 'Gli Account Owner e i Facilitator hanno responsabilità diverse e potrebbero aver bisogno di saperne di più sul portale. Condividete questi link con i vostri Account Owner e Facilitator, in modo che possano trovare le risposte alle domande che potrebbero avere nel loro percorso Mursion.',
    'MursionPortal.Users.Administrators.Text': 'Gli Admin avranno ora accesso al portale Mursion.',
    'MursionPortal.Users.Progress.Text': 'Gli studenti inizieranno a programmare le loro simulazioni. Potete seguire i loro progressi nella vostra Dashboard.',
    'MursionPortal.Users.Access.Text': 'Assicurarsi che il reparto IT abbia concesso a questi utenti l\'accesso all\'App Mursion.',
    'MursionPortal.Users.Registrationemail.Text': 'Gli utenti dovrebbero aver appena ricevuto l\'e-mail di registrazione da no-reply@mursion.com.',
    'MursionPortal.AddMoreUsers.Btn.Text': 'Aggiungi altri utenti',
    'MursionPortal.Users.Ok.Btn.Text': 'OK, capito!',
    'Users.Button.AddNewUsers': 'Aggiungi nuovi utenti',
    'MursionPortal.Project.CancelationDeadline.UpperLimit.Validation': 'Il termine di annullamento non può essere superiore a 365 giorni.',
    'MursionPortal.Dashboard.EmailTypeFilter.PasswordReset': 'Ripristino della password',
    'MursionPortal.Dashboard.EmailTypeFilter.Verification': 'Verifica',
    'MursionPortal.Dashboard.EmailTypeFilter.InviteToSchedule': 'Invito a programmare',
    'MursionPortal.Dashboard.EmailTypeFilter.Summary': 'Sintesi',
    'MursionPortal.Dashboard.EmailTypeFilter.RescheuleMissed': 'Riprogramma Persa',
    'MursionPortal.Dashboard.EmailTypeFilter.RescheuleCancelled': 'Riprogramma Annullata',
    'MursionPortal.Dashboard.EmailTypeFilter.PSRescheule': 'Riprogrammazione PS',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingCorrupted': 'Registrazioni video danneggiate',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingNotUploaded': 'Registrazioni video non caricate',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingAvailable': 'Registrazione video disponibile',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingShared': 'Registrazione video condivisa',
    'MursionPortal.Dashboard.EmailTypeFilter.Booking': 'Prenotazione',
    'MursionPortal.Dashboard.EmailTypeFilter.CodeOfConuct': 'Codice di condotta',
    'MursionPortal.Dashboard.EmailTypeFilter.UserRemoved': 'Utente rimosso',
    'MursionPortal.Dashboard.EmailTypeFilter.AttendanceReminer': 'Promemoria sulle presenze',
    'Users.Button.AddNewLearners': 'Aggiungi nuovi studenti',
    'MursionPortal.Scenario.Label.LastDelivered': 'Ultima consegna',
    'MursionPortal.Scenario.Label.TotalDeliveries': 'Totale consegne',
    'MursionPortal.Scenario.Text.NotYetDelivered': 'Non ancora consegnato',
    'ClientUserTable.AddNewUsers.UpdateUserWarning': 'Questi utenti {existingUsers} esistono già. Procedendo, questi utenti verranno aggiunti ai team selezionati (oltre a quelli a cui sono eventualmente già assegnati).',
    'MursionPortal.AddNewUsers.Facilitators': 'Facilitator',
    'ClientUserTable.AddNewUsers.Learners': 'Studente/i',
    'MursionPortal.Scheduling.TimeSlotsUnavailableMessage': 'Le fasce orarie selezionate non sono più disponibili. Aggiornare per visualizzare le fasce orarie programmabili.',
    'MursionPortal.Client.EmailTypeFilter.SessionFirstReminder': 'Primo promemoria della sessione',
    'MursionPortal.Client.EmailTypeFilter.SessionSecondReminder': 'Secondo promemoria della sessione',
    'Projects.ProjectInfo.Label.ShowSIMAvailaiblity': 'Mostra disponibilità SIM',
    'Projects.ProjectInfo.Label.ShowAvailaiblity.Tooltip': 'Quando uno studente programma, vedrà la disponibilità del SIM Specialist per l\'intervallo selezionato.',
    'Projects.InputValidation.GreaterThanZero': 'Deve essere maggiore di 0.',
    'MursionPortal.Weeks': 'Settimana/e',
    'MursionPortal.Scenario.CompletionRateFulfilled': 'You cannot schedule this simulation as you have completed the maximum number of simulations for this scenario or engagement as set by your organization.',// TODO add translations
    'MursionPortal.Dashboard.EmailTypeFilter.TimeSlotRequest': 'Richiesta di fascia oraria',
    'MursionPortal.Dashboard.EmailAnalytics.OpenAndActed.Label': 'I dati relativi alle attività Aperte e Attivate sono accurati al 24 novembre 2022.',
    'MursionPortal.Dashboard.EmailAnalytics.Prior.Label': 'Prima di questa data tutte le e-mail sono classificate come Non lette.',
    'MursionPortal.Learners.Filters.LearnerCompletion': 'Completamento dello studente',
    'MursionPortal.Learners.Filters.LearnerCompletion.NotYetCompleted': 'Non ancora completato',
    'MursionPortal.Learners.Filters.LearnerCompletion.CompletedOneSession': '1 sessione completata',
    'MursionPortal.Learners.Filters.LearnerCompletion.CompletedTwoPlusSessions': '2+ sessioni completate',
    'MursionPortal.Learners.Filters.MissedLateActivity': 'Attività Persa/In ritardo',
    'MursionPortal.Learners.Filters.MissedLateActivity.1Session': '1 sessione',
    'MursionPortal.Learners.Filters.MissedLateActivity.2Sessions': '2 sessioni',
    'MursionPortal.Learners.Filters.MissedLateActivity.3Sessions': '3 sessioni',
    'MursionPortal.Learners.Filters.MissedLateActivity.4PlusSessions': '4+ sessioni',
    'MursionPortal.BackToCompletedSessions': 'Torna a Sessioni completate',
    'MursionPortal.Scenario.InviteToSchedule.Modal.Text': 'Si desidera inviare gli inviti a <strong>tutti</strong> gli studenti assegnati che non hanno ancora completato questo scenario o si vuole <strong>selezionare gli studenti</strong> da invitare?',
    'MursionPortal.BackToSessionSummaries': 'Torna a Riepiloghi delle sessioni',
    'MursionPortal.BackToLearnerSessions': 'Torna a Sessioni dello studente',
    'MursionPortal.LearnerSessionReport.SessionObjective': 'Obiettivo della sessione',
    'MursionPortal.LearnerSessionReport.SessionStrategy': 'Quanto bene avete applicato le strategie?',
    'MursionPortal.LearnerSessionReport.SessionStrategyIcon': 'Strategia della sessione Obiettivo bersaglio',
    'MursionPortal.LearnerSessionReport.SessionPerspective.Title': 'Continuate così!',
    'MursionPortal.LearnerSessionReport.SessionDetail': 'Descrizione dello scenario',
    'MursionPortal.LearnerSessionReport.SessionDate': 'Data della sessione',
    'ClientUserTable.AddNewUsers.Procced.Button.Tooltip': 'Prima di procedere, rimuovere eventuali e-mail duplicate o non valide.',
    'MursionPortal.BackToSessionDetail': 'Torna a Dettaglio della sessione',
    'MursionPortal.SimAttendance.Instructions.Label': 'Mursion Simulation Specialists:',// TODO add translations
    'MursionPortal.SimAttendance.Instructions.LabelText': 'Select an attendance status for each attendee (mouse over the status to learn more). Your status is gathered automatically – these statuses are for the learner ONLY.',// TODO add translations
    'MursionPortal.SimAttendance.Instructions.Label1': 'Software License Simulation Specialists:',// TODO add translations
    'MursionPortal.SimAttendance.Instructions.LabelText1': 'Select an attendance status for each attendee (mouse over the status to learn more). If you experienced an error in the session, please also provide the error details. To do this, select the type of error under the “SIM Reported Error” section and add context in the Additional Details text box. Additional details about attendance--even if it’s not an Error--are encouraged, but are optional. If you have any further questions about attendance, please reach out to your Simulation Program Admin.',// TODO add translations
    'MursionPortal.Dashboard.EmailAnalytics.Label.EmailAnalytics': 'Analisi delle e-mail',
    'MursionPortal.Session.LearnerSession.Report': 'Resoconto',
    'MursionPortal.Session.LearnerSession.Recording': 'Registrazione',
    'MursionPortal.Session.LearnerSession.SessionDetails': 'Dettagli della sessione',
    'MursionPortal.Session.LearnerSessionReport': 'Resoconto sulla sessione dello studente',
    'MursionPortal.Scenario.InviteToScedule.Modal.SelectLearners': 'Selezionare gli studenti',
    'MursionPortal.Scenario.Button.Label': 'Copiare le e-mail degli studenti',
    'MursionPortal.VideoStatusFilter.Label': 'Stato del video',
    'MursionPortal.TooltipText.VideoNotUploaded': 'Se il video non viene caricato dopo 48 ore, contattare l\'assistenza.',
    'MursionPortal.VideoStatusFilter.SearchText': 'Ricerca stato video...',
    'MursionPortal.VideoStatusFilter.Uploaded': 'Caricato',
    'MursionPortal.VideoStatusFilter.NotUploaded': 'Non caricato',
    'MursionPortal.Scenario.Invite.Modal.Button.Label': 'Invito',
    'MursionPortal.Scenario.CopyEmails.Modal.Button.Label': 'Copiare le e-mail',
    'MursionPortal.Scenario.AllLearners.InvitationsSentOvernight': 'Gli inviti saranno inviati durante la notte se lo studente non ha già programmato o ricevuto un invito negli ultimi 2 giorni.',
    'MursionPortal.Scenario.SelectedLearners.InvitationsSentOvernight': '<code>Gli studenti selezionati</code> riceveranno un\'e-mail durante la notte che li invita a programmare, se non hanno già programmato o ricevuto un invito negli ultimi 2 giorni.',
    'MursionPortal.Scenario.SelectedLearners.Confirm.Text': 'Sicuri di voler invitare <code>tutti gli studenti selezionati</code>?',
    'ClientUserTable.Column.LastInvited': 'Ultimo invito',
    'ClientUserTable.ColumnTooltip.LastInvited': 'La data in cui l\'ultimo invito via e-mail a programmare è stato inviato. Può essere domani se l\'invito è appena stato effettuato.',
    'MursionPortal.Scenario.SelectedLearners.EmailsCopied': 'Gli indirizzi e-mail degli studenti selezionati sono stati copiati negli appunti',
    'MursionPortal.ScenarioInfo.MarkAllInactive': 'Segna tutti inattivi',
    'MursionPortal.ScenarioInfo.DecertifyAll': 'Decertifica tutti',
    'MursionPortal.ScenarioInfo.MarkAllInactive.Confirmation.Text': 'Sicuri di voler inattivare tutti i SIM Specialist?',
    'MursionPortal.ScenarioInfo.DecertifyAll.Confirmation.Text': 'Sicuri di voler decertificare tutti i SIM Specialist?',
    'Dashboard.Button.ViewSession': 'Visualizza sessione',
    'MursionPortal.SessionReport.NotAvailable': 'Non disponibile',
    'Projects.ProjectInfo.Checkbox.EnableReportTabSurvey.OneToOne': 'Abilitare la Scheda resoconto per le consegne 1:1',
    'MursionPortal.Learner.Session.Report': 'Resoconto sulla sessione dello studente',
    'MursionPortal.Scenario.Sim.InactivedAll': '{effectedSims} su {totalSims} SIM inattivati.',
    'MursionPortal.Scenario.Sim.DecertifiedAll': '{effectedSims} su {totalSims} SIM decertificati.',
    'MursionPortal.Scenario.Sim.DecertifiedFew': '{remainingSims} SIM non possono essere decertificati perché hanno sessioni programmate. Contrassegnarli come inattivi per onorare le loro sessioni e prevenire future assegnazioni di sessioni.',
    'MursionPortal.AriaLabel.ViewMore': 'Visualizza di più',
    'MursionPortal.SimAttendance.SimulationAttendance': 'Partecipazione degli studenti alla simulazione',
    'Mursion.Portal.Status.LearnerTechnicalError': 'Learner Technical Error',// TODO add translations
    'Mursion.Portal.Status.TooLateToComplete': 'Troppo in ritardo per completare',
    'Mursion.Portal.Status.LearnerElectedToLeave': 'Learner Elected to Leave',// TODO add translations
    'Mursion.Portal.Status.Unprepared.Tooltip': 'Lo studente partecipa ma non ha completato la simulazione perché impreparato o per altri motivi.',
    'MursionPortal.Label.SchedulingRate': 'Tasso di programmazione',
    'Mursion.Portal.Status.No.Show': 'Non si presenta',
    'MursionPortal.ScenarioInfo.SchedulingRate.Text': '{code} Volte',
    'MursionPortal.Scenario.Modal.Search.Placeholder': 'Ricerca indirizzo e-mail, nome, cognome',
    'MursionPortal.Scenario.SchedulingRateFulfilled': 'È necessario completare prima la sessione programmata.',
    'MursionPortal.Scenario.InviteToScedule.Modal.Button.Tooltip': 'Selezionare lo studente da invitare',
    'MursionPortal.Learner.Session.Report.Report.SimulationRecording': 'Riflettere sulla conversazione riproducendo la registrazione della simulazione',
    'Dashboard.SimulationTable.ColumnTooltip.SoftwareVersion': 'Indica il software di sessione utilizzato',
    'Dashboard.SimulationTable.Column.SoftwareVersion': 'Software',
    'MursionPortal.LearnerSessionReport.YourPerspective': 'Il vostro punto di vista',
    'MursionPortal.LearnerSessionReport.YourPerspectiveDescription': 'Il livello di sicurezza che si aveva all\'inizio della simulazione era <strong>{preConfidenceLevel}</strong> e dopo la simulazione era <strong>{postConfidenceLevel}.</strong>',
    'MursionPortal.LearnerSessionReport.YourPerspective.VeryLow': 'molto basso',
    'MursionPortal.LearnerSessionReport.YourPerspective.Low': 'basso',
    'MursionPortal.LearnerSessionReport.YourPerspective.Moderate': 'moderato',
    'MursionPortal.LearnerSessionReport.YourPerspective.High': 'alto',
    'MursionPortal.LearnerSessionReport.YourPerspective.VeryHigh': 'molto alto',
    'MursionPortal.LearnerSessionReport.Strategies.SuccessfullyStrategy': 'Avete dimostrato con <strong>successo</strong> questa strategia',
    'MursionPortal.LearnerSessionReport.Strategies.BenefitStrategy': '<strong>Trarrai vantaggio da un po\'più di pratica</strong> nell\'implementazione di questa strategia',
    'MursionPortal.SchedulingRate.ValidationMessage': 'Il Tasso di programmazione deve essere inferiore o uguale al Tasso di completamento.',
    'MursionPortal.LearnerSessionReport.Footer.FooterTitle': 'Prossimi passi: la pratica rende perfetti!',
    'MursionPortal.LearnerSessionReport.Footer.FooterSubTitle': 'La ripetizione è la chiave per rendere queste abilità una seconda natura.',
    'MursionPortal.LearnerSessionReport.SessionObjective.Body': 'Il resoconto sarà pronto a breve',
    'MursionPortal.Dashboard.NextOnProject.Footer.schedulingRateFulfilled.Text': 'You cannot schedule this simulation at this time as you have currently reached the scheduling limit for this scenario or engagement set by your organization.',// TODO add translations
    'MursionPortal.Status.Abbreviation.LicenseCancelled': 'C',
    'MursionPortal.Scenario.CharacterLimitExceeded.Message': 'È stato superato il limite di caratteri di {exceededCharLength}. Si prega di rivedere.',
    'MursionPortal.Scenario.AddedCharacter.Message': '{added} di {totalChar}',
    'MursionPortal.Scenario.CharacterLimit.Message': 'Limite di caratteri: {maxLength}',
    'MursionPortal.Scenario.Description.block.default.text': 'Aggiungere qui la descrizione',
    'MursionPortal.Scenario.AttachmentField.text': 'È possibile aggiungere altri file',
    'MursionPortal.sessionTable.Column.OriginalSim': 'SIM originale',
    'MursionPortal.sessionTable.Column.NewSim': 'Nuovo SIM',
    'MursionPortal.Users.EditUser.InactivatedAll.Message': '{code} Certificati sono stati inattivati.',
    'MursionPortal.Users.EditUser.InactivateAll.Confirmation': 'Sicuri di voler inattivare tutti i certificati per questo SIM Specialist?',
    'MursionPortal.Users.Modal.ActiveCerts.EmptyMessage': 'Nessun certificato attivo',
    'MursionPortal.Users.Modal.InactiveCerts.EmptyMessage': 'Nessun certificato inattivo',
    'MursionPortal.Users.Modal.ActiveCerts': 'Certificati attivi',
    'MursionPortal.Users.Modal.InactiveCerts': 'Certificati inattivi',
    'MursionPortal.Users.ActiveCerts.MarkAllInactive.Button': 'Segna tutti inattivi',
    'MursionPortal.SurveyFilter.Incomplete': 'Incompleti',
    'MursionPortal.SurveyFilter.SearchText': 'Ricerca Sondaggio...',
    'MursionPortal.Dashboard.SurveyReminder.Text': 'Hai {conteggio sondaggi} da completare',
    'MursionPortal.Dashboard.RemindMeLater': 'Ricorda più tardi',
    'MursionPortal.Dashboard.SurveysIncomplete': 'Sondaggi incompleti',
    'MursionPortal.AriaLabel.NormalFont': 'Carattere normale',
    'MursionPortal.AriaLabel.MediumFont': 'Carattere medio',
    'MursionPortal.AriaLabel.LargeFont': 'Carattere grande',
    'MursionPortal.AriaLabel.Font': 'Carattere',
    'Dashboard.LeftPane.CategoryHeader.RescheduledAndCancelled': 'Riprogrammati e annullati',
    'MursionPortal.Dashboard.Summary.Certifications': 'Certificazioni',
    'MursionPortal.SessionsTable.ActionTitle.Pickup': 'raccolta',
    'MursionPortal.Label.MissCancellationRate': 'Tasso di persa/annullata',
    'MursionPortal.Label.NotAllowed': 'Non consentito',
    'Dashboard.LeftPane.CategoryHeader.Simulations': 'SESSIONI',
    'Dashboard.LeftPane.Simulations.SimulationSummaries': 'Riepiloghi delle sessioni',
    'Dashboard.LeftPane.Simulations.SimulationDetail': 'Dettaglio sessione',
    'MursionPortal.Message.NotEnoughDataToDisplay': 'Non ci sono dati sufficienti di questa sessione.',
    'MursionPortal.Message.Notdata.SessionMatrix': 'Non ci sono dati sufficienti di questa sessione per fornirne l\'analisi.',
    'MursionPortal.Tooltip.SchedulingWindow': 'Gli utenti finali possono impostare questo valore in giorni, ore, minuti, dopodiché solo l\'utente sarà in grado di programmare una sessione.',
    'MursionPortal.DemandBasedScheduling.TimeBlockUnavailableSelectDate': 'Al momento non abbiamo alcuna fascia oraria disponibile. Riprovare più tardi. <code1></code1>Contattare l\'<code>assistenza</code> se si ha bisogno di aiuto immediato.',
    'Session.Edit.Modal.JoinSession': 'Avvia sessione',
    'MursionPortal.SimAttendance.Tooltip.Completed': 'Lo studente ha completato la parte di simulazione della sessione (escluso il debriefing alla fine della sessione).',
    'MursionPortal.SimAttendance.Tooltip.Late': 'Lo studente si è connesso troppo tardi per completare la simulazione.',
    'MursionPortal.SimAttendance.Tooltip.Left': 'Lo studente si è disconnesso intenzionalmente prima di completare la simulazione. Ciò potrebbe essere dovuto a uno studente non preparato o che ha dovuto abbandonare a causa di un\'emergenza.',
    'Session.Edit.Modal.JoinSessionBtnTooltip': 'Il pulsante Avvia sessione verrebbe abilitato prima di <code>{minutesBeforeJoin}</code> minuti dall\'inizio della sessione.',
    'Dashboard.Simulations.SimulationSummaries.TableHeader': 'Dati di riepilogo delle sessioni',
    'Dashboard.Learners.LearnerActivity.TableHeader': 'Dati sull\'attività dello studente',
    'Dashboard.Learners.LearnerSimulation.TableHeader': 'Dati sulle sessioni dello studente',
    'Dashboard.ContractProgress.Column.Missed': 'Persa Studente',
    'Dashboard.ContractProgress.Column.TotalRescheduled': 'Totale riprogrammate',
    'Dashboard.ContractProgress.Column.RescheduledMakeup': 'Recupero riprogrammato',
    'Dashboard.ContractProgress.Column.LateRescheduledSimulations': 'Riprogrammata in ritardo',
    'Dashboard.ContractProgress.Column.EarlyRescheduledSimulations': 'Riprogrammata in anticipo',
    'Dashboard.ContractProgress.Column.CompletedUtilization': 'Completamento della sessione',
    'Dashboard.ContractProgress.Column.ScheduledUtilization': 'Completamento stimato',
    'Dashboard.ContractProgress.ColumnTooltip.Remaining': 'Sessioni acquistate meno le sessioni completate.',
    'Dashboard.ContractProgress.ColumnTooltip.TotalRescheduled': 'Totale simulazioni programmate',
    'Dashboard.ContractProgress.ColumnTooltip.RescheduledMakeup': 'Totale simulazioni contrassegnate come recupero riprogrammato',
    'Dashboard.ContractProgress.ColumnTooltip.LateRescheduledSimulations': 'simulazioni che vengono riprogrammate in ritardo.',
    'Dashboard.ContractProgress.ColumnTooltip.EarlyRescheduledSimulations': 'simulazioni che vengono riprogrammate in anticipo.',
    'Dashboard.Learners.Column.SimulationId': 'ID - Sessione',
    'Dashboard.Learners.Column.SimulationId.CopyHover': 'Copia ID sessione',
    'Dashboard.Learners.Column.SSO_ID': 'ID SSO',
    'Dashboard.Learners.Column.Recorded': 'Registrato',
    'Dashboard.Learners.Column.LearnerRecordingConsent': 'Consenso dello studente alla registrazione',
    'Dashboard.Learners.Column.RecordingRecipients': 'Destinatari della registrazione',
    'Dashboard.Learners.Column.RecordingUploaded': 'Registrazione caricata',
    'Dashboard.Learners.ColumnTooltip.SSOID': 'Utenti che sono stati forniti',
    'Dashboard.Learners.ColumnTooltip.Recorded': 'Indica se la simulazione è stata registrata o se non è stata consentita.',
    'Dashboard.Learners.ColumnTooltip.LearnerRecordingConsent': 'Indica se lo studente ha dato il consenso alla registrazione della simulazione.',
    'Dashboard.Learners.ColumnTooltip.RecordingRecipients': 'Ruoli a cui viene inviata la registrazione della simulazione.',
    'Dashboard.Learners.ColumnTooltip.RecordingUploaded': 'Indica se la registrazione della simulazione è stata caricata.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.LateReschedule': 'Sessioni che sono state riprogrammate in ritardo dallo studente.',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.LateReschedule': 'Riprogrammazione in ritardo',
    'MursionPortal.Dashboard.Chart.Header.LearnerStatus': 'Stato studente (Tutti i Contratti)',
    'Dashboard.SimulationTable.Column.SimulationStatus': 'Stato della Sessione',
    'MursionPortal.Placeholder.ContractValue': 'Seleziona un Contratto',
    'MursionPortal.Placeholder.NoContractValue': 'Nessun Contratto disponibile',
    'Dashboard.Learners.Column.AssignedTeams': 'Team assegnati',
    'SessionReports.VideoRecordingDeletedWithSupportCase': 'Purtroppo, questo video non può essere visualizzato a causa di un #{supportCase} in sospeso.',
    'MursionPortal.Dashboard.CompletedSessions': 'Sessioni completate',
    'MursionPortal.Dashboard.ViewAll': 'Mostra tutto',
    'MursionPortal.Dashboard.DateOfSimulation': 'Data della Sessione',
    'Dashboard.LeftPane.CategoryHeader.Videos': 'VIDEO',
    'Dashboard.LeftPane.Videos.NotUploaded': 'Registrazioni non caricate',
    'Dashboard.NotUploaded.TableHeader': 'Registrazioni Dati non caricati',
    'Dashboard.VideosTable.Column.SessionId': 'IDSessione',
    'Dashboard.VideosTable.Column.SimspecialistId': 'IDSIMSpecialist',
    'Dashboard.VideosTable.Column.ClientId': 'IDCliente',
    'Dashboard.VideosTable.Column.SessionEnd': 'Fine Sessione',
    'Dashboard.VideosTable.Column.SoftwareVersion': 'Versione scenario',
    'Dashboard.VideosTable.NoVideosError': 'Nessun video è stato trovato.',
    'Filters.SimSpecialist': 'Nome SIM',
    'Filters.SimSpecialistPlaceHolderHint': 'Cerca SimSpecialist',
    'MursionPortal.Placeholder.SimSpecialistValue': 'Seleziona un SimSpecialist',
    'Users.Table.ErrorOnLoadUserMessage': 'Si è verificato un errore durante il caricamento dell\'utente!',
    'Dashboard.ContractProgress.InfoBadge.ContractDates': 'Date del Contratto',
    'Dashboard.ContractProgress.InfoBadge.ContractID': 'ID Contratto',
    'Dashboard.Learners.Column.EarlyCancelled': 'Annullato in anticipo',
    'Dashboard.Learners.Column.LateReschedules': 'Riprogrammazioni in ritardo',
    'Dashboard.Learners.Column.EarlyReschedules': 'Riprogrammazioni in anticipo',
    'Dashboard.Learners.Column.RescheduledMakeUp': 'Recupero riprogrammato',
    'Dashboard.Learners.Column.ErrorSim': 'Errore (SIM)',
    'Dashboard.Learners.Column.CancelledOps': 'Annullato (Ops)',
    'Dashboard.Learners.ColumnTooltip.AssignedProjects': 'Progetti a cui questo studente è attualmente assegnato',
    'Dashboard.Learners.ColumnTooltip.AssignedTeams': 'Team a cui questo studente è attualmente assegnato',
    'Dashboard.Learners.ColumnTooltip.AssignedScenarios': 'Scenari a cui questo studente è attualmente assegnato',
    'Dashboard.Learners.ColumnTooltip.ScheduledScenarios': 'Scenari che questo studente ha programmato ma non ha ancora completato',
    'Dashboard.Learners.ColumnTooltip.CompletedScenarios': 'Scenari che questo studente ha completato',
    'Dashboard.Learners.ColumnTooltip.EarlyCancelled': 'Simulazioni che sono state cancellate in anticipo (prima del termine di cancellazione) dallo studente, dal facilitator o dall\'account owner o dalle simulazioni',
    'Dashboard.Learners.ColumnTooltip.LateReschedules': 'Simulazioni che sono state riprogrammate in ritardo (dopo la scadenza della cancellazione) dallo studente, dal facilitator o dall\'account owner',
    'Dashboard.Learners.ColumnTooltip.RescheduledMakeUp': 'Simulazioni che erano state perse in precedenza dallo studente, dal facilitator, dall\'account owner o dalle simulazioni',
    'Dashboard.Learners.ColumnTooltip.EarlyReschedules': 'Ore di simulazione riprogrammate in anticipo (prima del termine di cancellazione) dallo studente, dal facilitator o dall\'account owner',
    'Dashboard.Learners.ColumnTooltip.ErrorSim': 'Simulazioni terminate con un errore lato SIM della connessione',
    'Dashboard.Learners.ColumnTooltip.CancelledOps': 'Simulazioni cancellate da Mursion',
    'SessionReports.VideoRecordingPermissionNotGranted': 'Questo video non è disponibile perché un partecipante non voleva essere registrato.',
    'SessionReports.NoVideoAvailableForSession': 'Non ci sono video per questa sessione.',
    'MursionPortal.NotAvailable': 'N/D',
    'Projects.ProjectInfo.Checkbox.EnablePostSimulationSurvey.OneToOne': 'Abilita il sondaggio di feedback sulla SIM post-simulazione per 1',
    'Projects.ProjectInfo.Checkbox.EnablePostSimulationSurvey.Workshop': 'Abilita il sondaggio di feedback sulla SIM post-simulazione per le consegne del Workshop',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PostSimulationSurvey.OneToOne': 'Sondaggio di feedback sulla SIM post-simulazione 1',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PostSimulationSurvey.Workshop': 'Consegne del Workshop del sondaggio di feedback sulla SIM post-simulazione',
    'Clients.Portal.Button.EditMemberships': 'Modifica adesioni',
    'Calendar.ProjectsList.ShowOnlyActiveProjects': 'Mostra solo Progetti attivi',
    'MursionPortal.Status.Undefined': 'Non definito',
    'MursionPortal.Status.Orphan': 'Orfana',
    'MursionPortal.Status.Reserved': 'Riservata',
    'MursionPortal.Status.Waif': 'Abbandonata',
    'MursionPortal.Status.Upcoming': 'In arrivo',
    'MursionPortal.Status.Running': 'In esecuzione',
    'MursionPortal.Status.NeedsReview': 'Necessita revisione',
    'MursionPortal.Status.Reviewed': 'Rivista',
    'MursionPortal.ErrorMessage.NoDataAvailable': 'Non ci sono dati sufficienti di questa sessione per fornirne l\'analisi.',
    'Dashboard.NotUploaded.MainHeader': 'Registrazioni non caricate',
    'MursionPortal.Message.NotEnoughDataToDisplay.ConversationalFlow': 'Non ci sono dati sufficienti di questa sessione per creare questo grafico',
    'MursionPortal.Label.SIMSpecialist': 'SIM specialist',
    'Dashboard.SimulationTable.Column.LearnerRecordingConsent': 'Consenso alla registrazione dello studente',
    'Dashboard.SimulationTable.Column.RecordingRecipients': 'Destinatari della registrazione',
    'Dashboard.SimulationTable.Column.RecordingUploaded': 'Registrazione caricata',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerRecordingConsent': 'Indica se lo studente ha dato il consenso alla registrazione della simulazione',
    'Dashboard.SimulationTable.ColumnTooltip.RecordingRecipients': 'Ruoli a cui viene inviata la registrazione della simulazione',
    'Dashboard.SimulationTable.ColumnTooltip.RecordingUploaded': 'Indica se la registrazione della simulazione è stata caricata',
    'MursionPortal.ConversationalFlow.label.zoom': 'Zoom',
    'MursionPortal.label.LearnerInterjects': 'Interiezione dello Studente',
    'MursionPortal.label.AvatarInterjects': 'Interiezione dell\'Avatar',
    'MursionPortal.label.LearnerInterrupted': 'Interiezione da parte dello Studente',
    'MursionPortal.label.AvatarInterrupted': 'Interiezione da parte dell\'Avatar',
    'MursionPortal.label.Silence': 'Silenzio',
    'Clients.Modal.Label.UserRecordingConsent': 'Consenso alla registrazione dell\'utente',
    'Clients.Modal.Label.UserRecordingConsent.ToolTip': 'Chiedi il consenso alla registrazione dell\'utente',
    'MursionPortal.Notset': 'Non impostato',
    'Clients.Modal.Label.UserML3SocialRecordingConsent': 'Consenso alla registrazione dell\'utente Mursion Social',
    'Clients.Modal.Label.UserML3SocialRecordingConsent.ToolTip': 'Chiedi all\'utente il consenso a essere registrato. Se questo non è abilitato, il SIM specialist lo chiederà verbalmente.',
    'MursionPortal.LearnerSimSelector.Sim.Available.ToolTip': 'SIM certificate che non sono prenotate per una sessione e hanno disponibilità per l\'orario selezionato',
    'MursionPortal.LearnerSimSelector.Sim.Unavailable.ToolTip': 'SIM certificate che non hanno disponibilità nel sistema e non sono prenotate per un\'altra sessione per l\'orario selezionato',
    'Settings.Config.Client.VideoRecordingDataRetentionPolicy': 'Mostra Configurazione politica di conservazione dei dati di registrazione video',
    'MursionPortal.DemandBasedScheduling.BackButton.BackToScenario': 'Torna allo Scenario',
    'MursionPortal.DemandBasedScheduling.BackButton.BackToCalendar': 'Torna al Calendario',
    'SessionReports.VideoRecordingDeletedDueToDataRetentionPolicy': 'Questo video non è disponibile a causa delle norme sulla conservazione dei dati della propria azienda.',
    'SessionReports.VideoRecordingAvailabilityMessage': 'Questo video sarà disponibile per la visualizzazione fino al {videoAvailabilityDate}',
    'SessionReports.SessionOrLearnerStatusError': 'Nessun video è stato registrato per questa sessione a causa di un errore tecnico.',
    'Clients.ImportUsers.NextStepButton': 'Passaggio successivo',
    'Clients.ImportUsers.CancelButton': 'Annulla',
    'Clients.ImportUsers.UploadValidateButton': 'Carica + Convalida',
    'Session.Table.Column.Attendance': 'Partecipazione',
    'Session.Table.Column.Value.CompleteAttendance': 'Completa partecipazione',
    'Session.Table.Column.Value.AttendanceComplete': 'Presenza completata',
    'Session.Table.Column.Value.NotApplicable': '(Non applicabile)',
    'Session.Table.Column.Survey': 'Sondaggio',
    'Session.Table.Column.Value.CompleteGoogleForm': 'Completa il modulo Google',
    'Session.Table.Column.Value.CompleteSurvey': 'Completa sondaggio',
    'Session.Table.Column.Value.SurveyComplete': 'Sondaggio completato',
    'Projects.Modal.Text.LearnerSelected': 'Almeno {maxLearners} studente deve essere selezionato',
    'Session.Table.Column.Value.SessionNotCompleted': 'Sessione non completata',
    'MursionPortal.Project.InviteToSchedule': 'Invita a Programmare',
    'MursionPortal.Project.InvitedToday': 'Invitato oggi',
    'MursionPortal.Project.YourInvitationsText': 'I tuoi inviti verranno inviati durante la notte.',
    'MursionPortal.Project.InviteToSchedule.Tooltip': 'Gli inviti sono già stati inviati per lo scenario. Potrai inviare di nuovo gli inviti domani.',
    'MursionPortal.Project.InvitedToday.Tooltip': 'Gli studenti che non hanno programmato o annullato/perso questo scenario riceveranno un\'e-mail di invito a programmare.',
    'MursionPortal.Project.InviteMessage.SentDate': 'Ultimo invio {sentDate}',
    'ITManager.TechRestriction.EditDialog.ProviderErrorMsg': 'Si prega di selezionare il Provider',
    'ITManager.TechRestriction.EditDialog.GlobalRestriction': 'Restrizione globale',
    'ITManager.TechRestriction.EditDialog.HolidayRestriction': 'Restrizione festiva',
    'ITManager.TechRestriction.Table.Provider': 'Provider',
    'Session.Table.Column.Survey.HoverText': 'Se le SIM abilitate riceveranno un link per completare il feedback sullo studente',
    'MursionPortal.Project.InviteMessage.InvitedBy': 'Invitato da {user} - {role}',
    'MursionPortal.Project.LearnersNotYetScheduled': '{learners} studenti devono ancora programmare',
    'Session.Table.Column.Value.AttendanceRequired': 'Presenza richiesta',
    'MursionPortal.Button.Schedule.Another': 'Programma un altro',
    'MursionPortal.EulaAgreement.CodeOfConduct': 'Ogni simulazione di Mursion coinvolge un singolo Simulation Specialist dal vivo che fornisce il dialogo parlato per un massimo di cinque avatar virtuali in una sessione di apprendimento. Il compito del Simulation Specialist è presentare conversazioni sfidanti per consentire agli studenti, come te, di mettere in pratica le complesse abilità umane in ogni scenario. In Mursion riconosciamo l\'importanza di creare uno spazio sicuro in cui le persone possano praticare coraggiosamente conversazioni potenzialmente stressanti, con diverse sfumature e complesse. Per garantire che sia gli Studenti che il Simulation Specialist possano partecipare con sicurezza, chiediamo alle persone di entrambi i gruppi di impegnarsi a rispettare un Codice di condotta di base.',
    'MursionPortal.EulaAgreement.CodeOfConduct.Header': 'Panoramica della metodologia e Codice di condotta',
    'MursionPortal.EulaAgreement.UserAgreement.Header': 'Accordo per gli utenti',
    'MursionPortal.Import.BackToUser': 'Torna all\'Utente',
    'MursionPortal.Import.BackToTeam': 'Torna al Team',
    'Mursion.Portal.Edit.Team.Upload.AccurateUploading': 'Assicurati che i dati siano accurati prima di caricare.',
    'Mursion.Portal.Edit.Team.Upload.AskedReUpload': 'Se sono presenti errori nel tuo file, verranno mostrati in un foglio di calcolo risultante al Passaggio 4. Segui le istruzioni per correggere gli errori nel modello originale e ricaricare il file.',
    'Mursion.Portal.Edit.Team.Upload.IncludeNewUser': 'Puoi anche includere nuovi utenti da importare insieme agli utenti non riusciti durante il nuovo caricamento.',
    'Mursion.Portal.Edit.Team.ReadyToUpload': 'Quando il tuo file è pronto, fai clic su Carica + Convalida',
    'Mursion.Portal.Edit.Team.UploadAndValidationFile': 'Caricamento + Convalida del file',
    'Mursion.Portal.Edit.Team.TeamAssignmentsUpdateSuccessfully': 'Incarichi del Team aggiornati con successo',
    'Mursion.Portal.Edit.Team.UploadSuccessTitle': '{userCount} Utenti importati!',
    'Mursion.Portal.Edit.Team.UploadFailureTitle': 'Errore!',
    'Mursion.Portal.Edit.Team.UploadSuccess': 'Tutti gli utenti sono stati aggiunti con successo al portale.',
    'Mursion.Portal.Edit.Team.DoneButton': 'Fatto',
    'Mursion.Portal.Edit.Team.DownloadSuccessful': 'Download riuscito - Procedi al passaggio successivo',
    'Mursion.Portal.Edit.Team.DownloadError': 'Errore di download - Riprova',
    'Mursion.Portal.Edit.Team.UploadFileAgain': 'Carica di nuovo il file',
    'Mursion.Portal.Edit.Team.ContinueWithoutCorrecting': 'Continua senza correggere gli errori',
    'Mursion.Portal.Edit.Team.SuccessfulImportTitle': 'Importazione riuscita',
    'Mursion.Portal.Edit.Team.UnSuccessfulImportTitle': 'Importazione non riuscita',
    'Mursion.Portal.Import.User.HeaderTitle': 'Importa utenti',
    'Mursion.Portal.Import.User.Upload.AskedReUpload': 'Se sono presenti errori nel tuo file, verranno mostrati in un foglio di calcolo risultante al Passaggio 4. Segui le istruzioni per correggere gli errori nel <strong>modello originale</strong> e ricaricare il file.',
    'Mursion.Portal.Import.User.ReadyToUpload': 'Quando il file è pronto e formattato correttamente, carica il file per la convalida',
    'Mursion.Portal.Import.User.ValidatingYourDataUntilTheUpload': 'Stiamo convalidando i dati e importando gli utenti. Ricontrolla tra 15 minuti o quando ricevi un avviso del completamento di questo processo.',
    'Mursion.Portal.Import.User.UploadSuccessTitle': '{successCount} Utenti importati!',
    'Mursion.Portal.Import.User.ErrorContinueTitle': 'Continua senza correggere gli errori!',
    'Mursion.Portal.Import.User.ErrorContinue': '{failedCount} users failed to import as the errors were not fixed.', // TODO add translations
    'Settings.ScenarioBank.Form.ArtBundlesWarning': 'Rinomina i pacchetti arte dai nomi predefiniti',
    'Mursion.Portal.Import.User.Instruction.Upload': 'Fai clic su <strong>Scarica modello Excel</strong> di seguito e apri il file scaricato (<strong>client_internal_users.xlsx</strong> o <strong>client_external_users.xlsx</strong>).',
    'Mursion.Portal.Import.User.Instruction.Format': '<strong>Nel modello, i campi e-mail, ID ruolo e SSO sono obbligatori: non lasciarli vuoti. Aggiungi le informazioni sull\'utente correttamente sotto ogni intestazione.</strong> NON modificare le intestazioni, la struttura del modello o il nome del file in quanto ciò potrebbe comportare un\'importazione non riuscita.',
    'Mursion.Portal.Import.User.Instruction.NewUser': 'In <strong>Colonne J-S o F-O</strong> aggiungi i nomi di tutti i Team a cui l\'utente deve essere assegnato (assicurati che l\'ortografia corrisponda esattamente al nome).',
    'Mursion.Portal.Import.User.Instruction.Error': 'Una volta inseriti tutti i tuoi utenti, salva il file sul desktop o in un luogo che ricorderai e fai clic su <strong>Procedi</strong>.',
    'Mursion.Portal.Import.User.Instruction.OriginalTemplate': 'Se sono presenti errori nel tuo file, verranno mostrati in un foglio di lavoro risultante al Passaggio 4. Segui le istruzioni per correggere gli errori nel <strong>modello originale</strong> e ricaricare il file.',
    'Mursion.Portal.Import.User.Instruction.UploadList': 'Se scegli di continuare senza correggere gli errori, il file <strong>Download dell\'elenco di caricamento non riuscito</strong> sarà disponibile solo per 7 giorni.',
    'Mursion.Portal.Import.User.DownloadExcelTemplate': 'Scarica il modello Excel',
    'Mursion.Portal.Import.User.UploadFileStatus': 'Caricamento file',
    'Mursion.Portal.Import.User.DoNotCloseUntilUpload': 'Stiamo convalidando i dati e importando gli utenti. Questo processo continuerà in background, quindi puoi passare a un\'altra scheda o finestra e tornare a questa schermata in qualsiasi momento. Questo processo di importazione dovrebbe richiedere meno di 5 minuti.',
    'Mursion.Portal.Import.User.FileStatus.validation': 'Importazione utenti',
    'Mursion.Portal.Import.User.CorrectErrorSubHeading': 'Passaggio finale: correggere gli errori',
    'Mursion.Portal.Import.User.SuccessCount': '{successCount} users were successfully imported; {failedCount} users failed to import – please review.', // TODO add translations
    'Mursion.Portal.Import.User.DownloadFile': 'Click the <strong>Download Errors</strong> button and open the file to view the errors.', // TODO add translations
    'Mursion.Portal.Import.User.FileTabs': '<strong>Nel modello, i campi dell\'e-mail e del ruolo sono obbligatori: non lasciarli vuoti</strong>. NON modificare le intestazioni, la struttura del modello o il nome del file in quanto ciò potrebbe comportare un\'importazione non riuscita.',
    'Mursion.Portal.Import.User.ReviewCorrect': 'Correggi gli errori nel <strong>modello originale</strong> (o <strong>client_internal_users.xlsx</strong> o <strong>client_external_users.xlsx</strong>) e ricarica il file.',
    'Mursion.Portal.Import.User.DownloadErrorButton': 'Scarica Errori',
    'Mursion.Portal.Edit.Team.EditTeamTitle': 'Modifica adesioni del Team',
    'Mursion.Portal.Edit.Team.Instruction.UpdateFile': 'Aggiungi le informazioni sull\'utente correttamente sotto ogni intestazione. Nelle Colonne da L a U, aggiungi i nomi di tutti i team a cui lo studente dovrebbe essere assegnato (cioè team a cui essere aggiunti o aggiornati), una colonna per team. Va bene lasciare vuote alcune colonne del team, se necessario. Assicurarsi che ciò avvenga correttamente, poiché sovrascriverà le assegnazioni del team esistenti.',
    'Mursion.Portal.Edit.Team.Instruction.Process': 'Carica un file alla volta: limita il numero a 5.000 utenti per file.',
    'Mursion.Portal.Edit.Team.Instruction.ProfileFields': '<strong>I campi dell\'e-mail e del ruolo sono obbligatori: non lasciarli vuoti.</strong> Non modificare le intestazioni, la struttura del modello o il nome del file in quanto ciò potrebbe comportare un\'importazione non riuscita.',
    'Mursion.Portal.Edit.Team.Instruction.ErrorOccur': 'I formati di file supportati sono solo .xlsx.',
    'Mursion.Portal.Edit.Team.DoNotCloseUntilUpload': 'Stiamo convalidando i dati e aggiornando le assegnazioni del team. Questo processo continuerà in background, quindi puoi passare a un\'altra scheda o finestra e tornare a questa schermata in qualsiasi momento. Questo processo dovrebbe richiedere meno di 5 minuti.',
    'Mursion.Portal.Edit.Team.FileStatus.validation': 'Aggiornamento adesioni del team',
    'Mursion.Portal.Edit.Team.UploadFailureNotification': 'Il tuo file contiene più di 5k utenti. Contatta l\'assistenza o carica più file con meno di 5k utenti.',
    'Mursion.Portal.Edit.Team.ReviewCorrect': '<strong>Nel modello, i campi dell\'e-mail e del ruolo sono obbligatori: non lasciarli vuoti.</strong> NON modificare le intestazioni, la struttura del modello o il nome del file in quanto ciò potrebbe comportare un\'importazione non riuscita.',
    'Mursion.Portal.Edit.Team.DownloadFile': 'Correggi gli errori nel file modello originale (<strong>edit_learner_team_memberships.xlsx</strong>) -- NON modificare le intestazioni, la struttura del modello o il nome del file in quanto ciò potrebbe comportare un\'importazione non riuscita.',
    'Mursion.Settings.ScenarioBank.Modal.ChecklistURL': 'URL lista di controllo SIM',
    'Mursion.Settings.ScenarioBank.Modal.MustBeAValidURL': 'Deve essere un URL valido',
    'MursionPortal.Label.SpeakingIsZero': 'è 0%',
    'MursionPortal.Label.InterruptionIsZero': 'che è 0%',
    'MursionPortal.CodeOfConduct.SchedulingFlow.Text': 'Scegliendo di programmare la sessione di Mursion, si accetta il modello di consegna della simulazione di Mursion e si conferma di sostenere e onorare il Codice di condotta Mursion. Leggere di più <code>qui</code>.',
    'Mursion.Portal.Import.User.InvalidFile': 'Verrà caricato solo il modello csv o excel.',
    'Mursion.Portal.Import.User.MaxFileSize': 'La dimensione del file non deve superare i 5 MB',
    'Mursion.Portal.Import.User.Tooltip': 'Fare clic qui per importare nuovi utenti o aggiornare utenti esistenti da un file di esportazione.',
    'Mursion.Portal.Edit.Team.Tooltip': 'Fare clic qui per assegnare o riassegnare in blocco l\'appartenenza al team.',
    'MursionPortal.Project.InviteLearnersToSchedule': 'Invita gli studenti a programmare',
    'MursionPortal.CheckboxLabel.SchedulingByBookIt': 'Programmazione di BookIt',
    'Dashboard.Report.SendReport': 'Spedisci il resoconto',
    'Dashboard.Report.ScheduleReport': 'Programma resoconto',
    'Dashboard.Report.EditSchedule': 'Modifica programma',
    'Dashboard.Report.ViewSchedule': 'Visualizza programma',
    'Dashboard.Report.DeleteSchedule': 'Elimina programma',
    'Dashboard.Report.Filter.Title': 'Filtri selezionati',
    'Dashboard.Report.SelectReportFrequency.Label': 'Seleziona frequenza del resoconto',
    'Dashboard.Report.SelectSessions.Label': 'Seleziona le sessioni da includere',
    'Dashboard.Report.SelectReportStartDate.Label': 'Seleziona data di inizio del resoconto',
    'Dashboard.Report.SelectReportStartDate.Tooltip': 'Se hai scelto di ricevere i resoconti settimanalmente, questo arriverà ogni settimana nel giorno selezionato. Se hai selezionato mensilmente, arriverà in quella data ogni mese.',
    'Dashboard.Report.SelectAccountOwner.Label': 'Seleziona l\'account owner o il facilitator',
    'Dashboard.Report.Dropdown.Select.Placeholder': 'Seleziona',
    'Dashboard.Report.ManuallyAddRecipients.Label': 'Aggiungi manualmente i destinatari',
    'Dashboard.Report.ManuallyAddRecipients.Label.Placeholder': 'Aggiungi e-mail qui',
    'Dashboard.Report.RecipientsBox.Label': 'Destinatari',
    'Dashboard.Report.RecipientsBox.RecipientMaxLimit': 'I destinatari non possono essere più di 30',
    'Dashboard.Report.SendReport.ConfirmationModal.Text': 'Il resoconto è stato inviato ai destinatari.',
    'Dashboard.Report.ScheduleReport.ConfirmationModal.HeadingText': 'Il tuo resoconto è stato programmato!',
    'Dashboard.Report.ScheduleReport.ConfirmationModal.Text': 'Interrompi o modifica in qualsiasi momento facendo clic su <strong>Modifica Programmazione.</strong>',
    'Dashboard.Report.EditSchedule.ConfirmationModal.HeadingText': 'I cambiamenti sono stati salvati!',
    'Dashboard.Report.EditSchedule.ConfirmationModal.Text': 'Interrompi i resoconti in qualsiasi momento facendo clic su <strong>Modifica Programmazione.</strong>',
    'Dashboard.Report.DeleteSchedule.ConfirmationModal.Text': 'Il programma è stato cancellato',
    'Dashboard.Report.DeleteSchedule.ConfirmationModal.ConfirmationText': 'Sei sicuro di voler eliminare la programmazione per questo resoconto?',
    'Dashboard.Report.ConfirmationModal.Button': 'Capito!',
    'Dashboard.Report.DeleteSchedule.ConfirmationButton': 'Sì, Elimina',
    'Dashboard.Report.EditSchedule.EditButton': 'Salva le modifiche alla programmazione',
    'Dashboard.Report.ReportFrequencyOption.Daily': 'QUOTIDIANO',
    'Dashboard.Report.ReportFrequencyOption.Weekly': 'SETTIMANALE',
    'Dashboard.Report.ReportFrequencyOption.Monthly': 'MENSILE',
    'Dashboard.Report.ReportSessionOption.PreviousDay': 'Sessioni giorno precedente',
    'Dashboard.Report.ReportSessionOption.Previous7Days': 'Sessioni 7 giorni precedenti',
    'Dashboard.Report.ReportSessionOption.CurrentSessions': 'Sessioni del giorno corrente',
    'Dashboard.Report.ReportSessionOption.Next7Days': 'Sessioni Prossimi 7 Giorni',
    'Dashboard.Report.ReportSessionOption.MonthToDate': 'Sessioni dal mese ad oggi',
    'MursionPortal.Dashboard.UpcomingSession': 'Prossima sessione',
    'Dashboard.Report.ReportSessionOption.PreviousFullMonth': 'Sessioni mese intero precedente',
    'Dashboard.Report.ReportSessionOption.Previous30Days': 'Sessioni 30 giorni precedenti',
    'Projects.ProjectInfo.CheckBox.ScenarioSequencing': 'Sequenziamento scenari',
    'Projects.ProjectInfo.CheckBox.ScenarioSequencing.Text': 'Fare clic sul pulsante per visualizzare e configurare l\'ordine degli scenari in questo progetto.',
    'Projects.ProjectInfo.Button.ScenarioSequence': 'Sequenza scenario',
    'MursionPortal.Projects.DragAndDropModal.Text': 'Trascina e rilascia gli scenari in ordine di preferenza',
    'MursionPortal.Projects.DragAndDropModal.DragHandlerImage': 'Trascina immagine maniglia',
    'MursionPortal.Projects.DragAndDropModal.ModalCloseIcon': 'Chiudi icona',
    'MursionPortal.Project.Scheduled.NextDate': 'Programmato {nextDate}',
    'MursionPortal.Project.Sequencing': 'Sequenziamento',
    'MursionPortal.Project.Checkbox.Sequencing': 'Abilita sequenziamento',
    'MursionPortal.Dashboard.Join.Tooltip': 'Una volta che questa Sessione sarà disponibile, potrai unirti ad essa',
    'MursionPortal.Project.InviteToScheduleProject.Tooltip': 'Gli studenti che non hanno programmato o annullato/perso scenari in questo progetto riceveranno un\'e-mail di invito a programmare.',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts': 'Tasti rapidi',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.SpaceBar': 'Tasto barra spaziatrice',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.SpaceBar.Text': 'Seleziona o rilascia lo scenario.',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.ArrowKeys': 'Tasti freccia destra e sinistra o tasti freccia su e giù',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.ArrowKeys.Text': 'Trascina lo scenario a sinistra e a destra.',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Esc': 'Tasto Esc',
    'MursionPortal.Esc': 'Esc',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Esc.Text': 'Chiudi Modal sequenza scenario',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Enter': 'Tasto Invio',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Enter.Text': 'Chiudi descrizione comandi tasti rapidi',
    'MursionPortal.Projects.DragAndDropModal.Tooltip': 'Apri descrizione comandi tasti rapidi',
    'Dashboard.Report.DuplicateEntry.ErrorMessage': 'Non è consentito inserire duplicati',
    'Projects.ProjectInfo.EnableSessionTimeStep': 'Abilita passaggio Tempo di sessione',
    'Projects.ProjectInfo.SessionTimeStepInfo': 'Il passaggio temporale della sessione a livello del licenziatario verrà utilizzato se il valore del passaggio temporale della sessione non è impostato a livello di progetto ',
    'Session.Edit.Modal.JoinSimulation': 'Partecipa alla sessione',
    'MursionPortal.Selector.RequestStatus': 'Stato della richiesta',
    'MursionPortal.Dashboard.RetakeSimulation': 'Riprendi simulazione',
    'MursionPortal.Dashboard.NextSimulationAssigned': 'Prossima simulazione assegnata',
    'MursionPortal.AriaLabel.SelectTime': 'Seleziona l\'ora',
    'Dashboard.Report.Frequency.Date.Select.Placeholder': 'Seleziona la data di inizio',
    'MursionPortal.InstructoScenarioTeamMapping.Header': 'Seleziona lo scenario e il team che vuoi mappare',
    'MursionPortal.InstructoScenarioTeamMapping.MappingAlreadyExistMessage': 'Questo scenario e il team sono già stati mappati in un contesto diverso.',
    'MursionPortal.DataSummaryPoints.manualFulFilled': 'Riempito manualmente',
    'MursionPortal.DataSummaryPoints.pendingRequest': 'Basato sulla richiesta in sospeso',
    'MursionPortal.DataSummaryPoints.declined': 'Richieste rifiutate',
    'MursionPortal.DataSummaryPoints.avgFulfilled': 'Riempimento medio per manuale',
    'MursionPortal.DataSummaryPoints.totalSwap': 'Totale Scambi',
    'MursionPortal.DataSummaryPoints.swapAccepted': 'Scambi accettati',
    'MursionPortal.Dashboard.MissedSimulations': 'Simulazioni perse',
    'MursionPortal.Dashboard.RescheduledSimulations': 'Simulazioni riprogrammate',
    'MursionPortal.Dashboard.BookedSimulations': 'Simulazioni prenotate',
    'MursionPortal.Dashboard.CancelledSimulations': 'Simulazioni annullate',
    'Dashboard.UserTable.Column.In': 'In',
    'Projects.ProjectInfo.ConfirmationDialog.ScenarioSequencing.Text': 'Sei sicuro di voler disabilitare il sequenziamento? Ciò eliminerebbe anche la numerazione di sequenza in ogni scenario.',
    'Projects.ProjectInfo.Disable.Sequencing': 'Disabilita Sequenziamento',
    'MursionPortal.ErrorMessage.TechnicalIssueMsg': 'Per favore riprova. Abbiamo riscontrato un problema tecnico.',
    'SessionFlow.Text.Event.DeletedByUsername': 'Eliminato da {user}',
    'Settings.ScenarioBank.Modal.timestepShouldBeAtLeast': 'Deseleziona "Abilita passaggio temporale della sessione" o inserisci il valore del passaggio temporale di almeno {minutes} minuti',
    'MursionPortal.AriaImage.Attribute.Previous': 'Precedente',
    'MursionPortal.AriaImage.Attribute.Notify': 'Notificare',
    'MursionPortal.AriaImage.Attribute.Upload': 'Carica',
    'MursionPortal.Report.FilterHeading.SoftwareVersions': 'Versioni software',
    'MursionPortal.Report.FilterHeading.DeliveryModes': 'Modalità di consegna',
    'MursionPortal.Report.FilterHeading.LearnerSessionStatuses': 'Stati della sessione dello studente',
    'MursionPortal.Report.FilterHeading.LearnerStatuses': 'Stati degli studenti',
    'MursionPortal.Report.FilterHeading.SimulationStatus': 'Stato della simulazione',
    'MursionPortal.Project.DuplicateProjectBtnTooltip': 'Ciò duplicherà tutte le impostazioni e la configurazione di questo progetto in un nuovo progetto',
    'MursionPortal.Project.DuplicateProjectBtnText': 'Duplica in Magic Project',
    'MursionPortal.ScenarioInfo.Label.Until': 'Hai tempo fino a {date} per portare questo fino a {completionRate} volte.',
    'Clients.Modal.Button.Schedule.Tooltip.Text': 'Devi prima programmare lo scenario precedente',
    'MursionPortal.Scenario.Name': 'Nome scenario',
    'MursionPortal.ScenarioCard.BackToProjects.Button': 'Torna a Progetti',
    'MursionPortal.ScenarioCard.ViewMore.Button': 'Visualizza di più',
    'MursionPortal.ScenarioInfo.Documents': 'Documenti dello Scenario',
    'MursionPortal.ScenarioInfo.Documents.File': 'File {extension}',
    'MursionPortal.ScenarioCard.ViewSessions': 'Visualizza sessioni',
    'MursionPortal.Label.ScenarioPreview': 'Anteprima scenario',
    'MursionPortal.ScenarioInfo.AssignedTeams': 'Cerca o seleziona Team',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialists': 'SIM specialist certificati',
    'MursionPortal.ScenarioInfo.AddSimSpecialist': 'Aggiungi Sim specialist',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.PencilIcon': 'Modifica icona',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.DeleteButton': 'Elimina SIM',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.SearchText': 'Cerca o seleziona SIM Specialist',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.AddSim': 'Aggiungi Sim',
    'MursionPortal.Label.InactiveSIMSpecialists': 'SIM specialist inattivi',
    'MursionPortal.Label.Search.InactiveSIMSpecialists': 'Cerca o seleziona SIM specialist inattivi',
    'MursionPortal.Project.InviteToSchedule.Sequencing.Tooltip': 'Il sequenziamento è impostato per questo scenario e nessuno studente ha ancora programmato lo scenario precedente.',
    'Clients.Modal.Label.FacilitatorView.Tooltip': 'Se impostato su Livello cliente, i facilitator potranno vedere i dettagli a livello di Account owner. Se impostato a livello di team, il facilitator può vedere solo le informazioni sui team a cui sono assegnati.',
    'Clients.Modal.Label.VoiceAnalytics.Tooltip': 'Fornisce agli studenti un\'analisi automatizzata della loro sessione.',
    'Clients.Modal.Label.Show.Utilization.Reporting.Tooltip': 'Aggiunge i Resoconti sull\'utilizzo alla dashboard dell\'Account Owner.',
    'Clients.Modal.Label.VideoRecordingDataRetentionPolicy.Tooltip': 'Abilita se il client ha un criterio di conservazione dei dati personalizzato.',
    'MursionPortal.Label.SoftwareType.Tooltip': '"Solo Web" indica che gli Studenti possono accedere alle loro sessioni solo tramite Mursion Magic, la piattaforma basata su browser.',
    'Projects.ProjectInfo.Label.Timeline.Tooltip': 'Tutte le sessioni per questo progetto devono essere completate entro la Sequenza temporale del progetto.',
    'Projects.ProjectInfo.Checkbox.AllowSessionRecording.Tooltip': 'Deseleziona per impedire le registrazioni.',
    'MursionPortal.Checkbox.AllowLearnerToRefuseRecording.Tooltip': 'Gli studenti possono disattivare la registrazione. Se deselezionata e uno studente rifiuta l\'autorizzazione alla registrazione, non sarà in grado di partecipare alla sessione.',
    'Projects.ProjectInfo.label.Tags.Tooltip': 'I tag sono raggruppamenti organizzativi per trovare rapidamente progetti simili a livello di cliente.',
    'Projects.ProjectInfo.label.DemandTime.Tooltip': 'Lasso di tempo in cui gli studenti possono richiedere simulazioni per le fasce orarie di domanda',
    'Projects.ProjectInfo.label.DemandSlots.Tooltip': 'Imposta il numero di opzioni di programmazione che uno studente vede durante la programmazione. Se impostato a zero, lo studente potrà prenotare istantaneamente una fascia oraria aperta.',
    'Projects.ProjectInfo.label.Rescheduling.Tooltip': 'Consente agli studenti di riprogrammare le sessioni. Tieni presente che lo studente non può prenotare una sessione lo stesso giorno a meno che non sia selezionato Stesso giorno.',
    'MursionPortal.Project.SameDay.Tooltip': 'Consente agli studenti di riprogrammare per più tardi nello stesso giorno di calendario.',
    'Cleints.Modal.Label.AvailablePrivateSSOLabel': 'Abilita SSO privato:',
    'Cleints.Modal.Label.DomainsLabel': 'Dominio Web del cliente:',
    'Clients.Modal.Label.Logo': 'Logo del cliente:',
    'Projects.ProjectInfo.Label.CancellationDeadline': 'Termine di cancellazione',
    'Clients.Modal.Label.MursionSocialAttendance.ToolTip': 'Raccogli la presenza dello studente durante le sessioni di Mursion Social.',
    'Clients.Modal.Label.VideoRecordingDataRetentionDays': 'Conservazione dei dati di registrazione video (in giorni):',
    'Clients.Modal.Label.VideoRecordingDataRetentionPolicy': 'Politica di conservazione dei dati di registrazione video:',
    'MursionPortal.PasswordRequirementMessage.SpecialCharaters': 'almeno un carattere speciale',
    'MursionPortal.ErrorMessage.InvalidCaptcha': 'Captcha non valido. Per favore riprova.',
    'MursionPortal.Project.MissedAlert.TooLateJoin': 'Sei arrivato un po\' troppo tardi per partecipare',
    'MursionPortal.Project.MissedAlert.DontWorry': 'Non preoccuparti! Succede.',
    'MursionPortal.Project.MissedAlert.ClickReschedule': 'Fare clic su riprogrammare la simulazione.',
    'MursionPortal.Project.EmailMissedLink.TooLateJoin.ClickRescheduleTxt': 'Fare clic di seguito per riprogrammare la simulazione.',
    'MursionPortal.Password.Strength.Tooweak': 'La password è troppo debole',
    'MursionPortal.Password.Strength.Weak': 'La password è debole',
    'MursionPortal.Password.Strength.Medium': 'La password è media',
    'MursionPortal.Password.Strength.Strong': 'La password è forte',
    'Dashboard.LeftPane.User.SchedulingInfo':'Informazioni sul programma',
    'Dashboard.Button.ExportToEmail':'Esporta dati',
    'Filters.All':'Tutti',
    'Filters.More':' di più',
    'Filters.AllExcept':'Tutti, tranne',
    'Filters.MoreClients':' più clienti',
    'Filters.AllClients':'Tutti i clienti',
    'Filters.Role':'Ruolo',
    'Filters.TimeZone':'Fuso orario',
    'Dashboard.UserActivity.TableHeader':'Dati sull\'attività dell\'utente',
    'MursionPortal.GenerateLearnerSurveyLink':'Copia link del sondaggio',
    'MursionPortal.SurveyLinkLabel':'Il link del sondaggio è stato copiato negli appunti',
    'MursionPortal.Attention':'Attenzione!',
    'MursionPortal.LinkCannotBeGenerated':'Il link non può essere generato perché manca il campo ID contratto in questo progetto di scenari',
    'Dashboard.UserTable.Column.UserName':'Nome',
    'Dashboard.UserTable.Column.DateInvited':'Invitato',
    'Dashboard.UserTable.Column.DateRegistered':'Registrato',
    'Dashboard.UserTable.Column.DateArchived':'Archiviato',
    'Dashboard.UserTable.Column.DateLastLogin':'Ultimo accesso',
    'Dashboard.UserTable.Column.UsedSoftwareVersions':'Software',
    'Dashboard.UserTable.Column.SessionsScheduled':'Simulazione programmata',
    'Dashboard.UserTable.Column.SessionsCompleted':'Simulazione completata',
    'Dashboard.UserTable.Column.SessionsMissed':'Simulazioni perse',
    'Dashboard.UserTable.Column.LateCancellations':'Simulazione annullata',
    'Dashboard.UserTable.Column.SessionsError':'Errori della simulazione',
    'Session.Table.Column.ProjectId':'ID contratto',
    'Session.Edit.Modal.Button.Leave':'Annulla la mia sessione',
    'Users.TableModal.SSOIDPlaceHolder':'ID SSO',
    'Users.Table.Modal.SSOID':'ID SSO',
    'Dashboard.SchedulingInfo.Filter.IndustriesAll':'Settore',
    'Dashboard.SchedulingInfo.Filter.UsedSoftwareVersion':'Software',
    'Dashboard.SchedulingInfo.MainHeader':'Informazioni sul programma',
    'Dashboard.SchedulingInfo.TableHeader':'Dati delle informazioni sulla programmazione',
    'RestorePassword.ResetPassword':'Reimposta la password',
    'RestorePassword.EmailHint':'Inserisci la tua e-mail',
    'Dashboard.ProjectUtilization.MainHeader':'Utilizzo dei progetti',
    'Settings.Config.ProjectAndScenario.AllowMursionMagic':'Tipo Mursion Magic',
    'Dashboard.SessionLearners.MainHeader':'Studenti della sessione',
    'MursionPortal.Table.ScrollMessage':'scorri a sinistra per visualizzare le colonne aggiuntive',
    'Dashboard.ProjectsActivity.MainHeader':'Attività del progetto',
    'MursionPortal.Table.ColumnHeader.ProjectId':'ID contratto',
    'MursionPortal.Button.ClearAll':'Cancella tutto',
    'MursionPortal.Dashboard.Chart.Registration':'Registrazione utente',
    'MursionPortal.Dashboard.Chart.InviteAll':'Invita tutti',
    'MursionPortal.Dashboard.Chart.Unregistered':'Non registrati',
    'MursionPortal.Dashboard.Chart.Registered':'Registrati',
    'MursionPortal.Dashboard.Chart.Login':'Accesso',
    'MursionPortal.Dashboard.Chart.NotLoggedIn':'Accesso non effettuato',
    'MursionPortal.Dashboard.Chart.LoggedIn':'Accesso effettuato',
    'MursionPortal.Dashboard.Chart.Scheduling':'Utenti programmati',
    'MursionPortal.Dashboard.Chart.NotScheduled':'Non programmati',
    'MursionPortal.Dashboard.Chart.Simulations':'Simulazioni',
    'MursionPortal.Dashboard.Chart.LateCancellations':'Cancellazioni in ritardo',
    'MursionPortal.Dashboard.Chart.Completed':'Completate' ,
    'MursionPortal.Dashboard.Chart.Errors':'Errori',
    'MursionPortal.Dashboard.Chart.Scheduled':'Programmati',
    'MursionPortal.Dashboard.Chart.Tooltip.Registered':'Utenti che sono stati invitati o utenti SSO forniti e che hanno effettuato l\'accesso.',
    'MursionPortal.Dashboard.Chart.Tooltip.Unregistered':'Utenti che sono stati invitati ma non hanno ancora effettuato l\'accesso o utenti SSO forniti e che non hanno ancora effettuato l\'accesso.',
    'MursionPortal.Dashboard.Chart.Tooltip.NotScheduled':'Utenti SSO registrati/forniti che non hanno ancora programmato una simulazione',
    'MursionPortal.Dashboard.Chart.Tooltip.Scheduled':'Utenti SSO registrati/forniti che hanno programmato almeno una simulazione',
    'MursionPortal.Label.ProjectId':'ID contratto',
    'MursionPortal.Label.SoftwareType':'Tipo',
    'MursionPortal.Label.SharingOrDownloading':'Non consentire la condivisione o il download',
    'Settings.SSO.Modal.EmployeeIDClaimName':'Nome richiesta ID dipendente',
    'Settings.SSO.Modal.LocationClaimName':'Nome richiesta posizione',
    'Settings.SSO.Modal.DepartmentClaimName':'Nome richiesta dipartimento',
    'Settings.SSO.Modal.PhoneClaimName':'Nome richiesta telefono',
    'Settings.SSO.Modal.TitleClaimName':'Nome richiesta titolo',
    'Settings.SSO.Modal.TimezoneClaimName':'Nome richiesta fuso orario',
    'Settings.SSO.Modal.LanguageClaimName':'Nome richiesta lingua',
    'MursionPortal.ProjectId':'ID contratto',
    'MursionPortal.AltText.MursionPortal':'Portale Mursion',
    'MursionPortal.Message.ClickToOpenApplicationDefault':'Fare clic qui sotto per partecipare alla simulazione',
    'MursionPortal.Message.ClickToOpenApplicationDesktopWeb':'Fare clic sul pulsante qui sotto per accedere alla simulazione tramite l\'applicazione desktop Mursion',
    'MursionPortal.Message.OpenWebApplication':'Puoi anche accedere alla tua simulazione su Mursion Magic, la nostra applicazione basata sul web',
    'MursionPortal.Button.OpenMursionWeb':'Avvia Mursion Magic',
    'MursionPortal.Message.Or':'OPPURE',
    'MursionPortal.Message.PortalHeadingText':'Simulazioni di formazione EQ',
    'ITManager.TechRestriction.Table.CreateButton':'Aggiungi restrizione',
    'ITManager.TechRestriction.EditDialog.TagName':'Nome del tag',
    'ITManager.TechRestriction.EditDialog.Title':'Modifica restrizione tecnica',
    'ITManager.TechRestriction.CreateDialog.Title':'Nuova restrizione tecnica',
    'MursionPortal.DateTimePicker.StartDate':'Data di inizio',
    'MursionPortal.DateTimePicker.EndDate':'Data di fine',
    'MursionPortal.DateTimePicker.TimeNotAvailable':'L\'orario selezionato non è disponibile',
    'ITManager.TechRestriction.ViewDialog.Title':'Visualizza restrizione tecnica',
    'MursionPortal.DateTimePicker.Timezone':'Fuso orario',
    'MursionPortal.Text.Team':'team',
    'MursionPortal.Text.Industry':'settore',
    'MursionPortal.Text.Environment':'ambiente',
    'MursionPortal.Text.Avatar':'avatar',
    'MursionPortal.Status.Capitalized.Upcoming':'IN ARRIVO',
    'MursionPortal.Status.Capitalized.Undefined':'NON DEFINITO',
    'MursionPortal.Status.Capitalized.Orphan':'ORFANA',
    'MursionPortal.Status.Capitalized.Waif':'ABBANDONATA',
    'MursionPortal.Status.Capitalized.Reserved':'RISERVATA',
    'MursionPortal.Status.Capitalized.Booked':'PRENOTATA',
    'MursionPortal.Status.Capitalized.Pending':'IN ATTESA',
    'MursionPortal.Status.Capitalized.Running':'IN ESECUZIONE',
    'MursionPortal.Status.Capitalized.Missed':'PERSA',
    'MursionPortal.Status.Capitalized.NeedsReview':'NECESSITA_REVISIONE',
    'MursionPortal.Status.Capitalized.Reviewed':'RIVISTA',
    'MursionPortal.Status.Capitalized.Cancelled': 'ANNULLATA',
    'MursionPortal.Status.Capitalized.LicenseCancelled':'ANNULLATA',
    'MursionPortal.Status.Capitalized.Error':'ERRORE',
    'MursionPortal.Status.Capitalized.SimError': 'ERROR',// TODO add translations
    'MursionPortal.Status.Capitalized.Offline':'OFFLINE',
    'MursionPortal.Status.Abbreviation.PendingNoLearners':'IA',
    'MursionPortal.Status.Abbreviation.PendingNoParticipants':'IE',
    'MursionPortal.Status.Abbreviation.Swap':'S',
    'MursionPortal.Status.Abbreviation.Prebooked':'PP',
    'MursionPortal.Status.Abbreviation.Booked':'P',
    'MursionPortal.Status.Abbreviation.Active':'A',
    'MursionPortal.Status.Abbreviation.Completed':'C',
    'MursionPortal.Status.Abbreviation.Missed':'PE',
    'MursionPortal.Status.Abbreviation.NeedsReview':'NR',
    'MursionPortal.Status.Abbreviation.Error':'E',
    'Settings.SSO.Table.NoSsoConfigurationsFound':'Non sono state trovate configurazioni SSO.',
    'MursionPortal.Label.SelfReviewTracker':'TRACKER DI AUTOVALUTAZIONE',
    'MursionPortal.ToggleButton.All':'tutti',
    'MursionPortal.Label.PerformanceTracker':'TRACKER DELLE PRESTAZIONI',
    'MursionPortal.ToggleButton.TurnTaking':' PRESA DI TURNO',
    'MursionPortal.Tooltip.Collaborative':'Questa è una misurazione del tempo in cui sia tu che l\'avatar (o gli avatar) vi siete alternati senza interrompervi a vicenda durante la conversazione.',
    'MursionPortal.Tooltip.Disruptive':'Questa è una misurazione del tempo in cui tu e l\'avatar (o gli avatar) vi siete interrotti a vicenda durante la conversazione.',
    'MursionPortal.Tooltip.Speaking':'Questa è una misurazione del tempo che hai trascorso parlando con l\'avatar (o gli avatar) durante la conversazione.',
    'MursionPortal.Tooltip.Listening':'Questa è una misura del tempo che hai passato ad ascoltare l\'avatar (o gli avatar) durante la conversazione.',
    'MursionPortal.Tooltip.MiScore':'La tua efficacia sociale durante la conversazione.',
    'MursionPortal.Tooltip.Percentile':'includi una descrizione di ciò che significa da qualche parte nella UI',
    'MursionPortal.Label.MiScore':'Punteggio MI',
    'MursionPortal.Label.Percentile':'percentile',
    'MursionPortal.Label.NotEnoughData':'Non ci sono abbastanza dati',
    'MursionPortal.Label.Listening':'Ascolta',
    'MursionPortal.Label.Speaking':'Parla',
    'MursionPortal.Label.Collaborative':'Collaborativo',
    'MursionPortal.Label.Disruptive':'Dirompente',
    'MursionPortal.ToggleButton.LocalLeaderboard':'Classifica locale',
    'MursionPortal.ToggleButton.GlobalLeaderboard':'Classifica globale',
    'MursionPortal.ToggleButton.DeepInsights':'Insight profondi',
    'MursionPortal.ErrorMessage.DataBeingProcessed':'I dati di analisi della sessione sono in corso di elaborazione. I dati verranno visualizzati nella pagina al termine dell\'elaborazione.',
    'MursionPortal.ErrorMessage.UnsupportedFormat':'L\'analisi della sessione è vuota o ha un formato non supportato.',
    'MursionPortal.Label.Positive':'Positivo',
    'MursionPortal.Label.Negative':'Negativo',
    'MursionPortal.Label.Neutral':'Neutrale',
    'MursionPortal.Header.Tracking':'Tracciamento',
    'MursionPortal.Button.ReadMore':'Leggi di più',
    'MursionPortal.Button.Hide':'Nascondi',
    'MursionPortal.Title.Interchange':'Interscambio. Conversazione naturale e prese di turno senza interventi o interiezioni',
    'MursionPortal.Title.InterventionByYou':'Tuo intervento. Interrompere con successo qualcuno che sta parlando, impedendogli di continuare',
    'MursionPortal.Title.InterventionByAvatars':'Intervento degli Avatar. Interrompere con successo qualcuno che sta parlando, impedendogli di continuare',
    'MursionPortal.Title.InterjectionByYou':'Tua interiezione. Tentare di interrompere qualcuno che sta parlando senza avere successo',
    'MursionPortal.Title.InterjectionByAvatars':'Interiezione degli Avatar. Tentare di interrompere qualcuno che sta parlando senza avere successo',
    'MursionPortal.Title.Pauses':'Pause. Più lunghe di 0,2 s e meno di 0,75s',
    'MursionPortal.Label.VoiceSelf':'Voce di sé',
    'MursionPortal.Label.VoiceOthers':'Voce di altri',
    'MursionPortal.Label.Avatars':'Avatar',
    'MursionPortal.Label.You':'Tu',
    'MursionPortal.ColumnHeader.User':'Utente',
    'MursionPortal.ColumnHeader.Percentile':'Percentile',
    'MursionPortal.ColumnHeader.ScenariosCompleted':'Scenari completati',
    'MursionPortal.Message.LeaderboardsUpdated':'Le classifiche saranno aggiornate quando più persone completeranno le loro simulazioni.',
    'MursionPortal.Message.CheckBack':'Per favore, torna a controllare di tanto in tanto per vedere a che punto sei!',
    'MursionPortal.Label.SimStart':'Inizio sim',
    'MursionPortal.Label.SimEnd':'Fine sim',
    'MursionPortal.Tooltip.Summary.Speaking':'Tempo trascorso a parlare con l\'avatar (o gli avatar) durante l\'intera conversazione',
    'MursionPortal.Tooltip.Summary.Listening':'Tempo trascorso ad ascoltare l\'avatar (o gli avatar) durante l\'intera conversazione',
    'MursionPortal.Capitalized.NotAvailable':'N/D',
    'MursionPortal.ColumnHeader.Trait':'Tratto',
    'MursionPortal.ColumnHeader.Me':'Io',
    'MursionPortal.ColumnHeader.OthersAvg':'Altri (media)',
    'MursionPortal.TooltipText.Speaking':'Tu parli di più del {percentage} delle persone.',
    'MursionPortal.TooltipText.Listening':'Tu ascolti di più del {percentage} delle persone.',
    'MursionPortal.TooltipText.Collaborative':'Tu sei più collaborativo del {percentage} delle persone',
    'MursionPortal.TooltipText.Disruptive':'Tu interrompi più del {percentage} delle persone.',
    'MursionPortal.Label.FeedbackValue':'Valore del feedback',
    'MursionPortal.Button.AnalyticsData':'Dati analitici',
    'MursionPortal.Button.RawAnalyticsData':'Dati analitici grezzi',
    'MursionPortal.Header.SocialEffectiveness':'Efficacia sociale',
    'MursionPortal.Label.Current':'Attuale',
    'MursionPortal.Header.ArticulationRate':'Tasso di articolazione',
    'MursionPortal.Label.Average':'Media',
    'MursionPortal.Header.Conversation':'Conversazione',
    'MursionPortal.Header.SpeakingListening':'Parlare / Ascoltare',
    'MursionPortal.Header.CumulativeTurnTaking':'Presa di turno cumulativa',
    'MursionPortal.Header.InstantaneousTurnTaking':'Presa di turno istantanea',
    'MursionPortal.ColumnHeader.ScenarioTemplate':'TEMPLATE_SCENARIO',
    'MursionPortal.ColumnHeader.Provider':'PROVIDER',
    'MursionPortal.Label.AbbreviatedMinute':'m',
    'MursionPortal.Header.Mursion':'<code>M</code>ursion',
    'MursionPortal.Header.Index':'<code>I</code>ndice',
    'MursionPortal.Header.Score':'Punteggio',
    'MursionPortal.visibilityHidden.Selected':'selezionato',
    'MursionPortal.AriaLabel.CloseTechSupport':'chiudere il supporto tecnico',
    'MursionPortal.Monitoring':'Monitoraggio',
    'MursionPortal.Monitoring.User.FirstLetter':'U',
    'MursionPortal.Monitoring.Peer.Label':'PEER TO PEER',
    'MursionPortal.Monitoring.Peer.NoUser':'Seleziona l\'utente per vedere i dettagli',
    'MursionPortal.Monitoring.Sessions.Total':'Sessioni totali',
    'MursionPortal.Monitoring.Sessions.Displayed':'Sessioni ML3 (visualizzate)',
    'MursionPortal.Monitoring.Sessions.NotDisplayed':'Sessioni non ML3',
    'MursionPortal.Monitoring.Card.StartTime':'Ora di inizio',
    'MursionPortal.Monitoring.Notification.NewSession':'È stata aggiunta una nuova sessione',
    'MursionPortal.ClickRefresh':'Fai clic su "Aggiorna" per visualizzare le modifiche',
    'MursionPortal.AriaLabel.DraftScenarioSuperScript':'Bozza superscript scenario {statusConfiguration}',
    'MursionPortal.AriaLabel.FinalizedScenarioSuperScript':'Definitivo superscript scenario {statusConfiguration}',
    'MursionPortal.AriaLabel.StartedScenarioSuperScript':'Superscript scenario avviato {statusConfiguration}',
    'MursionPortal.AriaLabel.FinishedScenarioSuperScript':'Superscript scenario finito {statusConfiguration}',
    'MursionPortal.AriaLabel.ArchivedScenarioSuperScript':'Superscript scenario archiviato {statusConfiguration}',
    'Calendar.Button.ScheduleEventON':'Programma l\'evento il {thisDate}',
    'Calendar.Button.CantScheduleON':'Impossibile programmare l\'evento il {thisDate}',
    'MursionPortal.AriaLabel.ChooseFileButton':'scegliere il pulsante file',
    'MursionPortal.Label.VisibilityHidden.FileUploaded':'File caricato',
    'MursionPortal.VisibilityHidden.EntityAdded':'Entità aggiunta',
    'MursionPortal.VisibilityHidden.EntityRemoved':'Entità rimossa',
    'MursionPortal.Legend.VisibilityHidden.StationTypeHomeOffice':'Tipo di postazione (ufficio, casa)',
    'MursionPortal.Legend.VisibilityHidden.HoursFullTimePartTime':'Ore (full time, part time)',
    'MursionPortal.AriaLabel.ShowTechSupport':'mostra il supporto tecnico',
    'MursionPortal.Monitoring.Card.UntilTheEnd':'Tempo rimanente',
    'MursionPortal.Monitoring.Card.OverTime':'Straordinari',
    'MursionPortal.Title.DemandBasedSessionScheduling':'Programmazione delle sessioni basate sulla richiesta',
    'MursionPortal.CheckboxLabel.SchedulingByDemand':'Programmazione in base alla richiesta',
    'MursionPortal.CheckboxLabel.ProjectTimezone':'Fuso orario del progetto',
    'MursionPortal.CheckboxLabel.DemandTime':'Tempo di richiesta',
    'MursionPortal.Label.DemandTimeStart':'Inizio del tempo di richiesta',
    'MursionPortal.Label.DemandTimeEnd':'Fine del tempo di richiesta',
    'MursionPortal.Label.DemandWindow':'Finestra della richiesta',
    'MursionPortal.Project.Days':'Giorno/i',
    'MursionPortal.MyProfile.Hover.Profile':'profilo {userName}',
    'Mursion.Portal.SessionUserStatus.Connected':'CONNESSO',
    'Mursion.Portal.SessionUserStatus.Late':'IN RITARDO',
    'Mursion.Portal.SessionUserStatus.Immersing':'STA COMINCIANDO',
    'Mursion.Portal.SessionUserStatus.Left':'HA ABBANDONATO',
    'Mursion.Portal.SessionUserStatus.Declined':'HA RIFIUTATO',
    'MursionPortal.DemandBasedScheduling.SubmitPage.ThankYou':'Grazie!',
    'MursionPortal.DemandBasedScheduling.SubmitPage.Message':'Stiamo lavorando per finalizzare la tua simulazione in base alle fasce orarie che hai fornito. Aspettati una nostra e-mail con maggiori dettagli. Ci vediamo presto!',
    'MursionPortal.DemandBasedScheduling.SubmitPage.GoToDashboard':'Vai a La mia dashboard',
    'MursionPortal.DemandBasedScheduling.ScheduleASession':'Programma una sessione',
    'MursionPortal.DemandBasedScheduling.Details':'DETTAGLI',
    'MursionPortal.DemandBasedScheduling.Slot':'FASCIA ORARIA {count}',
    'MursionPortal.DemandBasedScheduling.PickDateAndTime':'Scegli la data + l\'ora da impostare',
    'MursionPortal.DemandBasedScheduling.Button.Set':'IMPOSTA',
    'MursionPortal.DemandBasedScheduling.Button.Clear':'CANCELLA',
    'MursionPortal.DemandBasedScheduling.Jumbotron.Text':'I dettagli dello scenario verranno caricati una volta effettuata la selezione',
    'MursionPortal.AriaDescribedby.StartDate':'Data di inizio: premere il tasto punto interrogativo per ottenere le scorciatoie da tastiera per cambiare le date.',
    'MursionPortal.AriaDescribedby.EndDate':'Data di fine: premere il tasto punto interrogativo per ottenere le scorciatoie da tastiera per cambiare le date.',
    'MursionPortal.VisibilityHidden.Footer':'Piè di pagina',
    'MursionPortal.VisibilityHidden.Header':'Intestazione',
    'MursionPortal.AriaLabel.FullScreen':'schermo intero',
    'MursionPortal.AriaLabel.ClientNameEventsDisplayed':'Eventi del cliente {clientName} visualizzati sul calendario',
    'MursionPortal.AriaLabel.SideNavigationToggle':'Switch di navigazione laterale',
    'MursionPortal.AriaLabel.AddTags':'Aggiungi tag',
    'MursionPortal.VisibilityHidden.Navigation':'Navigazione',
    'MursionPortal.AriaLabel.SortByDescendingOrderButton':'pulsante ordina in ordine decrescente',
    'MursionPortal.AriaLabel.SortByAscendingOrderButton':'pulsante ordina in ordine crescente',
    'MursionPortal.VisibilityHidden.ProjectsTabSelected':'Scheda Progetti selezionata',
    'MursionPortal.VisibilityHidden.TeamsTabSelected':'Scheda Team selezionata',
    'MursionPortal.VisibilityHidden.SessionsTabSelected':'Scheda Sessioni selezionata',
    'MursionPortal.VisibilityHidden.UsersTabSelected':'Scheda Utenti selezionata',
    'MursionPortal.Error.PageNotFound':'404 Pagina non trovata',
    'MursionPortal.Text.PageNotFound':'Pagina non trovata. Controlla di avere i permessi per visualizzare la pagina se il link è corretto.',
    'MursionPortal.Text.Success':'Successo!',
    'MursionPortal.Text.YouAreNowSignedIn':'Ora sei entrato nel software di simulazione Mursion.',
    'MursionPortal.Text.PleaseReturnToDesktop':'Torna all\'applicazione desktop cliccando {linebreak} sull\'icona \M\ nella barra delle applicazioni del tuo computer (Windows) o nel Dock (Mac).',
    'MursionPortal.Client.EmailSettings.Header.DemandBased':'Basate sulla richiesta',
    'MursionPortal.Client.EmailSettings.TimeSlotsReceived':'Fasce orarie ricevute',
    'MursionPortal.Label.Past':'Passate',
    'MursionPortal.Monitoring.SessionsSummary.Preparation':'Preparazione',
    'MursionPortal.Monitoring.SessionsSummary.Normal':'Normale',
    'MursionPortal.Monitoring.SessionsSummary.Error':'Errore',
    'MursionPortal.Monitoring.SessionsSummary.Issue':'Problema',
    'MursionPortal.Monitoring.SessionsSummary.NotLoggedIn':'Accesso non effettuato',
    'MursionPortal.Monitoring.Legend.Label':'Legenda',
    'MursionPortal.Monitoring.Legend.Dashboard':'Dashboard',
    'MursionPortal.Label.SimSpecialist':'Specialista della Sim',
    'MursionPortal.Monitoring.SessionsSummary.ConnectedToPeers':'Collegato ai colleghi',
    'Requests.Table.CertifiedScenarios':'Scenari certificati',
    'Requests.Table.UncertifiedScenarios':'Scenari non certificati',
    'Requests.Table.NoUpComingDemands':'Non hai richieste basate sulla domanda con i criteri di cui sopra.',
    'Requests.Table.DeclineReason':'Rifiuta - Seleziona il motivo',
    'Requests.Table.UnAvailable':'Non disponibile',
    'Requests.Table.NotPrepared':'Non pronto',
    'Requests.Table.Other':'Altro',
    'Requests.Table.Availability':'Seleziona disponibilità',
    'Requests.Table.Declined':'Ha rifiutato',
    'Requests.Table.SuccessfullySent':'Richiesta inviata con successo',
    'Requests.Table.RequestCertificate':'Richiedi certificato',
    'Requests.Table.DeclineReasonText':'Motivo del rifiuto',
    'Requests.Table.AvailabilitySlot':'Disponibilità fasce orarie',
    'Requests.Table.DemandBased':'Basate sulla richiesta',
    'MursionPortal.Setting.Integrations':'Integrazione',
    'MursionPortal.Setting.Integrations.Title':'Seleziona un\'integrazione',
    'MursionPortal.Setting.Integrations.Header.PlatformConfigurations':'Configurazioni della piattaforma',
    'MursionPortal.Button.AddConfigurations':'Aggiungi configurazioni',
    'Settings.LTI.PlaceHolder.EnterConfigName':'Inserisci il nome della configurazione',
    'Settings.LTI.Table.ColumnHeader.DeploymentID':'ID distribuzione',
    'Settings.LTI.Table.ColumnHeader.Version':'Versione',
    'Settings.LTI.Table.Caption':'elenco di configurazione lti',
    'Settings.LTI.Table.Hover.ViewEditLti':'Visualizza/Modifica Lti',
    'Settings.LTI.Table.NoLtiConfigFound':'Nessuna configurazione LTI trovata',
    'MursionPortal.EditLtiModal.Button.DeleteLti':'Elimina configurazione',
    'MursionPortal.EditLtiModal.Button.SaveLtiConfig':'Salva configurazione',
    'MursionPortal.Lti.NewLTIConfig':'Nuova configurazione LTI',
    'MursionPortal.Lti.CreateLTIConfig':'Crea configurazione LTI',
    'Settings.LTI.Modal.PlatformIssuerIdentifier':'Identificatore emittente piattaforma',
    'Settings.LTI.Modal.PublicKeysetEndpoint':'Endpoint keyset pubblico',
    'Settings.LTI.Modal.DeploymentId':'ID distribuzione',
    'Settings.LTI.Modal.RolesMapping':'Mappatura ruoli',
    'Settings.LTI.Modal.RolesMapping.PortalRole':'Ruolo portale',
    'Settings.LTI.Modal.RolesMapping.LTIRole':'Ruolo LTI',
    'MursionPortal.ErrorMessage.LtiIdMustBeSpecified':'L\'Id LTI deve essere specificata',
    'MursionPortal.Setting.Integrations.LTIVersion':'Versione LTI',
    'MursionPortal.Dialog.CreateLTIConfiguration.SuccessMessage':'La configurazione LTI è stata creata con successo.',
    'MursionPortal.DemandBasedScheduling.TimeBlockUnavailableReserved':'Blocco orario non disponibile: riservato per un\'altra simulazione programmata.',
    'Requests.Table.ViewAvailability':'Visualizza disponibilità',
    'Settings.LTI.Modal.DeletionConfirmationText':'Sei sicuro di voler cancellare questa mappatura dei ruoli?',
    'Settings.ScenarioBank.Modal.InactiveCertification':'Certificazione inattiva',
    'Settings.ScenarioBank.Modal.NoInactiveSimSpecialist':'Nessuno specialista della Sim inattivo',
    'Settings.ScenarioBank.Modal.EditInactiveCertification':'Modifica certificazione inattiva',
    'Settings.ScenarioBank.Modal.NoInactiveCertification':'Nessuna certificazione inattiva',
    'MursionPortal.RequestStatus.All':'Stato della richiesta: Tutti',
    'MursionPortal.RequestStatus.Accepted':'Stato della richiesta: Accettata',
    'MursionPortal.RequestStatus.Declined':'Stato della richiesta: Rifiutata',
    'MursionPortal.RequestStatus.Pending':'Stato della richiesta: In sospeso',
    'Requests.Table.RequestCertification':'Richiedi certificazione',
    'Requests.Table.RequestTime':'Tempo di richiesta',
    'Requests.Table.ScheduleType':'Tipo di programma',
    'Requests.Table.AcceptTime':'Accetta orario',
    'Requests.Table.Scheduling':'Programmazione',
    'Requests.Table.FulfilledIn':'Compiuto in',
    'MursionPortal.DemandBasedScheduling.Timezone':'Fuso orario',
    'MursionPortal.DemandBasedScheduling.Timezone.Tooltip':'Vai nelle impostazioni del tuo profilo per cambiare il fuso orario.',
    'MursionPortal.Results.Found':'{count} risultati trovati',
    'MursionPortal.Header.VisibilityHidden.ClientUsers':'Utenti clienti',
    'MursonPortal.SchedulingType.Auto':'AUTOMATICO',
    'MursonPortal.SchedulingType.Manual':'MANUALE',
    'MursionPortal.ScheduleTypeSelector.All':'Programma: Tutti',
    'MursionPortal.ScheduleTypeSelector.Auto':'Programma: Automatico',
    'MursionPortal.ScheduleTypeSelector.Manual':'Programma: Manuale',
    'MursionPortal.ScheduleTypeSelector.NA':'Programma: N/D',
    'Integrations.LTI.AppId':'Id App',
    'Integrations.LTI.ConfigurationId':'Id di configurazione',
    'Settings.LTI.ModalHeader.EditLtiConfiguration':'Modifica configurazione LTI',
    'Settings.LTI.Modal.DeletionConfigConfirmationText':'Sicuro di voler cancellare questa configurazione LTI?',
    'MursionPortal.Rescheduling.SubmitPage.Message':'Ti invieremo un\'e-mail a breve con maggiori dettagli. Ci vediamo presto!',
    'MursionPortal.Rescheduling.RescheduleSimulation':'Riprogramma la simulazione',
    'MursionPortal.Rescheduling.SlotSelectionMessage':'Siamo spiacenti che tu non abbia potuto effettuare la simulazione. Scegli un giorno e un\'ora che vanno bene per te!',
    'MursionPortal.IVSBrowserWarning.Safari17.Message1': 'The browser version you are currently using is not supported for your Mursion session',  // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Message2': 'When joining your Mursion simulation, use one of these browsers:',  // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Firefox': "Firefox",  // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Chrome': "Chrome", // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Chrome.Subtitle': "Recommended", // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Edge': "Edge", // TODO: add translations
    'MursionPortal.Result.Found':'{count} risultato trovato',
    'MursionPortal.Project.Rescheduling':'Riprogrammazione',
    'Session.Edit.Modal.MissedSession':'SESSIONE PERSA',
    'Session.Edit.Modal.NoLongerRequired':'non è più necessaria',
    'Session.Edit.Modal.TechDifficulties':'ho difficoltà tecniche',
    'Session.Edit.Modal.Other':'altro',
    'Session.Edit.Modal.CancelBecause':'Sì, annulla la sessione perché',
    'Session.Edit.Modal.CancelSimulation':'Annulla simulazione',
    'MursionPortal.UserConsent.Heading':'Permessi di registrazione',
    'MursionPortal.UserConsent.Title':'Vuoi una registrazione di questa sessione di simulazione per il tuo percorso di apprendimento?',
    'MursionPortal.UserConsent.Description':'Se scegli "Accetto", il software Mursion elaborerà la tua simulazione dal vivo e realizzerà una registrazione video della tua sessione di simulazione nel portale del software Mursion per tua revisione. Tieni presente che, su richiesta del cliente che ha concesso in licenza il software Mursion per tuo conto, una copia della sessione di simulazione registrata (o delle sessioni registrate) verrà condivisa con un coach, un mentore o un revisore selezionato dal cliente. Tutti i revisori sono contrattualmente obbligati a seguire l\'<code>Informativa sulla privacy di Mursion</code>, a meno che tu non abbia concordato condizioni sulla privacy diverse. Salvo quanto richiesto dalla legge vigente, Mursion non distribuirà o divulgherà la tua registrazione video senza il tuo permesso. Mursion utilizzerà i dati resi anonimi provenienti dall\'utilizzo del software per migliorare la propria tecnologia di simulazione.',
    'MursionPortal.UserConsent.IConsent':'Accetto',
    'MursionPortal.UserConsent.IDoNotConsent':'Non accetto',
    'MursionPortal.RecordingLinkPermisssion.Title':'Permessi di registrazione',
    'MursionPortal.RecordingLinkPermisssion.DescriptionOne':'Vuoi ricevere una registrazione di questa sessione?',
    'MursionPortal.RecordingLinkPermisssion.DescriptionTwo':'In tal caso, controlla la tua casella di posta elettronica e verifica il tuo indirizzo e-mail. Ti verrà inviato un link domani, se disponibile.',
    'MursionPortal.PublicSession.PageTitle':'Inserisci i tuoi dettagli per partecipare a una simulazione',
    'MursionPortal.PublicSession.Form.label.FirstName':'Nome',
    'MursionPortal.PublicSession.Form.label.LastName':'Cognome',
    'MursionPortal.PublicSession.Form.label.Email':'E-mail (facoltativo)',
    'PublicSession.Form.Button.Join':'Partecipa',
    'MursionPortal.PublicSession.Form.Placeholder.FirstName':'Il tuo nome',
    'MursionPortal.PublicSession.Form.Placeholder.LastName':'Il tuo cognome',
    'MursionPortal.PublicSession.Form.Placeholder.Email':'name@provider.com',
    'MursionPortal.PublicSession.Form.Placeholder.HaveAccount':'Hai già un account?',
    'MursionPortal.PublicSession.Form.Placeholder.LogInHere':'Accedi qui',
    'Session.Edit.Modal.ToolTip.Google':'Aggiungi l\'evento al tuo calendario Google. Conferma l\'autorizzazione se necessario',
    'Session.Edit.Modal.ToolTip.OutlookIcalOther':'Fai clic per scaricare l\'evento sul desktop e per aggiungerlo all\'app del calendario',
    'Session.Edit.Modal.SimSpecialistAssigned':'Specialista della Sim assegnato',
    'Session.Edit.Modal.SessionSimSpecialistField.AddSIMSpecialist':'Aggiungi specialista della SIM',
    'Session.Edit.Modal.DemandBasedTimeSlots':'Fasce orarie basate sulla domanda',
    'Session.Edit.Modal.Reschedule':'Riprogramma',
    'Session.Edit.Modal.AddLearner':'Aggiungi studente',
    'MursionPortal.Label.SearchOrSelectLearners':'Cerca o seleziona studente/i',
    'MursionPortal.Client.EmailSettings.Header.Rescheduling':'Riprogrammazione',
    'MursionPortal.Client.EmailSettings.RescheduleMissedSession':'Riprogrammare la sessione persa',
    'Session.Edit.Modal.OtherPrioritiesAtWork':'altre priorità a lavoro',
    'MursionPortal.Modal.Header.Sorry':'Scusa!',
    'MursionPortal.Modal.UnschedulableSessionMsg':'La sessione non è programmabile.',
    'MursionPortal.Rescheduling.Slot.NoLongerAvailable':'L\'ora selezionata non è più disponibile, seleziona un\'opzione diversa.',
    'MursionPortal.EmailVerificationPage.Success':'Verifica e-mail riuscita!',
    'MursionPortal.EmailVerificationPage.SuccessDesc':'La tua e-mail è stata verificata con successo',
    'MursionPortal.EmailVerificationPage.Error':'Verifica e-mail non riuscita!',
    'MursionPortal.EmailVerificationPage.ErrorDesc':'Qualcosa è andato storto. Riprova per verificare la tua e-mail',
    'Settings.Config.Heading.SplashPageAttendance':'PARTECIPAZIONE',
    // 'Clients.Modal.Label.ShowSplashPage':'Partecipazione utente non registrato/Consenso alla registrazione',
    // 'Clients.Modal.Label.ShowSplashPage.ToolTip':'Acquisisci partecipazione e consenso alla registrazione degli utenti non registrati nelle simulazioni ML3z/Meet.',
    // 'Settings.Config.Invitation.ShowSplashPage':'Acquisisci partecipazione e consenso alla registrazione degli utenti non registrati nelle simulazioni ML3z/Meet.',
    'Settings.Config.External.Users':'(Può essere disabilitato nella configurazione del cliente)',
    'Settings.Config.Invitation.ExternalUser':'Per la partecipazione di utenti esterni',
    'MursionPortal.Scenario.Vignette.ButtonText.ShowMore.SessionDetails':'dettagli della sessione',
    'MursionPortal.DemandBasedScheduling.ProjectDropdown.Placeholder':'Seleziona un progetto',
    'MursionPortal.DemandBasedScheduling.ScenarioDropdown.Placeholder':'Seleziona uno scenario',
    'Session.Edit.Modal.Label.SearchOrSelectASimSpecialist':'Cerca o seleziona uno specialista della SIM',
    'MursionPortal.Button.Exclamation.GotIt':'Ho capito!',
    'MursionPortal.Modal.UnavailableSlotMsg':'L\'ora selezionata adesso non è disponibile. Scegli una nuova fascia oraria.',
    'MursionPortal.SimAttendance.NoLongerAvailable':'Non puoi più modificare la partecipazione per questa simulazione.',
    'MursionPortal.SimAttendance.Midnight':'È necessario completare la partecipazione entro la mezzanotte di ogni giorno. La partecipazione per questa simulazione verrà impostata automaticamente sugli stati del sistema. Si prega di contattare il Supporto se si è verificato qualcosa di anomalo.',
    'MursionPortal.SimAttendance.Instructions':'Istruzioni',
    'MursionPortal.SimAttendance.Attendee':'Partecipante',
    'MursionPortal.SimAttendance.Tooltip.Missed':'Lo studente non si è mai connesso alla simulazione.',
    'MursionPortal.SimAttendance.Tooltip.Declined':'Lo studente si è connesso alla simulazione ma ha rifiutato di partecipare ed è uscito prima di aver completato almeno il 50% della simulazione.',
    'MursionPortal.SimAttendance.Add.Attendee':'Aggiungi partecipante',
    'Session.Edit.Modal.EditAttendance':'Modifica partecipazione',
    'Session.Edit.Modal.CompleteAttendance':'Completa partecipazione',
    'MursionPortal.SimAttendance.Late':'In ritardo',
    'MursionPortal.SimAttendance.Left':'Ha abbandonato',
    'MursionPortal.Instructions.First':'Seleziona uno stato di partecipazione per ogni partecipante (passa il mouse sopra lo stato per saperne di più). Se uno studente non è elencato, fai clic sulla',
    'MursionPortal.Instructions.Second':'funzione in basso a sinistra.',
    'MursionPortal.TableColumn.SimsAction':'Azione della simulazione',
    'Session.Edit.Modal.Label.SelectTrainer':'Cerca o seleziona un trainer',
    'MursionPortal.Project.DemandSlots':'Richiedi fascia oraria',
    'MursionPortal.CompanyCard.InviteOperations':'Invita operazioni',
    'MursionPortal.CompanyCard.Modal.Header.NewOperations':'Nuove operazioni',
    'Settings.Config.Invitation.ShowContractFeature':'Consenti l\'aggiunta di contratti',
    'Settings.Config.Contract.Description':'(per il monitoraggio dello stato di avanzamento del contratto)',
    'MursionPortal.Contract.Edit':'Modifica',
    'MursionPortal.Contract.ContractName':'Nome contratto',
    'MursionPortal.Contract.ContractID':'ID contratto',
    'MursionPortal.Contract.ContractID.PlaceHolder':'Inserisci l\'ID contratto da Hubspot',
    'MursionPortal.Contract.StartAndEndDates':'Date di inizio e fine contratto',
    'MursionPortal.Contract.Notes.PlaceHolder':'Immettere altre informazioni da mostrare solo ai CSM e ai BDD.',
    'MursionPortal.Contract.LineItem.Add':'Aggiungi una voce del Contratto',
    'MursionPortal.Contract.LineItem.Add.Instruction':'Puoi aggiungere più voci della simulazione. Tutti gli altri tipi possono avere una sola voce.',
    'MursionPortal.Contract.LineItemType':'Tipo di voce',
    'MursionPortal.Contract.LineItemType.PlaceHolder':'Seleziona il tipo di voce',
    'MursionPortal.Contract.Quantity':'Quantità',
    'MursionPortal.Contract.Quantity.PlaceHolder':'Inserisci quanità',
    'MursionPortal.Contract.DeliveryMode.PlaceHolder':'Seleziona modalità di fornitura',
    'MursionPortal.Contract.SaveContract':'Salva dettagli contratto',
    'MursionPortal.Contract.Length.PlaceHolder':'Seleziona lunghezza',
    'MursionPortal.Contract.SowName.PlaceHolder':'Inserisci nome contratto',
    'MursionPortal.Client.Tab.Contracts':'Contratti',
    'MursionPortal.Client.Contracts':'Contratti con i clienti',
    'MursionPortal.Client.Create.Contracts':'Crea contratto',
    'MursionPortal.Client.Contract.ID':'ID contratto',
    'MursionPortal.NewUser.Optional':' (facoltativo)',
    'MursionPortal.NewUser.SuchAGoogleAccountOrOtherSsoProvider.TooltipText':'Ad esempio un account Google o un altro provider SSO',
    'MursionPortal.UserConsentDeclineConfirmation.Heading':'Conferma la scelta.',
    // 'MursionPortal.UserConsentDeclineConfirmation.Title':'Sicuro? Per procedere senza registrare, fai clic sul pulsante Conferma. Per modificare il consenso alla registrazione, fai clic sul pulsante Annulla.',
    'Settings.Config.Invitation.ShowSowFeature':'Consenti l\'aggiunta di SOW',
    'Settings.Config.SOW.Description':'(per il monitoraggio dello stato di avanzamento del SOW)',
    'MursionPortal.ScenarioBank.StandardizedScenario':'Scenario standardizzato',
    'MursionPortal.ScenarioBank.ShowOnlyBundleScenario':'Mostra solo scenari standardizzati',
    'Settings.Config.Invitation.SimWorkforceManagement':'Gestione della forza lavoro della SIM',
    'Settings.Config.SimWorkforceManagement.Description':'Abilitato significa che i resoconti sono disponibili nella pagina della dashboard e disabilitato significa che i resoconti non sono affatto presenti.',
    'MursionPortal.ScenarioBank.BundleScenario':'Scenari in pacchetti',
    'MursionPortal.Scheduling.SimAvailable.TimeSlotsTooltipMsg':'Seleziona questa fascia oraria per confermare automaticamente la prenotazione dello scenario!',
    'Dashboard.LeftPane.CategoryHeader.SupplyManagement':'Gestione della forza lavoro della SIM',
    'Dashboard.LeftPane.SupplyManagement.Scheduling':'Programmazione',
    'Dashboard.SupplyManagement.Scheduling.MainHeader':'Programmazione',
    'Dashboard.SupplyManagement.Scheduling.TableHeader':'Dati di programmazione',
    'Dashboard.LeftPane.SupplyManagement.ClientRoster':'Elenco clienti',
    'Dashboard.SupplyManagement.ClientRoster.MainHeader':'Elenco clienti',
    'Dashboard.SupplyManagement.ClientRoster.TableHeader':'Dati elenco clienti',
    'MursionPortal.Table.ColumnHeader.Time':'Ora',
    'MursionPortal.Table.ColumnHeader.AllScheduledSessions':'Tutte programmate (Sessioni)',
    'MursionPortal.Table.ColumnHeader.SimsAvailable':'SIM disponibili (Persone)',
    'MursionPortal.Table.ColumnHeader.AvailabilityHours':'Disponibilità (Ore)',
    'MursionPortal.Table.ColumnHeader.DemandBasedRequests':'Tutte le richieste basate sulla domanda (Totale - tutte e 3 le richieste)',
    'MursionPortal.Table.ColumnHeader.SlotOneRequests':'Richieste fascia oraria 1',
    'MursionPortal.Table.ColumnHeader.SlotTwoRequests':'Richieste fascia oraria 2',
    'MursionPortal.Table.ColumnHeader.SlotThreeRequests':'Richieste fascia oraria 3',
    'MursionPortal.Table.ColumnHeader.PendingRequests':'Richieste in sospeso (Sessioni)',
    'MursionPortal.Table.ColumnHeader.Auto':'Automatico',
    'MursionPortal.Table.ColumnHeader.Manual':'Manuale',
    'MursionPortal.Table.ColumnHeader.CapacityIndicator':'Indicatore di capacità',
    'MursionPortal.EmptyMessage.NoSupplyManagementDataYet':'Non ci sono ancora dati sulla gestione della forza lavoro della sim',
    'MursionPortal.Table.ColumnHeader.SimCertified':'SIM certificate',
    'MursionPortal.Table.ColumnHeader.ScheduledHours':'Programmate (Ore)',
    'MursionPortal.Table.ColumnHeader.Sessions':'Sessioni',
    'MursionPortal.Table.ColumnHeader.AvgFullfilled':'Media soddisfatta in',
    'MursionPortal.Table.ColumnHeader.SlotOneFullFillment':'Adempimento fascia oraria 1',
    'MursionPortal.Table.ColumnHeader.Cancelled':'Annullate',
    'MursionPortal.Table.ColumnHeader.Missed':'Perse',
    'MursionPortal.Table.ColumnHeader.Reschedules':'Riprogrammazioni',
    'MursionPortal.Table.ColumnHeader.Error':'Errore',
    'MursionPortal.Table.ColumnHeader.Completed':'Completate',
    'MursionPortal.Table.ColumnHeader.Other':'Altro',
    'MursionPortal.Table.ColumnHeader.SchedulingWindow':'Finestra di programmazione',
    'MursionPortal.Table.ColumnHeader.RequestDemand':'N. di richiesta (domanda)',
    'MursionPortal.Table.ColumnHeader.AutoFulfilled':'Completata automaticamente',
    'MursionPortal.Table.ColumnHeader.Pending':'In attesa',
    'MursionPortal.Table.ColumnHeader.SlotTwoFullFillment':'Adempimento fascia oraria 2',
    'MursionPortal.Table.ColumnHeader.SlotThreeFullFillment':'Adempimento fascia oraria 3',
    'MursionPortal.Table.ColumnHeader.UnutilizedAvailability':'Disponibilità non utilizzata (Ore)',
    'MursionPortal.Table.ColumnHeader.TotalAvailabilityEntered':'Disponibilità totale inserita',
    'MursionPortal.Table.ColumnHeader.AverageHoursWeek':'Media ore/settimana',
    'MursionPortal.Table.ColumnHeader.AvailabilityUtilization':'Utilizzo disponibilità',
    'MursionPortal.SupplyManagement.Above':'Al di sopra',
    'MursionPortal.SupplyManagement.Below':'Al di sotto',
    'MursionPortal.SupplyManagement.At':'A',
    'MyProfile.ProfServiceRole.Tooltiptext':'Utenti con il livello di accesso più alto',
    'MyProfile.AccManager.Tooltiptext':'Crea scenari, team, aggiunge utenti e assegna i team alle simulazioni',
    'MyProfile.SimSpecialist.Tooltiptext':'Conosciuto anche come "Sim", questo ruolo esegue simulazioni per gli studenti',
    'MyProfile.Buyer.Tooltiptext':'Un utente che gestisce un progetto Mursion per la propria organizzazione',
    'MyProfile.Facilitator.Tooltiptext':'Partecipante alla simulazione che assiste nella fornitura della simulazione',
    'MyProfile.Learner.Tooltiptext':'Partecipante alla simulazione',
    'MyProfile.Operations.Tooltiptext':'Impiegato Mursion che ha la capacità di aggiungere e modificare contratti',
    'MyProfile.SimDesigner.Tooltiptext':'Utente che ha la possibilità di creare e modificare scenari della raccolta',
    'MursionPortal.Label.AssignedUserRoles':'Ruoli utente assegnati',
    'MursionPortal.Label.AssignedUserRole':'Ruolo utente assegnato',
    'Clients.Modal.Projects.Button.CreateFromBank':'Crea da raccolta',
    'MursionPortal.Legend.VisibilityHidden.ScenarioPanel.Buttons':'Crea nuovo, Ripeti cliente, Crea da pulsanti scenario della raccolta',
    'MursionPortal.Label.ArtBundle':'Pacchetto arte',
    'Settings.ScenarioBank.Modal.ToolTip.ThisIsTheArtContentMsg':'Questo è il contenuto artistico 3D di ambienti e avatar che verrà utilizzato nella simulazione. I contenuti di questa cartella sono visualizzati nella seguente sezione intitolata "pacchetto arte".',
    'MursionPortal.SessionWizard.Label.ScenarioHasNoArtBundles':'Allo scenario non sono stati assegnati pacchetti arte.',
    'MursionPortal.Label.ArtProject':'Progetto artistico',
    'MursionPortal.Label.UpperCase.ArtProject':'PROGETTO ARTISTICO',
    'Settings.Config.SimulationContent.ArtProjectFolder':'Cartella Progetto artistico S3',
    'Settings.ScenarioBank.Modal.ThisScenarioHasNoArtProject':'Lo scenario non ha progetto artistico assegnato',
    'MursionPortal.SessionWizard.Label.SelectArtContentForUse':'Seleziona progetto artistico per l\'utilizzo',
    'Projects.Message.NoArtProjectFound':'Nessun progetto artistico trovato',
    'Settings.ScenarioBank.Form.ConfirmArchiveScenarioBank':'Sono presenti scenari figlio attivi creati da questo scenario della raccolta. Devi archiviare ciascuno di essi individualmente.',
    'Settings.StandardizedScenarioBank.EditConfirmation':'Sicuro di voler aggiornare tutti gli scenari esistenti creati a partire da questo?',
    'Settings.StandardizedScenarioBank.AttachmentsEditConfirmation':'L\'aggiornamento degli allegati cambierà tutti gli scenari esistenti creati a partire da questo.',
    'MursionPortal.Contract.Ending':'Fine',
    'MursionPortal.ProjectContracts.DuplicateError':'Il Contratto che hai selezionato si sovrappone a un altro Contratto aggiunto a questo progetto. Selezionane un altro o contatta le operazioni per richiedere un ordine di modifica',
    'MursionPortal.ProjectContracts.DateErrorHeading':'Non esiste alcun Contratto per queste date di progetto',
    'MursionPortal.ProjectContracts.DateError':'Non esiste alcun Contratto per supportare queste date di inizio e fine del progetto. Nessun progetto deve essere creato senza un Contratto valido. Vuoi procedere?',
    'MursionPortal.Contract.ViewDetails':'Visualizza dettagli',
    'MursionPortal.Contract.PleaseAddContractToProject':'Aggiungi il Contratto al Progetto',
    'MursionPortal.Contract.AddContractToProject':'Aggiungi il Contratto al Progetto',
    'MursionPortal.Contract.SelectContractToProject':'Seleziona il Contratto che desideri aggiungere a questo progetto - aggiungine uno alla volta',
    'MursionPortal.Contract.AddSelectedContract':'Aggiungi Contratto selezionato',
    'MursionPortal.Contract.AddContract':'Aggiungi Contratto',
    'MursionPortal.Contract.LineItemList':'Lista voci',
    'MursionPortal.DemandBasedScheduling.InstantBooking':'Prenotazione immediata',
    'MursionPortal.DemandBasedScheduling.AllSlots':'Invia fasce orarie',
    'MursionPortal.DemandBasedScheduling.ScheduleYourScenario':'Programmeremo il tuo scenario in una delle fasce orarie da te scelte.',
    'MursionPortal.DemandBasedScheduling.SelectOneOfTheseSlotsForAnInstantBooking':'Seleziona una di queste fasce orarie per una prenotazione immediata.',
    'MursionPortal.DemandBasedScheduling.TheseSlotDoNotwork':'Se queste fasce orarie non sono adatte a te, seleziona "Invia fascia oraria" per inviare orari che sono adatti a te.',
    'MursionPortal.AltText.CrossButton':'immagine del pulsante a croce',
    'MursionPortal.Title.BankScenario':'Scenario della raccolta',
    'Dashboard.LeftPane.SupplyManagement.SimRoster':'Elenco Sim',
    'Dashboard.SupplyManagement.SimRoster.MainHeader':'Elenco Sim',
    'Dashboard.SupplyManagement.SimRoster.TableHeader':'Dati elenco Sim',
    'MursionPortal.Table.ColumnHeader.SimFullName':'Nome completo SIM',
    'MursionPortal.Table.ColumnHeader.LanguagesDelivered':'Lingue consegnate (certificazione in lingua)',
    'MursionPortal.Table.ColumnHeader.Tier':'Livello',
    'MursionPortal.Table.ColumnHeader.Timezone':'Fuso orario',
    'MursionPortal.Table.ColumnHeader.Scheduled':'Programmate',
    'MursionPortal.Table.ColumnHeader.ProjectsHoursWeekTotal':'Totale ore/settimana previste',
    'MursionPortal.Table.ColumnHeader.ActiveClientCertifications':'Certificazioni Cliente Attivo (fatturabili)',
    'MursionPortal.Table.ColumnHeader.InactiveCertifications':'Certificazione inattiva',
    'MursionPortal.Table.ColumnHeader.Declined':'Rifiutate',
    'MursionPortal.Table.ColumnHeader.AvgFullfillTimeForManual':'Tempo medio di riempimento manuale',
    'MursionPortal.Table.ColumnHeader.Queue':'Coda',
    'MursionPortal.Table.ColumnHeader.Availability':'Disonibilità',
    'MursionPortal.Filters.Tier':'Livello',
    'MursionPortal.Filters.PlaceHolder.SearchTier':'Cerca livello',
    'MursionPortal.TierOptions.Tier1':'Livello 1',
    'MursionPortal.TierOptions.Tier2':'Livello 2',
    'MursionPortal.TierOptions.Tier3':'Livello 3',
    'MursionPortal.TierOptions.Tier4':'Livello 4',
    'MursionPortal.TierOptions.LeadSimulation':'Specialista della simulazione principale',
    'MursionPortal.Contract.BackToCLient':'Torna al Cliente',
    'MursionPortal.DeliveryMode.Workshop':'Workshop',
    'MursionPortal.DeliveryMode.PrivatePractice':'Pratica privata',
    'MursionPortal.DemandBasedScheduling.SimOnlySlotSelectionMessage':'Scegli un giorno e una fascia oraria che siano adatti a te.',
    'Dashboard.LeftPane.CategoryHeader.Performance':'UTILIZZO',
    'Dashboard.LeftPane.Performance.ContractOverview':'Panoramica',
    'Dashboard.LeftPane.Performance.ContractSummaries':'Riepiloghi dei Contratti',
    'Dashboard.LeftPane.Performance.ContractProgress':'Avanzamento del Contratto',
    'Dashboard.LeftPane.CategoryHeader.Learners':'STUDENTI',
    'Dashboard.LeftPane.Learners.LearnerActivity':'Attività dello studente',
    'Dashboard.LeftPane.Learners.LearnerSimulations':'Simulazioni dello studente',
    'Dashboard.Total':'Totale ingressi',
    'Filters.SimulationStatus':'Stato della simulazione',
    'Filters.SimulationStatusPlaceholder':'Cerca stato simulazione...',
    'Filters.LearnerStatus':'Stato dello studente',
    'Filters.LearnerStatusPlaceHolderHint':'Cerca stato studente...',
    'Filters.SimulationRecorded':'Simulazione registrata',
    'Filters.Standardized':'Standardizzato',
    'Filters.Boolean.Yes':'Sì',
    'Filters.Boolean.No':'No',
    'Dashboard.UserTable.Column.Roles':'Ruolo',
    'Dashboard.ContractSummaries.MainHeader':'Riepiloghi dei Contratti',
    'Dashboard.ContractOverview.MainHeader':'Panoramica',
    'Dashboard.ContractProgress.MainHeader':'Avanzamento del Contratto',
    'Dashboard.ContractProgress.Navigation.ContractItems':'Articoli del Contratto',
    'Dashboard.ContractProgress.Navigation.Projects':'Progetti',
    'Dashboard.ContractProgress.Navigation.Scenarios':'Scenari',
    'Dashboard.ContractProgress.Column.Item':'Articoli',
    'Dashboard.ContractProgress.Column.Purchased':'Acquistate',
    'Dashboard.ContractProgress.Column.Remaining':'Rimanenti',
    'Dashboard.ContractProgress.Column.Completed':'Completate',
    'Dashboard.ContractProgress.Column.Scheduled':'Programmate',
    'Dashboard.ContractProgress.Column.LateCancellations':'Cancellate in ritardo',
    'Dashboard.ContractProgress.Column.ProjectName':'Nome progetto',
    'Dashboard.ContractProgress.Column.StartDate':'Data di inizio',
    'Dashboard.ContractProgress.Column.EndDate':'Data di fine',
    'Dashboard.ContractProgress.Column.Error':'Errore',
    'Dashboard.ContractProgress.Column.ScenarioName':'Nome scenario',
    'Dashboard.ContractProgress.Column.Standardized':'Standardizzato',
    'Dashboard.ContractProgress.Column.LearnersAssigned':'Studenti assegnati',
    'Dashboard.ContractProgress.Column.LearnersCompleted':'Studenti completati',
    'Dashboard.ContractProgress.Column.LearnersCompletedPercent':'% Studenti che hanno completato',
    'Dashboard.ContractProgress.Column.Client':'Cliente',
    'Dashboard.ContractProgress.Column.ContractName':'Nome contratto',
    'Dashboard.ContractProgress.Column.ContractId':'ID contratto',
    'Dashboard.ContractProgress.Column.ContractNameHoverTitle':'ID copia',
    // 'Dashboard.ContractProgress.Column.PercentComplete':'% Completato',
    'Dashboard.ContractProgress.Column.ErrorLearner':'Errore (Studente)',
    'Dashboard.ContractProgress.Column.LearnersInvited':'Studenti invitati',
    'Dashboard.ContractProgress.Column.LearnersScheduledPercent':'% Studenti che hanno programmato',
    'Dashboard.ContractProgress.ColumnTooltip.Client':'Nome del cliente',
    'Dashboard.ContractProgress.ColumnTooltip.ContractName':'Nome del contratto',
    'Dashboard.ContractProgress.ColumnTooltip.PercentComplete':'Percentuale di giorni completati del contratto',
    'Dashboard.ContractProgress.ColumnTooltip.CompletedUtilization':'Simulazioni completate come percentuale delle simulazioni acquistate',
    'Dashboard.ContractProgress.ColumnTooltip.ScheduledUtilization':'Simulazioni completate e programmate come percentuale delle simulazioni acquistate',
    'Dashboard.ContractProgress.ColumnTooltip.MissedLearner':'Simulazioni che sono state perse dallo studente.',
    'Dashboard.ContractProgress.ColumnTooltip.ErrorLearner':'Simulazioni che si sono concluse con un errore della connessione da parte dello studente.',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersInvited':'Studenti che sono stati invitati a programmare una simulazione',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersScheduledPercent':'Studenti che hanno programmato almeno una simulazione come percentuale di coloro che sono stati invitati a programmare una simulazione',
    'Dashboard.ContractProgress.ColumnTooltip.Item':'Articoli contrattati nel Contratto',
    'Dashboard.ContractProgress.ColumnTooltip.Purchased':'Numero totale di simulazioni acquistate nell\'ambito di un contratto',
    'Dashboard.ContractProgress.ColumnTooltip.Completed':'Simulazioni che sono state completate con successo',
    'Dashboard.ContractProgress.ColumnTooltip.Scheduled':'Prossime simulazioni programmate ma non ancora completate (comprese quelle in esecuzione)',
    'Dashboard.ContractProgress.ColumnTooltip.Missed':'Simulazioni che sono state perse dallo studente',
    'Dashboard.ContractProgress.ColumnTooltip.LateCancellations':'Simulazioni che sono state annullate in ritardo (dopo la scadenza per la cancellazione) dallo studente, dal facilitatore o dal proprietario dell\'account o simulazioni in cui lo studente ha abbandonato o rifiutato',
    'Dashboard.ContractProgress.ColumnTooltip.Error':'Simulazioni che si sono concluse con un errore della connessione da parte dello studente.',
    'Dashboard.ContractProgress.ColumnTooltip.ProjectName':'Nome del progetto',
    'Dashboard.ContractProgress.ColumnTooltip.StartDate':'Data di inizio del contratto come indicato nel Contratto.',
    'Dashboard.ContractProgress.ColumnTooltip.EndDate':'Data di fine del contratto come indicato nel Contratto.',
    'Dashboard.ContractProgress.ColumnTooltip.ScenarioName':'Nome dello scenario',
    'Dashboard.ContractProgress.ColumnTooltip.Standardized':'Questo è uno scenario standardizzato',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersAssigned':'Numero di studenti a cui è stato assegnato uno scenario',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersCompleted':'Studenti che hanno completato almeno una simulazione come percentuale di coloro che sono stati invitati a programmare una simulazione',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersCompletedPercent':'Percentuale di studenti distinti che hanno completato lo scenario',
    'Dashboard.Learners.Column.Provider':'Provider',
    'Dashboard.Learners.Column.Client':'Cliente',
    'Dashboard.Learners.Column.LearnerName':'Nome dello studente',
    'Dashboard.Learners.Column.DateInvited':'Data invito',
    'Dashboard.Learners.Column.LastLogin':'Ultimo accesso',
    'Dashboard.Learners.Column.NoAssignedTeams':'Nessun team assegnato',
    'Dashboard.Learners.Column.NoAssignedScenarios':'Nessuno scenario assegnato',
    'Dashboard.Learners.Column.NoCompletedScenarios':'Nessuno scenario completato',
    'Dashboard.Learners.Column.Completed':'Completato',
    'Dashboard.Learners.Column.Scheduled':'Programmato',
    'Dashboard.Learners.Column.LateCancelled':'Cancellato in ritardo',
    'Dashboard.Learners.Column.EndedInError':'Concluse con un errore',
    'Dashboard.Learners.Column.ProjectName':'Nome progetto',
    'Dashboard.Learners.Column.ScenarioName':'Nome scenario',
    'Dashboard.Learners.Column.ScheduledStartDate':'Data di inizio programmata',
    'Dashboard.Learners.Column.ScheduledStartTime':'Orario di inizio programmato',
    'Dashboard.Learners.Column.ScheduledEndTime':'Orario di fine programmato',
    'Dashboard.Learners.Column.SimulationStatus':'Stato della simulazione',
    'Dashboard.Learners.Column.LearnerStatus':'Stato dello studente',
    'Dashboard.Learners.Column.LearnerTimeSpent':'Tempo speso studente',
    'Dashboard.Learners.Column.SimSpecialistName':'Nome specialista della Sim',
    'Dashboard.Learners.Column.SimSpecialistStatus':'Stato specialista della Sim',
    'Dashboard.Learners.Column.Report':'Resoconto',
    'Dashboard.Learners.ColumnTooltip.Provider':'Nome del provider/licenziatario',
    'Dashboard.Learners.ColumnTooltip.Client':'Nome del cliente',
    'Dashboard.Learners.ColumnTooltip.LearnerName':'Cognome e nome utente',
    'Dashboard.Learners.ColumnTooltip.DateInvited':'Data in cui uno studente è stato invitato per la prima volta a programmare uno scenario',
    'Dashboard.Learners.ColumnTooltip.LastLogin':'Data in cui un utente ha effettuato l\'ultimo accesso al portale',
    'Dashboard.Learners.ColumnTooltip.NoAssignedTeams':'Numero di team che sono state assegnati a uno studente',
    'Dashboard.Learners.ColumnTooltip.NoAssignedScenarios':'Numero di scenari distinti che sono stati assegnati a uno studente',
    'Dashboard.Learners.ColumnTooltip.NoCompletedScenarios':'Numero di scenari distinti che sono stati completati da uno studente',
    'Dashboard.Learners.ColumnTooltip.Completed':'Simulazioni che sono state completate con successo',
    'Dashboard.Learners.ColumnTooltip.Scheduled':'Prossime simulazioni programmate ma non ancora completate (comprese quelle in esecuzione)',
    'Dashboard.Learners.ColumnTooltip.Missed':'Simulazioni che sono state perse dallo studente',
    'Dashboard.Learners.ColumnTooltip.LateCancelled':'Simulazioni che sono state annullate in ritardo (dopo la scadenza per la cancellazione) dallo studente, dal facilitatore o dal proprietario dell\'account o simulazioni.',
    'Dashboard.Learners.ColumnTooltip.EndedInError':'Simulazioni che si sono concluse con un errore della connessione da parte dello studente.',
    'Dashboard.Learners.ColumnTooltip.ProjectName':'Nome del progetto',
    'Dashboard.Learners.ColumnTooltip.ScenarioName':'Nome dello scenario',
    'Dashboard.Learners.ColumnTooltip.ScheduledStartDate':'Data prevista per l\'inizio di questa simulazione',
    'Dashboard.Learners.ColumnTooltip.ScheduledStartTime':'Ora prevista per l\'inizio di questa simulazione',
    'Dashboard.Learners.ColumnTooltip.ScheduledEndTime':'Ora prevista per la fine di questa simulazione',
    'Dashboard.Learners.ColumnTooltip.SimulationStatus':'Stato finale per la simulazione',
    'Dashboard.Learners.ColumnTooltip.LearnerStatus':'Stato finale dello studente per la simulazione',
    'Dashboard.Learners.ColumnTooltip.LearnerTimeSpent':'Minuti che lo studente ha speso nella simulazione. Per gli studenti di Zoom e Meet, questo tempo è stimato in base allo specialista della SIM se completato',
    'Dashboard.Learners.ColumnTooltip.SimSpecialistName':'Specialista della simulazione incaricato di fornire la simulazione',
    'Dashboard.Learners.ColumnTooltip.SimSpecialistStatus':'Status finale dello specialista della SIM per la simulazione',
    'Dashboard.Learners.ColumnTooltip.SimulationId':'Identificatore univoco della simulazione',
    'Dashboard.Learners.ColumnTooltip.Report':'Collegamento al rapporto di simulazione per visualizzare (se disponibile) la registrazione e l\'analisi della simulazione',
    'Dashboard.SessionLearners.TableHeader':'Dati degli studenti della sessione',
    'MursionPortal.FilterHeading.Filter':'FILTRO',
    'MursionPortal.FilterHeading.Graph':'GRAFICI',
    'MursionPortal.Table.NoRecordsMessage':'Nessun risultato trovato!',
    'MursionPortal.Filters.DeliveryMode':'Modalità di fornitura',
    'MursionPortal.Filters.PlaceHolder.DeliveryMode':'Cerca modalità di fornitura...',
    'MursionPortal.Dashboard.Chart.Tooltip.Utilized':'Numero totale utilizzato per voce del contratto. Include simulazioni completate, perse o cancellate/riprogrammate (oltre la scadenza).',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Completed':'Simulazioni che sono state completate con successo.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.LearnerMissed':'Simulazioni che sono state perse dallo studente.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Error':'Simulazioni che si sono concluse con un errore della connessione da parte dello studente.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.LateCancellation':'Simulazioni che sono state annullate in ritardo (dopo la scadenza per la cancellazione) dallo studente, dal facilitatore o dal proprietario dell\'account o simulazioni in cui lo studente ha abbandonato o rifiutato.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.EarlyCancellation':'Simulazioni che sono state cancellate in anticipo (prima della scadenza per la cancellazione) dallo studente, dal facilitatore o dal proprietario dell\'account.',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.Completed':'Completate',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.Error':'Errore',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.LateCancellation':'Canc. in ritardo',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.EarlyCancellation':'Canc. in anticipo',
    'MursionPortal.Dashboard.Chart.PrivatePractice1':'Pratica privata 30m',
    'MursionPortal.Dashboard.Chart.PrivatePractice2':'Pratica privata 60m',
    'MursionPortal.Dashboard.Chart.Workshop1':'Workshop 60m',
    'MursionPortal.Dashboard.Chart.Workshop2':'Workshop 90m',
    'MursionPortal.Dashboard.Chart.StringType.Utilized':'Utilizzate',
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdown':'Scomposizione della simulazione',
    'MursionPortal.SessionRecording':'Registrazione della sessione',
    'MursionPortal.VoiceAnalytics':'Analisi vocale',
    'MursionPortal.VideoTab':'Video',
    'MursionPortal.SessionInformation':'Informazioni sulla sessione',
    'MursionPortal.SmartMatrix':'Metriche smart',
    'MursionPortal.DeliveryDetails':'Dettagli di fornitura',
    'MursionPortal.ConversationalFlow':'Flusso della conversazione',
    'MursionPortal.ConversationalFlowTooltip':'Questo grafico mostra la tua efficacia durante una conversazione. Si compone di input a turni che rivelano quanto sei in grado di partecipare a una conversazione costruttiva.',
    'MursionPortal.BalancedTalkTime':'Conversazione',
    'MursionPortal.BalancedFeedback':'Feedback equilibrato',
    'MursionPortal.ConversationalDisruptions':'Interruzione',
    'MursionPortal.DisruptionsFeedback':'Feedback sulle interruzioni',
    'MursionPortal.Heading.SessionSmartMetrics':'Analisi vocale della sessione:',
    'Clients.Modal.Label.SmartMetrics':'Analisi vocale:',
    'Clients.Modal.Projects.Header.SessionSmartMetrics':'Analisi vocale della sessione:',
    'Clients.Modal.Projects.Checkbox.EnableSmartMetrics':' Abilita analisi vocale',
    'Filters.Contract':'Contratto',
    'Filters.Session': 'Sessione',
    'Clients.Modal.Label.ShowSowReporting':'Mostra rapporto SOW:',
    'Clients.Modal.Label.ShowSurveyEnabled':'Invia sondaggio per studenti post-simulazione:',
    'Dashboard.SimulationTable.Column.Provider':'Provider',
    'Dashboard.SimulationTable.Column.Client':'Cliente',
    'Dashboard.SimulationTable.Column.Project':'Progetto',
    'Dashboard.SimulationTable.Column.ScenarioName':'Nome scenario',
    'Dashboard.SimulationTable.Column.ScheduledStartDate':'Data di inizio programmata',
    'Dashboard.SimulationTable.Column.ScheduledStartTime':'Orario di inizio programmato',
    'Dashboard.SimulationTable.Column.ScheduledEndTime':'Orario di fine programmato',
    'Dashboard.SimulationTable.Column.LearnersNames':'Nomi degli studenti',
    'Dashboard.SimulationTable.Column.LearnersEmails':'Email degli studenti',
    'Dashboard.SimulationTable.Column.LearnersStatus':'Stato dello studente/i',
    'Dashboard.SimulationTable.Column.LearnersTimeSpent':'Tempo speso studente/i',
    'Dashboard.SimulationTable.Column.SimSpecialistName':'Nome specialista della Sim',
    'Dashboard.SimulationTable.Column.SimSpecialistStatus':'Stato specialista della Sim',
    'Dashboard.SimulationTable.Column.SimSpecialistTimeSpent':'Tempo speso specialista della SIM',
    'Dashboard.SimulationTable.Column.ScheduledBy':'Programmata da',
    'Dashboard.SimulationTable.Column.ScheduledAt':'Programmata alle',
    'Dashboard.SimulationTable.Column.Recorded':'Registrata',
    'Dashboard.SimulationTable.Column.ClientId':'Id cliente',
    'Dashboard.SimulationTable.Column.LearnerName':'Nome dello studente',
    'Dashboard.SimulationTable.Column.LearnerName.ButtonHoverTitle':'Copia e-mail studente',
    'Dashboard.SimulationTable.Column.LearnerEmail':'E-mail studente',
    'Dashboard.SimulationTable.Column.LearnerStatus':'Stato dello studente',
    'Dashboard.SimulationTable.Column.LearnerTimeSpent':'Studente TempoTrascorso',
    'Dashboard.SimulationTable.ColumnTooltip.Provider':'Nome del provider/licenziatario',
    'Dashboard.SimulationTable.ColumnTooltip.Client':'Nome del cliente',
    'Dashboard.SimulationTable.ColumnTooltip.Project':'Nome del progetto',
    'Dashboard.SimulationTable.ColumnTooltip.ScenarioName':'Nome dello scenario',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledStartDate':'Data prevista per l\'inizio di questa simulazione',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledStartTime':'Ora prevista per l\'inizio di questa simulazione',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledEndTime':'Ora prevista per la fine di questa simulazione',
    'Dashboard.SimulationTable.ColumnTooltip.SimulationStatus':'Stato finale per la simulazione',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersNames':'Nome/i degli studenti registrati e non registrati alla simulazione',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersEmails':'E-mail degli studenti registrati e non registrati alla simulazione',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersStatus':'Stato finale dello studente per la simulazione',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersTimeSpent':'Tempo trascorso connessi alla simulazione dagli studenti che hanno aderito alla simulazione',
    'Dashboard.SimulationTable.ColumnTooltip.SimSpecialistName':'Nome specialista della Sim',
    'Dashboard.SimulationTable.ColumnTooltip.SimSpecialistStatus':'Status finale dello specialista della SIM per la simulazione',
    'Dashboard.SimulationTable.ColumnTooltip.SimSpecialistTimeSpent':'Durata effettiva, in minuti, di questa simulazione',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledBy':'Nome dell\'utente che ha pianificato la simulazione',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledAt':'Data e ora in cui è stata programmata questa simulazione',
    'Dashboard.SimulationTable.ColumnTooltip.Recorded':'Indica se la simulazione è stata registrata o se non è stata consentita.',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerName':'Nome/i degli studenti registrati e non registrati alla simulazione',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerStatus':'Stato finale dello studente per la simulazione',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerTimeSpent':'Minuti che lo studente ha speso nella simulazione. Per gli studenti di Zoom e Meet, questo tempo è stimato in base allo specialista della SIM se completato',
    'MursionPortal.Label.VideoTimeLineSlice':'Sezioni della sequenza temporale del video',
    'MursionPortal.Label.SpeakingMoreThanSixty':'Il parlato è più del 60%',
    'MursionPortal.Label.SpeakingBetweenSixty':'Il parlato è compresa tra il 40% e il 60%',
    'MursionPortal.Label.InterruptionMoreThanSixty':'L\'interruzione è superiore al 60%',
    'MursionPortal.Label.InterruptionBetweenSixty':'L\'interruzione è compresa tra il 40% e il 60%',
    'MursionPortal.Label.SpeakingLessThanFourty':'Il parlato è inferiore al 40%',
    'MursionPortal.Label.InterruptionLessThanFourty':'L\'interruzione è inferiore al 40%',
    'MursionPortal.Label.Interruption.ByAvatar':'Per avatar',
    'MursionPortal.Label.Interruption.ByLearner':'Per studente',
    'MursionPortal.Projects.NoContractsFound':'Nessun contratto è stato trovato.',
    'MursionPortal.Projects.ProjectContracts':'Contratti del progetto',
    'MursionPortal.Tooltip.AvatarInterrupt':'L\'Avatar tenta di interrompere chi parla, con successo.',
    'MursionPortal.Tooltip.LearnerInterrupt':'Lo studente tenta di interrompere chi parla, con successo.',
    'MursionPortal.Tooltip.Pauses':'Pause. Più lunghe di 0,2 s e meno di 0,75s',
    'MursionPortal.Tooltip.Silence':'Interruzione del discorso più lunga di 10 sec',
    'MursionPortal.Tooltip.AvatarSpeaking':'L\'avatar tenta di parlare con successo.',
    'MursionPortal.Tooltip.LearnerSpeaking':'Lo studente tenta di parlare con successo.',
    // 'MursionPortal.label.LearnerInterrupts':'Lo studente interrompe',
    // 'MursionPortal.label.AvatarInterrupts':'L\'avatar interrompe',
    'MursionPortal.label.Pause':'Pausa',
    'MursionPortal.Label.Silence':'Silenzio',
    'MursionPortal.label.AvatarSpeaking':'Parla l\'avatar',
    'MursionPortal.label.LearnerSpeaking':'Parla lo studente',
    'MursionPortal.Contract.DuplicateLineItem':'Esiste già una voce per questa durata di simulazione e modalità di fornitura',
    'MursionPortal.Contract.DeleteLineItemIcon':'Elimina l\'icona della voce',
    'SessionReports.Label.ShareVideoLink':'Condividi link video',
    'MursionPortal.Title.Silence':'Interruzione del discorso più lunga di 10 sec',
    'Dashboard.LeftPane.User.UserActivity':'Attività dell\'utente',
    'Dashboard.LeftPane.Performance.SelfService':'Utilizzo self-service',
    'MursionPortal.Placeholder.ClientValue':'Seleziona un cliente',
    'Filters.Date.FromDate':'Dalla data del',
    'Filters.Date.ToDate':'Aalla data del',
    'Dashboard.UserActivity.MainHeader':'Attività dell\'utente',
    'Dashboard.SelfService.MainHeader':'Utilizzo self-service',
    'Dashboard.SelfService.Column.SelfServiceProvider':'Provider',
    'Dashboard.SelfService.Column.SelfServiceTotalHoursUtilized':'Ore totali utilizzate',
    'Dashboard.SelfService.Column.SelfServiceHoursCompleted':'Ore completate',
    'Dashboard.SelfService.Column.SelfServiceHoursMissed':'Ore perse',
    'Dashboard.SelfService.Column.SelfServiceHoursErrored':'Ore con errori',
    'Dashboard.SelfService.Column.SelfServiceSimulationsComplete':'Simulazione completa',
    'Dashboard.SelfService.Column.SelfServiceSimulationsMissed':'Simulazioni perse',
    'Dashboard.SelfService.Column.SelfServiceSimulationsErrored':'Simulazione con errori',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceProvider':'Provider',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceTotalHoursUtilized':'Simulazioni che sono state completate con successo',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceHoursCompleted':'Ore completate',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceHoursMissed':'Simulazioni che sono state perse dallo studente.',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceHoursErrored':'Simulazioni che si sono concluse con un errore della connessione da parte dello studente.',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceSimulationsComplete':'Simulazione completa',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceSimulationsMissed':'Simulazioni perse',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceSimulationsErrored':'Simulazione con errori',
    'Dashboard.Learners.Column.AssignedProjects':'Progetti assegnati',
    'Dashboard.Learners.Column.AssignedScenarios':'Scenari assegnati',
    'Dashboard.Learners.Column.ScheduledScenarios':'Scenari programmati',
    'Dashboard.Learners.Column.CompletedScenarios':'Scenari completati',
    'Settings.Config.SessionReport.ShowSmartMetrics':'Analisi vocale',
    'Settings.SSO.CopySsoDeepLink':'Copia Deep Link SSO',
    'Settings.SSO.CopySsoDeepLinkNotification':'Il tuo link SSO è stato copiato negli appunti',
    'MursionPortal.Dashboard.Chart.Assigned':'Assegnato',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Simulation':'Simulazioni suddivise per ogni stato.',
    'MursionPortal.Dashboard.Chart.Header.NoDataAvailable':'Nessun dato disponibile',
    'Projects.ProjectInfo.Label.SurveyControl':'Sndaggi del progetto',
    'Projects.ProjectInfo.Checkbox.EnablePreSimulationSurvey':'Abilita sondaggio studente pre-simulazione',
    'Projects.ProjectInfo.Checkbox.EnablePostSimulationSurvey':'Abilita sondaggio studente post-simulazione',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled':'Sondaggio abilitato',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PreSimulationSurvey':'Sondaggio studente pre-simulazione',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PostSimulationSurvey':'Sondaggio studente post-simulazione',
    'Settings.SSO.ModalBody.SelectDestination':'Seleziona destinazione link',
    'Settings.SSO.ModalBody.SelectDestination.SessionsTab':'Scheda sessioni',
    'Settings.SSO.ModalBody.SelectDestination.CalendarTab':'Scheda calendario',
    'Settings.SSO.ModalBody.SelectDestination.ReportsTab':'Scheda resoconti',
    'Settings.SSO.ModalBody.SelectDestination.DashboardTab':'Scheda dashboard',
    'Settings.SSO.ModalBody.SelectDestination.ScenarioCard':'Carta Scenario',
    'Settings.SSO.ModalBody.SelectDestination.ScenarioTabSelectTitle':'Seleziona prima un progetto, poi uno scenario',
    'Settings.SSO.Button.CreateDeepLink':'Crea Deep Link SSO',
    'Settings.SSO.ModalHeader.DeepLink':'Copia Deep Link SSO',
    'MursionPortal.Dashboard.Chart.Header.LearnerSimulations':'Numero di scenari assegnati',
    'Clients.Modal.Label.MursionSocialAttendance':'Partecipazione Mursion Social:',
    'MursionPortal.CodeOfConduct.Text':'Scegliendo di programmare la sessione Mursion, accetti di sostenere e onorare il <code>Codice di condotta</code> di Mursion.',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.CompletedAllAvailable':'Studenti che hanno completato tutti gli scenari disponibili',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.NoneAvailableToSchedule':'Studenti che non hanno ancora uno scenario disponibile da programmare',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.NotYetScheduled':'Studenti con scenari disponibili che non hanno ancora programmato una simulazione',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.Scheduled':'Studenti che hanno una o più simulazioni programmate che non hanno ancora completato',
    'MursionPortal.DateRangeSelector.Label.DateRange':'Intervallo di date ',
    'MursionPortal.DemandBasedScheduling.TimeBlockUnavailableReservedText':'Già selezionato: seleziona un altro blocco temporale.',
    'MursionPortal.EndDate':'Data di fine',
    'MursionPortal.Label.FilterSIMsBy':'Filtra le SIM per',
    'MursionPortal.LearnerSimSelector.Learner':'Studenti',
    'MursionPortal.LearnerSimSelector.Learner.Selected':'Studente selezionato',
    'Dashboard.Filters.SimulationID':'Id simulazione',
    'MursionPortal.LearnerSimSelector.Sim.Selected':'Specialista della Sim',
    'MursionPortal.LearnerSimSelector.Sim.Unavailable':'Certificata e non disponibile',
    'MursionPortal.LearnerSimSelector.Tab.Search.Learner':'Cerca o seleziona studente',
    'MursionPortal.LearnerSimSelector.Tab.Search.Sim':'Cerca o seleziona specialista della SIM',
    'MursionPortal.Placeholder.ProvidersValue':'Seleziona uno provider',
    'MursionPortal.ProjectContract.Confirmation':'Sicuro di voler cancellare il contratto da questo progetto?',
    'MursionPortal.Scheduling.Label.DateTimeTab':'Data + Ora',
    'MursionPortal.Scheduling.NoAvailabilityForCertifiedAndAvailableSimSpecialist':'Non esiste disponibilità per una SIM certificata.',
    'MursionPortal.Scheduling.NoAvailabilityForCertifiedSimSpecialist':'Non ci sono Sim certificate disponibili',
    'MursionPortal.SessionFlow.Label.AreYouSure':'Sicuro?',
    'MursionPortal.SessionFlow.Label.BeforeDeletionPleaseEnsureTheFollowing':'Prima dell\'eliminazione accertati di quanto segue:',
    'MursionPortal.SessionFlow.Label.BeforeDeletionPleaseEnsureTheFollowing.Text1':'Verifica che l\'utente richiedente abbia l\'autorizzazione per l\'eliminazione (attenzione alle simulazioni di gruppo e al consenso dello studente).',
    'MursionPortal.SessionFlow.Label.BeforeDeletionPleaseEnsureTheFollowing.Text2':'Crea una richiesta di supporto in cui si dichiara chi sta richiedendo l\'eliminazione e verificane la possibilità con il Proprietario dell\'account, se necessario.',
    'MursionPortal.SessionFlow.Label.DeleteRecording':'Elimina registrazione',
    'MursionPortal.SessionFlow.Label.ProviderName':'Nome provider',
    'MursionPortal.SessionFlow.Label.ReminderThisCanNotBeUndone':'PROMEMORIA: Questa operazione non può essere annullata!',
    'MursionPortal.SessionFlow.Label.ScenarioDetails':'Dettagli dello scenario',
    'MursionPortal.SessionFlow.Label.SessionStartEndDateTime':'Data e ora inizio e fine sessione',
    'MursionPortal.SessionFlow.Label.SupportCase':'Richiesta di supporto #*',
    'MursionPortal.SessionFlow.Label.YouAreAboutToDeletevideoRecording':'Stai per eliminare una registrazione video',
    'MursionPortal.SessionNotStarted.AudioVideoSettings':"Controlla le tue impostazioni audio e video",
    'MursionPortal.SessionNotStarted.DescriptionOne':"Mentre aspetti puoi:",
    'MursionPortal.SessionNotStarted.DescriptionTwo':"Questa pop-up scomparirà <code>{value}</code> minuti prima della simulazione. Fai clic su Aggiorna se la pagina non si aggiorna automaticamente in quel momento.",
    'MursionPortal.SessionNotStarted.Heading':"Sei un po' in anticipo",
    'MursionPortal.SessionNotStarted.PreSimTips':"Dai un'occhiata a questi <code>suggerimenti per la pre-simulazione</code>",
    'MursionPortal.SessionNotStarted.ScenarioDetails':"Esamina i <code>dettagli dello scenario</code>",
    'MursionPortal.StartDate':'Data di inizio',
    'MursionPortal.Survey.Thankyou.Msg':'Ti ringraziamo per il tempo che hai dedicato a questo sondaggio. La tua risposta è stata registrata.',
    'MursionPortal.Title.Hours':'Ore',
    'MursionPortal.Title.Minutes':'Minuti',
    'MursionPortal.UserConsentDeclineConfirmation.PermissionDoesNotLieWithLearner':'Indica se acconsenti o meno a procedere alla registrazione delle simulazioni. Se non acconsenti, non puoi procedere oltre con la sessione di simulazione e il software si chiuderà. Se acconsenti, le tue sessioni di simulazione verranno registrate e ogni registrazione verrà archiviata in modo sicuro per 12 mesi, momento in cui verrà archiviata.',
    'MursionPortal.UserConsentDeclineConfirmation.PermissionsLiesWithLearner':'Sicuro? Per procedere senza registrare, fai clic sul pulsante Conferma. Per modificare il consenso alla registrazione, fai clic sul pulsante Annulla.',
    'Session.Edit.Modal.JoinSimulationBtnTooltip':'Potrai partecipare <code>{minutesBeforeJoin}</code> minuti prima dell\'inizio della simulazione.',
    'Session.Edit.Modal.LastEmailTrigger':'Ultima e-mail inviata allo studente: {sentDate}',
    'Session.Edit.Modal.LastEmailTrigger.Sender.UserName':'Da: {userName} {sentTime}',
    'Session.Rescheduling.Email.Reminder.Msg':'Invio di promemoria e-mail di riprogrammazione allo studente!',
    'Settings.Config.Invitation.MursionSocialAttendance':'Acquisisci partecipazione Mursion Social:',
    'Settings.LTI.Modal.SendScore':'Invia punteggio',
    'Dashboard.ContractProgress.Column.Provider':'Provider',
    'Settings.SSO.CopyDeepLinkNotification':'Il tuo link è stato copiato negli appunti',
    'MursionPortal.Dashboard.Table.Empty.Body.Message':'i dettagli verranno caricati una volta effettuata la selezione',
    'Settings.SSO.ModalBody.ProjectSelector.DisabledTitle':'Prima seleziona l\'opzione giusta',
    'Settings.SSO.ModalBody.ScenarioSelector.DisabledTitle':'Prima seleziona un progetto',
    'MursionPortal.Label.LearnerListening':'Studente in ascolto',
    'MursionPortal.Label.InterruptionCount':'Ore',
    'MursionPortal.ConversationalFlow.label.videoTimeLine':'Sequenza temporale del video',
    'MursionPortal.ConversationalFlow.label.zoomTimeLine':'Zoom',
    'MursionPortal.LearnerSimSelector.Sim.Available':'Certificata e disponibile',
    'MursionPortal.Button.View':'Visualizza',
    'MursionPortal.Dashboard.NewSimulationAssigned':'Nuova simulazione assegnata',
    'MursionPortal.Dashboard.MakeUpSimulation':'Recupera simulazione',
    'MursionPortal.Dashboard.MissedSimulation':'Workshop perso',
    'MursionPortal.Dashboard.CompletedSimulations':'Simulazioni completate',
    'MursionPortal.Dashboard.BackToDashboard':'Torna alla dashboard',
    'MursionPortal.Dashboard.CancelledSimulation':'Workshop perso',
    'MursionPortal.ScenarioSearchList.Dialog.Title':'Hai una disponibilità ricorrente programmata in questo lasso di tempo. Vuoi aggiornare anche la tua disponibilità ricorrente? ',
    'MursionPortal.ScenarioSearchList.Dialog.UpdateText':'Voglio aggiornare la mia disponibilità solo per {startDate}',
    'MursionPortal.ScenarioSearchList.Dialog.UpdateText.ToDate':'Voglio aggiornare la mia disponibilità ricorrente da {date}',
    'Mursion.Portal.Status.Connected':'CONNESSO',
    'Mursion.Portal.Status.Late':'IN RITARDO',
    'Mursion.Portal.Status.Immersing':'STA COMINCIANDO',
    'Mursion.Portal.Status.Left':'HA ABBANDONATO',
    'Mursion.Portal.Status.Declined':'HA RIFIUTATO',
    'Login.EditFile': 'Modifica {file} e salva per ricaricare.',
    'Login.Hello': 'Salve',
    'Login.Title': 'Benvenuto nel Portale Mursion',
    'Login.Description': 'Progetta, programma e gestisci le tue simulazioni di realtà virtuale',
    'Login.PrivacyPolicy': 'informativa sulla privacy',
    'Login.PrivacyDataInfo': 'dati informativa sulla privacy',
    'Login.TechnicalSupport': 'assistenza tecnica',
    'Login.SignIn': 'Accedi',
    'Login.Next': 'Avanti',
    'Login.Email': 'E-mail',
    'Login.Password': 'Password',
    'Login.ForgotPassword': 'Password dimenticata?',
    'Login.Login': 'Accesso',
    'Login.EmailHint': 'Inserisci la tua email',
    'Login.PasswordHint': 'Inserisci la tua password',
    'Login.NewPasswordHint': 'inserire una nuova password',
    'Login.RepeatPasswordHint': 'inserisci di nuovo la nuova password',
    'Login.Image': 'Logo-Mursion',
    'Filters.Heading': 'FILTRI:',
    'Dashboard.LeftPaneHeader': 'PANNELLO DI CONTROLLO',
    'Dashboard.LeftPane.CategoryHeader.Users': 'UTENTI',
    'Dashboard.LeftPane.CategoryHeader.Projects': 'PROGETTI',
    'Dashboard.LeftPane.Project.ProjectActivity': 'Attività di progetti',
    'Dashboard.LeftPane.Project.ProjectUtilization': 'Utilizzo dei progetti',
    'Dashboard.LeftPane.CategoryHeader.Sessions': 'SESSIONI',
    'Dashboard.LeftPane.Session.SessionLearner': 'Studenti di sessione',
    'Dashboard.LeftPane.CategoryHeader.Legacy': 'LEGACY',
    'Dashboard.VideosTable.HoverTitle': ' ', // TODO: add translations
    'Dashboard.Button.ExportToEmailSentMsg': 'Il rapporto verrà presto inviato a {email}.',
    'Dashboard.Button.ExportToEmail.HoverTitle': 'Esporta dati',
    'Dashboard.Button.ExportToEmail.HoverTitleNotAvailable': 'L\'esportazione non è disponibile',
    'Dashboard.Next': 'Avanti',
    'Dashboard.Last': 'Ultimo',
    'Dashboard.Help': 'Guida',
    'Dashboard.First': 'Primo',
    'Dashboard.Back': 'Indietro',
    'FiltersHeading': 'FILTRI:',
    'Filters.Client': 'Cliente',
    'Filters.ClientPlaceHolderHint': 'Ricerca clienti...',
    'Filters.Industries': 'Settori',
    'Filters.IndustriesPlaceHolderHint': 'Ricerca settori...',
    'Filters.IndustriesDisabledTitle': 'Disabilitato',
    'Filters.Project': 'Progetto',
    'Filters.ProjectPlaceHolderHint': 'Cerca progetti...',
    'Filters.ProjectDisabledTitle': 'Seleziona prima i clienti',
    'Filters.RoleDisabledTitle': 'La selezione del ruolo è disabilitata',
    'Filters.Scenario': 'Scenario',
    'Filters.ScenarioPlaceHolderHint': 'Cerca scenari...',
    'Filters.ScenarioDisabledTitle': 'Seleziona prima clienti e progetti',
    'Filters.Team': 'Team',
    'Filters.TeamPlaceHolderHint': 'Cerca team...',
    'Filters.TeamDisabledTitle': 'Seleziona prima clienti e progetti',
    'Filters.SessionStatus': 'Stato sessione',
    'Filters.SessionStatusPlaceHolderHint': 'Cerca stato sessione...',
    'Filters.RegistrationStatus': 'Stato di registrazione',
    'Filters.RegistrationStatus.ShowAllRegistration': 'Stato di registrazione: tutti',
    'Filters.RegistrationStatus.ShowAcceptedRegistration': 'Registrazione accettata',
    'Filters.RegistrationStatus.ShowNotAcceptedRegistration': 'Registrazione non accettata',
    'Filters.Date.LastMonth': 'Il mese scorso',
    'Filters.Date.Yesterday': 'Ieri',
    'Filters.Date.Today': 'Oggi',
    'Filters.Date.Tomorrow': 'Domani',
    'Filters.Date.NextMonth': 'Il mese prossimo',
    'Filters.Date.From': 'Da',
    'Filters.Date.To': 'a',
    'Dashboard.UserActivityTable.HoverTitle': 'Visualizza riepilogo utente',
    'Dashboard.Export.ErrMsg': 'Il limite massimo di voci per l\'esportazione è {limit}. Modifica la tua richiesta e riprova.',
    'MursionPortal.Close': 'Chiudi',
    'Dashboard.UserTable.Column.Provider': 'Provider',
    'Dashboard.UserTable.Column.Client': 'Cliente',
    'Dashboard.UserTable.Column.ProfileId': 'ID profilo',
    'Dashboard.UserTable.Column.UserEmail': 'E-mail utente',
    'Dashboard.UserTable.Column.UserEmail.ButtonHoverTitle': 'Copia e-mail',
    'Dashboard.UserTable.Column.SSO': 'SSO',
    'Dashboard.UserTable.Column.DateRegistered.ButtonTitle': 'Rinvia invito',
    'Dashboard.UserTable.Column.Scenarios': 'Scenari',
    'Dashboard.UserTable.Column.Teams': 'Team',
    'Dashboard.UserTable.Column.Industry': 'Settore',
    'Dashboard.UserTable.Column.Accepted': 'Accettato',
    'Session.Tab.Scheduled': 'Pianificato',
    'Session.Tab.Active': 'Attivo',
    'Session.Tab.Past': 'Pregresso',
    'Session.Tab.Cancelled': 'Annullato',
    'Session.Search': 'Cerca',
    'Session.Table.Column.Time': 'Ora',
    'Session.Table.Column.Client': 'Cliente',
    'Session.Table.Column.Project': 'Progetto',
    'Session.Table.Column.SoftwareVersion': 'Versione software',
    'Session.Table.Column.Scenario': 'Scenario',
    'Session.Table.Column.Trainees': 'Tirocinanti',
    'Session.Table.Column.Training': 'formazione',
    'Session.Table.Column.Session': 'sessione',
    'Session.Token.Label.Clients': 'Clienti',
    'Session.Token.Label.Projects': 'Progetti',
    'Session.Token.Label.Scenarios': 'Scenari',
    'Session.Token.Label.Teams': 'Team',
    'Session.Token.Label.SessionStatuses': 'Stati delle sessioni',
    'Session.Button.More': 'Altro',
    'Session.Time.NotRunning': 'Non ancora in esecuzione',
    'Session.Time.Finished': 'Già finito',
    'Session.Time.Running': 'In esecuzione ora',
    'Session.Table.Column.Learners': 'Allievi',
    'Session.Table.Column.Team': 'Team',
    'Session.Table.Column.Trainer': 'Formatore',
    'Session.Table.Column.SimSpecialist': 'Specialista Sim',
    'Session.Table.Column.TimeUntil': 'Tempo fino a',
    'Session.Table.Column.Created': 'Creato',
    'Session.Table.Column.Cancelled': 'Annullato',
    'Session.Table.Caption.SessionDetailsList': 'Elenco dettagli sessione',
    'Session.Export': 'Esporta',
    'Session.Export.Message': 'Esporta contenuto filtrato per',
    'Session.CalendarDate.Start': 'Da:',
    'Session.CalendarDate.End': 'A:',
    'Session.Cancel': 'Annulla',
    'Session.Confirm': 'Conferma',
    'Session.Total': 'TOTALE',
    'Session.Filter.SessionNotFoundMessage': 'Non sono state trovate sessioni.',
    'Session.Filter.LearnerUpcomingSessionNotFoundMessage': 'Non hai sessioni imminenti in programma.',
    'Session.Filter.UpcomingSessionNotFoundMessage': 'Nessuna sessione imminente programmata.',
    'Session.Filter.LearnerActiveSessionNotFoundMessage': 'Non hai sessioni attive.',
    'Session.Filter.ActiveSessionNotFoundMessage': 'Nessuna sessione attiva.',
    'Session.Filter.LearnerCompletedSessionNotFoundMessage': 'Non hai ancora completato nessuna sessione.',
    'Session.Filter.SessionReportNotFoundMessage': 'Nessun rapporto di sessione disponibile.',
    'Session.Filter.CompletedSessionNotFoundMessage': 'Nessuna sessione è stata completata.',
    'Session.Filter.CancelledSessionNotFoundMessage': 'Nessuna sessione è stata annullata.',
    'Session.Edit.Modal.Table.Column.DateTime': 'Data/ora',
    'Session.Edit.Modal.Table.Column.UserRole': 'Utente (ruolo)',
    'Session.Edit.Modal.Table.Column.Change': 'Modifica',
    'Session.Edit.Modal.Table.Column.SessionStatus': 'Stato sessione',
    'Session.Edit.Modal.Table.Column.Reason': 'Motivo',
    'Session.Edit.Modal.AssetIndustry': 'Settore risorsa',
    'Session.Edit.Modal.SimulationEnvironment': 'Ambiente/i di simulazione',
    'Session.Edit.Modal.AvatarInSimulation': 'Avatar in simulazione/i',
    'Session.Edit.Modal.Date': 'Data',
    'Session.Edit.Modal.Time': 'Ora',
    'Session.Edit.Modal.DeliveryMode': 'Modalità di consegna',
    'Session.Edit.Modal.Duration': 'Durata',
    'Session.Edit.Modal.Status': 'Stato',
    'Session.Edit.Modal.LearnersAttended': 'Allievi partecipanti',
    'Session.Edit.Modal.ReasonForCancellation': 'Motivo dell\'annullamento',
    'Session.Edit.Modal.MoreInfo': 'Maggiori informazioni...',
    'Session.Edit.Modal.HideAdditionalInfo': 'Nascondi informazioni aggiuntive...',
    'Session.Edit.Modal.AddToCalendar': 'Aggiungi al calendario',
    'Session.Edit.Modal.AttendSession': 'Partecipa alla sessione',
    'Session.Edit.Modal.PleaseWait': 'Attendi…',
    'Session.Edit.Modal.CopySessionLink': 'Copia collegamento sessione',
    'Session.Edit.Modal.SessionLink': 'Collegamento alla sessione',
    'Session.Edit.Modal.SubmitReview': 'Invia revisione',
    'Session.Edit.Modal.SessionRequestPending': 'Richiesta di sessione in sospeso',
    'Session.Edit.Modal.Back': 'Indietro',
    'Session.Edit.Modal.Learners': 'Allievo/i:',
    'Session.Edit.Modal.Learners.UnauthenticatedCheckbox': 'Solo gli utenti non autenticati parteciperanno',
    'Session.Edit.Modal.Trainees': 'Tirocinante/i:',
    'Session.Edit.Modal.Trainer': 'Formatore:',
    'Session.Edit.Modal.SimSpecialist': 'Specialista Sim:',
    'Session.Edit.Modal.Notes': 'Note:',
    'Session.Edit.Modal.ClientNotes': 'Note cliente:',
    'Session.Edit.Modal.Notes.EmptyMessage': 'Non sono presenti note',
    'Session.Edit.Modal.Attendance': 'Partecipazione:',
    'Session.Edit.Modal.ShowHistory': 'Mostra cronologia...',
    'Session.Edit.Modal.HideHistory': 'Nascondi cronologia...',
    'Session.Edit.Modal.LearnersAttendedLabel': 'Allievi partecipanti:',
    'Session.Edit.Modal.SimulationContent': 'Contenuto della simulazione',
    'Session.Edit.Modal.SimulationContent.Name': 'Nome:',
    'Session.Edit.Modal.SimulationContent.Description': 'Descrizione:',
    'Session.Edit.Modal.SimulationContent.Image': 'Immagine:',
    'Session.Edit.Modal.RestoreSession.ConfirmationText': 'Sei sicuro di voler ripristinare questa sessione?',
    'Session.Edit.Modal.CancelTraining.ConfirmationText': 'Sei sicuro di voler annullare questa formazione?',
    'Session.Edit.Modal.CancelSession.ConfirmationText': 'Sei sicuro di voler annullare questa sessione?',
    'Session.Edit.Modal.SessionSwap.ConfirmationText': 'Sei sicuro di voler accettare lo scambio in questa sessione?',
    'Session.Edit.Modal.AcceptRequest.ConfirmationText': 'Sei sicuro di voler accettare questa richiesta di sessione?',
    'Session.Edit.Modal.AssignSS.ConfirmationText': 'Sei sicuro di voler assegnare un nuovo specialista Sim?',
    'Session.Edit.Modal.ChangeSS.ConfirmationText': 'Sei sicuro di voler cambiare uno specialista Sim?',
    'Session.Edit.Modal.Button.RestoreSession': 'Ripristina sessione',
    'Session.Edit.Modal.Button.CancelTraining': 'Annulla formazione',
    'Session.Edit.Modal.Button.CancelSession': 'Annulla sessione',
    'Session.Edit.Modal.Button.AcceptSwap': 'Accetta scambio',
    'Session.Edit.Modal.Button.Participate': 'Partecipa',
    'Session.Edit.Modal.Button.Swap': 'Scambia',
    'Session.Edit.Modal.Button.UpdateSession': 'Aggiorna sessione',
    'Session.Edit.Modal.Button.UpdateTraining': 'Aggiorna formazione',
    'Session.Edit.Modal.Button.AcceptRequest': 'Accetta richiesta',
    'Session.Edit.Modal.Button.AssignSS': 'Assegna SS',
    'Session.Edit.Modal.Button.ChangeSS': 'Cambia SS',
    'Session.Attendance.Table.Column.UserName': 'Nome utente',
    'Session.Attendance.Table.Column.Time': 'Ora',
    'Session.Attendance.Table.Column.Status': 'Stato',
    'Session.ViewSessionReport': 'Visualizza rapporto di sessione',
    'Session.ViewEdit': 'Visualizza/Modifica sessione',
    'Session.Edit.Modal.Table.HistoryNotFoundMessage': 'Non è ancora presente cronologia',
    'Session.Export.ErrMsg': 'Il limite massimo di voci per l\'esportazione è {limit}. Modifica la tua richiesta e riprova.',
    'Session.Table.Column.Action': 'Azione',
    'Session.Edit.Modal.SessionSimSpecialistField.EmptyMsg.NoTrainers': 'Nessun formatore da selezionare',
    'Session.Edit.Modal.SessionSimSpecialistField.EmptyMsg.NoSimSpecialists': 'Nessuno specialista Sim da selezionare',
    'Session.Edit.Modal.SessionSimSpecialistField.NoSelectedMsg.NoTrainers': 'Nessun formatore selezionato',
    'Session.Edit.Modal.SessionSimSpecialistField.NoSelectedMsg.NoSimSpecialists': 'Nessuno specialista Sim selezionato',
    'Session.Edit.Modal.SessionSimSpecialistField.EditIconLabel': 'modifica',
    'Session.Edit.Modal.SessionSimSpecialistField.Search.Placeholder': 'Cerca',
    'Session.Edit.Modal.SessionSimSpecialist.Button.Accept': 'Accetta',
    'ConfirmationDialogue.AddReasonForChange': 'Aggiungi un motivo per la modifica',
    'ConfirmationDialogue.Button.Submit': 'Invia',
    'ConfirmationDialogue.Button.Cancel': 'Annulla',
    'ConfirmationDialogue.Button.Ok': 'Ok',
    'ConfirmationDialogue.ReasonForChange': 'Motivo della modifica',
    'ConfirmButton.CancelSession.ConfirmCancellation': 'Conferma annullamento',
    'ConfirmButton.CancelSession': 'Annulla sessione',
    'ConfirmButton.CancelSession.No': 'No',
    'Pagination.Button.First': 'Primo',
    'Pagination.Button.Back': 'Indietro',
    'MursionPortal.UserName': 'Nome utente',
    'MursionPortal.Email': 'E-mail',
    'ClientUserTable.Column.Roles': 'Ruoli',
    'MursionPortal.Department': 'Reparto',
    'MursionPortal.Location': 'Posizione',
    'ClientUserTable.Column.Projects': 'Progetti',
    'ClientUserTable.Column.Teams': 'Team',
    'ClientUserTable.Column.Accepted': 'Accettato',
    'ClientUserTable.HoverTitle': 'Visualizza/Modifica utente',
    'ClientUserTable.NoUserError': 'Non ci sono ancora utenti',
    'MursionPortal.FirstName': 'Nome',
    'MursionPortal.LastName': 'Cognome',
    'MursionPortal.Phone': 'Telefono',
    'Users.TableModal.EmailPlaceHolder': 'esempio@dominio.com',
    'Users.TableModal.Title': 'Titolo',
    'Users.TableModal.LocationPlaceHolder': 'Città, Paese',
    'Users.TableModal.DepartmentPlaceHolder': 'Nome del reparto',
    'Users.TableModal.TitlePlaceHolder': 'Qualifica lavorativa',
    'Users.Table.Modal.SettingsHeader': 'Impostazioni facilitatore/allievo',
    'Users.Table.ModalHeader': 'Info utente',
    'Users.Table.ModalButton.Cancel': 'Annulla',
    'Users.Table.ModalButton.Delete': 'Elimina',
    'Users.Table.ModalButton.Save': 'Salva',
    'Users.Table.ModalButton.SendInvite': 'Manda invito',
    'Users.Table.ModalButton.Restore': 'Ripristina',
    'Users.Table.Modal.Teams.Text': 'Non è stato ancora aggiunto nessun team.',
    'Users.Table.Modal.Teams': 'Team',
    'Users.Table.Modal.EmployeeIdentification': 'Identificazione dei dipendenti',
    'Users.Table.Modal.Timezone': 'Fuso orario',
    'Users.Table.Modal.Roles': 'Ruoli',
    'Users.Table.Modal.Settings.LearnerButton': 'Come allievo',
    'Users.Table.Modal.Settings.FacilitatorButton': 'Come facilitatore',
    'Users.Button.CreateUserButton': 'Crea Utente ',
    'Users.Button.Cancel': 'Annulla',
    'Users.Button.ImportUsers': 'Importa utenti',
    'Users.Button.Export': 'Esporta',
    'Users.heading.ExportContentFilteredBy': 'Esporta contenuto filtrato per',
    'Users.Button.Active': 'ATTIVO',
    'Users.Button.Archived': 'ARCHIVIATO',
    'Users.Button.All': 'TUTTI',
    'Users.Text.Total': 'TOTALE',
    'Users.Heading.NewUsers': 'Nuovi utenti',
    'Users.Placeholder': 'esempio@dominio.com',
    'Users.Button.AddUser': 'Aggiungi utente ',
    'Users.Button.CreateUser': 'Crea utente',
    'Users.Button.CreateUsers': 'Crea utenti',
    'Users.Heading.ImportNewUsers': 'Importa nuovi utenti',
    'Users.Heading.ImportNewExternalUsers': 'Importa nuovi utenti esterni',
    'Users.Button.ImportUserList': 'Importa elenco utenti',
    'Users.Text.InstructionMessage.UsersWithoutPredefined': 'Gli utenti senza team predefiniti verranno assegnati a',
    'Users.Text.AddCsv': 'Aggiungi il file .csv o .xslx contenente un elenco di indirizzi e-mail inseriti in una colonna.',
    'Users.Text.ToImport': 'Per importare campi aggiuntivi, segui queste linee guida:',
    'Users.Text.ListUser': 'L\'e-mail dell\'utente deve essere separata dall\'elenco dei team utenti da virgola, punto e virgola, barra verticale o due punti.',
    'Users.Text.ListTeams': 'I team devono essere separati l\'uno dall\'altro da virgola.',
    'Users.Text.ListFields': 'I campi e ogni team devono essere specificati in celle separate.',
    'Users.Text.UserRole': 'Il tipo di ruolo utente sarà predefinito su Allievo',
    'Users.Text.UnlessOtherwiseIndicated': 'salvo diversa indicazione',
    'Users.Text.SampleImport': 'Puoi scaricare un file di importazione esemplificativo qui:',
    'Users.DownloadCsvTemplate': 'Scarica modello CSV',
    'Users.DownloadExcelTemplate': 'Scarica modello Excel',
    'Users.Button.Update': 'Aggiorna',
    'Users.Labels.Clients': 'Clienti',
    'Users.Labels.Projects': 'Progetti',
    'Users.Labels.Scenarios': 'Scenari',
    'Users.Labels.Teams': 'Team',
    'Users.Labels.Roles': 'Ruoli',
    'Users.Text.OnlyAccepted': 'Solo accettati:',
    'Users.Button.Confirm': 'Conferma',
    'Users.Button.AccOwner': 'Proprietario acc',
    'Users.Button.Facilitator': 'Facilitatore',
    'Users.Button.Learner': 'Allievo',
    'Users.Button.ProfServices': 'Servizi prof.',
    'Users.Button.ProjectManager': 'Responsabile di progetto',
    'Users.Button.SimSpecialist': 'Specialista Sim',
    'Users.Button.ITManager': 'Responsabile IT',
    'Users.Button.BusinessAdmin': 'Amministratore aziendale',
    'Users.Button.SupportAdmin': 'Amministratore del supporto',
    'Users.Button.DevAdmin': 'Amministratore svi.',
    'Users.Button.ImportExternalUsers': 'Importa utenti esterni',
    'Users.Button.AssignLearners': 'Assegna allievi',
    'Users.CreateUser.Email.Placeholder': 'esempio@dominio.com',
    'Users.Text.Instructions': 'Aggiungi il file .csv o .xslx che contiene l\'elenco degli utenti con i seguenti campi: email, ssoid, First name, Last name, Role, Team. Tutti i campi, eccetto Team, sono obbligatori.',
    'Users.Text.Guidelines': 'Segui queste indicazioni:',
    'Users.Button.CreateUser.PencilIcon': 'Modifica dettagli utente',
    'Users.Button.CreateUser.TrashIcon': 'Rimuovi utente',
    'Users.ExportButton.TextYes': 'Sì',
    'Users.ExportButton.TextNo': 'No',
    'Users.Button.OK': 'OK',
    'Users.Button.AddProject': 'Aggiungi progetto',
    'Users.CreateUser.Label.AddToProjects': 'Aggiungi a progetto/i',
    'Users.UserInfo.Label.AddTeams': 'Aggiungi team',
    'Users.UserInfo.Label. SelectATeam': 'Seleziona un team',
    'Users.UserInfo.Label.AssignToTeam': 'Assegna a team:',
    'Users.CreateUser.Label.NoProjectsSpecified': 'Nessun progetto specificato',
    'Users.Option.SelectClient': 'Seleziona cliente',
    'Users.Label.Client': 'Cliente:',
    'Users.Label.RemoveTeam': 'Elimina team',
    'Users.Label.ChooseATeam': 'Scegli un team',
    'Users.Project.Warning': 'Questo cliente non ha progetti. Selezionane un altro.',
    'Users.Project.Warning.UnknownProject': 'Progetto sconosciuto',
    'Users.ProjectContainer.Warning': 'Si deve aggiungere almeno un progetto',
    'Users.CompanyUsers.Button.Ok': 'Ok',
    'Users.CompanyUsers.Button.Accept': 'Accetta',
    'Users.CreateUser.Label.Tier': 'Livello',
    'Users.CreateUser.Label.HireDate': 'Data di assunzione',
    'Users.CreateUser.Label.StationType': 'Tipo stazione',
    'Users.CreateUser.ToggleButton.Home': 'Casa',
    'Users.CreateUser.ToggleButton.Office': 'Ufficio',
    'Users.CreateUser.Label.Hours': 'Ore',
    'Users.CreateUser.ToggleButton.FullTime': 'Tempo pieno',
    'Users.CreateUser.ToggleButton.PartTime': 'Tempo parziale',
    'Users.CreateUser.Label.ZoomID': 'ID Zoom',
    'Users.CreateUser.ZoomID.Placeholder': 'ID Zoom',
    'Users.CreateUser.ZoomID.Hover': 'Inserisci un ID Zoom',
    'Users.CreateUser.Label.UserRoles': 'Ruolo/i utente',
    'Users.CreateUser.Label.ProjectManagerSettings': 'Impostazioni Responsabile di progetto',
    'Users.CreateUser.Label.SimSpecialistSettings': 'Impostazioni Specialista Sim',
    'Users.CreateUser.Label.Certificates': 'Attestati',
    'Users.CreateUser.Label.TurnOnEditCertificates': 'Attiva la modifica degli attestati',
    'Users.CreateUser.Label.TurnOffEditCertificates': 'Disattiva la modifica degli attestati',
    'Users.CreateUser.Label.NoCertificatesHaveBeenAddedYet': 'Non è stato ancora aggiunto alcun attestato.',
    'Users.CreateUser.Label.AddCertificate': 'Aggiungi attestato',
    'Users.CreateUser.Label.CertificateOwner': 'Proprietario dell\'attestato',
    'Users.CreateUser.Button.RemoveCertificate': 'Rimuovi attestato',
    'Users.TableHeading.Name': 'Nome',
    'Users.TableHeading.Email': 'E-mail',
    'Users.TableHeading.Roles': 'Ruoli',
    'Users.TableHeading.Location': 'Posizione',
    'Users.TableHeading.Accepted': 'Accettato',
    'Users.Table.Hover.ViewEditUser': 'Visualizza/Modifica utente',
    'Users.Table.Hover.NoUsersYet': 'Non ci sono ancora utenti',
    'Users.CreateUser.Button.Client': 'Cliente',
    'Users.CreateUser.Label.ChooseAClient': 'Scegli un cliente',
    'Users.CreateUser.Label.SelectAClient': 'Seleziona un cliente',
    'Users.CreateUser.Label.ChooseACertificate': 'Scegli un attestato',
    'Users.CreateUser.Label.SelectACertificate': 'Seleziona un attestato',
    'Users.CreateUser.Label.NoCertificatesToSelect': 'Nessun atetstato da selezionare',
    'Users.CreateUser.Button.AddCertificate': 'Aggiungi attestato  ',
    'Users.CreateUser.PlaceHolder.SelectTier': 'Seleziona livello',
    'Users.RequiredField.Warning': 'Controlla che tutti i campi siano compilati correttamente',
    'Users.RequiredField.ApplyChanges': 'Applica modifiche',
    'Users.Heading.NewUserInfo': 'Informazioni sul nuovo utente',
    'Users.SearchPlaceHolderText': 'Inserisci nome utente',
    'Users.SearchNoUserFoundError': 'Non sono stati trovati utenti clienti.',
    'Users.Table.Modal.Settings.AddTeamHeader': 'Aggiungi team',
    'Users.Table.Modal.Settings.ChooseTeamHeader': 'Scegli un team',
    'Users.Table.Modal.Settings.AssignTeamHeader': 'Assegna a team:',
    'Users.Table.Modal.Settings.SelectTeamOptionHeader': 'Seleziona un team',
    'Users.Table.Modal.Settings.TeamLearner': '(allievo)',
    'Users.Table.Modal.Settings.TeamFacilitator': '(facilitatore)',
    'Users.Table.Modal.Settings.RemoveTeamOption': 'Rimuovi team',
    'Users.Table.Modal.Settings.TurnOnEditTeamsOption': 'Attiva la modifica dei team',
    'Users.Table.Modal.Settings.TurnOffEditTeamsOption': 'Disattiva la modifica dei team',
    'Users.Total': 'TOTALE',
    'Users.Table.Modal.ArchiveHeader.ConfirmArchiveUser': 'Conferma archivia utente',
    'Users.Table.Modal.ArchiveText': 'Sei sicuro di voler archiviare questo utente?',
    'Users.Table.Modal.ArchiveUserButton': 'Archivia utente',
    'Users.Table.Modal.RestoreHeader.ConfirmRestoreUser': 'Conferma ripristino utente',
    'Users.Table.Modal.RestoreText': 'Sei sicuro di voler ripristinare questo utente?',
    'Users.Table.Modal.RestoreUserButton': 'Ripristina utente',
    'Users.Table.Modal.CancelButton': 'Annulla',
    'Users.Table.Modal.ArchiveButton': 'Archivia',
    'Users.Table.Modal.RestoreButton': 'Ripristina',
    'Users.Table.Modal.SendInviteButton': 'Manda invito',
    'Users.CreateUserButton.Modal.CreateUserButton': 'Crea utente',
    'Users.Table.Modal.UpdateUserButton': 'Aggiorna utente',
    'Users.ViewUserOptions': 'Visualizza utente {companyName}',
    'Users.NewUserOptions': 'Nuovo utente {companyName}',
    'Users.EditUserOptions': 'Modifica utente {companyName}',
    'Reports.SessionListHeader.Completed': 'Completato',
    'Reports.SessionListHeader.TotalSessionTime': 'Durata totale della sessione,',
    'Reports.SessionListHeader.TotalActualTime': 'Totale durata effettiva,',
    'Reports.SessionListHeader.SimulationCompletionTime': 'Tempo di completamento della simulazione',
    'Reports.SessionListHeader.CantOpenReport': 'Attendi mentre il nostro motore di analisi elabora i tuoi risultati. Potrebbe essere necessario aggiornare questa pagina dopo alcuni minuti.',
    'Reports.SessionListHeader.NoReportsFound': 'Nessun rapporto trovato.',
    'Reports.ReportCard.Button.ReviewSimulation': 'REVISIONE DELLA SIMULAZIONE',
    'Reports.ReportCard.Strategies': 'Strategie',
    'Reports.ReportCard.NotChecked': 'Non convalidato',
    'Dashboard.SchedulingInfoTable.Modal.SortBy': 'Ordina per:',
    'Dashboard.SchedulingInfoTable.Modal.NoItemsFound': 'Nessun è stato trovato nessun elemento.',
    'Dashboard.SchedulingInfoTable.Modal.Teams': 'Team',
    'Dashboard.SchedulingInfoTable.Modal.Teams.Placeholder': 'Inserisci il nome del team',
    'Dashboard.SchedulingInfoTable.Modal.Teams.EmptyMessage': 'Non è stato trovato nessun team',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.TeamName': 'Nome del team:',
    'Dashboard.SchedulingInfoTable.Modal.Learners': 'Allievi:',
    'Dashboard.SchedulingInfoTable.Modal.Projects': 'Progetti:',
    'Dashboard.SchedulingInfo.Filter.SearchIndustries.PlaceholderHint': 'Ricerca settori...',
    'Dashboard.SchedulingInfo.Filter.IndustryDisabledTitle': 'Disabilitato',
    'Dashboard.SchedulingInfo.Filter.UsedSoftwareVersion.PlaceholderHint': 'Versioni software utilizzate...',
    'Dashboard.SchedulingInfoTable.Modal.Total': 'TOTALE',
    'Dashboard.SchedulingInfoTable.Modal.ScenarioName': 'Nome scenario',
    'Dashboard.SchedulingInfoTable.Modal.SessionDate': 'Data sessione',
    'Dashboard.SchedulingInfoTable.Modal.ProjectName': 'Nome progetto',
    'Dashboard.SchedulingInfoTable.Modal.SoftwareVersion': 'Versione del software',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.DeliveryMode': 'Modalità di consegna',
    'Dashboard.SchedulingInfoTable.Modal.SessionStatus': 'Stato sessione',
    'Dashboard.SchedulingInfoTable.Modal.Name': 'Nome',
    'Dashboard.SchedulingInfoTable.Modal.Status': 'Stato',
    'Dashboard.SchedulingInfoTable.Modal.Recording': 'La registrazione va a',
    'Dashboard.SchedulingInfoTable.Modal.TeamName': 'Nome del team',
    'Dashboard.SchedulingInfoTable.Modal.Learner': 'Allievi',
    'Dashboard.SchedulingInfoTable.Modal.CompletedSessions': 'Sessioni completate',
    'Dashboard.SchedulingInfoTable.Modal.DeliveryMode': 'Modalità di consegna',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.ProjectName': 'Nome del progetto',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.SoftwareVersion': 'Versione software',
    'Dashboard.SchedulingInfoTable.Modal.Timeline': 'Sequenza temporale',
    'Dashboard.SchedulingInfoTable.Modal.SchedulesSessions': 'Sessioni programmate',
    'Dashboard.SchedulingInfoTable.Modal.MissedSessions': 'Sessioni miste',
    'Dashboard.SchedulingInfoTable.Modal.LateCancellations': 'Annullamenti \"tardivi\"',
    'Dashboard.SchedulingInfoTable.Modal.TimeSpent': 'Tempo impiegato',
    'Dashboard.SchedulingInfoTable.Modal.CancelledBy': 'Annullato da',
    'Dashboard.SchedulingInfoTable.Modal.CancellationReason': 'Motivo dell\'annullamento',
    'Dashboard.SchedulingInfoTable.Modal.DateScheduled': 'Data programmata',
    'Dashboard.SchedulingInfoTable.Modal.DateCancelled': 'Data annullata',
    'Dashboard.SchedulingInfoTable.Modal.Filter.Teams': 'Team',
    'Dashboard.SchedulingInfoTable.Modal.Placeholder.EnterTeamName': 'Inserisci il nome del team',
    'Dashboard.SchedulingInfoTable.Modal.TeamsEmptyMessage': 'Non è stato trovato nessun team',
    'Dashboard.SchedulingInfoTable.Modal.Filter.TeamName': 'Nome team',
    'Dashboard.SchedulingInfoTable.Modal.Filter.Scenarios': 'Scenari',
    'Dashboard.SchedulingInfoTable.Modal.Placeholder.EnterScenarioName': 'Inserisci il nome dello scenario',
    'Dashboard.SchedulingInfoTable.Modal.ScenariosEmptyMessage': 'Non sono stati trovati scenari',
    'Dashboard.SchedulingInfoTable.Modal.Filter.ScenarioName': 'Nome scenario',
    'Dashboard.SchedulingInfoTable.Modal.Filter.ProjectName': 'Nome del progetto',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SoftwareVersion': 'Versione software',
    'Dashboard.SchedulingInfoTable.Modal.Filter.ScenarioStatus': 'Stato dello scenario',
    'Dashboard.SchedulingInfoTable.Modal.Filter.DeliveryMode': 'Modalità di consegna',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsScheduled': 'Sessioni pianificate',
    'Dashboard.SchedulingInfoTable.Modal.SessionsEmptyMessage': 'Non sono state trovate sessioni',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsDateTime': 'Data/ora della sessione',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsCompleted': 'Sessioni completate',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsMissed': 'Sessioni perse',
    'Dashboard.SchedulingInfoTable.Modal.Filter.LateCancellations': 'Annullamenti "tardivi"',
    'Dashboard.SchedulingInfoTable.Modal.Filter.CancelledBy': 'Annullato da',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsError': 'Errore di sessione',
    'RestorePassword.EnterEmailAddress': 'Inserisci il tuo indirizzo e-mail e fai clic sul pulsante Ripristina: ti verrà inviata un\'e-mail con un collegamento per reimpostare la password.',
    'RestorePassword.Email': 'E-mail',
    'RestorePassword.Button.Cancel': 'Annulla',
    'RestorePassword.Button.Restore': 'Ripristina',
    'RestorePassword.Modal.IfValidEmailAddressEntered': 'Se è stato inserito un indirizzo valido, un\'e-mail con le istruzioni di ripristino è stata inviata all\'account associato. Contatta il supporto tecnico se il problema persiste.',
    'RestorePassword.Modal.Button.GotIt': 'Tutto chiaro',
    'Trainings.Heading.AllTrainingSessions': 'tutte le sessioni di formazione',
    'Requests.Table.Swap': 'SCAMBIA',
    'Requests.Table.SchedulingRequests': 'Richieste di pianificazione',
    'Requests.Total': 'TOTALE',
    'Requests.Table.NoSessionsFound': 'Non sono state trovate sessioni.',
    'Requests.Table.NoUpcomingSwapSessions': 'Non ci sono sessioni di SCAMBIO imminenti.',
    'Requests.Table.NoUpcomingRequests': 'Non hai richieste imminenti.',
    'GetTimeToEvents.Day': 'Giorno',
    'GetTimeToEvents.Days': 'Giorni',
    'GetTimeToEvents.Minute': 'Minuto',
    'GetTimeToEvents.Minutes': 'Minuti',
    'GetTimeToEvents.Hour': 'Ora',
    'GetTimeToEvents.Hours': 'Ore',
    'GetTimeToEvents.LessThanOneMinute': 'Meno di 1 minuto',
    'SessionReports.SessionInformation': 'Informazione sulla sessione',
    'SessionReports.SessionInformation.Hover.Information': 'Informazioni',
    'SessionReports.SmartMetrics': 'Metriche intelligenti',
    'SessionReports.SendVideoLink.Modal.ShareLinkToVideo': 'Condividi link al video',
    'SessionReports.Button.BackToReports': 'Torna a Rapporti',
    'SessionReports.Button.BackToTrainings': 'Torna a Formazioni',
    'SessionReports.Button.HideInfo': 'Nascondi informazioni',
    'SessionReports.Button.ShowInfo': 'Mostra informazioni',
    'SessionReports.Button.SendVideoLink': 'Invia collegamento video',
    'SessionReports.TryAgain': 'Riprova',
    'SessionReports.Mode': 'Modalità:',
    'SessionReports.SessionLength': 'Durata della sessione:',
    'SessionReports.LearnersAttended': 'Allievi partecipanti:',
    'SessionReports.ScenarioTimeline': 'Sequenza temporale dello scenario:',
    'SessionReports.ActualSessionTime': 'Tempo di sessione effettivo:',
    'SessionReports.ActualSimulationTime': 'Tempo di simulazione effettivo:',
    'SessionReports.LastUserStatus': 'Ultimo stato dell\'utente:',
    'SessionReports.SessionStatus': 'Stato sessione:',
    'SessionReports.Delivery': 'Consegna',
    'SessionReports.SendVideoLink.Modal.AddEmails': 'Aggiungi e-mail',
    'SessionReports.SendVideoLink.Modal.Button.AddEmail': 'Aggiungi e-mail ',
    'SessionReports.SendVideoLink.Modal.EmailPlaceHolder': 'esempio@dominio.com',
    'SessionReports.SendVideoLink.Modal.Button.Cancel': 'Annulla',
    'SessionReports.SendVideoLink.Modal.Button.Send': 'Invia',
    'SessionReports.FactSessionInfo': 'Informazioni fattuali sulla sessione',
    'SessionReports.Text.NoTraineeParticipatedInSession': 'Nessun tirocinante ha partecipato alla sessione',
    'SessionReports.Text.NoLearnerParticipatedInSession': 'Nessun allievo ha partecipato alla sessione',
    'SessionReports.ToolTip.TrackingYours': 'È molto importante che la tua percezione di un esito o di un elemento strategico sia allineata a quella degli avatar. Ciò indica che stai monitorando il tuo impatto sugli avatar, contribuendo così a migliorare l\'efficacia sociale. Queste informazioni sono contenute nella tabella seguente.',
    'SessionReports.ToolTip.TrackingLearners': 'È molto importante che la percezione dell\'allievo di un esito o di un elemento strategico sia allineata a quella degli avatar. Ciò indica che l\'allievo sta monitorando il proprio impatto sugli avatar, contribuendo così a migliorare l\'efficacia sociale. Queste informazioni sono contenute nella tabella seguente.',
    'SessionReports.ToolTip.AvatarsHeaderYours': 'Questa colonna indica la percezione dell\'avatar delle prestazioni della simulazione. Un segno di spunta indica che gli avatar hanno percepito che sei riuscito a raggiungere l\'esito, mentre una croce indica margini di miglioramento.',
    'SessionReports.ToolTip.AvatarsHeaderLearners': 'Questa colonna indica la percezione degli avatar delle prestazioni di simulazione dell\'allievo. Un segno di spunta indica che gli avatar hanno percepito che l\'allievo è riuscito a raggiungere l\'esito, mentre una croce indica che è possibile migliorare.',
    'SessionReports.ToolTip.SelfHeaderYours': 'Questa colonna indica la tua valutazione delle prestazioni della simulazione. Un segno di spunta indica che pensi di essere riuscito nel tuo intento, mentre una croce indica margini di miglioramento.',
    'SessionReports.ToolTip.SelfHeaderLearners': 'Questa colonna indica la valutazione dell\'allievo delle proprie prestazioni di simulazione. Un segno di spunta indica che l\'allievo ritiene di essere riuscito nel suo intento, mentre una croce indica margini di miglioramento.',
    'SessionReports.ToolTip.VideoLink': 'Puoi condividere un link al tuo video sulle prestazioni di simulazione con i tuoi colleghi. Inserisci semplicemente la loro e-mail nel riquadro pop-up. Tieni presente che le tue analisi non verranno condivise con i tuoi colleghi.',
    'SessionReports.ToolTip.SmartMetrics': 'Le metriche intelligenti vengono generate tramite l\'uso di algoritmi avanzati. Osserva queste misure generate dal computer per stabilire se hanno un impatto sul monitoraggio generale durante le varie simulazioni.',
    'SessionReports.ToolTip.MiScore': 'Il punteggio Mursion Index (MI) è un indicatore della tua efficacia sociale durante questa conversazione. Viene calcolato utilizzando vari dati, tra cui il modello di conversazione e il suo impatto sugli avatar. Prova a valutare il tuo impatto sugli avatar e affronta le conversazioni in modi diversi per stabilire come puoi influire positivamente sul tuo punteggio MI.',
    'SessionReports.ToolTip.ListeningSpeakingTime': 'Questo è un indicatore che mostra il rapporto tra il tempo trascorso a parlare e l\'ascolto durante la conversazione.',
    'SessionReports.ToolTip.Emotions': 'Emozioni (beta): stiamo attualmente esaminando algoritmi che ci consentano di interpretare i tuoi modelli vocali per determinare il sentiment generale. Osserva questi dati per vedere se hai avuto una connotazione positiva, negativa o neutra nei tuoi modelli vocali nel tempo. Ancora più importante, osserva l\'impatto che hai avuto sui modelli vocali degli avatar.',
    'SessionReports.ToolTip.TurnTracking': 'Il cosiddetto "turn taking" è un insieme di misure che consentono di comprendere la natura collaborativa / l\'efficacia sociale tra te e gli avatar.',
    'SessionReports.ToolTip.Pauses': 'Le pause sono una misura indiretta della tua fluidità durante la conversazione.',
    'SessionReports.ToolTip.ArticulationRate': 'Questa è una misura del ritmo con cui viene fornito un insieme continuo di informazioni.',
    'SessionReports.TableHeader.Outcome': 'Esito',
    'SessionReports.TableHeader.Self': 'Personale',
    'SessionReports.TableHeader.Avatars': 'Avatar',
    'SessionReports.TableHeader.Strategies': 'Strategie',
    'MursionPortal.Button.Cancel': 'Annulla',
    'Settings.Config.Heading.Industries': 'SETTORI',
    'Settings.Config.Button.New': 'Nuovo',
    'Settings.Config.Note': 'NOTA:',
    'Settings.Config.Note.Text.ConfigSettingsGlobal': 'Le impostazioni di configurazione sono globali. Le modifiche verranno applicate a tutti i clienti e utenti.',
    'Settings.Config.Heading.SimSpecialist': 'LIVELLI SPECIALISTA SIM',
    'Settings.Config.Heading.Scheduling': 'PIANIFICAZIONE',
    'Settings.Config.Heading.Scheduling.Description': '(Specialista Sim)',
    'Settings.Config.Scheduling.MaximumFullTimeSimSpecialistHours': 'Ore massime di specialista Sim a tempo pieno',
    'Settings.Config.Scheduling.MaximumPartTimeSimSpecialistHours': 'Ore massime di specialista Sim a tempo parziale',
    'Settings.Config.Scheduling.MaximumContinuousSimSpecialistHours': 'Ore massime continue di specialista Sim',
    'Settings.Config.Scheduling.LengthOfSimSpecialistRequiredBreak': 'Durata della pausa richiesta dello specialista Sim',
    'Settings.Config.Scheduling.FutureAvailabilityForSimSpecialist': 'Finestra di disponibilità futura per gli specialisti Sim',
    'Settings.Config.Scheduling.SimSpecialistShiftTime': 'Tempo turno specialista Sim',
    'Settings.Config.Scheduling.AvailableTimeBlockMaximumPerDay': 'Blocco temporale di disponibilità massimo al giorno',
    'Settings.Config.Scheduling.AvailableTimeBlockMinimumPerDay': 'Blocco temporale di disponibilità minimo al giorno',
    'Settings.Config.Scheduling.AvailabilityTimeStep': 'Fase del tempo di disponibilità',
    'Settings.Config.Scheduling.AdvanceSSScheduleConfirmation': 'Con quanto anticipo deve essere confermato il programma dello SS',
    'Settings.Config.Scheduling.RequestUnfulfilledReminder': 'Quando inviare il promemoria "richiesta non soddisfatta"',
    'Settings.Config.Scheduling.FirstEmailReminder': 'Quando inviare il primo promemoria tramite e-mail',
    'Settings.Config.Scheduling.SecondEmailReminder': 'Quando inviare il secondo promemoria tramite e-mail',
    'Settings.Config.Scheduling.BookedSessionWithNoAssignedLearner': 'Quando notificare la sessione prenotata senza allievo assegnato',
    'Settings.Config.Scheduling.UnfulfilledSessionSwapRequestReminder': 'Quando inviare un promemoria sulla richiesta di scambio di sessione non soddisfatta',
    'Settings.Config.Scheduling.NextSessionReminder': 'Quando inviare il promemoria della sessione successiva a seconda del tempo intercorso tra la creazione della sessione e l\'ora di inizio della sessione pianificata',
    'Settings.Config.Scheduling.ReservedAvailabilityExpired': 'Quando la disponibilità prenotata è scaduta',
    'Settings.Config.Scheduling.StartTimeForConsideringMissedSession': 'Tempo dopo l\'ora di inizio in cui una sessione è considerata \"Mancata\"',
    'Settings.Config.Scheduling.EndTimeForConsideringMissedSession': 'Tempo dopo l\'ora di fine in cui una sessione è considerata \"Mancata\"',
    'Settings.Config.Scheduling.RejoinSessionAfterError': 'I partecipanti alla sessione a tempo devono ripartecipare alla sessione dopo l\'errore',
    'Settings.Config.Scheduling.TimeDisabledForSessionScheduling': 'Periodo di tempo disabilitato per la pianificazione della sessione',
    'Settings.Config.Scheduling.SessionTimeStep': 'Fase temporale della sessione',
    'Settings.Config.Heading.ProjectAndScenario': 'PROGETTO E SCENARIO',
    'Settings.Config.ProjectAndScenario.MaximumProjectTimelineDuration': 'Durata massima della sequenza temporale del progetto',
    'Settings.Config.ProjectAndScenario.MinimumProjectTimelineDuration': 'Durata minima della sequenza temporale del progetto',
    'Settings.Config.ProjectAndScenario.MaximumScenarioTimelineDuration': 'Durata massima della sequenza temporale dello scenario',
    'Settings.Config.ProjectAndScenario.MinimumScenarioTimelineDuration': 'Durata minima della sequenza temporale dello scenario',
    'Settings.Config.ProjectAndScenario.MinimumSessionLength': 'Durata minima della sessione',
    'Settings.Config.ProjectAndScenario.MaximumSessionLength': 'Durata massima della sessione',
    'Settings.Config.ProjectAndScenario.MinimumSimulationLength': 'Durata minima della simulazione',
    'Settings.Config.ProjectAndScenario.SessionLengthStep': 'Fase della durata della sessione',
    'Settings.Config.ProjectAndScenario.MinimumLearnersInRemoteSession': 'Numero minimo di allievi in sessioni remote',
    'Settings.Config.ProjectAndScenario.MaximumLearnersInRemoteSession': 'Numero massimo di allievi in sessioni remote',
    'Settings.Config.ProjectAndScenario.MinimumLearnersInFishbowlSession': 'Numero minimo di allievi in sessioni locali',
    'Settings.Config.ProjectAndScenario.MaximumLearnersInFishbowlSession': 'Numero massimo di allievi in sessioni locali',
    'Settings.Config.ProjectAndScenario.MinimumLearnersInGroupSession': 'Numero minimo di allievi in sessioni di gruppo',
    'Settings.Config.ProjectAndScenario.MaximumLearnersInGroupSession': 'Numero massimo di allievi in sessioni di gruppo',
    'Settings.Config.ProjectAndScenario.AllowedScenarioVersions': 'Versioni scenario consentite',
    'Settings.Config.ProjectAndScenario.DefaultScenarioVersion': 'Versione scenario predefinita',
    'Settings.Config.Heading.CompanyEvent': 'EVENTO AZIENDALE',
    'Settings.Config.CompanyEvent.MaximumEventDuration': 'Durata massima dell\'evento',
    'Settings.Config.CompanyEvent.MinimumEventDuration': 'Durata minima dell\'evento',
    'Settings.Config.CompanyEvent.EventTimeStep': 'Fase di orario dell\'evento',
    'Settings.Config.Heading.SessionReport': 'RAPPORTO DI SESSIONE',
    'Settings.Config.SessionReport.ShowSessionReport': 'Mostra rapporto di sessione',
    'Settings.Config.Heading.FeedbackProvider': 'PROVIDER DI FEEDBACK',
    'Settings.Config.FeedbackProvider.ZenDeskSnippetKey': 'Chiave snippet ZenDesk',
    'Settings.Config.Heading.SimulationContent': 'CONTENUTO DELLA SIMULAZIONE',
    'Settings.Config.SimulationContent.SimulationContentFolder': 'Cartella S3 contenuto della simulazione',
    'Settings.Config.Heading.Invitation': 'INVITO',
    'Settings.Config.Invitation.ExpireUserRegistrationInvitation': 'Quando far scadere l\'invito alla registrazione dell\'utente',
    'Settings.Config.Invitation.ReminderToAcceptRegistrationInvitation': 'Quando ricordare agli utenti non registrati di accettare l\'invito alla registrazione',
    'Settings.Config.Invitation.RegistrationInvitationExpiresAfter': 'L\'invito alla registrazione scade dopo',
    'Settings.Config.IndustriesButton.Modal.EditIndustry': 'Modifica settore',
    'Settings.Config.NewButton.Modal.NewIndustry': 'Nuovo settore',
    'Settings.Modal.Placeholder.IndustryName': 'Nome del settore',
    'Settings.Config.IndustriesButton.Modal.Button.UpdateIndustry': 'Aggiorna settore',
    'Settings.Config.NewButton.Modal.Button.CreateIndustry': 'Crea settore',
    'Settings.Config.IndustriesButton.Modal.Button.RestoreIndustry': 'Ripristina settore',
    'Settings.Config.IndustriesButton.Modal.Button.ArchiveIndustry': 'Archivia settore',
    'MursionPortal.Modal.Heading.Name': 'Nome',
    'Settings.Config.RestoreIndustry.Modal.ConfirmRestore': 'Sei sicuro di voler ripristinare questo settore?',
    'Settings.Config.ArchiveIndustry.Modal.ConfirmArchive': 'Sei sicuro di voler archiviare questo settore?',
    'Settings.Config.Description.PerWeek': '(per settimana)',
    'Settings.Config.Description.PerDay': '(per giorno)',
    'Settings.Config.Description.BeforeBreak': '(prima della pausa)',
    'Settings.Config.Description.Minutes': '(minuti)',
    'Settings.Config.Description.Days': '(giorni)',
    'Settings.Config.Description.Hours': '(ore)',
    'Settings.Config.TierButton.Modal.Button.UpdateTier': 'Aggiorna livello',
    'Settings.Config.TierButton.Modal.Heading.EditTier': 'Modifica livello',
    'Settings.Config.TierButton.Modal.Placeholder.TierName': 'Nome livello',
    'MursionPortal.Button.Archive': 'Archivia',
    'Settings.Config.ArchiveIndustry.Modal.Heading.ConfirmArchive': 'Conferma archiviazione',
    'Settings.Config': 'Configurazione',
    'Settings.ScenarioBank': 'Gruppo scenario',
    'Settings.Environments': 'Ambienti (ML2)',
    'Settings.Avatars': 'Avatar (ML2)',
    'Settings.SSO': 'SSO',
    'Settings.Error.NoSettingsAvailable': 'Nessuna impostazione disponibile',
    'MursionPortal.Error.SomethingWentWrong': 'Siamo spiacenti, si è verificato un errore.',
    'MursionPortal.Error.ReloadingPage': 'Prova a ricaricare la pagina. L\'errore è stato segnalato.',
    'MursionPortal.Error.WorkingHard': 'Stiamo lavorando per risolverlo il prima possibile.',
    'MursionPortal.Error.SupportAvailable': 'L\'assistenza è disponibile dal lunedì al venerdì',
    'MursionPortal.Error.TechnicalAssistance': 'Se hai bisogno di assistenza tecnica, contatta il supporto Mursion',
    'MursionPortal.At': 'alle',
    'MursionPortal.Or': 'oppure',
    'Settings.Environment.Modal.PlaceHolder.EnvironmentName': 'Nome ambiente',
    'Settings.Environment.Modal.Description': 'Descrizione',
    'Settings.Environment.Modal.Industry': 'Settore',
    'Settings.Environment.Modal.Picture': 'Immagine',
    'MursionPortal.Button.Delete': 'Elimina',
    'Settings.Environment.Modal.Button.UploadFile': 'Carica file',
    'Settings.Environment.Button.CreateEnvironment': 'Crea ambiente ',
    'Settings.Environment.ModalHeader.NewEnvironment': 'Nuovo ambiente',
    'Settings.Environment.ModalHeader.Environment': 'Ambiente',
    'Settings.Environment.ModalHeader.Archived': '(Archiviato)',
    'Settings.Environment.ModalHeader.EditEnvironment': 'Modifica ambiente',
    'Settings.Environment.Modal.Button.CreateEnvironment': 'Crea ambiente',
    'Settings.Environment.Modal.Button.UpdateEnvironment': 'Aggiorna ambiente',
    'Settings.Environment.Modal.ArchiveEnvironmentText': 'Sei sicuro di voler archiviare questo ambiente?',
    'Settings.Environment.Modal.RestoreEnvironmentText': 'Sei sicuro di voler ripristinare questo ambiente?',
    'MursionPortal.Button.Restore': 'Ripristina',
    'MursionPortal.Total': 'TOTALE',
    'MursionPortal.Text.Total': 'TOTALE',
    'MursionPortal.Button.ExportToEmail.HoverTitle.ExportData': 'Esporta dati',
    'MursionPortal.Button.ExportToEmail.HoverTitle.ExportNotAvailable': 'L\'esportazione non è disponibile',
    'MursionPortal.Button.ExportToEmail': 'Esporta a e-mail',
    'MursionPortal.WarningMessage.RequestNotImplemented': 'La richiesta di dati non è ancora implementata',
    'MursionPortal.Table.ColumnHeader.Client': 'Cliente',
    'MursionPortal.Table.ColumnHeader.Provider': 'Provider',
    'MursionPortal.Table.ColumnHeader.Project': 'Progetto',
    'MursionPortal.Table.ColumnHeader.Scenario': 'Scenario',
    'MursionPortal.Table.ColumnHeader.SoftwareVersion': 'Versione software',
    'MursionPortal.Table.ColumnHeader.DeliveryMode': 'Modalità di consegna',
    'MursionPortal.Table.ColumnHeader.SessionStart': 'Inizio sessione',
    'MursionPortal.Table.ColumnHeader.SessionEnd': 'Fine sessione',
    'MursionPortal.Table.ColumnHeader.LearnerStatus': 'Stato allievo',
    'MursionPortal.Table.ColumnHeader.SessionStatus': 'Stato sessione',
    'MursionPortal.Table.ColumnHeader.LearnerName': 'Nome allievo',
    'MursionPortal.Table.ColumnHeader.LearnerEmail': 'E-mail allievo',
    'MursionPortal.Table.LearnerEmail.HoverTitle.CopyEmail': 'Copia e-mail',
    'MursionPortal.Table.LearnerEmail.HoverTitle.UserEmail': 'E-mail utente',
    'MursionPortal.Table.ColumnHeader.SessionId': 'ID sessione',
    'MursionPortal.Table.SessionId.HoverTitle.CopySessionId': 'Copia ID sessione',
    'MursionPortal.Table.ColumnHeader.SessionReport': 'Rapporto di sessione',
    'MursionPortal.Table.ColumnHeader.SimSpecialistName': 'Nome specialista Sim',
    'MursionPortal.Table.ColumnHeader.LearnerTimeSpent': 'Tempo impiegato dall\'allievo',
    'MursionPortal.Table.HoverTitle.SessionSummary': 'Riepilogo sessione',
    'MursionPortal.EmptyMessage.NoSessionsYet': 'Non ci sono ancora sessioni',
    'MursionPortal.Button.ViewReport': 'Visualizza rapporto',
    'Mursion.Portal.Status.Requested': 'Richiesto',
    'Mursion.Portal.Status.Pending': 'In sospeso',
    'Mursion.Portal.Status.PendingSwap': 'In sospeso (scambio)',
    'Mursion.Portal.Status.PreBooked': 'Prenotato in anticipo',
    'Mursion.Portal.Status.Booked': 'Prenotato',
    'Mursion.Portal.Status.Active': 'Attivo',
    'Mursion.Portal.Status.Complete': 'Completo',
    'Mursion.Portal.Status.Error': 'Errore',
    'Mursion.Portal.Status.Missed': 'Mancata',
    'Mursion.Portal.Status.NeedsReview': 'Revisione necessaria',
    'Mursion.Portal.Status.Cancelled': 'Annullato',
    'Dashboard.UsersActivity.Filter.Roles.Placeholder': 'Cerca...',
    'Settings.ScenarioBank.Table.ColumnHeader.Name': 'Nome',
    'MursionPortal.DeliveryMode': 'Modalità di consegna',
    'Settings.ScenarioBank.Table.ColumnHeader.SessionSize': 'Dimensione sessione',
    'MursionPortal.SessionLength': 'Durata sessione',
    'Settings.ScenarioBank.Table.ViewEditScenario': 'Visualizza/Modifica scenario',
    'Settings.ScenarioBank.Table.ThereAreNoScenarios': 'Non ci sono ancora scenari',
    'MursionPortal.Capitalized.Total': 'TOTALE',
    'Settings.ScenarioBank.Form.NewScenario': 'Nuovo scenario',
    'Settings.ScenarioBank.Form.Scenario': 'Scenario',
    'Settings.ScenarioBank.Form.EditScenario': 'Modifica scenario',
    'Settings.ScenarioBank.Form.Save': 'Salva',
    'Settings.ScenarioBank.Form.ConfirmArchiveScenario': 'Sei sicuro di voler archiviare questo scenario?',
    'Settings.ScenarioBank.Form.ConfirmRestoreScenario': 'Sei sicuro di voler ripristinare questo scenario?',
    'Settings.ScenarioBank.Button.CreateScenario': 'Crea scenario ',
    'MursionPortal.ShortLabel.Minutes': 'min',
    'Settings.ScenarioBank.Modal.Scenarios': 'Scenario/i',
    'Settings.ScenarioBank.Modal.CompleteProjectInfoFirst': 'Completa le informazioni sul progetto prima',
    'Settings.ScenarioBank.Modal.ScenarioName': 'Nome scenario',
    'Settings.ScenarioBank.Modal.Placeholder.EnterScenarioName': 'Inserisci il nome dello scenario',
    'Settings.ScenarioBank.Modal.Outcome': 'Esito',
    'Settings.ScenarioBank.Modal.Strategies': 'Strategie',
    'Settings.ScenarioBank.Modal.Attachments': 'Allegati',
    'Settings.ScenarioBank.Modal.SoftwareVersion': 'Versione del software',
    'Settings.ScenarioBank.Modal.WeAlreadyHaveSessions': 'Abbiamo già sessioni per questo scenario',
    'Settings.ScenarioBank.Modal.SelectDeliveryMode': 'Seleziona una modalità di consegna',
    'Settings.ScenarioBank.Modal.ShouldBeAtLeast': 'Dovrebbero essere almeno di {minutes} minuti',
    'Settings.ScenarioBank.Modal.SelectSessionLength': 'Seleziona una durata della sessione',
    'Settings.ScenarioBank.Modal.SessionSize': 'Dimensione sessione',
    'Settings.ScenarioBank.Modal.Learner': 'Allievo',
    'Settings.ScenarioBank.Modal.Learners': 'Allievi',
    'Settings.ScenarioBank.Modal.SelectSessionSize': 'Seleziona una dimensione della sessione',
    'MursionPortal.SimulationContent': 'Contenuto della simulazione',
    'Settings.ScenarioBank.Modal.ToolTip.ThisIsTheArtContent': 'Questo è il contenuto artistico 3D di ambienti e avatar che verranno utilizzati nella simulazione. I contenuti di questa cartella vengono visualizzati nella sezione intitolata "pacchetto contenuti" di seguito.',
    'Settings.ScenarioBank.Modal.ThisScenarioHasNoSimulation': 'Lo scenario non ha contenuto di simulazione assegnato',
    'Settings.ScenarioBank.Modal.SelectSimulationContentForUse': 'Seleziona il contenuto della simulazione per l\'uso',
    'Settings.ScenarioBank.Modal.Label.ScenarioOutcome': 'Esisto dello scenario',
    'Settings.ScenarioBank.Modal.Placeholder.EnterAnOutcome': 'Inserisci un esito',
    'Settings.ScenarioBank.Modal.Description': 'Descrizione',
    'Settings.ScenarioBank.Modal.NoDescriptionSpecified': 'Nessuna descrizione specificata',
    'Settings.ScenarioBank.Modal.ScenarioDescription': 'Descrizione dello scenario',
    'Settings.ScenarioBank.Modal.AllowedCharactersLimit': 'Il limite di caratteri consentito ({maxLength} caratteri) è stato superato',
    'Settings.ScenarioBank.Modal.ScenarioStrategies': 'Strategie di scenario',
    'Settings.ScenarioBank.Modal.Placeholder.EnterAnItem': 'Inserisci un elemento',
    'Settings.ScenarioBank.Modal.Attachments.DragAndDrop': 'Trascina e rilascia alcuni file qui o fai clic per selezionare i file',
    'Settings.ScenarioBank.Modal.Attachments.UploadedMaximumFiles': 'Hai caricato il numero massimo di file disponibile {maxFiles}.',
    'Settings.ScenarioBank.Modal.Attachments.UploadedFiles': 'File caricati,',
    'Settings.ScenarioBank.Modal.CertifiedSimSpecialists': 'Specialisti Sim certificati',
    'Settings.ScenarioBank.Modal.NoCertifiedSimSpecialist': 'Nessuno specialista Sim certificato',
    'Settings.ScenarioBank.Modal.Button.Add': 'Aggiungi ',
    'Settings.ScenarioBank.Modal.Edit': 'modifica',
    'Settings.ScenarioBank.Modal.AddAttachmentsToScenario': 'Aggiungi allegati allo scenario',
    'MursionPortal.Cancel': 'Annulla',
    'MursionPortal.OK': 'OK',
    'Settings.ScenarioBank.Modal.FileFormatsSupported': 'Sono supportati i seguenti formati di file:',
    'Settings.ScenarioBank.Modal.Images': 'Immagini',
    'Settings.ScenarioBank.Modal.Videos': 'Video',
    'Settings.ScenarioBank.Modal.Audios': 'Audio',
    'Settings.ScenarioBank.Modal.Documents': 'Documenti',
    'Settings.ScenarioBank.Modal.New': '(nuovo)',
    'Settings.ScenarioBank.Modal.FileSizeMustNotExceed': 'La dimensione del file non deve superare {size}',
    'Settings.ScenarioBank.Modal.CertifySimSpecialists': 'Certifica specialisti Sim',
    'MursionPortal.Confirm': 'Conferma',
    'MursionPortal.Delete': 'Elimina',
    'Settings.ScenarioBank.Modal.Archived': '(Archiviato)',
    'Settings.ScenarioBank.Modal.Restore': 'Ripristina',
    'Settings.ScenarioBank.Modal.ConfirmArchive': 'Conferma archiviazione',
    'Settings.ScenarioBank.Modal.Archive': 'Archivia',
    'Settings.ScenarioBank.Modal.ConfirmRestore': 'Conferma ripristino',
    'MursionPortal.PleaseWait': 'Attendi…',
    'MursionPortal.Loading': 'Caricamento in corso...',
    'Settings.ScenarioBank.Modal.Attachments.FileTypeNotSupported': 'Il tipo di file {extensions} non è supportato',
    'Settings.ScenarioBank.Modal.Attachments.FileTypesNotSupported': 'I tipi di file {extensions} non sono supportati',
    'Settings.ScenarioBank.Modal.Attachments.FileExceedsSizeLimit': 'Il file {fileNames} supera il limite di dimensione',
    'Settings.ScenarioBank.Modal.Attachments.FilesExceedSizeLimit': 'I file {fileNames} superano il limite di dimensione',
    'MursionPortal.Label.Total': 'TOTALE',
    'MursionPortal.Button.HoverTitle.ExportData': 'Esporta dati',
    'MursionPortal.Button.HoverTitle.ExportNotAvailable': 'L\'esportazione non è disponibile',
    'MursionPortal.Heading.Filters': 'FILTRI:',
    'Dashboard.ProjectsTable.Checkbox.OnlyBillable': 'Solo fatturabile',
    'MursionPortal.Filters.SoftwareVersion': 'Versione del software',
    'MursionPortal.Filters.PlaceHolder.SearchVersion': 'Cerca versione...',
    'MursionPortal.Filters.Status': 'Stato',
    'MursionPortal.Filters.PlaceHolder.SearchStatus': 'Ricerca stato...',
    'Dashboard.ProjectsTable.ColumnHeader.Provider': 'Provider',
    'Dashboard.ProjectsTable.ColumnHeader.Status': 'Stato',
    'Dashboard.ProjectsTable.ColumnHeader.StarAt': 'Inizia il',
    'Dashboard.ProjectsTable.ColumnHeader.EndAt': 'Finisce il',
    'Dashboard.ProjectsTable.ColumnHeader.Billable': 'Fatturabile',
    'Dashboard.ProjectsTable.ColumnHeader.CancellationWindow': 'Finestra di annullamento',
    'MursionPortal.Table.ColumnHeader.Scenarios': 'Scenari',
    'MursionPortal.Table.ColumnHeader.Teams': 'Team',
    'MursionPortal.Table.ColumnHeader.SessionsScheduled': 'Sessioni pianificate',
    'MursionPortal.Table.ColumnHeader.SessionsCompleted': 'Sessioni completate',
    'MursionPortal.Table.ColumnHeader.SessionsMissed': 'Sessioni perse',
    'MursionPortal.Table.ColumnHeader.LateCancellations': 'Annullamenti tardivi',
    'MursionPortal.Table.ColumnHeader.Learners': 'Allievi',
    'Dashboard.ProjectsTable.ColumnHeader.SessionsRecorded': 'Sessioni registrate',
    'MursionPortal.Table.ColumnHeader.SessionsError': 'Errore di sessione',
    'Dashboard.ProjectsTable.ColumnHeader.ContractedHours': 'Ore contrattate',
    'Dashboard.ProjectsTable.ColumnHeader.UtilizedHours': 'Ore utilizzate',
    'Dashboard.ProjectsTable.ColumnHeader.RemainingHours': 'Ore rimanenti',
    'Dashboard.ProjectsTable.ColumnHeader.CompletedHours': 'Ore completate',
    'Dashboard.ProjectsTable.ColumnHeader.MissedHours': 'Ore perse',
    'Dashboard.ProjectsTable.HoverTitle.ViewEditProject': 'Visualizza/Modifica progetto',
    'Dashboard.ProjectsTable.EmptyMessage.NoProjectsYet': 'Non ci sono ancora progetti',
    'MursionPortal.Button.Clear': 'Cancella',
    'Dashboard.Projects.AlertMessage.DataRequestNotImplemented': 'La richiesta di dati non è ancora implementata',
    'MursionPortal.SelectorMenu.SelectedCountOfTotal': 'Selezione: {formattedSelectedCount} di {formattedTotal}',
    'MursionPortal.Dashboard.Table.Modal.Filter.Teams': 'Team',
    'MursionPortal.Dashboard.Table.Modal.Placeholder.EnterTeamName': 'Inserisci il nome del team',
    'MursionPortal.Dashboard.Table.Modal.TeamsEmptyMessage': 'Non è stato trovato nessun team',
    'MursionPortal.Dashboard.Table.Modal.Filter.NumberOfLearners': 'n. di allievi',
    'MursionPortal.Dashboard.Table.Modal.Filter.Scenarios': 'Scenari',
    'MursionPortal.Dashboard.Table.Modal.Placeholder.EnterScenarioName': 'Inserisci il nome dello scenario',
    'MursionPortal.Dashboard.Table.Modal.ScenariosEmptyMessage': 'Non sono stati trovati scenari',
    'MursionPortal.Dashboard.Table.Modal.Filter.Name': 'Nome',
    'MursionPortal.Dashboard.Table.Modal.Filter.Status': 'Stato',
    'MursionPortal.Dashboard.Table.Modal.Filter.DeliveryMode': 'Modalità di consegna',
    'MursionPortal.Dashboard.Table.Modal.Filter.Learners': 'Allievi',
    'MursionPortal.Dashboard.Table.Modal.Placeholder.EnterLearnerName': 'Inserisci nome allievo',
    'MursionPortal.Dashboard.Table.Modal.LearnersEmptyMessage': 'Non sono stati trovati allievi',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsScheduled': 'Sessioni pianificate',
    'MursionPortal.Dashboard.Table.Modal.SessionsEmptyMessage': 'Non sono state trovate sessioni',
    'MursionPortal.Dashboard.Table.Modal.Filter.ScenarioName': 'Nome scenario',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionDateTime': 'Data/ora della sessione',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsCompleted': 'Sessioni completate',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsError': 'Errore di sessione',
    'Dashboard.ProjectsActivityTable.Modal.Filter.SessionsRecorded': 'Sessioni registrate',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsMissed': 'Sessioni perse',
    'MursionPortal.Dashboard.Table.Modal.Filter.LateCancellations': 'Annullamenti "tardivi"',
    'MursionPortal.Dashboard.Table.Modal.Filter.CancelledBy': 'Annullato da',
    'MursionPortal.Dashboard.Table.Modal.Filter.DateScheduled': 'Data programmata',
    'MursionPortal.Dashboard.Table.Modal.Filter.DateCancelled': 'Data annullata',
    'MursionPortal.ProjectStatus.Active': 'Attivo',
    'MursionPortal.ProjectStatus.Completed': 'Completato',
    'MursionPortal.ProjectStatus.Archived': 'Archiviato',
    'Settings.Avatars.Modal.Name': 'Nome',
    'Settings.Avatars.Modal.PlaceHolder.AvatarName': 'Nome avatar',
    'Settings.Avatars.Modal.Description': 'Descrizione',
    'MursionPortal.Name': 'Nome',
    'MursionPortal.Description': 'Descrizione',
    'MursionPortal.Environments': 'Ambienti',
    'Settings.Avatars.TableHover.ViewEditAvatar': 'Visualizza/Modifica avatar',
    'Settings.Avatars.NoAvatarText': 'Non ci sono ancora avatar',
    'Settings.Avatars.ModalHeader.NewAvatar': 'Nuovo avatar',
    'Settings.Avatars.ModalHeader.Avatar': 'Avatar',
    'Settings.Avatars.ModalHeader.EditAvatar': 'Modifica avatar',
    'Settings.Avatars.Modal.Button.CreateAvatar': 'Crea avatar',
    'Settings.Avatars.Modal.Button.UpdateAvatar': 'Aggiorna avatar',
    'Settings.Avatars.Modal.ArchiveAvatarText': 'Sei sicuro di voler archiviare questo avatar?',
    'Settings.Avatars.Modal.RestoreAvatarText': 'Sei sicuro di voler ripristinare questo avatar?',
    'Settings.Avatars.Modal.Button.Edit': 'modifica',
    'Settings.Avatars.Modal.EditButton.Accept': 'Accetta',
    'Settings.Avatars.Modal.NoSelectedEnvironments': 'Nessun ambiente selezionato',
    'Settings.Avatars.Modal.NoEnvironmentsSetForSelectedIndustry': 'Nessun ambiente impostato per il settore selezionato.',
    'Settings.Avatars.Button.CreateAvatar': 'Crea avatar ',
    'MursionPortal.Dashboard': 'Pannello di controllo',
    'MursionPortal.Tutorial': 'Tutorial',
    'MursionPortal.Eula': 'Eula',
    'MursionPortal.Companies': 'Aziende',
    'MursionPortal.SessionFlow': 'Flusso della sessione',
    'MursionPortal.SessionReport': 'Rapporto di sessione',
    'MursionPortal.AssetSync': 'Sincronizzazione risorse',
    'MursionPortal.Users': 'Utenti',
    'MursionPortal.AppDownload': 'Scarica l\'app',
    'MursionPortal.Calendar': 'Calendario',
    'MursionPortal.Clients': 'Clienti',
    'MursionPortal.Sessions': 'Sessioni',
    'MursionPortal.Trainings': 'Formazioni',
    'MursionPortal.Requests': 'Richieste',
    'MursionPortal.Settings': 'Impostazioni',
    'MursionPortal.ArtDesigner': 'Art Designer',
    'MursionPortal.ArtDesigner.BodyContents': 'We\'ve opened a new browser tab with the Mursion Designer application.',
    'MursionPortal.Projects': 'Progetti',
    'MursionPortal.Teams': 'Team',
    'MursionPortal.Reports': 'Rapporti',
    'MursionPortal.Downloads': 'Download',
    'MursionPortal.Upcoming': 'imminente',
    'MursionPortal.Auth': 'Oauth',
    'Settings.Avatars.Modal.ErrorMessage.ItemsNotAvailable': 'Non sono disponibili più di {max} elementi per la selezione',
    'Settings.Avatars.Modal.ErrorMessage.ItemNotAvailable': 'Non è disponibile più di {max} elemento per la selezione',
    'MursionPortal.Industry': 'Settore',
    'Settings.Environment.TableHover.ViewEditEnvironment': 'Visualizza/Modifica ambiente',
    'Settings.Environment.NoEnvironmentText': 'Non ci sono ancora ambienti',
    'Projects.Button.CreateProject': 'Crea progetto ',
    'Projects.ListHeader.CreationDate': 'Data di creazione',
    'Projects.ListHeader.ProjectManagers': 'Responsabili di progetto',
    'MursionPortal.Capitalized.Active': 'ATTIVO',
    'MursionPortal.Capitalized.Completed': 'COMPLETATO',
    'MursionPortal.Capitalized.Archived': 'ARCHIVIATO',
    'MursionPortal.Capitalized.All': 'TUTTI',
    'Projects.ProjectCard.BackToClient': 'Torna al cliente',
    'Projects.ProjectCard.NoProjectsFound': 'Nessun progetto trovato',
    'Projects.ProjectCard.PlaceHolder.EnterProjectName': 'Inserisci il nome del progetto',
    'Projects.ProjectCard.ProjectsList': 'ELENCO PROGETTI',
    'MursionPortal.Capitalized.Project': 'PROGETTO',
    'MursionPortal.Scenarios': 'Scenari',
    'Projects.ScenarioList.ThereAreNoScenarios': 'Non ci sono ancora scenari.',
    'Projects.ScenarioList.NoScenariosFound': 'Non sono stati trovati scenari.',
    'Projects.ScenarioList.Button.Draft': 'Bozza',
    'MursionPortal.Finalized': 'Finalizzato',
    'MursionPortal.Started': 'Avviato',
    'MursionPortal.Finished': 'Terminato',
    'MursionPortal.Archived': 'Archiviato',
    'MursionPortal.EnterScenarioName': 'Inserisci il nome dello scenario',
    'Projects.AllProjects': 'tutti i progetti',
    'MursionPortal.Label.LowerCase.Archived': 'archiviato',
    'MursionPortal.Label.Active': 'Attivo',
    'MursionPortal.Label.Completed': 'Completato',
    'Projects.Tab.Basic': 'Elementare',
    'MursionPortal.Heading.ProjectName': 'Nome del progetto',
    'MursionPortal.Label.Created': 'Creato',
    'MursionPortal.Label.Status': 'Stato',
    'MursionPortal.Label.Learners': 'Allievi',
    'MursionPortal.Label.LearnersCount': '{count} allievi',
    'Projects.Tab.NoLearners': 'Nessun allievo',
    'MursionPortal.Label.SoftwareVersion': 'Versione software',
    'Projects.ProjectInfo.Label.Non-Billable': 'Non fatturabile',
    'Projects.ProjectInfo.Label.Contracted': 'Aggiudicato',
    'Projects.ProjectInfo.Label.CancellationWindow': 'Finestra di annullamento',
    'MursionPortal.Header.Recording': 'Registrazione',
    'Projects.ProjectInfo.Checkbox.AllowRecording': 'Consenti registrazione',
    'MursionPortal.Label.VideoRecording': 'Videoregistrazione',
    'MursionPortal.Label.Allowed': 'Consentita',
    'MursionPortal.Label.Disallowed': 'Non consentita',
    'MursionPortal.Label.RecordedVideosGoBackTo': 'I video registrati tornano a:',
    'MursionPortal.Checkbox.RecordingPermissionLiesWithLearner': 'Il permesso di registrazione spetta all\'allievo',
    'MursionPortal.Label.LiesWithLearner': 'Spetta all\'allievo',
    'Projects.ProjectInfo.Label.Enabled': 'Abilitato',
    'Projects.ProjectInfo.Label.Disabled': 'Disabilitato',
    'MursionPortal.Heading.ProjectManagers': 'Responsabili di progetto',
    'MursionPortal.Heading.Tags': 'Tag',
    'MursionPortal.Button.AreYouSureEditProject': 'Sei sicuro di voler modificare questo progetto?',
    'MursionPortal.Button.SaveProjectInfo': 'Salva le informazioni sul progetto',
    'Projects.Tab.Utilization': 'Utilizzo',
    'MursionPortal.Label.Loading': 'Caricamento in corso...',
    'Projects.ProjectInfo.Label.NoProjectManagers': 'Nessun responsabile di progetto',
    'Projects.ProjectInfo.Label.NoSelectedProjectManagers': 'Nessun responsabile di progetto selezionato',
    'MursionPortal.Label.ProjectManagers': 'Responsabili di progetto',
    'MursionPortal.Label.RecordingPermission': 'Autorizzazione alla registrazione:',
    'Projects.Utilization.Label.UtilizedSessions': 'Sessioni utilizzate:',
    'Projects.Utilization.Label.UtilizedMissedSessions': 'Sessioni perse utilizzate:',
    'Projects.Utilization.Label.UtilizedCancelledSessions': 'Sessioni annullate utilizzate:',
    'Projects.Utilization.Label.UtilizedDuration': 'Durata utilizzata:',
    'Projects.Utilization.Label.RemainingDuration': 'Durata rimanente:',
    'MursionPortal.ScenarioListItem.Label.Timeline': 'sequenza temporale',
    'MursionPortal.ScenarioListItem.Label.SessionLength': 'durata della sessione',
    'MursionPortal.ScenarioListItem.Label.SessionSize': 'dimensione della sessione',
    'MursionPortal.ScenarioListItem.Label.DeliveryMode': 'modalità di consegna',
    'MursionPortal.ScenarioCard.BackToProject': 'Torna al progetto',
    'MursionPortal.ScenarioCard.NoScenariosFound': 'Nessuno scenario trovato',
    'MursionPortal.Placeholder.EnterScenarioName': 'Inserisci il nome dello scenario',
    'MursionPortal.ScenarioCard.Label.ScenariosList': 'ELENCO SCENARI',
    'MursionPortal.ScenarioCard.Header.Scenario': 'SCENARIO',
    'MursionPortal.ScenarioCard.Header.ChooseTypeOfEvent': 'Scegli il tipo di evento da programmare',
    'MursionPortal.Label.Session': 'Sessione',
    'MursionPortal.Label.Training': 'Formazione',
    'MursionPortal.Button.Confirm': 'Conferma',
    'MursionPortal.Button.Continue': 'Continua',
    'MursionPortal.Button.Schedule': 'Pianifica',
    'MursionPortal.Label.Draft': 'Bozza',
    'MursionPortal.Label.Finalized': 'Finalizzato',
    'MursionPortal.Label.Started': 'Avviato',
    'MursionPortal.Label.Finished': 'Terminato',
    'MursionPortal.Label.Archived': 'Archiviato',
    'MursionPortal.Label.Cancel': 'annulla',
    'MursionPortal.Label.Edit': 'modifica',
    'MursionPortal.Label.CancelEdit': 'Annulla modifica',
    'MursionPortal.Label.EditScenario': 'Modifica scenario',
    'MursionPortal.Label.EditScenarioName': 'Modifica nome scenario',
    'MursionPortal.ScenarioCard.Label.ApplyNameChange': 'Applica modifica del nome',
    'MursionPortal.ScenarioCard.Label.Apply': 'applica',
    'MursionPortal.ScenarioCard.Tab.Info': 'INFO',
    'MursionPortal.ScenarioCard.Tab.Learners': 'ALLIEVI',
    'MursionPortal.ScenarioCard.Tab.Sessions': 'SESSIONI',
    'MursionPortal.ScenarioCard.ConfirmButton.AreYouSureArchiveScenario': 'Sei sicuro di voler archiviare questo scenario? Verranno archiviati anche tutti i dati correlati.',
    'MursionPortal.ScenarioCard.ConfirmButton.ArchiveScenario': 'Archivia scenario',
    'MursionPortal.Label.Delivery': 'Consegna',
    'MursionPortal.ScenarioInfo.Header.ArchivedScenario': 'SCENARIO ARCHIVIATO',
    'MursionPortal.ScenarioInfo.Button.YouMayOnlyEditTeams': 'Puoi modificare i team e la cronologia solo se finalizzi uno scenario',
    'MursionPortal.ScenarioInfo.Button.FinalizeScenario': 'Finalizza scenario',
    'MursionPortal.ScenarioInfo.Button.SaveScenario': 'Salva scenario',
    'MursionPortal.ScenarioInfo.Button.CreatePlanning': 'Crea pianificazione ',
    'MursionPortal.ScenarioInfo.Label.SimLength': 'Durata sim',
    'MursionPortal.Header.Outcome': 'Esito',
    'MursionPortal.Header.Strategies': 'Strategie',
    'MursionPortal.Header.Attachments': 'Allegati',
    'MursionPortal.ScenarioInfo.Button.SaveScenarioInfo': 'Salva le informazioni sullo scenario',
    'MursionPortal.Label.ContentBundle': 'Pacchetto di contenuti',
    'MursionPortal.ScenarioInfo.Button.ShowLess': 'Mostra meno',
    'MursionPortal.ScenarioInfo.Button.ShowMore': 'Mostra di più',
    'MursionPortal.AssetSettings.Label.CurrentScenarioNoAssetSettings': 'Lo scenario corrente non ha impostazioni delle risorse.',
    'MursionPortal.Teams.Label.NoSelectedTeams': 'Nessun team selezionato',
    'MursionPortal.Teams.Label.ClientHasNoAssignedTeams': 'Il cliente non ha team assegnati',
    'MursionPortal.Label.Teams': 'Team',
    'MursionPortal.Label.NoTeamsSpecified': 'Nessun team specificato',
    'MursionPortal.Label.Capitalized.Draft': 'BOZZA',
    'MursionPortal.Label.Capitalized.Finalized': 'FINALIZZATO',
    'MursionPortal.Label.Capitalized.Started': 'AVVIATO',
    'MursionPortal.Label.Capitalized.Finished': 'FINITO',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioStillInDevelopmentStage': 'Lo scenario è ancora in fase di sviluppo',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioDeployedTimelineUpcoming': 'Lo scenario è distribuito, ma la tempistica è ancora imminente',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioActiveLearnersCanSchedule': 'Lo scenario è attivo e gli allievi possono programmare le sessioni',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioIsCompleted': 'Lo scenario è completato',
    'MursionPortal.Button.Add': 'Aggiungi ',
    'MursionPortal.Label.Warning': 'Avviso',
    'MursionPortal.SessionWizard.Label.UnableToCreateSession': 'Impossibile creare la sessione per il {failedParam} selezionato.',
    'MursionPortal.SessionWizard.Label.PleaseContinueToFindAnother': 'Premi Continua per trovare un altro <code>{failedParam}</code>.',
    'MursionPortal.SessionWizard.Label.ScheduleSession': 'Pianifica la sessione',
    'MursionPortal.SessionWizard.Label.ScheduleTraining': 'Pianifica la formazione',
    'MursionPortal.SessionWizard.Label.AddNotes': 'Aggiungi note',
    'MursionPortal.SessionWizard.Label.SelectSimulationContentForUse': 'Seleziona il contenuto della simulazione per l\'uso',
    'MursionPortal.SessionWizard.Label.SelectStep': 'Seleziona {step}',
    'MursionPortal.SessionWizard.Label.Expired': 'scaduto',
    'MursionPortal.SessionWizard.ConfirmButton.AreYouSureCreateEmergencySession': 'Sei sicuro di voler creare una sessione di emergenza?',
    'MursionPortal.SessionWizard.Button.Create': 'Crea',
    'MursionPortal.Button.Next': 'Avanti',
    'MursionPortal.SessionWizard.Label.RemainingTimeToBook': 'ancora a disposizione per prenotare questo periodo di tempo prima della scadenza della sospensione.',
    'MursionPortal.SessionWizard.Label.TimeBlockHoldExpired': 'Il blocco temporale è scaduto.',
    'MursionPortal.Label.Date': 'Data',
    'MursionPortal.Label.Time': 'Ora',
    'MursionPortal.SessionWizard.Label.TimeNoLongerAvailableClickSubmit': 'Quell\'orario non è più disponibile, vuoi selezionare un\'opzione diversa? Torna indietro per scegliere un\'opzione disponibile o fai clic su Invia per richiedere l\'opzione esistente.',
    'MursionPortal.SessionWizard.Label.TimeNoLongerAvailable': 'Quell\'orario non è più disponibile, vuoi selezionare un\'opzione diversa? Torna indietro per scegliere un\'opzione disponibile.',
    'MursionPortal.SessionWizard.Button.GoBack': 'Torna indietro',
    'MursionPortal.Button.Submit': 'Invia',
    'MursionPortal.SessionWizard.Label.NoAvailableClients': 'Nessun cliente disponibile',
    'MursionPortal.SessionWizard.Label.NoAvailableProjects': 'Nessun progetto disponibile',
    'MursionPortal.SessionWizard.Label.NoAvailableScenarios': 'Nessuno scenario disponibile',
    'MursionPortal.SessionWizard.Label.PleaseSelectScenario': 'Seleziona prima lo scenario',
    'MursionPortal.SessionWizard.Label.NoImage': 'Nessuna immagine',
    'MursionPortal.SessionWizard.EmptyMessage.NoAvailableSImSpecialists': 'Nessuno specialista Sim disponibile',
    'MursionPortal.SessionWizard.Label.ScenarioHasNoContentBundles': 'Lo scenario non ha pacchetti di contenuti assegnati.',
    'MursionPortal.SessionWizard.Option.SelectAnAsset': 'Seleziona una risorsa',
    'MursionPortal.SimSpecialistStep.Label.NoSimSpecialistsAvailable': 'Non ci sono specialisti Sim disponibili per il periodo selezionato. La sessione verrà assegnata su richiesta.',
    'MursionPortal.SsLearnersStep.ErrorMessage.YouCanAddMaxTrainees': 'Puoi aggiungere solo fino a {maxLearners} tirocinanti per questo tipo di sessione.',
    'MursionPortal.SsLearnersStep.ErrorMessage.YouCanAddMaxTrainee': 'Puoi aggiungere solo fino a {maxLearners} tirocinante per questo tipo di sessione.',
    'MursionPortal.EmptyMessage.NoUsersSelected': 'Nessun utente selezionato.',
    'MursionPortal.Placeholder.Search': 'Cerca',
    'MursionPortal.SsLearnersStep.Label.NoTraineeToSelect': 'Nessun tirocinante da selezionare',
    'MursionPortal.LearnersStep.ErrorMessage.YouCanAddMaxLearners': 'Puoi aggiungere solo fino a {maxLearners} allievi per questo tipo di sessione.',
    'MursionPortal.LearnersStep.ErrorMessage.YouCanAddMaxLearner': 'Puoi aggiungere solo fino a {maxLearners} allievo per questo tipo di sessione.',
    'MursionPortal.LearnersStep.Label.NoLearnersToSelect': 'Nessun allievo da selezionare',
    'MursionPortal.LearnersStep.Label.NoUsersSelected': 'Nessun utente selezionato.',
    'MursionPortal.LearnersStep.Option.AllTeams': 'Tutti i team',
    'MursionPortal.LearnersStep.Button.AddEntireTeam': 'Aggiungi l\'intero team',
    'MursionPortal.LearnersStep.Note.AllowedSessionSIze': 'La dimensione della sessione consentita è pari a {maxLearners}. Seleziona allievi specifici',
    'MursionPortal.LearnersStep.Checkbox.OnlyUnauthorizedUsers': 'Solo gli utenti non autenticati parteciperanno',
    'MursionPortal.NotesStep.SessionNotes': 'Note di sessione',
    'MursionPortal.NotesStep.ErrorMessage.AllowedLimitExceeded': 'Il limite di caratteri consentito ({limit} caratteri) è stato superato',
    'MursionPortal.Button.LoadMore': 'Carica altro',
    'MursionPortal.Label.Trainer': 'Formatore',
    'MursionPortal.Label.Description': 'Descrizione',
    'MursionPortal.Label.Outcome': 'Esito',
    'MursionPortal.Label.Strategies': 'Strategie',
    'MursionPortal.Label.DeliveryMode': 'Modalità di consegna',
    'MursionPortal.Label.SessionLength': 'Durata sessione',
    'MursionPortal.Label.NoDescriptionProvided': 'Nessuna descrizione fornita',
    'MursionPortal.Label.NoOutcomeProvided': 'Nessun esito fornito',
    'MursionPortal.Label.NoStrategiesProvided': 'Nessuna strategia fornita',
    'MursionPortal.Placeholder.Dotted.Search': 'Cerca...',
    'MursionPortal.TimeStep.Label.NoAvailableTimeBlocks': 'Nessun periodo di tempo disponibile',
    'MursionPortal.TimeStep.Label.NoAvailableTimeBlocksInRange': 'Nessun periodo di tempo disponibile nell\'intervallo selezionato',
    'MursionPortal.TimeStep.Footer.BySelectingThisTimeBlock': 'Selezionando questo periodo di tempo verrà richiesto un tempo di sessione attualmente non disponibile. Faremo del nostro meglio per soddisfare questa richiesta se procedi o se fai scorrere l\'interruttore di seguito su "Off" per selezionare solo i periodi di tempo disponibili confermati.',
    'MursionPortal.TimeStep.Tooltip.SlideTheToggleOff': 'Fai scorrere l\'interruttore "Off" per selezionare solo periodi di tempo disponibili.',
    'MursionPortal.TimeStep.Tooltip.SlideTheToggleOn': 'Fai scorrere l\'interruttore "On" per richiedere un ulteriore periodo di tempo.',
    'MursionPortal.Label.LowerCase.Client': 'cliente',
    'MursionPortal.Label.Project': 'progetto',
    'MursionPortal.Label.Scenario': 'scenario',
    'MursionPortal.Projects.NoProjectsFound': 'Non è stato trovato alcun progetto.',
    'MursionPortal.Projects.YourTeamsNotAssigned': 'I tuoi team non sono ancora stati assegnati a nessun progetto.',
    'MursionPortal.Projects.NoProjectsCreated': 'Nessun progetto è stato ancora creato.',
    'MursionPortal.Teams.NoTeamsFound': 'Non è stato trovato nessun team.',
    'MursionPortal.Teams.YouHaveNotBeen': 'Non sei stato ancora aggiunto ad alcun team.',
    'MursionPortal.Teams.YouHaveNoTeams': 'Non hai ancora team.',
    'MursionPortal.Teams.NoTeamsCreated': 'Non sono ancora stati creati team.',
    'Settings.SSO.Modal.ConfigurationName': 'Nome configurazione',
    'Settings.SSO.Modal.AuthorizationEndpoint': 'Endpoint di autorizzazione',
    'Settings.SSO.Modal.LogoutEndpoint': 'Endpoint di disconnessione',
    'Settings.SSO.Modal.ClientID': 'ID cliente',
    'Settings.SSO.Modal.ClientSecret': 'Chiave segreta cliente',
    'Settings.SSO.Modal.Scope': 'Ambito',
    'Settings.SSO.Modal.UserInfoEndpoint': 'Endpoint informazioni utente',
    'Settings.SSO.Modal.TokenEndpoint': 'Endpoint token',
    'Settings.SSO.Modal.TokenHTTPMethod': 'Metodo HTTP token',
    'Settings.SSO.Modal.UserIDClaimName': 'Nome reclamo ID utente',
    'Settings.SSO.Modal.EmailClaimName': 'Nome reclamo E-mail',
    'Settings.SSO.Modal.FirstNameClaimName': 'Nome reclamo Nome',
    'Settings.SSO.Modal.LastNameClaimName': 'Nome reclamo Cognome',
    'Settings.SSO.Modal.LinkAllowed': 'Collegamento consentito',
    'Settings.SSO.Modal.UnlinkAllowed': 'Scollegamento consentito',
    'Settings.SSO.Modal.SingleLogoutEnabled': 'Disconnessione singola abilitata',
    'Settings.SSO.Modal.SingleSignOnServiceEndpoint': 'Endpoint del servizio di disconnessione singola',
    'Settings.SSO.Modal.EntityID': 'ID entità',
    'Settings.SSO.Modal.X509Certificate': 'Certificato X.509',
    'Settings.SSO.Button.CreateSsoConfiguration': 'Crea configurazione SSO ',
    'Settings.SSO.Modal.Protocol': 'Protocollo :',
    'Settings.SSO.Modal.Type': 'Tipo :',
    'Settings.SSO.Modal.ID': 'ID :',
    'Settings.SSO.Modal.AssignedClients': 'Clienti assegnati',
    'Settings.SSO.Modal.Button.DeleteSso': 'Elimina SSO',
    'Settings.SSO.ModalHeader.EditSsoConfiguration': 'Modifica configurazione SSO',
    'Settings.SSO.ModalHeader.NewSsoConfiguration': 'Nuova configurazione SSO',
    'Settings.SSO.Modal.Button.CreateSsoConfiguration': 'Crea configurazione SSO',
    'Settings.SSO.Modal.Button.SaveSsoConfiguration': 'Salva configurazione SSO',
    'Settings.SSO.Modal.DeletionConfirmationText': 'Sei sicuro di volere questa configurazione SSO?',
    'Settings.SSO.Modal.AddButton.GroupClaimName': 'Nome rivendicazione di gruppo',
    'Settings.SSO.Modal.AddButton.Team': 'Team',
    'Settings.SSO.Modal.AddButton.PlaceHolder.GroupClaimValue': 'Nome rivendicazione di gruppo',
    'Settings.SSO.Modal.SPInitiatedSsoUrl': 'URL SSO avviato da SP :',
    'Settings.SSO.Modal.Button.DownloadSPMetadata': 'Scarica i metadati SP',
    'Settings.SSO.Modal.Button.Public': 'Pubblico',
    'Settings.SSO.Modal.Button.Private': 'Privato',
    'Settings.SSO.Modal.ConfigurationIcon': 'Icona di configurazione :',
    'Settings.SSO.Modal.PlaceHolder.EnterTeamClaimName': 'Inserisci nome reclamo team',
    'Settings.SSO.Modal.TeamClaimName': 'Nome reclamo team',
    'Settings.SSO.Modal.TeamMapping': 'Mappatura team :',
    'Settings.SSO.Modal.Button.Change': 'Modifica',
    'MursionPortal.PlaceHolder.SelectAClient': 'Seleziona un cliente',
    'Settings.SSO.Modal.AddButton.PlaceHolder.SelectATeam': 'Seleziona un team',
    'MursionPortal.Label.Client': 'Cliente',
    'Settings.SSO.Modal.ConfirmDelete': 'Conferma eliminazione',
    'Settings.SSO.Table.ColumnHeader.ConfigurationName': 'Nome configurazione',
    'Settings.SSO.Table.ColumnHeader.Type': 'Tipo',
    'Settings.SSO.Table.ColumnHeader.Protocol': 'Protocollo',
    'Settings.SSO.Table.ColumnHeader.ClientsCount': 'Conteggio clienti',
    'Settings.SSO.PlaceHolder.EnterSsoName': 'Inserisci nome SSO',
    'Settings.SSO.Table.NoSsoSchemasFound': 'Non è stato trovato alcun programma SSO.',
    'Settings.SSO.Table.HoverTitle.ViewEditSso': 'Visualizza/Modifica SSO',
    'Settings.SSO.Modal.HoverTitle.CopySPInitiatedSsoUrl': 'Copia l\'URL SSO avviato da SP',
    'Settings.SSO.Modal.CopyButton.ModalHeader.SPInitiatedSsoUrl': 'URL SSO avviato da SP',
    'MursionPortal.CopyText.CopiedToClipboard': 'è stato copiato negli appunti!',
    'Settings.SSO.Text.Public': 'Pubblico',
    'Settings.SSO.Text.Private': 'Privato',
    'Settings.SSO.Modal.NoClientsAssigned': 'Nessun cliente assegnato',
    'MursionPortal.Label.NotAvailable': 'n/d',
    'Teams.Modal.Header.NewTeam': 'Nuovo team',
    'MursionPortal.Button.More': 'Altro',
    'Teams.Modal.Header.AllTeams': 'tutti i team',
    'MursionPortal.Label.Name': 'Nome:',
    'MursionPortal.Label.Location': 'Posizione:',
    'MursionPortal.Label.Tags': 'Tag:',
    'MursionPortal.Header.Facilitators': 'Facilitatori:',
    'MursionPortal.Header.Scenarios': 'Scenari:',
    'MursionPortal.Button.SaveTeamInfo': 'Salva le informazioni sul team',
    'MursionPortal.IconButton.Cancel': 'annulla',
    'MursionPortal.IconButton.Edit': 'modifica',
    'MursionPortal.ButtonTitle.CancelEdit': 'Annulla modifica',
    'MursionPortal.ButtonTitle.EditTeam': 'Modifica team',
    'MursionPortal.ConfirmationText.RestoreTeam': 'Sei sicuro di voler ripristinare questo team?',
    'MursionPortal.ButtonTitle.RestoreTeam': 'Ripristina team',
    'MursionPortal.ConfirmationText.ArchiveTeam': 'Sei sicuro di voler archiviare questo team?',
    'MursionPortal.ButtonTitle.ArchiveTeam': 'Archivia team',
    'MursionPortal.EmptyMessage.NoFacilitators': 'Nessun facilitatore',
    'MursionPortal.EmptyMessage.NoSelectedFacilitators': 'Nessun facilitatore selezionato',
    'MursionPortal.Title.Facilitators': 'Facilitatori',
    'MursionPortal.Heading.Archived': '(Archiviato)',
    'Teams.Modal.Header.AllLearners': 'Tutti gli allievi',
    'Teams.Modal.Placeholder.SearchLearners': 'Cerca allievi...',
    'Teams.Modal.EmptyMessage.NoLearnersToSelect': 'Nessun allievo da selezionare',
    'Teams.Modal.Header.TeamLearners': 'Allievi del team',
    'Teams.Modal.Placeholder.SearchTeamLearners': 'Cerca allievi del team...',
    'Teams.Modal.EmptyMessage.NoTeamLearnersToSelect': 'Nessun allievo del team da selezionare',
    'Teams.Modal.Header.AssignLearners': 'Assegna gli allievi a un team "{teamName}"',
    'Teams.AssignLearners.Modal.Button.SelectAll': 'Seleziona tutto',
    'Teams.AssignLearners.Modal.EmptyMessage.NoItems': 'Nessun elemento da selezionare',
    'MursionPortal.Header.Teams': 'Team',
    'Teams.Modal.AlertMessage.Timezone': 'In caso di cambio di fuso orario, controlla gli eventi esistenti e le disponibilità.',
    'Teams.SelectorMenu.SelectedOf': 'Selezione: {selected} di {totalNumber}',
    'Teams.Modal.AlertMessage.ProfileTimezone': 'La selezione del fuso orario del tuo profilo corrente non corrisponde alle impostazioni di data e ora del tuo computer ({updatedTimezone}).Fai clic su "Salva modifiche" per procedere comunque o seleziona un\'opzione di fuso orario del profilo diversa.',
    'MursionPortal.Label.Learner': 'Allievo',
    'Teams.Modal.Button.More': 'Ancora {overlappedAmount}...',
    'MursionPortal.IconButton.Archive': 'archivia',
    'Teams.ToggleButton.Learners': 'Allievi',
    'Teams.ToggleButton.Sessions': 'Sessioni',
    'MursionPortal.Status.RegistrationStatus': 'Stato di registrazione',
    'MursionPortal.Status.RegistrationAccepted': 'Registrazione accettata',
    'MursionPortal.Status.RegistrationNotAccepted': 'Registrazione non accettata',
    'MursionPortal.SortBy': 'Ordina per',
    'MursionPortal.Placeholder.SortBy': 'ordina-per',
    'Clients.Button.CreateClient': 'Crea cliente ',
    'Clients.CreateClient.Header.NewClient': 'Nuovo cliente',
    'Clients.CreateClient.Button.CreateClient': 'Crea cliente',
    'Clients.CreateClient.Label.ClientName': 'Nome cliente',
    'Clients.CreateClient.Placeholder.CityCountry': 'Città, Paese',
    'MursionPortal.Timezone': 'Fuso orario',
    'Clients.CreateClient.Label.Logo': 'Logo',
    'Clients.Text.NoClientsFound': 'Non è stato trovato nessun cliente.',
    'MursionPortal.Text.Active': 'Attivo',
    'MursionPortal.Text.Archived': 'Archiviato',
    'Clients.Modal.Button.BackToClientList': 'Torna all\'elenco dei clienti',
    'Clients.Modal.EmptyMessage.NoClientsFound': 'Nessun cliente trovato',
    'Clients.Modal.Placeholder.EnterClientName': 'Inserisci nome cliente',
    'Clients.Modal.Header.ClientsList': 'ELENCO DEI CLIENTI',
    'Clients.Modal.Header.Client': 'CLIENTE',
    'Clients.Modal.Header.AllClients': 'tutti i clienti',
    'MursionPortal.Search': 'Cerca',
    'Clients.Modal.RestoreConfirmText': 'Sei sicuro di voler ripristinare questo cliente?',
    'Clients.Modal.DeleteConfirmText': 'Sei sicuro di voler archiviare questo cliente?',
    'Clients.Modal.RelatedDataText': 'Verranno archiviati anche tutti i dati correlati.',
    'Clients.Modal.DeleteErrorText': 'Il cliente corrente ha uno o più scenari. Archivia gli scenari del progetto prima di archiviare questo cliente.',
    'Clients.Button.RestoreClient': 'Ripristina cliente',
    'Clients.IconButton.Cancel': 'annulla',
    'Clients.IconButton.Edit': 'modifica',
    'Clients.IconButton.CancelEdit': 'Annulla modifica',
    'Cleints.IconButton.EditScenarioName': 'Modifica nome scenario',
    'Cleints.IconButton.ApplyNameChange': 'Applica modifica del nome',
    'Clients.IconButton.Apply': 'applica',
    'Clients.IconButton.EditClient': 'Modifica cliente',
    'Clients.Button.ArchiveClient': 'Archivia cliente',
    'Clients.Modal.Heading.Archived': '(Archiviato)',
    'Clients.Modal.Label.Name': 'Nome:',
    'Clients.Modal.Placeholder.ClientName': 'Nome cliente',
    'Clients.Modal.Label.Location': 'Posizione:',
    'Clients.Modal.Label.Industry': 'Settore:',
    'Clients.Modal.Placeholder.SelectIndustry': 'Seleziona settore',
    'Clients.Modal.Label.Timezone': 'Fuso orario:',
    'Clients.Modal.Label.SchedulingByRequest': 'Pianificazione su richiesta:',
    'MursionPortal.Enabled': 'Abilitato',
    'MursionPortal.Disabled': 'Disabilitato',
    'Clients.Modal.Label.SchedulingWindow': 'Finestra di pianificazione:',
    'Clients.Modal.Label.HowFarInAdvance': 'Con quanto anticipo i clienti dovrebbero pianificare',
    'MursionPortal.Days': 'giorni',
    'MursionPortal.Day': 'giorno',
    'Clients.Modal.Label.AvailableSharedSSOTitle': 'L\'elenco degli SSO condivisi disponibili che possono essere utilizzati per l\'accesso',
    'Clients.Modal.Label.AvailableSharedSSOLabel': 'SSO condiviso disponibile:',
    'Clients.Modal.EmptySelectedMessage': 'Nessun SSO selezionato',
    'Clients.Modal.EmptyListMessage': 'Nessun SSO disponibile',
    'Clients.Modal.SSOTitle': 'Seleziona SSO disponibile',
    'Cleints.Modal.Label.AvailablePrivateSSOTitle': 'L\'elenco degli SSO privati disponibili che possono essere utilizzati per l\'accesso',
    'Clients.Modal.Label.AccountOwners': 'Titolari account:',
    'Clients.Modal.AccountOwner.EmptyListMessage': 'Nessun titolare account',
    'Clients.Modal.AccountOwner.EmptySelectedMessage': 'Nessun titolare account selezionato',
    'Clients.Modal.AccountOwnersTitle': 'Titolare/i account',
    'MursionPortal.Message.Updating': 'Aggiornamento in corso...',
    'MursionPortal.Button.SaveClientInfo': 'Salva le informazioni sul cliente',
    'MursionPortal.Add': 'Aggiungi ',
    'Clients.Modal.Label.EditSSO': 'Modifica SSO',
    'Clients.Modal.Label.Edit': 'modifica',
    'MursionPortal.NumberOfFacilitators': 'Numero di facilitatori',
    'MursionPortal.NumberOfLearners': 'Numero di allievi',
    'Clients.Modal.Button.CreateTeam': 'Crea team ',
    'MursionPortal.NewTeam': 'Nuovo team',
    'MursionPortal.NoTags': 'Nessun tag',
    'MursionPortal.Header.Tags': 'Tag',
    'MursionPortal.AddTag': 'Aggiungi tag',
    'Clients.Modal.Teams.FacilitatorsEmptyListMessage': 'Nessun facilitatore selezionato',
    'Clients.Modal.Teams.LearnersEmptyListMessage': 'Nessun allievo selezionato',
    'MursionPortal.Learners': 'Allievi',
    'MursionPortal.Facilitator': 'Facilitatore',
    'MursionPortal.CreateTeam': 'Crea team',
    'Clients.Modal.Teams.ConfirmationText.RestoreTeam': 'Sei sicuro di voler ripristinare questo team?',
    'Clients.Modal.Teams.ButtonTitle.RestoreTeam': 'Ripristina team',
    'Clients.Modal.Teams.ConfirmationText.ArchiveTeam': 'Sei sicuro di voler archiviare questo team?',
    'Clients.Modal.Teams.ButtonTitle.ArchiveTeam': 'Archivia team',
    'Clients.Modal.Projects.Header.NewProject': 'Nuovo progetto',
    'MursionPortal.ProjectName': 'Nome del progetto',
    'MursionPortal.Contracted': 'Aggiudicato',
    'MursionPortal.CancellationWindow': 'Finestra di annullamento',
    'MursionPortal.SoftwareVersion': 'Versione del software',
    'MursionPortal.Billing': 'Fatturazione',
    'Clients.Modal.Projects.Checkbox.NonBillable': 'Non fatturabile',
    'MursionPortal.Recording': 'Registrazione',
    'Clients.Modal.Projects.Checkbox.AllowRecording': 'Consenti registrazione',
    'Clients.Modal.Projects.Label.RecordedVideos': 'I video registrati tornano a:',
    'Clients.Modal.Projects.Checkbox.RecordingPermission': 'Il permesso di registrazione spetta all\'allievo',
    'Clients.Modal.Projects.ButtonTitle.SaveProjectInfo': 'Salva le informazioni sul progetto',
    'Clients.Modal.Projects.Header.ProjectManager': 'Responsabile di progetto',
    'Clients.Modal.Projects.ProjectsEmptyListMessage': 'Nessun responsabile di progetto selezionato',
    'MursionPortal.Header.Bracketed.Scenarios': 'Scenario/i',
    'Clients.Modal.Projects.Text.CompleteProjectInfoFirst': 'Completa le informazioni sul progetto prima',
    'MursionPortal.Header.CreateScenario': 'Crea scenario',
    'Clients.Modal.Projects.ConfirmationText.RestoreProject': 'Sei sicuro di voler ripristinare questo progetto?',
    'Clients.Modal.Projects.ButtonTitle.RestoreProject': 'Ripristina progetto',
    'Clients.Modal.Projects.ButtonTitle.Edit': 'Modifica progetto',
    'Clients.Modal.Projects.ButtonTitle.CancelEdit': 'Annulla modifica',
    'Clients.Modal.Label.Cancel': 'annulla',
    'Clients.Modal.Projects.ConfirmationText.ArchiveProject': 'Sei sicuro di voler archiviare questo progetto?',
    'Clients.Modal.Projects.ButtonTitle.ArchiveProject': 'Archivia progetto',
    'Clients.Modal.Projects.ArchiveErrorMessage': 'Il progetto corrente ha uno o più scenari attivi. Archivia gli scenari del progetto prima di archiviare questo progetto.',
    'Clients.Modal.Projects.ButtonTitle.EditTags': 'Modifica tag',
    'Clients.Modal.Projects.Label.UtilizedMissedSessions': 'Sessioni perse utilizzate,',
    'Clients.Modal.Projects.Button.CreateNew': 'Crea Nuovo ',
    'Clients.Modal.Projects.Button.RepeatClient': 'Ripeti cliente',
    'Clients.Modal.Projects.Button.RepeatBank': 'Ripeti gruppo',
    'Clients.Modal.Projects.ButtonTitle.CreateNewScenario': 'Crea un nuovo scenario',
    'Clients.Modal.Projects.ButtonTitle.RepeatClientScenario': 'Ripeti lo scenario del cliente',
    'Clients.Modal.Projects.ButtonTitle.ScenarioBank': 'Crea uno scenario dal gruppo degli scenari',
    'Clients.Modal.Button.Schedule': 'Pianifica',
    'MursionPortal.Button.ActivateScenario': 'Attiva lo scenario',
    'MursionPortal.Button.ChangeScenario': 'Cambia scenario',
    'Clients.IconButton.Confirm': 'conferma',
    'Clients.IconButton.ConfirmEdit': 'Conferma modifica',
    'Clients.Modal.Projects.HeaderText.ChooseScenario': 'Scegli lo scenario da ripetere',
    'Clients.Modal.Projects.HeaderText.ChooseScenarioBank': 'Scegli lo scenario da Gruppo scenari',
    'MursionPortal.Placeholder.LowerCase.EnterScenarioName': 'Inserisci il nome dello scenario',
    'MursionPortal.IconButton.ResetToDefault': 'Reimposta a nome predefinito',
    'MursionPortal.IconButton.Reset': 'reimposta',
    'Clients.Modal.Header.CreatePlanning': 'Crea la pianificazione per il nuovo scenario',
    'Clients.Modal.EmptyMessage.NoClientScenariosFound': 'Non sono stati trovati scenari cliente.',
    'Clients.Modal.EmptyMessage.NoBankScenariosFound': 'Non sono stati trovati scenari di gruppo.',
    'Clients.Modal.Message.NoTeamsSpecified': 'Nessun team specificato',
    'Clients.Modal.EmptyMessage.NoSelectedTeams': 'Nessun team selezionato',
    'Clients.Modal.Message.NoAssignedTeams': 'Il cliente non ha team assegnati',
    'Clients.Modal.Label.Certificate': 'Attestato',
    'Clients.Modal.Label.DeliveryMode': 'Modalità di consegna',
    'Clients.Modal.Label.SessionSize': 'Dimensione sessione',
    'Clients.Modal.Label.SessionLength': 'Durata sessione',
    'Clients.Modal.Label.Outcome': 'Esito',
    'Clients.Modal.Label.Strategies': 'Strategie',
    'Clients.Modal.Label.Description': 'Descrizione',
    'MursionPortal.Button.MoreInfo': 'Maggiori informazioni...',
    'MursionPortal.Button.HideAdditionalInfo': 'Nascondi informazioni aggiuntive...',
    'MursionPortal.Header.Timeline': 'Sequenza temporale',
    'MursionPortal.Header.Projects': 'Progetti ',
    'MursionPortal.Header.Sessions': 'Sessioni',
    'MursionPortal.Header.Users': 'Utenti',
    'MursionPortal.Text.Yes': 'sì',
    'MursionPortal.Text.No': 'no',
    'MursionPortal.Button.Google': 'Google',
    'MursionPortal.Button.Outlook': 'Outlook, iCal, altro',
    'MursionPortal.Heading.GoogleCalendar': 'Calendario Google ',
    'MursionPortal.Heading.AddToCalendar': 'Aggiungi al calendario ',
    'Dashboard.Legacy.GoogleCalendarList.Authorized': 'Se non lo hai già fatto, ti verrà chiesto di <code>autorizzare</code> il <code>portale {companyName}</code> ad aggiungere eventi al tuo calendario.',
    'Dashboard.Legacy.GoogleCalendarList.EventAdd': 'L\'evento verrà <code>aggiunto al tuo calendario</code> e sarà ancora possibile accedervi sul portale {companyName}.',
    'MursionPortal.Heading.OtherCalendars': 'Altri calendari',
    'Dashboard.Legacy.OtherCalendarList.Downloaded': 'Il tuo evento verrà <code>scaricato</code> come <code>{companyName}</code>',
    'Dashboard.Legacy.OtherCalendarList.SelectCalendar': '<code>Fai clic con il tasto destro</code>, seleziona <code>"Apri con"</code> e seleziona l\'applicazione del calendario.',
    'Dashboard.Legacy.OtherCalendarList.ImportWithinCalendar': '(Puoi anche selezionare "Importa" nel calendario e individuare il file scaricato)',
    'Dashboard.Legacy.OtherCalendarList.AddedToCalendar': 'L\'evento verrà <code>aggiunto al tuo calendario</code> e sarà ancora possibile accedervi sul portale {companyName}',
    'MursionPortal.Text.NoLearnersAddedYet': 'Non sono stati ancora aggiunti allievi',
    'MursionPortal.Heading.Schedule': 'Pianifica',
    'MursionPortal.Heading.Users': 'UTENTI',
    'MursionPortal.Heading.ProfServices': 'Servizi prof.',
    'MursionPortal.Heading.SimSpecialist': 'Specialisti Sim',
    'MursionPortal.Heading.Clients': 'Clienti',
    'MursionPortal.Heading.Sessions': 'Sessioni',
    'MursionPortal.Heading.Projects': 'Progetti',
    'MursionPortal.Heading.Teams': 'Team',
    'MyMessages.Button.ArchiveAll': 'Archivia tutto',
    'MyMessages.Button.ArchivedMessages': 'Messaggi archiviati',
    'MyMessages.Button.ShowMore': 'Mostra altro...',
    'MyMessages.Label.NewMessages': '<code>{newMessagesCount}</code> nuovi messaggi',
    'MyMessages.Label.NewMessage': '<code>{newMessagesCount}</code> nuovo messaggio',
    'MyMessages.HoverTitle.InboxButton': 'pulsante posta in arrivo',
    'MyMessages.Text.InboxButton': 'Pulsante Posta in arrivo',
    'MyMessages.Text.HaveNewMessages': 'Hai {total} nuovi messaggi',
    'MyMessages.Text.HaveNewMessage': 'Hai un nuovo messaggio',
    'MursionPortal.CookiePolicy.Header.CookiePolicy': 'Informativa sui cookie',
    'MursionPortal.CookiePolicy.Text.MursionUsesEssentialCookiesOnly': 'Mursion utilizza solo i cookie essenziali necessari per la tua sicurezza e per il funzionamento della nostra applicazione Web. Non utilizziamo i cookie per raccogliere dati personali o altre informazioni, come gli indirizzi IP o l\'utilizzo del sito Web.',
    'MursionPortal.CookiePolicy.Text.ForMoreInformation': ' Per ulteriori informazioni, consulta la nostra <code>informativa sulla privacy</code>',
    'MursionPortal.Button.Ok': 'Ok',
    'MyProfile.Button.SaveChanges': 'Salva modifiche',
    'MyProfile.Label.ExternalProfiles': 'Profili esterni',
    'MyProfile.Label.UserRoles': 'Ruoli utente',
    'MyProfile.Label.UserRole': 'Ruolo utente',
    'MyProfile.Heading.ReviewAccountInformation': 'Rivedi le informazioni sull\'account',
    'MyProfile.HoverTitle.UnlinkProfile': 'Scollega il tuo profilo {linkName}',
    'MyProfile.HoverTitle.LinkProfile': 'Collega il profilo {linkName}',
    'MyProfile.Button.Unlink': 'Annulla collegamento',
    'MyProfile.Text.UnlinkConfirmationText': 'Sei sicuro di voler scollegare il tuo profilo {name}? Tieni presente che verrai disconnesso anche da {name}.',
    'MyProfile.Title.ProfileUnlink': '{name} profilo scollegato',
    'MyProfile.Text.SuccessfullyUnlinkedProfile': 'Hai scollegato correttamente il tuo profilo {name}',
    'MyProfile.Text.TimeZoneIsSetAutomatically': 'Il tuo fuso orario viene impostato automaticamente su {getTimeZone}. Fai clic su "Conferma" per accettare questa selezione o "Annulla" per effettuarne un\'altra.',
    'MyProfile.Text.TimeZoneChange': 'In caso di cambio di fuso orario, controlla gli eventi esistenti e le disponibilità.',
    'MyProfile.Text.ProfileTimeZoneNotMatchComputer': 'La selezione del fuso orario del profilo corrente non corrisponde alle impostazioni di data e ora del computer ({timeZone}).Fai clic su "Salva modifiche" per procedere comunque o seleziona un\'opzione di fuso orario del profilo diversa.',
    'MursionPortal.Button.MyProfile': 'Il mio profilo',
    'MursionPortal.Button.LogOut': 'Esci',
    'MyProfile.Label.Language': 'Lingua',
    'MursionPortal.Label.Roles': 'Ruoli',
    'MursionPortal.Label.Role': 'Ruolo',
    'MursionPortal.Projects.Header.ScenarioType': 'Tipo di scenario',
    'MursionPortal.Projects.Header.Client': 'Cliente',
    'MursionPortal.Projects.Header.Project': 'Progetto',
    'MursionPortal.Projects.Header.Scenario': 'Scenario',
    'MursionPortal.Projects.Header.SimulationContent': 'Contenuto della simulazione',
    'MursionPortal.Projects.Header.Date': 'Data',
    'MursionPortal.Projects.Header.Time': 'Ora',
    'MursionPortal.Projects.Header.Notes': 'Note',
    'Filters.Date.Week': 'Settimana',
    'Users.Table.Modal.SignLanguageInterpreter': 'Interprete lingua dei segni',
    'Calendar.Button.Schedule': 'Pianifica',
    'Calendar.Button.Today': 'Oggi',
    'MursionPortal.Availability': 'Disponibilità',
    'Calendar.Button.Schedule.QualityAssurance': 'Controllo qualità',
    'MursionPortal.Training': 'Formazione',
    'Calendar.Button.Schedule.EmergencySession': 'Sessione di emergenza',
    'Calendar.Button.Schedule.Session': 'Sessione',
    'Calendar.Button.Schedule.BreakPrep': 'Pausa/Preparazione',
    'MursionPortal.Month': 'Mese',
    'MursionPortal.Week': 'Settimana',
    'Calendar.Heading.Calendars': 'Calendari',
    'Calendar.Tip.SelectACalendar': 'Seleziona un calendario per visualizzare la disponibilità',
    'Calendar.ScheduledHours.SumOfAllScheduledSessions': 'Somma di tutte le sessioni pianificate nella vista corrente',
    'MursionPortal.ShortLabel.Hours': 'ore',
    'Calendar.ScheduledHours.SumOfAllTrainingSessions': 'Somma di tutte le sessioni di formazione pianificate nella vista corrente',
    'Calendar.ScheduledHours.Trainings': 'Corsi di formazione',
    'Calendar.ScheduledHours.SumOfRemainingAvailabilityHours': 'Somma di tutte le ore di disponibilità di SS rimanenti nella vista corrente',
    'Calendar.ScheduledHours.Available': 'Disponibile',
    'MursionPortal.Scheduled': 'Pianificato',
    'Calendar.Label.Display': 'Visualizza',
    'MursionPortal.Client': 'Cliente',
    'Calendar.Filter.Company': 'Azienda',
    'Calendar.Filter.SwapRequests': 'Richieste di scambio',
    'MursionPortal.QA': 'QA',
    'Calendar.SearchFilter.FilterProjects': 'Filtra progetti',
    'Calendar.SearchFilter.FilterClients': 'Filtra clienti',
    'Calendar.SearchFilter.FilterSimSpecialists': 'Filtra Specialisti Sim',
    'Calendar.IndustrySelector.Options.SelectAnIndustry': 'Seleziona un settore',
    'Calendar.IndustrySelector.Options.AllIndustries': 'Tutti i settori',
    'Calendar.IndustrySelector.Options.NoIndustriesToSelect': 'Nessun settore da selezionare',
    'MursionPortal.SelectAClient': 'Seleziona un attestato',
    'Calendar.ClientSelector.Options.AllClients': 'Tutti i clienti',
    'Calendar.ClientSelector.Options.NoClientToSelect': 'Nessun cliente da selezionare',
    'MursionPortal.SelectACertificate': 'Seleziona un attestato',
    'Calendar.CertificateSelector.Options.AllCertificates': 'Tutti gli attestati',
    'Calendar.CertificateSelector.Options.NoCertificatesToSelect': 'Nessun attestato da selezionare',
    'Calendar.ClientsList.NoClientsFound': 'Non è stato trovato nessun cliente',
    'MursionPortal.UncheckAll': 'Deseleziona tutto',
    'MursionPortal.CheckAll': 'Seleziona tutto',
    'Calendar.SimSpecialistList.NoSimSpecialistsFound': 'Non sono stati trovati specialisti sim',
    'Calendar.ProjectsList.CloseProjectOverview': 'Chiudi panoramica del progetto',
    'Calendar.ProjectsList.AllProjects': 'TUTTI I PROGETTI',
    'MursionPortal.Bracketed.Scenarios': 'Scenario/i',
    'Calendar.ProjectsList.Overview': 'Panoramica',
    'Calendar.ProjectsList.CloseClientOverview': 'Chiudi la panoramica del cliente',
    'MursionPortal.Scenario': 'scenario',
    'Calendar.ScenariosList.NoScenarios': 'Nessun scenario',
    'MursionPortal.Delivery': 'Consegna',
    'MursionPortal.Mode': 'Modalità',
    'MursionPortal.Timeline': 'Sequenza temporale',
    'Calendar.ScenariosList.Description': 'Descrizione',
    'MursionPortal.Outcome': 'Esito',
    'MursionPortal.Strategies': 'Strategie',
    'MursionPortal.ContentBundle': 'Pacchetto di contenuti',
    'MursionPortal.SimulationEnvironments': 'Ambiente/i di simulazione',
    'MursionPortal.AvatarsInSimulations': 'Avatar in simulazione/i',
    'Calendar.LearnersList.SessionNotScheduled': 'Sessione non pianificata',
    'Calendar.LearnersList.SessionScheduled': 'Sessione pianificata',
    'Calendar.LearnersList.SessionComplete': 'Sessione completa',
    'Calendar.LearnersList.Learner': '{learnersCount} allievo',
    'Calendar.LearnersList.Learners': '{learnersCount} allievi',
    'Calendar.Button.CantSchedule': 'Impossibile pianificare',
    'Calendar.Button.ScheduleEvent': 'Pianifica evento',
    'Calendar.MainPanel.ConfirmText.AreYouSure': 'Sei sicuro di voler annullare questo evento?',
    'Calendar.MainPanel.CancelEvent': 'Evento annullato',
    'Calendar.MainPanel.UpdateEvent': 'Aggiorna evento',
    'MursionPortal.Submit': 'Invia',
    'Calendar.MainPanel.NewEvent': 'Nuovo Evento',
    'MursionPortal.Project': 'Progetto',
    'MursionPortal.Button.Reload': 'Ricarica',
    'Clients.Modal.Label.FacilitatorVisibility': 'Vista facilitatore:',
    'Clients.Modal.Placeholder.FacilitatorVisibility': 'Seleziona Vista facilitatore',
    'Clients.Modal.Projects.ConfirmationText.EditProject': 'Sei sicuro di voler modificare questo progetto?',
    'Projects.ScenarioList.Legend.ScenariosStatusTab': 'Schede di stato degli scenari',
    'Projects.ScenarioList.Legend.CreateNew': 'Crea nuovo, Ripeti cliente, Ripeti pulsanti scenario banca',
    'MursionPortal.ScenarioCard.Tab.Legend.ViewModeTab': 'Scheda Modalità di visualizzazione',
    'MursionPortal.SearchPlaceHolder.SearchClient': 'Cerca cliente...',
    'Settings.SSO.Modal.AddButton.SearchPlaceHolder.SearchTeam': 'Seleziona team...',
    'Settings.SSO.Modal.Button.ChangeLabel': 'Modifica {label}',
    'MursionPortal.Label.Yes': 'Sì',
    'MursionPortal.Label.No': 'No',
    'Settings.SSO.Modal.AddButton.PlaceHolder.NoOptions': 'Nessuna opzione',
    'Settings.SSO.Modal.ClientName': 'Nome cliente',
    'MursionPortal.Modal.Button.Add': 'Aggiungi',
    'MursionPortal.Modal.Button.Remove': 'Rimuovi',
    'Users.Table.Hover.ViewEditAcceptedUsers': 'Visualizza/modifica gli utenti accettati',
    'Users.Table.Hover.ViewEditUnacceptedUsers': 'Visualizza/modifica gli utenti non accettati',
    'MursionPortal.Label.Industry': 'Settore',
    'MursionPortal.Label.Timezone': 'Fuso orario',
    'MursionPortal.Header.Client': 'Cliente',
    'MursionPortal.FilterByCertificate': 'Filtra per attestato',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Header.CreateQAEvent': 'Crea evento QA',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.TimeBlock': 'Fasce orarie',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.Participants': 'Partecipanti',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Button.SubmitNewEvent': 'Invia nuovo evento',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Error.TimeBlockOutsideRange': 'Il periodo di tempo è al di fuori dell\'intervallo disponibile',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Error.TimeBlockBeforeCurrentTime': 'Il periodo di tempo è precedente all\'ora corrente',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.Start': 'Inizia',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.End': 'Fine',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.Length': 'Lunghezza',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Error.NoAvailableTimeBlock': 'Non esiste un periodo di tempo disponibile per il giorno selezionato',
    'Calendar.Modal.Button.Client': 'Cliente',
    'Calendar.Modal.Button.Project': 'Progetto',
    'Calendar.Modal.Button.Scenario': 'Scenario',
    'Calendar.Modal.Header.CompanyEvents': 'Eventi aziendali',
    'MursionPortal.Header.Session': 'Sessione',
    'MursionPortal.Header.Events': 'Eventi',
    'MursionPortal.Header.Event': 'Evento',
    'Calendar.Modal.Label.Time': 'Ora',
    'Calendar.Label.SelectTimeZone': 'Seleziona fuso orario',
    'Calendar.Text.Training': 'Formazione',
    'Calendar.Text.QA': 'QA',
    'Calendar.Modal.Text.ConfirmDeleteTime': 'Conferma eliminazione blocco temporale',
    'Calendar.Button.NewTimeBlock': 'Nuovo blocco temporale',
    'Calendar.Modal.Label.Capitalized.Time': 'ORARIO',
    'Calendar.Modal.Label.Participants': 'PARTECIPANTI',
    'Calendar.Modal.Text.NoParticipants': 'Nessun partecipante',
    'Calendar.Modal.Label.SimulationSpecialist': 'SPECIALISTA DI SIMULAZIONE',
    'Calendar.Modal.Label.MeetingOrganizer': 'ORGANIZZATORE DELLA RIUNIONE',
    'Calendar.Error.ErrorPrefixMustBeBetween': '{errorPrefix} deve essere compreso tra  {duration1} e {duration2}.',
    'Calendar.Error.SelectedTimeBlockOverlapsExistingTime': 'Il periodo di tempo selezionato si sovrappone al periodo di tempo esistente.',
    'Calendar.Error.SelectedTimeBlockOverlapsUnavailableTime': 'Il periodo di tempo selezionato si sovrappone all\'intervallo di tempo non disponibile.',
    'Calendar.Error.SelectedTimeBlockIsNotMultipleOfEventGap': 'Il periodo di tempo selezionato non è una moltiplicazione dell\'intervallo di eventi ({duration})',
    'Calendar.Text.AvailabilityUser': 'Disponibilità {user}',
    'Calendar.Text.AvailabilityTime': 'Disponibilità {time}',
    'Calendar.Text.AvailabilityDurationAvailabilityTime': '{availabilityDuration} Disponibilità {time}',
    'Calendar.Text.TrainingsTime': '{eventsCount} Corsi di formazione {time}',
    'Calendar.Text.SessionsTime': '{eventsCount} Sessioni {time}',
    'Calendar.Text.AvailabilityName': 'Disponibilità {name}',
    'Calendar.Label.Learners': 'Allievi',
    'Calendar.Text.FullAvailability': 'Piena disponibilità',
    'Calendar.Text.YourAvailability': 'La tua disponibilità',
    'Calendar.Text.Event': 'Evento',
    'Calendar.Header.Trainings': 'Corsi di formazione',
    'Calendar.Header.CompanyEvents': 'Eventi aziendali',
    'Calendar.Header.Session': 'Sessione',
    'Calendar.Error.TimeBlockIsOutsideAvailableRange': 'Il periodo di tempo è al di fuori dell\'intervallo disponibile',
    'Calendar.Error.TimeBlockIsBeforeCurrentTime': 'Il periodo di tempo è precedente all\'ora corrente',
    'Calendar.Label.Break': 'Pausa',
    'Calendar.Label.Prep': 'Prep',
    'Calendar.Modal.Header.CreateBreakPrepEvent': 'Crea evento pausa/preparazione',
    'Calendar.Modal.Label.EventType': 'EventType',
    'Calendar.Modal.Label.TimeBlocks': 'Fasce orarie',
    'Calendar.Text.NoTimeBlocksInSelectedRange': 'Nessun periodo di tempo disponibile nell\'intervallo selezionato',
    'Calendar.Text.NoAvailableTimeBlocks': 'Nessun periodo di tempo disponibile',
    'Calendar.Text.SelectingThisTimeBlock': 'Selezionando questo periodo di tempo verrà richiesto un tempo di sessione attualmente non disponibile. Faremo del nostro meglio per soddisfare questa richiesta se procedi o se fai scorrere l\'interruttore di seguito su "Off" per selezionare solo i periodi di tempo disponibili confermati.',
    'Calendar.ToolTip.SlideToggleOff': 'Fai scorrere l\'interruttore "Off" per selezionare solo periodi di tempo disponibili.',
    'Calendar.ToolTip.SlideToggleOn': 'Fai scorrere l\'interruttore "On" per richiedere un ulteriore periodo di tempo.',
    'Calendar.Label.SelectAnyTimeBlock': 'Seleziona qualsiasi periodo di tempo',
    'Users.CreateUser.Label.GoogleMeetID': 'ID Google Meet',
    'Users.CreateUser.GoogleMeetId.Placeholder.GoogleMeetID': 'ID Google Meet',
    'Users.CreateUser.GoogleMeetID.Hover': 'Inserisci un ID Google Meet',
    'Setting.Edit.Modal.Error.UnableToCreateSession': 'Impossibile creare la sessione per l\'orario selezionato  {formattedStartTime} - {formattedEndTime}',
    'Session.Header.Session': 'Sessione',
    'Session.Header.AcceptSessionRequest': 'Conferma se desideri accettare questa richiesta di sessione e aggiungila alla tua pianificazione.',
    'CommonComponent.RecurrenceForm.Label.UntilDate': 'Fino alla data',
    'CommonComponent.RecurrenceForm.Label.Period': 'Periodo',
    'CommonComponent.RecurrenceForm.Option.Daily': 'Giornaliero',
    'CommonComponent.RecurrenceForm.Option.Weekly': 'Settimanale',
    'CommonComponent.RecurrenceForm.Label.Weekday': 'Giorno della settimana',
    'CommonComponent.RecurrenceForm.Text.NoSelectedWeekdays': 'Nessun giorno della settimana selezionato',
    'CommonComponent.RecurrenceForm.Error.PleaseSelectWeekdays': 'Seleziona giorno/i della settimana',
    'Calendar.EventType.Availability': 'Disponibilità',
    'Calendar.EventType.Session': 'Sessione',
    'Calendar.EventType.Training': 'Formazione',
    'Calendar.EventType.CompanyEvent': 'Evento aziendale',
    'Calendar.EventType.NewEvent': 'Nuovo evento',
    'Calendar.Text.NewEventTime': 'Nuovo evento {time}',
    'Calendar.Header.Training': 'Formazione',
    'Calendar.Modal.Text.Sessions': 'Sessioni',
    'Calendar.Modal.Text.Trainings': 'Corsi di formazione',
    'Calendar.Modal.Text.Events': 'Eventi',
    'Calendar.Modal.Text.PendingNoSS': '- In attesa (No SS).',
    'Calendar.Modal.Text.PendingSwap': '- In attesa (Scambio).',
    'Calendar.Modal.Text.PreBooked': '- Prenotato in anticipo.',
    'Calendar.Modal.Text.NeedsReview': '- Richiede revisione.',
    'Calendar.Modal.Text.JoinSession': '- puoi partecipare alla sessione.',
    'Calendar.Modal.Text.NoLearnersInSession': '- non ci sono allievi nella sessione.',
    'Calendar.Modal.Text.NoLearnersInSessionAfterBookedTime': '- non ci sono allievi nella sessione dopo l\'orario prenotato.',
    'Calendar.Modal.Header.Events': 'Eventi',
    'Calendar.Modal.Header.Session': 'sessione',
    'Calendar.Modal.Header.Training': 'formazione',
    'Calendar.Modal.Header.Availability': 'disponibilità',
    'Calendar.Modal.Filter.Client': 'Cliente',
    'Calendar.Modal.Filter.Project': 'Progetto',
    'Calendar.Modal.Filter.Time': 'Orario',
    'Calendar.Modal.Label.Recurrence': 'Ricorrenza',
    'Calendar.Modal.Header.CreateAvailability': 'Crea disponibilità',
    'Calendar.Modal.Text.Recurring': 'Ricorrente',
    'Calendar.SimSpecialistButton.Modal.Header.Scheduled': 'Pianificato',
    'Calendar.SimSpecialistButton.Modal.Header.ThisPeriod': '(questo periodo)',
    'Calendar.SimSpecialistButton.Modal.Label.Sessions': 'Sessioni',
    'Calendar.SimSpecialistButton.Modal.Label.Hours': 'Ore',
    'Calendar.SimSpecialistButton.Modal.Label.Trainings': 'Formazioni',
    'Calendar.SimSpecialistButton.Modal.Label.TrainingsHours': 'Ore di formazione',
    'Calendar.SimSpecialistButton.Modal.Label.Available': 'Disponibile',
    'Calendar.SimSpecialistButton.Modal.Header.Certification': 'Attestato',
    'Calendar.SimSpecialistButton.Modal.Text.NoCertificates': 'Nessun attestato',
    'Calendar.SimSpecialistButton.Modal.Header.SimSpecialistOverviewPanel': 'Pannello Panoramica Specialista Sim',
    'Calendar.SimSpecialistButton.Modal.HoverTitle.CloseSimSpecialistOverview': 'chiudi panoramica specialista sim',
    'MursionPortal.DeliveryMode.OneToOne': '1:1',
    'MursionPortal.DeliveryMode.OneToManyRemote': 'Remoto',
    'MursionPortal.DeliveryMode.OneToManyLocal': 'Locale (acquario)',
    'MursionPortal.DeliveryMode.Group': 'Consegna di gruppo',
    'MursionPortal.Projects.Header.Trainees': 'Tirocinanti',
    'Projects.Placeholder.SimContentName': 'Inserisci il nome del contenuto della sim',
    'Projects.Message.NoSimulationContentFound': 'Nessun contenuto di simulazione trovato',
    'MursionPortal.Button.UncheckAll': 'Deseleziona tutto',
    'MursionPortal.Button.CheckAll': 'Seleziona tutto',
    'Projects.Header.NewScenario': 'Progetto {projectName}',
    'MursionPortal.Button.SaveAsDraft': 'Salva come bozza',
    'MursionPortal.Button.Save': 'Salva',
    'Projects.Message.SelectEnvironmentFirst': 'Seleziona prima un ambiente',
    'Projects.Message.LoadingAvatarsList': 'Caricamento elenco avatar... ',
    'Projects.Message.NoAssignedAvatars': 'L\'ambiente selezionato non ha avatar assegnati',
    'Projetcs.EmptyMessage.NoSelectedAvatars': 'Nessun avatar selezionato',
    'Projects.Avatars.NoAvatarsSpecified': 'Nessun avatar specificato',
    'Projects.Message.SelectIndustryFirst': 'Seleziona prima un settore',
    'Projects.Message.LoadingEnvironmentsList': 'Caricamento elenco ambienti... ',
    'Projects.Message.NoAssignedEnvironments': 'Il settore selezionato non ha ambienti assegnati',
    'Projects.Message.ScenarioHasNoAssignedEnvironment': 'Lo scenario non ha un ambiente assegnato',
    'Projects.AltText.EnvironmentImage': 'immagine-ambiente',
    'Projects.Placeholder.SelectAnEnviroment': 'Seleziona un ambiente',
    'Projects.Message.UnnamedIndustry': 'Settore senza nome',
    'Projects.Placeholder.SelectAnIndustry': 'Seleziona un settore',
    'Projects.Header.NewProject': 'Nuovo progetto',
    'Projects.AltText.ClientLogo': 'Logo cliente',
    'Projects.Message.SelectAnyTimeBlock': 'Seleziona qualsiasi periodo di tempo',
    'Projects.Message.SelectScenarioFirst': 'Seleziona prima lo scenario',
    'MursionPortal.Text.Asset': 'Risorsa V2',
    'MursionPortal.Button.Accept': 'Accetta',
    'Projects.Modal.EmptyMessage.NoLearner': 'Nessun allievo',
    'MursionPortal.Label.SelectAll': 'Seleziona tutto',
    'Projects.Modal.Placeholder.ChooseATeam': 'Scegli un team',
    'MursionPortal.Placeholder.SearchTeams': 'Cerca team...',
    'Projects.Modal.Text.SessionSize': 'La dimensione della sessione consentita è pari a {maxLearners}. Seleziona allievi specifici',
    'Projects.Modal.Placeholder.SearchLearners': 'Cerca allievi',
    'MursionPortal.ErrorMessage.MaxLearners': 'Puoi aggiungere solo fino a {maxLearners} allievi per questo tipo di sessione.',
    'MursionPortal.ErrorMessage.MaxLearner': 'Puoi aggiungere solo fino a {maxLearners} allievo per questo tipo di sessione.',
    'MursionPortal.Label.ChooseMembers': 'Seleziona membri',
    'MursionPortal.Message.NoMembersToSelect': 'Nessun membro da selezionare',
    'MursionPortal.Message.PleaseSelectATeamFirst': 'Seleziona prima un team.',
    'AssetSync.Header.AssetSync': 'Sincronizzazione risorse',
    'AssetSync.Text.AssetsSyncedSuccessfully': 'Le risorse sono state sincronizzate correttamente',
    'AssetSync.Button.Sync': 'Sincronizza',
    'AssetSync.Button.TryAgain': 'Riprova',
    'AssetSync.SyncButton.Text.Syncing': 'Sincronizzazione in corso',
    'SessionFlow.Label.SessionExport': 'Esporta sessione',
    'MursionPortal.Button.Export': 'Esporta',
    'SessionFlow.Label.SessionFlowData': 'Dati flusso di sessione',
    'SessionFlow.Button.LoadSessionData': 'Carica i dati della sessione',
    'SessionFlow.Button.StartSessionAnalytics': 'Avvia analisi della sessione',
    'SessionFlow.Label.Checklist': 'Elenco di controllo',
    'SessionFlow.Label.RecordingSettings': 'Impostazioni di registrazione',
    'SessionFlow.Label.SessionVideoFact': 'Sessione Video fact',
    'SessionFlow.Label.UserStatus': 'Stato utente',
    'SessionFlow.SessionVideoFact.ColumnHeader.VideoAllowed': 'Video consentito',
    'MursionPortal.Table.ColumnHeader.UserName': 'Nome utente',
    'MursionPortal.Table.ColumnHeader.Email': 'E-mail',
    'MursionPortal.Table.ColumnHeader.Status': 'Stato',
    'SessionFlow.StartSessionAnalyticsButton.Text.EventStartingAnalytics': 'L\'evento per avviare il processo di analisi è stato pubblicato con successo',
    'SessionFlow.RecordingSettings.Label.VideoRecording': 'Videoregistrazione',
    'SessionFlow.RecordingSettings.Text.Allowed': 'Consentita',
    'SessionFlow.RecordingSettings.Text.Disallowed': 'Non consentita',
    'SessionFlow.RecordingSettings.Label.RecordingPermission': 'Autorizzazione alla registrazione',
    'SessionFlow.RecordingSettings.Text.LiesWithLearner': 'Spetta all\'allievo',
    'SessionFlow.RecordingSettings.Label.RecordedVideosGoBackTo': 'I video registrati tornano a',
    'SessionFlow.Error.CannotFindSession': 'Impossibile trovare una sessione con l\'ID fornito',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Type': 'Tipo',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Date': 'Data',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Error': 'Errore',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Payload': 'Carico utile',
    'SessionFlow.SessionFlowData.PlaceHolder.SessionID': 'ID sessione',
    'SessionFlow.Button.HideDetailedEventLog': 'Nascondi registro eventi dettagliato...',
    'SessionFlow.Button.ShowDetailedEventLog': 'Mostra registro eventi dettagliato...',
    'SessionFlow.Button.HideSessionHistory': 'Nascondi cronologia sessioni...',
    'SessionFlow.Button.ShowSessionHistory': 'Mostra cronologia sessioni...',
    'SessionFlow.Text.NoEvents': 'Nessun evento',
    'SessionFlow.SessionVideoFact.Label.VideoExists': 'Il video esiste',
    'SessionFlow.Text.NoLearners': 'Nessun allievo',
    'SessionFlow.Checklist.DataRecording': 'registrazione dati',
    'SessionFlow.Checklist.DataUploading': 'caricamento dei dati',
    'SessionFlow.Checklist.Analytics': 'analisi',
    'Companies.ArchiveIconButton.Modal.ConfirmationText.ArchiveCompany': 'Sei sicuro di voler archiviare questa azienda?',
    'Companies.ArchiveIconButton.Modal.Header.ArchiveCompany': 'Archivia azienda',
    'Companies.Label.CompanyInfo': 'Informazioni sull\'azienda',
    'Companies.CompanyInfo.Archived': '(Archiviato)',
    'Companies.CancelIconButton.HoverTitle.CancelEdit': 'Annulla modifica',
    'Companies.EditIconButton.HoverTitle.EditCompany': 'Modifica azienda',
    'Companies.Button.InviteProfService': 'Invita servizio prof.',
    'Companies.InviteProfServiceButton.Modal.Header.NewProfService': 'Nuovo servizio prof.',
    'Companies.Label.Config': 'CONFIG',
    'Companies.EditIconButton.HoverTitle.EditConfig': 'Modifica configurazione',
    'Companies.Config.Button.Test': 'Test',
    'Companies.Config.Button.SaveCompanyConfig': 'Salva configurazione azienda',
    'Companies.CompanyInfo.Button.SaveCompanyInfo': 'Salva informazioni sull\'azienda',
    'Companies.ValidationError': 'Sono presenti errori di convalida',
    'Companies.Config.SaveCompanyConfigButton.FailedToSaveConfig': 'Impossibile salvare una configurazione. Correggi gli errori.',
    'Companies.Config.Text.TestPassedSuccessfully': 'Test superato con successo',
    'Companies.CreateUserButton.PlaceHolder.CompanyName': 'Ragione sociale',
    'Companies.Button.CreateCompany': 'Crea azienda',
    'Companies.Error.NoCompanies': 'Nessuna azienda',
    'Companies.InviteProfServiceButton.Modal.Label.ProfServices': 'Servizi prof.',
    'Companies.InviteProfServiceButton.Modal.Button.AddUser': 'Aggiungi utente ',
    'Companies.CreateUserButton.Modal.Header.NewCompany': 'Nuova azienda',
    'Companies.Header.AllCompanies': 'tutte le aziende',
    'MursionPortal.Roles': 'Ruoli',
    'Users.ArchiveButton.Modal.Header.ArchivedGlobalUser': 'Utente globale archiviato',
    'Users.ActiveButton.Modal.Header.EditGlobalUser': 'Modifica utente globale',
    'Users.CreateUserButton.Modal.Header.NewGlobalUser': 'Nuovo utente globale',
    'Users.NoGlobalUserFoundError': 'Non sono stati trovati utenti globali.',
    'Users.Table.ColumnHeader.Department': 'Reparto',
    'MursionPortal.TechSupport.HoverTitle.ShowFeedBack': 'mostra feedback',
    'MursionPortal.TechSupport.HoverTitle.HideFeedBack': 'nascondi feedback',
    'MursionPortal.TechSupport': 'Assistenza tecnica',
    'MursionPortal.TechSupport.Header.ContactSupport': 'Contatta l\'assistenza',
    'MursionPortal.TechSupport.Label.Phone': 'Telefono',
    'MursionPortal.TechSupport.Label.Email': 'E-mail',
    'MursionPortal.TechSupport.Label.Hours': 'Ore',
    'MursionPortal.TechSupport.Link.MursionSupport': 'Assistenza Mursion',
    'Downloads.Text.ClickDownloadButton': 'Fai clic sul pulsante di download in basso per ottenere l\'ultima versione del programma di installazione. Questo eseguibile verrà salvato nella posizione di download del browser predefinito (ad es. cartella dei download).',
    'Downloads.Text.OnceDownloadIsComplete': 'Una volta completato il download, fai clic sull\'eseguibile scaricato e segui le istruzioni sullo schermo per completare l\'installazione.',
    'Downloads.Text.AppInstalled': 'Con l\'app installata, puoi fare doppio clic sull\'icona Mursion la prossima volta che devi avviarla.',
    'Downloads.Button.Download': 'Download',
    'Downloads.Text.PlatformNotCurrentlySupported': 'Siamo spiacenti, ma la tua piattaforma non è attualmente supportata.',
    'Downloads.Text.FollowOnScreenInstructions': 'Fai clic  {info} e segui le istruzioni sullo schermo per installare l\'applicazione',
    'Downloads.Text.ShortcutCreatedOnInstallationComplete': 'Una volta completata l\'installazione, verrà creato un collegamento a Mursion sul desktop.  {lineBreak} È possibile fare doppio clic  {image} sul collegamento quando è necessario avviare l\'applicazione per provare una simulazione pianificata.',
    'Filters.Date.LastWeek': 'La settimana scorsa',
    'MursionPortal.Text.Mins': 'minuti',
    'MursionPortal.Text.Min': 'min',
    'Tutorial.Header.ManageYourTeams': 'Gestisci i tuoi team',
    'MursionPortal.Button.Awesome': 'Fantastico',
    'Tutorial.Header.ReviewYourProjects': 'Rivedi i tuoi progetti',
    'MursionPortal.Button.LooksGood': 'Sembra che vada bene',
    'Tutorial.Header.LearnerScheduling': 'Pianificazione degli allievi',
    'MursionPortal.Button.AllDone': 'Tutto fatto!',
    'Tutorial.Header.InviteYourUsers': 'Invita i tuoi utenti',
    'MursionPortal.Button.GotIt': 'Tutto chiaro',
    'Tutorial.Header.CreateATeam': 'Crea un team',
    'Tutorial.Header.ManageYourProjects': 'Gestisci i tuoi progetti',
    'Tutorial.Header.YourTeams': 'I tuoi team',
    'Tutorial.Header.YourProjects': 'I tuoi progetti',
    'Tutorial.Header.SessionCalendar': 'Calendario delle sessioni',
    'Tutorial.Tooltip.TeamsOrganize': 'I team organizzano i tuoi allievi e saranno aggiunti agli scenari',
    'Tutorial.Text.ViewSessions': 'Visualizza le sessioni imminenti <code>e completate del team</code> ',
    'Tutorial.Tooltip.ContactManager': 'o contatta il tuo Project Manager {companyName}',
    'Tutorial.Tooltip.YourPointOfContact': 'Il tuo punto di contatto per tutto l\'apprendimento',
    'Tutorial.Text.TeamAdded': 'Verrà aggiunto un team <code>a uno scenario</code> per iniziare la pianificazione',
    'Tutorial.Text.ViewLearners': 'Visualizza attività di pianificazione e stato degli allievi<code></code> <code></code>',
    'Tutorial.Text.EnterEmail': 'Immetti un <code>indirizzo e-mail</code> per ogni nuovo utente del portale{companyName}',
    'Tutorial.Text.RegistrationEmail': 'Una <code>e-mail di registrazione</code> verrà inviata alla loro casella di posta',
    'Tutorial.Text.TeamsOrganize': 'I team <code>organizzano i tuoi allievi</code> e sono gestiti da un facilitatore',
    'Tutorial.Text.UniqueName': 'Dai al tuo team un <code>nome univoco</code> e inserisci una posizione',
    'Tutorial.Text.SessionDelivery': 'Successivamente, un team verrà <code>aggiunto a uno scenario</code> per la <code>consegna della sessione</code>',
    'Tutorial.Tooltip.Description': 'Descrizione, risultato e strategie',
    'Tutorial.Text.BeginScheduling': 'È necessario <code>aggiungere un team a uno scenario</code> per iniziare la pianificazione',
    'Tutorial.Text.MonitorSessions': 'Monitora <code>le prossime sessioni e la disponibilità di ogni progetto</code> <code></code>',
    'Tutorial.Text.LearnersWithoutSessions': 'Visualizza gli allievi che <code>non hanno ancora programmato</code> una sessione',
    'Tutorial.Tooltip.LearnersSchedule': 'Anche gli allievi possono programmare se stessi',
    'Tutorial.Text.AddedToLearners': 'Verrai aggiunto a <code>1 o più</code> team di allievi',
    'Tutorial.Tooltip.TeamAdmin': 'L\'amministratore del tuo team e il punto di contatto',
    'Tutorial.Text.TeamAddedBeforeProject': 'Il tuo team verrà <code>aggiunto a uno scenario</code> prima dell\'inizio del progetto',
    'Tutorial.Text.AddRemoveLearners': '<code>Aggiungi o rimuovi</code> allievi dai tuoi <tooltip>team</tooltip>',
    'Tutorial.Text.AddedByCompany': 'Verrai aggiunto a uno scenario/progetto dal <tooltip>proprietario dell\'account della tua azienda</tooltip>',
    'Tutorial.Text.ViewYourHours': 'Visualizza le tue ore, gli scenari o contatta il tuo <tooltip><code>Project Manager</code></tooltip>',
    'Tutorial.Text.ScenariosDetail': 'Gli scenari contengono i <tooltip>dettagli</tooltip> che guidano la tua <code>{companyName} esperienza</code>",',
    'Tutorial.Text.SelectTimeSlot': 'Seleziona una fascia oraria aperta per <tooltip><code>programmare una sessione</code></tooltip> di un allievo',
    'Tutorial.Text.ChooseRole': 'Scegli un ruolo (<tooltip>Titolare</tooltip> <secondTooltip>dell\'account Facilitatore</secondTooltip> o <thirdTooltip>Allievo</thirdTooltip>) per ogni nuovo utente',
    'Tutorial.Tooltip.AdminManages': 'Amministratore che gestisce utenti e progetti',
    'Tutorial.Tooltip.ManagesScheduling': 'Gestisce la pianificazione e la consegna delle sessioni',
    'Tutorial.Tooltip.SchedulesSessions': 'Pianifica e partecipa alle sessioni',
    'Tutorial.Text.ScenariosContain': 'Gli scenari contengono i <tooltip>dettagli</tooltip> che guidano la tua <code>{companyName} esperienza</code>",',
    'Tutorial.Text.SelectOpenTimeSlot': 'Seleziona una fascia oraria aperta per <tooltip><code>programmare un allievo</code></tooltip>',
    'Tutorial.Text.AdminOfTeam': 'Il <tooltip>facilitatore</tooltip> è l’amministratore e il punto di contatto per il tuo team',
    'Tutorial.Text.ProjectsContain': 'I progetti contengono <code>scenari</code>, con i <tooltip>dettagli</tooltip> che guidano la tua <code>esperienza di apprendimento</code>',
    'Tutorial.Text.ViewAvailability': 'Visualizza disponibilità e <code>programma sessioni</code>',
    'Tutorial.Text.TrackSessions': 'Tieni traccia delle <code>prossime sessioni</code> per ogni progetto. Annulla/riprogramma se necessario',
    'Tutorial.Text.ReviewPerformance': 'Rivedi le tue <code>prestazioni</code> e <code>il completamento delle strategie</code> dopo la sessione',
    'Tutorial.Header.Complete': 'Completa',
    'Tutorial.Text.RevisitThisGuide': 'Puoi rivedere questa guida e altre informazioni utili  {lineBreak} <code>in qualsiasi momento</code> dalla <code>Base delle conoscenze.</code>',
    'Tutorial.Button.TakeMeToThePortal': 'Portami al Portale',
    'Tutorial.Header.DownloadInstallMursionSimulator': 'Scarica e installa  {lineBreak} il simulatore di Mursion',
    'Tutorial.Text.PlanningToUseMursionOnComputer': 'Se prevedi di utilizzare questo computer per eseguire simulazioni di Mursion, ti consigliamo vivamente di installare il software necessario ora facendo clic sul pulsante "Installa Mursion" di seguito.',
    'Tutorial.Text.InstallSoftwareFromPortal': 'È possibile scegliere di installare il software in un secondo momento direttamente dal portale. Tieni presente che il simulatore immersivo di Mursion potrebbe richiedere diversi minuti per l\'installazione per il primo utilizzo. Pianifica di installare il software almeno 30 minuti prima che la simulazione inizi per avere tempo sufficiente per goderti appieno l\'esperienza di Mursion.',
    'Tutorial.Text.ProvideInstructionsForInstallation': 'Continueremo a fornire istruzioni per l\'installazione e altri flussi di lavoro di volta in volta tramite e-mail e tutorial all\'interno del portale e del simulatore di Mursion. Ci auguriamo che le simulazioni ti piacciano tanto quanto noi ci divertiamo a crearle!',
    'Tutorial.Text.TheMursionTeam': 'Il team di Mursion.',
    'Tutorial.Button.InstallLater': 'Installa in seguito',
    'Tutorial.Button.InstallMursion': 'Installa Mursion',
    'Tutorial.Header.LetsGetStarted': 'Iniziamo',
    'Tutorial.Text.ShortIntro': 'Innanzitutto, ecco una <code>breve introduzione</code> per mostrare alcune delle  {lineBreak} funzioni comuni del <code>tuo ruolo utente</code>',
    'Tutorial.Button.Proceed': 'Procedi',
    'Tutorial.Label.Step': 'FASE',
    'Tutorial.Label.NoSound': 'Questo video non ha audio.',
    'Tutorial.Button.SkipTutorial': 'Salta il tutorial',
    'Tutorial.Button.CloseTutorial': 'Chiudi il tutorial',
    'MursionPortal.Text.On': 'Acceso',
    'MursionPortal.Text.Off': 'Spento',
    'MursionPortal.Text.MursionIcon': 'icona-mursion',
    'MursionPortal.AriaLabel.CreateNew': 'crea nuovo',
    'MursionPortal.Legend.VisibilityHidden.Buttons': 'Crea nuovo, Ripeti cliente, Ripeti pulsanti scenario banca',
    'MursionPortal.Label.VisibilityHidden.EnterEmailId': 'Inserisci id e-mail',
    'MursionPortal.Label.VisibilityHidden.SelectRegistrationStatus': 'Seleziona lo stato di registrazione',
    'MursionPortal.Label.VisibilityHidden.ClientLogoUpload': 'caricamento del logo del cliente',
    'MursionPortal.Header.VisibilityHidden.CompanyUsers': 'Utenti dell\'azienda',
    'MursionPortal.Legend.VisibilityHidden.StationType': 'Tipo stazione',
    'MursionPortal.Legend.VisibilityHidden.Hours': 'Ore',
    'MursionPortal.Caption.VisibilityHidden.CompanyUsersList': 'elenco utenti azienda',
    'MursionPortal.AriaLabel.Search': 'cerca',
    'MursionPortal.AriaLabel.From': 'Da',
    'MursionPortal.AriaLabel.To': 'A',
    'MursionPortal.AriaLabel.RemoveAccountOwner': 'rimuovi il proprietario dell\'account',
    'MursionPortal.AriaLabel.InboxMessagePanel': 'pannello dei messaggi della posta in arrivo',
    'MursionPortal.AriaLabel.Breadcrumb': 'breadcrumb',
    'MursionPortal.Message.NoItemsHaveBeenFound': 'Nessun è stato trovato nessun elemento.',
    'MursionPortal.AriaLabel.Add': 'aggiungi',
    'MursionPortal.AriaLabel.SortByDescendingOrder': 'ordina in ordine decrescente',
    'MursionPortal.AriaLabel.SortByAscendingOrder': 'ordina in ordine crescente',
    'MursionPortal.AriaLabel.AddButton': 'aggiungi pulsante',
    'MursionPortal.AriaLabel.Timezone': 'fuso orario',
    'MursionPortal.AriaLabel.Combobox': 'casella combinata',
    'MursionPortal.AriaLabel.PreviousMonth': 'mese precedente',
    'MursionPortal.AriaLabel.NextMonth': 'mese successivo',
    'MursionPortal.AriaLabel.SearchInput': 'input di ricerca',
    'MursionPortal.AriaLabel.Informational': 'Informativo',
    'MursionPortal.Text.RedirectToCalendarPage': 'reindirizza alla pagina del calendario',
    'MursionPortal.AriaLabel.StartDate': 'data d\'inizio {startFormattedDate}',
    'MursionPortal.AriaLabel.EndDate': 'data di fine {endFormattedDate}',
    'MursionPortal.AriaLabel.CreateProject': 'crea progetto',
    'MursionPortal.AriaLabel.ContentInfo': 'informazioni sui contenuti',
    'MursionPortal.AriaLabel.DraftSuperScript': 'Bozza apice{statusConfiguration}',
    'MursionPortal.AriaLabel.FinalizedSuperScript': 'Apice finalizzato {statusConfiguration}',
    'MursionPortal.AriaLabel.StartedSuperScript': 'Apice iniziale {statusConfiguration}',
    'MursionPortal.AriaLabel.FinishedSuperScript': 'Apice finito {statusConfiguration}',
    'MursionPortal.AriaLabel.ArchivedSuperScript': 'Apice archiviato {statusConfiguration}',
    'MursionPortal.AriaLabel.EnterScenarioName': 'inserisci il nome dello scenario',
    'MursionPortal.AriaLabel.Capitalized.Search': 'Ricerca',
    'MursionPortal.AriaLabel.Close': 'Chiudi',
    'MursionPortal.AriaLabel.CreateTeam': 'crea team',
    'MursionPortal.AriaLabel.CreateClient': 'crea cliente',
    'MursionPortal.AriaLabel.CreateUser': 'crea utente',
    'MursionPortal.AriaLabel.ZoomId': 'ID Zoom',
    'MursionPortal.AriaLabel.PrimaryContent': 'Contenuto principale',
    'MursionPortal.AriaLabel.RegistrationStatus': 'stato della registrazione',
    'MursionPortal.AriaLabel.EnterTagName': 'inserisci il nome del tag',
    'MursionPortal.AriaLabel.EnterUserName': 'inserisci nome utente',
    'MursionPortal.AriaLabel.SearchProjectManagers': 'ricerca dei project manager',
    'MursionPortal.AriaLabel.SearchSSO': 'cerca SSO',
    'MursionPortal.AriaLabel.SearchAccountOwner': 'cerca proprietario account',
    'MursionPortal.WarningMessage.AllowedCharacterLimit': 'È stato superato il limite di caratteri ({maximumLength} caratteri) consentito',
    'MursionPortal.WarningMessage.InvalidCharactersDetected': 'Caratteri non validi rilevati',
    'MursionPortal.Days.Sunday': 'Domenica',
    'MursionPortal.Days.Monday': 'Lunedì',
    'MursionPortal.Days.Tuesday': 'Martedì',
    'MursionPortal.Days.Wednesday': 'Mercoledì',
    'MursionPortal.Days.Thursday': 'Giovedì',
    'MursionPortal.Days.Friday': 'Venerdì',
    'MursionPortal.Days.Saturday': 'Sabato',
    'MursionPortal.Button.AddNew': 'Aggiungi nuovo',
    'MursionPortal.Title.EditNotes': 'Modifica note',
    'MursionPortal.IconButton.Remove': 'rimuovi',
    'MursionPortal.Filter.ProviderAll': 'Provider',
    'MursionPortal.Placeholder.SearchProviders': 'Cerca provider...',
    'MursionPortal.AriaLabel.InboxButton': 'pulsante Posta in arrivo Hai  {messageCount} nuovi messaggi',
    'MursionPortal.AriaLabel.Event': 'evento',
    'MursionPortal.Title.SelectedDate': 'Data selezionata',
    'MursionPortal.Title.CurrentDate': 'Data attuale',
    'MursionPortal.Title.OffRangeDate': 'data fuori intervallo',
    'MursionPortal.Label.ClientLevel': 'Livello cliente',
    'MursionPortal.Label.TeamLevel': 'Livello team',
    'MursionPortal.AltText.UserImage': 'immagine-utente',
    'MursionPortal.AltText.KbMenu': 'menu kb',
    'MursionPortal.AltText.AvatarImage': 'immagine-avatar',
    'MursionPortal.AltText.EnvironmentImage': 'immagine-ambiente',
    'MursionPortal.AriaLabel.Cancel': 'annulla',
    'MursionPortal.AriaLabel.Edit': 'modifica',
    'MursionPortal.AriaLabel.EditAccountOwner': 'modifica proprietario dell\'account',
    'MursionPortal.AriaLabel.Remove': 'rimuovi',
    'MursionPortal.AriaLabel.SearchFilter': 'filtro di ricerca',
    'MursionPortal.AriaLabel.TierCombobox': 'livello casella combinata',
    'MursionPortal.AriaLabel.SortByAscending': 'ordina per crescente',
    'MursionPortal.AriaLabel.SortByDescending': 'ordina per decrescente',
    'MursionPortal.Message.VisibilityHidden.SelectingDeliveryMode': 'la selezione di una modalità di consegna consentirà di impostare la durata della sessione e la dimensione della sessione\'',
    'MursionPortal.AltText.DownloadComputerIcon': 'Icona computer di download di Mursion',
    'MursionPortal.AltText.DownloadIcon': 'Icona di download di Mursion',
    'MursionPortal.AltText.MursionLogo': 'Logo di Mursion',
    'MursionPortal.AriaLabel.MursionSupport': 'assistenza Mursion',
    'MursionPortal.AltText.MursionDashboard': 'Pannello di controllo di Mursion',
    'MursionPortal.AltText.BackgroundMaleUser': 'Utente maschio in background Mursion',
    'MursionPortal.AltText.BackgroundGroupUser': 'Utente gruppo in background Mursion',
    'MursionPortal.AltText.BackgroundFemaleUser': 'Utente femmina in background Mursion',
    'MursionPortal.ErrorMessage.CharacterLimitExceeded': 'È stato superato il limite di caratteri ({maximumLength} caratteri) consentito',
    'MursionPortal.ErrorMessage.InvalidCharactersDetected': 'Caratteri non validi rilevati',
    'MursionPortal.ErrorMessage.EnterValidPhoneNumber': 'Devi inserire un numero di telefono valido.',
    'MursionPortal.Message.InvitationLinkInvalid': 'Il tuo link di invito non è valido o potrebbe essere scaduto.  {lineBreak} Se non hai ancora completato la registrazione  {lineBreak}, chiedi al tuo manager un nuovo invito.',
    'MursionPortal.Header.SetYourPassword': 'Imposta la tua password',
    'MursionPortal.Message.PasswordMessage': 'La tua password deve contenere quanto segue:',
    'MursionPortal.Label.NewPassword': 'Nuova password',
    'MursionPortal.Label.RepeatPassword': 'Ripeti password',
    'MursionPortal.Button.SetPassword': 'Imposta la password',
    'MursionPortal.Header.ErrorMessage.SomethingWentWrong': 'Qualcosa è andato  {lineBreak} storto, contatta  {lineBreak} l\'assistenza',
    'MursionPortal.Header.ContactSupport': 'CONTATTA L\'ASSISTENZA',
    'MursionPortal.Label.Phone': 'Telefono',
    'MursionPortal.Label.Email': 'E-mail',
    'MursionPortal.Label.Hours': 'Ore',
    'MursionPortal.Message.EnterPasswordToConfirm': 'Inserisci la tua password per confermare questa modifica',
    'MursionPortal.Label.Password': 'Password',
    'MursionPortal.Message.BrowserDialog': 'Se non vedi una finestra di dialogo del browser, fai clic su <code>Apri Mursion</code> di seguito  {lineBreak} o usa il collegamento sul desktop per aprire l\'applicazione Mursion.',
    'MursionPortal.Button.OpenMursion': 'Apri ML3 Desktop App',
    'MursionPortal.Message.FollowInstructions': 'Fai clic {appName} e segui le istruzioni sullo schermo per installare l\'applicazione',
    'MursionPortal.Text.PositiveImpact': 'Quando ritieni di avere un impatto positivo, fai clic sul  <code>{icon}</code> pulsante.',
    'MursionPortal.Text.NegativeImpact': 'Quando ritieni di avere un impatto negativo, fai clic sul  <code>{icon}</code> pulsante.',
    'MursionPortal.Text.NeutralImpact': 'Quando ritieni che il tuo impatto non sia stato né positivo né negativo, fai clic sul  <code>{icon}</code> pulsante.',
    'MursionPortal.Title.Next': 'Avanti',
    'MursionPortal.Title.Start': 'Inizia',
    'MursionPortal.Text.UpdateYourRating': 'Mentre guardi il video, puoi aggiornare la tua valutazione in qualsiasi momento in cui pensi di avere un impatto diverso sull\'avatar.',
    'MursionPortal.Text.SeeCurrentRating': 'Il grafico sotto il video si aggiornerà in modo che tu possa vedere la tua valutazione attuale e cambiarla se pensi che il tuo impatto sia cambiato.',
    'MursionPortal.Text.ClickStartToBegin': 'Fai clic su "Avvia" per iniziare. Puoi fare clic su "Completa" dopo aver valutato l\'intera simulazione. Tieni presente che la funzionalità di ricerca video potrebbe essere disattivata durante alcune parti di questo video per preservare l\'integrità dei dati.',
    'MursionPortal.Header.HowWouldYouProceed': 'Come desideri procedere?',
    'MursionPoral.Button.SwitchUser': 'Cambia utente',
    'MursionPortal.Button.ContinueAs': 'Continua come <code>{userName}</code>',
    'MursionPortal.Text.ToContinue': 'Per continuare come<code>{loginData}</code> , fai clic su <code>Cambia utente</code>.',
    'MursionPortal.Message.YouAreSignedIn': 'Hai eseguito l\'accesso come  <code>{userName}</code> ({userProfile}).',
    'MursionPortal.Title.ContinueAs': 'Continua come {userName}',
    'MursionPortal.Message.OpenLink': 'Apri il link',
    'MursionPortal.Message.Allow': 'Consenti',
    'MursionPortal.Message.Open': 'Apri',
    'MursionPortal.Message.Yes': 'Sì',
    'MursionPortal.Message.OpenMursionApp': 'Apri MursionApp',
    'MursionPortal.PasswordRequirementMessage.MinimumCharacters': 'Almeno  {PASSWORD_MIN_LENGTH} caratteri',
    'MursionPortal.Message.PortalHeading': 'Progetta, pianifica e gestisci il tuo',
    'MursionPortal.PasswordRequirementMessage.UppercaseLetters': 'Lettere maiuscole + Lettere minuscole',
    'MursionPortal.PasswordRequirementMessage.Numbers': 'qualsiasi combinazione di numeri',
    'MursionPortal.ErrorMessage.InvalidEmailFormat': 'Formato e-mail non valido.',
    'MursionPortal.ErrorMessage.EmailCanNotBeEmpty': 'L\'indirizzo di posta elettronica non può essere vuoto.',
    'MursionPortal.ErrorMessage.EnterValidEmailAddress': 'Inserisci un indirizzo e-mail valido.',
    'MursionPortal.ErrorMessage.PasswordCannotBeEmpty': 'La password non può essere vuota.',
    'MursionPortal.ErrorMessage.UrlCannotBeEmpty': 'L\'URL non può essere vuoto',
    'MursionPortal.ErrorMessage.UrlIsNotValid': 'L\'URL non è valido',
    'MursionPortal.ErrorMessage.NoMeetingId': 'Non contiene ID riunione',
    'MursionPortal.ValidationErrorMessage.FileRequestFailed': 'Richiesta di file non riuscita',
    'MursionPortal.ValidationErrorMessage.FileExceedsLimit': 'Il file supera il limite di 5 MB',
    'MursionPortal.ValidationErrorMessage.EnterMessage': 'Inserisci il tuo messaggio prima di inviare',
    'MursionPortal.ValidationErrorMessage.MessageTooLong': 'Messaggio troppo lungo',
    'MursionPortal.ValidationErrorMessage.InvalidUrl': 'L\'URL del file non è valido',
    'MursionPortal.Button.SubmitFeedback': 'Invia feedback',
    'MursionPortal.Header.Feedback': 'Feedback',
    'MursionPortal.Text.AdditionalComments': 'Commenti o suggerimenti aggiuntivi',
    'MursionPortal.Header.ThanksForFeedback': 'Grazie per il tuo feedback!',
    'MursionPortal.Button.Close': 'Chiudi',
    'MursionPortal.Title.ShowFeedback': 'mostra feedback',
    'MursionPortal.Title.HideFeedback': 'nascondi feedback',
    'MursionPortal.ErrorMessage.FileTypeNotSupported': 'Il tipo di file  {type} non è supportato',
    'MursionPortal.ErrorMessage.ScenarioDuration': 'La durata dello scenario deve essere compresa tra  {durationMinimum} e {durationMaximum}.',
    'MursionPortal.ErrorMessage.ScenarioScheduledSessions': 'Ci sono  {sessionsCount} sessioni pianificate per questo scenario. Annulla queste sessioni prima di archiviare questo scenario.',
    'MursionPortal.ErrorMessage.ScenarioScheduledSession': 'C\'è  {sessionsCount} sessione pianificata per questo scenario. Annulla questa sessione prima di archiviare questo scenario.',
    'MursionPortal.ErrorMessage.ClientScheduledSessions': 'Ci sono {sessionsCount} sessioni pianificate per questo cliente. Annulla queste sessioni prima di archiviare il cliente.',
    'MursionPortal.ErrorMessage.ClientScheduledSession': 'C\'è {sessionsCount} sessione pianificata per questo cliente. Annulla questa sessione prima di archiviare il cliente.',
    'MursionPortal.EulaAgreement.EndUserAgreement': 'Il presente Contratto con l\'utente finale ("Contratto") è un contratto legale tra te, l\'utente e Mursion, Inc. ("Mursion") ed è soggetto ai termini e alle condizioni del Software Mursion come Contratto di servizio tra Mursion e l\'entità cliente Mursion attraverso la quale si utilizza il Software Mursion. A scopo di chiarezza, confermi e accetti che come condizione per l\'utilizzo e l\'accesso al Software Mursion ("Software Mursion"), tu, così come l\'entità che ti fornisce l\'accesso al Software Mursion, sei soggetto a e vincolato dai termini e dalle condizioni del presente Contratto.',
    'MursionPortal.EulaAgreement.TermsAndConditions': 'Fatti salvi i termini e le condizioni del presente Contratto e del Software Mursion come Contratto di servizio, Mursion concede all\'utente diritti limitati, non esclusivi, non trasferibili e non cedibili in sublicenza per accedere e utilizzare il Software Mursion.',
    'MursionPortal.EulaAgreement.AgreeAndAcknowledge': 'Accetti e confermi di non avere il diritto e di non copiare, modificare, decodificare, decompilare, disassemblare o creare lavori derivati basati, in tutto o in parte, sul Software Mursion o sui materiali correlati. Accetti di non eliminare, disabilitare o eludere alcun meccanismo di protezione relativo al Software Mursion. Dopo aver appreso di qualsiasi possesso o utilizzo o accesso non autorizzato al Software Mursion, informerai immediatamente Mursion e accetti inoltre di fornire prontamente i dettagli di ciascuno di questi eventi, per contribuire ragionevolmente a prevenirne il ripetersi (per quanto possibile) e di cooperare pienamente a qualsiasi procedimento intrapreso per proteggere i diritti di Mursion.',
    'MursionPortal.EulaAgreement.ConstruedAsGranting': 'Oltre a quanto esplicitamente dichiarato nel presente documento, nulla di quanto contenuto nel presente Contratto deve essere interpretato come concessione, implicitamente o in altro modo, di diritti aggiuntivi sulla proprietà intellettuale di Mursion senza previa approvazione scritta di Mursion.',
    'MursionPortal.EulaAgreement.NonDisclosure': 'Accetti di non mostrare o divulgare il Software Mursion o qualsiasi parte di esso a nessuno per scopi diversi da quanto espressamente consentito nel presente documento.',
    'MursionPortal.EulaAgreement.AgreeAndRelease': 'Con il presente documento accetti di rilasciare, esonerare e altrimenti tenere indenne Mursion, i suoi dipendenti, funzionari, agenti, affiliati, funzionari e direttori da qualsiasi pretesa, nota e sconosciuta, ogniqualvolta sorga, che derivi da, o in altro modo correlato all\'uso del software Mursion.',
    'MursionPortal.EulaAgreement.UnderstandAndAcknowledge': 'Comprendi e confermi che Mursion può raccogliere, mantenere, utilizzare, archiviare e analizzare le informazioni e i dati raccolti dal tuo utilizzo del Software Mursion, soggetti ai termini della politica sulla privacy di Mursion, disponibile qui',
    'MursionPortal.EulaAgreement.AccessRights': 'L\'utilizzo e i diritti di accesso a te concessi da Mursion cesseranno immediatamente quando il Contratto Mursion Software as a Service, con il quale ti viene fornito l\'accesso al Software Mursion, scade o cessa, o immediatamente in caso di violazione da parte tua di qualsiasi disposizione di questo contratto.',
    'MursionPortal.EulaAgreement.AgreementGoverned': 'Il presente Contratto sarà disciplinato e interpretato in conformità alle leggi dello Stato del Delaware, indipendentemente dalle sue disposizioni sui conflitti di leggi. Tutte le controversie derivanti o correlate al tuo utilizzo del Software Mursion o del presente contratto saranno portate in un tribunale statale o federale della giurisdizione competente nello Stato del Delaware e tu acconsenti alla giurisdizione personale in tali tribunali.',
    'MursionPortal.EulaAgreement.ProvisionsDeclaredInvalid': 'Se una qualsiasi disposizione del presente Contratto viene dichiarata non valida, tali disposizioni saranno separate dal presente Contratto e le altre disposizioni in esso contenute rimarranno in vigore a tutti gli effetti.',
    'MursionPortal.EulaAgreement.AgreementConflict': 'Nel caso in cui i termini del presente Contratto siano in conflitto con i termini del Contratto Mursion Software as a Service, prevarranno i termini qui contenuti.',
    'MursionPortal.EulaAgreement.AccessToMursionSofware': 'L\'utilizzo o l\'accesso al Software Mursion costituisce l\'accettazione del presente Contratto e dei termini e delle condizioni qui indicati.',
    'MursionPortal.Header.MursionUserAgreement': 'Contratto Mursion e utente',
    'MursionPortal.Button.Back': 'Indietro',
    'MursionPortal.Button.Skip': 'Salta',
    'MursionPortal.Title.BackToSessions': 'Torna a Sessioni',
    'MursionPortal.Title.Proceed': 'Procedi',
    'MursionPortal.Message.SkipActivity': 'Se scegli di saltare, perderai le informazioni sulle prestazioni che potrebbero essere fornite dal nostro motore di analisi comportamentale. Ti consigliamo di non saltare questa attività.',
    'MursionPortal.HeaderTitle.ConfirmCancellation': 'Conferma annullamento',
    'MursionPortal.Label.Delete': 'elimina',
    'MursionPortal.Label.Restore': 'ripristina',
    'MursionPortal.HeaderTitle.ConfirmRestore': 'Conferma ripristino',
    'MursionPortal.Label.Archive': 'archivia',
    'MursionPortal.Button.CancelTraining': 'Annulla formazione',
    'MursionPortal.Button.No': 'No',
    'MursionPortal.Button.CancelEvent': 'Annulla evento',
    'MursionPortal.Label.Update': 'aggiorna',
    'MursionPortal.HeaderTitle.ConfirmUpdate': 'Conferma aggiornamento',
    'MursionPortal.Button.UpdateSession': 'Aggiorna sessione',
    'MursionPortal.Label.Swap': 'scambia',
    'MursionPortal.HeaderTitle.ConfirmAcceptedSwap': 'Conferma scambio accettato',
    'MursionPortal.Button.AcceptSwap': 'Accetta scambio',
    'MursionPortal.Label.Reason': 'Motivo',
    'MursionPortal.Placeholder.ReasonForSwapping': 'Aggiungi un motivo per lo scambio.',
    'MursionPortal.Label.Request': 'richiesta',
    'MursionPortal.HeaderTitle.ConfirmAcceptedRequest': 'Conferma richiesta accettata',
    'MursionPortal.Button.AcceptRequest': 'Accetta richiesta',
    'MursionPortal.Placeholder.ReasonForAccepting': 'Aggiungi un motivo per l\'accettazione.',
    'MursionPortal.Button.ConfirmProjectChange': 'Conferma modifica progetto',
    'MursionPortal.HeaderTitle.Confirm': 'Conferma',
    'MursionPortal.Button.ConfirmCreateSession': 'Conferma creazione sessione',
    'MursionPortal.Placeholder.ReasonForCreation': 'Aggiungi un motivo per la creazione.',
    'MursionPortal.Button.ConfirmAssigningSimSpecialist': 'Conferma assegnazione specialista Sim',
    'MursionPortal.Placeholder.ReasonForAssigning': 'Aggiungi un motivo per l\'assegnazione.',
    'MursionPortal.Button.ConfirmChangingSimSpecialist': 'Conferma modifica specialista Sim',
    'MursionPortal.Placeholder.ReasonForChanging': 'Aggiungi un motivo per la modifica.',
    'MursionPortal.HeaderTitle.ConfirmTimeZone': 'Conferma fuso orario',
    'MursionPortal.Message.DontHaveMursionInstalled': 'Non hai installato ML3 Desktop App? Fai clic su <code>Scarica</code> per installare.',
    'MursionPortal.ErrorMessage.CannotContainCharacter': 'Il nome dello scenario non deve contenere il carattere "_" ",',
    'MursionPortal.ErrorMessage.EnterDifferentName': '{capitalized} {fieldName} attualmente esiste. Inserisci un altro  {objectName} {fieldName}.',
    'MursionPortal.ErrorMessage.EnterDifferentEmail': 'L\'e-mail dell\'utente esiste attualmente. Inserisci un indirizzo e-mail diverso.',
    'MursionPortal.ErrorMessage.NameExists': 'Esiste già un utente con lo stesso  {fieldName} . Inserisci un altro {fieldName}.',
    'MursionPortal.ErrorMessage.UnableToRemove': 'Impossibile rimuovere {entity}. Questo  {entity} è attualmente utilizzato in un attivo {depEntity}. ',
    'MursionPortal.ErrorMessage.OverlapsExistingTimeBlock': 'Il periodo di tempo selezionato si sovrappone al periodo di tempo esistente.',
    'MursionPortal.ErrorMessage.AccessIsDenied': 'Accesso negato.',
    'MursionPortal.ErrorMessage.SomethingMustBeBroken': 'Qualcosa deve essere rotto. Riprova più tardi.',
    'MursionPortal.ErrorMessage.Unauthorized': 'Non autorizzato',
    'MursionPortal.ErrorMessage.RefreshTokenNotFound': 'Aggiorna token non trovato',
    'MursionPortal.ErrorMessage.CannotRefreshToken': 'Impossibile aggiornare il token',
    'MursionPortal.QADialog.NoParticipants': 'Nessun partecipante',
    'MursionPortal.ErrorMessage.UsersUnavailable': 'Alcuni dei tuoi utenti non sono disponibili all\'ora selezionata.',
    'Filters.SsoProtocolType': 'Protocollo: Tutti',
    'Filters.SsoProtocolTypePlaceHolderHint': 'Cerca protocollo SSO',
    'Filters.SSO.PrivacyAll': 'Digita tutto',
    'Filters.SSO.PrivacyPublic': 'Digita Pubblico',
    'Filters.SSO.PrivacyPrivate': 'Digita Privato',
    'Filters.SSO.PrivacyAriaLabel': 'filtro tipo privacy sso',
    'Cleints.Modal.Label.DomainsTitle': 'L\'elenco dei domini che potrebbero essere utilizzati per l\'accesso',
    'MursionPortal.NoDomains': 'Nessun dominio',
    'MursionPortal.Header.Domains': 'Domini',
    'MursionPortal.AddDomain': 'Aggiungi dominio',
    'Clients.Modal.Projects.ButtonTitle.EditDomains': 'Modifica domini',
    'Clients.Modal.Projects.DomainField.EnterDomain': 'Immettere un nome di dominio (ad es. esempio.com)',
    'Clients.Modal.Projects.DomainField.InvalidDomain': 'Formato di dominio non valido',
    'MursionPortal.ErrorMessage.SomethingWrongWithResponse': 'Qualcosa di sbagliato nella risposta.',
    'MursionPortal.ErrorMessage.AuthorizationHeaderIsEmpty': 'L\'intestazione dell\'autorizzazione è vuota',
    'MursionPortal.ErrorMessage.CannotRefreshAccessToken': 'Impossibile aggiornare il token di accesso',
    'MursionPortal.ErrorMessage.AuthorizationTokenIsEmpty': 'Il token di autorizzazione è vuoto',
    'MursionPortal.ErrorMessage.AuthenticationFailed': 'Autenticazione non riuscita.',
    'MursionPortal.ErrorMessage.UnsupportedResponseFormat': 'Formato di risposta non supportato.',
    'MursionPortal.ErrorMessage.AccountBlocked': 'Il tuo account è stato bloccato fino a {blockedDate}. Prova ad accedere dopo.',
    'MursionPortal.ErrorMessage.AttemptsRemaining': '{attempts} tentativi rimanenti prima di un blocco di 24 ore. Se hai bisogno di aiuto, reimposta la password.',
    'MursionPortal.ErrorMessage.ClientIdMustBeSpecified': 'L\'ID cliente deve essere specificato',
    'MursionPortal.ErrorMessage.ProjectIdMustBeSpecified': 'L\'ID del progetto deve essere specificato',
    'MursionPortal.ErrorMessage.SessionIdMustBeSpecified': 'L\'ID sessione deve essere specificato',
    'MursionPortal.ErrorMessage.SSOIdMustBeSpecified': 'L\'ID SSO deve essere specificato',
    'MursionPortal.ErrorMessage.LearnerIdMustBeSpecified': 'L\'ID allievo deve essere specificato',
    'MursionPortal.ErrorMessage.UserIdMustBeSpecified': 'L\'ID utente deve essere specificato',
    'MursionPortal.ErrorMessage.TeamIdMustBeSpecified': 'L\'ID del team deve essere specificato',
    'MursionPortal.ErrorMessage.AssetProjectIdMustBeSpecified': 'L\'ID del progetto della risorsa deve essere specificato',
    'MursionPortal.ErrorMessage.AssetSettingsIdMustBeSpecified': 'L\'ID delle impostazioni della risorsa deve essere specificato',
    'MursionPortal.ErrorMessage.ScenarioIdMustBeSpecified': 'L\'ID scenario deve essere specificato',
    'MursionPortal.ErrorMessage.ScenarioTemplateIdMustBeSpecified': 'L\'ID del modello di scenario deve essere specificato',
    'MursionPortal.ErrorMessage.LicenseIdMustBeSpecified': 'fetchUsersList: l\'ID del licenziatario deve essere specificato',
    'MursionPortal.ErrorMessage.LicenseCertificateIdMustBeSpecified': 'fetchLicenseeCertificates: l\'ID del licenziatario deve essere specificato',
    'MursionPortal.ErrorMessage.LicenseConfigIdMustBeSpecified': 'fetchConfig: l\'ID del licenziatario deve essere specificato',
    'MursionPortal.ErrorMessage.LicenseUpdateConfigIdMustBeSpecified': 'updateConfig: l\'ID del licenziatario deve essere specificato',
    'MursionPortal.ErrorMessage.LicenseTestConfigIdMustBeSpecified': 'testConfig: l\'ID del licenziatario deve essere specificato',
    'MursionPortal.ErrorMessage.LicenseFetchTiersIdMustBeSpecified': 'fetchTiers: l\'ID del licenziatario deve essere specificato',
    'MursionPortal.ErrorMessage.LicenseUpdateTiersIdMustBeSpecified': 'updateTiers: l\'ID del licenziatario deve essere specificato',
    'MursionPortal.ErrorMessage.GlobalUserIdMustBeSpecified': 'L\'ID utente globale deve essere specificato',
    'MursionPortal.ErrorMessage.ScenarioTemplateListLicenseIdMustBeSpecified': 'fetchScenarioTemplatesList: l\'ID del licenziatario deve essere specificato',
    'MursionPortal.ErrorMessage.ScenarioAttachmentListIdMustBeSpecified': 'fetchScenarioAttachmentLink: è necessario specificare l\'ID dello scenario e l\'ID dell\'allegato',
    'MursionPortal.ErrorMessage.FetchScenarioIdMustBeSpecified': 'fetchScenario: l\'ID dello scenario deve essere specificato',
    'Projects.ProjectInfo.Checkbox.AllowRecordingInfo': 'Questa operazione non eliminerà le registrazioni attualmente salvate.',
    'MursionPortal.AriaLabel.ToggleMenu': 'attiva e disattiva menu',
    'MursionPortal.ClientListItem.RedirectToUserDetails': 'Reindirizza a dettagli {name}',
    'MursionPortal.AriaLabel.PreviousWeek': 'settimana precedente',
    'MursionPortal.AriaLabel.PreviousDay': 'giorno precedente',
    'MursionPortal.AriaLabel.NextWeek': 'prossima settimana',
    'MursionPortal.AriaLabel.NextDay': 'giorno precedente',
    'Dashboard.LeftPane.CategoryHeader.SessionsDashboard': 'Dashboard delle sessioni',
    'MursionPortal.Title.SessionsPage': 'Pagina delle sessioni',
    'Clients.heading.Clients': 'Clienti',
    'MursionPortal.AriaLabel.Client': 'cliente',
    'MursionPortal.AriaLabel.SimSpecialist': 'Specialista Sim',
    'MursionPortal.Project.TimeZoneStartDate': 'Data di inizio del fuso orario',
    'MursionPortal.Project.TimeZoneEndDate': 'Data di fine del fuso orario',
    'MursionPortal.AriaLabel.SelectTimezoneCombobox': 'seleziona la casella combinata del fuso orario',
    'MursionPortal.Support.PhoneNumber': '1-855-999-5818',
    'MursionPortal.Message.LearnerEmpty': 'Seleziona gli studenti che hanno partecipato alla sessione',
    'Dashboard.ProjectsTable.StatusValue.Active': 'ATTIVO',
    'Dashboard.ProjectsTable.StatusValue.Completed': 'COMPLETATO',
    'Dashboard.ProjectsTable.StatusValue.Archived': 'ARCHIVIATO',
    'MursionPortal.Button.Refresh': 'Ricarica',
    'MursionPortal.Prompt.CalendarUpdated': 'Il calendario è stato aggiornato.',
    'MursionPortal.Prompt.ClickRefresh': 'Fai clic su "Aggiorna" per visualizzare le modifiche.',
    'MursionPortal.Label.SessionAttendance': 'Presenza alle sessioni',
    'MursionPortal.Label.SessionVideoUrl': 'URL del video della sessione',
    'MursionPortal.Label.LearnerAttendance': 'Presenza studente',
    'MursionPortal.Text.UserProfileUpdated': 'Un profilo utente è stato aggiornato!',
    'MursionPortal.Text.ClientUpdated': 'Un cliente è stato aggiornato!',
    'MursionPortal.Text.ProjectUpdated': 'Un progetto è stato aggiornato!',
    'MursionPortal.Text.ScenarioUpdated': 'Uno scenario è stato aggiornato!',
    'MursionPortal.Text.LicenseeConfigUpdated': 'La configurazione del licenziatario è stata aggiornata!',
    'MursionPortal.Prompt.TrainingScheduled': '<code>La formazione</code> è stata programmata!',
    'Settings.SSO.Table.ColumnHeader.ClientName': 'Nome cliente',
    'MursionPortal.Text.UserCreatedSuccessfully': 'L\'utente è stato creato correttamente',
    'MursionPortal.Text.UserNotCreated': 'L\'utente non è stato creato',
    'MursionPortal.Text.AssignedTeams': 'Team assegnati',
    'MursionPortal.Client.EmailSettings.Invitation': 'Invito',
    'MursionPortal.Client.EmailSettings.InvitationReminder': 'Promemoria dell\'invito',
    'MursionPortal.Client.EmailSettings.Confirmation': 'Conferma',
    'MursionPortal.Client.EmailSettings.Cancellation': 'Cancellazione',
    'MursionPortal.Client.EmailSettings.FirstReminder': 'Primo promemoria',
    'MursionPortal.Client.EmailSettings.SecondReminder': 'Secondo promemoria',
    'MursionPortal.Client.EmailSettings.SessionRecording': 'La registrazione della sessione è pronta per il titolare dell\'account/facilitatore',
    'MursionPortal.Client.EmailSettings.LearnerSession': 'La registrazione della sessione dello studente è pronta',
    'MursionPortal.Client.EmailSettings.SimulationSessionVideoRejected': 'Registrazione video sessione di simulazione rifiutata',
    'MursionPortal.Client.EmailSettings.SimulationSessionVideoDisallowed': 'Registrazione video sessione di simulazione non consentita',
    'MursionPortal.Client.EmailSettings.SimulationSessionVideoRejectedUser': 'Registrazione video sessione di simulazione rifiutata da qualche utente',
    'MursionPortal.Client.EmailSettings.RequestRecieved': 'Richiesta di conferma ricevuta',
    'MursionPortal.Client.EmailSettings.RequestNotFullfilled': 'Richiesta non soddisfatta',
    'MursionPortal.Client.EmailSettings.RequestDateTime': 'Richiesta di modifica di data/ora necessaria',
    'MursionPortal.Client.EmailSettings.Title.SelectEmails': 'Seleziona le e-mail che desideri inviare agli studenti. Mursion consiglia di lasciare abilitate tutte le e-mail.',
    'MursionPortal.Client.EmailSettings.Header.Registration': 'Registrazione',
    'MursionPortal.Client.EmailSettings.Header.TrainingSession': 'Sessione di formazione',
    'MursionPortal.Client.EmailSettings.Header.Videos': 'Video',
    'MursionPortal.Client.EmailSettings.Header.Requests': 'Richieste',
    'MursionPortal.AriaLabel.ToggleButton': 'Pulsante commutazione',
    'MursionPortal.Client.EmailSettings.Hint.RecordingEnabled': 'solo se le registrazioni sono abilitate',
    'MursionPortal.Client.EmailSettings.Hint.RequestFeature': 'solo se la funzione di richiesta è abilitata',
    'MursionPortal.SuccessDialog.Title': 'Finestra di dialogo operazione riuscita',
    'MursionPortal.Dialog.CreateSSOConfiguartion.SuccessMessage': 'La configurazione SSO del cliente è stata creata correttamente.',
    'ITManager.TechRestriction.Table.EmptyMessage': 'Non sono state trovate restrizioni tecnologiche bancarie.',
    'ITManager.TechRestriction.Table.Tag': 'Tag',
    'ITManager.TechRestriction.Table.StartDate': 'Data di inizio',
    'ITManager.TechRestriction.Table.EndDate': 'Data finale',
    'ITManager.TechRestriction.EditDialog.EnterTag': 'Inserisci il nome del tag',
    'ITManager.TechRestriction.Table.HoverTitle': 'Visualizza/Modifica restrizione tecnica',
    'ITManager.TechRestriction.Table.NoTechRestrictionError': 'Non ci sono ancora restrizioni tecnologiche',
    'MursionPortal.DateTimePicker.StartTime': 'Ora di inizio',
    'MursionPortal.DateTimePicker.EndTime': 'Ora di fine',
    'MursionPortal.TechRestrictions': 'Restrizioni tecniche',
    'MursionPortal.Caption.ScenarioBankUsersList': 'elenco utenti banca scenario',
    'MursionPortal.Caption.SsoConfigurationList': 'elenco di configurazione sso',
    'MursionPortal.AriaLabel.DateRangeCalendarButton': 'premi invio per interagire con il calendario e seleziona la data di inizio e di fine',
    'MursionPortal.Modal.Header.SessionTimeout': 'Sessione scaduta',
    'MursionPortal.Modal.Body.SessionTimeout': 'Your session will timeout in 1 minute, please continue to extend your session', // TODO
    'MursionPortal.Status.PendingNoSS': 'In attesa (No SS)',
    'MursionPortal.Status.PendingNoL': 'In attesa (No L)',
    'MursionPortal.Status.PendingNoLearners': 'In attesa (nessuno studente)',
    'MursionPortal.Status.PendingNoLSS': 'In attesa (No L/SS)',
    'Dashboard.LeftPane.SupplyManagement.SimView': 'Vista Sim',
    'Dashboard.LeftPane.SupplyManagement.SchedulingView': 'Vista programmazione',
    'MursionPortal.Table.ColumnSelection.Button.Label': 'Colonne',
    'Session.Table.Column.SubmitRequest': 'Invia richiesta',
    'Filters.SimSpecialistStatus': 'Stato Sim Specialist',
    'Filters.SimSpecialistStatusPlaceHolderHint': 'Cerca filtro Stato Sim Specialist...',
    'Users.TableModal.SSOUserID': 'ID utente SSO',
    'MursionPortal.UnavailableSlotsError.SorryText': 'Ciao! Mi dispiace tanto!',
    'MursionPortal.UnavailableSlotsError.BusyText': 'Sembra che siamo impegnati con le simulazioni di altri studenti.',
    'MursionPortal.UnavailableSlotsError.TryAgainText': 'Ti preghiamo di riprovare domani o in un altro giorno!',
    'MursionPortal.Session.Survey.ClickHere': 'Clicca qui',
    'MursionPortal.Session.SurveyNotLoading': 'Il sondaggio non si carica?',
    'Users.Table.Heading.Viewonly': '(Solo visualizzazione)',
    'MursionPortal.BookItUI.BusyText': 'Sembra che al momento siamo impegnati con le simulazioni di altri studenti.',
    'MursionPortal.BookItUI.TryLaterText': 'Presto si apriranno altre sessioni di simulazione, ti preghiamo di riprovare più tardi.',
    'MursionPortal.BookItUI.MoreSimulationsText': 'Presto si apriranno altre sessioni di simulazione.',
    'MursionPortal.BookItUI.ComeBackText': 'Torna a trovarci se vuoi eseguire la simulazione più tardi.',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Archived': 'Utente archiviato',
    'ClientUserTable.AddNewUsers.UpdateArchivedUserWarning': 'Gli utenti {archivedUsers} sono attualmente archiviati. Procedendo si aggiungeranno questi utenti ai team selezionati e la loro archiviazione verrà annullata.',
    'ClientUserTable.AddNewUsers.UpdateExistingArchivedUserWarning': 'Questi utenti {existingUsers} esistono già e gli utenti {archivedUsers} sono attualmente archiviati. Procedendo si aggiungeranno questi utenti ai team selezionati e l\'archiviazione degli utenti archiviati verrà annullata.',
    'MursionPortal.ProjectCreateStep.UpperLimitValidation.Hours': 'Non può essere superiore a {code} ore',
    'MursionPortal.ProjectCreateStep.UpcomingAvailability': 'Prossima disponibilità',
    'MursionPortal.Table.ColumnHeader.TotalSession': 'Sessione totale',
    'MursionPortal.Table.ColumnHeader.ActiveCertifications': 'Certificazioni attive',
    'MursionPortal.Button.Decline': 'Rifiuta',
    'MursionPortal.Having.Issues.Logging.In': 'Hai problemi di accesso?',
    'MursionPortal.Label.Call': 'Chiama:',
    'MursionPortal.Project.Hours': 'Ora/e',
    'Users.Button.ProfService': 'Servizio professionale',
    'Users.Button.Operations': 'Servizio operativo',
    'Users.Button.SimDesigner': 'Sim Designer',
    'MursionPortal.ArtProject.Placeholder.EnterOrSelect': 'Selezionare o inserire',
    'MursionPortal.Setting.LearnerCommunicationPersonalization': 'Personalizzazione della comunicazione con lo studente',
    'MursionPortal.LearnerCommunicationPersonalization.Heading': 'Qui è possibile personalizzare i messaggi specifici per gli studenti.',
    'MursionPortal.SupplyBasedScheduling.SubmitPage.SimulationConfirmation': 'La simulazione è confermata! Si consiglia di arrivare qualche minuto prima dell\'orario di inizio.',
    'MursionPortal.DemandBasedScheduling.SubmitPage.LearnerConfirmed': '{learners} confermati per la simulazione!',
    'MursionPortal.DemandBasedScheduling.SubmitPage.DateAndTime': 'Data e Ora',
    'MursionPortal.Personalized.LearnerDashboard.Section': 'Messaggio che si desidera far visualizzare a tutti gli studenti nella Dashboard dello studente',
    'MursionPortal.Personalized.SchedulingPage.Section': 'Messaggio che si desidera far visualizzare a tutti gli studenti nella Pagina di programmazione',
    'MursionPortal.Personalized.Section.Tooltip': 'Il messaggio verrà aggiornato in tutti i luoghi',
    'MursionPortal.CheckboxLabel.JoinNow': 'Partecipa ora',
    'MursionPortal.ProjectCreateStep.UpcomingAvailability.Tooltip': 'Ai ruoli client verranno mostrate le fasce orarie disabilitate per determinate ore di \'Prossima disponibilità\' dopo la \'Disponibilità sim\'. Se \'Prossima disponibilità\' è 0, non verranno mostrate fasce orarie disabilitate e verranno visualizzate solo le fasce orarie \'Disponibilità Sim\'. Se \'Prossima disponibilità\' è 24 ore, tutte le fasce orarie disponibili dopo le ore di \'Disponibilità sim\' verranno mostrate come fasce orarie disabilitate per un giorno.',
    'MursionPortal.LearnerDashboard.JoinNow.OutsideOfficeHoursText': 'Ti preghiamo di tornare più tardi durante l\'orario d\'ufficio dell\'organizzazione per fare questo scenario.',
    'MursionPortal.Setting.Personalization.LastUpdated': 'Ultimo aggiornamento:',
    'MursionPortal.CheckboxLabel.JoinNowRequests': 'Richieste Partecipa ora',
    'MursionPortal.LearnerDashboard.JoinNow.HeaderText': 'Ti stiamo abbinando a un avatar <code>per una simulazione dal vivo</code>. Per prepararti, consulta il materiale sottostante.',
    'MursionPortal.LearnerDashboard.JoinNow.SorryText': 'Ci dispiace!',
    'MursionPortal.LearnerDashboard.JoinNow.AvatarsBusyText': 'Tutti i nostri avatar sono impegnati con altri studenti. Torna più tardi o riprova tra qualche minuto.',
    'MursionPortal.LearnerDashboard.JoinNow.CongratsText': 'Congratulazioni! Sei stato abbinato a un avatar!',
    'MursionPortal.LearnerDashboard.JoinNow.AvatarsMatchText': 'L\'avatar ti sta aspettando! Fai clic su Partecipa alla simulazione dal vivo quando sei pronto o la tua esperienza inizierà automaticamente dopo il conto alla rovescia.',
    'MursionPortal.LearnerDashboard.JoinNow.StartLearning': 'If you are not able to get connected, click below to start learning.',
    'MursionPortal.LearnerDashboard.JoinNow.StarBucks.MyLearning': 'Starbucks MyLearning',
    'MursionPortal.LearnerDashboard.JoinNow.StarBucks.LiveSimulationExp': 'This session is a live simulation experience. Partners can now choose to take an eLearning on de-escalating conflict in MyLearning, as an alternative to the live safety simulation.',
    'MursionPortal.Button.Leave': 'Lascia',
    'MursionPortal.Personalized.ScenarioPage.Section': 'Messaggio che si desidera far visualizzare agli studenti nella Pagina dello scenario',
    'MursionPortal.Personalized.CreateNewScenario.Button.Label': 'Crea nuovo per uno scenario specifico',
    'MursionPortal.Personalized.Scenario.Search.Placeholder': 'Cerca o seleziona lo Scenario:',
    'MursionPortal.Personalized.Scenario.Selection.Title': 'Aggiungi un nuovo Scenario specifico',
    'MursionPortal.Personalized.NoMessage': 'Attualmente non è stato impostato alcun messaggio',
    'MursionPortal.Personalized.MetaData.NoHistory': 'Non c\'è ancora una cronologia.',
    'MursionPortal.JoinNow.AlreadyBooked.Message': '{code} è prenotato per una sessione in questo momento.',
    'Users.Table.SendInvite.TooltipText': 'Disabilitando le e-mail di registrazione, si disabilitano anche i pulsanti "clicca per invitare" nel portale',
    'MursionPortal.JoinNow.AlreadyBooked.JoinSession.Message': 'Fare clic su Partecipa alla simulazione dal vivo per partecipare o riprendere la sessione.',
    'Dashboard.LeftPane.CategoryHeader.Scenarios': 'Scenari',
    'Dashboard.LeftPane.Learners.TeamAndProjectActivity': 'Attività del team e di progetto',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.NotYetScheduled': 'Non ancora programmata',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.Scheduled': 'Non ancora completata',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.LearnerCompleted': 'Completata',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.NotYetAssigned': 'Non ancora assegnata',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.NotYetScheduled': 'Numero distinto di studenti assegnati ad almeno un team che è assegnato ad almeno uno scenario, ma lo studente non ha mai programmato una sessione.',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.Scheduled': 'Numero distinto di studenti che hanno programmato almeno una sessione ma non hanno ancora partecipato e completato almeno una sessione.',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.CompletedAssigned': 'Numero distinto di studenti che hanno completato almeno una sessione.',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.NotYetAssigned': 'Numero distinto di studenti non assegnati a un team o di studenti che fanno parte di un team che non è assegnato ad alcuno scenario.',
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerStatus': 'Completamento dello studente',
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerSequence': 'Studenti unici',
    'MursionPortal.Dashboard.ScenarioLearner.Header.SequenceCount': '(Massimo di {sessionCount} sessioni)',
    'Dashboard.SequenceOrder.Header.Title': 'Attività perse, annullate in ritardo e riprogrammate in ritardo',
    'MursionPortal.Integrations': 'Integrazioni',
    'MursionPortal.Personalized.Message.Delete.Title': 'Sei sicuro di voler cancellare il messaggio?',
    'MursionPortal.Personalized.Message.Delete.Tooltip': 'Cancellare il messaggio',
    'MursionPortal.Personalized.Scenario.Message.OnlyOneAtTime': 'È possibile apportare modifiche a un solo messaggio alla volta. Si prega di salvare o annullare quello già in modalità di modifica.',
    'MursionPortal.Error.Time': '6:00 - 22:00 ET',
    'MursionPortal.CheckboxLabel.JoinNow.Tooltip.Text': 'La programmazione tramite BookIt è sempre abilitata quando Partecipa ora è abilitato.',
    'MursionPortal.JoinNow.FinalizedScenario.Message': 'Lo scenario è stato finalizzato, si prega di tornare quando lo scenario sarà attivo.',
    'MursionPortal.CheckboxLabel.sessionMissedTime': 'Ora della Sessione persa',
    'MursionPortal.Title.Seconds': 'Secondo(i)',
    'MursionPortal.RequestTable.Accept.ErrorMessage': 'Siamo spiacenti che la sessione sia stata annullata.',
    'MursionPortal.Client.EmailTypeFilter.SchedulingLocked': 'Programmazione bloccata',
    'MursionPortal.Client.EmailTypeFilter.SchedulingUnlocked': 'Programmazione sbloccata',
    'MursionPortal.ScenarioInfo.SchedulingLockout.Time': 'Blocco della programmazione',
    'MursionPortal.Table.ColumnHeader.SimAvailable': 'Sim Disponibile',
    'MursionPortal.CheckboxLabel.sessionMissedTime.Tooltip.Text': 'Tempo dopo il quale la sessione sarà contrassegnata come Persa se nessuno studente o SIM partecipa.',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.NotYetCompleted': 'Non ancora completata',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.completedOneSession': 'Completata 1 sessione',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.completedTwoPlusSession': 'Completate 2+ sessioni',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.OneSession': '1 Sessione',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.TwoSession': '2 Sessioni',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.ThreeSession': '3 Sessioni',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.FourPlusSession': '4+ Sessioni',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.NotYetCompleted': 'Numero di studenti unici assegnati a questo scenario ma che non lo hanno ancora completato',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.completedOneSession': 'Numero di studenti unici assegnati a questo scenario che lo hanno completato una volta.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.completedTwoPlusSession': 'Numero di studenti unici assegnati a questo scenario che lo hanno completato due o più volte.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.OneSession': 'Numero di studenti unici che hanno perso, annullato in ritardo o riprogrammato in ritardo una sessione.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.TwoSession': 'Numero di studenti unici che hanno perso, annullato in ritardo e/o riprogrammato in ritardo due sessioni.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.ThreeSession': 'Numero di studenti unici che hanno perso, annullato in ritardo e/o riprogrammato in ritardo tre sessioni.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.FourPlusSession': 'Numero di studenti unici che hanno perso, annullato in ritardo e/o riprogrammato in ritardo quattro o più sessioni.',
    'MursionPortal.ScenarioBank.ItemBank.Label': 'Blocchi di costruzione',
    'MursionPortal.ScenarioBank.ItemBank.Skills': 'Competenze',
    'MursionPortal.ScenarioBank.ItemBank.Domains': 'Domini',
    'MursionPortal.ScenarioBank.ItemBank.Events': 'Eventi',
    'MursionPortal.ScenarioBank.ItemBank.Pathways': 'Percorsi',
    'MursionPortal.ScenarioBank.ItemBank.Resources': 'Risorse',
    'MursionPortal.ScenarioBank.ItemBank.Outcomes': 'Risultati',
    'MursionPortal.ScenarioBank.ItemBank.Mindsets': 'Mentalità',
    'MursionPortal.ScenarioBank.ItemBank.Avatars': 'Avatar',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummaries': 'Riepiloghi della ricerca',
    'MursionPortal.ScenarioBank.ItemBank.LearnerInstructions': 'Istruzioni per lo studente',
    'MursionPortal.ScenarioBank.ItemBank.Column.Level': 'Livello',
    'MursionPortal.ScenarioBank.ItemBank.Column.Domain': 'Dominio',
    'MursionPortal.ScenarioBank.ItemBank.Column.Translations': 'Traduzioni',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Skill': 'Crea abilità',
    'MursionPortal.Dashboard.Label.ScenarioActivity.ErrorMessage': 'Selezionare almeno un Progetto e uno Scenario dai filtri',
    'MursionPortal.Learner.Onboarding': 'Onboarding dello studente',
    'MursionPortal.Tab.Onboarding': 'ONBOARDING',
    'MursionPortal.OrganizationName.Placeholder': 'inserire il nome della propria organizzazione',
    'Dashboard.LeftPane.Learners.LearnerLockedOut': 'Studente bloccato fuori',
    'Dashboard.Learners.LearnerLockedOut.TableHeader': 'Studenti bloccati dalla programmazione a causa del tasso di perdita/annullamento',
    'Dashboard.Learners.LearnerLockedOut.LockedOutColumn': 'Data/Ora bloccata per la programmazione',
    'Dashboard.Learners.LearnerLockedOut.AutoReleaseColumn': 'Data/Ora fino al rilascio automatico',
    'MursionPortal.DontHaveEmail.Text': 'Non avete un indirizzo e-mail della vostra organizzazione?',
    'MursionPortal.InvalidCompanyName.Error': 'La vostra organizzazione richiede agli utenti di accedere utilizzando il proprio indirizzo e-mail. Inserirlo qui sopra.',
    'MursionPortal.LearnerOnboardingPage.Text.Welcome': 'Benvenuti',
    'MursionPortal.LearnerOnboardingPage.Text.MursionVideoOverview': 'Panoramica del video di Mursion',
    'MursionPortal.LearnerOnboardingPage.Text.KeyThingsToKnow': 'Cose fondamentali da sapere',
    'MursionPortal.LearnerOnboardingPage.Welcome.Section.Text': 'Siamo entusiasti di darvi il benvenuto nel vostro programma di apprendimento Mursion! Mursion utilizza simulazioni immersive guidate dalla tecnologia human-in-the-loop per fornire un\'esperienza di apprendimento completa che vi fornirà gli strumenti e le tecniche necessarie per avere successo in un mondo in continua evoluzione.',
    'MursionPortal.LearnerOnboardingPage.Welcome.Section.Beginner.Text': 'Che siate principianti o professionisti esperti, il nostro programma ha qualcosa da offrire a tutti. Non vediamo l\'ora che iniziate.',
    'MursionPortal.LearnerOnboardingPage.Video.Section.Text': 'Per prima cosa, guardate questa panoramica video <strong>su Mursion!</strong>',
    'MursionPortal.LearnerOnboardingPage.Scheduled.Section.Text': '<strong>Ora siete pronti per:</strong> programmare la vostra prima simulazione!',
    'MursionPortal.LearnerOnboardingPage.keysThingsToKnow.Section.Heading': 'Vediamo quindi alcune cose fondamentali da sapere!',
    'MursionPortal.LearnerOnboardingPage.Mursion.Methodology': 'Metodologia di Mursion',
    'MursionPortal.LearnerOnboardingPage.Mursion.Methodology.Summary': 'Mursion si basa sulla ricerca e si dimostra in grado di favorire il cambiamento del comportamento. Le nostre simulazioni basate su avatar vi aiuteranno a imparare a rispondere in modo appropriato in situazioni emotivamente cariche.',
    'MursionPortal.LearnerOnboardingPage.Mursion.WhyAvatars.Text': 'Perché gli avatar',
    'MursionPortal.LearnerOnboardingPage.Mursion.WhyAvatars.Summary': 'Mursion è uno strumento più efficace dei metodi tradizionali o dei giochi di ruolo. Le ricerche dimostrano che le persone si fidano di più e sono più curiose quando si impegnano con gli avatar rispetto a quando interagiscono con i loro colleghi o con i manager.',
    'MursionPortal.LearnerOnboardingPage.Human.Text': 'L\'approccio human-in-the-loop',
    'MursionPortal.LearnerOnboardingPage.Human.Summary': 'Ogni simulazione Mursion prevede la presenza di uno Simulation Specialist dal vivo che fornisce il dialogo parlato e i movimenti di ben cinque avatar virtuali in ogni simulazione.',
    'MursionPortal.LearnerOnboardingPage.Practice.Text': 'Perché la pratica è importante',
    'MursionPortal.LearnerOnboardingPage.Practice.Text.Summary': 'Migliorare le capacità di leadership non è un compito facile. Ma la ricerca dimostra che l\'apprendimento ha un impatto maggiore quando include la pratica. Mursion dà priorità alla pratica creando spazi sicuri, dinamici e accessibili.',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Domain': 'Crea Dominio',
    'MursionPortal.ScenarioBank.ItemBank.Level.L1': 'L1',
    'MursionPortal.ScenarioBank.ItemBank.Level.L2': 'L2',
    'MursionPortal.ScenarioBank.ItemBank.Level.L3': 'L3',
    'MursionPortal.Label.OrganizationName': 'Nome dell\'organizzazione',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Pathway': 'Crea Percorso',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Event': 'Crea Evento',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Resource': 'Crea Risorsa',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Outcome': 'Crea Risultato',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Mindset': 'Crea Mentalità',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Avatar': 'Crea nome Avatar',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.ResearchSummary': 'Crea un riepilogo della ricerca',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.LearnerInstruction': 'Crea istruzioni per lo studente',
    'MursionPortal.ScenarioBank.ItemBank.BackButton.Label': 'Torna a Scenario Banca',
    'MursionPortal.ScenarioBank.ItemBank.Title.Placeholder': 'Digitare qui',
    'MursionPortal.Dashboard.NextOnProject.Footer.MissOrCancelationRateFulfilled.Text': 'You cannot schedule this simulation as you have reached the missed or cancellation limit set by your organization. Please reach out to your program admin to allow scheduling.',// TODO add translations
    'MursionPortal.ScenarioInfo.Show': 'Mostra',
    'MursionPortal.ScenarioInfo.CertificationHistory': 'Cronologia della certificazione',
    'MursionPortal.Session.AlreadyBooked': 'Spiacenti, la sessione è già stata accettata da un\'altro SIM.',
    'MursionPortal.CustomizeInvitation.Title': 'Personalizzare gli inviti',
    'MursionPortal.CustomizeInvitation.SubTitle': 'Per personalizzare, sovrascrivere il testo di ciascun campo sottostante o lasciare il testo predefinito.',
    'MursionPortal.CustomizeInvitation.IntroMessage': 'Messaggio introduttivo agli studenti (gli scenari disponibili si riempiranno automaticamente sotto questo messaggio):',
    'MursionPortal.CustomizeInvitation.Label.SenderName': 'Nome del mittente (l\'indirizzo e-mail sarà quello di noreply@mursion.com)',
    'MursionPortal.CustomizeInvitation.Label.Subject': 'Oggetto',
    'MursionPortal.InviteLearnersTeam.Section.Title': 'Quale squadra o quali squadre vorreste invitare a programmare?',
    'MursionPortal.InviteLearnersTeam.Section.SubTitle': 'Gli studenti saranno invitati a partecipare a tutti gli scenari di questo progetto che sono idonei a programmare',
    'MursionPortal.InviteLearnersTeam.ScenariosAssigned.Text': 'scenari assegnati',
    'MursionPortal.Confirmation.Modal.Invitations.Text': 'I vostri Inviti sono stati programmati!',
    'MursionPortal.ScenarioSetting.ValidationMessage': 'deve essere inferiore o uguale alla stessa impostazione a livello di cliente e/o di progetto.',
    'MursionPortal.ScenarioBank.EventDetail.Details': 'Dettagli',
    'MursionPortal.ScenarioBank.CharacterLimit.TitleMessage': 'Limite di caratteri: {titleMaxLength}',
    'MursionPortal.ScenarioBank.CharacterLimit.DescriptionMessage': 'Limite di caratteri: {descriptionMaxLength}',
    'MursionPortal.ScenarioBank': 'Modello di evento',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header': 'Ispirare fiducia e sicurezza negli altri nel condividere le informazioni sul proprio lavoro.',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title': 'Dettagli del Dominio',
    'MursionPortal.ScenarioBank.ItemBank.Edit.DescriptionOfUpdate.Label': 'Salvando queste modifiche, questo {type} verrà aggiornato in tutti gli scenari. Aggiungere una breve descrizione delle modifiche:',
    'Dashboard.Learners.LearnerLockedOut.LearnerColumn.Tooltip': 'Nome o e-mail dell\'utente',
    'MursionPortal.ScenarioInfo.CertificationHistory.Column.Certified': 'Certificato',
    'MursionPortal.ScenarioInfo.CertificationHistory.Column.Decertified': 'Decertificato',
    'MursionPortal.ScenarioInfo.CertificationHistory.Column.Inactive': 'Inattivo',
    'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease': 'Rilasciare lo studente per la programmazione',
    'MursionPortal.CustomizeInvitation.Sender.Name': 'Mursion',
    'MursionPortal.CustomizeInvitation.Subject.Title': 'Preparati a mettere in pratica le tue abilità! Le sessioni di Mursion sono pronte per essere programmate.',
    'MursionPortal.CustomizeInvitation.Intro.Message': 'Hai ricevuto l\'invito a partecipare a un\'esperienza di simulazione presso Mursion. Fai clic qui sotto per programmare una sessione, oppure visita la tua Dashboard ed esplora.',
    'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease.ConfirmationText': 'Sei sicuro di voler rilasciare {code} studente/i per la programmazione?',
    'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease.SuccessText': '{code} studente/i è stato/sono stati rilasciato/i per la programmazione.',
    'Dashboard.Learners.LearnerLockedOut.LockedOutColumn.Tooltip': 'Data/ora in cui lo studente è stato bloccato per la programmazione',
    'Dashboard.Learners.LearnerLockedOut.AutoReleaseColumn.Tooltip': 'Data/ora in cui lo studente verrà rilasciato automaticamente',
    'MursionPortal.ScenarioBank.ItemBank.NextGenScenario': 'Scenario NextGen',
    'MursionPortal.ScenarioBank.ResourceFilter.Book': 'Libro',
    'MursionPortal.ScenarioBank.ResourceFilter.Article': 'Articolo',
    'MursionPortal.ScenarioBank.Filter.Label.Publisher': 'Editore',
    'MursionPortal.ScenarioBank.ItemBank.Level.Placeholder': 'Selezionare il livello',
    'MursionPortal.ScenarioBank.ItemBank.Domain.Placeholder': 'Cercare o selezionare il dominio:',
    'MursionPortal.Label.CompletionRate.Tooltip': 'Il tasso di completamento è il numero di volte in cui uno studente può completare una simulazione prima di perdere la possibilità di programmare (blocco)',
    'MursionPortal.Label.SchedulingRate.Tooltip': 'Il tasso di programmazione è il numero di volte in cui uno studente può programmare una simulazione prima di perdere la possibilità di programmare (blocco)',
    'MursionPortal.Label.MissCancellationRate.Tooltip': 'Il tasso di perdita/annullamento è il numero di volte in cui uno studente può perdere o annullare una simulazione prima di perdere la possibilità di programmare (blocco)',
    'MursionPortal.ScenarioInfo.SchedulingLockout.Time.Tooltip': 'Ore di blocco della programmazione è il numero di ore in cui durerà il blocco della programmazione.',
    'MursionPortal.ScenarioBank.ItemBank.Mindset.Type.Derailing': 'Sviante',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Skill': 'Dettagli sull\'abilità',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Mindset': 'Dettagli sulla mentalità',
    'MursionPortal.ProjectCreate.DeliveryHours': 'Orari di consegna',
    'MursionPortal.ProjectCreate.DeliveryHours.Tooltip': 'L\'intervallo di tempo per il quale gli studenti saranno mostrati alle fasce orarie istantanee',
    'MursionPortal.ProjectCreate.DeliveryHours.Start': 'Inizio orario di consegna',
    'MursionPortal.ProjectCreate.DeliveryHours.End': 'Fine orario di consegna',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.LearnerInstruction': 'Dettagli dell\'istruzione dello studente',
    'MursionPortal.ScenarioBank.ItemBank.Avatar.Edit.Header.Title': 'Dettagli Avatar',
    'MursionPortal.ScenarioBank.Label.AddLink': 'Aggiungi Link',
    'MursionPortal.ScenarioBank.Year.placeholderText': 'Anno',
    'MursionPortal.ScenarioBank.ItemBank.Resource.Edit.Header.Title': 'Dettagli Risorsa',
    'MursionPortal.ScenarioBank.ItemBank.Outcome.Edit.Header.Title': 'Dettagli Risultato',
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.Advanced': 'Avanzato',
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.Intermediate': 'Intermedio',
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.Foundational': 'Di base',
    'MursionPortal.ScenarioBank.ItemBank.Skill': 'Abilità',
    'MursionPortal.Resources.Search.Text': 'Cercare o selezionare la/e risorsa/e',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummaries.Details': 'Dettagli sui Riepiloghi della ricerca',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummary': 'Riepilogo della ricerca',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Pathway': 'Dettagli Percorso',
    'MursionPortal.ScenarioBank.ItemBank.Pathway': 'Percorso',
    'MursionPortal.ScenarioBank.ConfirmationText.Archive': 'Sicuri di voler archiviare questo {block}?',
    'MursionPortal.ScenarioBank.ConfirmationText.Unarchived': 'Sicuri di voler disarchiviare questo {block}?',
    'MursionPortal.ScenarioBank.Unarchived': 'Disarchivia',
    'MursionPortal.ScenarioBank.ItemBank.Resource': 'Risorsa',
    'MursionPortal.ScenarioBank.ItemBank.Mindset': 'Mentalità',
    'MursionPortal.ScenarioBank.ItemBank.LearnerInstruction': 'Istruzione dello studente',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Event': 'Dettagli Evento',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives': 'Prospettive Avatar',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives.Intro': 'Introduzione',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives.DiscProfile': 'Profilo Disc',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives.VerbalStyle': 'Stile verbale',
    'MursionPortal.ScenarioBank.Content': 'Contenuto',
    'MursionPortal.ScenarioBank.Content.ScenarioTitle': 'Titolo dello scenario',
    'MursionPortal.ScenarioBank.Content.ScenarioCoverStory': 'Storia di copertina dello scenario',
    'MursionPortal.ScenarioBank.ItemBank.Outcome.Placeholder': 'Selezionare un risultato bancario',
    'MursionPortal.ScenarioBank.Content.WhyThisMatters': 'Perché è importante',
    'MursionPortal.ScenarioBank.NextGen.AvatarGuide': 'Guida agli avatar',
    'MursionPortal.ScenarioBank.NextGen.ViewScenario': 'Visualizza lo scenario',
    'MursionPortal.ScenarioBank.NextGen.Event': 'Icona dell\'evento',
    'MursionPortal.NextOnProject.Card.JoinNow': 'Partecipa alla simulazione dal vivo',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Title': 'Come funziona',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Intro': 'Ciao! Tra poco sarai abbinato a un avatar per <code>partecipare subito a una simulazione dal vivo</code>.',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Matched': 'Una volta abbinato a un avatar, fai clic su <code>’Partecipa alla simulazione dal vivo’</code>.',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Allow': 'Fai clic su <code>’Consenti’</code> per l\'accesso al microfono/telecamera quando viene richiesto.',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.StartSession': 'Fai clic su <code>’Avvia sessione’</code> e attendi che l\'avatar inizi la simulazione.',
    'MursionPortal.ScenarioBank.NextGen.OutOfSite.Label': 'Fuori dal sito, al centro dell\'attenzione',
    'MursionPortal.FirstGen.Scenario.Button.Text': '1a Gen',
    'MursionPortal.NextGen.Scenario.Button.Text': '2a Gen',
    'MursionPortal.NextGen.Scenario.Assessment': 'Valutazione',
    'MursionPortal.NextGen.Scenario.OutOfSite': 'Fuori dal sito, al centro dell\'attenzione',
    'MursionPortal.NextGen.Scenario.Synopsis': 'Sinossi',
    'MursionPortal.NextGen.Scenario.MinimumPoints': 'Punti minimi richiesti per un risultato riuscito',
    'MursionPortal.NextGen.Scenario.Successful': 'Riuscito',
    'MursionPortal.NextGen.Scenario.OpportunityDevelop': 'Opportunità di ulteriore sviluppo',
    'MursionPortal.NextGen.Scenario.LibraryInfo': 'Informazioni sulla biblioteca',
    'MursionPortal.NextGen.Scenario.LibraryInfo.DifficultyLevel': 'Livello di difficoltà',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ScenarioCoverImage': 'Immagine di copertina dello scenario',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ScenarioThumbnailImage': 'Immagine di anteprima dello scenario',
    'MursionPortal.NextGen.Scenario.LibraryInfo.CoverImageDescription': 'Descrizione dell\'immagine di copertina',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ThumbnailImageDescription': 'Descrizione dell\'immagine di anteprima',
    'MursionPortal.ScenarioBank.CharacterLimit.ResponseMessage': 'Limite di caratteri: {responseMaxLength}',
    'MursionPortal.ScenarioBank.EventDetail.AvatarResponseTitle': 'Risposta/e possibile/i dell\'Avatar',
    'MursionPortal.NextGen.Scenario.ReflectionGuide': 'Guida alla riflessione',
    'MursionPortal.NextGen.Scenario.Scoring': 'Punteggio',
    'MursionPortal.ErrorMessage.buildingBlock': 'L\'id del Blocco di costruzione deve essere specificato',
    'MursionPortal.NextGen.Scenario.LibraryInfo.DragAndDropText': 'Trascinare e rilasciare il file qui o fare clic per selezionare i file',
    'MursionPortal.NextGen.Scenario.Publish': 'Pubblica',
    'MursionPortal.NextGen.Scenario.Publish.Label': 'Compilare i campi obbligatori (*) per poter pubblicare.',
    'MursionPortal.NextGen.Scenario.Last.Publish.Label': 'Ultima pubblicazione {publishDate} di {publishedBy}',
    'MursionPortal.EulaAgreement.CodeOfConductCheckBox.Text': 'Accetto il modello di consegna della simulazione di Mursion e confermo di sostenere e onorare il Codice di condotta di Mursion in tutte le simulazioni a cui partecipo. Maggiori informazioni sulle simulazioni di Mursion e sul nostro Codice di condotta <code>qui.</code>',
    'MursionPortal.JoinNow.Cancellation.FirstOption.Text': 'Non ho tempo di farlo adesso',
    'MursionPortal.JoinNow.Cancellation.SecondOption.Text': 'Non so cosa sia',
    'MursionPortal.JoinNow.Cancellation.ThirdOption.Text': 'Tornerò a farlo più tardi',
    'MursionPortal.JoinNow.Cancellation.FirstOption.Description.Text': 'La maggior parte delle persone che hanno fatto questa simulazione l\'hanno trovata un valido impiego del loro tempo. L\'operazione richiede 30 minuti o meno.',
    'MursionPortal.JoinNow.Cancellation.SecondOption.Description.Text': 'Invece di un video, questa pratica interattiva aiuta a sviluppare competenze e abilità in uno spazio sicuro e dinamico.',
    'MursionPortal.JoinNow.Cancellation.ThirdOption.Description.Text': 'Certo! Potete tornare in qualsiasi momento durante gli orari stabiliti dall\'organizzazione e partecipare alla simulazione dal vivo per mettere in pratica le vostre abilità!',
    'MursionPortal.JoinNow.CancellationText.Heading': 'Perché vuoi annullare?',
    'MursionPortal.JoinNow.CancellationPage.Title': 'Pratica le opportunità con l\'avatar!',
    'MursionPortal.JoinNow.CancellationPage.Text': 'Individua modi positivi per risolvere la situazione nel rispetto delle politiche e delle linee guida di Starbucks e cerca opportunità per creare i momenti migliori per tutti i clienti.',
    'MursionPortal.JoinNow.JoinSimulation.Button.Text': 'Continua a partecipare alla simulazione',
    'MursionPortal.LearnerDashboard.JoinNow.SearchingAvatar': 'Stiamo cercando un avatar <code>per la tua simulazione dal vivo!</code>',
    'MursionPortal.LearnerDashboard.JoinNow.SuccessText': '<code>Congratulazioni</code>, sei stato abbinato a un avatar per la tua simulazione dal vivo!',
    'MursionPortal.LearnerDashboard.JoinNow.ConnectingText': 'Ti stiamo <code>collegando al tuo avatar</code> e la tua simulazione dal vivo inizierà a breve!',
    'MursionPortal.LearnerDashboard.JoinNow.DoNotCloseText': 'Non chiudere la finestra del browser!',
    'MursionPortal.LearnerDashboard.JoinNow.StrategiesToUse': 'Strategie da utilizzare',
    'MursionPortal.LearnerDashboard.JoinNow.PracticeOpportunityText': 'Pratica le opportunità con l\'avatar!',
    'MursionPortal.Text.Sorry': 'Siamo spiacenti',
    'MursionPortal.Text.Matching': 'Abbinamento',
    'MursionPortal.Text.Connecting': 'Collegamento',
    'MursionPortal.JoinNowScenarioInfoCard.Text': 'Torna più tardi o riprova tra qualche minuto!',
    'MursionPortal.JoinNowScenarioInfoCard.HeaderText': 'Tutti i nostri avatar sono impegnati con altri studenti!',
    'MursionPortal.JoinNow.CancellationPage.GoBackButton.Text': 'Annulla e torna alla dashboard',
    'MursionPortal.NextGen.Scenario.Event.AddMessage': 'Aggiungi un minimo di 2',
    'MursionPortal.ScenarioBank.ItemBank.Events.AddButton': '+Aggiungi',
    'MursionPortal.NextGen.ScenarioBank.Event.Select.Placeholder': 'Seleziona un Evento',
    'MursionPortal.NextGen.ScenarioBank.Skill.Select.Placeholder': 'Seleziona un\'Abilità',
    'MursionPortal.NextGen.ScenarioBank.Event.AvatarBehavior.Label': 'Comportamento dell\'avatar*',
    'MursionPortal.NextGen.ScenarioBank.Event.LearnerResponse.Label': 'Risposta attesa dello studente*',
    'MursionPortal.NextGen.ScenarioBank.Event.AvatarAdd.Button': 'Aggiungi Risposta',
    'MursionPortal.NextGen.ScenarioBank.AvatarResponseName.Select.Placeholder': 'Selezionare un Avatar',
    'MursionPortal.NextGen.ScenarioBank.Label.AvatarPossibleResponse': 'Risposta/e possibile/i dell\'Avatar',
    'MursionPortal.NextGen.ScenarioBank.Event.AssessmentObservedAction.Label': 'Descrivi l\'azione osservata',
    'MursionPortal.NextGen.ScenarioBank.Event.AssessmentImapct.Label': 'Spiega l\'impatto',
    'MursionPortal.NextGen.ScenarioBank.StrengthAssessment': 'Valutazione(i) della Forza *',
    'MursionPortal.NextGen.ScenarioBank.Button.StrengthAssessment': '+ Aggiungi Valutazione della Forza',
    'MursionPortal.NextGen.ScenarioBank.Label.Strength': 'Forza',
    'MursionPortal.NextGen.ScenarioBank.OpportunityAssessment': 'Valutazione dell\'Opportunità *',
    'MursionPortal.NextGen.ScenarioBank.Button.OpportunityAssessment': '+ Aggiungi valutazione dell\'Opportunità',
    'MursionPortal.NextGen.ScenarioBank.Label.Opportunity': 'Opportunità',
    'MursionPortal.NextGen.Scenario.Event.Mindset.Select.Placeholder': 'Seleziona Mentalità positiva',
    'MursionPortal.NextGen.Scenario.Event.Derailing.Select.Placeholder': 'Seleziona Mentalità sviante',
    'MursionPortal.NextGen.Scenario.Event.Scoring.Title': 'Punteggio dell\'Evento',
    'MursionPortal.NextGen.Scenario.Event.Mindset.Title': 'Aggiungi Mentalità positiva e quella sviante*',
    'MursionPortal.NextGen.Scenario.Event.Positive.Button.Text': 'Aggiungi un\'ulteriore Mentalità positiva',
    'MursionPortal.NextGen.Scenario.Event.Derailing.Button.Text': 'Aggiungi un\'ulteriore Mentalità sviante',
    'MursionPortal.Text.Searching': 'Ricerca',
    'MursionPortal.NextGen.Scenario.Event.MindsetsNavigated': 'Mentalità navigata',
    'Dashboard.ContractProgress.Column.RemainingUtilization': 'Utilizzo residuo',
    'Dashboard.ContractProgress.Column.EstimatedRemaining': 'Residuo stimato',
    'MursionPortal.Label.Completion': 'Tasso di completamento',
    'Dashboard.Report.ReportSessionOption.AllDays': 'Tutte le sessioni',
    'MursionPortal.Status.Abbreviation.EarlyCancelled': 'EC',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.EstimatedRemaining': 'Residuo stimato',
    'Dashboard.ContractProgress.ColumnTooltip.RemainingUtilization': 'Simulazioni rimanenti e programmate come numero di simulazioni acquistate',
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdownQuickView': 'Ripartizione della sessione - Vista rapida',
    'MursionPortal.NextGen.SelectorText.Race': 'Razza',
    'MursionPortal.NextGen.SelectorText.Ethnicity': 'Etnia',
    'MursionPortal.NextGen.SelectorText.None': 'Nessuna',
    'MursionPortal.NextGen.SelectorText.Woman': 'Uno deve essere una donna',
    'MursionPortal.NextGen.SelectorText.Man': 'Uno deve essere un uomo',
    'MursionPortal.NextGen.SelectorText.Wheelchair': 'Uno deve essere in sedia a rotelle',
    'MursionPortal.NextGen.SelectorText.agedText': 'Uno deve avere più di 55 anni',
    'MursionPortal.NextGen.Avatar.Restriction': 'Limitazione degli avatar',
    'MursionPortal.NextGen.AvatarOrScenarioComposition': 'Composizione Avatar/Scenario',
    'MursionPortal.Label.Opening': 'Apertura',
    'MursionPortal.Label.Closing': 'Chiusura',
    'MursionPortal.Label.Introduction': 'Introduzione',
    'MursionPortal.ReflectionGuide.LearnerAssessment': 'Valutazione dello studente',
    'MursionPortal.ReflectionGuide.HostAssessment': 'Valutazione dell\'ospite',
    'MursionPortal.ReflectionGuide.HostOpportunity': 'Opportunità dell\'ospite',
    'MursionPortal.ReflectionGuide.Introduction.Text': 'Bentornati! Vi sentite pronti a riflettere sulla vostra esperienza? (permettere allo studente di acconsentire prima di continuare)',
    'MursionPortal.Label.Say': 'DIRE',
    'MursionPortal.Label.Ask': 'CHIEDERE',
    'MursionPortal.Label.Bullet': 'Elencare',
    'MursionPortal.ReflectionGuide.LearnerPreparation.FirstQuestion': 'Com\'è andata la conversazione?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.SecondQuestion': 'In che modo la realtà della conversazione ha rispecchiato le aspettative per la conversazione?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.ThirdQuestion': 'Che cosa è successo?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.FourthQuestion': 'Ci sono stati spunti (verbali e non verbali) da parte dell\'avatar che hanno dato un indizio su come sarebbe andata la conversazione?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.FifthQuestion': 'Ci sono stati spunti (verbali e non verbali) da parte dell\'avatar che hanno dato un indizio su come sarebbe andata la conversazione?',
    'MursionPortal.ReflectionGuide.LearnerAssessment.FirstSay': 'In questa conversazione sono state affrontate alcune dinamiche difficili: Mickey era frustrato.',
    'MursionPortal.ReflectionGuide.LearnerAssessment.SecondSay': 'Anche se con buone intenzioni, spesso vedo i leader reagire in modo poco produttivo:',
    'MursionPortal.ReflectionGuide.LearnerAssessment.ThirdSay': 'Ho anche visto leader reagire in modo più produttivo:',
    'MursionPortal.ReflectionGuide.LearnerAssessment.FourthSay': 'In questa conversazione sono state affrontate alcune dinamiche impegnative',
    'MursionPortal.ReflectionGuide.LearnerAssessment.Ask': 'Dove pensi di esserti posizionato? (follow up: Perché/ Come mai/ Come lo sai?): Dove pensi di esserti posizionato? (follow up: Perché/ Come mai/ Come lo sai?)',
    'MursionPortal.ReflectionGuide.HostAssessment.Opening': 'Se potessi valorizzare uno dei punti di forza che hai dimostrato durante la riunione (selezionarne uno):',
    'MursionPortal.ReflectionGuide.HostAssessment.Closing': 'Le mie riflessioni coincidono con le tue? Perché o perché no?',
    'MursionPortal.ReflectionGuide.HostAssessment.Opportunity.Opening': 'Se potessi valorizzare una delle opportunità di sviluppo che ho osservato (selezionarne una):',
    'MursionPortal.ReflectionGuide.Closing.Say': 'È roba tosta e vi ringrazio per aver esercitato queste importanti abilità con Mursion oggi!',
    'MursionPortal.ReflectionGuide.Closing.Ask': 'Qual è una cosa che porterai con te da questa esperienza e che userai la prossima volta che ti troverai ad affrontare una situazione simile nella vita reale?',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummary.Placeholder': 'Seleziona un riepilogo della ricerca',
    'MursionPortal.ScenarioBank.ItemBank.LearnerInstruction.Placeholder': 'Selezionare un\'istruzione per lo studente',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ClinkHereToReplaceThisFile': 'Fare clic qui per sostituire questo file.',
    'MursionPortal.Status.Empty': ' ',
    'MursionPortal.ScenarioContent.Label': 'Aggiungere un minimo di {min} e un massimo di {max}.',
    'MursionPortal.Search.Text.Skills': 'Cercare o selezionare le abilità',
    'MursionPortal.Search.Text.Avatar': 'Cercare o selezionare gli avatar',
    'MursionPortal.NextGen.Scenario.TimelineAndScheduling': 'Sequenza temporale e programmazione',
    'MursionPortal.NextGen.Session.Replay': 'Ripetizione della sessione',
    'MursionPortal.NextGen.StrengthsAndOpportunities': 'Punti di forza e opportunità',
    'MursionPortal.NextGen.Recommendations': 'Raccomandazioni',
    'MursionPortal.NextGen.MoreToDiscover': 'Altro da scoprire',
    'MursionPortal.NextGen.MissionAccomplished': 'Missione compiuta!',
    'MursionPortal.NextGen.Learner.ScenarioMaterials': 'Materiali dello scenario',
    'MursionPortal.NextGen.Learner.PathwayAffiliation': 'Affiliazione al percorso',
    'MursionPortal.NextGen.Learner.SkillLevel': 'Livello di abilità',
    'MursionPortal.NextGen.Learner.YourMission': 'La tua missione',
    'MursionPortal.ScenarioBank.ShowOnlySecondGenscenarios': 'Mostra solo scenari di seconda Gen',
    'MursionPortal.NextGen.CreateNewSkill.Header.Text': 'Creare una nuova abilità bancaria',
    'MursionPortal.NextGen.Learner.StartSimulation': 'Avvio della simulazione',
    'MursionPortal.SimMaterials.Label.CoverStory': 'Storia di copertina',
    'MursionPortal.SimMaterials.Label.LearnerMission': 'Missione dello studente',
    'MursionPortal.SimMaterials.Label.Background': 'Contesto',
    'MursionPortal.SimMaterials.Label.PerspectivesAndIdentities': 'Prospettive e identità',
    'MursionPortal.SimMaterials.HeaderText.SimDashboard': 'Dashboard SIM',
    'MursionPortal.NextGen.Learner.ToStart': 'ad Avvia',
    'MursionPortal.Project.DeliveryHours.Standard': 'Orari di consegna standard',
    'MursionPortal.Project.DeliveryHours.Global': 'Orari di consegna globali',
    'MursionPortal.Label.Monday.ShortForm': 'Lu',
    'MursionPortal.Label.Tuesday.ShortForm': 'Ma',
    'MursionPortal.Label.Wednesday.ShortForm': 'Me',
    'MursionPortal.Label.Thursday.ShortForm': 'Gi',
    'MursionPortal.Label.Friday.ShortForm': 'Ve',
    'MursionPortal.Label.Saturday.ShortForm': 'Sa',
    'MursionPortal.Label.Sunday.ShortForm': 'Do',
    'MursionPortal.NextGen.CreateFromBank.Customized.Label': 'Personalizzato in base allo scenario bancario',
    'MursionPortal.NextGen.CreateFromBank.CustomizedFromBank.Tooltip': 'Questo scenario è stato personalizzato dallo scenario bancario originale. Qualsiasi aggiornamento apportato allo scenario bancario non verrà propagato a questo scenario.',
    'MursionPortal.BuildingBlock.Label.PublishedDate': 'Data di pubblicazione',
    'MursionPortal.Label.CopyTeamInviteLink': 'Copiare il link di invito del team',
    'MursionPortal.NextGen.SessionReplayText': 'Questa conversazione è stata un\'occasione di esercitazione per facilitare una conversazione a sostegno di un membro del team in un momento di difficoltà. Il tuo successo dipendeva dalla tua capacità di provare empatia per Jo.',
    'MursionPortal.NextGen.TheMission': 'La missione:',
    'MursionPortal.NextGen.DemonstratedStrength': 'Forza dimostrata',
    'MursionPortal.NextGen.OpportunityForFutureGrowth': 'Opportunità di crescita futura',
    'MursionPortal.NextGen.CompetenciesFocus': 'Le competenze oggetto di attenzione sono state:',
    'MursionPortal.NextGen.StrengthAndOpportunitiesText': 'Durante il debriefing della conversazione, sono stati identificati un punto di forza e un\'opportunità di crescita:',
    'MursionPortal.NextGen.WeObserved': 'Abbiamo osservato....',
    'MursionPortal.NextGen.WhyThisMatters': 'Perché è importante',
    'MursionPortal.ScenarioSetting.MissOrCancelationRate.ValidationMessage': 'Il Tempo di blocco della programmazione non può essere nullo se il tasso di perse/annullate non è nullo.',
    'MursionPortal.NextGen.CreateFromBank.Customized.Modal.Text': 'È stato personalizzato uno scenario bancario e qualsiasi modifica futura allo scenario bancario non si rifletterà in questo scenario. Si vuole procedere?',
    'MursionPortal.SimMaterials.Event.SkillsInFocus.Label': 'Abilità in primo piano',
    'MursionPortal.SimMaterials.Label.SimulationEvents': 'Eventi di simulazione',
    'MursionPortal.SimMaterials.Event.AvatarBehavior.Label': 'Comportamento dell\'avatar',
    'MursionPortal.SimMaterials.Event.LearnerResponse.Label': 'Risposta attesa dell\'allievo',
    'MursionPortal.Label.NoTimeSelected': 'Nessun tempo selezionato',
    'MursionPortal.Label.StartTime.EndTime.Selected': 'Devono essere selezionate sia l\'ora di inizio che l\'ora di fine',
    'MursionPortal.SimMaterials.Label.ReflectionAndDebrief': 'Riflessione e debriefing',
    'MursionPortal.NextGen.Scenario.Planning': 'Pianificazione',
    'MursionPortal.HowItWorks.Experience.Text': 'L\'esperienza inizierà automaticamente tra pochi secondi!',
    'MursionPortal.HowItWorks.MicCamera': 'Microfono/telecamera',
    'MursionPortal.HowItWorks.Prompt.Text': 'Quando viene richiesto, fare clic su <strong>\'Consenti\'</strong> per l\'accesso al microfono/alla telecamera, in modo che l\'avatar possa vederti e sentirti!',
    'MursionPortal.HowItWorks.StartSession.Text': 'Fare clic su <strong>\'Avvia sessione\'</strong> e rivedere la descrizione dello scenario per prepararsi!',
    'MursionPortal.LearnerMaterials.KeyCompetencies': 'Competenze chiave',
    'MursionPortal.LearnerMaterials.ResearchMaterials': 'Materiali di ricerca',
    'MursionPortal.LearnerMaterials.PracticeOpportunity': 'Opportunità di pratica',
    'MursionPortal.ScenarioInfo.Label.ScenarioBank': 'Lo scenario viene creato dalla banca',
    'MursionPortal.DemandBasedScheduling.SlotSelectionMessage': 'Scegliere le fasce orarie più adatte alle proprie esigenze. Programmeremo lo scenario in una delle fasce orarie scelte.',
    'MursionPortal.NextGen.ScenarioBank.SubStrengthAssessment': 'Forza',
    'MursionPortal.NextGen.ScenarioBank.SubOpportunityAssessment': 'Opportunità',
    // needs translations
    'MursionPortal.EngagementHub.EmailLearners': 'Email Learners', // TODO add translations
    'MursionPortal.EngagementHub.InviteToTeam': 'Invite to Team', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearnersToScheduleTitle': 'Invite your learners to start or continue their practice', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearnersToScheduleSummary': 'Engage your learners via email or by embedding shared links in your own communications.', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations': 'View Invitations', // TODO add translations
    'MursionPortal.EngagementHub.CreateInvite': 'Create Invite', // TODO add translations
    'MursionPortal.NextGen.replayConversation':'Replay the Conversation',// TODO add translations
    'MursionPortal.EngagementHub.BackTo': 'Back to {clientName}',// TODO add translations
    'MursionPortal.EngagementHub.LearningPotential': 'At Mursion, we believe in maximizing every learner\'s potential.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationLearnMore': 'Communication Is Key! Learn more', // TODO add translations
    'MursionPortal.EngagementHub.UnlockTeamPotential': 'Unlock Your Team’s Potential', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearners.Title': 'Invite your learners to schedule their simulations', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearners.Summary': '58% of users are more likely to sign up for their simulation if you send a custom invitation to schedule.', // TODO add translations
    'MursionPortal.EngagementHub.ManageEmailSettings.Label': 'Your Email, Your Way!', // TODO add translations
    'MursionPortal.EngagementHub.ManageEmailSettings.Title': 'Manage email settings and gain insights', // TODO add translations
    'MursionPortal.EngagementHub.Customize': 'Customize', // TODO add translations
    'MursionPortal.EngagementHub.EmailConfigurations.Title': 'Email Configurations', // TODO add translations
    'MursionPortal.EngagementHub.EmailConfigurations.Summary': 'Customize email messages that resonate with your learners and drive adoption and engagement.', // TODO add translations
    'MursionPortal.EngagementHub.PowerfulEmailAnalytics.Title': 'Powerful Email Analytics', // TODO add translations
    'MursionPortal.EngagementHub.PowerfulEmailAnalytics.Summary': 'Measure email open, click, and unread rates to discover insights and make data-driven decisions.', // TODO add translations
    'MursionPortal.EngagementHub.PersonalizeMessaging.Label': 'Add Personal Touch', // TODO add translations
    'MursionPortal.EngagementHub.PersonalizeMessaging.Title': 'Personalize messaging in the Mursion Portal', // TODO add translations
    'MursionPortal.EngagementHub.LearnerDashboard': 'Learner Dashboard', // TODO add translations
    'MursionPortal.EngagementHub.ScenarioPage': 'Scenario Pages', // TODO add translations
    'MursionPortal.EngagementHub.SchedulingPage': 'Scheduling Page', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerFeedbackTooltip': 'This email will only go out if feedback report is enabled at project level. Please reach out to your Mursion representative to learn more.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Title': 'Email Settings', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Summary': 'Use these tools to help customize different emails.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EmailName': 'Email Name', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.SenderName': 'Sender Name (email will be no-reply@mursion.com)', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.ViewCustomize': 'View/Customize', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EnableDisable': 'Enable/Disable', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerLockout': 'Learner Lockout', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.VideoFeedback': 'Video & Feedback', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.ModifySettings': 'Click edit to modify the settings', // TODO add translations
    'MursionPortal.EngagementHub.BackTitle': 'Back to Engagement Hub', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey': 'Communication Is Key!', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.summary': 'A great Mursion rollout starts with effective communication to your learners. It\'s important to ensure they are prepared and understand the connection between Mursion and your learning and development program goals.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.TItle': 'Elements of Effective Learner Communication', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LearnerBenefits.Title': 'Lead with Learner Benefits', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LearnerBenefits.Summary': 'Lead with learner benefits, not product features. Share learner testimonials and tie the skills they are developing to business objectives.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.PracticeProgress.Title': 'Practice makes Progress', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.PracticeProgress.Summary': 'Practice is essential to developing new skills. Ensure your message highlights The Importance of Practice - And Our Reluctance To Do It to acknowledge challenges that learners face.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LeadersMessage.Title': 'Message from Leaders', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LeadersMessage.Summary': 'Ask senior leaders to communicate (meetings, written, video, etc) why Mursion sessions are valuable and how developing the target skills is tied to business priorities and growth opportunities.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.SetExpectations.Title': 'Set Expectations', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.SetExpectations.Summary': 'Mursion is a new technology that uses avatars powered by humans. Prepare learners for these conversations by sharing why and how avatars create psychology safety and is an effective way to practice.', // TODO add translations
    'MursionPortal.Tab.Engagement': 'Engagement', // TODO add translations
    'MursionPortal.Tab.EngagementHub': 'Engagement Hub', // TODO add translations
    'MursionPortal.Tab.EmailManagement': 'Email Management', // TODO add translations
    'MursionPortal.Tab.PortalMessaging': 'Portal Messaging', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.BackButton.Label': 'Back to Invite to Schedule', // TODO add translations
    'MursionPortal.Portal.Messaging.Heading.Text': 'Inform, engage, and delight learners using your organization’s unique brand voice.',  // TODO add translations
    'MursionPortal.Portal.Messaging.SubHeading.Text': 'Add custom messaging throughout the Portal to engage your learners.',  // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.InvitationDate': 'Invitation Date', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.MessageToLearners': 'Message to Learners', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.ExcludedLearners': 'Excluded Learners', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.CreatedBy': 'Created By', // TODO add translations
    'MursionPortal.Portal.EngagementHub.InviteToScheuled.Text': '{scenariosCount} scenarios assigned to {learnerCount} learners', // TODO add translations
    'MursionPortal.Portal.EngagementHub.InviteToScheuled.Text.AddLearners': 'Add Learners', // TODO add translations
    'MursionPortal.Tab.EmailManagement.Analytics': 'Analytics', // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.Teams.Invitation.Message': 'The following team(s) already have scheduled an invitation on this day:', // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.New.Invitation.Message': 'Please make a note and start a new invitation', // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.Learners.Invitation.Text': 'Learners will receive the invitation in the early morning hours (Pacific time) on this day.', // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.SendDate.Label': 'Send Date', // TODO add translations
    'MursionPortal.Scenario.Generation.FilterType.Label' : 'Generation', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.CancelInvitation.Modal.Text': 'Are you sure you want to cancel the invitation created by {createdBy}?', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerFeedbackReport': 'Learner Feedback Report', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.CancellationByLearner': 'Cancellation by Learner', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.MissedSessionByLearner': 'Missed by Learner', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerReleased': 'Learner Released', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerLockOut': 'Learner Lockout', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.SessionConfirmation': 'Session Confirmation', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Invitation': 'Welcome to Mursion - a platform for you to practice your skills', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.InvitationReminder': `Reminder:You're invited to {licenseeName}`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Confirmation': 'Thanks for signing up! Your Simulation is confirmed.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.FirstReminder': `Don't forget! Your Mursion simulation is soon!`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.SecondReminder': `Get excited! Your Mursion simulation starts in {upcomingBeforeStart} minutes.`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.CancellationByByLearner': 'Your simulation was canceled', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.MissedSessionByLearner': 'We missed you today!', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerLockedOut': 'You missed/cancelled this scenario too many times', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerReleased': 'Your session is available to schedule', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerSessionRecordingIsReady': 'Want to see how you did? Visit your Mursion Dashboard.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerFeedbackReport': 'Want to see how you did? Review your session report.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerSessionRecordingReady': 'Learner Session Recording Ready', // TODO add translations
    'MursionPortal.EngagementHub.BackToEmailSettings': 'Back to Email Settings', // TODO add translations
    'MursionPortal.EngagementHub.CustomizeEmail.Text': 'Learners will start receiving this email after you save.', // TODO add translations
    'MursionPortal.NextGen.Learner.FeedbackFrom': 'Feedback from', // TODO add translations
    'MursionPortal.NextGen.Scenario.SimMaterials': 'SIM Materials', // TODO add translations
    'MursionPortal.NextGen.Scenario.LearnerMaterials': 'Learner Materials', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Modal.BodyText': 'Are you sure you want to save the Enable/Disable settings', // TODO add translations
    'MursionPortal.Client.EmailSettings.SessionRescheduledByPS': 'Session Rescheduled by PS', // TODO add translations
    'MursionPortal.EngagementHub.EmailPreview.Sender': 'Sender', // TODO add translations
    'MursionPortal.Scenario.CoverStory.Tooltip.Text': 'Cover Story will be visible to clients in the scenario library as well as learners and SIMs in their materials',// TODO add translations
    'MursionPortal.Scenario.Summary.Tooltip.Text':'Summary will be visible to clients in the scenario library',// TODO add translations
    'MursionPortal.NextGen.Event.positiveMindset': 'There must be at least ({startCount}) positive mindset defined. You can specify a maximum of ({endCount}) positive mindsets.', // TODO add translations
    'MursionPortal.NextGen.Event.derailingMindset': 'There must be at least ({startCount}) derailing mindset defined. You can specify a maximum of ({endCount}) derailing mindsets.', // TODO add translations
    'MursionPortal.NextGen.Event.StrengthAssessment': 'There must be at least ({startCount}) strength assessment defined. You can specify a maximum of ({endCount}) strength assessments.', // TODO add translations
    'MursionPortal.NextGen.Event.OpportunityAssessment': 'There must be at least ({startCount}) opportunity assessment defined. You can specify a maximum of ({endCount}) opportunity assessments.', // TODO add translations
    'MursionPortal.NextGen.Event.StrengthAssessment.AvatarResponse': 'You have added {addedCount} avatar response for strength assessment(s), you must add at least {totalCount} avatar response for strength assessment(s).', // TODO add translations 
    'MursionPortal.NextGen.Event.OpportunityAssessment.AvatarResponse': 'You have added {addedCount} avatar response for opportunity assessment(s), you must add at least {totalCount} avatar response for opportunity assessment(s).', // TODO add translations 
    'MursionPortal.Label.ClientLevelMissCancelSetting': 'Client Level Miss/Cancel Setting', // TODO add translations 
    'MursionPortal.Label.ProjectLevelMissCancelSetting':'Project Level Miss/Cancel Setting', // TODO add translations 
    'MursionPortal.Label.ScenarioLevelMissCancelSetting':'Scenario Level Miss/Cancel Setting', // TODO add translations 
    'MursionPortal.Label.MissedCancelledSessions':'Missed/Cancelled Sessions', // TODO add translations 
    'MursionPortal.EngagementHub.ViewInvitations.Column.SenderName': 'Sender Name', // TODO add translations,
    'MursionPortal.NextGen.Event.Delete.ConfirmBox': 'Are you sure you want to delete this {message}?', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.PositiveMindset': 'Positive Mindset', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.DerailingMindset': 'Derailing Mindset', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.StrengthAssessment': 'Strength assessment', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.OpportunityAssessment': 'Opportunity assessment', // TODO add translations,
    'MursionPortal.PersonalizedMessageTitle': 'Personalized message header. This will appear before every personalized message in Portal.', // TODO add translations
    'MursionPortal.NextGen.ScenarioReferences': 'Scenario References', // TODO add translations 
    'MursionPortal.NextGen.ChildScenarios': 'Child Scenarios', // TODO add translations 
    'MursionPortal.NextGen.Label.Customized': 'Customized', // TODO add translations  
    'MursionPortal.EngagementHub.EmailSettings.InvitationPasswordUsers': 'Invitation (Password Users)', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.InvitationReminderPasswordUsers': 'Invitation Reminder (Password Users)', // TODO add translations
    'MursionPortal.Label.Clicked': 'Clicked', // TODO add translations
    'MursionPortal.Client.EmailSettings.SessionRescheduledByLearner': 'Session Rescheduled by Learner', // TODO add translations
    'MursionPortal.Client.Team.CopyTeamInviteLink.Tooltip':'Users who have not yet registered or have not yet been added to the Portal will not be able to access this link. Please click the "Add Learners" button.', // TODO add translations
    'Settings.Config.LearnerSessionReportDelay': 'Learner Session Report Delay', // TODO add translations
    'MursionPortal.SkillsReferences.Label': 'Skills References', // TODO add translations
    'MursionPortal.ResearchSummariesReferences.Label': 'Research Summaries References', // TODO add translations
    'MursionPortal.Search.Text': 'Search or select', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.Event': 'Please select Event', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.Skill': 'Please select Skill', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.AvatarBehavior': 'Avatar Behavior is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.ExpectedLearnerResponse': 'Expected Learner Response is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.PositiveMindset': 'Please Select Positive Mindset', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.PositiveMindsetDescription': 'Positive mindset is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.DerailingMindset': 'Please Select Derailing Mindset', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.DerailingMindsetDescription': 'Derailing mindset is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.DescribeObservedAction': 'Describe Observed Action is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.ExplainImpact': 'Explain Impact is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.AvatarName': 'Please select avatar name', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.PossibleAvatarResponse':'Possible Avatar Response is required', // TODO add translations
    'MursionPortal.Label.SchedulingLockoutDate': 'Scheduling Lockout Date', // TODO add translations
    'MursionPortal.Label.SchedulingLockoutDate.Tooltip': 'Lockout effective date for all learners who miss and/or late cancel', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.MinimumScore': 'You’ve changed the number of events. Please update the minimum score.', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.SynopsisDescription': 'Synopsis description is required', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.SuccessfulAssessment': 'Successful Assessment is required', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.OpportunityAssessment': 'Opportunity Assessment is required', // TODO add translations
    'MursionPortal.NextGen.Scenario.LibraryInfo.PathwaysAffiliation': 'Pathway Affiliation(s)', // TODO add translations
    'MursionPortal.NextGen.BankScenarioReferences': 'Bank Scenario References', // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerActivationStatus':'Learner Activation',  // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerInviteTitle':'Unlock Your Team’s Potential',  // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerInviteMessage':'Target invitations to your learners with custom messaging',  // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerInvite':'Invite to Schedule',  // TODO add translations
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.NotYetRegistered':'Not Yet Registered', // TODO add translations
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.NotYetScheduled':'Not Yet Scheduled', // TODO add translations
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.ScheduledNotYetCompleted':'Scheduled - Not Yet Completed', // TODO add translations
    'MursionPortal.NextGen.ViewAndInviteLearners': 'View & Invite Learners', // TODO add translations
    'MursionPortal.Dashboard.Tooltip.Label.SchedulingEmail': 'Session Specific Emails and other Join Session Emails', // TODO add translations
    'MursionPortal.Dashboard.EmailAnalytics.Label.InviteToScheduleEmail': 'Invite-to-Schedule Emails', // TODO add translations
    'MursionPortal.Dashboard.Tooltip.Label.CallToActionEmail': 'Emails sent to learners inviting them to schedule scenario(s)', // TODO add translations
    'MursionPortal.NextGen.Scenario.ClientCustomizations': 'Client Customizations', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.InviteToSchedule.Tooltip': 'Invite to Schedule emails are able to be customized each time you send them.', // TODO add translations
    'MursionPortal.Contract.ContractTimezone': 'Contract Timezone', // TODO add translations
    'Dashboard.LeftPane.Simulations.SessionSearch':'Search for Session', // TODO add translations
    'MursionPortal.NextGen.Scenario.ClientCustomizations.ClientScenarioTitle': 'Client Scenario Title', // TODO add translations
    'MursionPortal.NextGen.Scenario.ClientCustomizations.ClientScenarioTitle.Tooltip': 'Client learners and program admins will see this title only. SIMs will see the bank scenario title and client scenario title in their SIM materials.', // TODO add translations
    'MursionPortal.NextGen.Event.DeleteMessage': 'Minimum 2 Events are mandatory for a Scenario.', // TODO add translations
    'Settings.Config.ProjectAndScenario.AllowSimPracticeSession': 'SIM Practice Session', // TODO add translations
    'MursionPortal.Calendar.Button.Practice.Session': 'Practice Session', // TODO add translations
    'MursionPortal.Scenario.InTrainingSimSpecialist': 'In Training SIM Specialists', // TODO add translations
    'MursionPortal.Scenario.NoInTrainingSimSpecialist': 'No In Training Sim Specialist', // TODO add translations
    'MursionPortal.Simspecialist.Thankyou.summary': 'You are scheduled for your practice simulation', // TODO add translations
    'MursionPortal.Button.DoNotSaveMyWork': 'Don’t Save my Work', // TODO add translations
    'MursionPortal.NextGen.Confirm.BrowserBack': 'You have not saved your changes, and navigating away will cause you to lose your work. Please complete required fields.', // TODO add translations
    'MursionPortal.UserConsent.Learner.Description': 'Se dici "Acconsento", il software Mursion elaborerà la tua simulazione dal vivo ed effettuerà una registrazione video della sessione di simulazione per la tua revisione nel portale del software Mursion. Ad eccezione di quanto richiesto dalla legge applicabile o per scopi di garanzia della qualità, Mursion non distribuirà o divulgherà la registrazione video senza la tua autorizzazione. Mursion utilizzerà i dati resi anonimi derivanti dal tuo utilizzo del suo software per migliorare la sua tecnologia di simulazione.', // TODO add translations, this translation was generated using the Google Translate, retranslation required in the future 
    'MursionPortal.UserConsent.LearnerAndAccountOwnerOrFacilitator.Description': 'Se dici "Acconsento", il software Mursion elaborerà la tua simulazione dal vivo ed effettuerà una registrazione video della sessione di simulazione per la tua revisione nel portale del software Mursion. Tieni presente che, su richiesta del cliente che ha concesso in licenza il software Mursion per tuo conto, una copia delle sessioni di simulazione registrate sarà condivisa con un coach, mentore o revisore selezionato dal cliente. Tutti i revisori sono contrattualmente obbligati a seguire l\'<code>informativa sulla privacy di Mursion</code>, a meno che tu non abbia accettato termini sulla privacy diversi. Ad eccezione di quanto richiesto dalla legge applicabile o per scopi di garanzia della qualità, Mursion non distribuirà o divulgherà la registrazione video senza la tua autorizzazione. Mursion utilizzerà i dati resi anonimi derivanti dal tuo utilizzo del suo software per migliorare la sua tecnologia di simulazione.', // TODO add translations, this translation was generated using the Google Translate, retranslation required in the future
    'MursionPortal.UserConsent.AccountOwnerOrFacilitator.Description': 'Le tue sessioni di simulazione verranno registrate sia in audio che in video su richiesta del cliente che ha concesso in licenza il software Mursion per tuo conto. Se acconsenti a procedere, una copia delle sessioni di simulazione registrate verrà condivisa con una terza parte selezionata dal cliente al fine di analizzare e valutare le registrazioni. Questi individui sono contrattualmente obbligati a seguire l\'<code>informativa sulla privacy di Mursion</code>, a meno che tu non abbia accettato termini sulla privacy diversi. Tuttavia, non riceverai né avrai accesso a una copia delle tue sessioni di simulazione registrate secondo i termini dell\'incarico o della valutazione che hai accettato di completare. Mursion può utilizzare la tua registrazione video per scopi di controllo della qualità e utilizzerà dati anonimi derivanti dal tuo utilizzo del suo software per migliorare la sua tecnologia di simulazione.', // TODO add translations, this translation was generated using the Google Translate, retranslation required in the future
    'MursionPortal.ProjectCreate.DeliveryHours.Validation': 'Delivery Hours Start time should be less than End time.', // TODO add translations
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.NotYetRegistered':'Unique number of learners who have been added to the Portal but have not yet completed registration', // TODO add translations
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.NotYetScheduled':'Unique number of learners who have registered but have not yet scheduled a session', // TODO add translations
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.ScheduledNotYetCompleted':'Unique number of learners who have registered and scheduled at least one session but have not yet completed a session', // TODO add translations 
    'MursionPortal.Restriction': 'Restriction', // TODO add translations
    'MursionPortal.Dashboard.SessionSearch.Placeholder' : 'Paste a session ID and hit return',  // TODO add translations
    'Operations.TechRestriction.CreateDialog.Title': 'New Holiday Tech Restriction', // TODO add translations
    'Operations.TechRestriction.EditDialog.Title': 'Edit Holiday Tech Restriction', // TODO add translations
    'Operations.TechRestriction.ViewDialog.Title': 'View Holiday Tech Restriction', // TODO add translations
    'MursionPortal.Client.EmailSettings.TriggerHours': 'Trigger (Hours)', // TODO add translations
    'MursionPortal.Client.EmailSettings.TriggerMin': 'Trigger (Minutes)', // TODO add translations
    'MursionPortal.Duplicate.Scenario.Confirmation.Text': 'Are you sure you want to duplicate this scenario?',  // TODO add translations 
    'MursionPortal.Contract.LineItem.ToolTip': 'A contract can only contain one type of line item. If your contract has both sessions and hours, please convert the sessions into hours and add it as an hours line item.', // TODO add translations
    'MursionPortal.ReflectionGuide.LearnerPerspective': 'Learner Perspective', // TODO add translations
    'MursionPortal.Dashboard.SessionSearch.Table.Placeholder': 'Search for Session will load once you search session ID', // TODO add translations
    'MursionPortal.Dashboard.SessionSearch.EmptySession': 'No sessions match that ID', // TODO add translations
    'MursionPortal.SimMaterials.Label.IntroAndPreReflection': 'Intro & Pre-Reflection', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.LearnerBreakdown': 'Learner Breakdown', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.LearnerBreakdown.Activated': 'Activated', // TODO add translations
    'MursionPortal.NextGen.Scenario.LibraryInfo.VersionNumber': 'Version Number', // TODO add translations
    'MursionPortal.NextGen.Scenario.LibraryInfo.VersionDetails': 'Version Details', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EmailReminderHours': 'Trigger {tiggerValue} hours before Session.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EmailReminderMinutes': 'Trigger {tiggerValue} minutes before Session.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.FirstEmailReminder.Tooltip': 'Time before Session when First Reminder would go. If not set Licensee Setting would be used.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.SecondEmailReminder.Tooltip': 'Time before Session when Second Reminder would go. If not set Licensee Setting would be used.', // TODO add translations
    'MursionPortal.SimAttendance.MissedSimulation.Message': 'You missed this simulation so you are unable to edit attendance. Please contact Support if you did not miss the simulation or had a technical issue that caused you to miss the simulation.', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Disclaimer': 'Invites are visible for last 30 days only', // TODO add translations
    'MursionPortal.PortalVersion': 'Portal Version', // TODO add translations
    'MursionPortal.ScenarioInfo.Label.AutoSIM': 'AutoSIM', // TODO add translations
    'MursionPortal.ProjectContract.AddConfirmation': 'Are you sure you want to add the contract to this project?', // TODO add translations
    'MursionPortal.ProjectContract.CompletedContract.AddConfirmation': 'Are you sure you want to add the completed contract to this project?', // TODO add translations
    'MursionPortal.Label.NewPortal' : 'New Portal', // TODO add translations
    'MursionPortal.Label.OldPortal' : 'Old Portal', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.ReminderValidation': 'Second Reminder should be less than First Reminder', // TODO add translations
    'MursionPortal.Project.InviteToSchedule.EmailDisabled.Tooltip' :'Invite to Schedule is disabled as email settings are disabled. Enable them to send this invite', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimReportedError' :'SIM Reported Error (Does not impact session status)', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimLateOrNoShow' :'SIM late or no show', // TODO add translations
    'MursionPortal.SimAttendance.Error.SessionStartedTooEarly' :'Session started too early', // TODO add translations
    'MursionPortal.SimAttendance.Error.AvatarIssue' :'Avatar Issue', // TODO add translations
    'MursionPortal.SimAttendance.Error.MouthlipSyncIssueDuringSession' :'Mouth/lip sync issue during session', // TODO add translations
    'MursionPortal.SimAttendance.Error.Hardware' :'Hardware', // TODO add translations
    'MursionPortal.SimAttendance.Error.AudioVideoIssueForSim' :'Audio/video issue for SIM', // TODO add translations
    'MursionPortal.SimAttendance.Error.AudioVideoIssueForLearner' :'Audio/video issue for Learner', // TODO add translations
    'MursionPortal.SimAttendance.Error.OtherSimHardwareIssues' :'Other SIM hardware issues (controller, laptop, etc.)', // TODO add translations
    'MursionPortal.SimAttendance.Error.LearnerAndSimBothJoined' :'Learner and SIM both joined, but no connection established', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimMomentIssue' :'SIM Moment issue', // TODO add translations
    'MursionPortal.SimAttendance.Error.WifiIssue' :'Wifi Issue', // TODO add translations
    'MursionPortal.SimAttendance.Error.LearnerWifi' :'Learner Wifi', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimWifi' :'SIM Wifi', // TODO add translations
    'MursionPortal.SimAttendance.Error.AdditionalDetails' :'Additional Details', // TODO add translations
    'MursionPortal.SimAttendance.Error.DescribeYourError' :'Describe your error...', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.CancellationByProfessionalServiceOrAccountOwner': 'Cancellation by PS or AO', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.CompleteLearnerSurvey': 'Complete Learner Survey', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.CompleteLearnerSurvey': 'How\'d it go?', // TODO add translations
    'MursionPortal.CustomizeInvitation.ButtonText.Label': 'Button Text', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Client.LearnerLockedOut': 'You are locked out!', // TODO add translations
    'MursionPortal.Filter.ReleaseLevel.Placeholder': 'Release Level', // TODO add translations
    'MursionPortal.TOTP.Page.Heading': 'We just sent a security code to your {email}. Enter the code to sign in.', // TODO add translations
    'MursionPortal.TOTP.ResendOTP.Text': 'Resend Code', // TODO add translations
    'MursionPortal.TOTP.Code.Label': 'Code', // TODO add translations
    'MursionPortal.TOTP.IncorrectCode.ErrorMsg': 'The code is incorrect. Enter the correct code.', // TODO add translations
    'MursionPortal.TOTP.ResendNewCode.ErrorMsg': 'The code has expired, click "Resend" to receive a new code.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Client.LearnerReleased': 'You are ready to schedule again.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.FirstReminder': `You’ve an upcoming Mursion Session in {firstReminderTime} hours!`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.SecondReminder': `Your Mursion Session is starting in {upcomingBeforeStart} minutes!`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.LearnerFeedbackReport': 'Mursion - Review Your Session Performance Report.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.LearnerSessionRecordingIsReady': 'Your Session Recording is now up! Review how you did', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.Confirmation': 'Your Mursion Session is Confirmed!', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.CancellationByLearnerOrPSOrAO': 'Your {licenseeName} session was canceled', // TODO add translations
    'MursionPortal.Personalized.PathwayPage.Section': 'Message you want learners to see on Pathway Page', // TODO add translations
    'MursionPortal.Personalized.CreateNewPathway.Button.Label': 'Create new for specific pathway', // TODO add translations
    'Projects.ScenarioList.NoPathwaysFound': 'No Pathways have been found.',// TODO add translations
    'MursionPortal.Personalized.Pathway.Search.Placeholder': 'Search or select Pathway(s):',// TODO add translations
    'Mursion.Portal.Status.SimTechnicalError': 'Sim Technical Error',// TODO add translations
    'MursionPortal.SimAttendance.Sim.Tooltip.Error': 'Session was unable to be completed due to technical difficulties on the Sim’s side. (if the session was able to be completed after an error this session should be marked complete and not error).',// TODO add translations
    'Session.Edit.Modal.Notes.BlankNoteValidation': 'Either add a valid note or remove this block', // TODO add translations
    'MursionPortal.Scenario.Error.Msg': 'Please complete required field.', // TODO add translations
    'MursionPortal.Scenario.Skills.Error.Msg': 'The number of skills must be in the range of {minSkills} and {maxSkills}.', // TODO add translations
    'MursionPortal.Capitalized.Incomplete': 'INCOMPLETE', // TODO add translations
    'MursionPortal.Dashboard.Chart.LicenseBreakdown.Title': 'License Breakdown – Contract Item',  // TODO add translations
    'MursionPortal.Dashboard.Chart.LearnerLicenses': 'Learner Licenses',  // TODO add translations
    'MursionPortal.Dashboard.Chart.LearnersRegistered': 'Learners Registered',  // TODO add translations
    'MursionPortal.Dashboard.Chart.LearnersScheduled': 'Learners Scheduled',  // TODO add translations
    'MursionPortal.SessionAttendance.AddAttendees': 'Add attendees',  // TODO add translations
    'MursionPortal.SessionAttendance.SimulationSpecialist': 'Simulation Specialist',  // TODO add translations
    'MursionPortal.Dashboard.SimulationSpecialist.Status': 'Simulation Specialist Status',  // TODO add translations
    'MursionPortal.Dashboard.Technical.Issue.Summary': 'If a technical issue occurred during this session, please indicate the type(s) of issue encountered:',  // TODO add translations
    'MursionPortal.Dashboard.Provide.Details': 'Please provide more details on what happened',  // TODO add translations
    'MursionPortal.SessionAttendance.SwitchToSimRole.Message': 'Please switch role to Sim Specialist to view the page!', // TODO add translations
    'MursionPortal.SessionAttendance.Heading': '<code>Instructions:</code> Review the Session, Simulation Specialist, and Learner Status\' below and update as required. This form may be edited for 24 hours after the end of the session', // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.Label': 'This is automatically determined by the Learner and Simulation Specialist status. No action required.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionDetails.Heading': '(selections below do not impact session status)', // TODO add translations
    'MursionPortal.SessionAttendance.SessionDetails.Instructions': '<code>Instructions:</code> Session details <code>must</code> be provided for any session that has a Learner Status other than ‘Complete’ or ‘No Show’. To complete, select one or more issue type(s) and describe details in the text box. Session detail information does not impact the Learner, Simulation Specialist, or Session status.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionDetails.Text': `Mursion Simulation Specialists only: If you filled out a UTS ticket for this session, please include the link to the Slack post in the text box (click the 3 vertical dots to the right of the post and select ‘Copy link').`, // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.Simspecialist.Label': 'This is automatically determined by the system. If you encountered an error that prevented the session from being completed and the indicated status is ‘Left’, select ‘Technical Error (SIMS)’. Otherwise, no action required.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.Learner.Label': 'This is automatically determined by the system. If the indicated status is inaccurate, select the correct status and explain the reason for the revision in Session Details. Otherwise, no action required.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.SimStatus.Label': 'Technical Error (SIMs)', // TODO add translations
    'Mursion.Portal.Status.UnableToComplete': 'Unable to Complete', // TODO add translations
    'Mursion.Portal.Status.ElectedToLeave': 'Elected to Leave', // TODO add translations
    'Mursion.Portal.Status.TechnicalErrorLearner': 'Technical Error (Learner)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.Audio': 'Audio', // TODO add translations
    'MursionPortal.SessionAttendance.Error.ConnectionInternet': 'Connection / Internet', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerTechnicalIssues': 'Learner technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.MursionTechnicalIssues': 'Mursion technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.OtherTechnicalIssues': 'Other technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.MomentSoftware': 'Moment Software', // TODO add translations
    'MursionPortal.SessionAttendance.Error.AvatarOrEnvironmentLipSyncMovementEtc': 'Avatar or Environment (lip sync, movement, etc.)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.HardwareControllerLaptopEtc': 'Hardware (controller, laptop, etc.)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerAndSimulationSpecialistBothJoined': 'Learner and Simulation Specialist both joined, no connection established', // TODO add translations
    'MursionPortal.SessionAttendance.Error.OtherPleaseExplainBelow': 'Other (please explain below)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.NonTechnicalIssues': 'Non-technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerMaterialsOrPreparation': 'Learner materials or preparation ', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerLanguageFluency': 'Learner language fluency', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerElectedToLeave': 'Learner elected to leave', // TODO add translations
    'MursionPortal.Dashboard.Overview.Empty.Body.Message': 'Contract report will load once you make a selection', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdown.ContractItem.Simulation':'Session Breakdown – Simulation Contract Item', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdown.ContractItem.Hour':'Session Breakdown – Hour Contract Item', // TODO add translations
    'MursionPortal.Copy.Tooltip.Text': 'Copy (plain text only, no formatting)', // TODO add translations
    'MursionPortal.RTF.DropDown.Text.Green': 'Green', // TODO add translations
    'MursionPortal.RTF.DropDown.Text.Thick': 'Thick', // TODO add translations
    'MursionPortal.Status.Connected.Label': 'Connected',  // TODO add translations
    'MursionPortal.Status.Immersing.Label': 'Immersing',  // TODO add translations
    'MursionPortal.Dashboard.NonTechnical.Issue.Summary': 'If a non-technical issue occurred during this session, please indicate the type(s) of issue encountered:',  // TODO add translations
    'MursionPortal.AutoSim.Scenario.TooltipLabel': 'AutoSim scenario can’t be scheduled.', // TODO add translations
    'MursionPortal.Scenario.Planing.AutoAssignTeam': 'Auto-Assign Team', // TODO add translations
    'MursionPortal.Scenario.Planing.AutoAssignTeam.Tooltip': 'Automatically assign this scenario to the selected teams when learners access the scenario page via the provided link.', // TODO add translations
    'MursionPortal.Dashboard.Overview.NoAssociatedDataForSelectedContract': 'No associated data for the selected contract(s)', // TODO add translations
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.LevelOne': 'Level 1', // TODO add translations
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.LevelTwo': 'Level 2', // TODO add translations
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.LevelThree': 'Level 3', // TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Title': 'Make sure you’re ready to take the session by testing your connection before joining.',// TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Button.Label': 'Test Connection',// TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Support.Link.Title': 'If any test fails, copy results at the end of the test and share them with our support team by',// TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Support.Link': 'Submitting a Support ticket.',// TODO add translations
    'MursionPortal.Dashboard.OnDemand.Text':'Ready, set, practice on-the-go',
    'MursionPortal.Dashboard.OnDemand.Title':'Try On-Demand Simulations',
    'MursionPortal.Dashboard.OnDemand.SubTitle':'Get a first look at the future of skill-building at work - dynamic GenAI simulations available instantly, no scheduling required. Practice anytime and receive actionable feedback that helps you grow and master new skills.',
    'MursionPortal.Dashboard.OnDemand.SmallTitle':'Early access, real results—get ahead now.',
    'MursionPortal.Dashboard.OnDemand.Button.Label':'Go to Mursion On-Demand',
    'MursionPortal.Dashboard.OnDemand.Button.Hide.Label':'Hide this',
    'MursionPortal.Dashboard.OnDemand.Preview':'PREVIEW',
    'MursionPortal.Dashboard.OnDemand.Text1':'✓ Scalable, focused practice',
    'MursionPortal.Dashboard.OnDemand.Text2':'✓ Flexible access',
    'MursionPortal.Dashboard.OnDemand.Text3':'✓ Targeted practice for mastery',
    'MursionPortal.Dashboard.OnDemand.Text4':'✓ Guided self-reflection',
    'MursionPortal.Dashboard.OnDemand.Mursion':'Mursion',
  },
};
export default italianLangTranslations;
