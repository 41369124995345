import { LOCALES } from 'src/i18n/locales';
import { TLocaleId } from 'src/i18n';

const japaneseLangTranslations: { [key: string]: { [idKey in TLocaleId]: string } } = {
  [LOCALES['日本語 (Japanese)']]: {
    'MursionPortal.SimAttendance.Tooltip.Error': 'Session was unable to be completed due to technical difficulties on the Learner’s side. (if the session was able to be completed after an error this session should be marked complete and not error).',// TODO add translations
    'SessionReports.ErrorOnLoadingVideo': '録画したものを見るには、ノートパソコンやデスクトップパソコンをご利用ください。',
    'MursionPortal.ConfirmationModal.Project.InvitationsConfirmationText': 'プロジェクトでシナリオを完了していない<strong>割り当てられたすべての学習者</strong>を招待してもよろしいですか？彼らには、一晩のスケジュールを決めるための招待メールが届きます。',
    'MursionPortal.ConfirmationModal.Scenario.InvitationsConfirmationText': 'まだこのシナリオを完了していない<strong>割り当てられたすべての学習者</strong>を招待してもよろしいですか？彼らには、一晩のスケジュールを決めるための招待メールが届きます。',
    'Clients.Modal.Label.VideoRecordingDataRetentionDays.Tooltip': 'お客様が録画データの保存を希望される日数です。この日数が経過すると、録画データは削除されます。',
    'Settings.SSO.CopyScenarioLink': 'シナリオの招待リンクをコピーする',
    'MursionPortal.Label.VideoRecordingSharing': 'ユーザーによるセッション動画の共有・ダウンロード',
    'MursionPortal.Status.Capitalized.LateCancelled': '最新の_キャンセル',
    'MursionPortal.Status.Capitalized.EarlyCancelled': '早い_キャンセル',
    'MursionPortal.Status.Abbreviation.LateCancelled': 'LC',
    'MursionPortal.Status.Abbreviation.Cancelled': 'C',
    'MursionPortal.Text.Congratulation': 'おめでとうございます。',
    'MursionPortal.Text.PasswordChanged': 'パスワードが変更されました',
    'MursionPortal.Text.PasswordShouldBeSame': '新しいパスワードは一致させる必要があります。',
    'Filters.LateRescheduled': '遅れて再スケジュール済',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Scheduled': '予定されているセッションのうち、まだ終了していないもの（実行中のものを含む）',
    'MursionPortal.Checkbox.EnableSmartMetrics': 'セッション学習者の音声分析を有効にする',
    'Dashboard.SimulationTable.Column.TimesLateRescheduled': '遅れて再スケジュールされた回数',
    'Dashboard.SimulationTable.ColumnTooltip.TimesLateRescheduled': 'このシミュレーションが遅れて再スケジュールされた回数',
    'SessionReports.Label.CopyVideoLink': '動画リンクをコピーする',
    'Login.LoginAgain': '再ログイン',
    'Dashboard.VideosTable.Column.SessionStart': 'セッション開始',
    'Filters.Date.LastSevenDays': '先週',
    'Filters.Date.NextSevenDays': '来週',
    'Filters.Date.ThisYear': '今年',
    'Mursion.Portal.Status.LearnerMissed': '学習者の欠席',
    'Mursion.Portal.Status.SystemCancelled': 'キャンセル済',
    'Mursion.Portal.Status.LateCancelled': '最新のキャンセル',
    'Mursion.Portal.Status.EarlierCancelled': '早いキャンセル',
    // 'MursionPortal.Dashboard.Chart.label.LearnerStatus.LearnerCompleted': '完了済',
    'MursionPortal.Dashboard.UpcomingSessions': '今後のセッション',
    'MursionPortal.Label.Opened': '開封済',
    'MursionPortal.Label.ActedOn': '実施済',
    'MursionPortal.Label.Unread': '未読',
    'MursionPortal.Label.Timeline': 'プロジェクトタイムライン',
    'Projects.ProjectInfo.Label.TimelineUtc': 'プロジェクトタイムライン (UTC)',
    'MursionPortal.ScenarioInfo.Header.Description': '説明',
    'MursionPortal.TimelineUTC': 'プロジェクトタイムライン<code>{word}</code> (UTC)',
    'MursionPortal.Label.ChangePassword': 'パスワードの変更',
    'MursionPortal.Label.ChangeYourPassword': 'ご利用のパスワードの変更',
    'MursionPortal.Label.NewChangePassword': '新しいパスワードを入力する',
    'MursionPortal.Label.OldPassword': '現在のパスワードを入力する',
    'MursionPortal.Label.RepeatChangePassword': '新しいパスワードを再入力してください',
    'MursionPortal.Prompt.SessionScheduled': '<code>セッションはキャンセルされました!',
    'MursionPortal.Status.Booked': '予約済',
    'MursionPortal.Status.Pending': '保留',
    'MursionPortal.Completed': '完了済',
    'MursionPortal.Status.Missed': '欠席',
    'MursionPortal.Status.Cancelled': 'キャンセル済',
    'MursionPortal.Status.LateCancelled': '最新のキャンセル',
    'MursionPortal.Status.EarlyCancelled': '早いキャンセル',
    'MursionPortal.Status.LicenseCancelled': 'キャンセル済',
    'MursionPortal.Status.Error': 'エラー',
    'MursionPortal.ConversationalFlow.label.zoom.all': 'すべて',
    'Clients.ImportUsers.DownloadErrorsButton': 'ダウンロード エラー',
    'Mursion.Portal.Edit.Team.DownloadErrorButton': 'ダウンロード エラー',
    'MursionPortal.Dashboard.NextOnProject': 'スケジュールへのシナリオ',
    'MursionPortal.Dashboard.NextOnProject.Footer.EndDate': 'このシナリオは、{endDate}までに完了させてください。',
    'MursionPortal.Dashboard.NextOnProject.Footer.CompletionRate': '{endDate}までに、{completionRate}回までを完了させることができます。',
    'MursionPortal.Dashboard.NothingPlanned.text1': '追いつかれました！スケジュールが',
    'MursionPortal.Dashboard.NothingPlanned.text2': 'ある場合は、ここに表示されます。',
    'MursionPortal.Learner.Missed': '学習者の欠席',
    'MursionPortal.AriaImage.Attribute.Next': '次へ',
    'MursionPortal.AriaImage.Attribute.Close': '閉じる',
    'MursionPortal.AriaImage.Attribute.Back': '戻る',
    'MursionPortal.AriaImage.Attribute.Delete': '削除してください。',
    'MursionPortal.AriaImage.Attribute.Remove': '削除',
    'MursionPortal.AriaImage.Attribute.Document': 'ドキュメント',
    'MursionPortal.AriaImage.Attribute.Video': '動画',
    'MursionPortal.AriaImage.Attribute.DeleteDocument': 'ドキュメントを削除する',
    'MursionPortal.ScenarioInfo.CompletionRate': '{コンプリート率｝回数',
    'MursionPortal.Button.RefreshPage': 'ページ更新',
    'MursionPortal.Prompt.CalendarUpdatedOutdatedNotification': '本当に終了しますか？カレンダーページが古いままになってしまいます。',
    'Reports.SessionListHeader.Label.AverageTime': '<code>各セッション</code>の平均時間。',
    'MursionPortal.Users.Archive.HasSessions': 'このSimulation Specialistには1つ以上のセッションが予定されているため、Simulation Specialistをアーカイブすることはできません。SIM Specialistを入れ替えるか、変更してください。',
    'MursionPortal.Label.InactiveSIMCertification.Tooltip': 'このシミュレーションで認証されたSimがあっても、新しいシミュレーションに利用できない場合に使用します。',
    'MursionPortal.Label.InactiveSIMCertification': '非アクティブSimの認証',
    'Dashboard.Filters.FilterBySessionDate': 'セッションの日付でフィルタリングする',
    'Dashboard.LeftPane.CategoryHeader.Emails': 'EMAILS',
    'Projects.ProjectInfo.Label.CancellationDeadline.Tooltip': 'これは、プロジェクト内のシミュレーションに登録した後、学習者がキャンセルしなければならない日数/時間/分です。学習者が期限内にキャンセルした場合、そのセッションは課金上、完了したものとみなされます。',
    'Projects.ProjectInfo.Checkbox.AllowSessionRecording': 'セッションの録画を許可する',
    'MursionPortal.Label.SendRecordedVideosTo': '録画した動画を送信する。',
    'MursionPortal.Checkbox.AllowLearnerToRefuseRecording': '受講者が録画に同意しない場合、セッションに参加することを許可します。',
    'Projects.ProjectInfo.SchedulingInterval': 'スケジュール決定の間隔',
    'Projects.ProjectInfo.SchedulingInterval.Tooltip': 'セッションの時間以外の周期でスケジューリングできるようになります。',
    'Projects.ProjectInfo.SchedulingIntervalLength': 'スケジュール決定の間隔の長さ',
    'Projects.ProjectInfo.SchedulingIntervalLength.tooltip': '学習者が確認するスケジュールブロックの頻度を決めます。15に設定すると、学習者は15分間隔でスケジュールブロックを確認することになります。セッションの期間は設定されませんが、セッションの実行頻度のみが設定されます。',
    'Projects.ProjectInfo.DeliveryMode.tooltip': '学習者が使用するプラットフォームです。Mursion Magicおよび/またはML3直接接続ソフトウェアを有効にする場合は、ML3に設定します。ML3z = Zoomです。Mursion Meet = Google Meetです。',
    'MursionPortal.Label.WebOnly': 'ウェブのみ',
    'MursionPortal.Label.DesktopOnly': 'デスクトップのみ',
    'MursionPortal.Label.DesktopAndWeb': 'デスクトップとウェブ',
    'MursionPortal.EmailCategory.CallsToAction': 'コール・トゥ・アクション',
    'MursionPortal.Dashboard.EmailAnalytics.Label.Sent': '送信',
    'MursionPortal.Dashboard.EmailAnalytics.Label.AllEmails': 'すべてのメール',
    'MursionPortal.Dashboard.EmailAnalytics.Label.Trends': 'トレンド',
    'MursionPortal.Dashboard.EmailAnalytics.Label.CategorySummary': 'カテゴリー別サマリー',
    'MursionPortal.Dashboard.EmailAnalytics.Label.RegistrationEmail': '登録メール',
    'MursionPortal.Dashboard.EmailAnalytics.Label.CallToActionEmail': 'コール・トゥ・アクション・メール',
    'MursionPortal.Dashboard.EmailAnalytics.Label.SchedulingEmail': 'メールのスケジューリング',
    'MursionPortal.Dashboard.EmailAnalytics.Label.ReSchedulingEmail': 'メールの再スケジュール',
    'MursionPortal.Dashboard.EmailAnalytics.Label.VideoEmail': '動画メール',
    'MursionPortal.Dashboard.EmailAnalytics.Label.EmailSent': '送信済メール',
    'MursionPortal.Dashboard.EmailAnalytics.Info.EmailAnalytics': 'メール分析では、学習者が<link>Mursion</link>からのメールによるコミュニケーションにどのように関与しているかの概要を知ることができます。メールは、次の3つのカテゴリのいずれかに分類されます。',
    'MursionPortal.Dashboard.EmailAnalytics.Info.EmailOpened': '送信済メールを学習者が1通以上開封しました。',
    'MursionPortal.Dashboard.EmailAnalytics.Info.EmailActedOn': '学習者がメールを開き、<strong>スケジュール</strong>や<strong>シミュレーションに参加する</strong>などのリンクを1つ以上クリックした場合です。',
    'MursionPortal.Dashboard.EmailAnalytics.Info.Unread': '学習者がメールを開封していない。未読メールが大量にある場合、学習者がメールを受信しているかどうか確認してください。<color>no-reply@mursion.com</color> が組織のスパムフィルターにブロックされていないか、IT部門に確認してください。',
    'MursionPortal.Dashboard.Tooltip.RegisterEmail': 'ユーザーに対するMursionアカウントの作成を促す電子メール',
    'MursionPortal.Dashboard.Tooltip.CallToActionEmail': 'プロジェクトページやシナリオページからAccount Ownerが送信するリマインダーメール',
    'MursionPortal.Dashboard.Tooltip.SchedulingEmail': 'セッション専用メール。スケジュールへの招待とその他のセッションへの参加メール',
    'MursionPortal.Dashboard.Tooltip.ReschedulingEmail': 'シミュレーションを欠席またはキャンセルした後、受講者に再スケジュールを促すメール',
    'MursionPortal.Dashboard.Tooltip.VideoEmail': 'シミュレーションの動画が視聴可能になったことを学習者に知らせる電子メール',
    'MursionPortal.Dashboard.EmailCategoryFilter': 'メールカテゴリー',
    'MursionPortal.Dashboard.EmailCategoryFilter.Search': 'メールカテゴリの検索...',
    'MursionPortal.Dashboard.EmailTypeFilter.SessionRescheduled': 'セッションの日程変更',
    'MursionPortal.Dashboard.EmailTypeFilter.Search': 'メールの種類を検索する...',
    'MursionPortal.Text.NoImageAvailable': '画像がありません',
    'Mursion.Portal.Edit.Team.Instruction.ExportFile': '下の<strong>現在のメンバーシップをダウンロード</strong>をクリックして、学習者のチーム割り当てを更新してください (<strong>edit_learner_team_memberships.xlsx</strong>)。',
    'Mursion.Portal.Edit.Team.DownloadExcelTemplate': '現在のメンバーシップをダウンロード',
    'MursionPortal.Project.SameDay': '当日',
    'MursionPortal.Project.CancelationDeadline.Validation': 'キャンセルの期限は0以上でなければなりません。',
    'ClientUserTable.AddNewUsers.RolesHeading': '一度に追加できるのは、1種類のユーザーだけです。追加するユーザーの種類を選択します。',
    'ClientUserTable.AddNewUsers.Roles.AccountOwner.Tooltip': 'Account Ownerは、お客様の組織全体の主要な管理者です。',
    'ClientUserTable.AddNewUsers.Roles.Facilitator.Tooltip': 'Facilitatorは、特定のチームを管理するチームレベルの管理者です。',
    'ClientUserTable.AddNewUsers.Roles.Learner.Tooltip': '学習者とは、Mursionのシミュレーションに参加する人のことです。これがユーザーの大半を占めることになります。',
    'ClientUserTable.AddNewUsers.Heading': '新しいユーザーを追加する',
    'ClientUserTable.AddNewUsers.Roles.Heading': 'ユーザーにロールを割り当てる',
    'ClientUserTable.AddNewUsers.ImportUsers': '500名までのユーザーをインポートする場合は、以下のステップを完了してください。500名以上のユーザーをインポートする場合は、「<code>ユーザーのインポート</code>」機能をご利用ください。',
    'ClientUserTable.AddNewAttributes.label': 'ユーザー属性の追加',
    'ClientUserTable.AddNewAttributes.Header.Text': 'これらの属性は、このリストに含まれるすべてのユーザーに適用されることに注意してください。',
    'ClientUserTable.UserAuthentication.label': 'ユーザー認証',
    'ClientUserTable.NewUsersAdded.Heading': '新しいユーザーを追加しました。',
    'ClientUserTable.AddNewUsers.AssignTeams.Heading': 'ユーザーにチームを割り当てる',
    'ClientUserTable.AddNewUsers.AssignTeams.CreateTeam': '学習者と Facilitatorは、チームに所属する必要があります。まだチームを作成していない場合は、<code>こちらをクリックしてください。</code>',
    'ClientUserTable.AddNewUsers.AssignTeams.SelectTeams': 'チーム名',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Validation': '500名以下のユーザーを追加してください。500名以上のユーザーをインポートする場合は、「ユーザーのインポート」機能をご利用ください。',
    'ClientUserTable.AddNewUsers.AssignTeams.Validation': '少なくとも1つのチームを選択する必要があります。',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Heading': 'ユーザーのメールアドレスを追加する',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.SubHeading': '一度に1つの電子メールを追加することも、リストを貼り付けることもできます。メールアドレスは、スペース、カンマ、またはセミコロンで区切ってください。',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Placeholder': 'ここにメールアドレスを入力または貼り付けてください。',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.MaximumUsersAllowed': '{maximumUsersAllowed}のユーザーを同時に使用する。',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Total': '合計',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Duplicate': '重複する',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.AlreadyExists': 'すでに存在します',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Invalid': '無効',
    'ClientUserTable.AddNewUsers.AddNewAttributes.SignLanguageInterpreter': '手話通訳者',
    'ClientUserTable.AssignTeams.TotalUsers': '<code>{users} 名の新しい {role}</code> を追加しようとしています',
    'ClientUserTable.AssignTeams.AddAndUpdateUsers': '<code>{users} 名の新しい {role}</code> を追加し、<code>{existingUsers} 名の既存の {role}</code> を更新しようとしています',
    'ClientUserTable.AssignTeams.UpdateUsers': '<code>{existing Users}名の既存の {role}</code> を更新しようとしています',
    'ClientUserTable.AssignTeams.NumberOfTeams': '<code>{numberOfTeams}チーム(</code>に割り当てられる予定です',
    'ClientUserTable.Confirmation.Heading': '追加する前にご確認ください',
    'MursionPortal.InviteToSchedule.Confirmation.Text': '詳しくはこちら:<code>スケジュールへの招待</code>。',
    'MursionPortal.AccountOwnerGuides.Confirmation.Text': 'Account Ownerガイド',
    'MursionPortal.FacilitatorGuides.Confirmation.Text': 'Facilitatorガイド',
    'MursionPortal.Users.Confirmation.Text': 'Mursionポータルにユーザーが追加されました。',
    'MursionPortal.Confirmation.Header.Text.BackToUsers': 'ユーザー一覧へ戻る',
    'MursionPortal.Users.Next.Steps.Text': '次のステップ',
    'MursionPortal.Users.StepOne.Text': 'ステップ1．',
    'MursionPortal.Users.StepTwo.Text': 'ステップ2．',
    'MursionPortal.Users.StepThree.Text': 'ステップ3．',
    'MursionPortal.Users.ThatIt.Text': 'それだ！',
    'MursionPortal.Users.InviteToScheduleLearner.Text': '学習者をスケジュールに招待する',
    'MursionPortal.Users.LearnersLink.Text': 'このリンクは学習者に送ることができます',
    'MursionPortal.Users.InviteToSchedule.ProjectorScenario.Text': '<strong>スケジュールへの招待</strong> ボタンを任意のプロジェクトやシナリオで使用します。',
    'MursionPortal.Users.CopyInviteLink.Text': '招待状のリンクをコピーする',
    'MursionPortal.Users.ShareContent.Text': 'サポートコンテンツを管理者と共有する',
    'MursionPortal.Users.AOAndFacilitators.Responsibilities.Text': 'Account Ownerと Facilitatorはそれぞれ異なる責任を負っており、ポータルについてより詳しく学ぶ必要があるかもしれません。これらのリンクをAccount Ownerや Facilitatorと共有することで、Mursion の利用において生じる疑問に対する答えを見つけることができます。',
    'MursionPortal.Users.Administrators.Text': 'これで、管理者はMursionポータルにアクセスできるようになります。',
    'MursionPortal.Users.Progress.Text': '学習者はシミュレーションのスケジュールを組み始めます。ダッシュボードで学習者の進捗を確認することができます。',
    'MursionPortal.Users.Access.Text': 'IT部門がこれらのユーザーにMursionアプリへのアクセス権を付与していることを確認します。',
    'MursionPortal.Users.Registrationemail.Text': 'ユーザーには、no-reply@mursion.com から登録メールが届いているはずです。',
    'MursionPortal.AddMoreUsers.Btn.Text': 'ユーザーを追加する',
    'MursionPortal.Users.Ok.Btn.Text': 'OK、了解です!',
    'Users.Button.AddNewUsers': '新規ユーザーを追加する',
    'MursionPortal.Project.CancelationDeadline.UpperLimit.Validation': 'キャンセルの期限は365日を超えることはできません。',
    'MursionPortal.Dashboard.EmailTypeFilter.PasswordReset': 'パスワードのリセット',
    'MursionPortal.Dashboard.EmailTypeFilter.Verification': '検証',
    'MursionPortal.Dashboard.EmailTypeFilter.InviteToSchedule': 'インビテーション・トゥ・スケジュール',
    'MursionPortal.Dashboard.EmailTypeFilter.Summary': '概要',
    'MursionPortal.Dashboard.EmailTypeFilter.RescheuleMissed': '再スケジュールの欠席',
    'MursionPortal.Dashboard.EmailTypeFilter.RescheuleCancelled': '再スケジュールのキャンセル',
    'MursionPortal.Dashboard.EmailTypeFilter.PSRescheule': 'PSの再スケジュール',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingCorrupted': '映像録画が壊れていました',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingNotUploaded': '映像録画がアップロードされていません',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingAvailable': '動画撮影可能',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingShared': '映像録画の共有化',
    'MursionPortal.Dashboard.EmailTypeFilter.Booking': '予約',
    'MursionPortal.Dashboard.EmailTypeFilter.CodeOfConuct': '行動規範',
    'MursionPortal.Dashboard.EmailTypeFilter.UserRemoved': 'ユーザー削除',
    'MursionPortal.Dashboard.EmailTypeFilter.AttendanceReminer': '出欠席のリマインダー',
    'Users.Button.AddNewLearners': '新しい学習者を追加する',
    'MursionPortal.Scenario.Label.LastDelivered': '最終納品',
    'MursionPortal.Scenario.Label.TotalDeliveries': '総出荷台数',
    'MursionPortal.Scenario.Text.NotYetDelivered': '未配信',
    'ClientUserTable.AddNewUsers.UpdateUserWarning': 'これらの {existingUsers} 名のユーザーはすでに存在しています。続行すると、これらのユーザーが選択したチームに追加されます（すでに割り当てられているチームにも追加されます）。',
    'MursionPortal.AddNewUsers.Facilitators': 'Facilitator',
    'ClientUserTable.AddNewUsers.Learners': '学習者',
    'MursionPortal.Scheduling.TimeSlotsUnavailableMessage': '選択されたタイムスロットは使用できなくなりました。スケジュール可能なタイムスロットを表示するには更新してください。',
    'MursionPortal.Client.EmailTypeFilter.SessionFirstReminder': 'セッション1回目のリマインダー',
    'MursionPortal.Client.EmailTypeFilter.SessionSecondReminder': 'セッション2回目のリマインダー',
    'Projects.ProjectInfo.Label.ShowSIMAvailaiblity': 'SIMの空き状況を表示する',
    'Projects.ProjectInfo.Label.ShowAvailaiblity.Tooltip': '学習者がスケジュールを立てる際、選択した範囲のSIM Specialistの空き状況が表示されます。',
    'Projects.InputValidation.GreaterThanZero': '0より大きい値でなければなりません。',
    'MursionPortal.Weeks': '週間',
    'MursionPortal.Scenario.CompletionRateFulfilled': 'You cannot schedule this simulation as you have completed the maximum number of simulations for this scenario or engagement as set by your organization.',// TODO add translations
    'MursionPortal.Dashboard.EmailTypeFilter.TimeSlotRequest': 'タイムスロットのリクエスト',
    'MursionPortal.Dashboard.EmailAnalytics.OpenAndActed.Label': '開封済と実施済のデータは2022年11月24日時点のものです。',
    'MursionPortal.Dashboard.EmailAnalytics.Prior.Label': 'それ以前は、すべてのメールが「未読」に分類されます。',
    'MursionPortal.Learners.Filters.LearnerCompletion': '学習者の完了度',
    'MursionPortal.Learners.Filters.LearnerCompletion.NotYetCompleted': '未完了',
    'MursionPortal.Learners.Filters.LearnerCompletion.CompletedOneSession': '1セッション完了',
    'MursionPortal.Learners.Filters.LearnerCompletion.CompletedTwoPlusSessions': '2回以上のセッションを完了',
    'MursionPortal.Learners.Filters.MissedLateActivity': '欠席・遅刻',
    'MursionPortal.Learners.Filters.MissedLateActivity.1Session': '1セッション',
    'MursionPortal.Learners.Filters.MissedLateActivity.2Sessions': '2回',
    'MursionPortal.Learners.Filters.MissedLateActivity.3Sessions': '全3回',
    'MursionPortal.Learners.Filters.MissedLateActivity.4PlusSessions': '4回以上',
    'MursionPortal.BackToCompletedSessions': '完了したセッションに戻る',
    'MursionPortal.Scenario.InviteToSchedule.Modal.Text': 'このシナリオを完了していない割り当てられた学習者<strong>全員</strong>に招待状を送りますか、それとも<strong>選択した学習者</strong>を招待しますか？',
    'MursionPortal.BackToSessionSummaries': 'セッションの概要へ戻る',
    'MursionPortal.BackToLearnerSessions': '学習者セッションに戻る',
    'MursionPortal.LearnerSessionReport.SessionObjective': 'セッションの目的',
    'MursionPortal.LearnerSessionReport.SessionStrategy': '戦略をどの程度応用できましたか？',
    'MursionPortal.LearnerSessionReport.SessionStrategyIcon': 'セッション戦略ブルズアイ・ターゲット',
    'MursionPortal.LearnerSessionReport.SessionPerspective.Title': 'がんばってください。',
    'MursionPortal.LearnerSessionReport.SessionDetail': 'シナリオの説明',
    'MursionPortal.LearnerSessionReport.SessionDate': 'セッション開催日',
    'ClientUserTable.AddNewUsers.Procced.Button.Tooltip': '重複しているメールや無効なメールを削除してから、次に進んでください。',
    'MursionPortal.BackToSessionDetail': 'セッションの詳細へ戻る',
    'MursionPortal.SimAttendance.Instructions.Label': 'Mursion Simulation Specialists:',// TODO add translations
    'MursionPortal.SimAttendance.Instructions.LabelText': 'Select an attendance status for each attendee (mouse over the status to learn more). Your status is gathered automatically – these statuses are for the learner ONLY.',// TODO add translations
    'MursionPortal.SimAttendance.Instructions.Label1': 'Software License Simulation Specialists:',// TODO add translations
    'MursionPortal.SimAttendance.Instructions.LabelText1': 'Select an attendance status for each attendee (mouse over the status to learn more). If you experienced an error in the session, please also provide the error details. To do this, select the type of error under the “SIM Reported Error” section and add context in the Additional Details text box. Additional details about attendance--even if it’s not an Error--are encouraged, but are optional. If you have any further questions about attendance, please reach out to your Simulation Program Admin.',// TODO add translations
    'MursionPortal.Dashboard.EmailAnalytics.Label.EmailAnalytics': 'メール分析',
    'MursionPortal.Session.LearnerSession.Report': 'レポート',
    'MursionPortal.Session.LearnerSession.Recording': '録画',
    'MursionPortal.Session.LearnerSession.SessionDetails': 'セッションの詳細',
    'MursionPortal.Session.LearnerSessionReport': '学習者のセッションのレポート',
    'MursionPortal.Scenario.InviteToScedule.Modal.SelectLearners': '学習者を選ぶ',
    'MursionPortal.Scenario.Button.Label': '学習者のメールをコピーする',
    'MursionPortal.VideoStatusFilter.Label': '動画のステータス',
    'MursionPortal.TooltipText.VideoNotUploaded': '48時間経過してもこの動画がアップロードされない場合は、サポートまでご連絡ください。',
    'MursionPortal.VideoStatusFilter.SearchText': '動画のステータスを検索します...',
    'MursionPortal.VideoStatusFilter.Uploaded': 'アップロード済',
    'MursionPortal.VideoStatusFilter.NotUploaded': 'アップロードされていません',
    'MursionPortal.Scenario.Invite.Modal.Button.Label': '招待する',
    'MursionPortal.Scenario.CopyEmails.Modal.Button.Label': 'メールをコピー',
    'MursionPortal.Scenario.AllLearners.InvitationsSentOvernight': '過去2日以内に学習者のスケジュールが未決定の場合、または招待状を受け取っていない場合、招待状は夜間に送信されます。',
    'MursionPortal.Scenario.SelectedLearners.InvitationsSentOvernight': '<code>選択された学習者</code>は、過去2日以内にスケジュールを設定したり招待状を受け取っていない場合、スケジュールを設定するための招待メールが夜間に送信されます。',
    'MursionPortal.Scenario.SelectedLearners.Confirm.Text': '本当に<code>選択したすべての学習者</code>を招待しますか？',
    'ClientUserTable.Column.LastInvited': '最終招待日',
    'ClientUserTable.ColumnTooltip.LastInvited': '最後にスケジュール案内のメール招待状が送信された日付。これは、招待されたばかりの場合は、次の日になることがあります。',
    'MursionPortal.Scenario.SelectedLearners.EmailsCopied': '選択された学習者の電子メールアドレスがクリップボードにコピーされました',
    'MursionPortal.ScenarioInfo.MarkAllInactive': '非アクティブをすべてマークする',
    'MursionPortal.ScenarioInfo.DecertifyAll': 'すべて認証解除',
    'MursionPortal.ScenarioInfo.MarkAllInactive.Confirmation.Text': '本当にすべてのSIM Specialistを非アクティブにするのですか？',
    'MursionPortal.ScenarioInfo.DecertifyAll.Confirmation.Text': '本当にすべてのSIM Specialistの認証を解除するのですか？',
    'Dashboard.Button.ViewSession': 'セッションを見る',
    'MursionPortal.SessionReport.NotAvailable': '利用不可',
    'Projects.ProjectInfo.Checkbox.EnableReportTabSurvey.OneToOne': '1:1の配信でレポートタブを有効にする',
    'MursionPortal.Learner.Session.Report': '学習者のセッションのレポート',
    'MursionPortal.Scenario.Sim.InactivedAll': '{TotalSims} 件のSIMのうち{effectedSims}件が非アクティブになりました。',
    'MursionPortal.Scenario.Sim.DecertifiedAll': '{TotalSims}件のSIMのうち{EffectedSims}件が認証解除されました。',
    'MursionPortal.Scenario.Sim.DecertifiedFew': 'セッションが予定されているため、{remainingSims} のSIMは認証解除できません。セッションを尊重し、将来のセッション割り当てを防ぐために、それらを非アクティブにマークします。',
    'MursionPortal.AriaLabel.ViewMore': '詳細を見る',
    'MursionPortal.SimAttendance.SimulationAttendance': 'シミュレーション学習者の出席率',
    'Mursion.Portal.Status.LearnerTechnicalError': 'Learner Technical Error',// TODO add translations
    'Mursion.Portal.Status.TooLateToComplete': '完了が遅すぎる',
    'Mursion.Portal.Status.LearnerElectedToLeave': 'Learner Elected to Leave',// TODO add translations,
    'Mursion.Portal.Status.Unprepared.Tooltip': '学習者は参加したが、準備不足などの理由でシミュレーションを完了できなかった。',
    'MursionPortal.Label.SchedulingRate': 'スケジューリング率',
    'Mursion.Portal.Status.No.Show': '欠席者',
    'MursionPortal.ScenarioInfo.SchedulingRate.Text': '{code} 回',
    'MursionPortal.Scenario.Modal.Search.Placeholder': 'メールアドレス、名前、姓を検索',
    'MursionPortal.Scenario.SchedulingRateFulfilled': 'まず予定されているセッションを完了させる必要があります。',
    'MursionPortal.Scenario.InviteToScedule.Modal.Button.Tooltip': '招待する学習者を選択する',
    'MursionPortal.Learner.Session.Report.Report.SimulationRecording': 'シミュレーションの録画を再生して、会話を振り返る',
    'Dashboard.SimulationTable.ColumnTooltip.SoftwareVersion': '使用したセッションのソフトを表します。',
    'Dashboard.SimulationTable.Column.SoftwareVersion': 'ソフトウェア',
    'MursionPortal.LearnerSessionReport.YourPerspective': 'あなたの視点',
    'MursionPortal.LearnerSessionReport.YourPerspectiveDescription': 'シミュレーションに臨むあなたの自信は<strong>{preConfidenceLevel}</strong>で、シミュレーション後は<strong>{postConfidenceLevel}</strong>となりました。',
    'MursionPortal.LearnerSessionReport.YourPerspective.VeryLow': '非常に低い',
    'MursionPortal.LearnerSessionReport.YourPerspective.Low': '低い',
    'MursionPortal.LearnerSessionReport.YourPerspective.Moderate': '控えめ',
    'MursionPortal.LearnerSessionReport.YourPerspective.High': '高い',
    'MursionPortal.LearnerSessionReport.YourPerspective.VeryHigh': '非常に高い',
    'MursionPortal.LearnerSessionReport.Strategies.SuccessfullyStrategy': 'あなたはこの戦略を<strong>成功</strong>させました。',
    'MursionPortal.LearnerSessionReport.Strategies.BenefitStrategy': 'この戦略を実行するには、<strong>もう少し練習する必要がある</strong>でしょう。',
    'MursionPortal.SchedulingRate.ValidationMessage': 'スケジューリングの割合は、完了の割合以下でなければなりません',
    'MursionPortal.LearnerSessionReport.Footer.FooterTitle': '次のステップ:練習すれば完璧になります!',
    'MursionPortal.LearnerSessionReport.Footer.FooterSubTitle': 'これらのスキルを自然に身につけるには、繰り返し行うことが重要です。',
    'MursionPortal.LearnerSessionReport.SessionObjective.Body': 'レポートはまもなく完成します',
    'MursionPortal.Dashboard.NextOnProject.Footer.schedulingRateFulfilled.Text': 'You cannot schedule this simulation at this time as you have currently reached the scheduling limit for this scenario or engagement set by your organization.',// TODO add translations
    'MursionPortal.Status.Abbreviation.LicenseCancelled': 'C',
    'MursionPortal.Scenario.CharacterLimitExceeded.Message': '文字数制限を{exceededCharLength}文字分超過しています。修正をお願いします。',
    'MursionPortal.Scenario.AddedCharacter.Message': '{totalChar}の{added}。',
    'MursionPortal.Scenario.CharacterLimit.Message': '文字数制限：{maxLength}文字。',
    'MursionPortal.Scenario.Description.block.default.text': 'ここに説明文を追加',
    'MursionPortal.Scenario.AttachmentField.text': 'さらにファイルを追加することができます',
    'MursionPortal.sessionTable.Column.OriginalSim': '元のSIM',
    'MursionPortal.sessionTable.Column.NewSim': '新しいSIM',
    'MursionPortal.Users.EditUser.InactivatedAll.Message': '{code}証明書が非アクティブになりました。',
    'MursionPortal.Users.EditUser.InactivateAll.Confirmation': 'このSIM Specialistのすべての証明書を非アクティブにしてもよろしいですか？',
    'MursionPortal.Users.Modal.ActiveCerts.EmptyMessage': 'アクティブな証明書がありません',
    'MursionPortal.Users.Modal.InactiveCerts.EmptyMessage': '非アクティブな証明書がありません',
    'MursionPortal.Users.Modal.ActiveCerts': 'アクティブな証明書',
    'MursionPortal.Users.Modal.InactiveCerts': '非アクティブな証明書',
    'MursionPortal.Users.ActiveCerts.MarkAllInactive.Button': '非アクティブをすべてマークする',
    'MursionPortal.SurveyFilter.Incomplete': '未完了',
    'MursionPortal.SurveyFilter.SearchText': 'アンケートを検索します...',
    'MursionPortal.Dashboard.SurveyReminder.Text': '完了すべきアンケート数が{surveyCount}件あります。',
    'MursionPortal.Dashboard.RemindMeLater': '後で思い出す',
    'MursionPortal.Dashboard.SurveysIncomplete': 'アンケートの不備',
    'MursionPortal.AriaLabel.NormalFont': 'ノーマルフォント',
    'MursionPortal.AriaLabel.MediumFont': '中フォント',
    'MursionPortal.AriaLabel.LargeFont': '大フォント',
    'MursionPortal.AriaLabel.Font': 'フォント',
    'Dashboard.LeftPane.CategoryHeader.RescheduledAndCancelled': '再スケジュール済・キャンセル済',
    'MursionPortal.Dashboard.Summary.Certifications': '証明書',
    'MursionPortal.SessionsTable.ActionTitle.Pickup': '取り上げる',
    'MursionPortal.Label.MissCancellationRate': '欠席・キャンセル率',
    'MursionPortal.Label.NotAllowed': '不可',
    'Dashboard.LeftPane.CategoryHeader.Simulations': 'セッション',
    'Dashboard.LeftPane.Simulations.SimulationSummaries': 'セッションの概要',
    'Dashboard.LeftPane.Simulations.SimulationDetail': 'セッションの詳細',
    'MursionPortal.Message.NotEnoughDataToDisplay': 'このセッションからの十分なデータがありませんでした。',
    'MursionPortal.Message.Notdata.SessionMatrix': 'このセッションからは、セッション分析を提供するのに十分なデータがありませんでした。',
    'MursionPortal.Tooltip.SchedulingWindow': 'エンドユーザーは、この値を日、時間、分で設定できます。それ以降は、ユーザーだけがセッションをスケジュールできます。',
    'MursionPortal.DemandBasedScheduling.TimeBlockUnavailableSelectDate': '現在、空き時間枠がありません。後でもう一度やり直してください。 <code1></code1>すぐに助けが必要な場合は、<code>サポート</code>にお問い合わせください。',
    'Session.Edit.Modal.JoinSession': 'セッションを開始',
    'MursionPortal.SimAttendance.Tooltip.Completed': '学習者は、セッションのシミュレーション部分を完了しました (セッション終了時の報告は含みません)。',
    'MursionPortal.SimAttendance.Tooltip.Late': '学習者の接続が遅すぎて、シミュレーションを完了できませんでした。',
    'MursionPortal.SimAttendance.Tooltip.Left': '学習者は、シミュレーションを完了する前に意図的に接続を切断しました。これは、学習者が準備ができていないか、学習者が緊急事態のために出発したことが原因である可能性があります。',
    'Session.Edit.Modal.JoinSessionBtnTooltip': '[セッションの開始] ボタンは、セッション開始の <code>{minutesBeforeJoin}</code> 分前に有効になります。',
    'Dashboard.Simulations.SimulationSummaries.TableHeader': 'セッション概要データ',
    'Dashboard.Learners.LearnerActivity.TableHeader': '学習者活動データ',
    'Dashboard.Learners.LearnerSimulation.TableHeader': '学習者セッションデータ',
    'Dashboard.ContractProgress.Column.Missed': '学習者が見逃した',
    'Dashboard.ContractProgress.Column.TotalRescheduled': '合計再スケジュール',
    'Dashboard.ContractProgress.Column.RescheduledMakeup': '再スケジュールメイク',
    'Dashboard.ContractProgress.Column.LateRescheduledSimulations': '遅発スケジュール変更',
    'Dashboard.ContractProgress.Column.EarlyRescheduledSimulations': '早期スケジュール変更',
    'Dashboard.ContractProgress.Column.CompletedUtilization': 'セッション完了',
    'Dashboard.ContractProgress.Column.ScheduledUtilization': '完成予想',
    'Dashboard.ContractProgress.ColumnTooltip.Remaining': '購入したセッションから完了したセッションを差し引いたもの。',
    'Dashboard.ContractProgress.ColumnTooltip.TotalRescheduled': 'スケジュールされているシミュレーションの合計',
    'Dashboard.ContractProgress.ColumnTooltip.RescheduledMakeup': '再スケジュールされたメイクアップとしてマークされたシミュレーションの合計',
    'Dashboard.ContractProgress.ColumnTooltip.LateRescheduledSimulations': '遅れて再スケジュールされるシミュレーション。',
    'Dashboard.ContractProgress.ColumnTooltip.EarlyRescheduledSimulations': '早期に再スケジュールされたシミュレーション。',
    'Dashboard.Learners.Column.SimulationId': 'セッション ID',
    'Dashboard.Learners.Column.SimulationId.CopyHover': 'セッション ID をコピー',
    'Dashboard.Learners.Column.SSO_ID': 'SSO ID',
    'Dashboard.Learners.Column.Recorded': '収録済み',
    'Dashboard.Learners.Column.LearnerRecordingConsent': '学習者の録音同意',
    'Dashboard.Learners.Column.RecordingRecipients': '録音受信者',
    'Dashboard.Learners.Column.RecordingUploaded': 'アップロードされた収録',
    'Dashboard.Learners.ColumnTooltip.SSOID': 'プロビジョニングされたユーザー',
    'Dashboard.Learners.ColumnTooltip.Recorded': 'シミュレーションが記録されたかどうか、または許可されていないかどうかを示します。',
    'Dashboard.Learners.ColumnTooltip.LearnerRecordingConsent': '学習者がシミュレーションの記録に同意したかどうかを示します。',
    'Dashboard.Learners.ColumnTooltip.RecordingRecipients': 'シミュレーションの記録が送信される役割。',
    'Dashboard.Learners.ColumnTooltip.RecordingUploaded': 'シミュレーション記録がアップロードされたかどうかを示します。',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.LateReschedule': '学習者が遅刻したセッションの再スケジュール。',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.LateReschedule': '遅い再スケジュール',
    'MursionPortal.Dashboard.Chart.Header.LearnerStatus': '学習者ステータス (すべての契約)',
    'Dashboard.SimulationTable.Column.SimulationStatus': 'セッションステータス',
    'MursionPortal.Placeholder.ContractValue': '契約を選択',
    'MursionPortal.Placeholder.NoContractValue': '契約がありません',
    'Dashboard.Learners.Column.AssignedTeams': '割り当てられたチーム',
    'SessionReports.VideoRecordingDeletedWithSupportCase': '残念ながら、保留中の #{supportCase} のため、この動画は表示できません。',
    'MursionPortal.Dashboard.CompletedSessions': '完了したセッション',
    'MursionPortal.Dashboard.ViewAll': 'すべて見る',
    'MursionPortal.Dashboard.DateOfSimulation': 'セッションの日付',
    'Dashboard.LeftPane.CategoryHeader.Videos': 'ビデオ',
    'Dashboard.LeftPane.Videos.NotUploaded': '録音がアップロードされていません',
    'Dashboard.NotUploaded.TableHeader': 'アップロードされていない録音データ',
    'Dashboard.VideosTable.Column.SessionId': 'SessionID',
    'Dashboard.VideosTable.Column.SimspecialistId': 'SIMSpecialistID',
    'Dashboard.VideosTable.Column.ClientId': 'ClientID',
    'Dashboard.VideosTable.Column.SessionEnd': 'セッション終了',
    'Dashboard.VideosTable.Column.SoftwareVersion': 'シナリオ版',
    'Dashboard.VideosTable.NoVideosError': 'ビデオは見つかりませんでした。',
    'Filters.SimSpecialist': 'SIM 名',
    'Filters.SimSpecialistPlaceHolderHint': 'SimSpecialists を検索',
    'MursionPortal.Placeholder.SimSpecialistValue': 'SimSpecialist の選択',
    'Users.Table.ErrorOnLoadUserMessage': 'ユーザーのロード中にエラーが発生しました!',
    'Dashboard.ContractProgress.InfoBadge.ContractDates': '契約日',
    'Dashboard.ContractProgress.InfoBadge.ContractID': '契約 ID',
    'Dashboard.Learners.Column.EarlyCancelled': '早期キャンセル',
    'Dashboard.Learners.Column.LateReschedules': '遅い再スケジュール',
    'Dashboard.Learners.Column.EarlyReschedules': '早期再スケジュール',
    'Dashboard.Learners.Column.RescheduledMakeUp': '再スケジュールメイク',
    'Dashboard.Learners.Column.ErrorSim': 'エラー (SIM)',
    'Dashboard.Learners.Column.CancelledOps': 'キャンセル (Ops)',
    'Dashboard.Learners.ColumnTooltip.AssignedProjects': 'この学習者が現在割り当てられているプロジェクト',
    'Dashboard.Learners.ColumnTooltip.AssignedTeams': 'この学習者が現在割り当てられているチーム',
    'Dashboard.Learners.ColumnTooltip.AssignedScenarios': 'この学習者が現在割り当てられているシナリオ',
    'Dashboard.Learners.ColumnTooltip.ScheduledScenarios': 'この学習者がスケジュールしたが、まだ完了していないシナリオ',
    'Dashboard.Learners.ColumnTooltip.CompletedScenarios': 'この学習者が完了したシナリオ',
    'Dashboard.Learners.ColumnTooltip.EarlyCancelled': '学習者、ファシリテーター、またはアカウント所有者によって早期に (キャンセル期限前に) キャンセルされたシミュレーション',
    'Dashboard.Learners.ColumnTooltip.LateReschedules': '学習者、ファシリテーター、またはアカウント所有者によって遅れて (キャンセル期限後に) 再スケジュールされたシミュレーション',
    'Dashboard.Learners.ColumnTooltip.RescheduledMakeUp': '学習者、ファシリテーター、アカウント所有者、またはシミュレーションによって以前に見逃されたシミュレーション',
    'Dashboard.Learners.ColumnTooltip.EarlyReschedules': '学習者、ファシリテーター、またはアカウント所有者によって早期に (キャンセル期限前に) 再スケジュールされたシミュレーション時間',
    'Dashboard.Learners.ColumnTooltip.ErrorSim': '接続の SIM 側でエラーになったシミュレーション',
    'Dashboard.Learners.ColumnTooltip.CancelledOps': 'Mursion によってキャンセルされたシミュレーション',
    'SessionReports.VideoRecordingPermissionNotGranted': '参加者が録画を希望しなかったため、このビデオは利用できません。',
    'SessionReports.NoVideoAvailableForSession': 'このセッションのビデオはありません。',
    'MursionPortal.NotAvailable': '該当なし',
    'Projects.ProjectInfo.Checkbox.EnablePostSimulationSurvey.OneToOne': '1 のシミュレーション後の SIM フィードバック調査を有効にする',
    'Projects.ProjectInfo.Checkbox.EnablePostSimulationSurvey.Workshop': 'ワークショップ配信のシミュレーション後の SIM フィードバック調査を有効にする',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PostSimulationSurvey.OneToOne': 'シミュレーション後の SIM フィードバック調査 1',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PostSimulationSurvey.Workshop': 'シミュレーション後の SIM フィードバック調査',
    'Clients.Portal.Button.EditMemberships': 'メンバーシップの編集',
    'Calendar.ProjectsList.ShowOnlyActiveProjects': 'アクティブなプロジェクトのみを表示',
    'MursionPortal.Status.Undefined': '未定義',
    'MursionPortal.Status.Orphan': '孤児',
    'MursionPortal.Status.Reserved': '予約済み',
    'MursionPortal.Status.Waif': 'ワイフ',
    'MursionPortal.Status.Upcoming': '今後',
    'MursionPortal.Status.Running': 'ランニング',
    'MursionPortal.Status.NeedsReview': 'レビューが必要です',
    'MursionPortal.Status.Reviewed': 'レビュー済み',
    'MursionPortal.ErrorMessage.NoDataAvailable': 'このセッションからは、セッション分析を提供するのに十分なデータがありませんでした。',
    'Dashboard.NotUploaded.MainHeader': '録音がアップロードされていません',
    'MursionPortal.Message.NotEnoughDataToDisplay.ConversationalFlow': 'このグラフを作成するには、このセッションのデータが不十分でした',
    'MursionPortal.Label.SIMSpecialist': 'SIM スペシャリスト',
    'Dashboard.SimulationTable.Column.LearnerRecordingConsent': '学習者の録音同意',
    'Dashboard.SimulationTable.Column.RecordingRecipients': '受信者の録音',
    'Dashboard.SimulationTable.Column.RecordingUploaded': '録音がアップロードされました',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerRecordingConsent': '学習者が記録されているシミュレーションに同意したかどうかを示します',
    'Dashboard.SimulationTable.ColumnTooltip.RecordingRecipients': 'シミュレーションの記録が送信される役割',
    'Dashboard.SimulationTable.ColumnTooltip.RecordingUploaded': 'シミュレーション記録がアップロードされたかどうかを示します',
    'MursionPortal.ConversationalFlow.label.zoom': 'Zoom',
    'MursionPortal.label.LearnerInterjects': '学習者のインタージェクト',
    'MursionPortal.label.AvatarInterjects': 'アバターインタージェクト',
    'MursionPortal.label.LearnerInterrupted': '学習者のインタージェクト',
    'MursionPortal.label.AvatarInterrupted': 'アバターインタージェクト',
    'MursionPortal.label.Silence': 'サイレンス',
    'Clients.Modal.Label.UserRecordingConsent': 'ユーザーの記録に関する同意',
    'Clients.Modal.Label.UserRecordingConsent.ToolTip': 'ユーザーに録音の同意を求める',
    'MursionPortal.Notset': '設定されていません',
    'Clients.Modal.Label.UserML3SocialRecordingConsent': 'Mursion ソーシャルユーザー記録の同意',
    'Clients.Modal.Label.UserML3SocialRecordingConsent.ToolTip': 'ユーザーに記録の同意を求めます。 これが有効になっていない場合は、SIM スペシャリストが口頭で尋ねます。',
    'MursionPortal.LearnerSimSelector.Sim.Available.ToolTip': 'セッションが予約されておらず、選択された時間に空きがある認定 SIM',
    'MursionPortal.LearnerSimSelector.Sim.Unavailable.ToolTip': 'システムに空室がなく、選択した時間に別のセッションが予約されていない認定済みの SIM',
    'Settings.Config.Client.VideoRecordingDataRetentionPolicy': 'ビデオ録画データ保持ポリシー構成を表示',
    'MursionPortal.DemandBasedScheduling.BackButton.BackToScenario': 'シナリオに戻る',
    'MursionPortal.DemandBasedScheduling.BackButton.BackToCalendar': 'カレンダーに戻る',
    'SessionReports.VideoRecordingDeletedDueToDataRetentionPolicy': 'あなたの会社のデータ保持ポリシーにより、このビデオは利用できません。',
    'SessionReports.VideoRecordingAvailabilityMessage': 'この動画は {videoAvailabilityDate} まで視聴可能です。',
    'SessionReports.SessionOrLearnerStatusError': '技術的なエラーのため、このセッションのビデオは記録されませんでした。',
    'Clients.ImportUsers.NextStepButton': '次のステップ',
    'Clients.ImportUsers.CancelButton': 'キャンセル',
    'Clients.ImportUsers.UploadValidateButton': 'アップロード + 検証',
    'Session.Table.Column.Attendance': '参加',
    'Session.Table.Column.Value.CompleteAttendance': '完全参加',
    'Session.Table.Column.Value.AttendanceComplete': '参加完了',
    'Session.Table.Column.Value.NotApplicable': '(該当なし)',
    'Session.Table.Column.Survey': '調査',
    'Session.Table.Column.Value.CompleteGoogleForm': 'Google フォームに記入してください',
    'Session.Table.Column.Value.CompleteSurvey': '調査を完了する',
    'Session.Table.Column.Value.SurveyComplete': '調査完了',
    'Projects.Modal.Text.LearnerSelected': '少なくとも {maxLearners} 人の学習者を選択する必要があります',
    'Session.Table.Column.Value.SessionNotCompleted': 'セッションは完了していません',
    'MursionPortal.Project.InviteToSchedule': 'スケジュールに招待',
    'MursionPortal.Project.InvitedToday': '今日招待された',
    'MursionPortal.Project.YourInvitationsText': '招待状は夜中に送信されます。',
    'MursionPortal.Project.InviteToSchedule.Tooltip': 'シナリオへの招待は既に送信されています。 明日また招待状を送信できるようになります。',
    'MursionPortal.Project.InvitedToday.Tooltip': 'このシナリオをスケジュールしていない、またはキャンセル/欠席した学習者は、スケジュールへの招待メールを受け取ります。',
    'MursionPortal.Project.InviteMessage.SentDate': '最終送信 {sentDate}',
    'ITManager.TechRestriction.EditDialog.ProviderErrorMsg': 'プロバイダーを選択してください',
    'ITManager.TechRestriction.EditDialog.GlobalRestriction': 'グローバル制限',
    'ITManager.TechRestriction.EditDialog.HolidayRestriction': '休日制限',
    'ITManager.TechRestriction.Table.Provider': 'プロバイダー',
    'Session.Table.Column.Survey.HoverText': '有効化されている場合、SIM は学習者に関する完全なフィードバックへのリンクを受け取ります',
    'MursionPortal.Project.InviteMessage.InvitedBy': '{user} から招待 - {role}',
    'MursionPortal.Project.LearnersNotYetScheduled': '{learners} の学習者はまだ予定を立てる必要があります',
    'Session.Table.Column.Value.AttendanceRequired': '参加が必要',
    'MursionPortal.Button.Schedule.Another': '別のスケジュール',
    'MursionPortal.EulaAgreement.CodeOfConduct': 'すべての Mursion シミュレーションには、学習セッションで最大 5 つの仮想アバターに会話を提供する 1 人のライブ シミュレーションスペシャリストが関与します。シミュレーションスペシャリストの仕事は、あなたのような学習者が各シナリオで複雑なヒューマンスキルを練習できるように、会話の課題を提示することです。Mursion では、人々が潜在的にストレスの多い微妙な複雑な会話を勇敢に練習できる安全な空間を作ることの重要性を認識しています。学習者とシミュレーションスペシャリストの両方が自信を持って参加できるようにするために、両方のグループの個人に基本的な行動規範を遵守するよう求めます。',
    'MursionPortal.EulaAgreement.CodeOfConduct.Header': '方法論の概要と行動規範',
    'MursionPortal.EulaAgreement.UserAgreement.Header': 'ユーザー規約',
    'MursionPortal.Import.BackToUser': 'ユーザーに戻る',
    'MursionPortal.Import.BackToTeam': 'チームに戻る',
    'Mursion.Portal.Edit.Team.Upload.AccurateUploading': 'アップロードする前に、データが正確であることを確認してください。',
    'Mursion.Portal.Edit.Team.Upload.AskedReUpload': 'ファイルにエラーがある場合は、ステップ 4 の結果のスプレッドシートに表示されます。ステップ 4 の指示に従って、元のテンプレートのエラーを修正し、ファイルを再アップロードしてください。',
    'Mursion.Portal.Edit.Team.Upload.IncludeNewUser': '再アップロード中に、失敗したユーザーとともにインポートする新しいユーザーを含めることもできます。',
    'Mursion.Portal.Edit.Team.ReadyToUpload': 'ファイルの準備ができたら、[アップロードと検証] をクリックします',
    'Mursion.Portal.Edit.Team.UploadAndValidationFile': 'アップロード + ファイルの検証',
    'Mursion.Portal.Edit.Team.TeamAssignmentsUpdateSuccessfully': 'チームの割り当てが正常に更新されました',
    'Mursion.Portal.Edit.Team.UploadSuccessTitle': '{userCount} ユーザーがインポートされました!',
    'Mursion.Portal.Edit.Team.UploadFailureTitle': 'エラー！',
    'Mursion.Portal.Edit.Team.UploadSuccess': 'すべてのユーザーがポータルに正常に追加されました。',
    'Mursion.Portal.Edit.Team.DoneButton': '完了',
    'Mursion.Portal.Edit.Team.DownloadSuccessful': 'ダウンロード成功 - 次のステップに進みます',
    'Mursion.Portal.Edit.Team.DownloadError': 'ダウンロードエラー - 再試行してください',
    'Mursion.Portal.Edit.Team.UploadFileAgain': 'ファイルをもう一度アップロードしてください',
    'Mursion.Portal.Edit.Team.ContinueWithoutCorrecting': 'エラーを修正せずに続行します',
    'Mursion.Portal.Edit.Team.SuccessfulImportTitle': '正常にインポートされました',
    'Mursion.Portal.Edit.Team.UnSuccessfulImportTitle': 'インポートに失敗しました',
    'Mursion.Portal.Import.User.HeaderTitle': 'ユーザーのインポート',
    'Mursion.Portal.Import.User.Upload.AskedReUpload': 'ファイルにエラーがある場合は、ステップ 4 の結果のスプレッドシートに表示されます。ステップ 4 の指示に従って、<strong>元のテンプレート</strong>のエラーを修正し、ファイルを再アップロードしてください。',
    'Mursion.Portal.Import.User.ReadyToUpload': 'ファイルの準備ができて適切にフォーマットされたら、検証のためにファイルをアップロードしてください',
    'Mursion.Portal.Import.User.ValidatingYourDataUntilTheUpload': 'データを検証し、ユーザーをインポートしています。15 分後、またはこのプロセスが完了したというアラートを受け取ったら、もう一度確認してください。',
    'Mursion.Portal.Import.User.UploadSuccessTitle': '{successCount} ユーザーがインポートされました!',
    'Mursion.Portal.Import.User.ErrorContinueTitle': 'エラーを修正せずに続行してください!',
    'Mursion.Portal.Import.User.ErrorContinue': '{failedCount} users failed to import as the errors were not fixed.', // TODO add translations
    'Settings.ScenarioBank.Form.ArtBundlesWarning': 'アート バンドルの名前をデフォルト名から変更してください。',
    'Mursion.Portal.Import.User.Instruction.Upload': '下の [<strong>Excel テンプレートをダウンロード</strong>] をクリックして、ダウンロードしたファイル (<strong>client_internal_users.xlsx</strong> または <strong>client_external_users.xlsx</strong>) を開きます。',
    'Mursion.Portal.Import.User.Instruction.Format': '<strong>テンプレートでは、メール、ロール、および SSO ID フィールドが必須です。これらを空白のままにしないでください。各ヘッダーの下にユーザー情報を正しく追加してください。</strong>インポートが失敗する可能性があるため、ヘッダー、テンプレート構造、またはファイル名を変更しないでください。',
    'Mursion.Portal.Import.User.Instruction.NewUser': '<strong>列 J-S または F-O</strong> に、ユーザーが割り当てられるチームの名前を追加します (スペルが名前と正確に一致していることを確認してください)。',
    'Mursion.Portal.Import.User.Instruction.Error': 'すべてのユーザーを入力したら、デスクトップまたは覚えやすい場所にファイルを保存し、[<strong>続行</strong>] をクリックします。',
    'Mursion.Portal.Import.User.Instruction.OriginalTemplate': 'ファイルにエラーがある場合は、ステップ 4 の結果のスプレッドシートに表示されます。指示に従って<strong>元のテンプレート</strong>のエラーを修正し、ファイルを再アップロードしてください。',
    'Mursion.Portal.Import.User.Instruction.UploadList': 'エラーを修正せずに続行することを選択した場合、<strong>失敗したアップロードリストのダウンロード</strong>ファイルは 7 日間のみ利用できます。',
    'Mursion.Portal.Import.User.DownloadExcelTemplate': 'Excel テンプレートをダウンロード',
    'Mursion.Portal.Import.User.UploadFileStatus': 'ファイルをアップロードしています',
    'Mursion.Portal.Import.User.DoNotCloseUntilUpload': 'データを検証し、ユーザーをインポートしています。このプロセスはバックグラウンドで続行されるため、別のタブまたはウィンドウに移動して、いつでもこの画面に戻ることができます。このインポートプロセスには 5 分もかかりません。',
    'Mursion.Portal.Import.User.FileStatus.validation': 'ユーザーのインポート',
    'Mursion.Portal.Import.User.CorrectErrorSubHeading': '最終ステップ: エラーを修正する',
    'Mursion.Portal.Import.User.SuccessCount': '{successCount} users were successfully imported; {failedCount} users failed to import – please review.', // TODO add translations
    'Mursion.Portal.Import.User.DownloadFile': 'Click the <strong>Download Errors</strong> button and open the file to view the errors.', // TODO add translations
    'Mursion.Portal.Import.User.FileTabs': '<strong>テンプレートでは、メールと役割のフィールドは必須です。空白のままにしないでください</strong>。インポートが失敗する可能性があるため、ヘッダー、テンプレート構造、またはファイル名を変更しないでください。',
    'Mursion.Portal.Import.User.ReviewCorrect': '<strong>元のテンプレート</strong> (<strong>client_internal_users.xlsx</strong>または <strong>client_external_users.xlsx</strong>) のエラーを修正し、ファイルを再アップロードしてください。',
    'Mursion.Portal.Import.User.DownloadErrorButton': 'エラーズのダウンロード',
    'Mursion.Portal.Edit.Team.EditTeamTitle': 'チームメンバーシップの編集',
    'Mursion.Portal.Edit.Team.Instruction.UpdateFile': '各ヘッダーの下にユーザー情報を正しく追加します。列 L から U に、学習者が割り当てられる必要があるチーム (つまり、追加または更新されるチーム) の名前を、チームごとに 1 列ずつ追加します。必要に応じて、一部のチーム列を空白のままにしても問題ありません。これにより、既存のチームの割り当てが上書きされるため、これが正しく行われていることを確認してください。',
    'Mursion.Portal.Edit.Team.Instruction.Process': '一度に 1 つのファイルをアップロードしてください - ファイルあたりのユーザー数を 5,000 に制限してください。',
    'Mursion.Portal.Edit.Team.Instruction.ProfileFields': '<strong>メールと役割のフィールドは必須です。空白のままにしないでください。</strong>インポートが失敗する可能性があるため、ヘッダー、テンプレート構造、またはファイル名を変更しないでください。',
    'Mursion.Portal.Edit.Team.Instruction.ErrorOccur': 'サポートされているファイル形式は .xlsx のみです。',
    'Mursion.Portal.Edit.Team.DoNotCloseUntilUpload': 'データを検証し、チームの割り当てを更新しています。このプロセスはバックグラウンドで続行されるため、別のタブまたはウィンドウに移動して、いつでもこの画面に戻ることができます。このプロセスには 5 分もかかりません。',
    'Mursion.Portal.Edit.Team.FileStatus.validation': 'チーム メンバーシップの更新',
    'Mursion.Portal.Edit.Team.UploadFailureNotification': 'ファイルには 5,000 人を超えるユーザーが含まれています。サポートに連絡するか、5,000 未満のユーザーで複数のファイルをアップロードしてください。',
    'Mursion.Portal.Edit.Team.ReviewCorrect': '<strong>テンプレートでは、メールと役割のフィールドが必須です。これらを空白のままにしないでください。</strong>インポートが失敗する可能性があるため、ヘッダー、テンプレート構造、またはファイル名を変更しないでください。',
    'Mursion.Portal.Edit.Team.DownloadFile': '元のテンプレート ファイル (<strong>edit_learner_team_memberships.xlsx</strong>) のエラーを修正します。インポートに失敗する可能性があるため、ヘッダー、テンプレート構造、またはファイル名を変更しないでください。',
    'Mursion.Settings.ScenarioBank.Modal.ChecklistURL': 'SIM チェックリスト URL',
    'Mursion.Settings.ScenarioBank.Modal.MustBeAValidURL': '有効な URL である必要があります',
    'MursionPortal.Label.SpeakingIsZero': 'は 0％ です',
    'MursionPortal.Label.InterruptionIsZero': 'というのは、0％ です',
    'MursionPortal.CodeOfConduct.SchedulingFlow.Text': 'Mursion セッションをスケジュールすることを選択することにより、Mursion のシミュレーション配信モデルに同意し、Mursion の行動規範を支持し尊重することを確認したことになります。詳しくは <code>こちら</code> をご覧ください。',
    'Mursion.Portal.Import.User.InvalidFile': 'csv または Excel テンプレートのみがアップロードされます。',
    'Mursion.Portal.Import.User.MaxFileSize': 'ファイルサイズは 5MB を超えてはなりません',
    'Mursion.Portal.Import.User.Tooltip': '新しいユーザーをインポートするか、エクスポートファイルから既存のユーザーを更新するには、ここをクリックしてください。',
    'Mursion.Portal.Edit.Team.Tooltip': 'チームメンバーシップを一括で割り当てまたは再割り当てするには、ここをクリックしてください。',
    'MursionPortal.Project.InviteLearnersToSchedule': '学習者をスケジュールに招待する',
    'MursionPortal.CheckboxLabel.SchedulingByBookIt': 'BookIt によるスケジューリング',
    'Dashboard.Report.SendReport': 'レポートを送信',
    'Dashboard.Report.ScheduleReport': 'レポートのスケジュール',
    'Dashboard.Report.EditSchedule': 'スケジュールの編集',
    'Dashboard.Report.ViewSchedule': 'スケジュールを見る',
    'Dashboard.Report.DeleteSchedule': 'スケジュールを削除',
    'Dashboard.Report.Filter.Title': '選択したフィルタ',
    'Dashboard.Report.SelectReportFrequency.Label': 'レポートの頻度を選択してください',
    'Dashboard.Report.SelectSessions.Label': '含めるセッションを選択してください',
    'Dashboard.Report.SelectReportStartDate.Label': 'レポート開始日を選択',
    'Dashboard.Report.SelectReportStartDate.Tooltip': 'レポートを毎週受け取ることを選択した場合、レポートは選択した日に毎週届きます。毎月を選択した場合は、毎月その日に配信されます。',
    'Dashboard.Report.SelectAccountOwner.Label': 'アカウント所有者またはファシリテーターを選択してください',
    'Dashboard.Report.Dropdown.Select.Placeholder': '選択する',
    'Dashboard.Report.ManuallyAddRecipients.Label': '受信者を手動で追加する',
    'Dashboard.Report.ManuallyAddRecipients.Label.Placeholder': 'ここにメールを追加してください',
    'Dashboard.Report.RecipientsBox.Label': '受信者',
    'Dashboard.Report.RecipientsBox.RecipientMaxLimit': '受信者は 30 フィートを超えることはできません',
    'Dashboard.Report.SendReport.ConfirmationModal.Text': 'レポートは受信者に送信されました。',
    'Dashboard.Report.ScheduleReport.ConfirmationModal.HeadingText': 'あなたのレポートがスケジュールされました!',
    'Dashboard.Report.ScheduleReport.ConfirmationModal.Text': '[<strong>Edit Schedule</strong>] をクリックすると、いつでも停止または編集できます。',
    'Dashboard.Report.EditSchedule.ConfirmationModal.HeadingText': '変更が保存されました!',
    'Dashboard.Report.EditSchedule.ConfirmationModal.Text': '[<strong>Edit Schedule</strong>] をクリックすると、いつでもレポートを停止できます。',
    'Dashboard.Report.DeleteSchedule.ConfirmationModal.Text': 'スケジュールは削除されました',
    'Dashboard.Report.DeleteSchedule.ConfirmationModal.ConfirmationText': 'このレポートのスケジュールを削除してもよろしいですか?',
    'Dashboard.Report.ConfirmationModal.Button': '分かりました！',
    'Dashboard.Report.DeleteSchedule.ConfirmationButton': 'はい、削除します',
    'Dashboard.Report.EditSchedule.EditButton': 'スケジュールの編集を保存',
    'Dashboard.Report.ReportFrequencyOption.Daily': '毎日',
    'Dashboard.Report.ReportFrequencyOption.Weekly': '毎週',
    'Dashboard.Report.ReportFrequencyOption.Monthly': '毎月',
    'Dashboard.Report.ReportSessionOption.PreviousDay': '前日のセッション',
    'Dashboard.Report.ReportSessionOption.Previous7Days': '過去 7 日間のセッション',
    'Dashboard.Report.ReportSessionOption.CurrentSessions': '当日のセッション',
    'Dashboard.Report.ReportSessionOption.Next7Days': '次の 7 日間のセッション',
    'Dashboard.Report.ReportSessionOption.MonthToDate': '月初来のセッション',
    'MursionPortal.Dashboard.UpcomingSession': '次回のセッション',
    'Dashboard.Report.ReportSessionOption.PreviousFullMonth': '前月のセッション数',
    'Dashboard.Report.ReportSessionOption.Previous30Days': '過去 30 日間のセッション',
    'Projects.ProjectInfo.CheckBox.ScenarioSequencing': 'シナリオシーケンシング',
    'Projects.ProjectInfo.CheckBox.ScenarioSequencing.Text': 'ボタンをクリックして、このプロジェクトのシナリオの順序を表示および構成します。',
    'Projects.ProjectInfo.Button.ScenarioSequence': 'シナリオシークエンス',
    'MursionPortal.Projects.DragAndDropModal.Text': 'シナリオを優先順にドラッグアンドドロップする',
    'MursionPortal.Projects.DragAndDropModal.DragHandlerImage': 'ドラッグハンドルイメージ',
    'MursionPortal.Projects.DragAndDropModal.ModalCloseIcon': '閉じるアイコン',
    'MursionPortal.Project.Scheduled.NextDate': '{nextDate} のスケジュール',
    'MursionPortal.Project.Sequencing': 'シーケンシング',
    'MursionPortal.Project.Checkbox.Sequencing': 'シーケンスを有効にする',
    'MursionPortal.Dashboard.Join.Tooltip': 'このセッションが利用可能になると、参加できるようになります',
    'MursionPortal.Project.InviteToScheduleProject.Tooltip': 'このプロジェクトでシナリオをスケジュールしていない、またはシナリオをキャンセル/欠席した学習者は、スケジュールへの招待メールを受け取ります。',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts': 'キーボードショートカット',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.SpaceBar': 'スペースキー',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.SpaceBar.Text': 'シナリオを選択またはドロップしてください。',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.ArrowKeys': '左右の矢印キーまたは上下の矢印キー',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.ArrowKeys.Text': 'シナリオを左右にドラッグします。',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Esc': 'Esc キー',
    'MursionPortal.Esc': 'Esc',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Esc.Text': 'シナリオシーケンスモーダルを閉じる',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Enter': 'Enter キー',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Enter.Text': 'キーボードショートカットのツールチップを閉じる',
    'MursionPortal.Projects.DragAndDropModal.Tooltip': 'キーボードショートカットのツールチップを開く',
    'Dashboard.Report.DuplicateEntry.ErrorMessage': '重複エントリーはできません',
    'Projects.ProjectInfo.EnableSessionTimeStep': 'セッション時間ステップを有効にする',
    'Projects.ProjectInfo.SessionTimeStepInfo': 'セッションタイムステップの値がプロジェクトレベルで設定されていない場合は、ライセンシーレベルのセッションタイムステップが使用されます。',
    'Session.Edit.Modal.JoinSimulation': 'セッションに参加',
    'MursionPortal.Selector.RequestStatus': 'リクエストステータス',
    'MursionPortal.Dashboard.RetakeSimulation': 'シミュレーションをやり直す',
    'MursionPortal.Dashboard.NextSimulationAssigned': '次のシミュレーションが割り当てられました',
    'MursionPortal.AriaLabel.SelectTime': '時間を選択',
    'Dashboard.Report.Frequency.Date.Select.Placeholder': '開始日を選択',
    'MursionPortal.InstructoScenarioTeamMapping.Header': 'マッピングするシナリオとチームを選択してください',
    'MursionPortal.InstructoScenarioTeamMapping.MappingAlreadyExistMessage': 'このシナリオとチームは、すでに別のコンテキストにマッピングされています。',
    'MursionPortal.DataSummaryPoints.manualFulFilled': '手動でフルフィル',
    'MursionPortal.DataSummaryPoints.pendingRequest': '保留中の需要ベース',
    'MursionPortal.DataSummaryPoints.declined': '拒否されたリクエスト',
    'MursionPortal.DataSummaryPoints.avgFulfilled': 'マニュアルの平均フルフィル',
    'MursionPortal.DataSummaryPoints.totalSwap': '合計スワップ',
    'MursionPortal.DataSummaryPoints.swapAccepted': '受け入れられたスワップ',
    'MursionPortal.Dashboard.MissedSimulations': 'ミスシミュレーション',
    'MursionPortal.Dashboard.RescheduledSimulations': '再スケジュールされたシミュレーション',
    'MursionPortal.Dashboard.BookedSimulations': '予約したシミュレーション',
    'MursionPortal.Dashboard.CancelledSimulations': 'キャンセルされたシミュレーション',
    'Dashboard.UserTable.Column.In': 'イン',
    'Projects.ProjectInfo.ConfirmationDialog.ScenarioSequencing.Text': 'シーケンス処理を無効にしてもよろしいですか? これにより、各シナリオのシーケンス番号も削除されます。',
    'Projects.ProjectInfo.Disable.Sequencing': 'シーケンスを無効にする',
    'MursionPortal.ErrorMessage.TechnicalIssueMsg': 'もう一度やり直してください。技術的な問題が発生しました。',
    'SessionFlow.Text.Event.DeletedByUsername': '{user} によって削除されました',
    'Settings.ScenarioBank.Modal.timestepShouldBeAtLeast': '「セッション時間ステップを有効にする」のチェックを外すか、少なくとも {minutes} 分の時間ステップ値を入力してください',
    'MursionPortal.AriaImage.Attribute.Previous': '以前',
    'MursionPortal.AriaImage.Attribute.Notify': '通知',
    'MursionPortal.AriaImage.Attribute.Upload': 'アップロード',
    'MursionPortal.Report.FilterHeading.SoftwareVersions': 'ソフトウェアのバージョン',
    'MursionPortal.Report.FilterHeading.DeliveryModes': '配信モード',
    'MursionPortal.Report.FilterHeading.LearnerSessionStatuses': '学習者セッションのステータス',
    'MursionPortal.Report.FilterHeading.LearnerStatuses': '学習者のステータス',
    'MursionPortal.Report.FilterHeading.SimulationStatus': 'シミュレーションのステータス',
    'MursionPortal.Project.DuplicateProjectBtnTooltip': 'これにより、このプロジェクトのすべての設定と設定が新しいプロジェクトに複製されます。',
    'MursionPortal.Project.DuplicateProjectBtnText': 'マジックプロジェクトに複製',
    'MursionPortal.ScenarioInfo.Label.Until': 'これを {date} までに {completion Rate} 回まで受けてください。',
    'Clients.Modal.Button.Schedule.Tooltip.Text': '最初に前のシナリオをスケジュールする必要があります',
    'MursionPortal.Scenario.Name': 'シナリオ名',
    'MursionPortal.ScenarioCard.BackToProjects.Button': 'プロジェクトに戻る',
    'MursionPortal.ScenarioCard.ViewMore.Button': 'もっと見る',
    'MursionPortal.ScenarioInfo.Documents': 'シナリオドキュメント',
    'MursionPortal.ScenarioInfo.Documents.File': '{extension} ファイル',
    'MursionPortal.ScenarioCard.ViewSessions': 'セッションを見る',
    'MursionPortal.Label.ScenarioPreview': 'シナリオプレビュー',
    'MursionPortal.ScenarioInfo.AssignedTeams': 'チームを検索または選択',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialists': '認定 SIM スペシャリスト',
    'MursionPortal.ScenarioInfo.AddSimSpecialist': 'SIM スペシャリストを追加',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.PencilIcon': 'アイコンを編集',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.DeleteButton': 'SIMを削除',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.SearchText': 'SIM スペシャリストを検索または選択',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.AddSim': 'SIM を追加',
    'MursionPortal.Label.InactiveSIMSpecialists': '非アクティブ SIM スペシャリスト',
    'MursionPortal.Label.Search.InactiveSIMSpecialists': '非アクティブな SIM スペシャリストを検索または選択します',
    'MursionPortal.Project.InviteToSchedule.Sequencing.Tooltip': 'このシナリオの順序付けが設定されており、まだ前のシナリオをスケジュールしている学習者はいません。',
    'Clients.Modal.Label.FacilitatorView.Tooltip': 'クライアントレベルに設定すると、ファシリテーターはアカウントオーナーレベルの詳細を表示できます。チームレベルに設定されている場合、ファシリテーターは自分が割り当てられているチームに関する情報のみを見ることができます。',
    'Clients.Modal.Label.VoiceAnalytics.Tooltip': 'セッションの自動分析を学習者に提供します。',
    'Clients.Modal.Label.Show.Utilization.Reporting.Tooltip': 'アカウント所有者のダッシュボードに利用状況レポートを追加します。',
    'Clients.Modal.Label.VideoRecordingDataRetentionPolicy.Tooltip': 'クライアントにカスタムデータ保持ポリシーがある場合に有効にします。',
    'MursionPortal.Label.SoftwareType.Tooltip': '「Web のみ」とは、学習者がブラウザベースのプラットフォームである Mursion Magic を介してのみセッションにアクセスできることを示します。',
    'Projects.ProjectInfo.Label.Timeline.Tooltip': 'このプロジェクトのすべてのセッションは、プロジェクトタイムライン内で完了する必要があります。',
    'Projects.ProjectInfo.Checkbox.AllowSessionRecording.Tooltip': '録音を防止するには、チェックを外してください。',
    'MursionPortal.Checkbox.AllowLearnerToRefuseRecording.Tooltip': '学習者は記録をオプトアウトできます。チェックを外した場合、学習者が記録の許可を拒否すると、セッションに参加できなくなります。',
    'Projects.ProjectInfo.label.Tags.Tooltip': 'タグは、クライアントレベルで同様のプロジェクトをすばやく見つけるための組織的なグループです。',
    'Projects.ProjectInfo.label.DemandTime.Tooltip': '学習者がデマンドスロットのシミュレーションをリクエストできる時間枠',
    'Projects.ProjectInfo.label.DemandSlots.Tooltip': 'スケジューリング時に学習者に表示されるスケジューリング オプションの数を設定します。 ゼロに設定すると、ラーナーは空いている時間帯を即座に予約できます。',
    'Projects.ProjectInfo.label.Rescheduling.Tooltip': '学習者がセッションを再スケジュールできるようにします。 同日をチェックしない限り、学習者は同日にセッションを予約できないことに注意してください。',
    'MursionPortal.Project.SameDay.Tooltip': '学習者が同じ暦日の後半にスケジュールを変更できるようにします。',
    'Cleints.Modal.Label.AvailablePrivateSSOLabel': 'プライベート SSO を有効にする:',
    'Cleints.Modal.Label.DomainsLabel': 'クライアント Web ドメイン:',
    'Clients.Modal.Label.Logo': 'クライアントのロゴ:',
    'Projects.ProjectInfo.Label.CancellationDeadline': 'キャンセル期限',
    'Clients.Modal.Label.MursionSocialAttendance.ToolTip': 'Mursion 社交セッション中に学習者の参加を収集します。',
    'Clients.Modal.Label.VideoRecordingDataRetentionDays': 'ビデオ録画データ保持 (日):',
    'Clients.Modal.Label.VideoRecordingDataRetentionPolicy': 'ビデオ録画データ保持ポリシー:',
    'MursionPortal.PasswordRequirementMessage.SpecialCharaters': '少なくとも 1 つの特殊文字',
    'MursionPortal.ErrorMessage.InvalidCaptcha': 'キャプチャが無効です。もう一度やり直してください。',
    'MursionPortal.Project.MissedAlert.TooLateJoin': '参加するには少し遅れて到着しました',
    'MursionPortal.Project.MissedAlert.DontWorry': '心配しないで！ それは起こります。',
    'MursionPortal.Project.MissedAlert.ClickReschedule': 'シミュレーションの再スケジュールをクリックします。',
    'MursionPortal.Project.EmailMissedLink.TooLateJoin.ClickRescheduleTxt': '以下をクリックして、シミュレーションのスケジュールを変更してください。',
    'MursionPortal.Password.Strength.Tooweak': 'パスワードが弱すぎる',
    'MursionPortal.Password.Strength.Weak': 'パスワードが弱いです',
    'MursionPortal.Password.Strength.Medium': 'パスワードは中です',
    'MursionPortal.Password.Strength.Strong': 'パスワードは強力です',
    'Dashboard.LeftPane.User.SchedulingInfo':'スケジューリング情報',
    'Dashboard.Button.ExportToEmail':'データをエクスポート',
    'Filters.All':'すべて',
    'Filters.More':'更に表示',
    'Filters.AllExcept':'以下を除くすべて：',
    'Filters.MoreClients':'その他のクライアント',
    'Filters.AllClients':'すべてのクライアント',
    'Filters.Role':'ロール',
    'Filters.TimeZone':'タイムゾーン',
    'Dashboard.UserActivity.TableHeader':'ユーザーアクティビティデータ',
    'MursionPortal.GenerateLearnerSurveyLink':'調査のリンクをコピー',
    'MursionPortal.SurveyLinkLabel':'あなたの調査のリンクがクリップボードにコピーされました',
    'MursionPortal.Attention':'注意！',
    'MursionPortal.LinkCannotBeGenerated':'このシナリオのプロジェクトでは契約IDフィールドが入力されていないのでリンクを生成できません。',
    'Dashboard.UserTable.Column.UserName':'名前',
    'Dashboard.UserTable.Column.DateInvited':'招待済み',
    'Dashboard.UserTable.Column.DateRegistered':'登録済み',
    'Dashboard.UserTable.Column.DateArchived':'アーカイブ済み',
    'Dashboard.UserTable.Column.DateLastLogin':'最終ログイン',
    'Dashboard.UserTable.Column.UsedSoftwareVersions':'ソフトウェア',
    'Dashboard.UserTable.Column.SessionsScheduled':'予定されているシミュレーション',
    'Dashboard.UserTable.Column.SessionsCompleted':'完了済みのシミュレーション',
    'Dashboard.UserTable.Column.SessionsMissed':'欠席されたシミュレーション',
    'Dashboard.UserTable.Column.LateCancellations':'キャンセルされたシミュレーション',
    'Dashboard.UserTable.Column.SessionsError':'シミュレーションのエラー',
    'Session.Table.Column.ProjectId':'契約ID',
    'Session.Edit.Modal.Button.Leave':'マイセッションをキャンセル',
    'Users.TableModal.SSOIDPlaceHolder':'SSO ID',
    'Users.Table.Modal.SSOID':'SSO ID',
    'Dashboard.SchedulingInfo.Filter.IndustriesAll':'産業',
    'Dashboard.SchedulingInfo.Filter.UsedSoftwareVersion':'ソフトウェア',
    'Dashboard.SchedulingInfo.MainHeader':'スケジューリング情報',
    'Dashboard.SchedulingInfo.TableHeader':'スケジューリング情報データ',
    'RestorePassword.ResetPassword':'パスワードをリセット',
    'RestorePassword.EmailHint':'メールアドレスを入力してください',
    'Dashboard.ProjectUtilization.MainHeader':'プロジェクトの利用状況',
    'Settings.Config.ProjectAndScenario.AllowMursionMagic':'Mursion Magicのタイプ',
    'Dashboard.SessionLearners.MainHeader':'セッション学習者',
    'MursionPortal.Table.ScrollMessage':'追加のコラムを見るには左にスクロールしてください',
    'Dashboard.ProjectsActivity.MainHeader':'プロジェクトのアクティビティ',
    'MursionPortal.Table.ColumnHeader.ProjectId':'契約ID',
    'MursionPortal.Button.ClearAll':'すべてキャンセル',
    'MursionPortal.Dashboard.Chart.Registration':'ユーザー登録',
    'MursionPortal.Dashboard.Chart.InviteAll':'すべて招待',
    'MursionPortal.Dashboard.Chart.Unregistered':'未登録',
    'MursionPortal.Dashboard.Chart.Registered':'登録済み',
    'MursionPortal.Dashboard.Chart.Login':'ログイン',
    'MursionPortal.Dashboard.Chart.NotLoggedIn':'ログインしていません',
    'MursionPortal.Dashboard.Chart.LoggedIn':'ログイン済み',
    'MursionPortal.Dashboard.Chart.Scheduling':'スケジュール済みのユーザー',
    'MursionPortal.Dashboard.Chart.NotScheduled':'未スケジュール',
    'MursionPortal.Dashboard.Chart.Simulations':'シミュレーション',
    'MursionPortal.Dashboard.Chart.LateCancellations':'遅発キャンセル',
    'MursionPortal.Dashboard.Chart.Completed':'完了済み',
    'MursionPortal.Dashboard.Chart.Errors':'エラー',
    'MursionPortal.Dashboard.Chart.Scheduled':'スケジュール済み',
    'MursionPortal.Dashboard.Chart.Tooltip.Registered':'招待済みのユーザーまたはプロビジョンされログインしているSSOユーザー。',
    'MursionPortal.Dashboard.Chart.Tooltip.Unregistered':'招待済みだがログインしていないユーザーまたはプロビジョンされたがログインしていないSSOユーザー。',
    'MursionPortal.Dashboard.Chart.Tooltip.NotScheduled':'シミュレーションをスケジュールしていない登録済み/プロビジョン済みのSSOユーザー',
    'MursionPortal.Dashboard.Chart.Tooltip.Scheduled':'シミュレーションを1回以上スケジュールしている登録済み/プロビジョン済みのSSOユーザー',
    'MursionPortal.Label.ProjectId':'契約ID',
    'MursionPortal.Label.SoftwareType':'タイプ',
    'MursionPortal.Label.SharingOrDownloading':'共有またはダウンロードを許可しない',
    'Settings.SSO.Modal.EmployeeIDClaimName':'従業員IDの請求名',
    'Settings.SSO.Modal.LocationClaimName':'場所の請求名',
    'Settings.SSO.Modal.DepartmentClaimName':'部署の請求名',
    'Settings.SSO.Modal.PhoneClaimName':'電話の請求名',
    'Settings.SSO.Modal.TitleClaimName':'役職の請求名',
    'Settings.SSO.Modal.TimezoneClaimName':'タイムゾーンの請求名',
    'Settings.SSO.Modal.LanguageClaimName':'言語の請求名',
    'MursionPortal.ProjectId':'契約ID',
    'MursionPortal.AltText.MursionPortal':'Mursionのポータル',
    'MursionPortal.Message.ClickToOpenApplicationDefault':'あなたのシミュレーションに参加するには以下をクリックしてください',
    'MursionPortal.Message.ClickToOpenApplicationDesktopWeb':'Mursionのデスクトップアプリケーションからシミュレーションにアクセスするには以下のボタンをクリックしてください',
    'MursionPortal.Message.OpenWebApplication':'また、ウェブベースのアプリケーションであるMursion Magicでもシミュレーションにアクセスできます',
    'MursionPortal.Button.OpenMursionWeb':'Mursion Masicを起動',
    'MursionPortal.Message.Or':'または',
    'MursionPortal.Message.PortalHeadingText':'EQトレーニングシミュレーション',
    'ITManager.TechRestriction.Table.CreateButton':'制限を追加',
    'ITManager.TechRestriction.EditDialog.TagName':'タグ名',
    'ITManager.TechRestriction.EditDialog.Title':'テクノロジー制限を編集',
    'ITManager.TechRestriction.CreateDialog.Title':'新しいテクノロジー制限',
    'MursionPortal.DateTimePicker.StartDate':'開始日',
    'MursionPortal.DateTimePicker.EndDate':'終了日',
    'MursionPortal.DateTimePicker.TimeNotAvailable':'選択した時間は利用できません',
    'ITManager.TechRestriction.ViewDialog.Title':'テクノロジー制限を閲覧',
    'MursionPortal.DateTimePicker.Timezone':'タイムゾーン',
    'MursionPortal.Text.Team':'チーム',
    'MursionPortal.Text.Industry':'産業',
    'MursionPortal.Text.Environment':'環境',
    'MursionPortal.Text.Avatar':'アバター',
    'MursionPortal.Status.Capitalized.Upcoming':'今後のシミュレーション',
    'MursionPortal.Status.Capitalized.Undefined':'未定義',
    'MursionPortal.Status.Capitalized.Orphan':'シムスペシャリスト無し',
    'MursionPortal.Status.Capitalized.Waif':'予約時間にシムスペシャリスト無し',
    'MursionPortal.Status.Capitalized.Reserved':'割り当て済み',
    'MursionPortal.Status.Capitalized.Booked':'予約済み',
    'MursionPortal.Status.Capitalized.Pending':'保留中',
    'MursionPortal.Status.Capitalized.Running':'実行中',
    'MursionPortal.Status.Capitalized.Missed':'欠席',
    'MursionPortal.Status.Capitalized.NeedsReview':'要_レビュー',
    'MursionPortal.Status.Capitalized.Reviewed':'レビュー済み',
    'MursionPortal.Status.Capitalized.LicenseCancelled':'キャンセル済み',
    'MursionPortal.Status.Capitalized.Cancelled': 'キャンセル済み',
    'MursionPortal.Status.Capitalized.Error':'エラー',
    'MursionPortal.Status.Capitalized.SimError': 'ERROR',// TODO add translations
    'MursionPortal.Status.Capitalized.Offline':'オフライン',
    'MursionPortal.Status.Abbreviation.PendingNoLearners':'P',
    'MursionPortal.Status.Abbreviation.PendingNoParticipants':'R',
    'MursionPortal.Status.Abbreviation.Swap':'S',
    'MursionPortal.Status.Abbreviation.Prebooked':'PB',
    'MursionPortal.Status.Abbreviation.Booked':'B',
    'MursionPortal.Status.Abbreviation.Active':'A',
    'MursionPortal.Status.Abbreviation.Completed':'C',
    'MursionPortal.Status.Abbreviation.Missed':'M',
    'MursionPortal.Status.Abbreviation.NeedsReview':'NR',
    'MursionPortal.Status.Abbreviation.Error':'E',
    'Settings.SSO.Table.NoSsoConfigurationsFound':'SSOの設定がみつかりませんでした。',
    'MursionPortal.Label.SelfReviewTracker':'セルフレビュートラッカー',
    'MursionPortal.ToggleButton.All':'すべて',
    'MursionPortal.Label.PerformanceTracker':'パフォーマンストラッカー',
    'MursionPortal.ToggleButton.TurnTaking':'話者交代',
    'MursionPortal.Tooltip.Collaborative':'これは、あなたとアバターの両方が会話中にお互いを遮ることなく話者を交代した時間のデータです。',
    'MursionPortal.Tooltip.Disruptive':'これは、あなたまたはアバターのいずれかが会話中に相手を遮った時間のデータです。',
    'MursionPortal.Tooltip.Speaking':'これは、あなたが会話の間にアバターに話すために使った時間のデータです。',
    'MursionPortal.Tooltip.Listening':'これは、あなたが会話の間にアバターの話を聞くために使った時間のデータです。',
    'MursionPortal.Tooltip.MiScore':'会話中のあなたの社会的有効性です。',
    'MursionPortal.Tooltip.Percentile':'これがUIの中で意味することの説明が含まれています',
    'MursionPortal.Label.MiScore':'MIスコア',
    'MursionPortal.Label.Percentile':'パーセンタイル',
    'MursionPortal.Label.NotEnoughData':'データ不足',
    'MursionPortal.Label.Listening':'リスニング',
    'MursionPortal.Label.Speaking':'スピーキング',
    'MursionPortal.Label.Collaborative':'協力的',
    'MursionPortal.Label.Disruptive':'非協力的',
    'MursionPortal.ToggleButton.LocalLeaderboard':'ローカルリーダーボード',
    'MursionPortal.ToggleButton.GlobalLeaderboard':'グローバルリーダーボード',
    'MursionPortal.ToggleButton.DeepInsights':'ディープインサイト',
    'MursionPortal.ErrorMessage.DataBeingProcessed':'現在、セッションの分析データが処理されています。このデータは処理が完了すると、このページに表示されます。',
    'MursionPortal.ErrorMessage.UnsupportedFormat':'セッションの分析が存在しないかサポートされていないフォーマットです。',
    'MursionPortal.Label.Positive':'ポジティブ',
    'MursionPortal.Label.Negative':'ネガティブ',
    'MursionPortal.Label.Neutral':'ニュートラル',
    'MursionPortal.Header.Tracking':'追跡中',
    'MursionPortal.Button.ReadMore':'更に読む',
    'MursionPortal.Button.Hide':'隠す',
    'MursionPortal.Title.Interchange':'交替。自然な会話で介入や妨害をせずに話者を交替すること',
    'MursionPortal.Title.InterventionByYou':'あなたによる介入。話している人の話を上手く遮って続けさせないようにすること',
    'MursionPortal.Title.InterventionByAvatars':'アバターによる介入。話している人の話を上手く遮って続けさせないようにすること',
    'MursionPortal.Title.InterjectionByYou':'あなたによる妨害。話している人の話を遮ろうとしたが上手くいかなかったこと',
    'MursionPortal.Title.InterjectionByAvatars':'アバターによる妨害。話している人の話を遮ろうとしたが上手くいかなかったこと',
    'MursionPortal.Title.Pauses':'一時停止。0.2秒より長く0.75秒より短いもの',
    'MursionPortal.Label.VoiceSelf':'自分の声',
    'MursionPortal.Label.VoiceOthers':'他者の声',
    'MursionPortal.Label.Avatars':'アバター',
    'MursionPortal.Label.You':'あなた',
    'MursionPortal.ColumnHeader.User':'ユーザー',
    'MursionPortal.ColumnHeader.Percentile':'パーセンタイル',
    'MursionPortal.ColumnHeader.ScenariosCompleted':'完了したシナリオ',
    'MursionPortal.Message.LeaderboardsUpdated':'リーダーボードはユーザーが新たにシミュレーションを完了すると更新されます。',
    'MursionPortal.Message.CheckBack':'自分の現状を確認するために時々チェックしてみてください！',
    'MursionPortal.Label.SimStart':'シミュレーション開始時間',
    'MursionPortal.Label.SimEnd':'シム終了',
    'MursionPortal.Tooltip.Summary.Speaking':'会話全体でアバターに話をするために使った時間',
    'MursionPortal.Tooltip.Summary.Listening':'会話全体でアバターの話を聞くために使った時間',
    'MursionPortal.Capitalized.NotAvailable':'N/A',
    'MursionPortal.ColumnHeader.Trait':'特徴',
    'MursionPortal.ColumnHeader.Me':'自分',
    'MursionPortal.ColumnHeader.OthersAvg':'その他(平均)',
    'MursionPortal.TooltipText.Speaking':'あなたは人々の{percentage}よりも喋っています。',
    'MursionPortal.TooltipText.Listening':'あなたは人々の{percentage}よりも聞いています。',
    'MursionPortal.TooltipText.Collaborative':'あなたは人々の{percentage}よりも協力的です。',
    'MursionPortal.TooltipText.Disruptive':'あなたは人々の{percentage}よりも非協力的です。',
    'MursionPortal.Label.FeedbackValue':'フィードバック値',
    'MursionPortal.Button.AnalyticsData':'分析データ',
    'MursionPortal.Button.RawAnalyticsData':'分析の生データ',
    'MursionPortal.Header.SocialEffectiveness':'社会的有効性',
    'MursionPortal.Label.Current':'現在',
    'MursionPortal.Header.ArticulationRate':'調音速度',
    'MursionPortal.Label.Average':'平均',
    'MursionPortal.Header.Conversation':'会話',
    'MursionPortal.Header.SpeakingListening':'スピーキング/リスニング',
    'MursionPortal.Header.CumulativeTurnTaking':'累積話者交替',
    'MursionPortal.Header.InstantaneousTurnTaking':'瞬間的話者交替',
    'MursionPortal.ColumnHeader.ScenarioTemplate':'シナリオの_テンプレート',
    'MursionPortal.ColumnHeader.Provider':'プロバイダー',
    'MursionPortal.Label.AbbreviatedMinute':'m',
    'MursionPortal.Header.Mursion':'<code>M</code>ursion',
    'MursionPortal.Header.Index':'<code>I</code>ndex',
    'MursionPortal.Header.Score':'スコア',
    'MursionPortal.visibilityHidden.Selected':'選択済み',
    'MursionPortal.AriaLabel.CloseTechSupport':'テクノロジーサポートを閉じる',
    'MursionPortal.Monitoring':'モニタリング中',
    'MursionPortal.Monitoring.User.FirstLetter':'U',
    'MursionPortal.Monitoring.Peer.Label':'P2P',
    'MursionPortal.Monitoring.Peer.NoUser':'詳細を見るユーザーを選択してください',
    'MursionPortal.Monitoring.Sessions.Total':'総セッション',
    'MursionPortal.Monitoring.Sessions.Displayed':'ML3セッション(表示)',
    'MursionPortal.Monitoring.Sessions.NotDisplayed':'非ML3セッション',
    'MursionPortal.Monitoring.Card.StartTime':'開始時間',
    'MursionPortal.Monitoring.Notification.NewSession':'新しいセッションが追加されました。',
    'MursionPortal.ClickRefresh':'この変更を閲覧するには「更新」をクリックしてください。',
    'MursionPortal.AriaLabel.DraftScenarioSuperScript':'下書きシナリオの上付き文字{statusConfiguration}',
    'MursionPortal.AriaLabel.FinalizedScenarioSuperScript':'完成シナリオの上付き文字{statusConfiguration}',
    'MursionPortal.AriaLabel.StartedScenarioSuperScript':'開始済みシナリオの上付き文字{statusConfiguration}',
    'MursionPortal.AriaLabel.FinishedScenarioSuperScript':'終了シナリオの上付き文字{statusConfiguration}',
    'MursionPortal.AriaLabel.ArchivedScenarioSuperScript':'アーカイブ済みシナリオの上付き文字{statusConfiguration}',
    'Calendar.Button.ScheduleEventON':'{thisDate}にイベントをスケジュール',
    'Calendar.Button.CantScheduleON':'{thisDate}にイベントをスケジュールできません',
    'MursionPortal.AriaLabel.ChooseFileButton':'ファイルボタンを選択',
    'MursionPortal.Label.VisibilityHidden.FileUploaded':'ファイルがアップロードされました',
    'MursionPortal.VisibilityHidden.EntityAdded':'エンティティが追加されました',
    'MursionPortal.VisibilityHidden.EntityRemoved':'エンティティが削除されました',
    'MursionPortal.Legend.VisibilityHidden.StationTypeHomeOffice':'ステーションタイプ（オフィス、ホーム）',
    'MursionPortal.Legend.VisibilityHidden.HoursFullTimePartTime':'時間（フルタイム、パートタイム）',
    'MursionPortal.AriaLabel.ShowTechSupport':'テクノロジーサポートを表示',
    'MursionPortal.Monitoring.Card.UntilTheEnd':'残り時間',
    'MursionPortal.Monitoring.Card.OverTime':'延長',
    'MursionPortal.Title.DemandBasedSessionScheduling':'デマンドベースのセッションのスケジューリング',
    'MursionPortal.CheckboxLabel.SchedulingByDemand':'デマンドに応じたスケジューリング',
    'MursionPortal.CheckboxLabel.ProjectTimezone':'プロジェクトのタイムゾーン',
    'MursionPortal.CheckboxLabel.DemandTime':'デマンド時間',
    'MursionPortal.Label.DemandTimeStart':'デマンド時間開始',
    'MursionPortal.Label.DemandTimeEnd':'デマンド時間終了',
    'MursionPortal.Label.DemandWindow':'デマンドウィンドウ',
    'MursionPortal.Project.Days':'日',
    'MursionPortal.MyProfile.Hover.Profile':'プロフィール{userName}',
    'Mursion.Portal.SessionUserStatus.Connected':'接続済み',
    'Mursion.Portal.SessionUserStatus.Late':'遅発',
    'Mursion.Portal.SessionUserStatus.Immersing':'没頭中',
    'Mursion.Portal.SessionUserStatus.Left':'退出済み',
    'Mursion.Portal.SessionUserStatus.Declined':'拒否済み',
    'MursionPortal.DemandBasedScheduling.SubmitPage.ThankYou':'ありがとうございます！',
    'MursionPortal.DemandBasedScheduling.SubmitPage.Message':'ご提供いただいたタイムスロットをもとにシミュレーションの最終調整を行っています。詳細はメールにてご連絡いたします。近いうちにお会いしましょう！',
    'MursionPortal.DemandBasedScheduling.SubmitPage.GoToDashboard':'マイダッシュボードに移動',
    'MursionPortal.DemandBasedScheduling.ScheduleASession':'セッションをスケジュール',
    'MursionPortal.DemandBasedScheduling.Details':'詳細',
    'MursionPortal.DemandBasedScheduling.Slot':'スロット{count}',
    'MursionPortal.DemandBasedScheduling.PickDateAndTime':'設定するための日時を選択してください',
    'MursionPortal.DemandBasedScheduling.Button.Set':'設定',
    'MursionPortal.DemandBasedScheduling.Button.Clear':'クリア',
    'MursionPortal.DemandBasedScheduling.Jumbotron.Text':'シナリオの詳細は選択をすると読み込まれます',
    'MursionPortal.AriaDescribedby.StartDate':'開始日：クエスチョンマークキーを押すと日付変更のためのキーボードショートカットが表示されます。',
    'MursionPortal.AriaDescribedby.EndDate':'終了日：クエスチョンマークキーを押すと日付変更のためのキーボードショートカットが表示されます。',
    'MursionPortal.VisibilityHidden.Footer':'フッター',
    'MursionPortal.VisibilityHidden.Header':'ヘッダー',
    'MursionPortal.AriaLabel.FullScreen':'フルスクリーン',
    'MursionPortal.AriaLabel.ClientNameEventsDisplayed':'カレンダーに表示されているクライアント{clientName}のイベント',
    'MursionPortal.AriaLabel.SideNavigationToggle':'サイドナビゲーショントグル',
    'MursionPortal.AriaLabel.AddTags':'タグを追加',
    'MursionPortal.VisibilityHidden.Navigation':'ナビゲーション',
    'MursionPortal.AriaLabel.SortByDescendingOrderButton':'降順で並び替えボタン',
    'MursionPortal.AriaLabel.SortByAscendingOrderButton':'昇順で並び替えボタン',
    'MursionPortal.VisibilityHidden.ProjectsTabSelected':'プロジェクトのタブが選択されました',
    'MursionPortal.VisibilityHidden.TeamsTabSelected':'チームのタブが選択されました',
    'MursionPortal.VisibilityHidden.SessionsTabSelected':'セッションのタブが選択されました',
    'MursionPortal.VisibilityHidden.UsersTabSelected':'ユーザーのタブが選択されました',
    'MursionPortal.Error.PageNotFound':'404 ページがみつかりませんでした',
    'MursionPortal.Text.PageNotFound':'ページがみつかりませんでした。リンク先が正しい場合は、ページを閲覧する権限があるかどうかを確認してください。',
    'MursionPortal.Text.Success':'成功！',
    'MursionPortal.Text.YouAreNowSignedIn':'現在、あなたはMursionのシミュレーションソフトウェアにサインインしています。',
    'MursionPortal.Text.PleaseReturnToDesktop':'タスクバー(Windows)またはドック(Mac)に表示されている\'M\'のアイコンをクリックしてデスクトップのアプリケーションに戻ってください。',
    'MursionPortal.Client.EmailSettings.Header.DemandBased':'デマンドベース',
    'MursionPortal.Client.EmailSettings.TimeSlotsReceived':'タイムスロットを受け取りました',
    'MursionPortal.Label.Past':'過去のシミュレーション' ,
    'MursionPortal.Monitoring.SessionsSummary.Preparation':'準備' ,
    'MursionPortal.Monitoring.SessionsSummary.Normal':'ノーマル' ,
    'MursionPortal.Monitoring.SessionsSummary.Error':'エラー' ,
    'MursionPortal.Monitoring.SessionsSummary.Issue':'問題' ,
    'MursionPortal.Monitoring.SessionsSummary.NotLoggedIn':'ログインしていません' ,
    'MursionPortal.Monitoring.Legend.Label':'キャプション' ,
    'MursionPortal.Monitoring.Legend.Dashboard':'ダッシュボード' ,
    'MursionPortal.Label.SimSpecialist':'シムスペシャリスト' ,
    'MursionPortal.Monitoring.SessionsSummary.ConnectedToPeers':'ピアと接続' ,
    'Requests.Table.CertifiedScenarios':'認定済みシナリオ',
    'Requests.Table.UncertifiedScenarios':'未認定シナリオ',
    'Requests.Table.NoUpComingDemands':'上記の基準に一致したデマンドベースのリクエストはありません。',
    'Requests.Table.DeclineReason':'拒否－理由を選択',
    'Requests.Table.UnAvailable':'利用不可',
    'Requests.Table.NotPrepared':'準備されていません',
    'Requests.Table.Other':'その他',
    'Requests.Table.Availability':'利用可能状況を選択',
    'Requests.Table.Declined':'拒否済み',
    'Requests.Table.SuccessfullySent':'リクエストが送信されました',
    'Requests.Table.RequestCertificate':'認定書をリクエスト',
    'Requests.Table.DeclineReasonText':'セッションを拒否',
    'Requests.Table.AvailabilitySlot':'利用可能スロット',
    'Requests.Table.DemandBased':'デマンドベース',
    'MursionPortal.Setting.Integrations':'統合',
    'MursionPortal.Setting.Integrations.Title':'統合を選択してください',
    'MursionPortal.Setting.Integrations.Header.PlatformConfigurations':'プラットフォームの設定',
    'MursionPortal.Button.AddConfigurations':'設定を追加',
    'Settings.LTI.PlaceHolder.EnterConfigName':'設定名を入力',
    'Settings.LTI.Table.ColumnHeader.DeploymentID':'デプロイメントID',
    'Settings.LTI.Table.ColumnHeader.Version':'バージョン',
    'Settings.LTI.Table.Caption':'LTI設定リスト',
    'Settings.LTI.Table.Hover.ViewEditLti':'LTIを表示/編集',
    'Settings.LTI.Table.NoLtiConfigFound':'LTI設定がみつかりませんでした',
    'MursionPortal.EditLtiModal.Button.DeleteLti':'設定を削除',
    'MursionPortal.EditLtiModal.Button.SaveLtiConfig':'設定を保存',
    'MursionPortal.Lti.NewLTIConfig':'新しいLTI設定',
    'MursionPortal.Lti.CreateLTIConfig':'LTI設定を作成',
    'Settings.LTI.Modal.PlatformIssuerIdentifier':'プラットフォーム発行者識別子',
    'Settings.LTI.Modal.PublicKeysetEndpoint':'パブリックキーセットのエンドポイント',
    'Settings.LTI.Modal.DeploymentId':'デプロイメントID',
    'Settings.LTI.Modal.RolesMapping':'ロールマッピング',
    'Settings.LTI.Modal.RolesMapping.PortalRole':'ポータルのロール',
    'Settings.LTI.Modal.RolesMapping.LTIRole':'LTIのロール',
    'MursionPortal.ErrorMessage.LtiIdMustBeSpecified':'LTI IDを指定する必要があります。',
    'MursionPortal.Setting.Integrations.LTIVersion':'LTIのバージョン',
    'MursionPortal.Dialog.CreateLTIConfiguration.SuccessMessage':'LTIの設定が正常に作成されました。',
    'MursionPortal.DemandBasedScheduling.TimeBlockUnavailableReserved':'タイムブロックが利用できません－他のスケジュールされたシミュレーションのために予約されています。',
    'Requests.Table.ViewAvailability':'利用可能状況を見る',
    'Settings.LTI.Modal.DeletionConfirmationText':'このロールマッピングを削除してもよろしいですか？',
    'Settings.ScenarioBank.Modal.InactiveCertification':'非アクティブな認定書' ,
    'Settings.ScenarioBank.Modal.NoInactiveSimSpecialist':'非アクティブなシムスペシャリストがいません' ,
    'Settings.ScenarioBank.Modal.EditInactiveCertification':'非アクティブな認定書を編集' ,
    'Settings.ScenarioBank.Modal.NoInactiveCertification':'非アクティブな認定書' ,
    'MursionPortal.RequestStatus.All':'リクエストステータス：すべて',
    'MursionPortal.RequestStatus.Accepted':'リクエストステータス：受付済み',
    'MursionPortal.RequestStatus.Declined':'リクエストステータス：拒否済み',
    'MursionPortal.RequestStatus.Pending':'リクエストのステータス：保留中',
    'Requests.Table.RequestCertification':'認定書をリクエスト',
    'Requests.Table.RequestTime':'時間をリクエスト',
    'Requests.Table.ScheduleType':'スケジュールタイプ',
    'Requests.Table.AcceptTime':'時間を受諾',
    'Requests.Table.Scheduling':'スケジューリング',
    'Requests.Table.FulfilledIn':'以下で履行',
    'MursionPortal.DemandBasedScheduling.Timezone':'タイムゾーン',
    'MursionPortal.DemandBasedScheduling.Timezone.Tooltip':'タイムゾーンを変更するにはプロフィール設定にアクセスしてください。',
    'MursionPortal.Results.Found':'{count}件の結果がみつかりました',
    'MursionPortal.Header.VisibilityHidden.ClientUsers':'クライアントユーザー',
    'MursonPortal.SchedulingType.Auto':'自動',
    'MursonPortal.SchedulingType.Manual':'手動',
    'MursionPortal.ScheduleTypeSelector.All':'スケジュール：すべて',
    'MursionPortal.ScheduleTypeSelector.Auto':'スケジュール：自動',
    'MursionPortal.ScheduleTypeSelector.Manual':'スケジュール：手動',
    'MursionPortal.ScheduleTypeSelector.NA':'スケジュール：N/A',
    'Integrations.LTI.AppId':'アプリID',
    'Integrations.LTI.ConfigurationId':'設定ID',
    'Settings.LTI.ModalHeader.EditLtiConfiguration':'LTI設定を編集',
    'Settings.LTI.Modal.DeletionConfigConfirmationText':'このLTI設定を削除してもよろしいですか？',
    'MursionPortal.Rescheduling.SubmitPage.Message':'間もなく詳細を記載したメールをお送りいたします。近いうちにお会いしましょう！',
    'MursionPortal.Rescheduling.RescheduleSimulation':'シミュレーションのスケジュールを変更',
    'MursionPortal.Rescheduling.SlotSelectionMessage':'申し訳ございません。あなたのシミュレーションを作成できませんでした。ご都合のよろしい日時を選択してください！',
    'MursionPortal.IVSBrowserWarning.Safari17.Message1': 'The browser version you are currently using is not supported for your Mursion session',  // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Message2': 'When joining your Mursion simulation, use one of these browsers:',  // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Firefox': "Firefox",  // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Chrome': "Chrome", // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Chrome.Subtitle': "Recommended", // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Edge': "Edge", // TODO: add translations
    'MursionPortal.Result.Found':'{count}件の結果がみつかりました',
    'MursionPortal.Project.Rescheduling':'スケジュール変更中',
    'Session.Edit.Modal.MissedSession':'欠席したセッション',
    'Session.Edit.Modal.NoLongerRequired':'必要なくなった',
    'Session.Edit.Modal.TechDifficulties':'技術的問題がある',
    'Session.Edit.Modal.Other':'その他',
    'Session.Edit.Modal.CancelBecause':'はい、以下の理由でセッションをキャンセルします：',
    'Session.Edit.Modal.CancelSimulation':'シミュレーションをキャンセル',
    'MursionPortal.UserConsent.Heading':'収録許可',
    'MursionPortal.UserConsent.Title':'ご自身の学習のためにこのシミュレーションセッションを収録しますか？',
    'MursionPortal.UserConsent.Description':'「同意します」を選択すると、Mursionのソフトウェアがあなたのライブシミュレーションを処理し、Mursionのソフトウェアポータルで見直しができるようにシミュレーションセッションの動画収録を行います。なお、あなたに代わってMursionのソフトウェアライセンスを取得したクライアントから要望があった場合、収録されたシミュレーションセッションのコピーがそのクライアントの選んだコーチや、メンター、またはレビュアーと共有されることにご注意ください。すべてのレビュアーは、あなたが異なるプライバシー条件に同意している場合を除き、<code>Mursionのプライバシーポリシー</code>に従う契約上の義務を負います。適用される法律で要求される場合を除き、Mursionはあなたの許可なく収録されたあなたの動画を配布または開示することはありません。Mursionは、シミュレーション技術の向上のために、あなたがソフトウェアを使用した際の匿名化されたデータを使用します。',
    'MursionPortal.UserConsent.IConsent':'同意します',
    'MursionPortal.UserConsent.IDoNotConsent':'同意しません',
    'MursionPortal.RecordingLinkPermisssion.Title':'収録許可',
    'MursionPortal.RecordingLinkPermisssion.DescriptionOne':'このセッションの収録を送ってもらいたいですか？',
    'MursionPortal.RecordingLinkPermisssion.DescriptionTwo':'その場合はメールボックスを確認し、メールアドレスを認証してください。利用できる場合は明日リンクが送られてきます。',
    'MursionPortal.PublicSession.PageTitle':'シミュレーションに参加するにはあなたの情報を入力してください',
    'MursionPortal.PublicSession.Form.label.FirstName':'名前',
    'MursionPortal.PublicSession.Form.label.LastName':'苗字',
    'MursionPortal.PublicSession.Form.label.Email':'メールアドレス(任意)',
    'PublicSession.Form.Button.Join':'参加',
    'MursionPortal.PublicSession.Form.Placeholder.FirstName':'あなたの名前',
    'MursionPortal.PublicSession.Form.Placeholder.LastName':'あなたの苗字',
    'MursionPortal.PublicSession.Form.Placeholder.Email':'name@provider.com',
    'MursionPortal.PublicSession.Form.Placeholder.HaveAccount':'既にアカウントをお持ちですか？',
    'MursionPortal.PublicSession.Form.Placeholder.LogInHere':'こちらからログインしてください',
    'Session.Edit.Modal.ToolTip.Google':'あなたのGoogleカレンダーにイベントを追加し、必要に応じて承認を確認してください',
    'Session.Edit.Modal.ToolTip.OutlookIcalOther':'クリックしてデスクトップにイベントをダウンロードし、カレンダーアプリに追加してください',
    'Session.Edit.Modal.SimSpecialistAssigned':'シムスペシャリストが割り当てられました',
    'Session.Edit.Modal.SessionSimSpecialistField.AddSIMSpecialist':'シムスペシャリストを追加',
    'Session.Edit.Modal.DemandBasedTimeSlots':'デマンドベースのタイムスロット',
    'Session.Edit.Modal.Reschedule':'スケジュール変更',
    'Session.Edit.Modal.AddLearner':'学習者を追加',
    'MursionPortal.Label.SearchOrSelectLearners':'学習者を検索または選択',
    'MursionPortal.Client.EmailSettings.Header.Rescheduling':'スケジュール変更中',
    'MursionPortal.Client.EmailSettings.RescheduleMissedSession':'欠席したセッションを再スケジュールしました',
    'Session.Edit.Modal.OtherPrioritiesAtWork':'仕事での他の優先事項',
    'MursionPortal.Modal.Header.Sorry':'申し訳ございません！',
    'MursionPortal.Modal.UnschedulableSessionMsg':'このセッションはスケジュールできません。',
    'MursionPortal.Rescheduling.Slot.NoLongerAvailable':'選択した時間はもはや利用できません。異なるオプションを選択してください。',
    'MursionPortal.EmailVerificationPage.Success':'メールアドレスの認証が完了しました！',
    'MursionPortal.EmailVerificationPage.SuccessDesc':'あなたのメールアドレスの認証が完了しました',
    'MursionPortal.EmailVerificationPage.Error':'メールアドレスを認証できませんでした！',
    'MursionPortal.EmailVerificationPage.ErrorDesc':'何らかの問題が発生しました。メールアドレスの認証をもう一度お試しください',
    'Settings.Config.Heading.SplashPageAttendance':'参加',
    // 'Clients.Modal.Label.ShowSplashPage':'未登録ユーザーの参加/収録の同意',
    // 'Clients.Modal.Label.ShowSplashPage.ToolTip':'ML3z/Meetのシミュレーションで未登録ユーザーのための参加と収録の同意を集めてください。',
    // 'Settings.Config.Invitation.ShowSplashPage':'ML3z/Meetのシミュレーションで未登録ユーザーのための参加と収録の同意を集めてください。',
    'Settings.Config.External.Users':'(クライアント設定から無効化できます)',
    'Settings.Config.Invitation.ExternalUser':'外部ユーザーの参加用',
    'MursionPortal.Scenario.Vignette.ButtonText.ShowMore.SessionDetails':'セッションの詳細',
    'MursionPortal.DemandBasedScheduling.ProjectDropdown.Placeholder':'プロジェクトを選択',
    'MursionPortal.DemandBasedScheduling.ScenarioDropdown.Placeholder':'シナリオを選択',
    'Session.Edit.Modal.Label.SearchOrSelectASimSpecialist':'シムスペシャリストを検索または選択',
    'MursionPortal.Button.Exclamation.GotIt':'分かりました！',
    'MursionPortal.Modal.UnavailableSlotMsg':'現在、あなたが選択した時間は利用できません。新しいタイムスロットを選択してください。',
    'MursionPortal.SimAttendance.NoLongerAvailable':'あなたはもはやこのシミュレーションの参加を編集できません。',
    'MursionPortal.SimAttendance.Midnight':'あなたはそれぞれの日の深夜までに参加を完了しなければなりません。このシミュレーションへの参加はシステムステータスに戻ります。異常が発生した場合はサポートまでお問い合わせください。',
    'MursionPortal.SimAttendance.Instructions':'説明',
    'MursionPortal.SimAttendance.Attendee':'参加者',
    'MursionPortal.SimAttendance.Tooltip.Missed':'このシミュレーションに接続しなかった学習者。',
    'MursionPortal.SimAttendance.Tooltip.Declined':'シミュレーションに接続したが参加を拒否し、50%以上を完了する前に退出した学習者。',
    'MursionPortal.SimAttendance.Add.Attendee':'参加者を追加',
    'Session.Edit.Modal.EditAttendance':'参加を編集',
    'Session.Edit.Modal.CompleteAttendance':'参加を完了',
    'MursionPortal.SimAttendance.Late':'遅発',
    'MursionPortal.SimAttendance.Left':'退出済み',
    'MursionPortal.Instructions.First':'各参加者の参加ステータスを選択してください(ステータスの詳細を見るにはマウスオーバーしてください)。学習者がリストされていない場合は以下をクリックしてください：',
    'MursionPortal.Instructions.Second':'左下にある機能。',
    'MursionPortal.TableColumn.SimsAction':'シムのアクション',
    'Session.Edit.Modal.Label.SelectTrainer':'トレーナーを検索または選択',
    'MursionPortal.Project.DemandSlots':'デマンドスロット',
    'MursionPortal.CompanyCard.InviteOperations':'招待オペレーション',
    'MursionPortal.CompanyCard.Modal.Header.NewOperations':'新規オペレーション',
    'Settings.Config.Invitation.ShowContractFeature':'契約の追加を許可',
    'Settings.Config.Contract.Description':'(契約の進捗を追跡するため)',
    'MursionPortal.Contract.Edit':'編集',
    'MursionPortal.Contract.ContractName':'契約名',
    'MursionPortal.Contract.ContractID':'契約ID',
    'MursionPortal.Contract.ContractID.PlaceHolder':'Hubspotから契約IDを入力',
    'MursionPortal.Contract.StartAndEndDates':'契約の開始日および終了日',
    'MursionPortal.Contract.Notes.PlaceHolder':'CSMおよびBDDのみに表示される他の情報を入力してください。',
    'MursionPortal.Contract.LineItem.Add':'契約条項を追加',
    'MursionPortal.Contract.LineItem.Add.Instruction':'シミュレーションの条項は複数追加できます。他のすべてのタイプには1つの条項しか追加できません。',
    'MursionPortal.Contract.LineItemType':'条項のタイプ',
    'MursionPortal.Contract.LineItemType.PlaceHolder':'条項のタイプを選択',
    'MursionPortal.Contract.Quantity':'量',
    'MursionPortal.Contract.Quantity.PlaceHolder':'量を入力',
    'MursionPortal.Contract.DeliveryMode.PlaceHolder':'提供モードを選択',
    'MursionPortal.Contract.SaveContract':'契約の詳細を保存',
    'MursionPortal.Contract.Length.PlaceHolder':'長さを保存',
    'MursionPortal.Contract.SowName.PlaceHolder':'契約名を入力',
    'MursionPortal.Client.Tab.Contracts':'契約',
    'MursionPortal.Client.Contracts':'クライアント契約',
    'MursionPortal.Client.Create.Contracts':'契約を作成',
    'MursionPortal.Client.Contract.ID':'契約ID',
    'MursionPortal.NewUser.Optional':'(任意)',
    'MursionPortal.NewUser.SuchAGoogleAccountOrOtherSsoProvider.TooltipText':'Googleアカウントまたは他のSSOプロバイダーなど',
    'MursionPortal.UserConsentDeclineConfirmation.Heading':'選択を確認してください。',
    // 'MursionPortal.UserConsentDeclineConfirmation.Title':'よろしいですか？収録せずに進むには確認ボタンをクリックしてください。収録に対する同意を変更するにはキャンセルボタンをクリックしてください。',
    'Settings.Config.Invitation.ShowSowFeature':'SOWの追加を許可',
    'Settings.Config.SOW.Description':'(SOWの進捗を追跡するため)',
    'MursionPortal.ScenarioBank.StandardizedScenario':'標準化されたシナリオ',
    'MursionPortal.ScenarioBank.ShowOnlyBundleScenario':'標準化されたシナリオのみを表示',
    'Settings.Config.Invitation.SimWorkforceManagement':'シムの人員管理',
    'Settings.Config.SimWorkforceManagement.Description':'有効化済みとはこのレポートがダッシュボードで利用できるという意味で、無効化済みとはこのレポートがまったく存在しないという意味です。',
    'MursionPortal.ScenarioBank.BundleScenario':'シナリオをバンドル',
    'MursionPortal.Scheduling.SimAvailable.TimeSlotsTooltipMsg':'このタイムスロットを選択するとシナリオの予約が自動的に確認されます！',
    'Dashboard.LeftPane.CategoryHeader.SupplyManagement':'シムの人員管理',
    'Dashboard.LeftPane.SupplyManagement.Scheduling':'スケジューリング',
    'Dashboard.SupplyManagement.Scheduling.MainHeader':'スケジューリング',
    'Dashboard.SupplyManagement.Scheduling.TableHeader':'スケジューリングデータ',
    'Dashboard.LeftPane.SupplyManagement.ClientRoster':'クライアントロスター',
    'Dashboard.SupplyManagement.ClientRoster.MainHeader':'クライアントロスター',
    'Dashboard.SupplyManagement.ClientRoster.TableHeader':'クライアントロスターデータ',
    'MursionPortal.Table.ColumnHeader.Time':'時間',
    'MursionPortal.Table.ColumnHeader.AllScheduledSessions':'すべてのスケジュール済み(セッション)',
    'MursionPortal.Table.ColumnHeader.SimsAvailable':'利用可能なシムスペシャリスト',
    'MursionPortal.Table.ColumnHeader.AvailabilityHours':'利用可能状況(時間)',
    'MursionPortal.Table.ColumnHeader.DemandBasedRequests':'すべてのデマンドベースのリクエスト(合計－すべて3リクエスト)',
    'MursionPortal.Table.ColumnHeader.SlotOneRequests':'スロット1のリクエスト',
    'MursionPortal.Table.ColumnHeader.SlotTwoRequests':'スロット2のリクエスト',
    'MursionPortal.Table.ColumnHeader.SlotThreeRequests':'スロット3のリクエスト',
    'MursionPortal.Table.ColumnHeader.PendingRequests':'保留中のリクエスト(セッション)',
    'MursionPortal.Table.ColumnHeader.Auto':'自動',
    'MursionPortal.Table.ColumnHeader.Manual':'手動',
    'MursionPortal.Table.ColumnHeader.CapacityIndicator':'キャパシティーインジケーター',
    'MursionPortal.EmptyMessage.NoSupplyManagementDataYet':'まだシムの人員管理データがありません',
    'MursionPortal.Table.ColumnHeader.SimCertified':'認定済みシム',
    'MursionPortal.Table.ColumnHeader.ScheduledHours':'スケジュール済み(時間)',
    'MursionPortal.Table.ColumnHeader.Sessions':'セッション',
    'MursionPortal.Table.ColumnHeader.AvgFullfilled':'平均履行',
    'MursionPortal.Table.ColumnHeader.SlotOneFullFillment':'スロット1の履行',
    'MursionPortal.Table.ColumnHeader.Cancelled':'キャンセル済み',
    'MursionPortal.Table.ColumnHeader.Missed':'欠席',
    'MursionPortal.Table.ColumnHeader.Reschedules':'スケジュール変更',
    'MursionPortal.Table.ColumnHeader.Error':'エラー',
    'MursionPortal.Table.ColumnHeader.Completed':'完了済み',
    'MursionPortal.Table.ColumnHeader.Other':'その他',
    'MursionPortal.Table.ColumnHeader.SchedulingWindow':'スケジューリングウィンドウ',
    'MursionPortal.Table.ColumnHeader.RequestDemand':'リクエスト数(デマンド)',
    'MursionPortal.Table.ColumnHeader.AutoFulfilled':'自動履行済み',
    'MursionPortal.Table.ColumnHeader.Pending':'保留中',
    'MursionPortal.Table.ColumnHeader.SlotTwoFullFillment':'スロット2の履行',
    'MursionPortal.Table.ColumnHeader.SlotThreeFullFillment':'スロット3の履行',
    'MursionPortal.Table.ColumnHeader.UnutilizedAvailability':'未使用の利用可能状況(時間)',
    'MursionPortal.Table.ColumnHeader.TotalAvailabilityEntered':'入力された利用可能状況の合計',
    'MursionPortal.Table.ColumnHeader.AverageHoursWeek':'平均時間/週',
    'MursionPortal.Table.ColumnHeader.AvailabilityUtilization':'シムスペシャリストの利用状況',
    'MursionPortal.SupplyManagement.Above':'以上',
    'MursionPortal.SupplyManagement.Below':'未満',
    'MursionPortal.SupplyManagement.At':'この数値：',
    'MyProfile.ProfServiceRole.Tooltiptext':'最高のアクセスレベルを持つユーザー',
    'MyProfile.AccManager.Tooltiptext':'シナリオとチームを作成し、ユーザーを追加し、チームにシミュレーションを割り当ててください',
    'MyProfile.SimSpecialist.Tooltiptext':'また、\'シム\'とも呼ばれているこのロールは、学習者のためのシミュレーションを実行します',
    'MyProfile.Buyer.Tooltiptext':'自らの組織のためにMursionのプロジェクトを管理するユーザー',
    'MyProfile.Facilitator.Tooltiptext':'シミュレーションの提供をサポートするシミュレーションの参加者',
    'MyProfile.Learner.Tooltiptext':'シミュレーション参加者',
    'MyProfile.Operations.Tooltiptext':'契約の追加および編集を行う能力を持つMursionの従業員',
    'MyProfile.SimDesigner.Tooltiptext':'バンクシナリオの追加および編集を行う能力を持つMursionの従業員',
    'MursionPortal.Label.AssignedUserRoles':'割り当てられたユーザーのロール',
    'MursionPortal.Label.AssignedUserRole':'割り当てられたユーザーのロール',
    'Clients.Modal.Projects.Button.CreateFromBank':'バンクから作成',
    'MursionPortal.Legend.VisibilityHidden.ScenarioPanel.Buttons':'新規作成と、クライアントの繰り返し、バンクシナリオから作成のボタン',
    'MursionPortal.Label.ArtBundle':'アートバンドル',
    'Settings.ScenarioBank.Modal.ToolTip.ThisIsTheArtContentMsg':'これは、シミュレーションで使用される環境とアバターの3Dアートのコンテンツです。このフォルダのコンテンツは以下の「アートバンドル」というタイトルのセクションに表示されます。',
    'MursionPortal.SessionWizard.Label.ScenarioHasNoArtBundles':'このシナリオには割り当てられたアートバンドルがありません。',
    'MursionPortal.Label.ArtProject':'アートプロジェクト',
    'MursionPortal.Label.UpperCase.ArtProject':'アートプロジェクト',
    'Settings.Config.SimulationContent.ArtProjectFolder':'アートプロジェクトS3フォルダ',
    'Settings.ScenarioBank.Modal.ThisScenarioHasNoArtProject':'このシナリオには割り当てられたアートプロジェクトがありません',
    'MursionPortal.SessionWizard.Label.SelectArtContentForUse':'使用するアートプロジェクトを選択',
    'Projects.Message.NoArtProjectFound':'アートプロジェクトがみつかりませんでした',
    'Settings.ScenarioBank.Form.ConfirmArchiveScenarioBank':'このバンクシナリオから作成されたアクティブな子シナリオがあります。これらはそれぞれをアーカイブする必要があります。',
    'Settings.StandardizedScenarioBank.EditConfirmation':'これから作成されたすべての既存のシナリオを更新してもよろしいですか？',
    'Settings.StandardizedScenarioBank.AttachmentsEditConfirmation':'添付ファイルを更新すると、これから作成されたすべての既存のシナリオが変更されます。',
    'MursionPortal.Contract.Ending':'終了中',
    'MursionPortal.ProjectContracts.DuplicateError':'あなたが選択した契約は、このプロジェクトに追加されている他の契約と重複しています。別の契約を選択するか、運営に連絡して変更命令をリクエストしてください',
    'MursionPortal.ProjectContracts.DateErrorHeading':'これらのプロジェクト日のための契約がありません',
    'MursionPortal.ProjectContracts.DateError':'これらのプロジェクトの開始日および終了日をサポートする契約がありません。有効な契約なしでプロジェクトを作成してはなりません。先に進みますか？',
    'MursionPortal.Contract.ViewDetails':'詳細を閲覧',
    'MursionPortal.Contract.PleaseAddContractToProject':'プロジェクトに契約を追加してください',
    'MursionPortal.Contract.AddContractToProject':'プロジェクトに契約を追加',
    'MursionPortal.Contract.SelectContractToProject':'このプロジェクトに追加したい契約を選択してください－一度に1つずつ追加してください',
    'MursionPortal.Contract.AddSelectedContract':'選択した契約を追加',
    'MursionPortal.Contract.AddContract':'契約を追加',
    'MursionPortal.Contract.LineItemList':'条項リスト',
    'MursionPortal.DemandBasedScheduling.InstantBooking':'インスタント予約',
    'MursionPortal.DemandBasedScheduling.AllSlots':'スロットを提出',
    'MursionPortal.DemandBasedScheduling.ScheduleYourScenario':'あなたが選択したタイムスロットの1つにシナリオをスケジュールいたします。',
    'MursionPortal.DemandBasedScheduling.SelectOneOfTheseSlotsForAnInstantBooking':'インスタント予約のためにこれらのスロットから1つを選択してください。',
    'MursionPortal.DemandBasedScheduling.TheseSlotDoNotwork':'これらのスロットを利用できない場合は、ご都合の良い時間を提出するために「スロットを提出」を選択してください。',
    'MursionPortal.AltText.CrossButton':'バツボタン画像',
    'MursionPortal.Title.BankScenario':'バンクシナリオ',
    'Dashboard.LeftPane.SupplyManagement.SimRoster':'シムロスター',
    'Dashboard.SupplyManagement.SimRoster.MainHeader':'シムロスター',
    'Dashboard.SupplyManagement.SimRoster.TableHeader':'シムロスターデータ',
    'MursionPortal.Table.ColumnHeader.SimFullName':'シムのフルネーム',
    'MursionPortal.Table.ColumnHeader.LanguagesDelivered':'提供言語(認定言語)',
    'MursionPortal.Table.ColumnHeader.Tier':'ティア',
    'MursionPortal.Table.ColumnHeader.Timezone':'タイムゾーン',
    'MursionPortal.Table.ColumnHeader.Scheduled':'スケジュール済み',
    'MursionPortal.Table.ColumnHeader.ProjectsHoursWeekTotal':'予定の時間/週合計',
    'MursionPortal.Table.ColumnHeader.ActiveClientCertifications':'アクティブなクライアント(請求可能)の認定書',
    'MursionPortal.Table.ColumnHeader.InactiveCertifications':'非アクティブな認定書',
    'MursionPortal.Table.ColumnHeader.Declined':'拒否済み',
    'MursionPortal.Table.ColumnHeader.AvgFullfillTimeForManual':'手動の平均履行時間',
    'MursionPortal.Table.ColumnHeader.Queue':'キュー',
    'MursionPortal.Table.ColumnHeader.Availability':'利用可能状況',
    'MursionPortal.Filters.Tier':'ティア',
    'MursionPortal.Filters.PlaceHolder.SearchTier':'ティアを検索',
    'MursionPortal.TierOptions.Tier1':'ティア1',
    'MursionPortal.TierOptions.Tier2':'ティア2',
    'MursionPortal.TierOptions.Tier3':'ティア3',
    'MursionPortal.TierOptions.Tier4':'ティア4',
    'MursionPortal.TierOptions.LeadSimulation':'リードシミュレーションスペシャリスト',
    'MursionPortal.Contract.BackToCLient':'クライアントに戻る',
    'MursionPortal.DeliveryMode.Workshop':'ワークショップ',
    'MursionPortal.DeliveryMode.PrivatePractice':'プライベート練習',
    'MursionPortal.DemandBasedScheduling.SimOnlySlotSelectionMessage':'ご都合の良い日とタイムスロットを選択してください。',
    'Dashboard.LeftPane.CategoryHeader.Performance':'利用状況',
    'Dashboard.LeftPane.Performance.ContractOverview':'概要',
    'Dashboard.LeftPane.Performance.ContractSummaries':'契約のサマリー',
    'Dashboard.LeftPane.Performance.ContractProgress':'契約の進捗',
    'Dashboard.LeftPane.CategoryHeader.Learners':'学習者',
    'Dashboard.LeftPane.Learners.LearnerActivity':'学習者のアクティビティ',
    'Dashboard.LeftPane.Learners.LearnerSimulations':'学習者のシミュレーション',
    'Dashboard.Total':'総エントリ',
    'Filters.SimulationStatus':'シミュレーションのステータス',
    'Filters.SimulationStatusPlaceholder':'シミュレーションのステータスを検索...',
    'Filters.LearnerStatus':'学習者のステータス',
    'Filters.LearnerStatusPlaceHolderHint':'学習者のステータスを検索...',
    'Filters.SimulationRecorded':'収録されたシミュレーション',
    'Filters.Standardized':'標準化済み',
    'Filters.Boolean.Yes':'はい',
    'Filters.Boolean.No':'いいえ',
    'Dashboard.UserTable.Column.Roles':'ロール',
    'Dashboard.ContractSummaries.MainHeader':'契約のサマリー',
    'Dashboard.ContractOverview.MainHeader':'概要',
    'Dashboard.ContractProgress.MainHeader':'契約の進捗',
    'Dashboard.ContractProgress.Navigation.ContractItems':'契約の項目',
    'Dashboard.ContractProgress.Navigation.Projects':'プロジェクト',
    'Dashboard.ContractProgress.Navigation.Scenarios':'シナリオ',
    'Dashboard.ContractProgress.Column.Item':'項目',
    'Dashboard.ContractProgress.Column.Purchased':'購入済み',
    'Dashboard.ContractProgress.Column.Remaining':'残り',
    'Dashboard.ContractProgress.Column.Completed':'完了済み',
    'Dashboard.ContractProgress.Column.Scheduled':'スケジュール済み',
    'Dashboard.ContractProgress.Column.LateCancellations':'遅発キャンセル',
    'Dashboard.ContractProgress.Column.ProjectName':'プロジェクト名',
    'Dashboard.ContractProgress.Column.StartDate':'開始日',
    'Dashboard.ContractProgress.Column.EndDate':'終了日',
    'Dashboard.ContractProgress.Column.Error':'エラー',
    'Dashboard.ContractProgress.Column.ScenarioName':'シナリオ名',
    'Dashboard.ContractProgress.Column.Standardized':'標準化済み',
    'Dashboard.ContractProgress.Column.LearnersAssigned':'割り当てられた学習者',
    'Dashboard.ContractProgress.Column.LearnersCompleted':'完了した学習者',
    'Dashboard.ContractProgress.Column.LearnersCompletedPercent':'%完了学習者',
    'Dashboard.ContractProgress.Column.Client':'クライアント',
    'Dashboard.ContractProgress.Column.ContractName':'契約名',
    'Dashboard.ContractProgress.Column.ContractId':'契約ID',
    'Dashboard.ContractProgress.Column.ContractNameHoverTitle':'IDをコピー',
    // 'Dashboard.ContractProgress.Column.PercentComplete':'%完了',
    'Dashboard.ContractProgress.Column.ErrorLearner':'エラー(学習者)',
    'Dashboard.ContractProgress.Column.LearnersInvited':'招待された学習者',
    'Dashboard.ContractProgress.Column.LearnersScheduledPercent':'%スケジュール済み学習者',
    'Dashboard.ContractProgress.ColumnTooltip.Client':'クライアント名',
    'Dashboard.ContractProgress.ColumnTooltip.ContractName':'契約名',
    'Dashboard.ContractProgress.ColumnTooltip.PercentComplete':'この契約で完了された日数の割合',
    'Dashboard.ContractProgress.ColumnTooltip.CompletedUtilization':'購入されたシミュレーションの割合として完了されたシミュレーション',
    'Dashboard.ContractProgress.ColumnTooltip.ScheduledUtilization':'購入されたシミュレーションの割合として完了およびスケジュールされたシミュレーション',
    'Dashboard.ContractProgress.ColumnTooltip.MissedLearner':'学習者が欠席したシミュレーション。',
    'Dashboard.ContractProgress.ColumnTooltip.ErrorLearner':'学習者側の接続エラーで終了したシミュレーション',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersInvited':'シミュレーションのスケジュールを招待された学習者',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersScheduledPercent':'シミュレーションのスケジュールを招待された者の割合として1回以上シミュレーションをスケジュールした学習者',
    'Dashboard.ContractProgress.ColumnTooltip.Item':'この契約で契約された項目',
    'Dashboard.ContractProgress.ColumnTooltip.Purchased':'契約の一部として購入されたシミュレーションの総数',
    'Dashboard.ContractProgress.ColumnTooltip.Completed':'問題なく完了したシミュレーション',
    'Dashboard.ContractProgress.ColumnTooltip.Scheduled':'スケジュールされているがまだ完了していない今後のシミュレーション(実施中のものを含む)',
    'Dashboard.ContractProgress.ColumnTooltip.Missed':'学習者が欠席したシミュレーション',
    'Dashboard.ContractProgress.ColumnTooltip.LateCancellations':'学習者や、ファシリテーター、またはアカウント所有者によって遅発キャンセル(キャンセル締め切り日の後)が行われたシミュレーションまたは学習者が退出もしくは拒否したシミュレーション',
    'Dashboard.ContractProgress.ColumnTooltip.Error':'学習者側の接続エラーで終了したシミュレーション',
    'Dashboard.ContractProgress.ColumnTooltip.ProjectName':'プロジェクト名',
    'Dashboard.ContractProgress.ColumnTooltip.StartDate':'この契約に記載されている契約開始日',
    'Dashboard.ContractProgress.ColumnTooltip.EndDate':'この契約に記載されている契約終了日',
    'Dashboard.ContractProgress.ColumnTooltip.ScenarioName':'シナリオ名',
    'Dashboard.ContractProgress.ColumnTooltip.Standardized':'これは標準化されたシナリオです',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersAssigned':'シナリオが割り当てられている学習者の名前',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersCompleted':'シミュレーションのスケジュールを招待された者の割合として1回以上シミュレーションを完了した学習者',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersCompletedPercent':'このシナリオを完了した個別の学習者の割合',
    'Dashboard.Learners.Column.Provider':'プロバイダー',
    'Dashboard.Learners.Column.Client':'クライアント',
    'Dashboard.Learners.Column.LearnerName':'学習者名',
    'Dashboard.Learners.Column.DateInvited':'招待日',
    'Dashboard.Learners.Column.LastLogin':'最終ログイン',
    'Dashboard.Learners.Column.NoAssignedTeams':'割り当てられたチームがありません',
    'Dashboard.Learners.Column.NoAssignedScenarios':'割り当てられたシナリオがありません',
    'Dashboard.Learners.Column.NoCompletedScenarios':'完了したシナリオがありません',
    'Dashboard.Learners.Column.Completed':'完了済み',
    'Dashboard.Learners.Column.Scheduled':'スケジュール済み',
    'Dashboard.Learners.Column.LateCancelled':'遅発キャンセル済み',
    'Dashboard.Learners.Column.EndedInError':'エラーによって終了済み',
    'Dashboard.Learners.Column.ProjectName':'プロジェクト名',
    'Dashboard.Learners.Column.ScenarioName':'シナリオ名',
    'Dashboard.Learners.Column.ScheduledStartDate':'スケジュールされた開始日',
    'Dashboard.Learners.Column.ScheduledStartTime':'スケジュールされた開始時間',
    'Dashboard.Learners.Column.ScheduledEndTime':'スケジュールされた終了時間',
    'Dashboard.Learners.Column.SimulationStatus':'シミュレーションのステータス',
    'Dashboard.Learners.Column.LearnerStatus':'学習者のステータス',
    'Dashboard.Learners.Column.LearnerTimeSpent':'学習者が消費した時間',
    'Dashboard.Learners.Column.SimSpecialistName':'シムスペシャリスト名',
    'Dashboard.Learners.Column.SimSpecialistStatus':'シムスペシャリストのステータス',
    'Dashboard.Learners.Column.Report':'レポート',
    'Dashboard.Learners.ColumnTooltip.Provider':'プロバイダー名/ライセンシー名',
    'Dashboard.Learners.ColumnTooltip.Client':'クライアント名',
    'Dashboard.Learners.ColumnTooltip.LearnerName':'ユーザーの苗字と名前',
    'Dashboard.Learners.ColumnTooltip.DateInvited':'学習者がシナリオのスケジュールを初めて招待された日',
    'Dashboard.Learners.ColumnTooltip.LastLogin':'ユーザーがこのポータルに最後にログインした日',
    'Dashboard.Learners.ColumnTooltip.NoAssignedTeams':'学習者に割り当てられているチームの数',
    'Dashboard.Learners.ColumnTooltip.NoAssignedScenarios':'学習者に割り当てられている個別のシナリオの数',
    'Dashboard.Learners.ColumnTooltip.NoCompletedScenarios':'学習者によって完了された個別のシナリオの数',
    'Dashboard.Learners.ColumnTooltip.Completed':'問題なく完了したシミュレーション',
    'Dashboard.Learners.ColumnTooltip.Scheduled':'スケジュールされているがまだ完了していない今後のシミュレーション(実施中のものを含む)',
    'Dashboard.Learners.ColumnTooltip.Missed':'学習者が欠席したシミュレーション',
    'Dashboard.Learners.ColumnTooltip.LateCancelled':'学習者や、ファシリテーター、またはアカウント所有者によって遅発キャンセル(キャンセル締め切り日の後)が行われたシミュレーションまたはシミュレーション。',
    'Dashboard.Learners.ColumnTooltip.EndedInError':'学習者側の接続エラーで終了したシミュレーション',
    'Dashboard.Learners.ColumnTooltip.ProjectName':'プロジェクト名',
    'Dashboard.Learners.ColumnTooltip.ScenarioName':'シナリオ名',
    'Dashboard.Learners.ColumnTooltip.ScheduledStartDate':'このシミュレーションの開始予定日',
    'Dashboard.Learners.ColumnTooltip.ScheduledStartTime':'このシミュレーションの開始予定時間',
    'Dashboard.Learners.ColumnTooltip.ScheduledEndTime':'このシミュレーションの終了予定時間',
    'Dashboard.Learners.ColumnTooltip.SimulationStatus':'このシミュレーションの最終ステータス',
    'Dashboard.Learners.ColumnTooltip.LearnerStatus':'このシミュレーションの学習者の最終ステータス',
    'Dashboard.Learners.ColumnTooltip.LearnerTimeSpent':'このシミュレーションで学習者が消費した時間。ZoomとMeetの学習者の場合、これは完了していればシムスペシャリストに基づいて推定されます',
    'Dashboard.Learners.ColumnTooltip.SimSpecialistName':'このシミュレーションを提供するために割り当てられたシミュレーションスペシャリスト',
    'Dashboard.Learners.ColumnTooltip.SimSpecialistStatus':'このシミュレーションのシムスペシャリストの最終ステータス',
    'Dashboard.Learners.ColumnTooltip.SimulationId':'このシミュレーションの固有識別子',
    'Dashboard.Learners.ColumnTooltip.Report':'このシミュレーションの(利用できる場合)収録と分析を閲覧するためのシミュレーションレポートへのリンク',
    'Dashboard.SessionLearners.TableHeader':'セッション学習者データ',
    'MursionPortal.FilterHeading.Filter':'フィルター',
    'MursionPortal.FilterHeading.Graph':'グラフ',
    'MursionPortal.Table.NoRecordsMessage':'結果がみつかりませんでした！',
    'MursionPortal.Filters.DeliveryMode':'提供モード',
    'MursionPortal.Filters.PlaceHolder.DeliveryMode':'提供モードを検索...',
    'MursionPortal.Dashboard.Chart.Tooltip.Utilized':'契約条項のために利用された総数。完了や、欠席、または(締め切り後に)キャンセル/スケジュール変更されたシミュレーションを含む。',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Completed':'問題なく完了したシミュレーション。',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.LearnerMissed':'学習者が欠席したシミュレーション。',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Error':'学習者側の接続エラーで終了したシミュレーション。',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.LateCancellation':'学習者や、ファシリテーター、またはアカウント所有者によって遅発キャンセル(キャンセル締め切り日の後)が行われたシミュレーションまたは学習者が退出もしくは拒否したシミュレーション。',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.EarlyCancellation':'学習者や、ファシリテーター、またはアカウント所有者によって早期キャンセル(キャンセル締め切り日の前)が行われたシミュレーション。',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.Completed':'完了済み',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.Error':'エラー',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.LateCancellation':'遅発キャンセル。',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.EarlyCancellation':'早期キャンセル。',
    'MursionPortal.Dashboard.Chart.PrivatePractice1':'30分プライベート練習',
    'MursionPortal.Dashboard.Chart.PrivatePractice2':'60分プライベート練習',
    'MursionPortal.Dashboard.Chart.Workshop1':'60分ワークショップ',
    'MursionPortal.Dashboard.Chart.Workshop2':'90分ワークショップ',
    'MursionPortal.Dashboard.Chart.StringType.Utilized':'利用済み',
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdown':'シミュレーションの概要',
    'MursionPortal.SessionRecording':'セッションの収録',
    'MursionPortal.VoiceAnalytics':'声分析',
    'MursionPortal.VideoTab':'動画',
    'MursionPortal.SessionInformation':'セッション情報',
    'MursionPortal.SmartMatrix':'スマートメトリック',
    'MursionPortal.DeliveryDetails':'提供の詳細',
    'MursionPortal.ConversationalFlow':'会話の流れ',
    'MursionPortal.ConversationalFlowTooltip':'このグラフは会話中のあなたの有効性を表しています。これは話者交代の情報に基づいています。話者交代の情報は、あなたがどれくらい上手く建設的な会話に参加できたかを示しています。',
    'MursionPortal.BalancedTalkTime':'会話',
    'MursionPortal.BalancedFeedback':'バランスに関するフィードバック',
    'MursionPortal.ConversationalDisruptions':'遮断',
    'MursionPortal.DisruptionsFeedback':'途絶に関するフィードバック',
    'MursionPortal.Heading.SessionSmartMetrics':'セッションの声分析：',
    'Clients.Modal.Label.SmartMetrics':'声分析',
    'Clients.Modal.Projects.Header.SessionSmartMetrics':'セッションの声分析：',
    'Clients.Modal.Projects.Checkbox.EnableSmartMetrics':'声分析を有効化',
    'Filters.Contract':'契約',
    'Filters.Session': 'セッション',
    'Clients.Modal.Label.ShowSowReporting':'SOWレポートを表示：',
    'Clients.Modal.Label.ShowSurveyEnabled':'シミュレーション後の学習者調査を送信：',
    'Dashboard.SimulationTable.Column.Provider':'プロバイダー',
    'Dashboard.SimulationTable.Column.Client':'クライアント',
    'Dashboard.SimulationTable.Column.Project':'プロジェクト',
    'Dashboard.SimulationTable.Column.ScenarioName':'シナリオ名',
    'Dashboard.SimulationTable.Column.ScheduledStartDate':'スケジュールされた開始日',
    'Dashboard.SimulationTable.Column.ScheduledStartTime':'スケジュールされた開始時間',
    'Dashboard.SimulationTable.Column.ScheduledEndTime':'スケジュールされた終了時間',
    'Dashboard.SimulationTable.Column.LearnersNames':'学習者名',
    'Dashboard.SimulationTable.Column.LearnersEmails':'学習者のメールアドレス',
    'Dashboard.SimulationTable.Column.LearnersStatus':'学習者のステータス',
    'Dashboard.SimulationTable.Column.LearnersTimeSpent':'学習者が消費した時間',
    'Dashboard.SimulationTable.Column.SimSpecialistName':'シムスペシャリスト名',
    'Dashboard.SimulationTable.Column.SimSpecialistStatus':'シムスペシャリストのステータス',
    'Dashboard.SimulationTable.Column.SimSpecialistTimeSpent':'シムスペシャリストが消費した時間',
    'Dashboard.SimulationTable.Column.ScheduledBy':'スケジュール者',
    'Dashboard.SimulationTable.Column.ScheduledAt':'スケジュール日時：',
    'Dashboard.SimulationTable.Column.Recorded':'収録済み',
    'Dashboard.SimulationTable.Column.ClientId':'クライアントID',
    'Dashboard.SimulationTable.Column.LearnerName':'学習者名',
    'Dashboard.SimulationTable.Column.LearnerName.ButtonHoverTitle':'学習者のメールアドレスをコピー',
    'Dashboard.SimulationTable.Column.LearnerEmail':'学習者のメールアドレス',
    'Dashboard.SimulationTable.Column.LearnerStatus':'学習者のステータス',
    'Dashboard.SimulationTable.Column.LearnerTimeSpent':'学習者が消費した時間',
    'Dashboard.SimulationTable.ColumnTooltip.Provider':'プロバイダー名/ライセンシー名',
    'Dashboard.SimulationTable.ColumnTooltip.Client':'クライアント名',
    'Dashboard.SimulationTable.ColumnTooltip.Project':'プロジェクト名',
    'Dashboard.SimulationTable.ColumnTooltip.ScenarioName':'シナリオ名',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledStartDate':'このシミュレーションの開始予定日',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledStartTime':'このシミュレーションの開始予定時間',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledEndTime':'このシミュレーションの終了予定時間',
    'Dashboard.SimulationTable.ColumnTooltip.SimulationStatus':'このシミュレーションの最終ステータス',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersNames':'このシミュレーションの登録ユーザー名および未登録ユーザー名',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersEmails':'このシミュレーションの登録ユーザーおよび未登録ユーザーのメールアドレス',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersStatus':'このシミュレーションの学習者の最終ステータス',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersTimeSpent':'このシミュレーションに参加した学習者によってこのシミュレーションに接続するために消費された時間',
    'Dashboard.SimulationTable.ColumnTooltip.SimSpecialistName':'シムスペシャリスト名',
    'Dashboard.SimulationTable.ColumnTooltip.SimSpecialistStatus':'このシミュレーションのシムスペシャリストの最終ステータス',
    'Dashboard.SimulationTable.ColumnTooltip.SimSpecialistTimeSpent':'このシミュレーションの実際の長さ(分)',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledBy':'このシミュレーションをスケジュールしたユーザーの名前',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledAt':'このシミュレーションがスケジュールされた日時',
    'Dashboard.SimulationTable.ColumnTooltip.Recorded':'このシミュレーションが収録されたか、または収録が許可されていなかったかを示してください。',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerName':'このシミュレーションの登録ユーザー名および未登録ユーザー名',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerStatus':'このシミュレーションの学習者の最終ステータス',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerTimeSpent':'このシミュレーションで学習者が消費した時間。ZoomとMeetの学習者の場合、これは完了していればシムスペシャリストに基づいて推定されます',
    'MursionPortal.Label.VideoTimeLineSlice':'動画のタイムラインスライス',
    'MursionPortal.Label.SpeakingMoreThanSixty':'スピーキングが60%以上です',
    'MursionPortal.Label.SpeakingBetweenSixty':'スピーキングが40%から60%です',
    'MursionPortal.Label.InterruptionMoreThanSixty':'遮断が60%以上です',
    'MursionPortal.Label.InterruptionBetweenSixty':'遮断が40%から60%です',
    'MursionPortal.Label.SpeakingLessThanFourty':'スピーキングが40%未満です',
    'MursionPortal.Label.InterruptionLessThanFourty':'遮断が40%未満です',
    'MursionPortal.Label.Interruption.ByAvatar':'アバターによって',
    'MursionPortal.Label.Interruption.ByLearner':'学習者によって',
    'MursionPortal.Projects.NoContractsFound':'契約がみつかりませんでした。',
    'MursionPortal.Projects.ProjectContracts':'プロジェクトの契約',
    'MursionPortal.Tooltip.AvatarInterrupt':'アバターが話者を上手く遮りました。',
    'MursionPortal.Tooltip.LearnerInterrupt':'学習者が話者を上手く遮りました。',
    'MursionPortal.Tooltip.Pauses':'一時停止。0.2秒より長く0.75秒より短いもの',
    'MursionPortal.Tooltip.Silence':'10秒を超える発話の中断',
    'MursionPortal.Tooltip.AvatarSpeaking':'アバターが上手く話し始めました。',
    'MursionPortal.Tooltip.LearnerSpeaking':'学習者が上手く話し始めました。',
    // 'MursionPortal.label.LearnerInterrupts':'学習者の遮断',
    // 'MursionPortal.label.AvatarInterrupts':'アバターの遮断',
    'MursionPortal.label.Pause':'一時停止',
    'MursionPortal.Label.Silence':'沈黙',
    'MursionPortal.label.AvatarSpeaking':'アバターの発話',
    'MursionPortal.label.LearnerSpeaking':'学習者の発話',
    'MursionPortal.Contract.DuplicateLineItem':'このシミュレーションの長さと提供モードには既に条項が存在しています',
    'MursionPortal.Contract.DeleteLineItemIcon':'条項アイコンを削除',
    'SessionReports.Label.ShareVideoLink':'動画のリンクを共有',
    'MursionPortal.Title.Silence':'10秒を超える発話の中断',
    'Dashboard.LeftPane.User.UserActivity':'ユーザーのアクティビティ',
    'Dashboard.LeftPane.Performance.SelfService':'セルフサービスの利用状況',
    'MursionPortal.Placeholder.ClientValue':'クライアントを選択',
    'Filters.Date.FromDate':'開始日',
    'Filters.Date.ToDate':'終了日',
    'Dashboard.UserActivity.MainHeader':'ユーザーのアクティビティ',
    'Dashboard.SelfService.MainHeader':'セルフサービスの利用状況',
    'Dashboard.SelfService.Column.SelfServiceProvider':'プロバイダー',
    'Dashboard.SelfService.Column.SelfServiceTotalHoursUtilized':'総利用時間',
    'Dashboard.SelfService.Column.SelfServiceHoursCompleted':'完了された時間',
    'Dashboard.SelfService.Column.SelfServiceHoursMissed':'欠席された時間',
    'Dashboard.SelfService.Column.SelfServiceHoursErrored':'エラーがあった時間',
    'Dashboard.SelfService.Column.SelfServiceSimulationsComplete':'完了したシミュレーション',
    'Dashboard.SelfService.Column.SelfServiceSimulationsMissed':'欠席されたシミュレーション',
    'Dashboard.SelfService.Column.SelfServiceSimulationsErrored':'エラーがあったシミュレーション',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceProvider':'プロバイダー',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceTotalHoursUtilized':'問題なく完了したシミュレーション',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceHoursCompleted':'完了された時間',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceHoursMissed':'学習者が欠席したシミュレーション',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceHoursErrored':'学習者側の接続エラーによって終了したシミュレーション',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceSimulationsComplete':'完了したシミュレーション',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceSimulationsMissed':'欠席されたシミュレーション',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceSimulationsErrored':'エラーがあったシミュレーション',
    'Dashboard.Learners.Column.AssignedProjects':'割り当てられたプロジェクト',
    'Dashboard.Learners.Column.AssignedScenarios':'割り当てられたシナリオ',
    'Dashboard.Learners.Column.ScheduledScenarios':'スケジュールされたシナリオ',
    'Dashboard.Learners.Column.CompletedScenarios':'完了したシナリオ',
    'Settings.Config.SessionReport.ShowSmartMetrics':'声分析',
    'Settings.SSO.CopySsoDeepLink':'SSOのディープリンクをコピー',
    'Settings.SSO.CopySsoDeepLinkNotification':'あなたのSSOのリンクがクリップボードにコピーされました',
    'MursionPortal.Dashboard.Chart.Assigned':'割当済み',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Simulation':'各ステータスで分類されたシミュレーション。',
    'MursionPortal.Dashboard.Chart.Header.NoDataAvailable':'利用できるデータがありません',
    'Projects.ProjectInfo.Label.SurveyControl':'プロジェクトの調査',
    'Projects.ProjectInfo.Checkbox.EnablePreSimulationSurvey':'シミュレーション前の学習者調査を有効化',
    'Projects.ProjectInfo.Checkbox.EnablePostSimulationSurvey':'シミュレーション後の学習者調査を有効化',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled':'調査を有効化しました',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PreSimulationSurvey':'シミュレーション前の学習者調査',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PostSimulationSurvey':'シミュレーション後の学習者調査',
    'Settings.SSO.ModalBody.SelectDestination':'リンク先を選択',
    'Settings.SSO.ModalBody.SelectDestination.SessionsTab':'セッションタブ',
    'Settings.SSO.ModalBody.SelectDestination.CalendarTab':'カレンダータブ',
    'Settings.SSO.ModalBody.SelectDestination.ReportsTab':'レポートタブ',
    'Settings.SSO.ModalBody.SelectDestination.DashboardTab':'ダッシュボードタブ',
    'Settings.SSO.ModalBody.SelectDestination.ScenarioCard':'シナリオカード',
    'Settings.SSO.ModalBody.SelectDestination.ScenarioTabSelectTitle':'まずプロジェクトを選択してからシナリオを選択してください',
    'Settings.SSO.Button.CreateDeepLink':'SSOのディープリンクを作成',
    'Settings.SSO.ModalHeader.DeepLink':'SSOのディープリンクをコピー',
    'MursionPortal.Dashboard.Chart.Header.LearnerSimulations':'割り当てられたシナリオの数',
    'Clients.Modal.Label.MursionSocialAttendance':'Mursion Socialの参加：',
    'MursionPortal.CodeOfConduct.Text':'Mursionのセッションのスケジュールを選択することによって、あなたはMursionの<code>行動規範</code>を支持し履行することに同意するものとします。',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.CompletedAllAvailable':'利用可能なすべてのシナリオを完了した学習者',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.NoneAvailableToSchedule':'まだスケジュールできるシナリオがない学習者',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.NotYetScheduled':'利用可能なシナリオはあるが、まだシミュレーションをスケジュールしていない学習者',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.Scheduled':'1つ以上のシミュレーションをスケジュールしているが、まだ完了していない学習者',
    'MursionPortal.DateRangeSelector.Label.DateRange':'日付の範囲',
    'MursionPortal.DemandBasedScheduling.TimeBlockUnavailableReservedText':'既に選択済みです－別のタイムブロックを選択してください。',
    'MursionPortal.EndDate':'終了日',
    'MursionPortal.Label.FilterSIMsBy':'シムのフィルター基準：',
    'MursionPortal.LearnerSimSelector.Learner':'学習者',
    'MursionPortal.LearnerSimSelector.Learner.Selected':'選択された学習者',
    'Dashboard.Filters.SimulationID':'シミュレーションID',
    'MursionPortal.LearnerSimSelector.Sim.Selected':'シムスペシャリスト',
    'MursionPortal.LearnerSimSelector.Sim.Unavailable':'認定済みで利用不可',
    'MursionPortal.LearnerSimSelector.Tab.Search.Learner':'学習者を検索または選択',
    'MursionPortal.LearnerSimSelector.Tab.Search.Sim':'シムスペシャリストを検索また選択',
    'MursionPortal.Placeholder.ProvidersValue':'プロバイダーを選択',
    'MursionPortal.ProjectContract.Confirmation':'このプロジェクトからこの契約を削除してもよろしいですか？',
    'MursionPortal.Scheduling.Label.DateTimeTab':'日付＋時間',
    'MursionPortal.Scheduling.NoAvailabilityForCertifiedAndAvailableSimSpecialist':'認定シムの利用可能状況が存在しません。',
    'MursionPortal.Scheduling.NoAvailabilityForCertifiedSimSpecialist':'利用できる認定済みシムがいません',
    'MursionPortal.SessionFlow.Label.AreYouSure':'よろしいですか？',
    'MursionPortal.SessionFlow.Label.BeforeDeletionPleaseEnsureTheFollowing':'削除する前に以下のことをご確認ください：',
    'MursionPortal.SessionFlow.Label.BeforeDeletionPleaseEnsureTheFollowing.Text1':'リクエストしているユーザーが削除する権限をもっていることを確認したかどうか(グループシミュレーションと学習者の同意にご注意ください)。',
    'MursionPortal.SessionFlow.Label.BeforeDeletionPleaseEnsureTheFollowing.Text2':'この削除をリクエストしている人物を述べたサポートケースを作成し、必要に応じてアカウント所有者に確認をとったかどうか。',
    'MursionPortal.SessionFlow.Label.DeleteRecording':'収録を削除',
    'MursionPortal.SessionFlow.Label.ProviderName':'プロバイダー名',
    'MursionPortal.SessionFlow.Label.ReminderThisCanNotBeUndone':'リマインダー：これは取り消すことができません！',
    'MursionPortal.SessionFlow.Label.ScenarioDetails':'シナリオの詳細',
    'MursionPortal.SessionFlow.Label.SessionStartEndDateTime':'セッションの開始日時&終了日時',
    'MursionPortal.SessionFlow.Label.SupportCase':'サポートケース#*',
    'MursionPortal.SessionFlow.Label.YouAreAboutToDeletevideoRecording':'あなたは動画の収録を削除しようとしています',
    'MursionPortal.SessionNotStarted.AudioVideoSettings':"音声および動画の設定をご確認ください",
    'MursionPortal.SessionNotStarted.DescriptionOne':"待っている間に以下のことをできます：",
    'MursionPortal.SessionNotStarted.DescriptionTwo':"このポップアップはシミュレーションの<code>{value}</code>分前に消えます。このページがこの時間になっても自動的に更新されなかった場合は再読み込みをクリックしてください。",
    'MursionPortal.SessionNotStarted.Heading':"少々早くお越しのようです",
    'MursionPortal.SessionNotStarted.PreSimTips':"こちらの<code>シミュレーション前のヒント</code>をご覧ください",
    'MursionPortal.SessionNotStarted.ScenarioDetails':"<code>シナリオの詳細</code>をご確認ください",
    'MursionPortal.StartDate':'開始日',
    'MursionPortal.Survey.Thankyou.Msg':'お時間を頂戴してこの調査にご参加いただけたことに感謝を申し上げます。あなたの回答は記録されました。',
    'MursionPortal.Title.Hours':'時間',
    'MursionPortal.Title.Minutes':'分',
    'MursionPortal.UserConsentDeclineConfirmation.PermissionDoesNotLieWithLearner':'先に進むにはあなたのシミュレーションが収録されることに同意するかどうか意思表示をしてください。同意していただけない場合は、これ以上シミュレーションのセッションを進めることはできず、ソフトウェアが終了します。同意していただける場合は、シミュレーションのセッションが収録され、各収録が12ヵ月間保存され、その後はアーカイブされます。',
    'MursionPortal.UserConsentDeclineConfirmation.PermissionsLiesWithLearner':'よろしいですか？収録せずに進むには確認ボタンをクリックしてください。収録に対する同意を変更するにはキャンセルボタンをクリックしてください。',
    'Session.Edit.Modal.JoinSimulationBtnTooltip':'あなたはシミュレーションが始まる<code>{minutesBeforeJoin}</code>分前に参加できるようになります。',
    'Session.Edit.Modal.LastEmailTrigger':'学習者に送信された最後のメール：{sentDate}',
    'Session.Edit.Modal.LastEmailTrigger.Sender.UserName':'送信者および送信時間：{userName} {sentTime}',
    'Session.Rescheduling.Email.Reminder.Msg':'この学習者にスケジューリング変更のリマインダーを送信しています！',
    'Settings.Config.Invitation.MursionSocialAttendance':'Mursion Socialの参加を集めてください。',
    'Settings.LTI.Modal.SendScore':'スコアを送信',
    'Dashboard.ContractProgress.Column.Provider':'プロバイダー',
    'Settings.SSO.CopyDeepLinkNotification':'あなたのリンクがクリップボードにコピーされました',
    'MursionPortal.Dashboard.Table.Empty.Body.Message':'詳細は選択をすると読み込まれます',
    'Settings.SSO.ModalBody.ProjectSelector.DisabledTitle':'まず正しいオプションを選択してください',
    'Settings.SSO.ModalBody.ScenarioSelector.DisabledTitle':'まずプロジェクトを選択してください',
    'MursionPortal.Label.LearnerListening':'聞いている学習者',
    'MursionPortal.Label.InterruptionCount':'タイムゾーン',
    'MursionPortal.ConversationalFlow.label.videoTimeLine':'動画のタイムライン',
    'MursionPortal.ConversationalFlow.label.zoomTimeLine':'Zoom',
    'MursionPortal.LearnerSimSelector.Sim.Available':'認定済みで利用可能',
    'MursionPortal.Button.View':'閲覧',
    'MursionPortal.Dashboard.NewSimulationAssigned':'新しく割り当てられたシミュレーション',
    'MursionPortal.Dashboard.MakeUpSimulation':'補填シミュレーション',
    'MursionPortal.Dashboard.MissedSimulation':'欠席されたワークショップ',
    'MursionPortal.Dashboard.CompletedSimulations':'完了したシミュレーション',
    'MursionPortal.Dashboard.BackToDashboard':'ダッシュボードに戻る',
    'MursionPortal.Dashboard.CancelledSimulation':'欠席されたワークショップ',
    'MursionPortal.ScenarioSearchList.Dialog.Title':'この時間に繰り返しの利用可能状況がスケジュールされています。繰り返しの利用可能状況も更新しますか？',
    'MursionPortal.ScenarioSearchList.Dialog.UpdateText':'{startDate}の利用可能状況だけを更新します',
    'MursionPortal.ScenarioSearchList.Dialog.UpdateText.ToDate':'{date}からの繰り返しの利用可能状況を更新します',
    'Mursion.Portal.Status.Connected':'接続済み',
    'Mursion.Portal.Status.Late':'遅発',
    'Mursion.Portal.Status.Immersing':'没頭中',
    'Mursion.Portal.Status.Left':'途中退出',
    'Mursion.Portal.Status.Declined':'拒否済み',
    'Login.EditFile': '{file}を編集し、保存して再読み込みします。',
    'Login.Hello': 'こんにちわ',
    'Login.Title': 'Mursionポータルへようこそ',
    'Login.Description': 'バーチャル リアリティ シミュレーションを設計、スケジュール、管理します',
    'Login.PrivacyPolicy': 'プライバシーポリシー',
    'Login.PrivacyDataInfo': 'プライバシーデータ情報',
    'Login.TechnicalSupport': 'テクニカルサポート',
    'Login.SignIn': 'サインイン',
    'Login.Next': '次へ',
    'Login.Email': 'メールアドレス',
    'Login.Password': 'パスワード',
    'Login.ForgotPassword': 'パスワードを忘れた',
    'Login.Login': 'ログイン',
    'Login.EmailHint': 'メールアドレスを入力',
    'Login.PasswordHint': 'パスワードを入力してください',
    'Login.NewPasswordHint': '新しいパスワードを入力してください',
    'Login.RepeatPasswordHint': '新しいパスワードをもう一度入力してください',
    'Login.Image': 'Mursionのロゴ',
    'Filters.Heading': 'フィルター：',
    'Dashboard.LeftPaneHeader': 'ダッシュボード',
    'Dashboard.LeftPane.CategoryHeader.Users': 'ユーザー',
    'Dashboard.LeftPane.CategoryHeader.Projects': 'プロジェクト',
    'Dashboard.LeftPane.Project.ProjectActivity': 'プロジェクトアクティビティ',
    'Dashboard.LeftPane.Project.ProjectUtilization': 'プロジェクトの活用',
    'Dashboard.LeftPane.CategoryHeader.Sessions': 'セッション',
    'Dashboard.LeftPane.Session.SessionLearner': 'セッション学習者',
    'Dashboard.LeftPane.CategoryHeader.Legacy': 'レガシー',
    'Dashboard.VideosTable.HoverTitle': '', // TODO: add translations
    'Dashboard.Button.ExportToEmailSentMsg': 'レポートはまもなく{email}に送信されます。',
    'Dashboard.Button.ExportToEmail.HoverTitle': 'データのエクスポート',
    'Dashboard.Button.ExportToEmail.HoverTitleNotAvailable': 'エクスポートは利用できません',
    'Dashboard.Next': '次へ',
    'Dashboard.Last': '最後',
    'Dashboard.Help': 'ヘルプ',
    'Dashboard.First': '最初',
    'Dashboard.Back': '戻る',
    'FiltersHeading': 'フィルター：',
    'Filters.Client': 'クライアント',
    'Filters.ClientPlaceHolderHint': 'クライアントを検索',
    'Filters.Industries': '業種',
    'Filters.IndustriesPlaceHolderHint': '業種を検索',
    'Filters.IndustriesDisabledTitle': '無効化',
    'Filters.Project': 'プロジェクト',
    'Filters.ProjectPlaceHolderHint': 'プロジェクトを検索',
    'Filters.ProjectDisabledTitle': '最初にクライアントを選択します',
    'Filters.RoleDisabledTitle': '役割の選択は無効です',
    'Filters.Scenario': 'シナリオ',
    'Filters.ScenarioPlaceHolderHint': 'シナリオを検索',
    'Filters.ScenarioDisabledTitle': '最初にクライアントとプロジェクトを選択します',
    'Filters.Team': 'チーム',
    'Filters.TeamPlaceHolderHint': 'チームを検索',
    'Filters.TeamDisabledTitle': '最初にクライアントとプロジェクトを選択します',
    'Filters.SessionStatus': 'セッションの状況',
    'Filters.SessionStatusPlaceHolderHint': 'セッション状況を検索',
    'Filters.RegistrationStatus': '登録状況',
    'Filters.RegistrationStatus.ShowAllRegistration': '登録状況：すべて',
    'Filters.RegistrationStatus.ShowAcceptedRegistration': '登録が受け付けられました',
    'Filters.RegistrationStatus.ShowNotAcceptedRegistration': '登録は受け付けられていません',
    'Filters.Date.LastMonth': '先月',
    'Filters.Date.Yesterday': '昨日',
    'Filters.Date.Today': '今日',
    'Filters.Date.Tomorrow': '明日',
    'Filters.Date.NextMonth': '翌月',
    'Filters.Date.From': '開始日付',
    'Filters.Date.To': '終了日付',
    'Dashboard.UserActivityTable.HoverTitle': 'ユーザーの概要を表示',
    'Dashboard.Export.ErrMsg': 'エクスポートするエントリの最大制限は{limit}です。リクエストを変更して、もう一度お試しください。',
    'MursionPortal.Close': '閉じる',
    'Dashboard.UserTable.Column.Provider': 'プロバイダー',
    'Dashboard.UserTable.Column.Client': 'クライアント',
    'Dashboard.UserTable.Column.ProfileId': 'プロファイル ID',
    'Dashboard.UserTable.Column.UserEmail': 'ユーザーのメールアドレス',
    'Dashboard.UserTable.Column.UserEmail.ButtonHoverTitle': 'メールをコピーする',
    'Dashboard.UserTable.Column.SSO': 'SSO',
    'Dashboard.UserTable.Column.DateRegistered.ButtonTitle': '招待状の再送',
    'Dashboard.UserTable.Column.Scenarios': 'シナリオ',
    'Dashboard.UserTable.Column.Teams': 'チーム',
    'Dashboard.UserTable.Column.Industry': '業種',
    'Dashboard.UserTable.Column.Accepted': '承認済み',
    'Session.Tab.Scheduled': 'スケジュール済み',
    'Session.Tab.Active': '有効',
    'Session.Tab.Past': '過去',
    'Session.Tab.Cancelled': 'キャンセル済み',
    'Session.Search': '検索',
    'Session.Table.Column.Time': '時間',
    'Session.Table.Column.Client': 'クライアント',
    'Session.Table.Column.Project': 'プロジェクト',
    'Session.Table.Column.SoftwareVersion': 'ソフトウェアバージョン',
    'Session.Table.Column.Scenario': 'シナリオ',
    'Session.Table.Column.Trainees': '研修生',
    'Session.Table.Column.Training': 'トレーニング',
    'Session.Table.Column.Session': 'セッション',
    'Session.Token.Label.Clients': 'クライアント',
    'Session.Token.Label.Projects': 'プロジェクト',
    'Session.Token.Label.Scenarios': 'シナリオ',
    'Session.Token.Label.Teams': 'チーム',
    'Session.Token.Label.SessionStatuses': 'セッション状況',
    'Session.Button.More': 'もっと',
    'Session.Time.NotRunning': 'まだ実行されていません',
    'Session.Time.Finished': 'すでに終了しました',
    'Session.Time.Running': '今実行中です',
    'Session.Table.Column.Learners': '学習者',
    'Session.Table.Column.Team': 'チーム',
    'Session.Table.Column.Trainer': 'トレーナー',
    'Session.Table.Column.SimSpecialist': 'シムスペシャリスト',
    'Session.Table.Column.TimeUntil': '次までの時間',
    'Session.Table.Column.Created': '作成されました',
    'Session.Table.Column.Cancelled': 'キャンセル済み',
    'Session.Table.Caption.SessionDetailsList': 'セッションの詳細リスト',
    'Session.Export': 'エクスポート',
    'Session.Export.Message': '次によってフィルタリングされたコンテンツをエクスポート',
    'Session.CalendarDate.Start': '開始：',
    'Session.CalendarDate.End': '終了：',
    'Session.Cancel': 'キャンセル',
    'Session.Confirm': '確認',
    'Session.Total': '合計',
    'Session.Filter.SessionNotFoundMessage': 'セッションが見つかりませんでした。',
    'Session.Filter.LearnerUpcomingSessionNotFoundMessage': '今後のセッションは予定されていません。',
    'Session.Filter.UpcomingSessionNotFoundMessage': '今後のセッションは予定されていません。',
    'Session.Filter.LearnerActiveSessionNotFoundMessage': 'アクティブなセッションはありません。',
    'Session.Filter.ActiveSessionNotFoundMessage': 'アクティブなセッションはありません。',
    'Session.Filter.LearnerCompletedSessionNotFoundMessage': 'まだセッションを完了していません。',
    'Session.Filter.SessionReportNotFoundMessage': '利用可能なセッションレポートはありません。',
    'Session.Filter.CompletedSessionNotFoundMessage': 'まだセッションを完了していません。',
    'Session.Filter.CancelledSessionNotFoundMessage': 'キャンセルされたセッションはありません。',
    'Session.Edit.Modal.Table.Column.DateTime': '日付／時刻',
    'Session.Edit.Modal.Table.Column.UserRole': 'ユーザー（役割）',
    'Session.Edit.Modal.Table.Column.Change': '変更',
    'Session.Edit.Modal.Table.Column.SessionStatus': 'セッション状況',
    'Session.Edit.Modal.Table.Column.Reason': '理由',
    'Session.Edit.Modal.AssetIndustry': '資産の業種',
    'Session.Edit.Modal.SimulationEnvironment': 'シミュレーション環境',
    'Session.Edit.Modal.AvatarInSimulation': 'シミュレーションのアバター',
    'Session.Edit.Modal.Date': '日付',
    'Session.Edit.Modal.Time': '時間',
    'Session.Edit.Modal.DeliveryMode': '配信モード',
    'Session.Edit.Modal.Duration': '経過時間',
    'Session.Edit.Modal.Status': '状況',
    'Session.Edit.Modal.LearnersAttended': '参加した学習者',
    'Session.Edit.Modal.ReasonForCancellation': 'キャンセルの理由',
    'Session.Edit.Modal.MoreInfo': '詳細',
    'Session.Edit.Modal.HideAdditionalInfo': '追加情報を非表示にする',
    'Session.Edit.Modal.AddToCalendar': 'カレンダーに追加',
    'Session.Edit.Modal.AttendSession': 'セッションに参加',
    'Session.Edit.Modal.PleaseWait': 'しばらくお待ちください...',
    'Session.Edit.Modal.CopySessionLink': 'セッションリンクをコピー',
    'Session.Edit.Modal.SessionLink': 'セッションリンク',
    'Session.Edit.Modal.SubmitReview': '評価を送信',
    'Session.Edit.Modal.SessionRequestPending': 'セッションリクエストは保留中です',
    'Session.Edit.Modal.Back': '戻る',
    'Session.Edit.Modal.Learners': '学習者：',
    'Session.Edit.Modal.Learners.UnauthenticatedCheckbox': '認証されていないユーザーのみが参加します',
    'Session.Edit.Modal.Trainees': '研修生：',
    'Session.Edit.Modal.Trainer': 'トレーナー：',
    'Session.Edit.Modal.SimSpecialist': 'シムスペシャリスト：',
    'Session.Edit.Modal.Notes': '注意：',
    'Session.Edit.Modal.ClientNotes': 'クライアントメモ：',
    'Session.Edit.Modal.Notes.EmptyMessage': 'まだメモはありません',
    'Session.Edit.Modal.Attendance': '参加者：',
    'Session.Edit.Modal.ShowHistory': '履歴を表示',
    'Session.Edit.Modal.HideHistory': '履歴を非表示',
    'Session.Edit.Modal.LearnersAttendedLabel': '参加した学習者：',
    'Session.Edit.Modal.SimulationContent': 'シミュレーションコンテンツ',
    'Session.Edit.Modal.SimulationContent.Name': '名前：',
    'Session.Edit.Modal.SimulationContent.Description': '記述：',
    'Session.Edit.Modal.SimulationContent.Image': '画像：',
    'Session.Edit.Modal.RestoreSession.ConfirmationText': 'このセッションを復元しますか？',
    'Session.Edit.Modal.CancelTraining.ConfirmationText': 'このトレーニングをキャンセルしますか？',
    'Session.Edit.Modal.CancelSession.ConfirmationText': 'このセッションをキャンセルしますか？',
    'Session.Edit.Modal.SessionSwap.ConfirmationText': 'このセッションのスワップを受け入れますか？',
    'Session.Edit.Modal.AcceptRequest.ConfirmationText': 'このセッションリクエストを受け入れますか？',
    'Session.Edit.Modal.AssignSS.ConfirmationText': '新しいシムスペシャリストを割り当てますか？',
    'Session.Edit.Modal.ChangeSS.ConfirmationText': 'シムスペシャリストを変更しますか？',
    'Session.Edit.Modal.Button.RestoreSession': 'セッションを復元',
    'Session.Edit.Modal.Button.CancelTraining': 'トレーニングをキャンセル',
    'Session.Edit.Modal.Button.CancelSession': 'セッションをキャンセル',
    'Session.Edit.Modal.Button.AcceptSwap': 'スワップを受け入れる',
    'Session.Edit.Modal.Button.Participate': '参加する',
    'Session.Edit.Modal.Button.Swap': 'スワップする',
    'Session.Edit.Modal.Button.UpdateSession': 'セッションを更新',
    'Session.Edit.Modal.Button.UpdateTraining': 'トレーニングを更新',
    'Session.Edit.Modal.Button.AcceptRequest': 'リクエストを受け入れる',
    'Session.Edit.Modal.Button.AssignSS': 'SSを割り当てる',
    'Session.Edit.Modal.Button.ChangeSS': 'SSを変更する',
    'Session.Attendance.Table.Column.UserName': 'ユーザー名',
    'Session.Attendance.Table.Column.Time': '時間',
    'Session.Attendance.Table.Column.Status': '状況',
    'Session.ViewSessionReport': 'セッションレポートを表示',
    'Session.ViewEdit': 'セッションの表示/編集',
    'Session.Edit.Modal.Table.HistoryNotFoundMessage': 'まだ履歴はありません',
    'Session.Export.ErrMsg': 'エクスポートするエントリの最大制限は{limit}です。リクエストを変更して、もう一度お試しください。',
    'Session.Table.Column.Action': 'アクション',
    'Session.Edit.Modal.SessionSimSpecialistField.EmptyMsg.NoTrainers': '選択するトレーナーがいません',
    'Session.Edit.Modal.SessionSimSpecialistField.EmptyMsg.NoSimSpecialists': '選択するシムスペシャリストがいません',
    'Session.Edit.Modal.SessionSimSpecialistField.NoSelectedMsg.NoTrainers': 'トレーナーが選択されていません',
    'Session.Edit.Modal.SessionSimSpecialistField.NoSelectedMsg.NoSimSpecialists': 'シムスペシャリストが選択されていません',
    'Session.Edit.Modal.SessionSimSpecialistField.EditIconLabel': '編集',
    'Session.Edit.Modal.SessionSimSpecialistField.Search.Placeholder': '検索',
    'Session.Edit.Modal.SessionSimSpecialist.Button.Accept': '受け入れる',
    'ConfirmationDialogue.AddReasonForChange': '変更の理由を追加してください',
    'ConfirmationDialogue.Button.Submit': '送信する',
    'ConfirmationDialogue.Button.Cancel': 'キャンセル',
    'ConfirmationDialogue.Button.Ok': '了解',
    'ConfirmationDialogue.ReasonForChange': '変更理由',
    'ConfirmButton.CancelSession.ConfirmCancellation': 'キャンセルを確認',
    'ConfirmButton.CancelSession': 'セッションをキャンセル',
    'ConfirmButton.CancelSession.No': 'いいえ',
    'Pagination.Button.First': '最初',
    'Pagination.Button.Back': '戻る',
    'MursionPortal.UserName': 'ユーザー名',
    'MursionPortal.Email': 'メールアドレス',
    'ClientUserTable.Column.Roles': '役割',
    'MursionPortal.Department': '部門',
    'MursionPortal.Location': '場所',
    'ClientUserTable.Column.Projects': 'プロジェクト',
    'ClientUserTable.Column.Teams': 'チーム',
    'ClientUserTable.Column.Accepted': '承認済み',
    'ClientUserTable.HoverTitle': 'ユーザーの表示/編集',
    'ClientUserTable.NoUserError': 'ユーザーが存在しません',
    'MursionPortal.FirstName': '名',
    'MursionPortal.LastName': '姓',
    'MursionPortal.Phone': '電話',
    'Users.TableModal.EmailPlaceHolder': 'example@domain.com',
    'Users.TableModal.Title': '役職',
    'Users.TableModal.LocationPlaceHolder': '市、都道府県、国',
    'Users.TableModal.DepartmentPlaceHolder': '部門名',
    'Users.TableModal.TitlePlaceHolder': '役職名',
    'Users.Table.Modal.SettingsHeader': 'ファシリテーター/学習者の設定',
    'Users.Table.ModalHeader': 'ユーザー情報',
    'Users.Table.ModalButton.Cancel': 'キャンセル',
    'Users.Table.ModalButton.Delete': '削除',
    'Users.Table.ModalButton.Save': '保存',
    'Users.Table.ModalButton.SendInvite': '招待を送る',
    'Users.Table.ModalButton.Restore': '復元',
    'Users.Table.Modal.Teams.Text': 'チームはまだ追加されていません',
    'Users.Table.Modal.Teams': 'チーム',
    'Users.Table.Modal.EmployeeIdentification': '従業員の識別',
    'Users.Table.Modal.Timezone': 'タイムゾーン',
    'Users.Table.Modal.Roles': '役割',
    'Users.Table.Modal.Settings.LearnerButton': '学習者として',
    'Users.Table.Modal.Settings.FacilitatorButton': 'ファシリテーターとして',
    'Users.Button.CreateUserButton': 'ユーザーを作成する',
    'Users.Button.Cancel': 'キャンセル',
    'Users.Button.ImportUsers': 'ユーザーをインポート',
    'Users.Button.Export': 'エクスポート',
    'Users.heading.ExportContentFilteredBy': '次によってフィルタリングされたコンテンツをエクスポート',
    'Users.Button.Active': '有効',
    'Users.Button.Archived': 'アーカイブ済み',
    'Users.Button.All': 'すべて',
    'Users.Text.Total': '合計',
    'Users.Heading.NewUsers': '新しいユーザー',
    'Users.Placeholder': 'example@domain.com',
    'Users.Button.AddUser': 'ユーザーを追加',
    'Users.Button.CreateUser': 'ユーザーを作成',
    'Users.Button.CreateUsers': 'ユーザーを作成',
    'Users.Heading.ImportNewUsers': '新規ユーザーをインポート',
    'Users.Heading.ImportNewExternalUsers': '新しい外部ユーザーをインポート',
    'Users.Button.ImportUserList': 'ユーザーリストをインポート',
    'Users.Text.InstructionMessage.UsersWithoutPredefined': '事前定義されたチームを持たないユーザーは次に割り当てられます',
    'Users.Text.AddCsv': '1つの列に配置されたメールアドレスのリストを含む.csvまたは.xslxファイルを追加します。',
    'Users.Text.ToImport': '追加のフィールドをインポートするには、次のガイドラインに従ってください。',
    'Users.Text.ListUser': 'ユーザーのメールアドレスは、コンマ、セミコロン、パイプ、またはコロンでユーザーチームのリストから分離する必要があります。',
    'Users.Text.ListTeams': 'チームは、コンマによって互いに分離されている必要があります。',
    'Users.Text.ListFields': 'フィールドと各チームは、別々のセルに指定する必要があります。',
    'Users.Text.UserRole': 'ユーザー役割タイプはデフォルトで学習者になります',
    'Users.Text.UnlessOtherwiseIndicated': '特に断りのない限り',
    'Users.Text.SampleImport': 'ここからサンプルのインポートファイルをダウンロードできます。',
    'Users.DownloadCsvTemplate': 'CSVテンプレートをダウンロード',
    'Users.DownloadExcelTemplate': 'Excelテンプレートをダウンロード',
    'Users.Button.Update': '更新',
    'Users.Labels.Clients': 'クライアント',
    'Users.Labels.Projects': 'プロジェクト',
    'Users.Labels.Scenarios': 'シナリオ',
    'Users.Labels.Teams': 'チーム',
    'Users.Labels.Roles': '役割',
    'Users.Text.OnlyAccepted': '受け入れられたもののみ：',
    'Users.Button.Confirm': '確認',
    'Users.Button.AccOwner': 'アカウントオーナー',
    'Users.Button.Facilitator': 'ファシリテーター',
    'Users.Button.Learner': '学習者',
    'Users.Button.ProfServices': '専門サービス',
    'Users.Button.ProjectManager': 'プロジェクトマネージャー',
    'Users.Button.SimSpecialist': 'シムスペシャリスト',
    'Users.Button.ITManager': 'IT マネージャー',
    'Users.Button.BusinessAdmin': 'ビジネス管理者',
    'Users.Button.SupportAdmin': 'サポート管理者',
    'Users.Button.DevAdmin': '開発管理者',
    'Users.Button.ImportExternalUsers': '外部ユーザーをインポート',
    'Users.Button.AssignLearners': '学習者を割り当てる',
    'Users.CreateUser.Email.Placeholder': 'example@domain.com',
    'Users.Text.Instructions': '次のフィールドを持つユーザーのリストを含む.csvまたは.xslxファイルを追加します：メールアドレス、ssoid、名前（下の名前）、名字、役割、チーム。チームを除くすべてのフィールドが必須です。',
    'Users.Text.Guidelines': '次のガイドラインに従ってください。',
    'Users.Button.CreateUser.PencilIcon': 'ユーザーの詳細を編集',
    'Users.Button.CreateUser.TrashIcon': 'ユーザーを削除',
    'Users.ExportButton.TextYes': 'はい',
    'Users.ExportButton.TextNo': 'いいえ',
    'Users.Button.OK': '了解しました',
    'Users.Button.AddProject': 'プロジェクトを追加',
    'Users.CreateUser.Label.AddToProjects': 'プロジェクトに追加',
    'Users.UserInfo.Label.AddTeams': 'チームを追加',
    'Users.UserInfo.Label. SelectATeam': 'チームを選択',
    'Users.UserInfo.Label.AssignToTeam': 'チームへの割り当て：',
    'Users.CreateUser.Label.NoProjectsSpecified': 'プロジェクトは指定されていません',
    'Users.Option.SelectClient': 'クライアントを選択',
    'Users.Label.Client': 'クライアント：',
    'Users.Label.RemoveTeam': 'チームを削除',
    'Users.Label.ChooseATeam': 'チームを選択',
    'Users.Project.Warning': 'このクライアントにはプロジェクトがありません。別のものを選択してください。',
    'Users.Project.Warning.UnknownProject': '不明なプロジェクト',
    'Users.ProjectContainer.Warning': '少なくとも1つのプロジェクトを追加してください',
    'Users.CompanyUsers.Button.Ok': '了解',
    'Users.CompanyUsers.Button.Accept': '受け入れる',
    'Users.CreateUser.Label.Tier': 'ティア',
    'Users.CreateUser.Label.HireDate': '雇用日付',
    'Users.CreateUser.Label.StationType': 'ステーションタイプ',
    'Users.CreateUser.ToggleButton.Home': 'ホーム',
    'Users.CreateUser.ToggleButton.Office': 'オフィス',
    'Users.CreateUser.Label.Hours': '時間',
    'Users.CreateUser.ToggleButton.FullTime': 'フルタイム',
    'Users.CreateUser.ToggleButton.PartTime': 'パートタイム',
    'Users.CreateUser.Label.ZoomID': 'Zoom ID',
    'Users.CreateUser.ZoomID.Placeholder': 'Zoom ID',
    'Users.CreateUser.ZoomID.Hover': 'Zoom IDを入力してください',
    'Users.CreateUser.Label.UserRoles': 'ユーザーの役割',
    'Users.CreateUser.Label.ProjectManagerSettings': 'プロジェクトマネージャーの設定',
    'Users.CreateUser.Label.SimSpecialistSettings': 'シムスペシャリストの設定',
    'Users.CreateUser.Label.Certificates': '証明書',
    'Users.CreateUser.Label.TurnOnEditCertificates': '証明書の編集をオンにする',
    'Users.CreateUser.Label.TurnOffEditCertificates': '証明書の編集をオフにする',
    'Users.CreateUser.Label.NoCertificatesHaveBeenAddedYet': '証明書はまだ追加されていません',
    'Users.CreateUser.Label.AddCertificate': '証明書を追加',
    'Users.CreateUser.Label.CertificateOwner': '証明書の所有者',
    'Users.CreateUser.Button.RemoveCertificate': '証明書を削除',
    'Users.TableHeading.Name': 'お名前',
    'Users.TableHeading.Email': 'メールアドレス',
    'Users.TableHeading.Roles': '役割',
    'Users.TableHeading.Location': '場所',
    'Users.TableHeading.Accepted': '承認済み',
    'Users.Table.Hover.ViewEditUser': 'ユーザーの表示/編集',
    'Users.Table.Hover.NoUsersYet': 'ユーザーが存在しません',
    'Users.CreateUser.Button.Client': 'クライアント',
    'Users.CreateUser.Label.ChooseAClient': 'クライアントを選択',
    'Users.CreateUser.Label.SelectAClient': 'クライアントを選択',
    'Users.CreateUser.Label.ChooseACertificate': '証明書を選択',
    'Users.CreateUser.Label.SelectACertificate': '証明書を選択',
    'Users.CreateUser.Label.NoCertificatesToSelect': '選択できる証明書がありません',
    'Users.CreateUser.Button.AddCertificate': '証明書を追加',
    'Users.CreateUser.PlaceHolder.SelectTier': 'ティアを選択',
    'Users.RequiredField.Warning': 'すべてのフィールドが正しく入力されていることを確認してください',
    'Users.RequiredField.ApplyChanges': '変更を適用',
    'Users.Heading.NewUserInfo': '新規ユーザー情報',
    'Users.SearchPlaceHolderText': 'ユーザー名を入力',
    'Users.SearchNoUserFoundError': 'クライアントユーザーは見つかりませんでした。',
    'Users.Table.Modal.Settings.AddTeamHeader': 'チームを追加',
    'Users.Table.Modal.Settings.ChooseTeamHeader': 'チームを選択',
    'Users.Table.Modal.Settings.AssignTeamHeader': 'チームへの割り当て：',
    'Users.Table.Modal.Settings.SelectTeamOptionHeader': 'チームを選択',
    'Users.Table.Modal.Settings.TeamLearner': '（学習者）',
    'Users.Table.Modal.Settings.TeamFacilitator': '（ファシリテーター）',
    'Users.Table.Modal.Settings.RemoveTeamOption': 'チームを削除',
    'Users.Table.Modal.Settings.TurnOnEditTeamsOption': 'チーム編集をオンにする',
    'Users.Table.Modal.Settings.TurnOffEditTeamsOption': 'チーム編集をオフにする',
    'Users.Total': '合計',
    'Users.Table.Modal.ArchiveHeader.ConfirmArchiveUser': 'アーカイブユーザーを確認',
    'Users.Table.Modal.ArchiveText': 'このユーザーをアーカイブしますか？',
    'Users.Table.Modal.ArchiveUserButton': 'ユーザーをアーカイブ',
    'Users.Table.Modal.RestoreHeader.ConfirmRestoreUser': 'ユーザーの復元を確認',
    'Users.Table.Modal.RestoreText': 'このユーザーを復元しますか？',
    'Users.Table.Modal.RestoreUserButton': 'ユーザーを復元',
    'Users.Table.Modal.CancelButton': 'キャンセル',
    'Users.Table.Modal.ArchiveButton': 'アーカイブ',
    'Users.Table.Modal.RestoreButton': '復元',
    'Users.Table.Modal.SendInviteButton': '招待を送る',
    'Users.CreateUserButton.Modal.CreateUserButton': 'ユーザーを作成',
    'Users.Table.Modal.UpdateUserButton': 'ユーザーを更新',
    'Users.ViewUserOptions': '{companyName}ユーザーを表示',
    'Users.NewUserOptions': '新しい{companyName}ユーザー',
    'Users.EditUserOptions': '{companyName}ユーザーを編集',
    'Reports.SessionListHeader.Completed': '完了',
    'Reports.SessionListHeader.TotalSessionTime': '総セッション時間,',
    'Reports.SessionListHeader.TotalActualTime': '合計実時間,',
    'Reports.SessionListHeader.SimulationCompletionTime': 'シミュレーション完了時間',
    'Reports.SessionListHeader.CantOpenReport': '分析エンジンが結果を処理するまでお待ちください。数分後にこのページを更新する必要が生じる場合があります。',
    'Reports.SessionListHeader.NoReportsFound': 'レポートが見つかりません。',
    'Reports.ReportCard.Button.ReviewSimulation': '評価シミュレーション',
    'Reports.ReportCard.Strategies': '戦略',
    'Reports.ReportCard.NotChecked': '未確認',
    'Dashboard.SchedulingInfoTable.Modal.SortBy': '並べ替え：',
    'Dashboard.SchedulingInfoTable.Modal.NoItemsFound': 'アイテムが見つかりませんでした。',
    'Dashboard.SchedulingInfoTable.Modal.Teams': 'チーム',
    'Dashboard.SchedulingInfoTable.Modal.Teams.Placeholder': 'チーム名を入力',
    'Dashboard.SchedulingInfoTable.Modal.Teams.EmptyMessage': 'チームが見つかりません',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.TeamName': 'チーム名',
    'Dashboard.SchedulingInfoTable.Modal.Learners': '学習者：',
    'Dashboard.SchedulingInfoTable.Modal.Projects': 'プロジェクト：',
    'Dashboard.SchedulingInfo.Filter.SearchIndustries.PlaceholderHint': '業種を検索',
    'Dashboard.SchedulingInfo.Filter.IndustryDisabledTitle': '無効化',
    'Dashboard.SchedulingInfo.Filter.UsedSoftwareVersion.PlaceholderHint': '使用されているソフトウェアバージョン',
    'Dashboard.SchedulingInfoTable.Modal.Total': '合計',
    'Dashboard.SchedulingInfoTable.Modal.ScenarioName': 'シナリオ名',
    'Dashboard.SchedulingInfoTable.Modal.SessionDate': 'セッション日付',
    'Dashboard.SchedulingInfoTable.Modal.ProjectName': 'プロジェクト名',
    'Dashboard.SchedulingInfoTable.Modal.SoftwareVersion': 'ソフトウェアバージョン',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.DeliveryMode': '配信モード',
    'Dashboard.SchedulingInfoTable.Modal.SessionStatus': 'セッション状況',
    'Dashboard.SchedulingInfoTable.Modal.Name': 'お名前',
    'Dashboard.SchedulingInfoTable.Modal.Status': '状況',
    'Dashboard.SchedulingInfoTable.Modal.Recording': '録音は次へ',
    'Dashboard.SchedulingInfoTable.Modal.TeamName': 'チーム名',
    'Dashboard.SchedulingInfoTable.Modal.Learner': '学習者',
    'Dashboard.SchedulingInfoTable.Modal.CompletedSessions': '完了したセッション',
    'Dashboard.SchedulingInfoTable.Modal.DeliveryMode': '配信モード',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.ProjectName': 'プロジェクト名',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.SoftwareVersion': 'ソフトウェアバージョン',
    'Dashboard.SchedulingInfoTable.Modal.Timeline': 'タイムライン',
    'Dashboard.SchedulingInfoTable.Modal.SchedulesSessions': 'セッションをスケジュール',
    'Dashboard.SchedulingInfoTable.Modal.MissedSessions': '混合セッション',
    'Dashboard.SchedulingInfoTable.Modal.LateCancellations': '「遅い」キャンセル',
    'Dashboard.SchedulingInfoTable.Modal.TimeSpent': '所要時間',
    'Dashboard.SchedulingInfoTable.Modal.CancelledBy': 'キャンセル者',
    'Dashboard.SchedulingInfoTable.Modal.CancellationReason': 'キャンセル理由',
    'Dashboard.SchedulingInfoTable.Modal.DateScheduled': '予定日付',
    'Dashboard.SchedulingInfoTable.Modal.DateCancelled': 'キャンセル日付',
    'Dashboard.SchedulingInfoTable.Modal.Filter.Teams': 'チーム',
    'Dashboard.SchedulingInfoTable.Modal.Placeholder.EnterTeamName': 'チーム名を入力',
    'Dashboard.SchedulingInfoTable.Modal.TeamsEmptyMessage': 'チームが見つかりません',
    'Dashboard.SchedulingInfoTable.Modal.Filter.TeamName': 'チーム名',
    'Dashboard.SchedulingInfoTable.Modal.Filter.Scenarios': 'シナリオ',
    'Dashboard.SchedulingInfoTable.Modal.Placeholder.EnterScenarioName': 'シナリオ名を入力してください',
    'Dashboard.SchedulingInfoTable.Modal.ScenariosEmptyMessage': 'シナリオは見つかりませんでした',
    'Dashboard.SchedulingInfoTable.Modal.Filter.ScenarioName': 'シナリオ名',
    'Dashboard.SchedulingInfoTable.Modal.Filter.ProjectName': 'プロジェクト名',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SoftwareVersion': 'ソフトウェアバージョン',
    'Dashboard.SchedulingInfoTable.Modal.Filter.ScenarioStatus': 'シナリオ状況',
    'Dashboard.SchedulingInfoTable.Modal.Filter.DeliveryMode': '配信モード',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsScheduled': 'スケジュールされたセッション',
    'Dashboard.SchedulingInfoTable.Modal.SessionsEmptyMessage': 'セッションが見つかりませんでした。',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsDateTime': 'セッション日時',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsCompleted': '完了したセッション',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsMissed': '見逃したセッション',
    'Dashboard.SchedulingInfoTable.Modal.Filter.LateCancellations': '「遅い」キャンセル',
    'Dashboard.SchedulingInfoTable.Modal.Filter.CancelledBy': 'キャンセル者',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsError': 'セッションエラー',
    'RestorePassword.EnterEmailAddress': 'メールアドレスを入力し、[復元]ボタンをクリックしてください。パスワードをリセットするためのリンクが記載されたメールが送信されます。',
    'RestorePassword.Email': 'メールアドレス',
    'RestorePassword.Button.Cancel': 'キャンセル',
    'RestorePassword.Button.Restore': '復元',
    'RestorePassword.Modal.IfValidEmailAddressEntered': '有効なメールアドレスが入力されると、リセット手順が記載されたメールが関連付けられたアカウントが送信されます。問題が解決しない場合は、テクニカルサポートにご連絡ください。',
    'RestorePassword.Modal.Button.GotIt': '了解しました',
    'Trainings.Heading.AllTrainingSessions': 'すべてのトレーニングセッション',
    'Requests.Table.Swap': 'スワップする',
    'Requests.Table.SchedulingRequests': 'リクエストをスケジュール',
    'Requests.Total': '合計',
    'Requests.Table.NoSessionsFound': 'セッションが見つかりませんでした。',
    'Requests.Table.NoUpcomingSwapSessions': 'SWAPセッションは予定されていません。',
    'Requests.Table.NoUpcomingRequests': 'リクエストは予定されていません。',
    'GetTimeToEvents.Day': '日',
    'GetTimeToEvents.Days': '日',
    'GetTimeToEvents.Minute': '分',
    'GetTimeToEvents.Minutes': '分',
    'GetTimeToEvents.Hour': '時間',
    'GetTimeToEvents.Hours': '時間',
    'GetTimeToEvents.LessThanOneMinute': '1 分未満',
    'SessionReports.SessionInformation': 'セッション情報',
    'SessionReports.SessionInformation.Hover.Information': '情報',
    'SessionReports.SmartMetrics': 'スマートメトリクス',
    'SessionReports.SendVideoLink.Modal.ShareLinkToVideo': 'ビデオへのリンクを共有',
    'SessionReports.Button.BackToReports': 'レポートに戻る',
    'SessionReports.Button.BackToTrainings': 'トレーニングに戻る',
    'SessionReports.Button.HideInfo': '情報を非表示にする',
    'SessionReports.Button.ShowInfo': '情報を表示',
    'SessionReports.Button.SendVideoLink': 'ビデオリンクを送信',
    'SessionReports.TryAgain': '再度お試しください',
    'SessionReports.Mode': 'モード：',
    'SessionReports.SessionLength': 'セッションの長さ：',
    'SessionReports.LearnersAttended': '参加した学習者：',
    'SessionReports.ScenarioTimeline': 'シナリオのタイムライン：',
    'SessionReports.ActualSessionTime': '実際のセッション時間：',
    'SessionReports.ActualSimulationTime': '実際のシミュレーション時間：',
    'SessionReports.LastUserStatus': '最終ユーザー状況：',
    'SessionReports.SessionStatus': 'セッション状況：',
    'SessionReports.Delivery': '配信',
    'SessionReports.SendVideoLink.Modal.AddEmails': 'メールを追加',
    'SessionReports.SendVideoLink.Modal.Button.AddEmail': 'メールを追加',
    'SessionReports.SendVideoLink.Modal.EmailPlaceHolder': 'example@domain.com',
    'SessionReports.SendVideoLink.Modal.Button.Cancel': 'キャンセル',
    'SessionReports.SendVideoLink.Modal.Button.Send': '送信',
    'SessionReports.FactSessionInfo': 'ファクトセッション情報',
    'SessionReports.Text.NoTraineeParticipatedInSession': 'セッションに参加している研修生はいません',
    'SessionReports.Text.NoLearnerParticipatedInSession': 'セッションに参加した学習者はいません',
    'SessionReports.ToolTip.TrackingYours': '結果または戦略項目の認識がアバターの認識と一致していることが最も重要です。これは、アバターへの影響を追跡していることを示しており、それによって社会的有効性の向上に役立っています。この情報は、以下の表に含まれています。',
    'SessionReports.ToolTip.TrackingLearners': '結果または戦略項目に対する学習者の認識がアバターの認識と一致していることが最も重要です。これは、学習者がアバターへの影響を追跡していることを示しており、社会的有効性の向上に役立ちます。この情報は、以下の表に含まれています。',
    'SessionReports.ToolTip.AvatarsHeaderYours': 'この列は、シミュレーションパフォーマンスに対するアバターの認識を示しています。チェックマークは、アバターが成果の達成に成功したと認識したことを示し、クロスマークは改善の余地があることを示します。',
    'SessionReports.ToolTip.AvatarsHeaderLearners': 'この列は、学習者のシミュレーションパフォーマンスに対するアバターの認識を示しています。チェックマークは、アバターが学習者が成果の達成に成功したと認識したことを示し、クロスマークは改善の余地があることを示します。',
    'SessionReports.ToolTip.SelfHeaderYours': 'この列は、シミュレーションパフォーマンスの独自の評価を示します。チェックマークは成功したと思ったことを示し、クロスマークは改善の余地があることを示します。',
    'SessionReports.ToolTip.SelfHeaderLearners': 'この列は、学習者によるシミュレーションパフォーマンスへの評価を示しています。チェックマークは学習者が成功したという認識を示し、クロスマークは改善の余地があることを示します。',
    'SessionReports.ToolTip.VideoLink': 'シミュレーションパフォーマンスビデオへのリンクを同僚と共有することができます。ポップアップボックスにメールアドレスを入力してください。あなたの分析は同僚と共有されないことにご注意ください。',
    'SessionReports.ToolTip.SmartMetrics': 'スマートメトリクスは、高度なアルゴリズムを使用して生成されます。これらのコンピューターで生成された測定値を観察して、さまざまなシミュレーション中に全体的な追跡に影響があるかどうかを確認します。',
    'SessionReports.ToolTip.MiScore': 'Mursion Index（MI）スコアは、この会話の社会的有効性の指標となります。これは、会話の順序パターンやアバターへの影響など、さまざまなデータを使用して計算されます。アバターへの影響を評価し、さまざまな方法で会話にアプローチして、MIスコアにプラスの影響を与える方法を確認してください。',
    'SessionReports.ToolTip.ListeningSpeakingTime': 'これは、会話中に話す時間と聞く時間の比率を示す指標です。',
    'SessionReports.ToolTip.Emotions': '感情（ベータ版）：現在、音声パターンを解釈して全体的な感情を判断できるアルゴリズムを調査中です。このデータを観察して、時間の経過とともにボーカルパターンにポジティブ、ネガティブ、またはニュートラルな意味合いがあったかどうかを確認します。さらに重要なのは、アバターのボーカルパターンに与えた影響を観察することです。',
    'SessionReports.ToolTip.TurnTracking': '話者交替は、あなたとアバターの間の協調的な性質・社会的有効性への洞察を流す一連の方法です。',
    'SessionReports.ToolTip.Pauses': '一時停止は、会話中の流暢さの間接的な尺度です。',
    'SessionReports.ToolTip.ArticulationRate': 'これは、継続的な情報セットが提供されるペースの尺度です。',
    'SessionReports.TableHeader.Outcome': '結果',
    'SessionReports.TableHeader.Self': '自身',
    'SessionReports.TableHeader.Avatars': 'アバター',
    'SessionReports.TableHeader.Strategies': '戦略',
    'MursionPortal.Button.Cancel': 'キャンセル',
    'Settings.Config.Heading.Industries': '業種',
    'Settings.Config.Button.New': '新規',
    'Settings.Config.Note': '注意：',
    'Settings.Config.Note.Text.ConfigSettingsGlobal': '構成設定はグローバルです。変更はすべてのクライアントとユーザーに適用されます。',
    'Settings.Config.Heading.SimSpecialist': 'シムスペシャリストティア',
    'Settings.Config.Heading.Scheduling': 'スケジューリング',
    'Settings.Config.Heading.Scheduling.Description': '（シムスペシャリスト）',
    'Settings.Config.Scheduling.MaximumFullTimeSimSpecialistHours': 'フルタイムの最大シムスペシャリスト時間',
    'Settings.Config.Scheduling.MaximumPartTimeSimSpecialistHours': 'パートタイムの最大シムスペシャリスト時間',
    'Settings.Config.Scheduling.MaximumContinuousSimSpecialistHours': '連続する最大シムスペシャリスト時間',
    'Settings.Config.Scheduling.LengthOfSimSpecialistRequiredBreak': 'シムスペシャリストが必要とする休憩時間',
    'Settings.Config.Scheduling.FutureAvailabilityForSimSpecialist': 'シムスペシャリストの将来の可用性ウィンドウ',
    'Settings.Config.Scheduling.SimSpecialistShiftTime': 'シムスペシャリストシフトタイム',
    'Settings.Config.Scheduling.AvailableTimeBlockMaximumPerDay': '1日あたりの最大可用性時間枠',
    'Settings.Config.Scheduling.AvailableTimeBlockMinimumPerDay': '1日あたりの最小可用性時間枠',
    'Settings.Config.Scheduling.AvailabilityTimeStep': '可用性の時間ステップ',
    'Settings.Config.Scheduling.AdvanceSSScheduleConfirmation': 'SSスケジュールはどのくらい前に確認する必要がありますか',
    'Settings.Config.Scheduling.RequestUnfulfilledReminder': '「リクエストが満たされていない」リマインダーを送信するタイミング',
    'Settings.Config.Scheduling.FirstEmailReminder': '最初のメールリマインダーを送信するタイミング',
    'Settings.Config.Scheduling.SecondEmailReminder': '2番目のメールリマインダーを送信するタイミング',
    'Settings.Config.Scheduling.BookedSessionWithNoAssignedLearner': '学習者が割り当てられていない予約済みセッションを通知するタイミング',
    'Settings.Config.Scheduling.UnfulfilledSessionSwapRequestReminder': '満たされていないセッションスワップリクエストに関するリマインダーを送信するタイミング',
    'Settings.Config.Scheduling.NextSessionReminder': 'セッションの作成からセッションの開始予定時刻までの時間に応じて、次のセッションリマインダーを送信するタイミング',
    'Settings.Config.Scheduling.ReservedAvailabilityExpired': '予約済みの可用性が期限切れになるタイミング',
    'Settings.Config.Scheduling.StartTimeForConsideringMissedSession': 'セッションを「逃した」と見なされる開始時刻からの時間',
    'Settings.Config.Scheduling.EndTimeForConsideringMissedSession': 'セッションを「逃した」と見なされる終了時間後の時間',
    'Settings.Config.Scheduling.RejoinSessionAfterError': 'セッション参加者がエラー後にセッションに再参加する必要がある時間',
    'Settings.Config.Scheduling.TimeDisabledForSessionScheduling': 'セッションスケジューリングで無効になっている時間',
    'Settings.Config.Scheduling.SessionTimeStep': 'セッションタイムステップ',
    'Settings.Config.Heading.ProjectAndScenario': 'プロジェクトとシナリオ',
    'Settings.Config.ProjectAndScenario.MaximumProjectTimelineDuration': 'プロジェクトの最大タイムライン期間',
    'Settings.Config.ProjectAndScenario.MinimumProjectTimelineDuration': 'プロジェクトの最小タイムライン期間',
    'Settings.Config.ProjectAndScenario.MaximumScenarioTimelineDuration': 'シナリオの最大タイムライン期間',
    'Settings.Config.ProjectAndScenario.MinimumScenarioTimelineDuration': 'シナリオの最小タイムライン期間',
    'Settings.Config.ProjectAndScenario.MinimumSessionLength': '最小セッション長',
    'Settings.Config.ProjectAndScenario.MaximumSessionLength': '最大セッション長',
    'Settings.Config.ProjectAndScenario.MinimumSimulationLength': '最小シミュレーション長',
    'Settings.Config.ProjectAndScenario.SessionLengthStep': 'セッション長ステップ',
    'Settings.Config.ProjectAndScenario.MinimumLearnersInRemoteSession': 'リモートセッションにおける学習者の最小人数',
    'Settings.Config.ProjectAndScenario.MaximumLearnersInRemoteSession': 'リモートセッションにおける学習者の最大人数',
    'Settings.Config.ProjectAndScenario.MinimumLearnersInFishbowlSession': 'ローカル金魚鉢セッションにおける学習者の最小人数',
    'Settings.Config.ProjectAndScenario.MaximumLearnersInFishbowlSession': 'ローカル金魚鉢セッションにおける学習者の最大人数',
    'Settings.Config.ProjectAndScenario.MinimumLearnersInGroupSession': 'グループセッションにおける学習者の最小人数',
    'Settings.Config.ProjectAndScenario.MaximumLearnersInGroupSession': 'グループセッションにおける学習者の最大人数',
    'Settings.Config.ProjectAndScenario.AllowedScenarioVersions': '許可されたシナリオバージョン',
    'Settings.Config.ProjectAndScenario.DefaultScenarioVersion': 'デフォルトのシナリオバージョン',
    'Settings.Config.Heading.CompanyEvent': '会社のイベント',
    'Settings.Config.CompanyEvent.MaximumEventDuration': '最大イベント期間',
    'Settings.Config.CompanyEvent.MinimumEventDuration': '最小イベント期間',
    'Settings.Config.CompanyEvent.EventTimeStep': 'イベントタイムステップ',
    'Settings.Config.Heading.SessionReport': 'セッションレポート',
    'Settings.Config.SessionReport.ShowSessionReport': 'セッションレポートを表示',
    'Settings.Config.Heading.FeedbackProvider': 'フィードバックプロバイダー',
    'Settings.Config.FeedbackProvider.ZenDeskSnippetKey': 'ZenDeskスニペットキー',
    'Settings.Config.Heading.SimulationContent': 'シミュレーション',
    'Settings.Config.SimulationContent.SimulationContentFolder': 'シミュレーションコンテンツS3フォルダー',
    'Settings.Config.Heading.Invitation': '招待',
    'Settings.Config.Invitation.ExpireUserRegistrationInvitation': 'ユーザー登録の招待を期限切れにするタイミング',
    'Settings.Config.Invitation.ReminderToAcceptRegistrationInvitation': '未登録のユーザーに登録の招待を受け入れるように通知するタイミング',
    'Settings.Config.Invitation.RegistrationInvitationExpiresAfter': '登録の招待状が期限は次に切れます',
    'Settings.Config.IndustriesButton.Modal.EditIndustry': '業種を編集する',
    'Settings.Config.NewButton.Modal.NewIndustry': '新しい業種',
    'Settings.Modal.Placeholder.IndustryName': '業種名',
    'Settings.Config.IndustriesButton.Modal.Button.UpdateIndustry': '業種を更新する',
    'Settings.Config.NewButton.Modal.Button.CreateIndustry': '業種を作成する',
    'Settings.Config.IndustriesButton.Modal.Button.RestoreIndustry': '業種を復元する',
    'Settings.Config.IndustriesButton.Modal.Button.ArchiveIndustry': '業種をアーカイブする',
    'MursionPortal.Modal.Heading.Name': 'お名前',
    'Settings.Config.RestoreIndustry.Modal.ConfirmRestore': 'この業種を復元しますか？',
    'Settings.Config.ArchiveIndustry.Modal.ConfirmArchive': 'この業種をアーカイブしますか？',
    'Settings.Config.Description.PerWeek': '（週ごと）',
    'Settings.Config.Description.PerDay': '（1日あたり）',
    'Settings.Config.Description.BeforeBreak': '（休憩前）',
    'Settings.Config.Description.Minutes': '（分）',
    'Settings.Config.Description.Days': '（日）',
    'Settings.Config.Description.Hours': '（時間）',
    'Settings.Config.TierButton.Modal.Button.UpdateTier': 'ティアを更新',
    'Settings.Config.TierButton.Modal.Heading.EditTier': 'ティアを編集',
    'Settings.Config.TierButton.Modal.Placeholder.TierName': 'ティア名',
    'MursionPortal.Button.Archive': 'アーカイブ',
    'Settings.Config.ArchiveIndustry.Modal.Heading.ConfirmArchive': 'アーカイブを確認',
    'Settings.Config': '構成',
    'Settings.ScenarioBank': 'シナリオバンク',
    'Settings.Environments': '環境（ML2）',
    'Settings.Avatars': 'アバター（ML2）',
    'Settings.SSO': 'SSO',
    'Settings.Error.NoSettingsAvailable': '利用可能な設定はありません',
    'MursionPortal.Error.SomethingWentWrong': '申し訳ありません。何か問題が発生したようです。',
    'MursionPortal.Error.ReloadingPage': 'ページを再読み込みしてみてください。エラーが報告されました。',
    'MursionPortal.Error.WorkingHard': 'できるだけ早く修正できるよう努めています。',
    'MursionPortal.Error.SupportAvailable': 'サポートは月曜日から金曜日までご利用いただけます',
    'MursionPortal.Error.TechnicalAssistance': '技術サポ―トが必要な場合は、Mursionサポートにお問い合わせください',
    'MursionPortal.At': '問合せ先',
    'MursionPortal.Or': 'または',
    'Settings.Environment.Modal.PlaceHolder.EnvironmentName': '環境名',
    'Settings.Environment.Modal.Description': '記述',
    'Settings.Environment.Modal.Industry': '業種',
    'Settings.Environment.Modal.Picture': '写真',
    'MursionPortal.Button.Delete': '削除',
    'Settings.Environment.Modal.Button.UploadFile': 'ファイルをアップロード',
    'Settings.Environment.Button.CreateEnvironment': '環境を作成',
    'Settings.Environment.ModalHeader.NewEnvironment': '新しい環境',
    'Settings.Environment.ModalHeader.Environment': '環境',
    'Settings.Environment.ModalHeader.Archived': '（アーカイブ済み）',
    'Settings.Environment.ModalHeader.EditEnvironment': '環境を編集',
    'Settings.Environment.Modal.Button.CreateEnvironment': '環境を作成',
    'Settings.Environment.Modal.Button.UpdateEnvironment': '環境を更新',
    'Settings.Environment.Modal.ArchiveEnvironmentText': 'この環境をアーカイブしますか？',
    'Settings.Environment.Modal.RestoreEnvironmentText': 'この環境を復元しますか？',
    'MursionPortal.Button.Restore': '復元',
    'MursionPortal.Total': '合計',
    'MursionPortal.Text.Total': '合計',
    'MursionPortal.Button.ExportToEmail.HoverTitle.ExportData': 'データをエクスポート',
    'MursionPortal.Button.ExportToEmail.HoverTitle.ExportNotAvailable': 'エクスポートは利用できません',
    'MursionPortal.Button.ExportToEmail': 'メールにエクスポート',
    'MursionPortal.WarningMessage.RequestNotImplemented': 'データリクエストはまだ実装されていません',
    'MursionPortal.Table.ColumnHeader.Client': 'クライアント',
    'MursionPortal.Table.ColumnHeader.Provider': 'プロバイダー',
    'MursionPortal.Table.ColumnHeader.Project': 'プロジェクト',
    'MursionPortal.Table.ColumnHeader.Scenario': 'シナリオ',
    'MursionPortal.Table.ColumnHeader.SoftwareVersion': 'ソフトウェアバージョン',
    'MursionPortal.Table.ColumnHeader.DeliveryMode': '配信モード',
    'MursionPortal.Table.ColumnHeader.SessionStart': 'セッション開始',
    'MursionPortal.Table.ColumnHeader.SessionEnd': 'セッション終了',
    'MursionPortal.Table.ColumnHeader.LearnerStatus': '学習者の状況',
    'MursionPortal.Table.ColumnHeader.SessionStatus': 'セッション状況',
    'MursionPortal.Table.ColumnHeader.LearnerName': '学習者名',
    'MursionPortal.Table.ColumnHeader.LearnerEmail': '学習者のメールアドレス',
    'MursionPortal.Table.LearnerEmail.HoverTitle.CopyEmail': 'メールをコピーする',
    'MursionPortal.Table.LearnerEmail.HoverTitle.UserEmail': 'ユーザーのメールアドレス',
    'MursionPortal.Table.ColumnHeader.SessionId': 'セッション ID',
    'MursionPortal.Table.SessionId.HoverTitle.CopySessionId': 'セッション IDをコピー',
    'MursionPortal.Table.ColumnHeader.SessionReport': 'セッションレポート',
    'MursionPortal.Table.ColumnHeader.SimSpecialistName': 'シムスペシャリスト名',
    'MursionPortal.Table.ColumnHeader.LearnerTimeSpent': '学習者の費やした時間',
    'MursionPortal.Table.HoverTitle.SessionSummary': 'セッションの概要',
    'MursionPortal.EmptyMessage.NoSessionsYet': 'まだセッションはありません',
    'MursionPortal.Button.ViewReport': 'レポート表示',
    'Mursion.Portal.Status.Requested': '要求済み',
    'Mursion.Portal.Status.Pending': '保留中',
    'Mursion.Portal.Status.PendingSwap': '保留中（スワップ）',
    'Mursion.Portal.Status.PreBooked': '事前予約',
    'Mursion.Portal.Status.Booked': '予約済み',
    'Mursion.Portal.Status.Active': '有効',
    'Mursion.Portal.Status.Complete': '完了',
    'Mursion.Portal.Status.Error': 'エラー',
    'Mursion.Portal.Status.Missed': '逃した',
    'Mursion.Portal.Status.NeedsReview': '評価が必要',
    'Mursion.Portal.Status.Cancelled': 'キャンセル済み',
    'Dashboard.UsersActivity.Filter.Roles.Placeholder': '検索',
    'Settings.ScenarioBank.Table.ColumnHeader.Name': 'お名前',
    'MursionPortal.DeliveryMode': '配信モード',
    'Settings.ScenarioBank.Table.ColumnHeader.SessionSize': 'セッションサイズ',
    'MursionPortal.SessionLength': 'セッションの長さ',
    'Settings.ScenarioBank.Table.ViewEditScenario': 'シナリオを表示/編集',
    'Settings.ScenarioBank.Table.ThereAreNoScenarios': 'まだシナリオはありません',
    'MursionPortal.Capitalized.Total': '合計',
    'Settings.ScenarioBank.Form.NewScenario': '新規シナリオ',
    'Settings.ScenarioBank.Form.Scenario': 'シナリオ',
    'Settings.ScenarioBank.Form.EditScenario': 'シナリオを編集',
    'Settings.ScenarioBank.Form.Save': '保存',
    'Settings.ScenarioBank.Form.ConfirmArchiveScenario': 'このシナリオをアーカイブしますか？',
    'Settings.ScenarioBank.Form.ConfirmRestoreScenario': 'このシナリオを復元しますか？',
    'Settings.ScenarioBank.Button.CreateScenario': 'シナリオを作成',
    'MursionPortal.ShortLabel.Minutes': '最小',
    'Settings.ScenarioBank.Modal.Scenarios': 'シナリオ',
    'Settings.ScenarioBank.Modal.CompleteProjectInfoFirst': '最初にプロジェクト情報を完成させる',
    'Settings.ScenarioBank.Modal.ScenarioName': 'シナリオ名',
    'Settings.ScenarioBank.Modal.Placeholder.EnterScenarioName': 'シナリオ名を入力してください',
    'Settings.ScenarioBank.Modal.Outcome': '結果',
    'Settings.ScenarioBank.Modal.Strategies': '戦略',
    'Settings.ScenarioBank.Modal.Attachments': '添付ファイル',
    'Settings.ScenarioBank.Modal.SoftwareVersion': 'ソフトウェアバージョン',
    'Settings.ScenarioBank.Modal.WeAlreadyHaveSessions': 'このシナリオのセッションはすでに存在します',
    'Settings.ScenarioBank.Modal.SelectDeliveryMode': '配信モードを選択',
    'Settings.ScenarioBank.Modal.ShouldBeAtLeast': '少なくとも{minutes}分にしてください',
    'Settings.ScenarioBank.Modal.SelectSessionLength': 'セッションの長さを選択',
    'Settings.ScenarioBank.Modal.SessionSize': 'セッションサイズ',
    'Settings.ScenarioBank.Modal.Learner': '学習者',
    'Settings.ScenarioBank.Modal.Learners': '学習者',
    'Settings.ScenarioBank.Modal.SelectSessionSize': 'セッションサイズを選択',
    'MursionPortal.SimulationContent': 'シミュレーションコンテンツ',
    'Settings.ScenarioBank.Modal.ToolTip.ThisIsTheArtContent': 'これは、シミュレーションで使用される環境とアバターの3Dアートコンテンツです。このフォルダのコンテンツは、以下の「コンテンツバンドル」というタイトルのセクションに表示されます。',
    'Settings.ScenarioBank.Modal.ThisScenarioHasNoSimulation': 'シナリオにはシミュレーションコンテンツが割り当てられていません',
    'Settings.ScenarioBank.Modal.SelectSimulationContentForUse': '使用するシミュレーションコンテンツを選択',
    'Settings.ScenarioBank.Modal.Label.ScenarioOutcome': 'シナリオの結果',
    'Settings.ScenarioBank.Modal.Placeholder.EnterAnOutcome': '結果を入力',
    'Settings.ScenarioBank.Modal.Description': '記述',
    'Settings.ScenarioBank.Modal.NoDescriptionSpecified': '記述が指定されていません',
    'Settings.ScenarioBank.Modal.ScenarioDescription': 'シナリオの記述',
    'Settings.ScenarioBank.Modal.AllowedCharactersLimit': '許可されている文字数制限（{maxLength}文字）を超えています',
    'Settings.ScenarioBank.Modal.ScenarioStrategies': 'シナリオ戦略',
    'Settings.ScenarioBank.Modal.Placeholder.EnterAnItem': 'アイテムを入力',
    'Settings.ScenarioBank.Modal.Attachments.DragAndDrop': 'ここにいくつかのファイルをドラッグアンドドロップするか、クリックしてファイルを選択',
    'Settings.ScenarioBank.Modal.Attachments.UploadedMaximumFiles': '利用可能な最大数のファイル{maxFiles}をアップロードしました。',
    'Settings.ScenarioBank.Modal.Attachments.UploadedFiles': 'アップロードされたファイル,',
    'Settings.ScenarioBank.Modal.CertifiedSimSpecialists': '認定シムスペシャリスト',
    'Settings.ScenarioBank.Modal.NoCertifiedSimSpecialist': '認定シムスペシャリストなし',
    'Settings.ScenarioBank.Modal.Button.Add': '追加',
    'Settings.ScenarioBank.Modal.Edit': '編集',
    'Settings.ScenarioBank.Modal.AddAttachmentsToScenario': 'シナリオに添付ファイルを追加',
    'MursionPortal.Cancel': 'キャンセル',
    'MursionPortal.OK': '了解しました',
    'Settings.ScenarioBank.Modal.FileFormatsSupported': '次のファイル形式がサポートされています：',
    'Settings.ScenarioBank.Modal.Images': '画像',
    'Settings.ScenarioBank.Modal.Videos': '動画',
    'Settings.ScenarioBank.Modal.Audios': '音声ファイル',
    'Settings.ScenarioBank.Modal.Documents': '文書',
    'Settings.ScenarioBank.Modal.New': '（新規）',
    'Settings.ScenarioBank.Modal.FileSizeMustNotExceed': 'ファイルサイズは{size}以内にしてください',
    'Settings.ScenarioBank.Modal.CertifySimSpecialists': 'シムスペシャリストを認定',
    'MursionPortal.Confirm': '確認',
    'MursionPortal.Delete': '削除',
    'Settings.ScenarioBank.Modal.Archived': '（アーカイブ済み）',
    'Settings.ScenarioBank.Modal.Restore': '復元',
    'Settings.ScenarioBank.Modal.ConfirmArchive': 'アーカイブを確認',
    'Settings.ScenarioBank.Modal.Archive': 'アーカイブ',
    'Settings.ScenarioBank.Modal.ConfirmRestore': '復元を確認',
    'MursionPortal.PleaseWait': 'しばらくお待ちください...',
    'MursionPortal.Loading': '読み込み中',
    'Settings.ScenarioBank.Modal.Attachments.FileTypeNotSupported': 'ファイルタイプ{extensions}はサポートされていません',
    'Settings.ScenarioBank.Modal.Attachments.FileTypesNotSupported': 'ファイルタイプ{extensions}はサポートされていません',
    'Settings.ScenarioBank.Modal.Attachments.FileExceedsSizeLimit': 'ファイル{fileNames}がサイズ制限を超えています',
    'Settings.ScenarioBank.Modal.Attachments.FilesExceedSizeLimit': 'ファイル{fileNames}がサイズ制限を超えています',
    'MursionPortal.Label.Total': '合計',
    'MursionPortal.Button.HoverTitle.ExportData': 'データをエクスポート',
    'MursionPortal.Button.HoverTitle.ExportNotAvailable': 'エクスポートは利用できません',
    'MursionPortal.Heading.Filters': 'フィルター：',
    'Dashboard.ProjectsTable.Checkbox.OnlyBillable': '請求可能なもののみ',
    'MursionPortal.Filters.SoftwareVersion': 'ソフトウェアバージョン',
    'MursionPortal.Filters.PlaceHolder.SearchVersion': 'バージョンを検索',
    'MursionPortal.Filters.Status': '状況',
    'MursionPortal.Filters.PlaceHolder.SearchStatus': '状況を検索',
    'Dashboard.ProjectsTable.ColumnHeader.Provider': 'プロバイダー',
    'Dashboard.ProjectsTable.ColumnHeader.Status': '状況',
    'Dashboard.ProjectsTable.ColumnHeader.StarAt': '開始：',
    'Dashboard.ProjectsTable.ColumnHeader.EndAt': '終了：',
    'Dashboard.ProjectsTable.ColumnHeader.Billable': '請求可能',
    'Dashboard.ProjectsTable.ColumnHeader.CancellationWindow': 'キャンセルウィンドウ',
    'MursionPortal.Table.ColumnHeader.Scenarios': 'シナリオ',
    'MursionPortal.Table.ColumnHeader.Teams': 'チーム',
    'MursionPortal.Table.ColumnHeader.SessionsScheduled': 'スケジュールされたセッション',
    'MursionPortal.Table.ColumnHeader.SessionsCompleted': '完了したセッション',
    'MursionPortal.Table.ColumnHeader.SessionsMissed': '見逃したセッション',
    'MursionPortal.Table.ColumnHeader.LateCancellations': '遅いキャンセル',
    'MursionPortal.Table.ColumnHeader.Learners': '学習者',
    'Dashboard.ProjectsTable.ColumnHeader.SessionsRecorded': '記録されたセッション',
    'MursionPortal.Table.ColumnHeader.SessionsError': 'セッションエラー',
    'Dashboard.ProjectsTable.ColumnHeader.ContractedHours': '契約時間',
    'Dashboard.ProjectsTable.ColumnHeader.UtilizedHours': '利用時間',
    'Dashboard.ProjectsTable.ColumnHeader.RemainingHours': '残り時間',
    'Dashboard.ProjectsTable.ColumnHeader.CompletedHours': '完了済み時間',
    'Dashboard.ProjectsTable.ColumnHeader.MissedHours': '逃した時間',
    'Dashboard.ProjectsTable.HoverTitle.ViewEditProject': 'プロジェクトを表示/編集',
    'Dashboard.ProjectsTable.EmptyMessage.NoProjectsYet': 'プロジェクトはまだありません',
    'MursionPortal.Button.Clear': 'クリア',
    'Dashboard.Projects.AlertMessage.DataRequestNotImplemented': 'データリクエストはまだ実装されていません',
    'MursionPortal.SelectorMenu.SelectedCountOfTotal': '{formattedTotal}の選択された{formattedSelectedCount}',
    'MursionPortal.Dashboard.Table.Modal.Filter.Teams': 'チーム',
    'MursionPortal.Dashboard.Table.Modal.Placeholder.EnterTeamName': 'チーム名を入力',
    'MursionPortal.Dashboard.Table.Modal.TeamsEmptyMessage': 'チームが見つかりません',
    'MursionPortal.Dashboard.Table.Modal.Filter.NumberOfLearners': '学習者数',
    'MursionPortal.Dashboard.Table.Modal.Filter.Scenarios': 'シナリオ',
    'MursionPortal.Dashboard.Table.Modal.Placeholder.EnterScenarioName': 'シナリオ名を入力してください',
    'MursionPortal.Dashboard.Table.Modal.ScenariosEmptyMessage': 'シナリオは見つかりませんでした',
    'MursionPortal.Dashboard.Table.Modal.Filter.Name': 'お名前',
    'MursionPortal.Dashboard.Table.Modal.Filter.Status': '状況',
    'MursionPortal.Dashboard.Table.Modal.Filter.DeliveryMode': '配信モード',
    'MursionPortal.Dashboard.Table.Modal.Filter.Learners': '学習者',
    'MursionPortal.Dashboard.Table.Modal.Placeholder.EnterLearnerName': '学習者名を入力',
    'MursionPortal.Dashboard.Table.Modal.LearnersEmptyMessage': '学習者は見つかりませんでした',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsScheduled': 'スケジュールされたセッション',
    'MursionPortal.Dashboard.Table.Modal.SessionsEmptyMessage': 'セッションが見つかりませんでした。',
    'MursionPortal.Dashboard.Table.Modal.Filter.ScenarioName': 'シナリオ名',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionDateTime': 'セッション日時',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsCompleted': '完了したセッション',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsError': 'セッションエラー',
    'Dashboard.ProjectsActivityTable.Modal.Filter.SessionsRecorded': '記録されたセッション',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsMissed': '見逃したセッション',
    'MursionPortal.Dashboard.Table.Modal.Filter.LateCancellations': '「遅い」キャンセル',
    'MursionPortal.Dashboard.Table.Modal.Filter.CancelledBy': 'キャンセル者',
    'MursionPortal.Dashboard.Table.Modal.Filter.DateScheduled': '予定日付',
    'MursionPortal.Dashboard.Table.Modal.Filter.DateCancelled': 'キャンセル日付',
    'MursionPortal.ProjectStatus.Active': '有効',
    'MursionPortal.ProjectStatus.Completed': '完了',
    'MursionPortal.ProjectStatus.Archived': 'アーカイブ済み',
    'Settings.Avatars.Modal.Name': 'お名前',
    'Settings.Avatars.Modal.PlaceHolder.AvatarName': 'アバター名',
    'Settings.Avatars.Modal.Description': '記述',
    'MursionPortal.Name': 'お名前',
    'MursionPortal.Description': '記述',
    'MursionPortal.Environments': '環境',
    'Settings.Avatars.TableHover.ViewEditAvatar': 'アバターを表示/編集',
    'Settings.Avatars.NoAvatarText': 'アバターはまだありません',
    'Settings.Avatars.ModalHeader.NewAvatar': '新しいアバター',
    'Settings.Avatars.ModalHeader.Avatar': 'アバター',
    'Settings.Avatars.ModalHeader.EditAvatar': 'アバターを編集',
    'Settings.Avatars.Modal.Button.CreateAvatar': 'アバターを作成',
    'Settings.Avatars.Modal.Button.UpdateAvatar': 'アバターを更新',
    'Settings.Avatars.Modal.ArchiveAvatarText': 'このアバターをアーカイブしますか？',
    'Settings.Avatars.Modal.RestoreAvatarText': 'このアバターを復元しますか？',
    'Settings.Avatars.Modal.Button.Edit': '編集',
    'Settings.Avatars.Modal.EditButton.Accept': '受け入れる',
    'Settings.Avatars.Modal.NoSelectedEnvironments': '選択された環境はありません',
    'Settings.Avatars.Modal.NoEnvironmentsSetForSelectedIndustry': '選択した業種向けにに設定された環境はありません。',
    'Settings.Avatars.Button.CreateAvatar': 'アバターを作成',
    'MursionPortal.Dashboard': 'ダッシュボード',
    'MursionPortal.Tutorial': 'チュートリアル',
    'MursionPortal.Eula': 'ソフトウェア利用許諾契約',
    'MursionPortal.Companies': '会社',
    'MursionPortal.SessionFlow': 'セッションフロー',
    'MursionPortal.SessionReport': 'セッションレポート',
    'MursionPortal.AssetSync': 'アセット同期',
    'MursionPortal.Users': 'ユーザー',
    'MursionPortal.AppDownload': 'アプリのダウンロード',
    'MursionPortal.Calendar': 'カレンダー',
    'MursionPortal.Clients': 'クライアント',
    'MursionPortal.Sessions': 'セッション',
    'MursionPortal.Trainings': 'トレーニング',
    'MursionPortal.Requests': 'リクエスト',
    'MursionPortal.Settings': '設定',
    'MursionPortal.ArtDesigner': 'Art Designer',
    'MursionPortal.ArtDesigner.BodyContents': 'We\'ve opened a new browser tab with the Mursion Designer application.',
    'MursionPortal.Projects': 'プロジェクト',
    'MursionPortal.Teams': 'チーム',
    'MursionPortal.Reports': 'レポート',
    'MursionPortal.Downloads': 'ダウンロード',
    'MursionPortal.Upcoming': '予定されている',
    'MursionPortal.Auth': 'OAuth',
    'Settings.Avatars.Modal.ErrorMessage.ItemsNotAvailable': '選択できるアイテムは{max}以下です',
    'Settings.Avatars.Modal.ErrorMessage.ItemNotAvailable': '選択できるアイテムは{max}以下です',
    'MursionPortal.Industry': '業種',
    'Settings.Environment.TableHover.ViewEditEnvironment': '環境を表示/編集',
    'Settings.Environment.NoEnvironmentText': 'まだ環境はありません',
    'Projects.Button.CreateProject': 'プロジェクトを作成',
    'Projects.ListHeader.CreationDate': '作成日',
    'Projects.ListHeader.ProjectManagers': 'プロジェクトマネージャー',
    'MursionPortal.Capitalized.Active': '有効',
    'MursionPortal.Capitalized.Completed': '完了済み',
    'MursionPortal.Capitalized.Archived': 'アーカイブ済み',
    'MursionPortal.Capitalized.All': 'すべて',
    'Projects.ProjectCard.BackToClient': 'クライアントに戻る',
    'Projects.ProjectCard.NoProjectsFound': 'プロジェクトが見つかりません',
    'Projects.ProjectCard.PlaceHolder.EnterProjectName': 'プロジェクト名を入力',
    'Projects.ProjectCard.ProjectsList': 'プロジェクトリスト',
    'MursionPortal.Capitalized.Project': 'プロジェクト',
    'MursionPortal.Scenarios': 'シナリオ',
    'Projects.ScenarioList.ThereAreNoScenarios': 'まだシナリオはありません。',
    'Projects.ScenarioList.NoScenariosFound': 'シナリオが見つかりませんでした。',
    'Projects.ScenarioList.Button.Draft': '下書き',
    'MursionPortal.Finalized': '確定済み',
    'MursionPortal.Started': '開始済み',
    'MursionPortal.Finished': '終了済み',
    'MursionPortal.Archived': 'アーカイブ済み',
    'MursionPortal.EnterScenarioName': 'シナリオ名を入力',
    'Projects.AllProjects': 'すべてのプロジェクト',
    'MursionPortal.Label.LowerCase.Archived': 'アーカイブ済み',
    'MursionPortal.Label.Active': '有効',
    'MursionPortal.Label.Completed': '完了',
    'Projects.Tab.Basic': '基本',
    'MursionPortal.Heading.ProjectName': 'プロジェクト名',
    'MursionPortal.Label.Created': '作成されました',
    'MursionPortal.Label.Status': '状況',
    'MursionPortal.Label.Learners': '学習者',
    'MursionPortal.Label.LearnersCount': '{count}人の学習者',
    'Projects.Tab.NoLearners': '学習者なし',
    'MursionPortal.Label.SoftwareVersion': 'ソフトウェアバージョン',
    'Projects.ProjectInfo.Label.Non-Billable': '請求不可',
    'Projects.ProjectInfo.Label.Contracted': '契約済み',
    'Projects.ProjectInfo.Label.CancellationWindow': 'キャンセルウィンドウ',
    'MursionPortal.Header.Recording': '記録中',
    'Projects.ProjectInfo.Checkbox.AllowRecording': '記録を許可',
    'MursionPortal.Label.VideoRecording': 'ビデオ録画',
    'MursionPortal.Label.Allowed': '許可',
    'MursionPortal.Label.Disallowed': '許可されない',
    'MursionPortal.Label.RecordedVideosGoBackTo': '録画されたビデオは次の場所に戻ります：',
    'MursionPortal.Checkbox.RecordingPermissionLiesWithLearner': '録音許可は学習者が決めるものです',
    'MursionPortal.Label.LiesWithLearner': '学習者による',
    'Projects.ProjectInfo.Label.Enabled': '有効化',
    'Projects.ProjectInfo.Label.Disabled': '無効化',
    'MursionPortal.Heading.ProjectManagers': 'プロジェクトマネージャー',
    'MursionPortal.Heading.Tags': 'タグ',
    'MursionPortal.Button.AreYouSureEditProject': 'このプロジェクトを編集しますか？',
    'MursionPortal.Button.SaveProjectInfo': 'プロジェクト情報を保存',
    'Projects.Tab.Utilization': '稼働率',
    'MursionPortal.Label.Loading': '読み込み中',
    'Projects.ProjectInfo.Label.NoProjectManagers': 'プロジェクトマネージャーなし',
    'Projects.ProjectInfo.Label.NoSelectedProjectManagers': 'プロジェクトマネージャーが選択されていません',
    'MursionPortal.Label.ProjectManagers': 'プロジェクトマネージャー',
    'MursionPortal.Label.RecordingPermission': '録音許可：',
    'Projects.Utilization.Label.UtilizedSessions': '利用済みセッション：',
    'Projects.Utilization.Label.UtilizedMissedSessions': '利用されなかったセッション：',
    'Projects.Utilization.Label.UtilizedCancelledSessions': '利用済みのキャンセルされたセッション：',
    'Projects.Utilization.Label.UtilizedDuration': '利用期間：',
    'Projects.Utilization.Label.RemainingDuration': '残りの期間：',
    'MursionPortal.ScenarioListItem.Label.Timeline': 'タイムライン',
    'MursionPortal.ScenarioListItem.Label.SessionLength': 'セッションの長さ',
    'MursionPortal.ScenarioListItem.Label.SessionSize': 'セッションサイズ',
    'MursionPortal.ScenarioListItem.Label.DeliveryMode': '配信モード',
    'MursionPortal.ScenarioCard.BackToProject': 'プロジェクトに戻る',
    'MursionPortal.ScenarioCard.NoScenariosFound': 'シナリオが見つかりません',
    'MursionPortal.Placeholder.EnterScenarioName': 'シナリオ名を入力してください',
    'MursionPortal.ScenarioCard.Label.ScenariosList': 'シナリオリスト',
    'MursionPortal.ScenarioCard.Header.Scenario': 'シナリオ',
    'MursionPortal.ScenarioCard.Header.ChooseTypeOfEvent': 'スケジュールするイベントの種類を選択',
    'MursionPortal.Label.Session': 'セッション',
    'MursionPortal.Label.Training': 'トレーニング',
    'MursionPortal.Button.Confirm': '確認',
    'MursionPortal.Button.Continue': '続行',
    'MursionPortal.Button.Schedule': 'スケジュール',
    'MursionPortal.Label.Draft': '下書き',
    'MursionPortal.Label.Finalized': '確定済み',
    'MursionPortal.Label.Started': '開始済み',
    'MursionPortal.Label.Finished': '終了済み',
    'MursionPortal.Label.Archived': 'アーカイブ済み',
    'MursionPortal.Label.Cancel': 'キャンセル',
    'MursionPortal.Label.Edit': '編集',
    'MursionPortal.Label.CancelEdit': '編集をキャンセル',
    'MursionPortal.Label.EditScenario': 'シナリオを編集',
    'MursionPortal.Label.EditScenarioName': 'シナリオ名を編集',
    'MursionPortal.ScenarioCard.Label.ApplyNameChange': '名前の変更を適用',
    'MursionPortal.ScenarioCard.Label.Apply': '適用',
    'MursionPortal.ScenarioCard.Tab.Info': '情報',
    'MursionPortal.ScenarioCard.Tab.Learners': '学習者',
    'MursionPortal.ScenarioCard.Tab.Sessions': 'セッション',
    'MursionPortal.ScenarioCard.ConfirmButton.AreYouSureArchiveScenario': 'このシナリオをアーカイブしますか？関連するすべてのデータもアーカイブされます。',
    'MursionPortal.ScenarioCard.ConfirmButton.ArchiveScenario': 'シナリオをアーカイブ',
    'MursionPortal.Label.Delivery': '配信',
    'MursionPortal.ScenarioInfo.Header.ArchivedScenario': 'アーカイブ済みシナリオ',
    'MursionPortal.ScenarioInfo.Button.YouMayOnlyEditTeams': 'シナリオを完成させた場合にのみ、チームとタイムラインを編集できます',
    'MursionPortal.ScenarioInfo.Button.FinalizeScenario': 'シナリオを完成',
    'MursionPortal.ScenarioInfo.Button.SaveScenario': 'シナリオを保存',
    'MursionPortal.ScenarioInfo.Button.CreatePlanning': '計画を作成',
    'MursionPortal.ScenarioInfo.Label.SimLength': 'シムの長さ',
    'MursionPortal.Header.Outcome': '結果',
    'MursionPortal.Header.Strategies': '戦略',
    'MursionPortal.Header.Attachments': '添付ファイル',
    'MursionPortal.ScenarioInfo.Button.SaveScenarioInfo': 'シナリオ情報を保存',
    'MursionPortal.Label.ContentBundle': 'コンテンツバンドル',
    'MursionPortal.ScenarioInfo.Button.ShowLess': '少なく表示',
    'MursionPortal.ScenarioInfo.Button.ShowMore': 'もっと表示',
    'MursionPortal.AssetSettings.Label.CurrentScenarioNoAssetSettings': '現在のシナリオにはアセット設定がありません。',
    'MursionPortal.Teams.Label.NoSelectedTeams': '選択されたチームはありません',
    'MursionPortal.Teams.Label.ClientHasNoAssignedTeams': 'クライアントに割り当てられたチームがありません',
    'MursionPortal.Label.Teams': 'チーム',
    'MursionPortal.Label.NoTeamsSpecified': 'チームが指定されていません',
    'MursionPortal.Label.Capitalized.Draft': '下書き',
    'MursionPortal.Label.Capitalized.Finalized': '完成済み',
    'MursionPortal.Label.Capitalized.Started': '開始済み',
    'MursionPortal.Label.Capitalized.Finished': '終了済み',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioStillInDevelopmentStage': 'シナリオはまだ開発段階です',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioDeployedTimelineUpcoming': 'シナリオは展開されていますが、タイムラインはまだ近づいています',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioActiveLearnersCanSchedule': 'シナリオはアクティブであり、学習者はセッションをスケジュールできます',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioIsCompleted': 'シナリオが完了しました',
    'MursionPortal.Button.Add': '追加',
    'MursionPortal.Label.Warning': '警告',
    'MursionPortal.SessionWizard.Label.UnableToCreateSession': '選択した{failedParam}のセッションを作成できません。',
    'MursionPortal.SessionWizard.Label.PleaseContinueToFindAnother': '[続行]を押して、別の <code>{failedParam}</code>を見つけます。',
    'MursionPortal.SessionWizard.Label.ScheduleSession': 'セッションスケジュール',
    'MursionPortal.SessionWizard.Label.ScheduleTraining': 'トレーニングスケジュール',
    'MursionPortal.SessionWizard.Label.AddNotes': 'メモを追加',
    'MursionPortal.SessionWizard.Label.SelectSimulationContentForUse': '使用するシミュレーションコンテンツを選択',
    'MursionPortal.SessionWizard.Label.SelectStep': '{step}を選択',
    'MursionPortal.SessionWizard.Label.Expired': '期限切れ',
    'MursionPortal.SessionWizard.ConfirmButton.AreYouSureCreateEmergencySession': '緊急セッションを作成しますか？',
    'MursionPortal.SessionWizard.Button.Create': '作成',
    'MursionPortal.Button.Next': '次へ',
    'MursionPortal.SessionWizard.Label.RemainingTimeToBook': 'が、保留が切れる前に、この時間枠を予約するために残されています。',
    'MursionPortal.SessionWizard.Label.TimeBlockHoldExpired': 'タイムブロックホールドの期限が切れました。',
    'MursionPortal.Label.Date': '日付',
    'MursionPortal.Label.Time': '時間',
    'MursionPortal.SessionWizard.Label.TimeNoLongerAvailableClickSubmit': 'その時間はもう利用できません。別のオプションを選択しますか？戻って利用可能なオプションを選択するか、[送信]をクリックして既存のオプションをリクエストします。',
    'MursionPortal.SessionWizard.Label.TimeNoLongerAvailable': 'その時間はもう利用できません。別のオプションを選択しますか？戻って利用可能なオプションを選択します。',
    'MursionPortal.SessionWizard.Button.GoBack': '戻る',
    'MursionPortal.Button.Submit': '送信する',
    'MursionPortal.SessionWizard.Label.NoAvailableClients': '利用可能なクライアントがありません',
    'MursionPortal.SessionWizard.Label.NoAvailableProjects': '利用可能なプロジェクトがありません',
    'MursionPortal.SessionWizard.Label.NoAvailableScenarios': '利用可能なシナリオがありません',
    'MursionPortal.SessionWizard.Label.PleaseSelectScenario': '最初にシナリオを選択してください',
    'MursionPortal.SessionWizard.Label.NoImage': '画像なし',
    'MursionPortal.SessionWizard.EmptyMessage.NoAvailableSImSpecialists': '利用可能なシムスペシャリストがいない',
    'MursionPortal.SessionWizard.Label.ScenarioHasNoContentBundles': 'シナリオには、割り当てられたコンテンツバンドルがありません。',
    'MursionPortal.SessionWizard.Option.SelectAnAsset': 'アセットを選択',
    'MursionPortal.SimSpecialistStep.Label.NoSimSpecialistsAvailable': '選択した時間に利用できるシムスペシャリストがいません。セッションはリクエストに応じて割り当てられます。',
    'MursionPortal.SsLearnersStep.ErrorMessage.YouCanAddMaxTrainees': 'このタイプのセッションでは、最大{maxLearners}人の学習者まで追加できます。',
    'MursionPortal.SsLearnersStep.ErrorMessage.YouCanAddMaxTrainee': 'このタイプのセッションでは、最大{maxLearners}人の学習者まで追加できます。',
    'MursionPortal.EmptyMessage.NoUsersSelected': 'ユーザーが選択されていません',
    'MursionPortal.Placeholder.Search': '検索',
    'MursionPortal.SsLearnersStep.Label.NoTraineeToSelect': '選択する研修生がいない',
    'MursionPortal.LearnersStep.ErrorMessage.YouCanAddMaxLearners': 'このタイプのセッションでは、最大{maxLearners}人の学習者まで追加できます。',
    'MursionPortal.LearnersStep.ErrorMessage.YouCanAddMaxLearner': 'このタイプのセッションでは、最大{maxLearners}人の学習者まで追加できます。',
    'MursionPortal.LearnersStep.Label.NoLearnersToSelect': '選択できる学習者がいません',
    'MursionPortal.LearnersStep.Label.NoUsersSelected': 'ユーザーが選択されていません',
    'MursionPortal.LearnersStep.Option.AllTeams': 'すべてのチーム',
    'MursionPortal.LearnersStep.Button.AddEntireTeam': 'チーム全体を追加',
    'MursionPortal.LearnersStep.Note.AllowedSessionSIze': '許可されるセッションサイズは{maxLearners}人です。特定の学習者を選択する',
    'MursionPortal.LearnersStep.Checkbox.OnlyUnauthorizedUsers': '認証されていないユーザーのみが参加します',
    'MursionPortal.NotesStep.SessionNotes': 'セッションメモ',
    'MursionPortal.NotesStep.ErrorMessage.AllowedLimitExceeded': '許可されている文字数制限（{limit}文字）を超えています',
    'MursionPortal.Button.LoadMore': 'さらに読み込む',
    'MursionPortal.Label.Trainer': 'トレーナー',
    'MursionPortal.Label.Description': '記述',
    'MursionPortal.Label.Outcome': '結果',
    'MursionPortal.Label.Strategies': '戦略',
    'MursionPortal.Label.DeliveryMode': '配信モード',
    'MursionPortal.Label.SessionLength': 'セッションの長さ',
    'MursionPortal.Label.NoDescriptionProvided': '記述がありません',
    'MursionPortal.Label.NoOutcomeProvided': '結果は提供されていません',
    'MursionPortal.Label.NoStrategiesProvided': '戦略は提供されていません',
    'MursionPortal.Placeholder.Dotted.Search': '検索',
    'MursionPortal.TimeStep.Label.NoAvailableTimeBlocks': '利用可能な時間枠がありません',
    'MursionPortal.TimeStep.Label.NoAvailableTimeBlocksInRange': '選択した範囲内に利用可能な時間枠がありません',
    'MursionPortal.TimeStep.Footer.BySelectingThisTimeBlock': 'この時間枠を選択すると、現在利用できないセッション時間を要求することになります。このリクエストを満たすために最善を尽くします。または、下のトグル「オフ」をスライドして確認済みの利用可能な時間枠からのみ選択してください。',
    'MursionPortal.TimeStep.Tooltip.SlideTheToggleOff': 'トグルを「オフ」にスライドして、使用可能な時間枠からのみ選択します。',
    'MursionPortal.TimeStep.Tooltip.SlideTheToggleOn': 'トグルを「オン」にスライドして、追加の時間枠をリクエストします。',
    'MursionPortal.Label.LowerCase.Client': 'クライアント',
    'MursionPortal.Label.Project': 'プロジェクト',
    'MursionPortal.Label.Scenario': 'シナリオ',
    'MursionPortal.Projects.NoProjectsFound': 'プロジェクトが見つかりませんでした。',
    'MursionPortal.Projects.YourTeamsNotAssigned': 'あなたのチームはまだどのプロジェクトにも割り当てられていません。',
    'MursionPortal.Projects.NoProjectsCreated': 'プロジェクトはまだ作成されていません。',
    'MursionPortal.Teams.NoTeamsFound': 'チームが見つかりません。',
    'MursionPortal.Teams.YouHaveNotBeen': 'あなたはまだどのチームにも追加されていません。',
    'MursionPortal.Teams.YouHaveNoTeams': 'まだチームを持ちません。',
    'MursionPortal.Teams.NoTeamsCreated': 'チームはまだ作成されていません。',
    'Settings.SSO.Modal.ConfigurationName': '構成名',
    'Settings.SSO.Modal.AuthorizationEndpoint': '承認エンドポイント',
    'Settings.SSO.Modal.LogoutEndpoint': 'ログアウトエンドポイント',
    'Settings.SSO.Modal.ClientID': 'クライアントID',
    'Settings.SSO.Modal.ClientSecret': 'クライアントシークレット',
    'Settings.SSO.Modal.Scope': '対象',
    'Settings.SSO.Modal.UserInfoEndpoint': 'ユーザー情報エンドポイント',
    'Settings.SSO.Modal.TokenEndpoint': 'トークンエンドポイント',
    'Settings.SSO.Modal.TokenHTTPMethod': 'トークンHTTPメソッド',
    'Settings.SSO.Modal.UserIDClaimName': 'ユーザーIDクレーム名',
    'Settings.SSO.Modal.EmailClaimName': 'メールクレーム名',
    'Settings.SSO.Modal.FirstNameClaimName': '下の名前クレーム名',
    'Settings.SSO.Modal.LastNameClaimName': '姓クレーム名',
    'Settings.SSO.Modal.LinkAllowed': 'リンクを許可',
    'Settings.SSO.Modal.UnlinkAllowed': 'リンク解除を許可',
    'Settings.SSO.Modal.SingleLogoutEnabled': 'シングルログアウトが有効',
    'Settings.SSO.Modal.SingleSignOnServiceEndpoint': 'シングルサインオンサービスエンドポイント',
    'Settings.SSO.Modal.EntityID': 'エンティティID',
    'Settings.SSO.Modal.X509Certificate': 'X.509 証明書',
    'Settings.SSO.Button.CreateSsoConfiguration': 'SSO構成を作成',
    'Settings.SSO.Modal.Protocol': 'プロトコル ：',
    'Settings.SSO.Modal.Type': 'タイプ ：',
    'Settings.SSO.Modal.ID': 'ID ：',
    'Settings.SSO.Modal.AssignedClients': '割り当てられたクライアント',
    'Settings.SSO.Modal.Button.DeleteSso': 'SSOを削除',
    'Settings.SSO.ModalHeader.EditSsoConfiguration': 'SSO構成を編集',
    'Settings.SSO.ModalHeader.NewSsoConfiguration': '新しいSSO構成',
    'Settings.SSO.Modal.Button.CreateSsoConfiguration': 'SSO構成を作成',
    'Settings.SSO.Modal.Button.SaveSsoConfiguration': 'SSO構成を保存',
    'Settings.SSO.Modal.DeletionConfirmationText': 'SSO構成を削除しますか？',
    'Settings.SSO.Modal.AddButton.GroupClaimName': 'グループクレーム名',
    'Settings.SSO.Modal.AddButton.Team': 'チーム',
    'Settings.SSO.Modal.AddButton.PlaceHolder.GroupClaimValue': 'グループクレーム名',
    'Settings.SSO.Modal.SPInitiatedSsoUrl': 'SPが開始したSSO URL ：',
    'Settings.SSO.Modal.Button.DownloadSPMetadata': 'SPメタデータをダウンロード',
    'Settings.SSO.Modal.Button.Public': '公開',
    'Settings.SSO.Modal.Button.Private': 'プライベート',
    'Settings.SSO.Modal.ConfigurationIcon': '構成アイコン ：',
    'Settings.SSO.Modal.PlaceHolder.EnterTeamClaimName': 'チームクレーム名を入力',
    'Settings.SSO.Modal.TeamClaimName': 'チームクレーム名',
    'Settings.SSO.Modal.TeamMapping': 'チームマッピング ：',
    'Settings.SSO.Modal.Button.Change': '変更',
    'MursionPortal.PlaceHolder.SelectAClient': 'クライアントを選択',
    'Settings.SSO.Modal.AddButton.PlaceHolder.SelectATeam': 'チームを選択',
    'MursionPortal.Label.Client': 'クライアント',
    'Settings.SSO.Modal.ConfirmDelete': '削除の確認',
    'Settings.SSO.Table.ColumnHeader.ConfigurationName': '構成名',
    'Settings.SSO.Table.ColumnHeader.Type': '種類',
    'Settings.SSO.Table.ColumnHeader.Protocol': 'プロトコル',
    'Settings.SSO.Table.ColumnHeader.ClientsCount': 'クライアント数',
    'Settings.SSO.PlaceHolder.EnterSsoName': 'SSO名を入力',
    'Settings.SSO.Table.NoSsoSchemasFound': 'SSOスキーマが見つかりませんでした。',
    'Settings.SSO.Table.HoverTitle.ViewEditSso': 'SSOの表示/編集',
    'Settings.SSO.Modal.HoverTitle.CopySPInitiatedSsoUrl': 'SPが開始したSSO URLをコピー',
    'Settings.SSO.Modal.CopyButton.ModalHeader.SPInitiatedSsoUrl': 'SPが開始したSSO URL',
    'MursionPortal.CopyText.CopiedToClipboard': 'がクリップボードにコピーされました。',
    'Settings.SSO.Text.Public': '公開',
    'Settings.SSO.Text.Private': 'プライベート',
    'Settings.SSO.Modal.NoClientsAssigned': 'クライアントが割り当てられていません',
    'MursionPortal.Label.NotAvailable': '該当なし',
    'Teams.Modal.Header.NewTeam': '新しいチーム',
    'MursionPortal.Button.More': 'もっと',
    'Teams.Modal.Header.AllTeams': 'すべてのチーム',
    'MursionPortal.Label.Name': '名前：',
    'MursionPortal.Label.Location': '所在地：',
    'MursionPortal.Label.Tags': 'タグ：',
    'MursionPortal.Header.Facilitators': 'ファシリテーター：',
    'MursionPortal.Header.Scenarios': 'シナリオ：',
    'MursionPortal.Button.SaveTeamInfo': 'チーム情報を保存',
    'MursionPortal.IconButton.Cancel': 'キャンセル',
    'MursionPortal.IconButton.Edit': '編集',
    'MursionPortal.ButtonTitle.CancelEdit': '編集をキャンセル',
    'MursionPortal.ButtonTitle.EditTeam': 'チームを編集',
    'MursionPortal.ConfirmationText.RestoreTeam': 'このチームを復元しますか？',
    'MursionPortal.ButtonTitle.RestoreTeam': 'チームを復元',
    'MursionPortal.ConfirmationText.ArchiveTeam': 'このチームをアーカイブしますか？',
    'MursionPortal.ButtonTitle.ArchiveTeam': 'チームをアーカイブ',
    'MursionPortal.EmptyMessage.NoFacilitators': 'ファシリテーターなし',
    'MursionPortal.EmptyMessage.NoSelectedFacilitators': 'ファシリテーターが選択されていません',
    'MursionPortal.Title.Facilitators': 'ファシリテーター',
    'MursionPortal.Heading.Archived': '（アーカイブ済み）',
    'Teams.Modal.Header.AllLearners': 'すべての学習者',
    'Teams.Modal.Placeholder.SearchLearners': '学習者を検索',
    'Teams.Modal.EmptyMessage.NoLearnersToSelect': '選択できる学習者がいません',
    'Teams.Modal.Header.TeamLearners': 'チームの学習者',
    'Teams.Modal.Placeholder.SearchTeamLearners': 'チームの学習者を検索',
    'Teams.Modal.EmptyMessage.NoTeamLearnersToSelect': '選択できるチームの学習者がいません',
    'Teams.Modal.Header.AssignLearners': '学習者をチーム「{teamName}」に割り当てる',
    'Teams.AssignLearners.Modal.Button.SelectAll': 'すべてを選択',
    'Teams.AssignLearners.Modal.EmptyMessage.NoItems': '選択するアイテムがありません',
    'MursionPortal.Header.Teams': 'チーム',
    'Teams.Modal.AlertMessage.Timezone': 'タイムゾーンが変更された場合は、既存のイベントと可用性を確認してください。',
    'Teams.SelectorMenu.SelectedOf': '{totalNumber}の選択された{selected}',
    'Teams.Modal.AlertMessage.ProfileTimezone': '現在のプロファイルのタイムゾーンの選択が、コンピューターの日付と時刻の設定（{updatedTimezone}）と一致しません。[変更を保存]をクリックして続行するか、別のプロファイルタイムゾーンオプションを選択してください。',
    'MursionPortal.Label.Learner': '学習者',
    'Teams.Modal.Button.More': 'さらに{overlappedAmount}',
    'MursionPortal.IconButton.Archive': 'アーカイブ',
    'Teams.ToggleButton.Learners': '学習者',
    'Teams.ToggleButton.Sessions': 'セッション',
    'MursionPortal.Status.RegistrationStatus': '登録状況',
    'MursionPortal.Status.RegistrationAccepted': '登録が受け付けられました',
    'MursionPortal.Status.RegistrationNotAccepted': '登録は受け付けられていません',
    'MursionPortal.SortBy': '次で並べ替え',
    'MursionPortal.Placeholder.SortBy': '並び替え',
    'Clients.Button.CreateClient': 'クライアントを作成',
    'Clients.CreateClient.Header.NewClient': '新規クライアント',
    'Clients.CreateClient.Button.CreateClient': 'クライアントを作成',
    'Clients.CreateClient.Label.ClientName': 'クライアント名',
    'Clients.CreateClient.Placeholder.CityCountry': '市、都道府県、国',
    'MursionPortal.Timezone': 'タイムゾーン',
    'Clients.CreateClient.Label.Logo': 'ロゴ',
    'Clients.Text.NoClientsFound': 'クライアントが見つかりませんでした。',
    'MursionPortal.Text.Active': '有効',
    'MursionPortal.Text.Archived': 'アーカイブ済み',
    'Clients.Modal.Button.BackToClientList': 'クライアントリストに戻る',
    'Clients.Modal.EmptyMessage.NoClientsFound': 'クライアントが見つかりません',
    'Clients.Modal.Placeholder.EnterClientName': 'クライアント名を入力',
    'Clients.Modal.Header.ClientsList': 'クライアントリスト',
    'Clients.Modal.Header.Client': 'クライアント',
    'Clients.Modal.Header.AllClients': 'すべてのクライアント',
    'MursionPortal.Search': '検索',
    'Clients.Modal.RestoreConfirmText': 'このクライアントを復元しますか？',
    'Clients.Modal.DeleteConfirmText': 'このクライアントをアーカイブしますか？',
    'Clients.Modal.RelatedDataText': '関連するすべてのデータもアーカイブされます。',
    'Clients.Modal.DeleteErrorText': '現在のクライアントにはシナリオがあります。このクライアントをアーカイブする前に、プロジェクトシナリオをアーカイブしてください。',
    'Clients.Button.RestoreClient': 'クライアントを復元',
    'Clients.IconButton.Cancel': 'キャンセル',
    'Clients.IconButton.Edit': '編集',
    'Clients.IconButton.CancelEdit': '編集をキャンセル',
    'Cleints.IconButton.EditScenarioName': 'シナリオ名を編集',
    'Cleints.IconButton.ApplyNameChange': '名前の変更を適用',
    'Clients.IconButton.Apply': '適用',
    'Clients.IconButton.EditClient': 'クライアントを編集',
    'Clients.Button.ArchiveClient': 'クライアントをアーカイブ',
    'Clients.Modal.Heading.Archived': '（アーカイブ済み）',
    'Clients.Modal.Label.Name': '名前：',
    'Clients.Modal.Placeholder.ClientName': 'クライアント名',
    'Clients.Modal.Label.Location': '所在地：',
    'Clients.Modal.Label.Industry': '業種：',
    'Clients.Modal.Placeholder.SelectIndustry': '業種を選択する',
    'Clients.Modal.Label.Timezone': 'タイムゾーン：',
    'Clients.Modal.Label.SchedulingByRequest': 'リクエストによるスケジュール：',
    'MursionPortal.Enabled': '有効化',
    'MursionPortal.Disabled': '無効化',
    'Clients.Modal.Label.SchedulingWindow': 'スケジュールウィンドウ：',
    'Clients.Modal.Label.HowFarInAdvance': 'クライアントはどのくらい前にスケジュールする必要がありますか',
    'MursionPortal.Days': '日',
    'MursionPortal.Day': '日',
    'Clients.Modal.Label.AvailableSharedSSOTitle': 'サインインに使用できる利用可能な共有SSOのリスト',
    'Clients.Modal.Label.AvailableSharedSSOLabel': '利用可能な共有SSO:',
    'Clients.Modal.EmptySelectedMessage': 'SSOが選択されていません',
    'Clients.Modal.EmptyListMessage': '利用可能なSSOはありません',
    'Clients.Modal.SSOTitle': '利用可能なSSOを選択',
    'Cleints.Modal.Label.AvailablePrivateSSOTitle': 'サインインに使用できる利用可能な共有SSOのリスト',
    'Clients.Modal.Label.AccountOwners': 'アカウント所有者：',
    'Clients.Modal.AccountOwner.EmptyListMessage': 'アカウント所有者なし',
    'Clients.Modal.AccountOwner.EmptySelectedMessage': '選択されたアカウント所有者は存在しません',
    'Clients.Modal.AccountOwnersTitle': 'アカウント所有者',
    'MursionPortal.Message.Updating': '更新中',
    'MursionPortal.Button.SaveClientInfo': 'クライアント情報を保存',
    'MursionPortal.Add': '追加',
    'Clients.Modal.Label.EditSSO': 'SSOを編集',
    'Clients.Modal.Label.Edit': '編集',
    'MursionPortal.NumberOfFacilitators': 'ファシリテーターの数',
    'MursionPortal.NumberOfLearners': '学習者の人数',
    'Clients.Modal.Button.CreateTeam': 'チームを作成',
    'MursionPortal.NewTeam': '新しいチーム',
    'MursionPortal.NoTags': 'タグがありません',
    'MursionPortal.Header.Tags': 'タグ',
    'MursionPortal.AddTag': 'タグを追加',
    'Clients.Modal.Teams.FacilitatorsEmptyListMessage': 'ファシリテーターが選択されていません',
    'Clients.Modal.Teams.LearnersEmptyListMessage': '学習者が選択されていません',
    'MursionPortal.Learners': '学習者',
    'MursionPortal.Facilitator': 'ファシリテーター',
    'MursionPortal.CreateTeam': 'チームを作成',
    'Clients.Modal.Teams.ConfirmationText.RestoreTeam': 'このチームを復元しますか？',
    'Clients.Modal.Teams.ButtonTitle.RestoreTeam': 'チームを復元',
    'Clients.Modal.Teams.ConfirmationText.ArchiveTeam': 'このチームをアーカイブしますか？',
    'Clients.Modal.Teams.ButtonTitle.ArchiveTeam': 'チームをアーカイブ',
    'Clients.Modal.Projects.Header.NewProject': '新規プロジェクト',
    'MursionPortal.ProjectName': 'プロジェクト名',
    'MursionPortal.Contracted': '契約済み',
    'MursionPortal.CancellationWindow': 'キャンセルウィンドウ',
    'MursionPortal.SoftwareVersion': 'ソフトウェアバージョン',
    'MursionPortal.Billing': '請求',
    'Clients.Modal.Projects.Checkbox.NonBillable': '請求不可',
    'MursionPortal.Recording': '記録中',
    'Clients.Modal.Projects.Checkbox.AllowRecording': '記録を許可',
    'Clients.Modal.Projects.Label.RecordedVideos': '録画されたビデオは次の場所に戻ります：',
    'Clients.Modal.Projects.Checkbox.RecordingPermission': '録音許可は学習者が決めるものです',
    'Clients.Modal.Projects.ButtonTitle.SaveProjectInfo': 'プロジェクト情報を保存',
    'Clients.Modal.Projects.Header.ProjectManager': 'プロジェクトマネージャー',
    'Clients.Modal.Projects.ProjectsEmptyListMessage': 'プロジェクトマネージャーが選択されていません',
    'MursionPortal.Header.Bracketed.Scenarios': 'シナリオ',
    'Clients.Modal.Projects.Text.CompleteProjectInfoFirst': '最初にプロジェクト情報を完成させる',
    'MursionPortal.Header.CreateScenario': 'シナリオを作成',
    'Clients.Modal.Projects.ConfirmationText.RestoreProject': 'このプロジェクトを復元しますか？',
    'Clients.Modal.Projects.ButtonTitle.RestoreProject': 'プロジェクトを復元',
    'Clients.Modal.Projects.ButtonTitle.Edit': 'プロジェクトを編集',
    'Clients.Modal.Projects.ButtonTitle.CancelEdit': '編集をキャンセル',
    'Clients.Modal.Label.Cancel': 'キャンセル',
    'Clients.Modal.Projects.ConfirmationText.ArchiveProject': 'このプロジェクトをアーカイブしますか？',
    'Clients.Modal.Projects.ButtonTitle.ArchiveProject': 'プロジェクトをアーカイブ',
    'Clients.Modal.Projects.ArchiveErrorMessage': '現在のプロジェクトにはアクティブなシナリオが存在します。このプロジェクトをアーカイブする前に、プロジェクトシナリオをアーカイブしてください。',
    'Clients.Modal.Projects.ButtonTitle.EditTags': 'タグを編集',
    'Clients.Modal.Projects.Label.UtilizedMissedSessions': '利用されなかったセッション,',
    'Clients.Modal.Projects.Button.CreateNew': '新規作成',
    'Clients.Modal.Projects.Button.RepeatClient': 'クライアントを繰り返す',
    'Clients.Modal.Projects.Button.RepeatBank': 'バンクを繰り返す',
    'Clients.Modal.Projects.ButtonTitle.CreateNewScenario': '新しいシナリオを作成',
    'Clients.Modal.Projects.ButtonTitle.RepeatClientScenario': 'クライアントシナリオを繰り返す',
    'Clients.Modal.Projects.ButtonTitle.ScenarioBank': 'シナリオバンクからシナリオを作成する',
    'Clients.Modal.Button.Schedule': 'スケジュール',
    'MursionPortal.Button.ActivateScenario': 'シナリオを有効化',
    'MursionPortal.Button.ChangeScenario': 'シナリオを変更',
    'Clients.IconButton.Confirm': '確認',
    'Clients.IconButton.ConfirmEdit': '編集を確認',
    'Clients.Modal.Projects.HeaderText.ChooseScenario': '繰り返すシナリオを選択',
    'Clients.Modal.Projects.HeaderText.ChooseScenarioBank': 'シナリオバンクからシナリオを選択',
    'MursionPortal.Placeholder.LowerCase.EnterScenarioName': 'シナリオ名を入力',
    'MursionPortal.IconButton.ResetToDefault': '既定値に戻す',
    'MursionPortal.IconButton.Reset': 'リセット',
    'Clients.Modal.Header.CreatePlanning': '新しいシナリオの計画を作成',
    'Clients.Modal.EmptyMessage.NoClientScenariosFound': 'クライアントシナリオが見つかりませんでした。',
    'Clients.Modal.EmptyMessage.NoBankScenariosFound': 'バンクシナリオが見つかりませんでした。',
    'Clients.Modal.Message.NoTeamsSpecified': 'チームが指定されていません',
    'Clients.Modal.EmptyMessage.NoSelectedTeams': '選択されたチームはありません',
    'Clients.Modal.Message.NoAssignedTeams': 'クライアントに割り当てられたチームがありません',
    'Clients.Modal.Label.Certificate': '証明書',
    'Clients.Modal.Label.DeliveryMode': '配信モード',
    'Clients.Modal.Label.SessionSize': 'セッションサイズ',
    'Clients.Modal.Label.SessionLength': 'セッションの長さ',
    'Clients.Modal.Label.Outcome': '結果',
    'Clients.Modal.Label.Strategies': '戦略',
    'Clients.Modal.Label.Description': '記述',
    'MursionPortal.Button.MoreInfo': '詳細',
    'MursionPortal.Button.HideAdditionalInfo': '追加情報を非表示にする',
    'MursionPortal.Header.Timeline': 'タイムライン',
    'MursionPortal.Header.Projects': 'プロジェクト ',
    'MursionPortal.Header.Sessions': 'セッション',
    'MursionPortal.Header.Users': 'ユーザー',
    'MursionPortal.Text.Yes': 'はい',
    'MursionPortal.Text.No': 'いいえ',
    'MursionPortal.Button.Google': 'Google',
    'MursionPortal.Button.Outlook': 'Outlook、iCal、その他',
    'MursionPortal.Heading.GoogleCalendar': 'Google カレンダー ',
    'MursionPortal.Heading.AddToCalendar': 'カレンダーに追加 ',
    'Dashboard.Legacy.GoogleCalendarList.Authorized': 'まだ行っていない場合は、<code>{companyName}ポータル</code>がカレンダーにイベントを追加することを<code>承認</code>するよう求められます。',
    'Dashboard.Legacy.GoogleCalendarList.EventAdd': 'イベントは<code>カレンダーに追加され</code>、{companyName}ポータルから引き続きアクセスできます。',
    'MursionPortal.Heading.OtherCalendars': 'その他のカレンダー',
    'Dashboard.Legacy.OtherCalendarList.Downloaded': 'イベントは <code>{companyName}</code>として<code>ダウンロード</code>されます',
    'Dashboard.Legacy.OtherCalendarList.SelectCalendar': '<code>右クリック</code>して<code>[プログラムから開く]</code>を選択し、カレンダーアプリケーションを選択します。',
    'Dashboard.Legacy.OtherCalendarList.ImportWithinCalendar': '（カレンダー内で[インポート]を選択して、ダウンロードしたファイルを見つけることもできます）',
    'Dashboard.Legacy.OtherCalendarList.AddedToCalendar': 'イベントは<code>カレンダーに追加され</code>、{companyName}ポータルから引き続きアクセスできます',
    'MursionPortal.Text.NoLearnersAddedYet': '学習者がまだ追加されていません',
    'MursionPortal.Heading.Schedule': 'スケジュール',
    'MursionPortal.Heading.Users': 'ユーザー',
    'MursionPortal.Heading.ProfServices': '専門サービス',
    'MursionPortal.Heading.SimSpecialist': 'シムスペシャリスト',
    'MursionPortal.Heading.Clients': 'クライアント',
    'MursionPortal.Heading.Sessions': 'セッション',
    'MursionPortal.Heading.Projects': 'プロジェクト',
    'MursionPortal.Heading.Teams': 'チーム',
    'MyMessages.Button.ArchiveAll': 'すべてをアーカイブ',
    'MyMessages.Button.ArchivedMessages': '保管メッセージ',
    'MyMessages.Button.ShowMore': 'もっと表示',
    'MyMessages.Label.NewMessages': '<code>{newMessagesCount}</code>の新しいメッセージ',
    'MyMessages.Label.NewMessage': '<code>{newMessagesCount}</code>の新しいメッセージ',
    'MyMessages.HoverTitle.InboxButton': '受信トレイボタン',
    'MyMessages.Text.InboxButton': '受信トレイボタン',
    'MyMessages.Text.HaveNewMessages': '{total}の新しいメッセージがあります',
    'MyMessages.Text.HaveNewMessage': '新しいメッセージが届きました',
    'MursionPortal.CookiePolicy.Header.CookiePolicy': 'Cookieポリシー',
    'MursionPortal.CookiePolicy.Text.MursionUsesEssentialCookiesOnly': 'Mursionは、お客様のセキュリティと当社のWebアプリケーションの動作に必要な必須のCookieのみを使用します。Cookieを使用して、個人データやIPアドレスやWebサイトの使用状況などの他の情報を収集することはありません。',
    'MursionPortal.CookiePolicy.Text.ForMoreInformation': '詳細については、<code>プライバシーポリシー</code>をご覧ください。',
    'MursionPortal.Button.Ok': '了解',
    'MyProfile.Button.SaveChanges': '変更を保存',
    'MyProfile.Label.ExternalProfiles': '外部プロファイル',
    'MyProfile.Label.UserRoles': 'ユーザーの役割',
    'MyProfile.Label.UserRole': 'ユーザの役割',
    'MyProfile.Heading.ReviewAccountInformation': 'アカウント情報を確認してください',
    'MyProfile.HoverTitle.UnlinkProfile': '{linkName}プロファイルをリンク解除する',
    'MyProfile.HoverTitle.LinkProfile': '{linkName}プロファイルをリンクする',
    'MyProfile.Button.Unlink': 'リンク解除',
    'MyProfile.Text.UnlinkConfirmationText': '{name}プロファイルのリンクを解除しますか？{name}からもサインアウトされることにご注意ください。',
    'MyProfile.Title.ProfileUnlink': '{name}プロファイルをリンク解除',
    'MyProfile.Text.SuccessfullyUnlinkedProfile': '{name}プロファイルのリンクが正常に解除されました',
    'MyProfile.Text.TimeZoneIsSetAutomatically': 'タイムゾーンは自動的に{getTimeZone}に設定されます。[確認]をクリックしてこの選択を受け入れるか、[キャンセル]をクリックして別の選択を行ってください。',
    'MyProfile.Text.TimeZoneChange': 'タイムゾーンが変更された場合は、既存のイベントと可用性を確認してください。',
    'MyProfile.Text.ProfileTimeZoneNotMatchComputer': '現在のプロファイルのタイムゾーンの選択が、コンピューターの日付と時刻の設定（{timeZone}）と一致しません。[変更を保存]をクリックして続行するか、別のプロファイルタイムゾーンオプションを選択してください。',
    'MursionPortal.Button.MyProfile': 'マイプロファイル',
    'MursionPortal.Button.LogOut': 'ログアウト',
    'MyProfile.Label.Language': '言語',
    'MursionPortal.Label.Roles': '役割',
    'MursionPortal.Label.Role': '役割',
    'MursionPortal.Projects.Header.ScenarioType': 'シナリオタイプ',
    'MursionPortal.Projects.Header.Client': 'クライアント',
    'MursionPortal.Projects.Header.Project': 'プロジェクト',
    'MursionPortal.Projects.Header.Scenario': 'シナリオ',
    'MursionPortal.Projects.Header.SimulationContent': 'シミュレーションコンテンツ',
    'MursionPortal.Projects.Header.Date': '日付',
    'MursionPortal.Projects.Header.Time': '時間',
    'MursionPortal.Projects.Header.Notes': '備考',
    'Filters.Date.Week': '週',
    'Users.Table.Modal.SignLanguageInterpreter': '手話通訳士',
    'Calendar.Button.Schedule': '予定',
    'Calendar.Button.Today': '今日',
    'MursionPortal.Availability': '利用可能性',
    'Calendar.Button.Schedule.QualityAssurance': '品質保証',
    'MursionPortal.Training': '研修',
    'Calendar.Button.Schedule.EmergencySession': '緊急会議',
    'Calendar.Button.Schedule.Session': 'セッション',
    'Calendar.Button.Schedule.BreakPrep': '休憩 / 準備',
    'MursionPortal.Month': '月',
    'MursionPortal.Week': '週',
    'Calendar.Heading.Calendars': 'カレンダー',
    'Calendar.Tip.SelectACalendar': 'カレンダーを選択して利用可能性を表示',
    'Calendar.ScheduledHours.SumOfAllScheduledSessions': '現在のビューで予定されているすべてのセッションの合計',
    'MursionPortal.ShortLabel.Hours': '時間',
    'Calendar.ScheduledHours.SumOfAllTrainingSessions': '現在のビューで予定されているすべての研修会の合計',
    'Calendar.ScheduledHours.Trainings': '研修',
    'Calendar.ScheduledHours.SumOfRemainingAvailabilityHours': '現在のビューの残りのすべてのSS利用可能時間の合計',
    'Calendar.ScheduledHours.Available': '利用可能',
    'MursionPortal.Scheduled': '予定済み',
    'Calendar.Label.Display': '表示',
    'MursionPortal.Client': 'クライアント',
    'Calendar.Filter.Company': '会社',
    'Calendar.Filter.SwapRequests': '交換リクエスト',
    'MursionPortal.QA': 'QA',
    'Calendar.SearchFilter.FilterProjects': 'プロジェクトをフィルター',
    'Calendar.SearchFilter.FilterClients': 'クライアントをフィルター',
    'Calendar.SearchFilter.FilterSimSpecialists': 'SIMスペシャリストをフィルター',
    'Calendar.IndustrySelector.Options.SelectAnIndustry': '業種を選択',
    'Calendar.IndustrySelector.Options.AllIndustries': '全業種',
    'Calendar.IndustrySelector.Options.NoIndustriesToSelect': '選択する業種がありません',
    'MursionPortal.SelectAClient': '証明書を選択',
    'Calendar.ClientSelector.Options.AllClients': 'すべてのクライアント',
    'Calendar.ClientSelector.Options.NoClientToSelect': '選択するクライアントがありません',
    'MursionPortal.SelectACertificate': '証明書を選択',
    'Calendar.CertificateSelector.Options.AllCertificates': 'すべての修了証',
    'Calendar.CertificateSelector.Options.NoCertificatesToSelect': '選択する証明書がありません',
    'Calendar.ClientsList.NoClientsFound': 'クライアントが見つかりませんでした。',
    'MursionPortal.UncheckAll': 'すべてのチェックを外す',
    'MursionPortal.CheckAll': 'すべてチェックする',
    'Calendar.SimSpecialistList.NoSimSpecialistsFound': 'SIMスペシャリストが見つかりませんでした。',
    'Calendar.ProjectsList.CloseProjectOverview': 'プロジェクトの概要を閉じる ',
    'Calendar.ProjectsList.AllProjects': 'すべてのプロジェクト',
    'MursionPortal.Bracketed.Scenarios': 'シナリオ',
    'Calendar.ProjectsList.Overview': '概要',
    'Calendar.ProjectsList.CloseClientOverview': 'クライアントの概要を閉じる',
    'MursionPortal.Scenario': 'シナリオ',
    'Calendar.ScenariosList.NoScenarios': 'シナリオがありません',
    'MursionPortal.Delivery': '配信',
    'MursionPortal.Mode': 'モード',
    'MursionPortal.Timeline': 'タイムライン',
    'Calendar.ScenariosList.Description': '記述',
    'MursionPortal.Outcome': '結果',
    'MursionPortal.Strategies': '戦略',
    'MursionPortal.ContentBundle': 'コンテンツバンドル',
    'MursionPortal.SimulationEnvironments': 'シミュレーション環境',
    'MursionPortal.AvatarsInSimulations': 'シミュレーションのアバター',
    'Calendar.LearnersList.SessionNotScheduled': 'セッションは予定されていません',
    'Calendar.LearnersList.SessionScheduled': '予定されているセッション',
    'Calendar.LearnersList.SessionComplete': 'セッション完了',
    'Calendar.LearnersList.Learner': '{learnersCount} 学習者',
    'Calendar.LearnersList.Learners': '{learnersCount} 学習者の',
    'Calendar.Button.CantSchedule': '予定が組めません',
    'Calendar.Button.ScheduleEvent': 'イベントの予定',
    'Calendar.MainPanel.ConfirmText.AreYouSure': '本当にこのイベントをキャンセルしますか？',
    'Calendar.MainPanel.CancelEvent': 'イベントをキャンセル',
    'Calendar.MainPanel.UpdateEvent': 'イベントを更新',
    'MursionPortal.Submit': '送信する',
    'Calendar.MainPanel.NewEvent': '新しいイベント',
    'MursionPortal.Project': 'プロジェクト',
    'MursionPortal.Button.Reload': '再読み込み',
    'Clients.Modal.Label.FacilitatorVisibility': 'ファシリテータービュー:',
    'Clients.Modal.Placeholder.FacilitatorVisibility': 'ファシリテータービューを選択',
    'Clients.Modal.Projects.ConfirmationText.EditProject': '本当にこのプロジェクトを編集しますか？',
    'Projects.ScenarioList.Legend.ScenariosStatusTab': 'シナリオステータスタブ',
    'Projects.ScenarioList.Legend.CreateNew': '新規、リピートクライアント、リピート銀行シナリオボタンの作成',
    'MursionPortal.ScenarioCard.Tab.Legend.ViewModeTab': 'モードタブを表示',
    'MursionPortal.SearchPlaceHolder.SearchClient': 'クライアントを検索',
    'Settings.SSO.Modal.AddButton.SearchPlaceHolder.SearchTeam': 'チームを選択',
    'Settings.SSO.Modal.Button.ChangeLabel': '{label}\'の変更',
    'MursionPortal.Label.Yes': 'はい',
    'MursionPortal.Label.No': 'いいえ',
    'Settings.SSO.Modal.AddButton.PlaceHolder.NoOptions': 'オプションなし',
    'Settings.SSO.Modal.ClientName': 'クライアント名',
    'MursionPortal.Modal.Button.Add': '追加',
    'MursionPortal.Modal.Button.Remove': '削除',
    'Users.Table.Hover.ViewEditAcceptedUsers': '承認されたユーザーの表示/編集',
    'Users.Table.Hover.ViewEditUnacceptedUsers': '承認されていないユーザーの表示/編集',
    'MursionPortal.Label.Industry': '業種',
    'MursionPortal.Label.Timezone': 'タイムゾーン',
    'MursionPortal.Header.Client': 'クライアント',
    'MursionPortal.FilterByCertificate': '証明書でフィルター',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Header.CreateQAEvent': 'QAイベントの作成',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.TimeBlock': '時間枠',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.Participants': '参加者',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Button.SubmitNewEvent': '新しいイベントを送信する',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Error.TimeBlockOutsideRange': '時間枠が利用可能な範囲外です',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Error.TimeBlockBeforeCurrentTime': '時間枠が現在の時間より前です',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.Start': '開始',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.End': '終了',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.Length': '長さ',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Error.NoAvailableTimeBlock': '選択した日に利用可能な時間枠がありません',
    'Calendar.Modal.Button.Client': 'クライアント',
    'Calendar.Modal.Button.Project': 'プロジェクト',
    'Calendar.Modal.Button.Scenario': 'シナリオ',
    'Calendar.Modal.Header.CompanyEvents': '会社のイベント',
    'MursionPortal.Header.Session': 'セッション',
    'MursionPortal.Header.Events': 'イベント',
    'MursionPortal.Header.Event': 'イベント',
    'Calendar.Modal.Label.Time': '時間',
    'Calendar.Label.SelectTimeZone': 'タイムゾーンを選択',
    'Calendar.Text.Training': '研修',
    'Calendar.Text.QA': 'QA',
    'Calendar.Modal.Text.ConfirmDeleteTime': '削除時間枠の確認',
    'Calendar.Button.NewTimeBlock': '新しい時間枠',
    'Calendar.Modal.Label.Capitalized.Time': '時間',
    'Calendar.Modal.Label.Participants': '参加者',
    'Calendar.Modal.Text.NoParticipants': '参加者なし',
    'Calendar.Modal.Label.SimulationSpecialist': 'シミュレーションスペシャリスト',
    'Calendar.Modal.Label.MeetingOrganizer': 'ミーティング主催者',
    'Calendar.Error.ErrorPrefixMustBeBetween': '{errorPrefix} は {duration1} から {duration2} の間となる必要があります。',
    'Calendar.Error.SelectedTimeBlockOverlapsExistingTime': '選択した時間枠が既存の時間枠と重複しています。',
    'Calendar.Error.SelectedTimeBlockOverlapsUnavailableTime': '選択した時間枠が利用不可な時間間隔と重複しています。',
    'Calendar.Error.SelectedTimeBlockIsNotMultipleOfEventGap': '選択した時間枠がイベントギャップ（{duration}）の倍数ではありません',
    'Calendar.Text.AvailabilityUser': '利用可能性 {user}',
    'Calendar.Text.AvailabilityTime': '利用可能性 {time}',
    'Calendar.Text.AvailabilityDurationAvailabilityTime': '{availabilityDuration} 利用可能性 {time}',
    'Calendar.Text.TrainingsTime': '{eventsCount} 研修 {time}',
    'Calendar.Text.SessionsTime': '{eventsCount} セッション {time}',
    'Calendar.Text.AvailabilityName': '利用可用性 {name}',
    'Calendar.Label.Learners': '学習者',
    'Calendar.Text.FullAvailability': '完全利用可能性',
    'Calendar.Text.YourAvailability': '自身の利用可能性',
    'Calendar.Text.Event': 'イベント',
    'Calendar.Header.Trainings': '研修',
    'Calendar.Header.CompanyEvents': '会社のイベント',
    'Calendar.Header.Session': 'セッション',
    'Calendar.Error.TimeBlockIsOutsideAvailableRange': '時間枠が利用可能な範囲外です',
    'Calendar.Error.TimeBlockIsBeforeCurrentTime': '時間枠が現在の時間より前です',
    'Calendar.Label.Break': '休憩',
    'Calendar.Label.Prep': '準備',
    'Calendar.Modal.Header.CreateBreakPrepEvent': '休憩/準備イベントの作成',
    'Calendar.Modal.Label.EventType': 'イベントタイプ',
    'Calendar.Modal.Label.TimeBlocks': '時間枠',
    'Calendar.Text.NoTimeBlocksInSelectedRange': '選択した範囲内に利用可能な時間枠がありません',
    'Calendar.Text.NoAvailableTimeBlocks': '利用可能な時間枠がありません',
    'Calendar.Text.SelectingThisTimeBlock': 'この時間枠を選択すると、現在利用不可能なセッション時間をリクエストすることになります。当社はこのリクエストに応えられるよう最善を尽くします。また、下のトグルを「オフ」にスライドすると、確認済みの利用可能な時間枠からのみ選択することができます。',
    'Calendar.ToolTip.SlideToggleOff': 'トグルを「オフ」にスライドして、使用可能な時間枠からのみ選択します。',
    'Calendar.ToolTip.SlideToggleOn': 'トグルを「オン」にスライドして、追加の時間枠をリクエストします。',
    'Calendar.Label.SelectAnyTimeBlock': '任意の時間枠を選択',
    'Users.CreateUser.Label.GoogleMeetID': 'Google Meet ID',
    'Users.CreateUser.GoogleMeetId.Placeholder.GoogleMeetID': 'Google Meet ID',
    'Users.CreateUser.GoogleMeetID.Hover': 'Google Meet IDを入力してください',
    'Setting.Edit.Modal.Error.UnableToCreateSession': '選択した時間{formattedStartTime} から {formattedEndTime} にセッションを作成できません',
    'Session.Header.Session': 'セッション',
    'Session.Header.AcceptSessionRequest': 'このセッションリクエストを承認して予定に追加するかどうかを確認してください',
    'CommonComponent.RecurrenceForm.Label.UntilDate': '日付まで',
    'CommonComponent.RecurrenceForm.Label.Period': '期間',
    'CommonComponent.RecurrenceForm.Option.Daily': '毎日',
    'CommonComponent.RecurrenceForm.Option.Weekly': '毎週',
    'CommonComponent.RecurrenceForm.Label.Weekday': '平日',
    'CommonComponent.RecurrenceForm.Text.NoSelectedWeekdays': '選択された平日はありません',
    'CommonComponent.RecurrenceForm.Error.PleaseSelectWeekdays': '平日を選択してください',
    'Calendar.EventType.Availability': '利用可能性',
    'Calendar.EventType.Session': 'セッション',
    'Calendar.EventType.Training': '研修',
    'Calendar.EventType.CompanyEvent': '会社のイベント',
    'Calendar.EventType.NewEvent': '新しいイベント',
    'Calendar.Text.NewEventTime': '新しいイベント {time}',
    'Calendar.Header.Training': '研修',
    'Calendar.Modal.Text.Sessions': 'セッション',
    'Calendar.Modal.Text.Trainings': '研修',
    'Calendar.Modal.Text.Events': 'イベント',
    'Calendar.Modal.Text.PendingNoSS': '- 保留中（SSなし）。',
    'Calendar.Modal.Text.PendingSwap': '- 保留中（交換）。',
    'Calendar.Modal.Text.PreBooked': '- 事前予約。',
    'Calendar.Modal.Text.NeedsReview': '- 評価が必要。',
    'Calendar.Modal.Text.JoinSession': '- セッションに参加できます。',
    'Calendar.Modal.Text.NoLearnersInSession': '- セッションに学習者がいません。',
    'Calendar.Modal.Text.NoLearnersInSessionAfterBookedTime': '- 予約時間後のセッションに学習者がいません。',
    'Calendar.Modal.Header.Events': 'イベント',
    'Calendar.Modal.Header.Session': 'セッション',
    'Calendar.Modal.Header.Training': '研修',
    'Calendar.Modal.Header.Availability': '利用可能性',
    'Calendar.Modal.Filter.Client': 'クライアント',
    'Calendar.Modal.Filter.Project': 'プロジェクト',
    'Calendar.Modal.Filter.Time': '時間',
    'Calendar.Modal.Label.Recurrence': '繰り返し',
    'Calendar.Modal.Header.CreateAvailability': '利用可用性の作成',
    'Calendar.Modal.Text.Recurring': '繰り返す',
    'Calendar.SimSpecialistButton.Modal.Header.Scheduled': '予定済み',
    'Calendar.SimSpecialistButton.Modal.Header.ThisPeriod': '（この期間）',
    'Calendar.SimSpecialistButton.Modal.Label.Sessions': 'セッション',
    'Calendar.SimSpecialistButton.Modal.Label.Hours': '時間',
    'Calendar.SimSpecialistButton.Modal.Label.Trainings': '研修',
    'Calendar.SimSpecialistButton.Modal.Label.TrainingsHours': '研修時間',
    'Calendar.SimSpecialistButton.Modal.Label.Available': '利用可能',
    'Calendar.SimSpecialistButton.Modal.Header.Certification': '証明書',
    'Calendar.SimSpecialistButton.Modal.Text.NoCertificates': '証明書なし',
    'Calendar.SimSpecialistButton.Modal.Header.SimSpecialistOverviewPanel': 'SIMスペシャリスト概要パネル',
    'Calendar.SimSpecialistButton.Modal.HoverTitle.CloseSimSpecialistOverview': 'SIMスペシャリストの概要を閉じる',
    'MursionPortal.DeliveryMode.OneToOne': '1対1',
    'MursionPortal.DeliveryMode.OneToManyRemote': 'リモート',
    'MursionPortal.DeliveryMode.OneToManyLocal': 'ローカル（フィッシュボウル）',
    'MursionPortal.DeliveryMode.Group': 'グループ配信',
    'MursionPortal.Projects.Header.Trainees': '研修生',
    'Projects.Placeholder.SimContentName': 'SIMコンテンツ名を入力してください',
    'Projects.Message.NoSimulationContentFound': 'シミュレーションコンテンツが見つかりません',
    'MursionPortal.Button.UncheckAll': 'すべてのチェックを外す',
    'MursionPortal.Button.CheckAll': 'すべてチェックする',
    'Projects.Header.NewScenario': 'プロジェクト {projectName}',
    'MursionPortal.Button.SaveAsDraft': '下書きを保存',
    'MursionPortal.Button.Save': '保存',
    'Projects.Message.SelectEnvironmentFirst': '最初に環境を選択してください',
    'Projects.Message.LoadingAvatarsList': 'アバターリストを読み込み中...',
    'Projects.Message.NoAssignedAvatars': '選択した環境にはアバターが割り当てられていません',
    'Projetcs.EmptyMessage.NoSelectedAvatars': '選択されたアバターはありません',
    'Projects.Avatars.NoAvatarsSpecified': 'アバターが指定されていません',
    'Projects.Message.SelectIndustryFirst': '業種を最初に選択してください',
    'Projects.Message.LoadingEnvironmentsList': '環境リストを読み込んでいます...',
    'Projects.Message.NoAssignedEnvironments': '選択した業種には環境が割り当てられていません',
    'Projects.Message.ScenarioHasNoAssignedEnvironment': 'シナリオには環境が割り当てられていません',
    'Projects.AltText.EnvironmentImage': '環境ー画像',
    'Projects.Placeholder.SelectAnEnviroment': '環境を選択',
    'Projects.Message.UnnamedIndustry': '名前のない業種',
    'Projects.Placeholder.SelectAnIndustry': '業種を選択',
    'Projects.Header.NewProject': '新規プロジェクト',
    'Projects.AltText.ClientLogo': 'クライアントロゴ',
    'Projects.Message.SelectAnyTimeBlock': '任意の時間枠を選択',
    'Projects.Message.SelectScenarioFirst': '最初にシナリオを選択してください',
    'MursionPortal.Text.Asset': 'アセットV2',
    'MursionPortal.Button.Accept': '承認する',
    'Projects.Modal.EmptyMessage.NoLearner': '学習者なし',
    'MursionPortal.Label.SelectAll': 'すべてを選択',
    'Projects.Modal.Placeholder.ChooseATeam': 'チームを選択',
    'MursionPortal.Placeholder.SearchTeams': 'チームを検索中...',
    'Projects.Modal.Text.SessionSize': '許可されるセッションサイズは {maxLearners}人です。特定の学習者を選択',
    'Projects.Modal.Placeholder.SearchLearners': '学習者を検索',
    'MursionPortal.ErrorMessage.MaxLearners': 'このタイプのセッションでは、最大 {maxLearners} 人まで学習者を追加できます。',
    'MursionPortal.ErrorMessage.MaxLearner': 'このタイプのセッションでは、最大 {maxLearners} 人まで学習者を追加できます。',
    'MursionPortal.Label.ChooseMembers': 'メンバーを選択',
    'MursionPortal.Message.NoMembersToSelect': '選択するメンバーがいません',
    'MursionPortal.Message.PleaseSelectATeamFirst': '最初にチームを選択してください',
    'AssetSync.Header.AssetSync': 'アセットの同期',
    'AssetSync.Text.AssetsSyncedSuccessfully': 'アセットが正常に同期されました',
    'AssetSync.Button.Sync': '同期',
    'AssetSync.Button.TryAgain': '再度お試しください',
    'AssetSync.SyncButton.Text.Syncing': '同期中',
    'SessionFlow.Label.SessionExport': 'セッションをエクスポート',
    'MursionPortal.Button.Export': 'エクスポート',
    'SessionFlow.Label.SessionFlowData': 'セッションのフローデータ',
    'SessionFlow.Button.LoadSessionData': 'セッションデータの読み込み',
    'SessionFlow.Button.StartSessionAnalytics': 'セッション分析を開始',
    'SessionFlow.Label.Checklist': 'チェックリスト',
    'SessionFlow.Label.RecordingSettings': '録画設定',
    'SessionFlow.Label.SessionVideoFact': 'セッション動画の事実',
    'SessionFlow.Label.UserStatus': 'ユーザーステータス',
    'SessionFlow.SessionVideoFact.ColumnHeader.VideoAllowed': '録画が許可されています',
    'MursionPortal.Table.ColumnHeader.UserName': 'ユーザー名',
    'MursionPortal.Table.ColumnHeader.Email': 'Eメール',
    'MursionPortal.Table.ColumnHeader.Status': 'ステータス',
    'SessionFlow.StartSessionAnalyticsButton.Text.EventStartingAnalytics': '分析プロセスを開始するイベントが正常に公開されました',
    'SessionFlow.RecordingSettings.Label.VideoRecording': '動画の録画',
    'SessionFlow.RecordingSettings.Text.Allowed': '許可',
    'SessionFlow.RecordingSettings.Text.Disallowed': '不許可',
    'SessionFlow.RecordingSettings.Label.RecordingPermission': '録画許可',
    'SessionFlow.RecordingSettings.Text.LiesWithLearner': '学習者の義務',
    'SessionFlow.RecordingSettings.Label.RecordedVideosGoBackTo': '録画された動画が戻る場所：',
    'SessionFlow.Error.CannotFindSession': '指定されたIDのセッションが見つかりません ',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Type': '種類',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Date': '日付',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Error': 'エラー',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Payload': 'ペイロード',
    'SessionFlow.SessionFlowData.PlaceHolder.SessionID': 'セッション ID',
    'SessionFlow.Button.HideDetailedEventLog': '詳細なイベントログを非表示...',
    'SessionFlow.Button.ShowDetailedEventLog': '詳細なイベントログを表示...',
    'SessionFlow.Button.HideSessionHistory': 'セッション履歴を非表示...',
    'SessionFlow.Button.ShowSessionHistory': 'セッション履歴を表示...',
    'SessionFlow.Text.NoEvents': 'イベントはありません',
    'SessionFlow.SessionVideoFact.Label.VideoExists': '動画が存在します',
    'SessionFlow.Text.NoLearners': '学習者がいません',
    'SessionFlow.Checklist.DataRecording': 'データ記録',
    'SessionFlow.Checklist.DataUploading': 'データのアップロード',
    'SessionFlow.Checklist.Analytics': '分析',
    'Companies.ArchiveIconButton.Modal.ConfirmationText.ArchiveCompany': '本当にこの会社をアーカイブしますか？',
    'Companies.ArchiveIconButton.Modal.Header.ArchiveCompany': '会社をアーカイブする',
    'Companies.Label.CompanyInfo': '会社情報',
    'Companies.CompanyInfo.Archived': '（アーカイブ済み）',
    'Companies.CancelIconButton.HoverTitle.CancelEdit': '編集をキャンセル',
    'Companies.EditIconButton.HoverTitle.EditCompany': '会社を編集',
    'Companies.Button.InviteProfService': '専門サービスの招待',
    'Companies.InviteProfServiceButton.Modal.Header.NewProfService': '新しい専門サービス',
    'Companies.Label.Config': '設定',
    'Companies.EditIconButton.HoverTitle.EditConfig': '設定を編集',
    'Companies.Config.Button.Test': 'テスト',
    'Companies.Config.Button.SaveCompanyConfig': '会社設定を保存',
    'Companies.CompanyInfo.Button.SaveCompanyInfo': '会社情報を保存',
    'Companies.ValidationError': '検証エラーがあります',
    'Companies.Config.SaveCompanyConfigButton.FailedToSaveConfig': '設定の保存に失敗しました。エラーを修正してください。',
    'Companies.Config.Text.TestPassedSuccessfully': 'テストに合格しました',
    'Companies.CreateUserButton.PlaceHolder.CompanyName': '会社名',
    'Companies.Button.CreateCompany': '会社を作成',
    'Companies.Error.NoCompanies': '会社がありません',
    'Companies.InviteProfServiceButton.Modal.Label.ProfServices': '専門サービス',
    'Companies.InviteProfServiceButton.Modal.Button.AddUser': 'ユーザーを追加',
    'Companies.CreateUserButton.Modal.Header.NewCompany': '新しい会社',
    'Companies.Header.AllCompanies': 'すべての会社',
    'MursionPortal.Roles': '役割',
    'Users.ArchiveButton.Modal.Header.ArchivedGlobalUser': 'アーカイブされたグローバルユーザー',
    'Users.ActiveButton.Modal.Header.EditGlobalUser': 'グローバルユーザーを編集',
    'Users.CreateUserButton.Modal.Header.NewGlobalUser': '新しいグローバルユーザー ',
    'Users.NoGlobalUserFoundError': 'グローバルユーザーが見つかりませんでした。',
    'Users.Table.ColumnHeader.Department': '部門',
    'MursionPortal.TechSupport.HoverTitle.ShowFeedBack': 'フィードバックを表示',
    'MursionPortal.TechSupport.HoverTitle.HideFeedBack': 'フィードバックを非表示',
    'MursionPortal.TechSupport': 'テクニカルサポート',
    'MursionPortal.TechSupport.Header.ContactSupport': 'サポートに問い合わせる',
    'MursionPortal.TechSupport.Label.Phone': '電話',
    'MursionPortal.TechSupport.Label.Email': 'Eメール',
    'MursionPortal.TechSupport.Label.Hours': '時間',
    'MursionPortal.TechSupport.Link.MursionSupport': 'Mursionサポート',
    'Downloads.Text.ClickDownloadButton': '下のダウンロードボタンをクリックし、インストーラーの最新バージョンを入手してください。この実行可能ファイルは、ブラウザでデフォルトのダウンロード場所（ダウンロードフォルダなど）に保存されます。',
    'Downloads.Text.OnceDownloadIsComplete': 'ダウンロードが完了しましたら、ダウンロードした実行可能ファイルをクリックし、画面の指示に従ってインストールを完了させてください。',
    'Downloads.Text.AppInstalled': 'アプリをインストールすると、次回からはMursionアイコンをダブルクリックして起動することができます。',
    'Downloads.Button.Download': 'ダウンロード',
    'Downloads.Text.PlatformNotCurrentlySupported': '申し訳ありませんが、お使いのプラットフォームは現在サポートされていません。',
    'Downloads.Text.FollowOnScreenInstructions': '{info} をクリックし、画面の指示に従ってアプリケーションをインストールします',
    'Downloads.Text.ShortcutCreatedOnInstallationComplete': 'インストールが完了すると、Mursionへのショートカットがデスクトップに作成されます。{lineBreak} 予定されているシミュレーションを体験するためにアプリケーションを起動する必要があるときは、{image} ショートカットをダブルクリックしてください。',
    'Filters.Date.LastWeek': '先週',
    'MursionPortal.Text.Mins': '分',
    'MursionPortal.Text.Min': '分',
    'Tutorial.Header.ManageYourTeams': 'チームを管理',
    'MursionPortal.Button.Awesome': '素晴らしい',
    'Tutorial.Header.ReviewYourProjects': 'プロジェクトを確認',
    'MursionPortal.Button.LooksGood': '良さそう',
    'Tutorial.Header.LearnerScheduling': '学習者の日程管理',
    'MursionPortal.Button.AllDone': 'すべて完了！',
    'Tutorial.Header.InviteYourUsers': 'ユーザーを招待',
    'MursionPortal.Button.GotIt': '了解',
    'Tutorial.Header.CreateATeam': 'チームを作成',
    'Tutorial.Header.ManageYourProjects': 'プロジェクトを管理',
    'Tutorial.Header.YourTeams': 'チームの',
    'Tutorial.Header.YourProjects': 'プロジェクトの',
    'Tutorial.Header.SessionCalendar': 'セッションカレンダー ',
    'Tutorial.Tooltip.TeamsOrganize': 'チームは学習者を編成し、シナリオに追加されます',
    'Tutorial.Text.ViewSessions': 'チームの <code>今後および完了した</code> セッションを表示',
    'Tutorial.Tooltip.ContactManager': 'または、{companyName} のプロジェクトマネージャーに連絡します',
    'Tutorial.Tooltip.YourPointOfContact': 'あらゆる学習のための連絡先',
    'Tutorial.Text.TeamAdded': 'チームが <code>シナリオに追加され</code> 、日程管理が開始します',
    'Tutorial.Text.ViewLearners': '学習者の <code>日程管理アクティビティ</code> と <code>ステータス</code>を表示',
    'Tutorial.Text.EnterEmail': '新しい {companyName} ポータルユーザーごとに <code>Eメールアドレス</code> を入力します',
    'Tutorial.Text.RegistrationEmail': '<code>登録メール</code> が彼らの受信トレイへ送信されます',
    'Tutorial.Text.TeamsOrganize': 'チームは <code>学習者を編成し</code> 、ファシリテーターによって管理されます',
    'Tutorial.Text.UniqueName': 'チームに <code>一意の名前を</code> 付けて、場所を入力してください',
    'Tutorial.Text.SessionDelivery': '後で、チームが <code>セッション配信</code> の <code>シナリオに追加されます</code>',
    'Tutorial.Tooltip.Description': '説明、結果、および戦略',
    'Tutorial.Text.BeginScheduling': '日程調整を始めるために、チームを <code>シナリオに追加する</code> 必要があります',
    'Tutorial.Text.MonitorSessions': '各プロジェクトの <code>今後のセッション</code> と <code> 利用可能性</code>を監視',
    'Tutorial.Text.LearnersWithoutSessions': 'セッションを<code>まだ予定に入れていない学習者</code> を表示',
    'Tutorial.Tooltip.LearnersSchedule': '学習者は自分で予定を設定することもできます',
    'Tutorial.Text.AddedToLearners': '<code>1つ以上</code>の学習者チームに追加されます',
    'Tutorial.Tooltip.TeamAdmin': 'チーム管理者と連絡先',
    'Tutorial.Text.TeamAddedBeforeProject': 'チームはプロジェクトの開始前に <code>シナリオに追加されます</code> ',
    'Tutorial.Text.AddRemoveLearners': '<tooltip>チーム</tooltip> への学習者の<code>追加または削除</code>',
    'Tutorial.Text.AddedByCompany': 'あなたの会社の <tooltip>アカウント所有者</tooltip>があなたをシナリオ/プロジェクトに追加します',
    'Tutorial.Text.ViewYourHours': '時間やシナリオを表示、または、<tooltip><code>プロジェクトマネージャー</code></tooltip>に連絡してください',
    'Tutorial.Text.ScenariosDetail': 'シナリオには、<code>{companyName}での経験</code>を促進する <tooltip>詳細</tooltip>が含まれています',
    'Tutorial.Text.SelectTimeSlot': '開いている時間枠を選択し、セッションを <tooltip><code>学習者の予定に入れます</code></tooltip>',
    'Tutorial.Text.ChooseRole': '新規ユーザーごとに役割（<tooltip>アカウント所有者</tooltip> <secondTooltip>ファシリテーター</secondTooltip> または <thirdTooltip>学習者</thirdTooltip>）を選択',
    'Tutorial.Tooltip.AdminManages': 'ユーザーとプロジェクトを管理する管理者',
    'Tutorial.Tooltip.ManagesScheduling': '日程管理とセッション配信を管理',
    'Tutorial.Tooltip.SchedulesSessions': 'セッションの予定と参加 ',
    'Tutorial.Text.ScenariosContain': 'シナリオには、<code>{companyName}での経験</code>を促進する <tooltip>詳細</tooltip> が含まれています',
    'Tutorial.Text.SelectOpenTimeSlot': '開いている時間枠を選択し、<tooltip><code>学習者の予定に入れます</code></tooltip>',
    'Tutorial.Text.AdminOfTeam': '<tooltip>ファシリテーター</tooltip> は、チームの管理者および連絡窓口です',
    'Tutorial.Text.ProjectsContain': 'プロジェクトには <code>シナリオ</code>が含まれており、その <tooltip>詳細な内容</tooltip> が <code>学習体験</code>を促進します',
    'Tutorial.Text.ViewAvailability': '利用可能性の表示と <code>セッションの予定</code>',
    'Tutorial.Text.TrackSessions': '各プロジェクトの <code>今後のセッション</code> を追跡します。必要に応じてキャンセル/再日程調整',
    'Tutorial.Text.ReviewPerformance': 'セッション後に <code>パフォーマンス</code> と <code>戦略の完了</code> を確認します',
    'Tutorial.Header.Complete': '完了',
    'Tutorial.Text.RevisitThisGuide': 'このガイドやその他の役立つ情報は、{lineBreak} <code>ナレッジベース</code> で <code>いつでも</code>再確認できます',
    'Tutorial.Button.TakeMeToThePortal': 'ポータルまでご案内',
    'Tutorial.Header.DownloadInstallMursionSimulator': 'Mursionシミュレーターを {lineBreak}ダウンロードしてインストールします',
    'Tutorial.Text.PlanningToUseMursionOnComputer': 'ご使用のコンピューターを使用してMursionシミュレーションを実行する場合は、下の[Mursionをインストール]ボタンをクリックして、必要なソフトウェアを今すぐインストールすることをお勧めします。',
    'Tutorial.Text.InstallSoftwareFromPortal': '後でポータルから直接ソフトウェアをインストールすることもできます。Mursionの没入型シミュレーターは、初めて使用する際に、インストールに数分かかる場合があります。シミュレーション開始の30分前までにソフトウェアのインストールを済ませておくことで、Mursionを満喫するための十分な時間を確保できます',
    'Tutorial.Text.ProvideInstructionsForInstallation': 'Mursionのポータルやシミュレーター内のEメールやチュートリアルにより、インストールおよびその他のワークフローに関する説明を随時行っていきます。私たちと同じくらい、皆さんにシミュレーションの作成を楽しんでいただければ幸いです！',
    'Tutorial.Text.TheMursionTeam': 'Mursionチーム。',
    'Tutorial.Button.InstallLater': '後でインストール',
    'Tutorial.Button.InstallMursion': 'Mursionをインストール',
    'Tutorial.Header.LetsGetStarted': '始めましょう',
    'Tutorial.Text.ShortIntro': 'まず、<code>ユーザーの役割</code> の一般的な機能について {lineBreak} <code>簡単な説明</code>をご覧ください',
    'Tutorial.Button.Proceed': '続ける',
    'Tutorial.Label.Step': 'ステップ',
    'Tutorial.Label.NoSound': 'この動画には音声がありません。',
    'Tutorial.Button.SkipTutorial': 'チュートリアルをスキップ',
    'Tutorial.Button.CloseTutorial': 'チュートリアルを閉じる',
    'MursionPortal.Text.On': 'オン',
    'MursionPortal.Text.Off': 'オフ',
    'MursionPortal.Text.MursionIcon': 'Mursion-アイコン',
    'MursionPortal.AriaLabel.CreateNew': '新規作成',
    'MursionPortal.Legend.VisibilityHidden.Buttons': '新規、リピートクライアント、リピート銀行シナリオボタンの作成',
    'MursionPortal.Label.VisibilityHidden.EnterEmailId': 'EメールIDを入力',
    'MursionPortal.Label.VisibilityHidden.SelectRegistrationStatus': '[登録ステータス]を選択',
    'MursionPortal.Label.VisibilityHidden.ClientLogoUpload': 'クライアントロゴのアップロード',
    'MursionPortal.Header.VisibilityHidden.CompanyUsers': '法人ユーザー',
    'MursionPortal.Legend.VisibilityHidden.StationType': 'ステーションタイプ',
    'MursionPortal.Legend.VisibilityHidden.Hours': '時間',
    'MursionPortal.Caption.VisibilityHidden.CompanyUsersList': '法人ユーザーのリスト',
    'MursionPortal.AriaLabel.Search': '検索',
    'MursionPortal.AriaLabel.From': '開始',
    'MursionPortal.AriaLabel.To': '終了',
    'MursionPortal.AriaLabel.RemoveAccountOwner': 'アカウント所有者を削除',
    'MursionPortal.AriaLabel.InboxMessagePanel': '受信トレイメッセージパネル',
    'MursionPortal.AriaLabel.Breadcrumb': 'パンくずリスト',
    'MursionPortal.Message.NoItemsHaveBeenFound': '項目が見つかりませんでした。',
    'MursionPortal.AriaLabel.Add': '追加',
    'MursionPortal.AriaLabel.SortByDescendingOrder': '降順で並べ替え',
    'MursionPortal.AriaLabel.SortByAscendingOrder': '昇順で並べ替え',
    'MursionPortal.AriaLabel.AddButton': '追加ボタン',
    'MursionPortal.AriaLabel.Timezone': 'タイムゾーン',
    'MursionPortal.AriaLabel.Combobox': 'コンボボックス',
    'MursionPortal.AriaLabel.PreviousMonth': '前月',
    'MursionPortal.AriaLabel.NextMonth': '翌月',
    'MursionPortal.AriaLabel.SearchInput': '検索入力',
    'MursionPortal.AriaLabel.Informational': '情報',
    'MursionPortal.Text.RedirectToCalendarPage': 'カレンダーページにリダイレクトする',
    'MursionPortal.AriaLabel.StartDate': '開始日 {startFormattedDate}',
    'MursionPortal.AriaLabel.EndDate': '終了日 {endFormattedDate}',
    'MursionPortal.AriaLabel.CreateProject': 'プロジェクトの作成',
    'MursionPortal.AriaLabel.ContentInfo': 'コンテンツ情報',
    'MursionPortal.AriaLabel.DraftSuperScript': '下書きの上付き文字 {statusConfiguration}',
    'MursionPortal.AriaLabel.FinalizedSuperScript': '最終的な上付き文字 {statusConfiguration}',
    'MursionPortal.AriaLabel.StartedSuperScript': '上付き文字を開始 {statusConfiguration}',
    'MursionPortal.AriaLabel.FinishedSuperScript': '上付き文字を終了 {statusConfiguration}',
    'MursionPortal.AriaLabel.ArchivedSuperScript': '上付き文字をアーカイブしました {statusConfiguration}',
    'MursionPortal.AriaLabel.EnterScenarioName': 'シナリオ名を入力',
    'MursionPortal.AriaLabel.Capitalized.Search': '検索',
    'MursionPortal.AriaLabel.Close': '閉じる',
    'MursionPortal.AriaLabel.CreateTeam': 'チームを作成',
    'MursionPortal.AriaLabel.CreateClient': 'クライアントを作成',
    'MursionPortal.AriaLabel.CreateUser': 'ユーザーを作成',
    'MursionPortal.AriaLabel.ZoomId': 'Zoom ID',
    'MursionPortal.AriaLabel.PrimaryContent': '主要コンテンツ',
    'MursionPortal.AriaLabel.RegistrationStatus': '登録状況',
    'MursionPortal.AriaLabel.EnterTagName': 'タグ名を入力',
    'MursionPortal.AriaLabel.EnterUserName': 'ユーザー名を入力',
    'MursionPortal.AriaLabel.SearchProjectManagers': 'プロジェクトマネージャーを検索',
    'MursionPortal.AriaLabel.SearchSSO': 'SSOを検索',
    'MursionPortal.AriaLabel.SearchAccountOwner': 'アカウント所有者を検索',
    'MursionPortal.WarningMessage.AllowedCharacterLimit': '許可されている文字数制限（{maximumLength} 文字）を超えています',
    'MursionPortal.WarningMessage.InvalidCharactersDetected': '無効な文字が検出されました',
    'MursionPortal.Days.Sunday': '日曜日',
    'MursionPortal.Days.Monday': '月曜日',
    'MursionPortal.Days.Tuesday': '火曜日',
    'MursionPortal.Days.Wednesday': '水曜日',
    'MursionPortal.Days.Thursday': '木曜日',
    'MursionPortal.Days.Friday': '金曜日',
    'MursionPortal.Days.Saturday': '土曜日',
    'MursionPortal.Button.AddNew': '新規追加',
    'MursionPortal.Title.EditNotes': 'メモを編集',
    'MursionPortal.IconButton.Remove': '削除',
    'MursionPortal.Filter.ProviderAll': 'プロバイダー',
    'MursionPortal.Placeholder.SearchProviders': 'プロバイダーを検索...',
    'MursionPortal.AriaLabel.InboxButton': '受信トレイボタン 新しいメッセージが {messageCount} 通あります',
    'MursionPortal.AriaLabel.Event': 'イベント',
    'MursionPortal.Title.SelectedDate': '選択した日付',
    'MursionPortal.Title.CurrentDate': '現在の日付',
    'MursionPortal.Title.OffRangeDate': '範囲外の日付',
    'MursionPortal.Label.ClientLevel': 'クライアントレベル',
    'MursionPortal.Label.TeamLevel': 'チームレベル',
    'MursionPortal.AltText.UserImage': 'ユーザー画像',
    'MursionPortal.AltText.KbMenu': 'kbメニュー',
    'MursionPortal.AltText.AvatarImage': 'アバターー画像',
    'MursionPortal.AltText.EnvironmentImage': '環境ー画像',
    'MursionPortal.AriaLabel.Cancel': 'キャンセル',
    'MursionPortal.AriaLabel.Edit': '編集',
    'MursionPortal.AriaLabel.EditAccountOwner': 'アカウント所有者を編集',
    'MursionPortal.AriaLabel.Remove': '削除',
    'MursionPortal.AriaLabel.SearchFilter': '検索フィルター',
    'MursionPortal.AriaLabel.TierCombobox': '階層別コンボボックス',
    'MursionPortal.AriaLabel.SortByAscending': '昇順で並べ替える',
    'MursionPortal.AriaLabel.SortByDescending': '降順で並べ替え',
    'MursionPortal.Message.VisibilityHidden.SelectingDeliveryMode': '配信モードを選択すると、セッションの長さやとセッションサイズの設定が可能となります。',
    'MursionPortal.AltText.DownloadComputerIcon': 'Mursionダウンロードのコンピュータアイコン',
    'MursionPortal.AltText.DownloadIcon': 'Mursionダウンロードアイコン',
    'MursionPortal.AltText.MursionLogo': 'Mursionロゴ',
    'MursionPortal.AriaLabel.MursionSupport': 'Mursionサポート',
    'MursionPortal.AltText.MursionDashboard': 'Mursion-ダッシュボード',
    'MursionPortal.AltText.BackgroundMaleUser': 'Mursion背景の男性ユーザー',
    'MursionPortal.AltText.BackgroundGroupUser': 'Mursion背景のグループユーザー',
    'MursionPortal.AltText.BackgroundFemaleUser': 'Mursion背景の女性ユーザー',
    'MursionPortal.ErrorMessage.CharacterLimitExceeded': '許可されている文字数制限（{maximumLength} 文字）を超えています',
    'MursionPortal.ErrorMessage.InvalidCharactersDetected': '無効な文字が検出されました',
    'MursionPortal.ErrorMessage.EnterValidPhoneNumber': '有効な電話番号を入力してください。',
    'MursionPortal.Message.InvitationLinkInvalid': '招待リンクが無効、または有効期限が過ぎている可能性があります。{lineBreak} 登録を完了していない場合 {lineBreak} 、管理者に再招待を依頼してください。',
    'MursionPortal.Header.SetYourPassword': 'パスワードを作る',
    'MursionPortal.Message.PasswordMessage': 'パスワードには次のものが含まれている必要があります。',
    'MursionPortal.Label.NewPassword': '新しいパスワード',
    'MursionPortal.Label.RepeatPassword': 'パスワード再入力',
    'MursionPortal.Button.SetPassword': 'パスワードを設定',
    'MursionPortal.Header.ErrorMessage.SomethingWentWrong': '問題が発生しました {lineBreak}ので、サポートまで {lineBreak}ご連絡ください',
    'MursionPortal.Header.ContactSupport': 'サポートに問い合わせる',
    'MursionPortal.Label.Phone': '電話',
    'MursionPortal.Label.Email': 'Eメール',
    'MursionPortal.Label.Hours': '時間',
    'MursionPortal.Message.EnterPasswordToConfirm': 'この変更を確認するためにパスワードを入力してください',
    'MursionPortal.Label.Password': 'パスワード',
    'MursionPortal.Message.BrowserDialog': 'ブラウザダイアログが表示されない場合は、下の <code>[Mursionを開く]を</code>クリックするか、{lineBreak}Mursionデスクトップショートカットを使用してMursionアプリケーションを開いてください。',
    'MursionPortal.Button.OpenMursion': 'ML3 Desktop Appを開く',
    'MursionPortal.Message.FollowInstructions': '{appName} をクリックし、画面の指示に従ってアプリケーションをインストールします',
    'MursionPortal.Text.PositiveImpact': 'あなたが良い影響を及ぼしていると感じていましたら、<code>{icon}</code> ボタンをクリックしてください。',
    'MursionPortal.Text.NegativeImpact': 'あなたが悪い影響を及ぼしていると感じていましたら、<code>{icon}</code> ボタンをクリックしてください。',
    'MursionPortal.Text.NeutralImpact': 'あなたが及ぼしている影響が良くも悪くもないと感じていましたら、<code>{icon}</code> ボタンをクリックしてください。',
    'MursionPortal.Title.Next': '次へ',
    'MursionPortal.Title.Start': '開始',
    'MursionPortal.Text.UpdateYourRating': '動画を見ながら、ご自身がアバターに異なる影響を与えていると思われるときはいつでも評価を更新することができます。',
    'MursionPortal.Text.SeeCurrentRating': '動画の下のグラフが更新されますので、現在の評価を確認し、影響が変化したと思われる場合はこれを変更することができます。',
    'MursionPortal.Text.ClickStartToBegin': '[開始] をクリックして始めます。シミュレーション全体を評価した後に[完了]をクリックすることができます。データの整合性を維持するために、この動画の一部ではシーク機能が無効になっている場合がありますので、ご注意してください。',
    'MursionPortal.Header.HowWouldYouProceed': 'どのように進めますか？',
    'MursionPoral.Button.SwitchUser': 'ユーザーの切り替え',
    'MursionPortal.Button.ContinueAs': '<code>{userName}</code>として続ける',
    'MursionPortal.Text.ToContinue': '<code>{loginData}</code> として続けるために 、<code>[ユーザーの切り替え]</code>をクリックしてください。',
    'MursionPortal.Message.YouAreSignedIn': '現在、<code>{userName}</code>（{userProfile}）としてサインインしています。',
    'MursionPortal.Title.ContinueAs': '{userName}として続ける',
    'MursionPortal.Message.OpenLink': 'リンクを開く',
    'MursionPortal.Message.Allow': '許可',
    'MursionPortal.Message.Open': '開く',
    'MursionPortal.Message.Yes': 'はい',
    'MursionPortal.Message.OpenMursionApp': 'MursionApp を開く',
    'MursionPortal.PasswordRequirementMessage.MinimumCharacters': '少なくとも {PASSWORD_MIN_LENGTH} 文字',
    'MursionPortal.Message.PortalHeading': '設計、スケジュール、管理',
    'MursionPortal.PasswordRequirementMessage.UppercaseLetters': '大文字 + 小文字',
    'MursionPortal.PasswordRequirementMessage.Numbers': '数字の任意の組み合わせ',
    'MursionPortal.ErrorMessage.InvalidEmailFormat': '無効なEメール形式です。',
    'MursionPortal.ErrorMessage.EmailCanNotBeEmpty': 'Eメールアドレスを入力してください。',
    'MursionPortal.ErrorMessage.EnterValidEmailAddress': '有効なEメールアドレスを入力してください。',
    'MursionPortal.ErrorMessage.PasswordCannotBeEmpty': 'パスワードを入力してください',
    'MursionPortal.ErrorMessage.UrlCannotBeEmpty': 'URLを入力してください',
    'MursionPortal.ErrorMessage.UrlIsNotValid': 'URLが無効です',
    'MursionPortal.ErrorMessage.NoMeetingId': 'ミーティングIDを含ませてください',
    'MursionPortal.ValidationErrorMessage.FileRequestFailed': 'ファイル要求が失敗しました',
    'MursionPortal.ValidationErrorMessage.FileExceedsLimit': 'ファイルが5MBの制限を超えています',
    'MursionPortal.ValidationErrorMessage.EnterMessage': 'メッセージを入力してから送信してください',
    'MursionPortal.ValidationErrorMessage.MessageTooLong': 'メッセージが長すぎます',
    'MursionPortal.ValidationErrorMessage.InvalidUrl': 'ファイルのURLが無効です',
    'MursionPortal.Button.SubmitFeedback': 'フィードバックを送信する',
    'MursionPortal.Header.Feedback': 'フィードバック',
    'MursionPortal.Text.AdditionalComments': '追加のコメントまたは提案',
    'MursionPortal.Header.ThanksForFeedback': 'フィードバックをありがとうございます',
    'MursionPortal.Button.Close': '閉じる',
    'MursionPortal.Title.ShowFeedback': 'フィードバックを表示',
    'MursionPortal.Title.HideFeedback': 'フィードバックを非表示',
    'MursionPortal.ErrorMessage.FileTypeNotSupported': 'ファイルタイプ {type} はサポートされていません',
    'MursionPortal.ErrorMessage.ScenarioDuration': 'シナリオの期間は {durationMinimum} から{durationMaximum}の間にしてください。',
    'MursionPortal.ErrorMessage.ScenarioScheduledSessions': 'このシナリオには、予定されているセッションが {sessionsCount} 件あります。このシナリオをアーカイブする前に、これらのセッションをキャンセルしてください。',
    'MursionPortal.ErrorMessage.ScenarioScheduledSession': 'このシナリオには、予定されているセッションが {sessionsCount} 件あります。このシナリオをアーカイブする前に、このセッションをキャンセルしてください。',
    'MursionPortal.ErrorMessage.ClientScheduledSessions': 'このクライアントには、予定されているセッションが {sessionsCount} 件あります。このクライアントをアーカイブする前に、これらのセッションをキャンセルしてください。',
    'MursionPortal.ErrorMessage.ClientScheduledSession': 'このクライアントには、予定されているセッションが {sessionsCount} 件あります。このクライアントをアーカイブする前に、このセッションをキャンセルしてください。',
    'MursionPortal.EulaAgreement.EndUserAgreement': '本エンドユーザー契約（以下「契約」といいます）は、お客様、ユーザー、およびMursion, Inc. （以下「Mursion」といいます）の間の法的契約であり、MursionおよびMursionソフトウェアを使用しているMursionのクライアント企業の間におけるサービスとしてのMursionソフトウェア契約の利用規約に従います。明確にするために記しますと、お客様は、Mursionソフトウェア（以下「Mursionソフトウェア」といいます）の使用およびアクセスの条件として、お客様およびお客様にMursionソフトウェアへのアクセスを提供する企業が本契約の利用規約に従うことを認め、同意するものとします。',
    'MursionPortal.EulaAgreement.TermsAndConditions': '本契約およびサービスとしてのMursionソフトウェア契約の条件に従い、Mursionはお客様に対し、Mursionソフトウェアへアクセスおよび使用するための限定的かつ非独占的で、譲渡不能な、サブライセンス不能な権利を許諾します。',
    'MursionPortal.EulaAgreement.AgreeAndAcknowledge': 'お客様は、Mursionソフトウェアまたは関連資料の全部または一部を複製、修正、リバースエンジニアリング、逆コンパイル、逆アセンブルする、または、それに基づく二次的著作物を作成する権利を有しておらず、またそのようなことをしないことに同意し、認めるものとします。お客様は、Mursionソフトウェアに関連する保護機構を無効化、停止、または回避しないことに同意するものとします。お客様は、Mursionソフトウェアに対する不正な所有、使用、またはアクセスを知った場合、速やかにMursionに通知し、さらに、そのような出来事のそれぞれの詳細を速やかに提供し、（可能な範囲で）再発防止に合理的に協力し、Mursionの権利を保護するために行われるあらゆる手続きに全面的に協力することに同意するものとします。',
    'MursionPortal.EulaAgreement.ConstruedAsGranting': '本契約に明示的に記載されている場合を除き、本契約に記載されているいかなる内容も、暗示的か否かにかかわらず、Mursionの書面による事前の承認を得ることなく、Mursionの知的財産に対する追加的な権利を付与するものとは解釈されないものとします。',
    'MursionPortal.EulaAgreement.NonDisclosure': 'お客様は、本契約で明示的に許可されている以外の目的で、Mursionソフトウェアまたはその一部を誰かに表示または開示しないことに同意するものとします。',
    'MursionPortal.EulaAgreement.AgreeAndRelease': 'お客様は、お客様によるMursionソフトウェアの使用に起因、原因とする、またはこれに関連する、既知および未知のあらゆる請求から、Mursion、その従業員、役員、代理店、関連会社、幹部職および取締役を解放、免責し、または損失を与えないことに同意するものとします。',
    'MursionPortal.EulaAgreement.UnderstandAndAcknowledge': 'お客様は、Mursionが、お客様によるMursionソフトウェアの使用から収集した情報およびデータを、本契約に記載されているMursionのプライバシーポリシーの条件に従って収集、維持、使用、保存、および分析する可能性があることを理解し、認めるものとします。',
    'MursionPortal.EulaAgreement.AccessRights': 'Mursionによりお客様に付与された使用権およびアクセス権は、Mursionソフトウェアへのアクセスをお客様に提供するサービスとしてのMursionソフトウェア契約が満了または終了した場合、またはお客様が本契約のいずれかの条項に違反した場合には直ちに終了するものとします。',
    'MursionPortal.EulaAgreement.AgreementGoverned': '本契約は、法の抵触に関する原則を考慮することなく、デラウェア州法に準拠し、これに基づいて解釈されるものとします。Mursionソフトウェアまたは本契約の使用に起因または関連するすべての紛争は、デラウェア州の管轄権を有する州または連邦裁判所に提起されるものとし、お客様は、かかる裁判所の対人管轄権に同意するものとします。',
    'MursionPortal.EulaAgreement.ProvisionsDeclaredInvalid': '本契約のいずれかの条項が無効であると宣言された場合、そのような条項は本契約から分離され、本契約の他の条項は引き続き完全に効力を有するものとします。',
    'MursionPortal.EulaAgreement.AgreementConflict': '本契約の条件がサービスとしてのMursionソフトウェア契約の条件と矛盾する場合、本契約に含まれる条件が優先するものとします。',
    'MursionPortal.EulaAgreement.AccessToMursionSofware': 'Mursionソフトウェアの使用またはアクセスは、本契約および本契約に記載されている利用規約への同意を証明するものとします。',
    'MursionPortal.Header.MursionUserAgreement': 'Mursionユーザー契約',
    'MursionPortal.Button.Back': '戻る',
    'MursionPortal.Button.Skip': 'スキップ',
    'MursionPortal.Title.BackToSessions': 'セッションに戻る',
    'MursionPortal.Title.Proceed': '続ける',
    'MursionPortal.Message.SkipActivity': 'スキップを選択した場合、当社の行動分析エンジンによって提供される可能性のあるパフォーマンスインサイトが失われます。このアクティビティをスキップしないことをお勧めします。',
    'MursionPortal.HeaderTitle.ConfirmCancellation': 'キャンセルを確認',
    'MursionPortal.Label.Delete': '削除',
    'MursionPortal.Label.Restore': '復元',
    'MursionPortal.HeaderTitle.ConfirmRestore': '復元の確認',
    'MursionPortal.Label.Archive': 'アーカイブ',
    'MursionPortal.Button.CancelTraining': '研修をキャンセル',
    'MursionPortal.Button.No': 'いいえ',
    'MursionPortal.Button.CancelEvent': 'イベントをキャンセル',
    'MursionPortal.Label.Update': '更新',
    'MursionPortal.HeaderTitle.ConfirmUpdate': '更新の確認',
    'MursionPortal.Button.UpdateSession': 'セッションを更新',
    'MursionPortal.Label.Swap': '交換',
    'MursionPortal.HeaderTitle.ConfirmAcceptedSwap': '承認された交換を確認',
    'MursionPortal.Button.AcceptSwap': '交換を承認する',
    'MursionPortal.Label.Reason': '理由',
    'MursionPortal.Placeholder.ReasonForSwapping': '交換の理由を追加してください。',
    'MursionPortal.Label.Request': 'リクエスト',
    'MursionPortal.HeaderTitle.ConfirmAcceptedRequest': '承認されたリクエストを確認 ',
    'MursionPortal.Button.AcceptRequest': 'リクエストを承認する',
    'MursionPortal.Placeholder.ReasonForAccepting': '承認する理由を追加してください。',
    'MursionPortal.Button.ConfirmProjectChange': 'プロジェクトの変更を確認',
    'MursionPortal.HeaderTitle.Confirm': '確認',
    'MursionPortal.Button.ConfirmCreateSession': 'セッションの作成を確認',
    'MursionPortal.Placeholder.ReasonForCreation': '作成の理由を追加してください。',
    'MursionPortal.Button.ConfirmAssigningSimSpecialist': 'SIMスペシャリストの割り当てを確認',
    'MursionPortal.Placeholder.ReasonForAssigning': '割り当てる理由を追加してください。',
    'MursionPortal.Button.ConfirmChangingSimSpecialist': 'シムスペシャリストの変更を確認',
    'MursionPortal.Placeholder.ReasonForChanging': '変更の理由を追加してください。',
    'MursionPortal.HeaderTitle.ConfirmTimeZone': 'タイムゾーンの確認',
    'MursionPortal.Message.DontHaveMursionInstalled': 'ML3 Desktop Appをインストールしていませんか？[<code>をダウンロード</code>]をクリックし、インストールします。',
    'MursionPortal.ErrorMessage.CannotContainCharacter': 'シナリオ名に「_」の文字を含めないでください。',
    'MursionPortal.ErrorMessage.EnterDifferentName': '{capitalized} {fieldName} は既に存在します。別の {objectName} {fieldName}を入力してください。',
    'MursionPortal.ErrorMessage.EnterDifferentEmail': 'ユーザーのEメールアドレスは既に存在します。別のEメールアドレスを入力してください。',
    'MursionPortal.ErrorMessage.NameExists': '同じ {fieldName} を持つユーザーが既に存在します。別の {fieldName}を入力してください。',
    'MursionPortal.ErrorMessage.UnableToRemove': '{entity}を削除できません。この {entity} は現在、有効な {depEntity}で使用されています。',
    'MursionPortal.ErrorMessage.OverlapsExistingTimeBlock': '選択した時間枠が既存の時間枠と重複しています。',
    'MursionPortal.ErrorMessage.AccessIsDenied': 'アクセスが拒否されています。',
    'MursionPortal.ErrorMessage.SomethingMustBeBroken': '何かが壊れています。後でもう一度お試しください。',
    'MursionPortal.ErrorMessage.Unauthorized': '未承認',
    'MursionPortal.ErrorMessage.RefreshTokenNotFound': '更新トークンが見つかりません',
    'MursionPortal.ErrorMessage.CannotRefreshToken': 'トークンを更新できません',
    'MursionPortal.QADialog.NoParticipants': '参加者なし',
    'MursionPortal.ErrorMessage.UsersUnavailable': '一部のユーザーが選択した時間に利用できません。',
    'Filters.SsoProtocolType': 'プロトコル：すべて',
    'Filters.SsoProtocolTypePlaceHolderHint': 'SSOプロトコルを検索',
    'Filters.SSO.PrivacyAll': '種類  すべて',
    'Filters.SSO.PrivacyPublic': '種類  公開',
    'Filters.SSO.PrivacyPrivate': '種類 プライベート',
    'Filters.SSO.PrivacyAriaLabel': 'ssoプライバシーの種類 フィルター',
    'Cleints.Modal.Label.DomainsTitle': 'サインインに使用できるドメインのリスト',
    'MursionPortal.NoDomains': 'ドメインなし',
    'MursionPortal.Header.Domains': 'ドメイン',
    'MursionPortal.AddDomain': 'ドメインの追加',
    'Clients.Modal.Projects.ButtonTitle.EditDomains': 'ドメインの編集',
    'Clients.Modal.Projects.DomainField.EnterDomain': 'ドメイン名を入力してください（例：example.com）',
    'Clients.Modal.Projects.DomainField.InvalidDomain': 'ドメイン形式が無効です',
    'MursionPortal.ErrorMessage.SomethingWrongWithResponse': '応答に問題があります。',
    'MursionPortal.ErrorMessage.AuthorizationHeaderIsEmpty': '承認ヘッダーが空です',
    'MursionPortal.ErrorMessage.CannotRefreshAccessToken': 'アクセストークンを更新できません',
    'MursionPortal.ErrorMessage.AuthorizationTokenIsEmpty': '承認トークンが空です',
    'MursionPortal.ErrorMessage.AuthenticationFailed': '認証に失敗しました。',
    'MursionPortal.ErrorMessage.UnsupportedResponseFormat': '応答形式がサポートされていません。',
    'MursionPortal.ErrorMessage.AccountBlocked': 'あなたのアカウントは {blockedDate}までブロックされています。後でログインしてください。',
    'MursionPortal.ErrorMessage.AttemptsRemaining': '残り{attempts} 回の試行の後、24時間のロックアウトになります。ヘルプが必要な場合は、パスワードをリセットしてください。',
    'MursionPortal.ErrorMessage.ClientIdMustBeSpecified': 'クライアントIDを指定してください',
    'MursionPortal.ErrorMessage.ProjectIdMustBeSpecified': 'プロジェクトIDを指定してください',
    'MursionPortal.ErrorMessage.SessionIdMustBeSpecified': 'セッションIDを指定してください',
    'MursionPortal.ErrorMessage.SSOIdMustBeSpecified': 'SSOのIDを指定してください',
    'MursionPortal.ErrorMessage.LearnerIdMustBeSpecified': '学習者IDを指定してください',
    'MursionPortal.ErrorMessage.UserIdMustBeSpecified': 'ユーザーIDを指定してください',
    'MursionPortal.ErrorMessage.TeamIdMustBeSpecified': 'チームIDを指定してください',
    'MursionPortal.ErrorMessage.AssetProjectIdMustBeSpecified': 'アセットプロジェクトIDを指定してください',
    'MursionPortal.ErrorMessage.AssetSettingsIdMustBeSpecified': 'アセット設定IDを指定してください',
    'MursionPortal.ErrorMessage.ScenarioIdMustBeSpecified': 'シナリオIDを指定してください',
    'MursionPortal.ErrorMessage.ScenarioTemplateIdMustBeSpecified': 'シナリオテンプレートIDを指定してください',
    'MursionPortal.ErrorMessage.LicenseIdMustBeSpecified': 'fetchUsersList：ライセンシーIDを指定してください',
    'MursionPortal.ErrorMessage.LicenseCertificateIdMustBeSpecified': 'fetchLicenseeCertificates：ライセンシーIDを指定してください',
    'MursionPortal.ErrorMessage.LicenseConfigIdMustBeSpecified': 'fetchConfig：ライセンシーIDを指定してください',
    'MursionPortal.ErrorMessage.LicenseUpdateConfigIdMustBeSpecified': 'updateConfig：ライセンシーIDを指定してください',
    'MursionPortal.ErrorMessage.LicenseTestConfigIdMustBeSpecified': 'testConfig：ライセンシーIDを指定してください',
    'MursionPortal.ErrorMessage.LicenseFetchTiersIdMustBeSpecified': 'fetchTiers：ライセンシーIDを指定してください',
    'MursionPortal.ErrorMessage.LicenseUpdateTiersIdMustBeSpecified': 'updateTiers：ライセンシーIDを指定してください',
    'MursionPortal.ErrorMessage.GlobalUserIdMustBeSpecified': 'グローバルユーザーIDを指定してください',
    'MursionPortal.ErrorMessage.ScenarioTemplateListLicenseIdMustBeSpecified': 'fetchScenarioTemplatesList：ライセンシーIDを指定してください',
    'MursionPortal.ErrorMessage.ScenarioAttachmentListIdMustBeSpecified': 'fetchScenarioAttachmentLink：シナリオIDと添付ファイルIDを指定してください',
    'MursionPortal.ErrorMessage.FetchScenarioIdMustBeSpecified': 'fetchScenario：シナリオIDを指定してください',
    'Projects.ProjectInfo.Checkbox.AllowRecordingInfo': 'これにより、現在保存されている録音は削除されません。',
    'MursionPortal.AriaLabel.ToggleMenu': 'トグルメニュー',
    'MursionPortal.ClientListItem.RedirectToUserDetails': '{name} の詳細にリダイレクト',
    'MursionPortal.AriaLabel.PreviousWeek': '先週',
    'MursionPortal.AriaLabel.PreviousDay': '前日',
    'MursionPortal.AriaLabel.NextWeek': '来週',
    'MursionPortal.AriaLabel.NextDay': '前日',
    'Dashboard.LeftPane.CategoryHeader.SessionsDashboard': 'セッションダッシュボード ',
    'MursionPortal.Title.SessionsPage': 'セッションページ',
    'Clients.heading.Clients': 'クライアント',
    'MursionPortal.AriaLabel.Client': 'クライアント',
    'MursionPortal.AriaLabel.SimSpecialist': 'SimSpecialist',
    'MursionPortal.Project.TimeZoneStartDate': 'タイムゾーン開始日 ',
    'MursionPortal.Project.TimeZoneEndDate': 'タイムゾーン終了日 ',
    'MursionPortal.AriaLabel.SelectTimezoneCombobox': 'タイムゾーンコンボボックスを選択 ',
    'MursionPortal.Support.PhoneNumber': '1-855-999-5818 ',
    'MursionPortal.Message.LearnerEmpty': 'セッションに参加した学習者を選択してください',
    'Dashboard.ProjectsTable.StatusValue.Active': 'アクティブ',
    'Dashboard.ProjectsTable.StatusValue.Completed': '完了',
    'Dashboard.ProjectsTable.StatusValue.Archived': 'アーカイブ済み',
    'MursionPortal.Button.Refresh': '更新',
    'MursionPortal.Prompt.CalendarUpdated': 'カレンダーが更新されました。',
    'MursionPortal.Prompt.ClickRefresh': '[更新]をクリックして変更を表示します。',
    'MursionPortal.Label.SessionAttendance': 'セッション出席',
    'MursionPortal.Label.SessionVideoUrl': 'セッションビデオURL',
    'MursionPortal.Label.LearnerAttendance': '学習者の出席',
    'MursionPortal.Text.UserProfileUpdated': 'ユーザープロファイルが更新されました！',
    'MursionPortal.Text.ClientUpdated': 'クライアントが更新されました！',
    'MursionPortal.Text.ProjectUpdated': 'プロジェクトが更新されました！',
    'MursionPortal.Text.ScenarioUpdated': 'シナリオが更新されました！',
    'MursionPortal.Text.LicenseeConfigUpdated': 'ライセンシーの構成が更新されました！',
    'MursionPortal.Prompt.TrainingScheduled': '<code>トレーニング</code> が予定されています！',
    'Settings.SSO.Table.ColumnHeader.ClientName': 'クライアント名',
    'MursionPortal.Text.UserCreatedSuccessfully': 'ユーザーの作成に成功しました',
    'MursionPortal.Text.UserNotCreated': 'ユーザーが作成されていません',
    'MursionPortal.Text.AssignedTeams': '割り当てチーム',
    'MursionPortal.Client.EmailSettings.Invitation': '招待',
    'MursionPortal.Client.EmailSettings.InvitationReminder': '招待状リマインダー',
    'MursionPortal.Client.EmailSettings.Confirmation': '確認',
    'MursionPortal.Client.EmailSettings.Cancellation': 'キャンセル',
    'MursionPortal.Client.EmailSettings.FirstReminder': '初回のリマインダー',
    'MursionPortal.Client.EmailSettings.SecondReminder': '２回目のリマインダー',
    'MursionPortal.Client.EmailSettings.SessionRecording': 'アカウント所有者/ファシリテーターのセッション記録の準備ができました',
    'MursionPortal.Client.EmailSettings.LearnerSession': '学習者のセッション記録の準備ができました',
    'MursionPortal.Client.EmailSettings.SimulationSessionVideoRejected': 'シミュレーションセッションのビデオ録画が拒否されました',
    'MursionPortal.Client.EmailSettings.SimulationSessionVideoDisallowed': 'シミュレーションセッションのビデオ録画は許可されません',
    'MursionPortal.Client.EmailSettings.SimulationSessionVideoRejectedUser': 'シミュレーションセッションのビデオ録画がユーザーに拒否されました',
    'MursionPortal.Client.EmailSettings.RequestRecieved': '受領確認リクエスト',
    'MursionPortal.Client.EmailSettings.RequestNotFullfilled': 'リクエストが満足されていません',
    'MursionPortal.Client.EmailSettings.RequestDateTime': 'リクエスト日時の変更が必要です',
    'MursionPortal.Client.EmailSettings.Title.SelectEmails': '学習者に送信するメールを選択します。Mursionは、すべての電子メールを有効のままにしておくことをお勧めします。',
    'MursionPortal.Client.EmailSettings.Header.Registration': '登録',
    'MursionPortal.Client.EmailSettings.Header.TrainingSession': 'トレーニングセッション',
    'MursionPortal.Client.EmailSettings.Header.Videos': '動画',
    'MursionPortal.Client.EmailSettings.Header.Requests': 'リクエスト',
    'MursionPortal.AriaLabel.ToggleButton': 'トグルボタン',
    'MursionPortal.Client.EmailSettings.Hint.RecordingEnabled': '記録が有効になっている場合のみ',
    'MursionPortal.Client.EmailSettings.Hint.RequestFeature': 'リクエスト機能が有効になっている場合のみ',
    'MursionPortal.SuccessDialog.Title': '成功ダイアログ',
    'MursionPortal.Dialog.CreateSSOConfiguartion.SuccessMessage': 'クライアントSSO構成が正常に作成されました。',
    'ITManager.TechRestriction.Table.EmptyMessage': '銀行の技術的制限は見つかりませんでした。',
    'ITManager.TechRestriction.Table.Tag': 'タグ',
    'ITManager.TechRestriction.Table.StartDate': '開始日',
    'ITManager.TechRestriction.Table.EndDate': '終了日',
    'ITManager.TechRestriction.EditDialog.EnterTag': 'タグ名を入力',
    'ITManager.TechRestriction.Table.HoverTitle': '技術的制限の表示/編集',
    'ITManager.TechRestriction.Table.NoTechRestrictionError': '技術的制限はまだありません',
    'MursionPortal.DateTimePicker.StartTime': '開始時間',
    'MursionPortal.DateTimePicker.EndTime': '終了時間',
    'MursionPortal.TechRestrictions': '技術的制限',
    'MursionPortal.Caption.ScenarioBankUsersList': 'シナリオ銀行ユーザーリスト',
    'MursionPortal.Caption.SsoConfigurationList': 'sso構成リスト',
    'MursionPortal.AriaLabel.DateRangeCalendarButton': 'Enterキーを押してカレンダーを操作し、開始日と終了日を選択します',
    'MursionPortal.Modal.Header.SessionTimeout': 'セッション タイムアウト',
    'MursionPortal.Modal.Body.SessionTimeout': 'Your session will timeout in 1 minute, please continue to extend your session', // TODO
    'MursionPortal.Status.PendingNoSS': '保留中（SSなし）',
    'MursionPortal.Status.PendingNoL': '保留中（Lなし）',
    'MursionPortal.Status.PendingNoLearners': '保留中（学習者なし）',
    'MursionPortal.Status.PendingNoLSS': '保留中（L/SSなし）',
    'Dashboard.LeftPane.SupplyManagement.SimView': 'Simビュー',
    'Dashboard.LeftPane.SupplyManagement.SchedulingView': 'スケジューリングビュー',
    'MursionPortal.Table.ColumnSelection.Button.Label': 'コラム',
    'Session.Table.Column.SubmitRequest': 'リクエストを送信',
    'Filters.SimSpecialistStatus': 'Sim Specialist',
    'Filters.SimSpecialistStatusPlaceHolderHint': 'Sim Specialistのステータスを検索する フィルター...',
    'Users.TableModal.SSOUserID': 'SSOユーザーID',
    'MursionPortal.UnavailableSlotsError.SorryText': 'こんにちは！申し訳ありません！',
    'MursionPortal.UnavailableSlotsError.BusyText': '他の学習者とのシミュレーションに忙しそうです。',
    'MursionPortal.UnavailableSlotsError.TryAgainText': '明日か別の日に、もう一度お試しください！',
    'MursionPortal.Session.Survey.ClickHere': 'ここをクリック',
    'MursionPortal.Session.SurveyNotLoading': 'アンケートが読み込まれませんか？',
    'Users.Table.Heading.Viewonly': '(閲覧のみ)',
    'MursionPortal.BookItUI.BusyText': '現在、他の学習者とのシミュレーションに忙しそうです。',
    'MursionPortal.BookItUI.TryLaterText': 'もっと多くのシミュレーションセッションがまもなく開始されます。本日中にもう一度お試しください。',
    'MursionPortal.BookItUI.MoreSimulationsText': 'もっと多くのシミュレーションセッションがまもなく開始されます。',
    'MursionPortal.BookItUI.ComeBackText': 'シミュレーションをしたい人は、また後で来てください。',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Archived': 'アーカイブユーザー',
    'ClientUserTable.AddNewUsers.UpdateArchivedUserWarning': '{archivedUsers}人のユーザーは現在アーカイブされています。続行すると、これらのユーザーが選択したチームに追加され、ユーザーのアーカイブが解除されます。',
    'ClientUserTable.AddNewUsers.UpdateExistingArchivedUserWarning': 'これらの{existingUsers}人のユーザーはすでに存在し、{archivedUsers}人のユーザーは現在アーカイブされています。続行すると、これらのユーザーが選択したチームに追加され、アーカイブされたユーザーのアーカイブも解除されます。',
    'MursionPortal.ProjectCreateStep.UpperLimitValidation.Hours': '{code}時間より大きくできません',
    'MursionPortal.ProjectCreateStep.UpcomingAvailability': '今後の空き状況',
    'MursionPortal.Table.ColumnHeader.TotalSession': '合計セッション',
    'MursionPortal.Table.ColumnHeader.ActiveCertifications': 'アクティブな認定資格',
    'MursionPortal.Button.Decline': '減少',
    'MursionPortal.Having.Issues.Logging.In': 'ログインに問題がありますか？',
    'MursionPortal.Label.Call': '電話：',
    'MursionPortal.Project.Hours': '時間',
    'Users.Button.ProfService': 'プロフェッショナルサービス',
    'Users.Button.Operations': 'オペレーションサービス',
    'Users.Button.SimDesigner': 'Sim Designer',
    'MursionPortal.ArtProject.Placeholder.EnterOrSelect': '選択または入力',
    'MursionPortal.Setting.LearnerCommunicationPersonalization': '学習者コミュニケーションのパーソナライゼーション',
    'MursionPortal.LearnerCommunicationPersonalization.Heading': 'ここでは、学習者のために特定のメッセージをカスタマイズすることができます。',
    'MursionPortal.SupplyBasedScheduling.SubmitPage.SimulationConfirmation': 'シミュレーションを確認しました！開始時間の数分前に入ってください。',
    'MursionPortal.DemandBasedScheduling.SubmitPage.LearnerConfirmed': '{learners} のシミュレーションを確認しました！',
    'MursionPortal.DemandBasedScheduling.SubmitPage.DateAndTime': '日時',
    'MursionPortal.Personalized.LearnerDashboard.Section': '学習者ダッシュボードですべての学習者に表示させたいメッセージ',
    'MursionPortal.Personalized.SchedulingPage.Section': 'スケジューリングページですべての学習者に表示させたいメッセージ',
    'MursionPortal.Personalized.Section.Tooltip': 'メッセージはすべての場所で更新される',
    'MursionPortal.CheckboxLabel.JoinNow': '今すぐ参加する',
    'MursionPortal.ProjectCreateStep.UpcomingAvailability.Tooltip': 'クライアントのロールは、\'Simの利用可能性\' 後の指定された\'今後の利用可能性\'の時間にわたり無効スロットに表示されます。\'今後の利用可能性\' が0なら、無効なスロットは表示されず、 \'Simの利用可能性\' スロッ トだけが表示されます。 \'Simの利用可能性\' が24時間なら、\'Simの利用可能性\' の時間以降に利用可能なスロッ トはすべて無効スロットに表示されます。',
    'MursionPortal.LearnerDashboard.JoinNow.OutsideOfficeHoursText': 'このシナリオを利用するには、後日、指導教官の就労時間中に来てください。',
    'MursionPortal.Setting.Personalization.LastUpdated': '最終更新日',
    'MursionPortal.CheckboxLabel.JoinNowRequests': '今すぐ参加するリクエスト',
    'MursionPortal.LearnerDashboard.JoinNow.HeaderText': '<code>ライブシミュレーションのため</code>、アバターとマッチングします。下記の資料を確認のうえ、準備してください。',
    'MursionPortal.LearnerDashboard.JoinNow.SorryText': '申し訳ありません！',
    'MursionPortal.LearnerDashboard.JoinNow.AvatarsBusyText': 'すべてのアバターが他の学習者で忙しくしています。また後日お越しいただくか、数分後に再度お試しください。',
    'MursionPortal.LearnerDashboard.JoinNow.CongratsText': 'おめでとうございます！あなたはアバターとマッチングされました！',
    'MursionPortal.LearnerDashboard.JoinNow.AvatarsMatchText': 'アバターがあなたを待っています！準備ができたら「ライブシミュレーションに参加する」をクリックするか、カウントダウン後に自動的に体験が始まります。',
    'MursionPortal.LearnerDashboard.JoinNow.StartLearning': 'If you are not able to get connected, click below to start learning.',
    'MursionPortal.LearnerDashboard.JoinNow.StarBucks.MyLearning': 'Starbucks MyLearning',
    'MursionPortal.LearnerDashboard.JoinNow.StarBucks.LiveSimulationExp': 'This session is a live simulation experience. Partners can now choose to take an eLearning on de-escalating conflict in MyLearning, as an alternative to the live safety simulation.',
    'MursionPortal.Button.Leave': '出発',
    'MursionPortal.Personalized.ScenarioPage.Section': 'シナリオページで学習者に見せたいメッセージ',
    'MursionPortal.Personalized.CreateNewScenario.Button.Label': '特定のシナリオ用に新規作成',
    'MursionPortal.Personalized.Scenario.Search.Placeholder': 'シナリオを検索または選択する：',
    'MursionPortal.Personalized.Scenario.Selection.Title': '新しい特定シナリオの追加',
    'MursionPortal.Personalized.NoMessage': '現在、メッセージは設定されていない',
    'MursionPortal.Personalized.MetaData.NoHistory': 'まだ履歴はない。',
    'MursionPortal.JoinNow.AlreadyBooked.Message': '{code}は現在セッションを予約しています。',
    'Users.Table.SendInvite.TooltipText': '登録メールを無効にすると、ポータルの「クリックで招待」ボタンも無効になります',
    'MursionPortal.JoinNow.AlreadyBooked.JoinSession.Message': 'セッションに参加または再開するには、「ライブシミュレーションに参加する」をクリックします。',
    'Dashboard.LeftPane.CategoryHeader.Scenarios': 'シナリオ',
    'Dashboard.LeftPane.Learners.TeamAndProjectActivity': 'チーム＆プロジェクト活動',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.NotYetScheduled': 'スケジュールなし',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.Scheduled': '未完成',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.LearnerCompleted': '完成',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.NotYetAssigned': '未割当',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.NotYetScheduled': '少なくとも 1 つのチームに割り当てられており、少なくとも 1 つのシナリオに割り当てられているが、 セッションをスケジュールしたことがない学習者の数。',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.Scheduled': '少なくとも1回のセッションをスケジュールを入れているが、まだ出席しておらず、少なくとも1回のセッションを完了していない学習者の数。',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.CompletedAssigned': '少なくとも1回のセッションを完了した学習者の数。',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.NotYetAssigned': 'チームに割り当てられていない学習者、またはどのシナリオにも割り当てられていないチームの一員である学習者の数。',
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerStatus': '学習者の修了',
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerSequence': '一意の学習者',
    'MursionPortal.Dashboard.ScenarioLearner.Header.SequenceCount': '（セッションの最大数{sessionCount}）。',
    'Dashboard.SequenceOrder.Header.Title': '欠席、遅発キャンセル、遅発スケジュール変更アクティビティ',
    'MursionPortal.Integrations': '統合',
    'MursionPortal.Personalized.Message.Delete.Title': '本当にメッセージを削除しますか？',
    'MursionPortal.Personalized.Message.Delete.Tooltip': 'メッセージの削除',
    'MursionPortal.Personalized.Scenario.Message.OnlyOneAtTime': '一度に変更できるのは1つのメッセージのみです。すでに編集モードになっているものは保存するか、キャンセルしてください。',
    'MursionPortal.Error.Time': '午前6時～午後10時（東部標準時',
    'MursionPortal.CheckboxLabel.JoinNow.Tooltip.Text': 'BookItによるスケジューリングは、「今すぐ参加する」が有効な場合に常に有効になります。',
    'MursionPortal.JoinNow.FinalizedScenario.Message': 'シナリオは確定していますので、シナリオがアクティブになったらまた来てください。',
    'MursionPortal.CheckboxLabel.sessionMissedTime': 'セッション欠席時間',
    'MursionPortal.Title.Seconds': 'セカンド',
    'MursionPortal.RequestTable.Accept.ErrorMessage': 'セッションはキャンセルとなりました。',
    'MursionPortal.Client.EmailTypeFilter.SchedulingLocked': 'スケジューリングがロックされています',
    'MursionPortal.Client.EmailTypeFilter.SchedulingUnlocked': 'スケジューリングがロックされていません',
    'MursionPortal.ScenarioInfo.SchedulingLockout.Time': 'ロックアウトのスケジューリング',
    'MursionPortal.Table.ColumnHeader.SimAvailable': 'Sim利用可能',
    'MursionPortal.CheckboxLabel.sessionMissedTime.Tooltip.Text': '学習者または SIM が参加しなかった場合、セッションが「欠席」とマークされるまでの時間。',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.NotYetCompleted': '未完成',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.completedOneSession': '1セッション終了',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.completedTwoPlusSession': '２+セッションを完了',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.OneSession': '1セッション',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.TwoSession': '2セッション',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.ThreeSession': '3セッション',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.FourPlusSession': '4+セッション',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.NotYetCompleted': 'このシナリオに割り当てられたが、まだ完了していない一意の学習者数',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.completedOneSession': 'このシナリオに割り当てられた学習者のうち、一度でもこのシナリオを完了した人の数。',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.completedTwoPlusSession': 'このシナリオを2回以上完了した一意の学習者数。',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.OneSession': '1回セッションを欠席、遅発キャンセル、遅発再スケジュールした一意の学習者数。',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.TwoSession': '2回セッションを欠席、遅発キャンセル、遅発再スケジュールした一意の学習者数。',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.ThreeSession': '3回セッションを欠席、遅発キャンセル、遅発再スケジュールした一意の学習者数。',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.FourPlusSession': '4回以上のセッションを欠席、遅発キャンセル、遅発再スケジュールした一意の学習者数。',
    'MursionPortal.ScenarioBank.ItemBank.Label': 'ビルディングブロック',
    'MursionPortal.ScenarioBank.ItemBank.Skills': 'スキル',
    'MursionPortal.ScenarioBank.ItemBank.Domains': 'ドメイン',
    'MursionPortal.ScenarioBank.ItemBank.Events': 'イベント',
    'MursionPortal.ScenarioBank.ItemBank.Pathways': '経路',
    'MursionPortal.ScenarioBank.ItemBank.Resources': 'リソース',
    'MursionPortal.ScenarioBank.ItemBank.Outcomes': '成果',
    'MursionPortal.ScenarioBank.ItemBank.Mindsets': 'マインドセット',
    'MursionPortal.ScenarioBank.ItemBank.Avatars': 'アバター',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummaries': '調査概要',
    'MursionPortal.ScenarioBank.ItemBank.LearnerInstructions': '学習者の指示',
    'MursionPortal.ScenarioBank.ItemBank.Column.Level': 'レベル',
    'MursionPortal.ScenarioBank.ItemBank.Column.Domain': 'ドメイン',
    'MursionPortal.ScenarioBank.ItemBank.Column.Translations': '翻訳',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Skill': 'クリエイトスキル',
    'MursionPortal.Dashboard.Label.ScenarioActivity.ErrorMessage': 'フィルターから少なくとも1つのプロジェクトとシナリオを選択してください。',
    'MursionPortal.Learner.Onboarding': '学習者のオンボーディング',
    'MursionPortal.Tab.Onboarding': 'オンボーディング',
    'MursionPortal.OrganizationName.Placeholder': '組織名を入力する',
    'Dashboard.LeftPane.Learners.LearnerLockedOut': '学習者のロックアウト',
    'Dashboard.Learners.LearnerLockedOut.TableHeader': '欠席/キャンセルによりスケジューリングからロックされた学習者の割合',
    'Dashboard.Learners.LearnerLockedOut.LockedOutColumn': 'スケジューリングのためにロックされた日時',
    'Dashboard.Learners.LearnerLockedOut.AutoReleaseColumn': '自動リリースまでの日時',
    'MursionPortal.DontHaveEmail.Text': '組織のメールアドレスをお持ちでないですか？',
    'MursionPortal.InvalidCompanyName.Error': '組織では、ユーザーがメールアドレスを使用してログインする必要があります。上記に入力してください。',
    'MursionPortal.LearnerOnboardingPage.Text.Welcome': 'ようこそ',
    'MursionPortal.LearnerOnboardingPage.Text.MursionVideoOverview': 'Mursionビデオの概要',
    'MursionPortal.LearnerOnboardingPage.Text.KeyThingsToKnow': '知っておくべきこと',
    'MursionPortal.LearnerOnboardingPage.Welcome.Section.Text': 'Mursionの学習プログラムへようこそ！Mursionは、Human-in-the-Loopテクノロジーによる没入型シミュレーションを使用して、進化し続ける世界で成功するために必要なツールやテクニックを習得できる総合的な学習体験を提供します。',
    'MursionPortal.LearnerOnboardingPage.Welcome.Section.Beginner.Text': '私たちのプログラムは、初心者の方でも、経験豊富なプロの方でも、楽しんでいただけます。プログラムを受講していただいてとても嬉しく思います。',
    'MursionPortal.LearnerOnboardingPage.Video.Section.Text': 'まずは、<strong>Mursionの概要</strong>のビデオをご覧ください。',
    'MursionPortal.LearnerOnboardingPage.Scheduled.Section.Text': '<strong>これで準備は整いました：</strong>最初のシミュレーションのスケジュールを入れましょう！',
    'MursionPortal.LearnerOnboardingPage.keysThingsToKnow.Section.Heading': '次に、知っておくべき重要なことを説明します。',
    'MursionPortal.LearnerOnboardingPage.Mursion.Methodology': 'Mursionの方法論',
    'MursionPortal.LearnerOnboardingPage.Mursion.Methodology.Summary': 'Mursion は調査に基づき、行動の変化を促すことが実証されています。当社のアバターベースのシミュレーションは、感情的になりやすい状況での適切な対応を学ぶのに役立ちます。',
    'MursionPortal.LearnerOnboardingPage.Mursion.WhyAvatars.Text': 'なぜアバターなのか',
    'MursionPortal.LearnerOnboardingPage.Mursion.WhyAvatars.Summary': 'Mursionは、従来の方法やロールプレイよりも効果的なツールです。調査によると、同僚や上司と接するよりもアバターと接する方が、より信頼され、好奇心をそそられるようです。',
    'MursionPortal.LearnerOnboardingPage.Human.Text': 'ループの中の人間',
    'MursionPortal.LearnerOnboardingPage.Human.Summary': 'Mursionのシミュレーションでは、 Simulation Specialistがライブで、各シミュレーションに登場する5体ものバーチャルアバターの台詞と動きを担当します。',
    'MursionPortal.LearnerOnboardingPage.Practice.Text': '練習が重要な理由',
    'MursionPortal.LearnerOnboardingPage.Practice.Text.Summary': 'リーダーシップスキルの向上は簡単なことではありません。ただし調査によると、学習は実習を含むときに最も大きな影響があるということです。Mursionでは 、安 全 かつダイナミックで 、アクセスしやすいスペースを設けることで 、実習を優先しています 。',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Domain': 'ドメインの作成',
    'MursionPortal.ScenarioBank.ItemBank.Level.L1': 'L1',
    'MursionPortal.ScenarioBank.ItemBank.Level.L2': 'L2',
    'MursionPortal.ScenarioBank.ItemBank.Level.L3': 'L3',
    'MursionPortal.Label.OrganizationName': '組織名',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Pathway': '経路の作成',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Event': 'イベントの作成',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Resource': 'リソースの作成',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Outcome': '成果の作成',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Mindset': 'マインドセットの作成',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Avatar': 'アバター名の作成',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.ResearchSummary': '調査概要の作成',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.LearnerInstruction': '学習者向け指導の作成',
    'MursionPortal.ScenarioBank.ItemBank.BackButton.Label': 'シナリオバンクに戻る',
    'MursionPortal.ScenarioBank.ItemBank.Title.Placeholder': 'ここにタイプする',
    'MursionPortal.Dashboard.NextOnProject.Footer.MissOrCancelationRateFulfilled.Text': 'You cannot schedule this simulation as you have reached the missed or cancellation limit set by your organization. Please reach out to your program admin to allow scheduling.',// TODO add translations
    'MursionPortal.ScenarioInfo.Show': '表示',
    'MursionPortal.ScenarioInfo.CertificationHistory': '認定の履歴',
    'MursionPortal.Session.AlreadyBooked': '申し訳ありませんが、セッションはすでに他のSIMによって受け付けられています。',
    'MursionPortal.CustomizeInvitation.Title': '招待状のカスタマイズ',
    'MursionPortal.CustomizeInvitation.SubTitle': 'カスタマイズするには、以下の各フィールドのテキストを上書きするか、デフォルトのテキストのままにしてください。',
    'MursionPortal.CustomizeInvitation.IntroMessage': '学習者へのイントロメッセージ（このメッセージの下に利用可能なシナリオが自動入力されます）：',
    'MursionPortal.CustomizeInvitation.Label.SenderName': '送信者名（メールアドレスはnoreply@mursion.com。）',
    'MursionPortal.CustomizeInvitation.Label.Subject': '件名',
    'MursionPortal.InviteLearnersTeam.Section.Title': 'どのチームをスケジュールに招待したいですか？',
    'MursionPortal.InviteLearnersTeam.Section.SubTitle': '学習者は、このスケジュールする資格があるこのプロジェクトのすべてのシナリオに招待されます。',
    'MursionPortal.InviteLearnersTeam.ScenariosAssigned.Text': '割り当てられたシナリオ',
    'MursionPortal.Confirmation.Modal.Invitations.Text': '招待状はスケジュール済みです！',
    'MursionPortal.ScenarioSetting.ValidationMessage': 'クライアントおよび/またはプロジェクトレベルで同じ設定以下でなければなりません。',
    'MursionPortal.ScenarioBank.EventDetail.Details': '詳細',
    'MursionPortal.ScenarioBank.CharacterLimit.TitleMessage': '文字数制限：{titleMaxLength}。',
    'MursionPortal.ScenarioBank.CharacterLimit.DescriptionMessage': '文字数制限：{descriptionMaxLength}。',
    'MursionPortal.ScenarioBank': 'イベントテンプレート',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header': '他の人が自分の仕事に関する情報を共有することに自信を持ち、信頼できるように感化する',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title': 'ドメイン詳細',
    'MursionPortal.ScenarioBank.ItemBank.Edit.DescriptionOfUpdate.Label': 'これらの変更を保存すると、すべてのシナリオでこの{type}が更新されます。変更の簡単な説明を追加してください：',
    'Dashboard.Learners.LearnerLockedOut.LearnerColumn.Tooltip': 'ユーザー名またはメール',
    'MursionPortal.ScenarioInfo.CertificationHistory.Column.Certified': '認定済み',
    'MursionPortal.ScenarioInfo.CertificationHistory.Column.Decertified': '認定なし',
    'MursionPortal.ScenarioInfo.CertificationHistory.Column.Inactive': '非アクティブ',
    'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease': 'スケジュールのために学習者を解放する',
    'MursionPortal.CustomizeInvitation.Sender.Name': 'Mursion',
    'MursionPortal.CustomizeInvitation.Subject.Title': 'スキルを練習する準備をしましょう！Mursionセッションをスケジュールする準備ができました。',
    'MursionPortal.CustomizeInvitation.Intro.Message': 'Mursionのシミュレーション体験にご招待します。以下をクリックしてセッションをスケジュールするか、ダッシュボードにアクセスして探索してください。',
    'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease.ConfirmationText': '{code}名の学習者をスケジュールのために解放してもよろしいですか？',
    'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease.SuccessText': '{code}名の学習者がスケジュールのために解放されました。',
    'Dashboard.Learners.LearnerLockedOut.LockedOutColumn.Tooltip': '学習者がスケジュールにロックされた日時',
    'Dashboard.Learners.LearnerLockedOut.AutoReleaseColumn.Tooltip': '学習者が自動的に解放される日時',
    'MursionPortal.ScenarioBank.ItemBank.NextGenScenario': 'NextGenシナリオ',
    'MursionPortal.ScenarioBank.ResourceFilter.Book': '書籍',
    'MursionPortal.ScenarioBank.ResourceFilter.Article': '記事',
    'MursionPortal.ScenarioBank.Filter.Label.Publisher': '出版社',
    'MursionPortal.ScenarioBank.ItemBank.Level.Placeholder': 'レベルを選択',
    'MursionPortal.ScenarioBank.ItemBank.Domain.Placeholder': 'ドメインを検索または選択：',
    'MursionPortal.Label.CompletionRate.Tooltip': '完了率とは、受講者がスケジュールできなくなる（ロックアウトされる）までに、何回シミュレーションを完了できるかということです。',
    'MursionPortal.Label.SchedulingRate.Tooltip': 'スケジューリング率とは、学習者がシミュレーションのスケジュールができなくなる（ロックアウトされる）までに、何回スケジュールできるかということです。',
    'MursionPortal.Label.MissCancellationRate.Tooltip': 'ミス/キャンセル率とは、受講者がスケジュールを組むことができなくなる（ロックアウトされる）までに、シミュレーションを欠席するかキャンセルできる回数のことです。',
    'MursionPortal.ScenarioInfo.SchedulingLockout.Time.Tooltip': 'スケジュールロックアウト時間は、スケジュールのロックアウトが何時間継続するかを示します。',
    'MursionPortal.ScenarioBank.ItemBank.Mindset.Type.Derailing': '脱線',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Skill': 'スキルの詳細',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Mindset': 'マインドセットの詳細',
    'MursionPortal.ProjectCreate.DeliveryHours': '配達時間',
    'MursionPortal.ProjectCreate.DeliveryHours.Tooltip': '学習者にインスタントスロットを表示する時間枠',
    'MursionPortal.ProjectCreate.DeliveryHours.Start': '提供時間開始',
    'MursionPortal.ProjectCreate.DeliveryHours.End': '提供時間終了',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.LearnerInstruction': '学習者の指導内容',
    'MursionPortal.ScenarioBank.ItemBank.Avatar.Edit.Header.Title': 'アバターの詳細',
    'MursionPortal.ScenarioBank.Label.AddLink': 'リンクの追加',
    'MursionPortal.ScenarioBank.Year.placeholderText': '年',
    'MursionPortal.ScenarioBank.ItemBank.Resource.Edit.Header.Title': 'リソースの詳細',
    'MursionPortal.ScenarioBank.ItemBank.Outcome.Edit.Header.Title': '成果の詳細',
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.Advanced': '上級',
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.Intermediate': '中級',
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.Foundational': '基礎',
    'MursionPortal.ScenarioBank.ItemBank.Skill': 'スキル',
    'MursionPortal.Resources.Search.Text': 'リソースを検索または選択する',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummaries.Details': '調査概要の詳細',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummary': '調査概要',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Pathway': '経路の詳細',
    'MursionPortal.ScenarioBank.ItemBank.Pathway': '経路',
    'MursionPortal.ScenarioBank.ConfirmationText.Archive': '本当にこの{block}をアーカイブにしますか？',
    'MursionPortal.ScenarioBank.ConfirmationText.Unarchived': '本当にこの{block}のアーカイブを解除しますか？',
    'MursionPortal.ScenarioBank.Unarchived': 'アーカイブ解除',
    'MursionPortal.ScenarioBank.ItemBank.Resource': 'リソース',
    'MursionPortal.ScenarioBank.ItemBank.Mindset': 'マインドセット',
    'MursionPortal.ScenarioBank.ItemBank.LearnerInstruction': '学習者の指示',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Event': 'イベントの詳細',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives': 'アバターの視点',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives.Intro': 'イントロ',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives.DiscProfile': 'Discプロフィール',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives.VerbalStyle': '言葉のスタイル',
    'MursionPortal.ScenarioBank.Content': '内容',
    'MursionPortal.ScenarioBank.Content.ScenarioTitle': 'シナリオタイトル',
    'MursionPortal.ScenarioBank.Content.ScenarioCoverStory': 'シナリオカバーストーリー',
    'MursionPortal.ScenarioBank.ItemBank.Outcome.Placeholder': '銀行の結果を選択する',
    'MursionPortal.ScenarioBank.Content.WhyThisMatters': 'なぜこれが重要なのか',
    'MursionPortal.ScenarioBank.NextGen.AvatarGuide': 'アバターガイド',
    'MursionPortal.ScenarioBank.NextGen.ViewScenario': 'シナリオを見る',
    'MursionPortal.ScenarioBank.NextGen.Event': 'イベントアイコン',
    'MursionPortal.NextOnProject.Card.JoinNow': 'ライブシミュレーションに参加する',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Title': '仕組み',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Intro': 'こんにちは！あなたはもうすぐアバターとマッチングされ、<code>ライブシミュレーションに参加することになります</code>。',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Matched': 'アバターとマッチングしたら、<code>’ライブシミュレーションに参加する’</code>をクリックします。',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Allow': 'プロンプトが表示されたら、<code>’許可する’</code>をクリックし、マイク/カメラへのアクセスを許可します。',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.StartSession': '<code>’セッション開始’</code>をクリックし、アバターがシミュレーションを開始するのを待ちます。',
    'MursionPortal.ScenarioBank.NextGen.OutOfSite.Label': '現場から離れた場所で、トップオブマインド',
    'MursionPortal.FirstGen.Scenario.Button.Text': '第1 Gen',
    'MursionPortal.NextGen.Scenario.Button.Text': '第2世代',
    'MursionPortal.NextGen.Scenario.Assessment': '評価',
    'MursionPortal.NextGen.Scenario.OutOfSite': '現場から離れた場所で、トップオブマインド',
    'MursionPortal.NextGen.Scenario.Synopsis': '概要',
    'MursionPortal.NextGen.Scenario.MinimumPoints': '合格に必要な最低ポイント',
    'MursionPortal.NextGen.Scenario.Successful': 't成功',
    'MursionPortal.NextGen.Scenario.OpportunityDevelop': 'さらなる発展の機会',
    'MursionPortal.NextGen.Scenario.LibraryInfo': 'ライブラリー情報',
    'MursionPortal.NextGen.Scenario.LibraryInfo.DifficultyLevel': '難易度',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ScenarioCoverImage': 'シナリオ表紙画像',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ScenarioThumbnailImage': 'シナリオのサムネイル画像',
    'MursionPortal.NextGen.Scenario.LibraryInfo.CoverImageDescription': '表紙画像の説明',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ThumbnailImageDescription': 'サムネイル画像の説明',
    'MursionPortal.ScenarioBank.CharacterLimit.ResponseMessage': '文字数制限：{responseMaxLength}。',
    'MursionPortal.ScenarioBank.EventDetail.AvatarResponseTitle': 'アバター 可能な応答',
    'MursionPortal.NextGen.Scenario.ReflectionGuide': '復習ガイド',
    'MursionPortal.NextGen.Scenario.Scoring': '採点',
    'MursionPortal.ErrorMessage.buildingBlock': 'ビルディングブロックのidを指定する必要があります。',
    'MursionPortal.NextGen.Scenario.LibraryInfo.DragAndDropText': 'ここにファイルをドラッグ＆ドロップするか、クリックしてファイルを選択してください。',
    'MursionPortal.NextGen.Scenario.Publish': '発行',
    'MursionPortal.NextGen.Scenario.Publish.Label': '発行するには必須項目(*)に入力してください。',
    'MursionPortal.NextGen.Scenario.Last.Publish.Label': '最終発行日{publishDate}は{publishedBy}である。',
    'MursionPortal.EulaAgreement.CodeOfConductCheckBox.Text': '私は、Mursionのシミュレーション提供モデルに同意し、参加するすべてのシミュレーションにおいてMursionの行動規範を守り、尊重することを確認します。Mursionのシミュレーションと行動規範について、<code>こちら</code>をご覧ください。',
    'MursionPortal.JoinNow.Cancellation.FirstOption.Text': '今は時間がありません。',
    'MursionPortal.JoinNow.Cancellation.SecondOption.Text': 'これが何かわかりません',
    'MursionPortal.JoinNow.Cancellation.ThirdOption.Text': '後で戻ってきます',
    'MursionPortal.JoinNow.Cancellation.FirstOption.Description.Text': 'このシミュレーションを行ったほとんどの人が、貴重な時間の使い方だと感じています。所要時間は30分以内です。',
    'MursionPortal.JoinNow.Cancellation.SecondOption.Description.Text': 'ビデオの代わりに、このインタラクティブな練習は、安全でダイナミックな空間でコンピテンシーとスキルを身につけるのに役立ちます。',
    'MursionPortal.JoinNow.Cancellation.ThirdOption.Description.Text': 'もちろんです！組織の設定時間内であれば、いつでもライブシミュレーションに参加し、スキルを練習することができます！',
    'MursionPortal.JoinNow.CancellationText.Heading': 'なぜキャンセルしたいのですか？',
    'MursionPortal.JoinNow.CancellationPage.Title': 'アバターとの練習機会！',
    'MursionPortal.JoinNow.CancellationPage.Text': 'Starbucksのポリシーとガイドラインの範囲内で状況を解決するポジティブな方法を特定し、すべてのお客様のためにベストモーメントを生み出す機会を探す。',
    'MursionPortal.JoinNow.JoinSimulation.Button.Text': 'シミュレーションに参加する',
    'MursionPortal.LearnerDashboard.JoinNow.SearchingAvatar': 'ライブシミュレーション用のアバター<code>を探しています！</code>。',
    'MursionPortal.LearnerDashboard.JoinNow.SuccessText': '<code>おめでとう</code>、あなたはライブシミュレーション用のアバターとマッチングされました！',
    'MursionPortal.LearnerDashboard.JoinNow.ConnectingText': 'まもなくライブ・シミュレーションが始まります！',
    'MursionPortal.LearnerDashboard.JoinNow.DoNotCloseText': 'このブラウザウィンドウを閉じないでください！',
    'MursionPortal.LearnerDashboard.JoinNow.StrategiesToUse': '活用のための戦略',
    'MursionPortal.LearnerDashboard.JoinNow.PracticeOpportunityText': 'アバターとの練習機会！',
    'MursionPortal.Text.Sorry': '申し訳ありません',
    'MursionPortal.Text.Matching': 'マッチング',
    'MursionPortal.Text.Connecting': '接続中',
    'MursionPortal.JoinNowScenarioInfoCard.Text': '後ほど戻ってくるか、数分後にもう一度お試しください！',
    'MursionPortal.JoinNowScenarioInfoCard.HeaderText': '私たちのアバターは皆、他の学習者の相手で忙しくしています！',
    'MursionPortal.JoinNow.CancellationPage.GoBackButton.Text': 'キャンセルしてダッシュボードに戻る',
    'MursionPortal.NextGen.Scenario.Event.AddMessage': '最低2つ追加',
    'MursionPortal.ScenarioBank.ItemBank.Events.AddButton': '+追加',
    'MursionPortal.NextGen.ScenarioBank.Event.Select.Placeholder': 'イベントを選択',
    'MursionPortal.NextGen.ScenarioBank.Skill.Select.Placeholder': 'スキルを選択',
    'MursionPortal.NextGen.ScenarioBank.Event.AvatarBehavior.Label': 'アバターの行動*',
    'MursionPortal.NextGen.ScenarioBank.Event.LearnerResponse.Label': '期待される学習者の応答',
    'MursionPortal.NextGen.ScenarioBank.Event.AvatarAdd.Button': '応答の追加',
    'MursionPortal.NextGen.ScenarioBank.AvatarResponseName.Select.Placeholder': 'アバターの選択',
    'MursionPortal.NextGen.ScenarioBank.Label.AvatarPossibleResponse': 'アバター 可能な応答',
    'MursionPortal.NextGen.ScenarioBank.Event.AssessmentObservedAction.Label': '観察された行動を記述する',
    'MursionPortal.NextGen.ScenarioBank.Event.AssessmentImapct.Label': '影響を説明する',
    'MursionPortal.NextGen.ScenarioBank.StrengthAssessment': 'ストレングス評価',
    'MursionPortal.NextGen.ScenarioBank.Button.StrengthAssessment': '+ 強さの評価を加える',
    'MursionPortal.NextGen.ScenarioBank.Label.Strength': '強さ',
    'MursionPortal.NextGen.ScenarioBank.OpportunityAssessment': '機会評価',
    'MursionPortal.NextGen.ScenarioBank.Button.OpportunityAssessment': '+ 機会評価の追加',
    'MursionPortal.NextGen.ScenarioBank.Label.Opportunity': 'チャンス',
    'MursionPortal.NextGen.Scenario.Event.Mindset.Select.Placeholder': 'ポジティブなマインドセットを選択',
    'MursionPortal.NextGen.Scenario.Event.Derailing.Select.Placeholder': '脱線するマインドセットを選択',
    'MursionPortal.NextGen.Scenario.Event.Scoring.Title': 'イベント採点',
    'MursionPortal.NextGen.Scenario.Event.Mindset.Title': 'ポジティブ・マインドセットと脱線マインドセット*の追加',
    'MursionPortal.NextGen.Scenario.Event.Positive.Button.Text': 'ポジティブ・マインドセットの追加',
    'MursionPortal.NextGen.Scenario.Event.Derailing.Button.Text': '脱線するマインドセットの追加',
    'MursionPortal.Text.Searching': '検索',
    'MursionPortal.NextGen.Scenario.Event.MindsetsNavigated': 'マインドセットの移動',
    'Dashboard.ContractProgress.Column.RemainingUtilization': '残りの利用状況',
    'Dashboard.ContractProgress.Column.EstimatedRemaining': '予想される残り時間',
    'MursionPortal.Label.Completion': '完了率',
    'Dashboard.Report.ReportSessionOption.AllDays': '全セッション',
    'MursionPortal.Status.Abbreviation.EarlyCancelled': 'EC',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.EstimatedRemaining': '予想される残り時間',
    'Dashboard.ContractProgress.ColumnTooltip.RemainingUtilization': '購入されたシミュレーションの残りのシミュレーションおよびスケジュールされたシミュレーション',
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdownQuickView': 'セッションの内訳 - クイックビュー',
    'MursionPortal.NextGen.SelectorText.Race': 'レース',
    'MursionPortal.NextGen.SelectorText.Ethnicity': '民族',
    'MursionPortal.NextGen.SelectorText.None': 'なし',
    'MursionPortal.NextGen.SelectorText.Woman': '女性であること',
    'MursionPortal.NextGen.SelectorText.Man': '男性であること',
    'MursionPortal.NextGen.SelectorText.Wheelchair': '車椅子に乗っていること',
    'MursionPortal.NextGen.SelectorText.agedText': '55歳以上であること',
    'MursionPortal.NextGen.Avatar.Restriction': 'アバター制限',
    'MursionPortal.NextGen.AvatarOrScenarioComposition': 'アバター／シナリオ構成',
    'MursionPortal.Label.Opening': 'オープニング',
    'MursionPortal.Label.Closing': 'クロージング',
    'MursionPortal.Label.Introduction': 'はじめに',
    'MursionPortal.ReflectionGuide.LearnerAssessment': '学習者の評価',
    'MursionPortal.ReflectionGuide.HostAssessment': 'ホストの評価',
    'MursionPortal.ReflectionGuide.HostOpportunity': 'ホストの機会',
    'MursionPortal.ReflectionGuide.Introduction.Text': 'おかえりなさい！自分の経験を振り返る準備はできましたか？(受講者に同意してもらってから続ける）',
    'MursionPortal.Label.Say': '言うこと',
    'MursionPortal.Label.Ask': '聞くこと',
    'MursionPortal.Label.Bullet': '箇条書き',
    'MursionPortal.ReflectionGuide.LearnerPreparation.FirstQuestion': '会話はどうでしたか？',
    'MursionPortal.ReflectionGuide.LearnerPreparation.SecondQuestion': '現実の会話は、あなたの会話プランとどのようにマッピングされましたか？',
    'MursionPortal.ReflectionGuide.LearnerPreparation.ThirdQuestion': '何が起こりましたか？',
    'MursionPortal.ReflectionGuide.LearnerPreparation.FourthQuestion': 'アバターからの合図（言語的、非言語的）で、会話がどのように進んでいくかについてヒントはありましたか？',
    'MursionPortal.ReflectionGuide.LearnerPreparation.FifthQuestion': 'アバターからの合図（言語的、非言語的）で、会話がどのように進んでいくかについてヒントはありましたか？',
    'MursionPortal.ReflectionGuide.LearnerAssessment.FirstSay': 'この会話では、いくつかの困難なダイナミックスを経験する必要がありました。Mickeyはイライラしていました。',
    'MursionPortal.ReflectionGuide.LearnerAssessment.SecondSay': '良いと思ってやっていることですが、私はしばしばリーダーがあまり生産的でない反応をするのを目にします。',
    'MursionPortal.ReflectionGuide.LearnerAssessment.ThirdSay': '私は、リーダーたちがより生産的な方法で反応するのを見たこともあります。',
    'MursionPortal.ReflectionGuide.LearnerAssessment.FourthSay': 'この会話では、いくつかの困難なダイナミックスを経験する必要がありました',
    'MursionPortal.ReflectionGuide.LearnerAssessment.Ask': '着地点はどこだと思いますか？(フォローアップ：なぜ/どうして/どうして知っていますか？)：どこに着陸したと思いますか？(フォローアップ：なぜ/どうして/どうして知っていますか？)',
    'MursionPortal.ReflectionGuide.HostAssessment.Opening': 'ミーティング中にあなたが示した強みのうち、１つ高めることができるとしたらどれですか（１つ選択）：',
    'MursionPortal.ReflectionGuide.HostAssessment.Closing': '私の考察はあなたの考察と一致していますか？ 一致している／一致していない理由は何ですか？',
    'MursionPortal.ReflectionGuide.HostAssessment.Opportunity.Opening': '私が観察した成長の機会を１つ高めることができるとしたらどれですか（１つ選択）：',
    'MursionPortal.ReflectionGuide.Closing.Say': '今日、Mursionでこの重要なスキルを練習していただきありがとうございます！',
    'MursionPortal.ReflectionGuide.Closing.Ask': 'この経験から得たもので、次に実生活で同じような状況に直面したときに生かせるものは何ですか？',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummary.Placeholder': '調査概要を選択する',
    'MursionPortal.ScenarioBank.ItemBank.LearnerInstruction.Placeholder': '学習者の指導を選択する',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ClinkHereToReplaceThisFile': 'このファイルを置き換えるには、ここをクリックしてください。',    
    'MursionPortal.Status.Empty': ' ',
    'MursionPortal.ScenarioContent.Label': '最小値{min}と最大値{max}を追加する。',
    'MursionPortal.Search.Text.Skills': 'スキルを検索または選択する',
    'MursionPortal.Search.Text.Avatar': 'アバターを検索または選択する',
    'MursionPortal.NextGen.Scenario.TimelineAndScheduling': 'タイムラインとスケジューリング',
    'MursionPortal.NextGen.Session.Replay': 'セッションリプレイ',
    'MursionPortal.NextGen.StrengthsAndOpportunities': '強みと機会',
    'MursionPortal.NextGen.Recommendations': '推薦',
    'MursionPortal.NextGen.MoreToDiscover': 'さらなる発見',
    'MursionPortal.NextGen.MissionAccomplished': 'ミッションは達成されました！',
    'MursionPortal.NextGen.Learner.ScenarioMaterials': 'シナリオ資料',
    'MursionPortal.NextGen.Learner.PathwayAffiliation': '進路の関係',
    'MursionPortal.NextGen.Learner.SkillLevel': 'スキルレベル',
    'MursionPortal.NextGen.Learner.YourMission': 'あなたのミッション',
    'MursionPortal.ScenarioBank.ShowOnlySecondGenscenarios': '第2Genのシナリオのみを表示',
    'MursionPortal.NextGen.CreateNewSkill.Header.Text': '新しい銀行スキルの作成',
    'MursionPortal.NextGen.Learner.StartSimulation': 'シミュレーション開始',
    'MursionPortal.SimMaterials.Label.CoverStory': 'カバーストーリー',
    'MursionPortal.SimMaterials.Label.LearnerMission': '学習者のミッション',
    'MursionPortal.SimMaterials.Label.Background': '背景',
    'MursionPortal.SimMaterials.Label.PerspectivesAndIdentities': '視点とアイデンティティ',
    'MursionPortal.SimMaterials.HeaderText.SimDashboard': 'SIMダッシュボード',
    'MursionPortal.NextGen.Learner.ToStart': 'スタート',
    'MursionPortal.Project.DeliveryHours.Standard': '標準配達時間',
    'MursionPortal.Project.DeliveryHours.Global': 'グローバルデリバリーアワー',
    'MursionPortal.Label.Monday.ShortForm': '月曜',
    'MursionPortal.Label.Tuesday.ShortForm': '火曜',
    'MursionPortal.Label.Wednesday.ShortForm': '水曜',
    'MursionPortal.Label.Thursday.ShortForm': '木曜',
    'MursionPortal.Label.Friday.ShortForm': '金曜',
    'MursionPortal.Label.Saturday.ShortForm': '土曜',
    'MursionPortal.Label.Sunday.ShortForm': '日曜',
    'MursionPortal.NextGen.CreateFromBank.Customized.Label': '銀行のシナリオからカスタマイズ',
    'MursionPortal.NextGen.CreateFromBank.CustomizedFromBank.Tooltip': 'このシナリオは、オリジナルのバンクシナリオをカスタマイズしたものです。バンクシナリオに加えられた更新は、このシナリオには反映されません。',
    'MursionPortal.BuildingBlock.Label.PublishedDate': '公開日',
    'MursionPortal.Label.CopyTeamInviteLink': 'チーム招待リンクをコピーする',
    'MursionPortal.NextGen.SessionReplayText': 'この会話は、苦境にあるチームメンバーをサポートするための会話を促進する練習の機会でした。ここでの成功は、あなたがジョーに共感できるかどうかにかかっています。',
    'MursionPortal.NextGen.TheMission': 'ミッション：',
    'MursionPortal.NextGen.DemonstratedStrength': '実証された強み',
    'MursionPortal.NextGen.OpportunityForFutureGrowth': '将来の成長機会',
    'MursionPortal.NextGen.CompetenciesFocus': '注目されるコンピテンシーは以下の通りです。',
    'MursionPortal.NextGen.StrengthAndOpportunitiesText': '会話の報告会では、観察された強みと成長の機会が特定されました：',
    'MursionPortal.NextGen.WeObserved': '観察された内容',
    'MursionPortal.NextGen.WhyThisMatters': 'これが重要な理由',
    'MursionPortal.ScenarioSetting.MissOrCancelationRate.ValidationMessage': 'ミス/キャンセル率がNULLでない場合、スケジューリングロックアウトタイムをNULLにすることはできない。',
    'MursionPortal.NextGen.CreateFromBank.Customized.Modal.Text': '銀行シナリオをカスタマイズしたため、今後銀行シナリオを変更してもこのシナリオには反映されません。続行しますか？',
    'MursionPortal.SimMaterials.Event.SkillsInFocus.Label': 'スキルインフォーカス',
    'MursionPortal.SimMaterials.Label.SimulationEvents': 'シミュレーションイベント',
    'MursionPortal.SimMaterials.Event.AvatarBehavior.Label': 'アバターの行動',
    'MursionPortal.SimMaterials.Event.LearnerResponse.Label': '期待される学習者の反応',
    'MursionPortal.Label.NoTimeSelected': '時間指定なし',
    'MursionPortal.Label.StartTime.EndTime.Selected': '開始時刻と終了時刻の両方を選択する必要があります。',
    'MursionPortal.SimMaterials.Label.ReflectionAndDebrief': '復習と報告',
    'MursionPortal.NextGen.Scenario.Planning': 'プランニング',
    'MursionPortal.HowItWorks.Experience.Text': '数秒後に自動的に体験が始まります！',
    'MursionPortal.HowItWorks.MicCamera': 'マイク/カメラ',
    'MursionPortal.HowItWorks.Prompt.Text': 'プロンプトが表示されたら、<strong>「許可」</strong>をクリックしてマイク/カメラにアクセスし、アバターがあなたを見たり聞いたりできるようにします！',
    'MursionPortal.HowItWorks.StartSession.Text': '<strong>「セッションを開始する」</strong>をクリックし、シナリオの説明を確認して準備してください！',
    'MursionPortal.LearnerMaterials.KeyCompetencies': '主要なコンピテンシー',
    'MursionPortal.LearnerMaterials.ResearchMaterials': '研究資料',
    'MursionPortal.LearnerMaterials.PracticeOpportunity': '練習の機会',
    'MursionPortal.ScenarioInfo.Label.ScenarioBank': 'シナリオは銀行から作成',
    'MursionPortal.DemandBasedScheduling.SlotSelectionMessage': 'ご都合の良い時間帯をお選びください。選んだ時間帯からシナリオをスケジュールします。',
    'MursionPortal.NextGen.ScenarioBank.SubStrengthAssessment': '強さ',
    'MursionPortal.NextGen.ScenarioBank.SubOpportunityAssessment': '機会',
    // needs translations
    'MursionPortal.EngagementHub.EmailLearners': 'Email Learners', // TODO add translations
    'MursionPortal.EngagementHub.InviteToTeam': 'Invite to Team', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearnersToScheduleTitle': 'Invite your learners to start or continue their practice', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearnersToScheduleSummary': 'Engage your learners via email or by embedding shared links in your own communications.', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations': 'View Invitations', // TODO add translations
    'MursionPortal.EngagementHub.CreateInvite': 'Create Invite', // TODO add translations
    'MursionPortal.NextGen.replayConversation':'Replay the Conversation',// TODO add translations
    'MursionPortal.EngagementHub.BackTo': 'Back to {clientName}', // TODO add translations
    'MursionPortal.EngagementHub.LearningPotential': 'At Mursion, we believe in maximizing every learner\'s potential.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationLearnMore': 'Communication Is Key! Learn more', // TODO add translations
    'MursionPortal.EngagementHub.UnlockTeamPotential': 'Unlock Your Team’s Potential', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearners.Title': 'Invite your learners to schedule their simulations', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearners.Summary': '58% of users are more likely to sign up for their simulation if you send a custom invitation to schedule.', // TODO add translations
    'MursionPortal.EngagementHub.ManageEmailSettings.Label': 'Your Email, Your Way!', // TODO add translations
    'MursionPortal.EngagementHub.ManageEmailSettings.Title': 'Manage email settings and gain insights', // TODO add translations
    'MursionPortal.EngagementHub.Customize': 'Customize', // TODO add translations
    'MursionPortal.EngagementHub.EmailConfigurations.Title': 'Email Configurations', // TODO add translations
    'MursionPortal.EngagementHub.EmailConfigurations.Summary': 'Customize email messages that resonate with your learners and drive adoption and engagement.', // TODO add translations
    'MursionPortal.EngagementHub.PowerfulEmailAnalytics.Title': 'Powerful Email Analytics', // TODO add translations
    'MursionPortal.EngagementHub.PowerfulEmailAnalytics.Summary': 'Measure email open, click, and unread rates to discover insights and make data-driven decisions.', // TODO add translations
    'MursionPortal.EngagementHub.PersonalizeMessaging.Label': 'Add Personal Touch', // TODO add translations
    'MursionPortal.EngagementHub.PersonalizeMessaging.Title': 'Personalize messaging in the Mursion Portal', // TODO add translations
    'MursionPortal.EngagementHub.LearnerDashboard': 'Learner Dashboard', // TODO add translations
    'MursionPortal.EngagementHub.ScenarioPage': 'Scenario Pages', // TODO add translations
    'MursionPortal.EngagementHub.SchedulingPage': 'Scheduling Page', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerFeedbackTooltip': 'This email will only go out if feedback report is enabled at project level. Please reach out to your Mursion representative to learn more.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Title': 'Email Settings', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Summary': 'Use these tools to help customize different emails.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EmailName': 'Email Name', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.SenderName': 'Sender Name (email will be no-reply@mursion.com)', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.ViewCustomize': 'View/Customize', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EnableDisable': 'Enable/Disable', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerLockout': 'Learner Lockout', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.VideoFeedback': 'Video & Feedback', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.ModifySettings': 'Click edit to modify the settings', // TODO add translations
    'MursionPortal.EngagementHub.BackTitle': 'Back to Engagement Hub', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey': 'Communication Is Key!', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.summary': 'A great Mursion rollout starts with effective communication to your learners. It\'s important to ensure they are prepared and understand the connection between Mursion and your learning and development program goals.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.TItle': 'Elements of Effective Learner Communication', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LearnerBenefits.Title': 'Lead with Learner Benefits', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LearnerBenefits.Summary': 'Lead with learner benefits, not product features. Share learner testimonials and tie the skills they are developing to business objectives.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.PracticeProgress.Title': 'Practice makes Progress', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.PracticeProgress.Summary': 'Practice is essential to developing new skills. Ensure your message highlights The Importance of Practice - And Our Reluctance To Do It to acknowledge challenges that learners face.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LeadersMessage.Title': 'Message from Leaders', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LeadersMessage.Summary': 'Ask senior leaders to communicate (meetings, written, video, etc) why Mursion sessions are valuable and how developing the target skills is tied to business priorities and growth opportunities.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.SetExpectations.Title': 'Set Expectations', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.SetExpectations.Summary': 'Mursion is a new technology that uses avatars powered by humans. Prepare learners for these conversations by sharing why and how avatars create psychology safety and is an effective way to practice.', // TODO add translations
    'MursionPortal.Tab.Engagement': 'Engagement', // TODO add translations
    'MursionPortal.Tab.EngagementHub': 'Engagement Hub', // TODO add translations
    'MursionPortal.Tab.EmailManagement': 'Email Management', // TODO add translations
    'MursionPortal.Tab.PortalMessaging': 'Portal Messaging', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.BackButton.Label': 'Back to Invite to Schedule', // TODO add translations
    'MursionPortal.Portal.Messaging.Heading.Text': 'Inform, engage, and delight learners using your organization’s unique brand voice.',  // TODO add translations
    'MursionPortal.Portal.Messaging.SubHeading.Text': 'Add custom messaging throughout the Portal to engage your learners.',  // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.InvitationDate': 'Invitation Date', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.MessageToLearners': 'Message to Learners', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.ExcludedLearners': 'Excluded Learners', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.CreatedBy': 'Created By', // TODO add translations
    'MursionPortal.Portal.EngagementHub.InviteToScheuled.Text': '{scenariosCount} scenarios assigned to {learnerCount} learners', // TODO add translations
    'MursionPortal.Portal.EngagementHub.InviteToScheuled.Text.AddLearners': 'Add Learners', // TODO add translations
    'MursionPortal.Tab.EmailManagement.Analytics': 'Analytics', // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.Teams.Invitation.Message': 'The following team(s) already have scheduled an invitation on this day:', // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.New.Invitation.Message': 'Please make a note and start a new invitation', // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.Learners.Invitation.Text': 'Learners will receive the invitation in the early morning hours (Pacific time) on this day.', // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.SendDate.Label': 'Send Date', // TODO add translations
    'MursionPortal.Scenario.Generation.FilterType.Label' : 'Generation', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.CancelInvitation.Modal.Text': 'Are you sure you want to cancel the invitation created by {createdBy}?', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerFeedbackReport': 'Learner Feedback Report', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.CancellationByLearner': 'Cancellation by Learner', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.MissedSessionByLearner': 'Missed by Learner', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerReleased': 'Learner Released', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerLockOut': 'Learner Lockout', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.SessionConfirmation': 'Session Confirmation', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Invitation': 'Welcome to Mursion - a platform for you to practice your skills', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.InvitationReminder': `Reminder:You're invited to {licenseeName}`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Confirmation': 'Thanks for signing up! Your Simulation is confirmed.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.FirstReminder': `Don't forget! Your Mursion simulation is soon!`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.SecondReminder': `Get excited! Your Mursion simulation starts in {upcomingBeforeStart} minutes.`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.CancellationByByLearner': 'Your simulation was canceled', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.MissedSessionByLearner': 'We missed you today!', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerLockedOut': 'You missed/cancelled this scenario too many times', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerReleased': 'Your session is available to schedule', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerSessionRecordingIsReady': 'Want to see how you did? Visit your Mursion Dashboard.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerFeedbackReport': 'Want to see how you did? Review your session report.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerSessionRecordingReady': 'Learner Session Recording Ready', // TODO add translations
    'MursionPortal.EngagementHub.BackToEmailSettings': 'Back to Email Settings', // TODO add translations
    'MursionPortal.EngagementHub.CustomizeEmail.Text': 'Learners will start receiving this email after you save.', // TODO add translations
    'MursionPortal.NextGen.Learner.FeedbackFrom': 'Feedback from', // TODO add translations
    'MursionPortal.NextGen.Scenario.SimMaterials': 'SIM Materials', // TODO add translations
    'MursionPortal.NextGen.Scenario.LearnerMaterials': 'Learner Materials', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Modal.BodyText': 'Are you sure you want to save the Enable/Disable settings', // TODO add translations
    'MursionPortal.Client.EmailSettings.SessionRescheduledByPS': 'Session Rescheduled by PS', // TODO add translations
    'MursionPortal.EngagementHub.EmailPreview.Sender': 'Sender', // TODO add translations
    'MursionPortal.Scenario.CoverStory.Tooltip.Text': 'Cover Story will be visible to clients in the scenario library as well as learners and SIMs in their materials',// TODO add translations
    'MursionPortal.Scenario.Summary.Tooltip.Text':'Summary will be visible to clients in the scenario library',// TODO add translations
    'MursionPortal.NextGen.Event.positiveMindset': 'There must be at least ({startCount}) positive mindset defined. You can specify a maximum of ({endCount}) positive mindsets.', // TODO add translations
    'MursionPortal.NextGen.Event.derailingMindset': 'There must be at least ({startCount}) derailing mindset defined. You can specify a maximum of ({endCount}) derailing mindsets.', // TODO add translations
    'MursionPortal.NextGen.Event.StrengthAssessment': 'There must be at least ({startCount}) strength assessment defined. You can specify a maximum of ({endCount}) strength assessments.', // TODO add translations
    'MursionPortal.NextGen.Event.OpportunityAssessment': 'There must be at least ({startCount}) opportunity assessment defined. You can specify a maximum of ({endCount}) opportunity assessments.', // TODO add translations
    'MursionPortal.NextGen.Event.StrengthAssessment.AvatarResponse': 'You have added {addedCount} avatar response for strength assessment(s), you must add at least {totalCount} avatar response for strength assessment(s).', // TODO add translations 
    'MursionPortal.NextGen.Event.OpportunityAssessment.AvatarResponse': 'You have added {addedCount} avatar response for opportunity assessment(s), you must add at least {totalCount} avatar response for opportunity assessment(s).', // TODO add translations 
    'MursionPortal.Label.ClientLevelMissCancelSetting': 'Client Level Miss/Cancel Setting', // TODO add translations 
    'MursionPortal.Label.ProjectLevelMissCancelSetting':'Project Level Miss/Cancel Setting', // TODO add translations 
    'MursionPortal.Label.ScenarioLevelMissCancelSetting':'Scenario Level Miss/Cancel Setting', // TODO add translations 
    'MursionPortal.Label.MissedCancelledSessions':'Missed/Cancelled Sessions', // TODO add translations 
    'MursionPortal.EngagementHub.ViewInvitations.Column.SenderName': 'Sender Name', // TODO add translations,
    'MursionPortal.NextGen.Event.Delete.ConfirmBox': 'Are you sure you want to delete this {message}?', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.PositiveMindset': 'Positive Mindset', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.DerailingMindset': 'Derailing Mindset', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.StrengthAssessment': 'Strength assessment', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.OpportunityAssessment': 'Opportunity assessment', // TODO add translations,
    'MursionPortal.PersonalizedMessageTitle': 'Personalized message header. This will appear before every personalized message in Portal.', // TODO add translations
    'MursionPortal.NextGen.ScenarioReferences': 'Scenario References', // TODO add translations 
    'MursionPortal.NextGen.ChildScenarios': 'Child Scenarios', // TODO add translations 
    'MursionPortal.NextGen.Label.Customized': 'Customized', // TODO add translations  
    'MursionPortal.EngagementHub.EmailSettings.InvitationPasswordUsers': 'Invitation (Password Users)', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.InvitationReminderPasswordUsers': 'Invitation Reminder (Password Users)', // TODO add translations
    'MursionPortal.Label.Clicked': 'Clicked', // TODO add translations
    'MursionPortal.Client.EmailSettings.SessionRescheduledByLearner': 'Session Rescheduled by Learner', // TODO add translations
    'MursionPortal.Client.Team.CopyTeamInviteLink.Tooltip':'Users who have not yet registered or have not yet been added to the Portal will not be able to access this link. Please click the "Add Learners" button.', // TODO add translations
    'Settings.Config.LearnerSessionReportDelay': 'Learner Session Report Delay', // TODO add translations
    'MursionPortal.SkillsReferences.Label': 'Skills References', // TODO add translations
    'MursionPortal.ResearchSummariesReferences.Label': 'Research Summaries References', // TODO add translations
    'MursionPortal.Search.Text': 'Search or select', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.Event': 'Please select Event', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.Skill': 'Please select Skill', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.AvatarBehavior': 'Avatar Behavior is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.ExpectedLearnerResponse': 'Expected Learner Response is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.PositiveMindset': 'Please Select Positive Mindset', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.PositiveMindsetDescription': 'Positive mindset is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.DerailingMindset': 'Please Select Derailing Mindset', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.DerailingMindsetDescription': 'Derailing mindset is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.DescribeObservedAction': 'Describe Observed Action is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.ExplainImpact': 'Explain Impact is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.AvatarName': 'Please select avatar name', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.PossibleAvatarResponse':'Possible Avatar Response is required', // TODO add translations
    'MursionPortal.Label.SchedulingLockoutDate': 'Scheduling Lockout Date', // TODO add translations
    'MursionPortal.Label.SchedulingLockoutDate.Tooltip': 'Lockout effective date for all learners who miss and/or late cancel', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.MinimumScore': 'You’ve changed the number of events. Please update the minimum score.', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.SynopsisDescription': 'Synopsis description is required', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.SuccessfulAssessment': 'Successful Assessment is required', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.OpportunityAssessment': 'Opportunity Assessment is required', // TODO add translations
    'MursionPortal.NextGen.Scenario.LibraryInfo.PathwaysAffiliation': 'Pathway Affiliation(s)', // TODO add translations
    'MursionPortal.NextGen.BankScenarioReferences': 'Bank Scenario References', // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerActivationStatus':'Learner Activation',  // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerInviteTitle':'Unlock Your Team’s Potential',  // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerInviteMessage':'Target invitations to your learners with custom messaging',  // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerInvite':'Invite to Schedule',  // TODO add translations
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.NotYetRegistered':'Not Yet Registered', // TODO add translations
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.NotYetScheduled':'Not Yet Scheduled', // TODO add translations
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.ScheduledNotYetCompleted':'Scheduled - Not Yet Completed', // TODO add translations
    'MursionPortal.NextGen.ViewAndInviteLearners': 'View & Invite Learners', // TODO add translations
    'MursionPortal.Dashboard.Tooltip.Label.SchedulingEmail': 'Session Specific Emails and other Join Session Emails', // TODO add translations
    'MursionPortal.Dashboard.EmailAnalytics.Label.InviteToScheduleEmail': 'Invite-to-Schedule Emails', // TODO add translations
    'MursionPortal.Dashboard.Tooltip.Label.CallToActionEmail': 'Emails sent to learners inviting them to schedule scenario(s)', // TODO add translations
    'MursionPortal.NextGen.Scenario.ClientCustomizations': 'Client Customizations', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.InviteToSchedule.Tooltip': 'Invite to Schedule emails are able to be customized each time you send them.', // TODO add translations
    'MursionPortal.Contract.ContractTimezone': 'Contract Timezone', // TODO add translations
    'Dashboard.LeftPane.Simulations.SessionSearch':'Search for Session', // TODO add translations
    'MursionPortal.NextGen.Scenario.ClientCustomizations.ClientScenarioTitle': 'Client Scenario Title', // TODO add translations
    'MursionPortal.NextGen.Scenario.ClientCustomizations.ClientScenarioTitle.Tooltip': 'Client learners and program admins will see this title only. SIMs will see the bank scenario title and client scenario title in their SIM materials.', // TODO add translations
    'MursionPortal.NextGen.Event.DeleteMessage': 'Minimum 2 Events are mandatory for a Scenario.', // TODO add translations
    'Settings.Config.ProjectAndScenario.AllowSimPracticeSession': 'SIM Practice Session', // TODO add translations
    'MursionPortal.Calendar.Button.Practice.Session': 'Practice Session', // TODO add translations
    'MursionPortal.Scenario.InTrainingSimSpecialist': 'In Training SIM Specialists', // TODO add translations
    'MursionPortal.Scenario.NoInTrainingSimSpecialist': 'No In Training Sim Specialist', // TODO add translations
    'MursionPortal.Simspecialist.Thankyou.summary': 'You are scheduled for your practice simulation', // TODO add translations
    'MursionPortal.Button.DoNotSaveMyWork': 'Don’t Save my Work', // TODO add translations
    'MursionPortal.NextGen.Confirm.BrowserBack': 'You have not saved your changes, and navigating away will cause you to lose your work. Please complete required fields.', // TODO add translations
    'MursionPortal.UserConsent.Learner.Description': '「同意します」と言うと、Mursion ソフトウェアはライブ シミュレーションを処理し、Mursion ソフトウェア ポータルでレビューできるようにシミュレーション セッションのビデオ録画を作成します。適用される法律で義務付けられている場合または品質保証の目的を除き、Mursion はお客様の許可なしにビデオ録画を配布または開示することはありません。 Mursion は、シミュレーション技術を向上させるために、ソフトウェアの使用から得られる匿名化されたデータを使用します。', // TODO add translations, this translation was generated using the Google Translate, retranslation required in the future
    'MursionPortal.UserConsent.LearnerAndAccountOwnerOrFacilitator.Description': '「同意します」と言うと、Mursion ソフトウェアはライブ シミュレーションを処理し、Mursion ソフトウェア ポータルでレビューできるようにシミュレーション セッションのビデオ録画を作成します。あなたに代わって Mursion ソフトウェアのライセンスを取得したクライアントの要求に応じて、記録されたシミュレーション セッションのコピーが、クライアントが選択したコーチ、メンター、またはレビュー担当者と共有されることに注意してください。別のプライバシー規約に同意しない限り、すべてのレビュー担当者は、<code>Mursion のプライバシーに関する通知</code> に従うことが契約上義務付けられています。適用される法律で義務付けられている場合または品質保証の目的を除き、Mursion はお客様の許可なしにビデオ録画を配布または開示することはありません。 Mursion は、シミュレーション技術を向上させるために、ソフトウェアの使用から得られる匿名化されたデータを使用します。', // TODO add translations, this translation was generated using the Google Translate, retranslation required in the future
    'MursionPortal.UserConsent.AccountOwnerOrFacilitator.Description': 'あなたのシミュレーション セッションは、あなたに代わって Mursion ソフトウェアのライセンスを取得したクライアントの要求に応じて、音声とビデオの両方で記録されます。続行することに同意した場合、記録を分析してスコアを付けるために、記録されたシミュレーション セッションのコピーがクライアントが選択した第三者と共有されます。これらの個人は、別のプライバシー条件に同意しない限り、<code>Mursion のプライバシー通知</code> に従うことが契約上義務付けられています。ただし、完了することに同意した課題または評価の条件に従って、記録されたシミュレーション セッションのコピーを受け取ったり、アクセスしたりすることはできません。 Mursion は、品質保証の目的でお客様のビデオ録画を使用する場合があり、また、シミュレーション技術を向上させるために、お客様によるソフトウェアの使用から得られる匿名化されたデータを使用します。', // TODO add translations, this translation was generated using the Google Translate, retranslation required in the future
    'MursionPortal.ProjectCreate.DeliveryHours.Validation': 'Delivery Hours Start time should be less than End time.', // TODO add translations
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.NotYetRegistered':'Unique number of learners who have been added to the Portal but have not yet completed registration', // TODO add translations
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.NotYetScheduled':'Unique number of learners who have registered but have not yet scheduled a session', // TODO add translations
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.ScheduledNotYetCompleted':'Unique number of learners who have registered and scheduled at least one session but have not yet completed a session', // TODO add translations
    'MursionPortal.Restriction': 'Restriction', // TODO add translations
    'MursionPortal.Dashboard.SessionSearch.Placeholder' : 'Paste a session ID and hit return',  // TODO add translations
    'Operations.TechRestriction.CreateDialog.Title': 'New Holiday Tech Restriction', // TODO add translations
    'Operations.TechRestriction.EditDialog.Title': 'Edit Holiday Tech Restriction', // TODO add translations
    'Operations.TechRestriction.ViewDialog.Title': 'View Holiday Tech Restriction', // TODO add translations
    'MursionPortal.Client.EmailSettings.TriggerHours': 'Trigger (Hours)', // TODO add translations
    'MursionPortal.Client.EmailSettings.TriggerMin': 'Trigger (Minutes)', // TODO add translations
    'MursionPortal.Duplicate.Scenario.Confirmation.Text': 'Are you sure you want to duplicate this scenario?',  // TODO add translations
    'MursionPortal.Contract.LineItem.ToolTip': 'A contract can only contain one type of line item. If your contract has both sessions and hours, please convert the sessions into hours and add it as an hours line item.', // TODO add translations
    'MursionPortal.ReflectionGuide.LearnerPerspective': 'Learner Perspective', // TODO add translations
    'MursionPortal.Dashboard.SessionSearch.Table.Placeholder': 'Search for Session will load once you search session ID', // TODO add translations
    'MursionPortal.Dashboard.SessionSearch.EmptySession': 'No sessions match that ID', // TODO add translations
    'MursionPortal.SimMaterials.Label.IntroAndPreReflection': 'Intro & Pre-Reflection', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.LearnerBreakdown': 'Learner Breakdown', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.LearnerBreakdown.Activated': 'Activated', // TODO add translations
    'MursionPortal.NextGen.Scenario.LibraryInfo.VersionNumber': 'Version Number', // TODO add translations
    'MursionPortal.NextGen.Scenario.LibraryInfo.VersionDetails': 'Version Details', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EmailReminderHours': 'Trigger {tiggerValue} hours before Session.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EmailReminderMinutes': 'Trigger {tiggerValue} minutes before Session.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.FirstEmailReminder.Tooltip': 'Time before Session when First Reminder would go. If not set Licensee Setting would be used.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.SecondEmailReminder.Tooltip': 'Time before Session when Second Reminder would go. If not set Licensee Setting would be used.', // TODO add translations
    'MursionPortal.SimAttendance.MissedSimulation.Message': 'You missed this simulation so you are unable to edit attendance. Please contact Support if you did not miss the simulation or had a technical issue that caused you to miss the simulation.', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Disclaimer': 'Invites are visible for last 30 days only', // TODO add translations
    'MursionPortal.PortalVersion': 'Portal Version', // TODO add translations
    'MursionPortal.ScenarioInfo.Label.AutoSIM': 'AutoSIM', // TODO add translations
    'MursionPortal.ProjectContract.AddConfirmation': 'Are you sure you want to add the contract to this project?', // TODO add translations
    'MursionPortal.ProjectContract.CompletedContract.AddConfirmation': 'Are you sure you want to add the completed contract to this project?', // TODO add translations
    'MursionPortal.Label.NewPortal' : 'New Portal', // TODO add translations
    'MursionPortal.Label.OldPortal' : 'Old Portal', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.ReminderValidation': 'Second Reminder should be less than First Reminder', // TODO add translations
    'MursionPortal.Project.InviteToSchedule.EmailDisabled.Tooltip' :'Invite to Schedule is disabled as email settings are disabled. Enable them to send this invite', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimReportedError' :'SIM Reported Error (Does not impact session status)', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimLateOrNoShow' :'SIM late or no show', // TODO add translations
    'MursionPortal.SimAttendance.Error.SessionStartedTooEarly' :'Session started too early', // TODO add translations
    'MursionPortal.SimAttendance.Error.AvatarIssue' :'Avatar Issue', // TODO add translations
    'MursionPortal.SimAttendance.Error.MouthlipSyncIssueDuringSession' :'Mouth/lip sync issue during session', // TODO add translations
    'MursionPortal.SimAttendance.Error.Hardware' :'Hardware', // TODO add translations
    'MursionPortal.SimAttendance.Error.AudioVideoIssueForSim' :'Audio/video issue for SIM', // TODO add translations
    'MursionPortal.SimAttendance.Error.AudioVideoIssueForLearner' :'Audio/video issue for Learner', // TODO add translations
    'MursionPortal.SimAttendance.Error.OtherSimHardwareIssues' :'Other SIM hardware issues (controller, laptop, etc.)', // TODO add translations
    'MursionPortal.SimAttendance.Error.LearnerAndSimBothJoined' :'Learner and SIM both joined, but no connection established', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimMomentIssue' :'SIM Moment issue', // TODO add translations
    'MursionPortal.SimAttendance.Error.WifiIssue' :'Wifi Issue', // TODO add translations
    'MursionPortal.SimAttendance.Error.LearnerWifi' :'Learner Wifi', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimWifi' :'SIM Wifi', // TODO add translations
    'MursionPortal.SimAttendance.Error.AdditionalDetails' :'Additional Details', // TODO add translations
    'MursionPortal.SimAttendance.Error.DescribeYourError' :'Describe your error...', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.CancellationByProfessionalServiceOrAccountOwner': 'Cancellation by PS or AO', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.CompleteLearnerSurvey': 'Complete Learner Survey', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.CompleteLearnerSurvey': 'How\'d it go?', // TODO add translations
    'MursionPortal.CustomizeInvitation.ButtonText.Label': 'Button Text', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Client.LearnerLockedOut': 'You are locked out!', // TODO add translations
    'MursionPortal.Filter.ReleaseLevel.Placeholder': 'Release Level', // TODO add translations
    'MursionPortal.TOTP.Page.Heading': 'We just sent a security code to your {email}. Enter the code to sign in.', // TODO add translations
    'MursionPortal.TOTP.ResendOTP.Text': 'Resend Code', // TODO add translations
    'MursionPortal.TOTP.Code.Label': 'Code', // TODO add translations
    'MursionPortal.TOTP.IncorrectCode.ErrorMsg': 'The code is incorrect. Enter the correct code.', // TODO add translations
    'MursionPortal.TOTP.ResendNewCode.ErrorMsg': 'The code has expired, click "Resend" to receive a new code.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Client.LearnerReleased': 'You are ready to schedule again.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.FirstReminder': `You’ve an upcoming Mursion Session in {firstReminderTime} hours!`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.SecondReminder': `Your Mursion Session is starting in {upcomingBeforeStart} minutes!`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.LearnerFeedbackReport': 'Mursion - Review Your Session Performance Report.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.LearnerSessionRecordingIsReady': 'Your Session Recording is now up! Review how you did', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.Confirmation': 'Your Mursion Session is Confirmed!', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.CancellationByLearnerOrPSOrAO': 'Your {licenseeName} session was canceled', // TODO add translations
    'MursionPortal.Personalized.PathwayPage.Section': 'Message you want learners to see on Pathway Page', // TODO add translations
    'MursionPortal.Personalized.CreateNewPathway.Button.Label': 'Create new for specific pathway', // TODO add translations
    'Projects.ScenarioList.NoPathwaysFound': 'No Pathways have been found.',// TODO add translations
    'MursionPortal.Personalized.Pathway.Search.Placeholder': 'Search or select Pathway(s):',// TODO add translations
    'Mursion.Portal.Status.SimTechnicalError': 'Sim Technical Error',// TODO add translations
    'MursionPortal.SimAttendance.Sim.Tooltip.Error': 'Session was unable to be completed due to technical difficulties on the Sim’s side. (if the session was able to be completed after an error this session should be marked complete and not error).',// TODO add translations
    'Session.Edit.Modal.Notes.BlankNoteValidation': 'Either add a valid note or remove this block', // TODO add translations
    'MursionPortal.Scenario.Error.Msg': 'Please complete required field.', // TODO add translations
    'MursionPortal.Scenario.Skills.Error.Msg': 'The number of skills must be in the range of {minSkills} and {maxSkills}.', // TODO add translations
    'MursionPortal.Capitalized.Incomplete': 'INCOMPLETE', // TODO add translations
    'MursionPortal.Dashboard.Chart.LicenseBreakdown.Title': 'License Breakdown – Contract Item',  // TODO add translations
    'MursionPortal.Dashboard.Chart.LearnerLicenses': 'Learner Licenses',  // TODO add translations
    'MursionPortal.Dashboard.Chart.LearnersRegistered': 'Learners Registered',  // TODO add translations
    'MursionPortal.Dashboard.Chart.LearnersScheduled': 'Learners Scheduled',  // TODO add translations
    'MursionPortal.SessionAttendance.AddAttendees': 'Add attendees',  // TODO add translations
    'MursionPortal.SessionAttendance.SimulationSpecialist': 'Simulation Specialist',  // TODO add translations
    'MursionPortal.Dashboard.SimulationSpecialist.Status': 'Simulation Specialist Status',  // TODO add translations
    'MursionPortal.Dashboard.Technical.Issue.Summary': 'If a technical issue occurred during this session, please indicate the type(s) of issue encountered:',  // TODO add translations
    'MursionPortal.Dashboard.Provide.Details': 'Please provide more details on what happened',  // TODO add translations
    'MursionPortal.SessionAttendance.SwitchToSimRole.Message': 'Please switch role to Sim Specialist to view the page!', // TODO add translations
    'MursionPortal.SessionAttendance.Heading': '<code>Instructions:</code> Review the Session, Simulation Specialist, and Learner Status\' below and update as required. This form may be edited for 24 hours after the end of the session', // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.Label': 'This is automatically determined by the Learner and Simulation Specialist status. No action required.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionDetails.Heading': '(selections below do not impact session status)', // TODO add translations
    'MursionPortal.SessionAttendance.SessionDetails.Instructions': '<code>Instructions:</code> Session details <code>must</code> be provided for any session that has a Learner Status other than ‘Complete’ or ‘No Show’. To complete, select one or more issue type(s) and describe details in the text box. Session detail information does not impact the Learner, Simulation Specialist, or Session status.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionDetails.Text': `Mursion Simulation Specialists only: If you filled out a UTS ticket for this session, please include the link to the Slack post in the text box (click the 3 vertical dots to the right of the post and select ‘Copy link').`, // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.Simspecialist.Label': 'This is automatically determined by the system. If you encountered an error that prevented the session from being completed and the indicated status is ‘Left’, select ‘Technical Error (SIMS)’. Otherwise, no action required.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.Learner.Label': 'This is automatically determined by the system. If the indicated status is inaccurate, select the correct status and explain the reason for the revision in Session Details. Otherwise, no action required.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.SimStatus.Label': 'Technical Error (SIMs)', // TODO add translations
    'Mursion.Portal.Status.UnableToComplete': 'Unable to Complete', // TODO add translations
    'Mursion.Portal.Status.ElectedToLeave': 'Elected to Leave', // TODO add translations
    'Mursion.Portal.Status.TechnicalErrorLearner': 'Technical Error (Learner)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.Audio': 'Audio', // TODO add translations
    'MursionPortal.SessionAttendance.Error.ConnectionInternet': 'Connection / Internet', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerTechnicalIssues': 'Learner technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.MursionTechnicalIssues': 'Mursion technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.OtherTechnicalIssues': 'Other technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.MomentSoftware': 'Moment Software', // TODO add translations
    'MursionPortal.SessionAttendance.Error.AvatarOrEnvironmentLipSyncMovementEtc': 'Avatar or Environment (lip sync, movement, etc.)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.HardwareControllerLaptopEtc': 'Hardware (controller, laptop, etc.)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerAndSimulationSpecialistBothJoined': 'Learner and Simulation Specialist both joined, no connection established', // TODO add translations
    'MursionPortal.SessionAttendance.Error.OtherPleaseExplainBelow': 'Other (please explain below)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.NonTechnicalIssues': 'Non-technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerMaterialsOrPreparation': 'Learner materials or preparation ', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerLanguageFluency': 'Learner language fluency', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerElectedToLeave': 'Learner elected to leave', // TODO add translations
    'MursionPortal.Dashboard.Overview.Empty.Body.Message': 'Contract report will load once you make a selection', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdown.ContractItem.Simulation':'Session Breakdown – Simulation Contract Item', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdown.ContractItem.Hour':'Session Breakdown – Hour Contract Item', // TODO add translations
    'MursionPortal.Copy.Tooltip.Text': 'Copy (plain text only, no formatting)', // TODO add translations
    'MursionPortal.RTF.DropDown.Text.Green': 'Green', // TODO add translations
    'MursionPortal.RTF.DropDown.Text.Thick': 'Thick', // TODO add translations
    'MursionPortal.Status.Connected.Label': 'Connected',  // TODO add translations
    'MursionPortal.Status.Immersing.Label': 'Immersing',  // TODO add translations
    'MursionPortal.Dashboard.NonTechnical.Issue.Summary': 'If a non-technical issue occurred during this session, please indicate the type(s) of issue encountered:',  // TODO add translations
    'MursionPortal.AutoSim.Scenario.TooltipLabel': 'AutoSim scenario can’t be scheduled.', // TODO add translations
    'MursionPortal.Scenario.Planing.AutoAssignTeam': 'Auto-Assign Team', // TODO add translations
    'MursionPortal.Scenario.Planing.AutoAssignTeam.Tooltip': 'Automatically assign this scenario to the selected teams when learners access the scenario page via the provided link.', // TODO add translations
    'MursionPortal.Dashboard.Overview.NoAssociatedDataForSelectedContract': 'No associated data for the selected contract(s)', // TODO add translations
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.LevelOne': 'Level 1', // TODO add translations
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.LevelTwo': 'Level 2', // TODO add translations
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.LevelThree': 'Level 3', // TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Title': 'Make sure you’re ready to take the session by testing your connection before joining.',// TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Button.Label': 'Test Connection',// TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Support.Link.Title': 'If any test fails, copy results at the end of the test and share them with our support team by',// TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Support.Link': 'Submitting a Support ticket.',// TODO add translations
    'MursionPortal.Dashboard.OnDemand.Text':'Ready, set, practice on-the-go',
    'MursionPortal.Dashboard.OnDemand.Title':'Try On-Demand Simulations',
    'MursionPortal.Dashboard.OnDemand.SubTitle':'Get a first look at the future of skill-building at work - dynamic GenAI simulations available instantly, no scheduling required. Practice anytime and receive actionable feedback that helps you grow and master new skills.',
    'MursionPortal.Dashboard.OnDemand.SmallTitle':'Early access, real results—get ahead now.',
    'MursionPortal.Dashboard.OnDemand.Button.Label':'Go to Mursion On-Demand',
    'MursionPortal.Dashboard.OnDemand.Button.Hide.Label':'Hide this',
    'MursionPortal.Dashboard.OnDemand.Preview':'PREVIEW',
    'MursionPortal.Dashboard.OnDemand.Text1':'✓ Scalable, focused practice',
    'MursionPortal.Dashboard.OnDemand.Text2':'✓ Flexible access',
    'MursionPortal.Dashboard.OnDemand.Text3':'✓ Targeted practice for mastery',
    'MursionPortal.Dashboard.OnDemand.Text4':'✓ Guided self-reflection',
    'MursionPortal.Dashboard.OnDemand.Mursion':'Mursion',
  },
};
export default japaneseLangTranslations;
