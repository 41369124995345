import en from 'src/i18n/languages/en-US';
import fr from 'src/i18n/languages/fr';
import fr_CA from 'src/i18n/languages/fr-CA';
import de from 'src/i18n/languages/de';
import es from 'src/i18n/languages/es';
import it from 'src/i18n/languages/it';
import pt_BR from 'src/i18n/languages/pt-BR';
import ru from 'src/i18n/languages/ru';
import zh_CN from 'src/i18n/languages/zh-CN';
import ja from 'src/i18n/languages/ja';
import pl from 'src/i18n/languages/pl';
import { TLocaleId } from 'src/i18n';


const languages: { [key: string]: { [idKey in TLocaleId]: string } } = {
  ...en,
  ...fr,
  ...fr_CA,
  ...de,
  ...es,
  ...it,
  ...pt_BR,
  ...ru,
  ...zh_CN,
  ...ja,
  ...pl,
};

export default languages;