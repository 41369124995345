import { LOCALES } from 'src/i18n/locales';
import { TLocaleId } from 'src/i18n';

 // Add polish lang but data according to English(Default Lang)
const polishLangTranslations: { [key: string]: { [idKey in TLocaleId]: string } } = {
  [LOCALES['Polski (Polish)']]: {
    'Login.EditFile': 'Edytuj {file} i zapisz, aby załadować ponownie.',
    'Login.Hello': 'Cześć',
    'Login.Title': 'Witamy na portalu Mursion',
    'Login.Description': 'Projektuj, planuj i zarządzaj symulacjami rzeczywistości wirtualnej',
    'Login.PrivacyPolicy': 'polityka prywatności',
    'Login.PrivacyDataInfo': 'informacje dotyczące prywatności',
    'Login.TechnicalSupport': 'pomoc techniczna',
    'Login.SignIn': 'Zaloguj się',
    'Login.Next': 'Dalej',
    'Login.Email': 'E-mail',
    'Login.Password': 'Hasło',
    'Login.ForgotPassword': 'Zapomniałeś hasło?',
    'Login.Login': 'Zaloguj się',
    'Login.LoginAgain': 'Zaloguj się ponownie',
    'Login.EmailHint': 'wprowadź swój adres e-mail',
    'Login.PasswordHint': 'wprowadź hasło',
    'Login.NewPasswordHint': 'wprowadź nowe hasło',
    'Login.RepeatPasswordHint': 'wprowadź nowe hasło ponownie',
    'Login.Image': 'Mursion-Logo',
    'Filters.Heading': 'FILTRY:',
    'Dashboard.LeftPaneHeader': 'PULPIT NAWIGACYJNY',
    'Dashboard.LeftPane.CategoryHeader.Users': 'UŻYTKOWNICY',
    'Dashboard.LeftPane.User.UserActivity': 'Aktywność użytkownika',
    'Dashboard.LeftPane.User.SchedulingInfo': 'Informacje o harmonogramie',
    'Dashboard.LeftPane.CategoryHeader.Simulations': 'SESJE',
    'Dashboard.LeftPane.Simulations.SimulationSummaries': 'Podsumowania sesji',
    'Dashboard.LeftPane.Simulations.SimulationDetail': 'Szczegóły sesji',
    'Dashboard.LeftPane.CategoryHeader.Projects': 'PROJEKTY',
    'Dashboard.LeftPane.Project.ProjectActivity': 'Aktywność projektów',
    'Dashboard.LeftPane.Project.ProjectUtilization': 'Wykorzystanie projektów',
    'Dashboard.LeftPane.CategoryHeader.Sessions': 'SESJE',
    'Dashboard.LeftPane.Session.SessionLearner': 'Uczniowie w sesji',
    'Dashboard.LeftPane.CategoryHeader.Legacy': 'DZIEDZICTWO',
    'Dashboard.LeftPane.CategoryHeader.Performance': 'WYKORZYSTANIE',
    'Dashboard.LeftPane.Performance.ContractSummaries': 'Podsumowania umów',
    'Dashboard.LeftPane.Performance.ContractOverview': 'Przegląd',
    'Dashboard.LeftPane.Performance.ContractProgress': 'Postęp umowy',
    'Dashboard.LeftPane.Performance.SelfService': 'Wykorzystanie samoobsługowe',
    'Dashboard.LeftPane.CategoryHeader.Learners': 'UCZNIOWIE',
    'Dashboard.LeftPane.Learners.LearnerActivity': 'Aktywność ucznia',
    'Dashboard.LeftPane.Learners.LearnerSimulations': 'Sesje ucznia',
    'Dashboard.LeftPane.CategoryHeader.Videos': 'WIDEO',
    'Dashboard.LeftPane.Videos.NotUploaded': 'Nagrania nie zostały przesłane',
    'Dashboard.NotUploaded.MainHeader': 'Nagrania nie zostały przesłane',
    'Dashboard.NotUploaded.TableHeader': 'Dane nie przesłanych nagrań',
    'Dashboard.VideosTable.Column.SessionId': 'IDsesji',
    'Dashboard.VideosTable.Column.SimspecialistId': 'IDSIMSpecialist',
    'Dashboard.VideosTable.Column.ClientId': 'IDklienta',
    'Dashboard.VideosTable.Column.SessionStart': 'Rozpoczęcie sesji',
    'Dashboard.VideosTable.Column.SessionEnd': 'Zakończenie sesji',
    'Dashboard.VideosTable.Column.SoftwareVersion': 'Wersja scenariusza',
    'Dashboard.VideosTable.HoverTitle': '',
    'Dashboard.VideosTable.NoVideosError': 'Nie znaleziono żadnych filmów.',
    'Dashboard.Button.ExportToEmail': 'Eksportuj dane',
    'Dashboard.Button.ExportToEmailSentMsg': 'Raport zostanie wkrótce wysłany na adres {email}.',
    'Dashboard.Button.ExportToEmail.HoverTitle': 'Eksportuj dane',
    'Dashboard.Button.ExportToEmail.HoverTitleNotAvailable': 'Eksport jest niedostępny',
    'Dashboard.Next': 'Dalej',
    'Dashboard.Last': 'Ostatnia',
    'Dashboard.Help': 'Pomoc',
    'Dashboard.First': 'Pierwsza',
    'Dashboard.Back': 'Wstecz',
    'Dashboard.Total': 'Łączna liczba wejść',
    'Dashboard.Simulations.SimulationSummaries.TableHeader': 'Dane podsumowujące sesję',
    'Dashboard.Learners.LearnerActivity.TableHeader': 'Dane dotyczące aktywności ucznia',
    'Dashboard.Learners.LearnerSimulation.TableHeader': 'Dane dotyczące sesji ucznia',
    'FiltersHeading': 'FILTRY:',
    'Filters.All': 'Wszystko',
    'Filters.More': 'więcej',
    'Filters.AllExcept': 'Wszystko oprócz',
    'Filters.MoreClients': 'więcej klientów',
    'Filters.Client': 'Klient',
    'Filters.SimSpecialist': 'Imię SIM',
    'Filters.AllClients': 'Wszyscy klienci',
    'Filters.ClientPlaceHolderHint': 'Wyszukaj klientów...',
    'Filters.SimSpecialistPlaceHolderHint': 'Szukaj SimSpecialists',
    'MursionPortal.Placeholder.ClientValue': 'Wybierz klienta',
    'MursionPortal.Placeholder.ContractValue': 'Wybierz umowę',
    'MursionPortal.Placeholder.NoContractValue': 'Brak dostępnej umowy',
    'MursionPortal.Placeholder.SimSpecialistValue': 'Wybierz SimSpecialist',
    'Filters.Industries': 'Branże',
    'Filters.IndustriesPlaceHolderHint': 'Wyszukaj branże...',
    'Filters.IndustriesDisabledTitle': 'Nieaktywne',
    'Filters.Project': 'Projekt',
    'Filters.ProjectPlaceHolderHint': 'Wyszukaj projekty...',
    'Filters.ProjectDisabledTitle': 'Najpierw wybierz klientów',
    'Filters.Role': 'Rola',
    'Filters.RoleDisabledTitle': 'Wybór roli jest niedostępny',
    'Filters.Scenario': 'Scenariusz',
    'Filters.ScenarioPlaceHolderHint': 'Wyszukaj scenariusze...',
    'Filters.ScenarioDisabledTitle': 'Najpierw wybierz klientów i projekty',
    'Filters.Team': 'Zespół',
    'Filters.TeamPlaceHolderHint': 'Wyszukaj zespoły...',
    'Filters.TeamDisabledTitle': 'Najpierw wybierz klientów i projekty',
    'Filters.SessionStatus': 'Status sesji',
    'Filters.SessionStatusPlaceHolderHint': 'Wyszukaj status sesji...',
    'Filters.SimulationStatus': 'Status sesji',
    'Filters.SimulationStatusPlaceholder': 'Wyszukaj status symulacji...',
    'Filters.LearnerStatus': 'Status ucznia',
    'Filters.LearnerStatusPlaceHolderHint': 'Wyszukaj status ucznia...',
    'Filters.RegistrationStatus': 'Status rejestracji',
    'Filters.RegistrationStatus.ShowAllRegistration': 'Status rejestracji: Wszystkie',
    'Filters.RegistrationStatus.ShowAcceptedRegistration': 'Rejestracja zaakceptowana',
    'Filters.RegistrationStatus.ShowNotAcceptedRegistration': 'Rejestracja nie została zaakceptowana',
    'Filters.TimeZone': 'Strefa czasowa',
    'Filters.Date.LastMonth': 'Poprzedni miesiąc',
    'Filters.Date.Yesterday': 'Wczoraj',
    'Filters.Date.Week': 'Tydzień',
    'Filters.Date.Today': 'Dzisiaj',
    'Filters.Date.Tomorrow': 'Jutro',
    'Filters.Date.NextMonth': 'Następny miesiąc',
    'Filters.Date.LastSevenDays': 'Zeszły tydzień',
    'Filters.Date.NextSevenDays': 'Następny tydzień',
    'Filters.Date.ThisYear': 'Bieżący rok',
    'Filters.Date.From': 'Od',
    'Filters.Date.FromDate': 'Od dnia',
    'Filters.Date.To': 'Do',
    'Filters.Date.ToDate': 'Do dnia',
    'Filters.SimulationRecorded': 'Symulacja zarejestrowana',
    'Filters.Standardized': 'Standaryzowane',
    'Filters.LateRescheduled': 'Przełożone z opóźnieniem',
    'Filters.Boolean.Yes': 'Tak',
    'Filters.Boolean.No': 'Nie',
    'Filters.SsoProtocolType': 'Protokół: Wszystkie',
    'Filters.SsoProtocolTypePlaceHolderHint': 'Wyszukaj protokół SSO',
    'Filters.SSO.PrivacyAll': 'Typ: Wszystkie',
    'Filters.SSO.PrivacyPublic': 'Typ: Publiczne',
    'Filters.SSO.PrivacyPrivate': 'Typ: Prywatne',
    'Filters.SSO.PrivacyAriaLabel': 'filtr typu prywatyzującego sso',
    'Filters.Contract': 'Umowa',
    'Filters.Session': 'Sesja',
    'Dashboard.Filters.SimulationID': 'ID sesji',
    'Dashboard.UserActivity.MainHeader': 'Aktywność użytkownika',
    'Dashboard.UserActivity.TableHeader': 'Dane dotyczące aktywności użytkownika',
    'Dashboard.UserActivityTable.HoverTitle': 'Wyświetl podsumowanie użytkownika',
    'Dashboard.Export.ErrMsg': 'Maksymalny limit wpisów do eksportu to {limit}. Zmodyfikuj prośbę i spróbuj ponownie.',
    'MursionPortal.Close': 'Zamknij',
    'MursionPortal.GenerateLearnerSurveyLink': 'Skopiuj link do ankiety',
    'MursionPortal.SurveyLinkLabel': 'Link do ankiety został skopiowany do schowka',
    'MursionPortal.Attention': 'Uwaga!',
    'MursionPortal.LinkCannotBeGenerated': 'Nie można wygenerować linku, ponieważ w tym projekcie scenariusza brakuje pola ID umowy.',
    'Dashboard.UserTable.Column.Provider': 'Dostawca',
    'Dashboard.UserTable.Column.Client': 'Klient',
    'Dashboard.UserTable.Column.ProfileId': 'ID profilu',
    'Dashboard.UserTable.Column.UserName': 'Imię',
    'Dashboard.UserTable.Column.UserEmail': 'E-mail użytkownika',
    'Dashboard.UserTable.Column.UserEmail.ButtonHoverTitle': 'Skopiuj e-mail',
    'Dashboard.UserTable.Column.Roles': 'Rola',
    'Dashboard.UserTable.Column.SSO': 'SSO',
    'Dashboard.UserTable.Column.DateInvited': 'Zaproszony',
    'Dashboard.UserTable.Column.DateRegistered': 'Zarejestrowany',
    'Dashboard.UserTable.Column.DateRegistered.ButtonTitle': 'Wyślij zaproszenie ponownie',
    'Dashboard.UserTable.Column.DateArchived': 'Zarchiwizowane',
    'Dashboard.UserTable.Column.DateLastLogin': 'Ostatnie logowanie',
    'Dashboard.UserTable.Column.Scenarios': 'Scenariusze',
    'Dashboard.UserTable.Column.Teams': 'Zespoły',
    'Dashboard.UserTable.Column.Industry': 'Branża',
    'Dashboard.UserTable.Column.UsedSoftwareVersions': 'Software',
    'Dashboard.UserTable.Column.Accepted': 'Zaakceptowane',
    'Dashboard.UserTable.Column.SessionsScheduled': 'Symulacje zaplanowane',
    'Dashboard.UserTable.Column.SessionsCompleted': 'Symulacje zakończone',
    'Dashboard.UserTable.Column.SessionsMissed': 'Symulacje pominięte',
    'Dashboard.UserTable.Column.LateCancellations': 'Symulacje anulowane',
    'Dashboard.UserTable.Column.SessionsError': 'Błędy symulacji',
    'Session.Tab.Scheduled': 'Zaplanowane',
    'Session.Tab.Active': 'Aktywne',
    'Session.Tab.Past': 'Minione',
    'Session.Tab.Cancelled': 'Anulowane',
    'Session.Search': 'Szukaj',
    'Session.Table.Column.Time': 'Czas',
    'Session.Table.Column.Client': 'Klient',
    'Session.Table.Column.Project': 'Projekt',
    'Session.Table.Column.ProjectId': 'ID umowy',
    'Session.Table.Column.SoftwareVersion': 'Wersja software',
    'Session.Table.Column.Scenario': 'Scenariusz',
    'Session.Table.Column.Trainees': 'Kursanci',
    'Session.Table.Column.Training': 'szkolenie',
    'Session.Table.Column.Session': 'sesja',
    'Session.Token.Label.Clients': 'Klienci',
    'Session.Token.Label.Projects': 'Projekty',
    'Session.Token.Label.Scenarios': 'Scenariusze',
    'Session.Token.Label.Teams': 'Zespoły',
    'Session.Token.Label.SessionStatuses': 'Statusy sesji',
    'Session.Button.More': 'Więcej',
    'Session.Time.NotRunning': 'Jeszcze nie rozpoczęte',
    'Session.Time.Finished': 'Zakończone',
    'Session.Time.Running': 'Trwające',
    'Session.Table.Column.Learners': 'Uczniowie',
    'Session.Table.Column.Team': 'Zespół',
    'Session.Table.Column.Trainer': 'Instruktor',
    'Session.Table.Column.SimSpecialist': 'Sim Specialist',
    'Session.Table.Column.TimeUntil': 'Czas do',
    'Session.Table.Column.Created': 'Utworzone',
    'Session.Table.Column.Cancelled': 'Anulowane',
    'Session.Table.Caption.SessionDetailsList': 'Lista szczegółów sesji',
    'Session.Export': 'Eksport',
    'Session.Export.Message': 'Zawartość eksportu filtrowana według',
    'Session.CalendarDate.Start': 'Od:',
    'Session.CalendarDate.End': 'Do:',
    'Session.Cancel': 'Anuluj',
    'Session.Confirm': 'Potwierdź',
    'Session.Total': 'RAZEM',
    'Session.Filter.SessionNotFoundMessage': 'Nie znaleziono sesji.',
    'Session.Filter.LearnerUpcomingSessionNotFoundMessage': 'Nie masz zaplanowanych nadchodzących sesji.',
    'Session.Filter.UpcomingSessionNotFoundMessage': 'Brak zaplanowanych nadchodzących sesji.',
    'Session.Filter.LearnerActiveSessionNotFoundMessage': 'Nie masz aktywnych sesji.',
    'Session.Filter.ActiveSessionNotFoundMessage': 'Brak aktywnych sesji.',
    'Session.Filter.LearnerCompletedSessionNotFoundMessage': 'Nie ukończyłeś jeszcze żadnej sesji.',
    'Session.Filter.SessionReportNotFoundMessage': 'Brak dostępnego raportu z sesji.',
    'Session.Filter.CompletedSessionNotFoundMessage': 'Żadna sesja nie została zakończona.',
    'Session.Filter.CancelledSessionNotFoundMessage': 'Żadna sesja nie została anulowana.',
    'Session.Edit.Modal.Table.Column.DateTime': 'Data/Godzina',
    'Session.Edit.Modal.Table.Column.UserRole': 'Użytkownik (rola)',
    'Session.Edit.Modal.Table.Column.Change': 'Zmień',
    'Session.Edit.Modal.Table.Column.SessionStatus': 'Status sesji',
    'Session.Edit.Modal.Table.Column.Reason': 'Powód',
    'Session.Edit.Modal.AssetIndustry': 'Przemysł zasobów',
    'Session.Edit.Modal.SimulationEnvironment': 'Środowiska symulacji',
    'Session.Edit.Modal.AvatarInSimulation': 'Awatary w symulacjach',
    'Session.Edit.Modal.Date': 'Data',
    'Session.Edit.Modal.Time': 'Czas',
    'Session.Edit.Modal.DeliveryMode': 'Metoda dostawy',
    'Session.Edit.Modal.Duration': 'Czas trwania',
    'Session.Edit.Modal.Status': 'Status',
    'Session.Edit.Modal.LearnersAttended': 'Uczestniczący uczniowie',
    'Session.Edit.Modal.ReasonForCancellation': 'Powód anulowania',
    'Session.Edit.Modal.MoreInfo': 'Więcej informacji...',
    'Session.Edit.Modal.HideAdditionalInfo': 'Ukryj dodatkowe informacje...',
    'Session.Edit.Modal.AddToCalendar': 'Dodaj do kalendarza',
    'Session.Edit.Modal.AttendSession': 'Weź udział w sesji',
    'Session.Edit.Modal.PleaseWait': 'Proszę czekać...',
    'Session.Edit.Modal.CopySessionLink': 'Skopiuj link do sesji',
    'Session.Edit.Modal.SessionLink': 'Link do sesji',
    'Session.Edit.Modal.SubmitReview': 'Dodaj recenzję',
    'Session.Edit.Modal.SessionRequestPending': 'Oczekująca prośba o sesję',
    'Session.Edit.Modal.Back': 'Wstecz',
    'Session.Edit.Modal.Learners': 'Uczniowie:',
    'Session.Edit.Modal.Learners.UnauthenticatedCheckbox': 'Dołączą tylko nieuwierzytelnieni użytkownicy',
    'Session.Edit.Modal.Trainees': 'Kursanci:',
    'Session.Edit.Modal.Trainer': 'Instruktor:',
    'Session.Edit.Modal.SimSpecialist': 'Sim Specialist:',
    'Session.Edit.Modal.Notes': 'Uwagi:',
    'Session.Edit.Modal.ClientNotes': 'Uwagi klienta:',
    'Session.Edit.Modal.Notes.EmptyMessage': 'Brak uwag',
    'Session.Edit.Modal.Attendance': 'Frekwencja:',
    'Session.Edit.Modal.ShowHistory': 'Pokaż historię...',
    'Session.Edit.Modal.HideHistory': 'Ukryj historię...',
    'Session.Edit.Modal.LearnersAttendedLabel': 'Uczestniczący uczniowie:',
    'Session.Edit.Modal.SimulationContent': 'Zawartość symulacji',
    'Session.Edit.Modal.SimulationContent.Name': 'Nazwa:',
    'Session.Edit.Modal.SimulationContent.Description': 'Opis:',
    'Session.Edit.Modal.SimulationContent.Image': 'Obraz:',
    'Session.Edit.Modal.RestoreSession.ConfirmationText': 'Czy na pewno chcesz przywrócić tę sesję?',
    'Session.Edit.Modal.CancelTraining.ConfirmationText': 'Czy na pewno chcesz anulować to szkolenie?',
    'Session.Edit.Modal.CancelSession.ConfirmationText': 'Czy na pewno chcesz anulować tę sesję?',
    'Session.Edit.Modal.SessionSwap.ConfirmationText': 'Czy na pewno chcesz zaakceptować zamianę tej sesji?',
    'Session.Edit.Modal.AcceptRequest.ConfirmationText': 'Czy na pewno chcesz zaakceptować tę prośbę o sesję?',
    'Session.Edit.Modal.AssignSS.ConfirmationText': 'Czy na pewno chcesz przypisać nowego Sim Specialist?',
    'Session.Edit.Modal.ChangeSS.ConfirmationText': 'Czy na pewno chcesz zmienić Sim Specialist?',
    'Session.Edit.Modal.Button.RestoreSession': 'Przywróć sesję',
    'Session.Edit.Modal.Button.CancelTraining': 'Anuluj szkolenie',
    'Session.Edit.Modal.Button.CancelSession': 'Anuluj sesję',
    'Session.Edit.Modal.Button.AcceptSwap': 'Zaakceptuj zamianę',
    'Session.Edit.Modal.Button.Leave': 'Anuluj moją sesję',
    'Session.Edit.Modal.Button.Participate': 'Weź udział',
    'Session.Edit.Modal.Button.Swap': 'Zamień',
    'Session.Edit.Modal.Button.UpdateSession': 'Zaktualizuj sesję',
    'Session.Edit.Modal.Button.UpdateTraining': 'Zaktualizuj szkolenie',
    'Session.Edit.Modal.Button.AcceptRequest': 'Zaakceptuj prośbę',
    'Session.Edit.Modal.Button.AssignSS': 'Przypisz SS',
    'Session.Edit.Modal.Button.ChangeSS': 'Zmień SS',
    'Session.Attendance.Table.Column.UserName': 'Nazwa użytkownika',
    'Session.Attendance.Table.Column.Time': 'Czas',
    'Session.Attendance.Table.Column.Status': 'Status',
    'Session.ViewSessionReport': 'Zobacz raport z sesji',
    'Session.ViewEdit': 'Wyświetl/edytuj sesję',
    'Session.Edit.Modal.Table.HistoryNotFoundMessage': 'Nie ma jeszcze historii',
    'Session.Export.ErrMsg': 'Maksymalny limit wpisów do eksportu to {limit}. Zmodyfikuj prośbę i spróbuj ponownie.',
    'Session.Table.Column.Action': 'Działanie',
    'Session.Edit.Modal.SessionSimSpecialistField.EmptyMsg.NoTrainers': 'Brak instruktorów do wyboru',
    'Session.Edit.Modal.SessionSimSpecialistField.EmptyMsg.NoSimSpecialists': 'Brak sim specialists do wyboru',
    'Session.Edit.Modal.SessionSimSpecialistField.NoSelectedMsg.NoTrainers': 'Nie wybrano instruktorów',
    'Session.Edit.Modal.SessionSimSpecialistField.NoSelectedMsg.NoSimSpecialists': 'Nie wybrano sim specialists',
    'Session.Edit.Modal.SessionSimSpecialistField.EditIconLabel': 'edytuj',
    'Session.Edit.Modal.SessionSimSpecialistField.Search.Placeholder': 'Szukaj',
    'Session.Edit.Modal.SessionSimSpecialist.Button.Accept': 'Zaakceptuj',
    'ConfirmationDialogue.AddReasonForChange': 'Proszę, podaj powód zmiany',
    'ConfirmationDialogue.Button.Submit': 'Wyślij',
    'ConfirmationDialogue.Button.Cancel': 'Anuluj',
    'ConfirmationDialogue.Button.Ok': 'Ok',
    'ConfirmationDialogue.ReasonForChange': 'Powód zmiany',
    'ConfirmButton.CancelSession.ConfirmCancellation': 'Potwierdź anulowanie',
    'ConfirmButton.CancelSession': 'Anuluj sesję',
    'ConfirmButton.CancelSession.No': 'Nie',
    'Pagination.Button.First': 'Pierwsza',
    'Pagination.Button.Back': 'Wstecz',
    'MursionPortal.UserName': 'Nazwa użytkownika',
    'MursionPortal.Email': 'E-mail',
    'ClientUserTable.Column.Roles': 'Role',
    'MursionPortal.Department': 'Dział',
    'MursionPortal.Location': 'Lokalizacja',
    'ClientUserTable.Column.Projects': 'Projekty',
    'ClientUserTable.Column.Teams': 'Zespoły',
    'ClientUserTable.Column.Accepted': 'Zaakceptowane',
    'ClientUserTable.HoverTitle': 'Wyświetl/edytuj użytkownika',
    'ClientUserTable.NoUserError': 'Nie ma jeszcze użytkowników',
    'MursionPortal.FirstName': 'Imię',
    'MursionPortal.LastName': 'Nazwisko',
    'MursionPortal.Phone': 'Telefon',
    'Users.TableModal.EmailPlaceHolder': 'example@domain.com',
    'Users.TableModal.Title': 'Tytuł',
    'Users.TableModal.LocationPlaceHolder': 'Miasto, kraj',
    'Users.TableModal.DepartmentPlaceHolder': 'Nazwa działu',
    'Users.TableModal.TitlePlaceHolder': 'Stanowisko',
    'Users.TableModal.SSOIDPlaceHolder': 'SSO ID',
    'Users.Table.Modal.SSOID': 'SSO ID',
    'Users.Table.Modal.SettingsHeader': 'Ustawienia Facilitator/Uczeń',
    'Users.Table.Modal.SignLanguageInterpreter': 'Tłumacz języka migowego',
    'Users.Table.ModalHeader': 'Informacje użytkownika',
    'Users.Table.ModalButton.Cancel': 'Anuluj',
    'Users.Table.ModalButton.Delete': 'Usuń',
    'Users.Table.ModalButton.Save': 'Zapisz',
    'Users.Table.ModalButton.SendInvite': 'Wyślij zaproszenie',
    'Users.Table.ModalButton.Restore': 'Przywróć',
    'Users.Table.ErrorOnLoadUserMessage': 'Wystąpił błąd podczas ładowania użytkownika!',
    'Users.Table.Modal.Teams.Text': 'Nie dodano jeszcze żadnych zespołów',
    'Users.Table.Modal.Teams': 'Zespoły',
    'Users.Table.Modal.EmployeeIdentification': 'Identyfikacja pracownika',
    'Users.Table.Modal.Timezone': 'Strefa czasowa',
    'Users.Table.Modal.Roles': 'Role',
    'Users.Table.Modal.Settings.LearnerButton': 'Jako uczeń',
    'Users.Table.Modal.Settings.FacilitatorButton': 'Jako Facilitator',
    'Users.Button.CreateUserButton': 'Utwórz użytkownika',
    'Users.Button.Cancel': 'Anuluj',
    'Users.Button.ImportUsers': 'Importuj użytkowników',
    'Users.Button.Export': 'Eksport',
    'Users.heading.ExportContentFilteredBy': 'Zawartość eksportu filtrowana według',
    'Users.Button.Active': 'AKTYWNE',
    'Users.Button.Archived': 'ZARCHIWIZOWANE',
    'Users.Button.All': 'WSZYSTKO',
    'Users.Text.Total': 'RAZEM',
    'Users.Heading.NewUsers': 'Nowi użytkownicy',
    'Users.Placeholder': 'example@domain.com',
    'Users.Button.AddUser': 'Dodaj użytkownika',
    'Users.Button.CreateUser': 'Utwórz użytkownika',
    'Users.Button.CreateUsers': 'Utwórz użytkowników',
    'Users.Heading.ImportNewUsers': 'Importuj nowych użytkowników',
    'Users.Heading.ImportNewExternalUsers': 'Importuj nowych użytkowników zewnętrznych',
    'Users.Button.ImportUserList': 'Importuj listę użytkowników',
    'Users.Text.InstructionMessage.UsersWithoutPredefined': 'Użytkownicy bez predefiniowanych zespołów zostaną przypisani do',
    'Users.Text.AddCsv': 'Dodaj plik .csv lub .xslx zawierający listę adresów e-mail umieszczonych w jednej kolumnie.',
    'Users.Text.ToImport': 'Aby zaimportować dodatkowe pola, postępuj zgodnie z tymi wskazówkami:',
    'Users.Text.ListUser': 'Adres e-mail użytkownika musi być oddzielony od listy zespołów użytkownika przecinkiem, średnikiem, kreską pionową lub dwukropkiem.',
    'Users.Text.ListTeams': 'Zespoły muszą być oddzielone od siebie przecinkami.',
    'Users.Text.ListFields': 'Pola i każdy z zespołów muszą być określone w osobnych komórkach.',
    'Users.Text.UserRole': 'Typ roli użytkownika będzie ustawiony domyślnie jako ucznia',
    'Users.Text.UnlessOtherwiseIndicated': 'chyba że wskazano inaczej',
    'Users.Text.SampleImport': 'Przykładowe pliki importu można pobrać tutaj:',
    'Users.DownloadCsvTemplate': 'Pobierz szablon CSV',
    'Users.DownloadExcelTemplate': 'Pobierz szablon Excel',
    'Users.Button.Update': 'Aktualizacja',
    'Users.Labels.Clients': 'Klienci',
    'Users.Labels.Projects': 'Projekty',
    'Users.Labels.Scenarios': 'Scenariusze',
    'Users.Labels.Teams': 'Zespoły',
    'Users.Labels.Roles': 'Role',
    'Users.Text.OnlyAccepted': 'Tylko zaakceptowane:',
    'Users.Button.Confirm': 'Potwierdź',
    'Users.Button.AccOwner': 'Account Owner',
    'Users.Button.Facilitator': 'Facilitator',
    'Users.Button.Learner': 'Uczeń',
    'Users.Button.ProfServices': 'Usługi Profesjonalne',
    'Users.Button.ProjectManager': 'Kierownik Projektu',
    'Users.Button.SimSpecialist': 'Sim Specialist',
    'Users.Button.ITManager': 'Kierownik działu IT',
    'ITManager.TechRestriction.Table.CreateButton': 'Dodaj ograniczenie',
    'ITManager.TechRestriction.Table.EmptyMessage': 'Nie znaleziono żadnych ograniczeń technicznych banku.',
    'ITManager.TechRestriction.Table.Tag': 'Tag',
    'ITManager.TechRestriction.Table.StartDate': 'Data rozpoczęcia',
    'ITManager.TechRestriction.Table.EndDate': 'Data zakończenia',
    'ITManager.TechRestriction.EditDialog.TagName': 'Nazwa tagu',
    'ITManager.TechRestriction.EditDialog.Title': 'Edytuj ograniczenie techniczne',
    'ITManager.TechRestriction.CreateDialog.Title': 'Nowe ograniczenie techniczne',
    'ITManager.TechRestriction.ViewDialog.Title': 'Zobacz ograniczenia techniczne',
    'ITManager.TechRestriction.EditDialog.EnterTag': 'Wpisz nazwę tagu',
    'ITManager.TechRestriction.Table.HoverTitle': 'Wyświetl/edytuj ograniczenia techniczne',
    'ITManager.TechRestriction.Table.NoTechRestrictionError': 'Nie ma jeszcze żadnych ograniczeń technicznych',
    'Users.Button.BusinessAdmin': 'Administrator Biznesowy',
    'Users.Button.SupportAdmin': 'Administrator Pomocy Technicznej',
    'Users.Button.DevAdmin': 'Administrator Deweloper',
    'Users.Button.ImportExternalUsers': 'Importuj użytkowników zewnętrznych',
    'Users.Button.AssignLearners': 'Przypisz uczniów',
    'Users.CreateUser.Email.Placeholder': 'example@domain.com',
    'Users.Text.Instructions': 'Dodaj plik .csv lub .xslx zawierający listę użytkowników z następującymi polami: e-mail, ssoid, imię, nazwisko, rola, zespół. Wszystkie pola oprócz zespołu są wymagane.',
    'Users.Text.Guidelines': 'Postępuj zgodnie z tymi wskazówkami:',
    'Users.Button.CreateUser.PencilIcon': 'Edytuj dane użytkownika',
    'Users.Button.CreateUser.TrashIcon': 'Usuń użytkownika',
    'Users.ExportButton.TextYes': 'Tak',
    'Users.ExportButton.TextNo': 'Nie',
    'Users.Button.OK': 'OK',
    'Users.Button.AddProject': 'Dodaj projekt',
    'Users.CreateUser.Label.AddToProjects': 'Dodaj do projektów',
    'Users.UserInfo.Label.AddTeams': 'Dodaj zespoły',
    'Users.UserInfo.Label. SelectATeam': 'Wybierz zespół',
    'Users.UserInfo.Label.AssignToTeam': 'Przypisz do zespołu:',
    'Users.CreateUser.Label.NoProjectsSpecified': 'Nie określono projektów',
    'Users.Option.SelectClient': 'Wybierz Klienta',
    'Users.Label.Client': 'Klient:',
    'Users.Label.RemoveTeam': 'Usuń zespół',
    'Users.Label.ChooseATeam': 'Wybierz zespół',
    'Users.Project.Warning': 'Ten klient nie ma projektów. Wybierz innego.',
    'Users.Project.Warning.UnknownProject': 'Projekt nieznany',
    'Users.ProjectContainer.Warning': 'Należy dodać co najmniej jeden projekt',
    'Users.CompanyUsers.Button.Ok': 'Ok',
    'Users.CompanyUsers.Button.Accept': 'Zaakceptuj',
    'Users.CreateUser.Label.Tier': 'Poziom',
    'Users.CreateUser.Label.HireDate': 'Data zatrudnienia',
    'Users.CreateUser.Label.StationType': 'Rodzaj stanowiska',
    'Users.CreateUser.ToggleButton.Home': 'Dom',
    'Users.CreateUser.ToggleButton.Office': 'Biuro',
    'Users.CreateUser.Label.Hours': 'Godziny',
    'Users.CreateUser.ToggleButton.FullTime': 'Pełny etat',
    'Users.CreateUser.ToggleButton.PartTime': 'Pół etatu',
    'Users.CreateUser.Label.ZoomID': 'Zoom ID',
    'Users.CreateUser.ZoomID.Placeholder': 'Zoom ID',
    'Users.CreateUser.ZoomID.Hover': 'Wprowadź Zoom ID',
    'Users.CreateUser.Label.UserRoles': 'Role użytkownika',
    'Users.CreateUser.Label.ProjectManagerSettings': 'Ustawienia Kierownika Projektu',
    'Users.CreateUser.Label.SimSpecialistSettings': 'Ustawienia Sim Specialist',
    'Users.CreateUser.Label.Certificates': 'Certyfikaty',
    'Users.CreateUser.Label.TurnOnEditCertificates': 'Włącz edycję certyfikatów',
    'Users.CreateUser.Label.TurnOffEditCertificates': 'Wyłącz edycję certyfikatów',
    'Users.CreateUser.Label.NoCertificatesHaveBeenAddedYet': 'Nie dodano jeszcze żadnych certyfikatów',
    'Users.CreateUser.Label.AddCertificate': 'Dodaj certyfikat',
    'Users.CreateUser.Label.CertificateOwner': 'Właściciel certyfikatu',
    'Users.CreateUser.Button.RemoveCertificate': 'Usuń certyfikat',
    'Users.TableHeading.Name': 'Imię',
    'Users.TableHeading.Email': 'E-mail',
    'Users.TableHeading.Roles': 'Role',
    'Users.TableHeading.Location': 'Lokalizacja',
    'Users.TableHeading.Accepted': 'Zaakceptowane',
    'Users.Table.Hover.ViewEditUser': 'Wyświetl/edytuj użytkownika',
    'Users.Table.Hover.NoUsersYet': 'Nie ma jeszcze użytkowników',
    'Users.CreateUser.Button.Client': 'Klient',
    'Users.CreateUser.Label.ChooseAClient': 'Wybierz klienta',
    'Users.CreateUser.Label.SelectAClient': 'Wybierz klienta',
    'Users.CreateUser.Label.ChooseACertificate': 'Wybierz certyfikat',
    'Users.CreateUser.Label.SelectACertificate': 'Wybierz certyfikat',
    'Users.CreateUser.Label.NoCertificatesToSelect': 'Brak certyfikatów do wyboru',
    'Users.CreateUser.Button.AddCertificate': 'Dodaj certyfikat',
    'Users.CreateUser.PlaceHolder.SelectTier': 'Wybierz poziom',
    'Users.RequiredField.Warning': 'Sprawdź, czy wszystkie pola są wypełnione poprawnie',
    'Users.RequiredField.ApplyChanges': 'Zatwierdź zmiany',
    'Users.Heading.NewUserInfo': 'Informacje o nowym użytkowniku',
    'Users.SearchPlaceHolderText': 'Wpisz nazwę użytkownika',
    'Users.SearchNoUserFoundError': 'Nie znaleziono żadnych użytkowników klienta.',
    'Users.Table.Modal.Settings.AddTeamHeader': 'Dodaj zespoły',
    'Users.Table.Modal.Settings.ChooseTeamHeader': 'Wybierz zespół',
    'Users.Table.Modal.Settings.AssignTeamHeader': 'Przypisz do zespołu:',
    'Users.Table.Modal.Settings.SelectTeamOptionHeader': 'Wybierz zespół',
    'Users.Table.Modal.Settings.TeamLearner': '(uczeń)',
    'Users.Table.Modal.Settings.TeamFacilitator': '(facilitator)',
    'Users.Table.Modal.Settings.RemoveTeamOption': 'Usuń zespół',
    'Users.Table.Modal.Settings.TurnOnEditTeamsOption': 'Włącz edycję zespołów',
    'Users.Table.Modal.Settings.TurnOffEditTeamsOption': 'Wyłącz edycję zespołów',
    'Users.Total': 'RAZEM',
    'Users.Table.Modal.ArchiveHeader.ConfirmArchiveUser': 'Potwierdź archiwizację użytkownika',
    'Users.Table.Modal.ArchiveText': 'Czy na pewno chcesz zarchiwizować tego użytkownika?',
    'Users.Table.Modal.ArchiveUserButton': 'Archiwizuj użytkownika',
    'Users.Table.Modal.RestoreHeader.ConfirmRestoreUser': 'Potwierdź przywrócenie użytkownika',
    'Users.Table.Modal.RestoreText': 'Czy na pewno chcesz przywrócić tego użytkownika?',
    'Users.Table.Modal.RestoreUserButton': 'Przywróć użytkownika',
    'Users.Table.Modal.CancelButton': 'Anuluj',
    'Users.Table.Modal.ArchiveButton': 'Archiwizuj',
    'Users.Table.Modal.RestoreButton': 'Przywróć',
    'Users.Table.Modal.SendInviteButton': 'Wyślij zaproszenie',
    'Users.CreateUserButton.Modal.CreateUserButton': 'Utwórz użytkownika',
    'Users.Table.Modal.UpdateUserButton': 'Zaktualizuj użytkownika',
    'Users.ViewUserOptions': 'Wyświetl użytkownika {companyName}',
    'Users.NewUserOptions': 'Nowy użytkownik {companyName}',
    'Users.EditUserOptions': 'Edytuj użytkownika {companyName}',
    'Reports.SessionListHeader.Completed': 'Ukończone',
    'Reports.SessionListHeader.TotalSessionTime': 'Całkowity czas trwania sesji:',
    'Reports.SessionListHeader.TotalActualTime': 'Całkowity czas rzeczywisty:',
    'Reports.SessionListHeader.SimulationCompletionTime': 'Czas zakończenia symulacji',
    'Reports.SessionListHeader.CantOpenReport': 'Poczekaj, aż nasz silnik analityczny przetworzy Twoje wyniki. Po kilku minutach może być konieczne odświeżenie tej strony.',
    'Reports.SessionListHeader.NoReportsFound': 'Nie znaleziono raportów.',
    'Reports.ReportCard.Button.ReviewSimulation': 'PRZEGLĄDAJ SYMULACJĘ',
    'Reports.ReportCard.Strategies': 'Strategie',
    'Reports.ReportCard.NotChecked': 'Nie sprawdzono',
    'Dashboard.SchedulingInfoTable.Modal.SortBy': 'Sortuj według:',
    'Dashboard.SchedulingInfoTable.Modal.NoItemsFound': 'Nie znaleziono żadnych elementów.',
    'Dashboard.SchedulingInfoTable.Modal.Teams': 'Zespoły',
    'Dashboard.SchedulingInfoTable.Modal.Teams.Placeholder': 'Wpisz nazwę zespołu',
    'Dashboard.SchedulingInfoTable.Modal.Teams.EmptyMessage': 'Nie znaleziono żadnych zespołów',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.TeamName': 'Nazwa zespołu:',
    'Dashboard.SchedulingInfoTable.Modal.Learners': 'Uczniowie:',
    'Dashboard.SchedulingInfoTable.Modal.Projects': 'Projekty:',
    'Dashboard.SchedulingInfo.Filter.IndustriesAll': 'Branża',
    'Dashboard.SchedulingInfo.Filter.SearchIndustries.PlaceholderHint': 'Wyszukaj branże...',
    'Dashboard.SchedulingInfo.Filter.IndustryDisabledTitle': 'Nieaktywne',
    'Dashboard.SchedulingInfo.Filter.UsedSoftwareVersion': 'Software',
    'Dashboard.SchedulingInfo.Filter.UsedSoftwareVersion.PlaceholderHint': 'Używane wersje software...',
    'Dashboard.SchedulingInfoTable.Modal.Total': 'RAZEM',
    'Dashboard.SchedulingInfoTable.Modal.ScenarioName': 'Nazwa scenariusza',
    'Dashboard.SchedulingInfoTable.Modal.SessionDate': 'Data sesji',
    'Dashboard.SchedulingInfoTable.Modal.ProjectName': 'Nazwa projektu',
    'Dashboard.SchedulingInfoTable.Modal.SoftwareVersion': 'Wersja software',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.DeliveryMode': 'Metoda dostawy',
    'Dashboard.SchedulingInfoTable.Modal.SessionStatus': 'Status sesji',
    'Dashboard.SchedulingInfoTable.Modal.Name': 'Imię',
    'Dashboard.SchedulingInfoTable.Modal.Status': 'Status',
    'Dashboard.SchedulingInfoTable.Modal.Recording': 'Nagranie trafia do',
    'Dashboard.SchedulingInfoTable.Modal.TeamName': 'Nazwa zespołu',
    'Dashboard.SchedulingInfoTable.Modal.Learner': 'Uczniowie',
    'Dashboard.SchedulingInfoTable.Modal.CompletedSessions': 'Sesje zakończone',
    'Dashboard.SchedulingInfoTable.Modal.DeliveryMode': 'Metoda dostawy',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.ProjectName': 'Nazwa projektu',
    'Dashboard.SchedulingInfoTable.Modal.Capitalized.SoftwareVersion': 'Wersja software',
    'Dashboard.SchedulingInfoTable.Modal.Timeline': 'Oś czasu',
    'Dashboard.SchedulingInfoTable.Modal.SchedulesSessions': 'Sesje zaplanowane',
    'Dashboard.SchedulingInfoTable.Modal.MissedSessions': 'Sesje mieszane',
    'Dashboard.SchedulingInfoTable.Modal.LateCancellations': '\„Opóźnione\” anulowania',
    'Dashboard.SchedulingInfoTable.Modal.TimeSpent': 'Poświęcony czas',
    'Dashboard.SchedulingInfoTable.Modal.CancelledBy': 'Anulowane przez',
    'Dashboard.SchedulingInfoTable.Modal.CancellationReason': 'Powód anulowania',
    'Dashboard.SchedulingInfoTable.Modal.DateScheduled': 'Zaplanowana data',
    'Dashboard.SchedulingInfoTable.Modal.DateCancelled': 'Data anulowania',
    'Dashboard.SchedulingInfo.MainHeader': 'Informacje o harmonogramie',
    'Dashboard.SchedulingInfo.TableHeader': 'Dane informacyjne dotyczące harmonogramu',
    'Dashboard.SchedulingInfoTable.Modal.Filter.Teams': 'Zespoły',
    'Dashboard.SchedulingInfoTable.Modal.Placeholder.EnterTeamName': 'Wpisz nazwę zespołu',
    'Dashboard.SchedulingInfoTable.Modal.TeamsEmptyMessage': 'Nie znaleziono żadnych zespołów',
    'Dashboard.SchedulingInfoTable.Modal.Filter.TeamName': 'Nazwa zespołu',
    'Dashboard.SchedulingInfoTable.Modal.Filter.Scenarios': 'Scenariusze',
    'Dashboard.SchedulingInfoTable.Modal.Placeholder.EnterScenarioName': 'Wprowadź nazwę scenariusza',
    'Dashboard.SchedulingInfoTable.Modal.ScenariosEmptyMessage': 'Nie znaleziono żadnych scenariuszy',
    'Dashboard.SchedulingInfoTable.Modal.Filter.ScenarioName': 'Nazwa scenariusza',
    'Dashboard.SchedulingInfoTable.Modal.Filter.ProjectName': 'Nazwa projektu',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SoftwareVersion': 'Wersja software',
    'Dashboard.SchedulingInfoTable.Modal.Filter.ScenarioStatus': 'Status scenariusza',
    'Dashboard.SchedulingInfoTable.Modal.Filter.DeliveryMode': 'Metoda dostawy',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsScheduled': 'Zaplanowane sesje',
    'Dashboard.SchedulingInfoTable.Modal.SessionsEmptyMessage': 'Nie znaleziono sesji',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsDateTime': 'Data/godzina sesji',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsCompleted': 'Sesje zakończone',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsMissed': 'Sesje pominięte',
    'Dashboard.SchedulingInfoTable.Modal.Filter.LateCancellations': '„Opóźnione” anulowania',
    'Dashboard.SchedulingInfoTable.Modal.Filter.CancelledBy': 'Anulowane przez',
    'Dashboard.SchedulingInfoTable.Modal.Filter.SessionsError': 'Błąd sesji',
    'Dashboard.ContractSummaries.MainHeader': 'Podsumowania umów',
    'Dashboard.ContractOverview.MainHeader': 'Przegląd',
    'Dashboard.ContractProgress.MainHeader': 'Postęp umowy',
    'Dashboard.ContractProgress.Navigation.ContractItems': 'Przedmioty umowy',
    'Dashboard.ContractProgress.Navigation.Projects': 'Projekty',
    'Dashboard.ContractProgress.Navigation.Scenarios': 'Scenariusze',
    'Dashboard.ContractProgress.Column.Item': 'Przedmiot',
    'Dashboard.ContractProgress.Column.Purchased': 'Kupione',
    'Dashboard.ContractProgress.Column.Remaining': 'Pozostałe',
    'Dashboard.ContractProgress.Column.Completed': 'Ukończone',
    'Dashboard.ContractProgress.Column.Scheduled': 'Zaplanowane',
    'Dashboard.ContractProgress.Column.Missed': 'Uczeń pominięty',
    'Dashboard.ContractProgress.Column.LateCancellations': 'Anulowania z opóźnieniem',
    'Dashboard.ContractProgress.Column.TotalRescheduled': 'Wszystkie przełożone',
    'Dashboard.ContractProgress.Column.RescheduledMakeup': 'Nadrobienie przełożonych',
    'Dashboard.ContractProgress.Column.LateRescheduledSimulations': 'Przełożone z opóźnieniem',
    'Dashboard.ContractProgress.Column.EarlyRescheduledSimulations': 'Przełożone z wyprzedzeniem',
    'Dashboard.ContractProgress.Column.ProjectName': 'Nazwa projektu',
    'Dashboard.ContractProgress.Column.StartDate': 'Data rozpoczęcia',
    'Dashboard.ContractProgress.Column.EndDate': 'Data zakończenia',
    'Dashboard.ContractProgress.Column.Error': 'Błąd',
    'Dashboard.ContractProgress.Column.ScenarioName': 'Nazwa scenariusza',
    'Dashboard.ContractProgress.Column.Standardized': 'Standaryzowane',
    'Dashboard.ContractProgress.Column.LearnersAssigned': 'Przypisani uczniowie',
    'Dashboard.ContractProgress.Column.LearnersCompleted': 'Uczniów ukończyło',
    'Dashboard.ContractProgress.Column.LearnersCompletedPercent': '% uczniów ukończyło',
    'Dashboard.ContractProgress.Column.Provider': 'Dostawca',
    'Dashboard.ContractProgress.Column.Client': 'Klient',
    'Dashboard.ContractProgress.Column.ContractName': 'Nazwa umowy',
    'Dashboard.ContractProgress.Column.ContractId': 'ID umowy',
    'Dashboard.ContractProgress.Column.ContractNameHoverTitle': 'Skopiuj ID',
    'Dashboard.ContractProgress.Column.CompletedUtilization': 'Zakończenie sesji',
    'Dashboard.ContractProgress.Column.ScheduledUtilization': 'Szacowane zakończenie',
    'Dashboard.ContractProgress.Column.RemainingUtilization': 'Pozostało do wykorzystania',
    'Dashboard.ContractProgress.Column.EstimatedRemaining': 'Szacunkowo do wykorzystania',
    'Dashboard.ContractProgress.Column.ErrorLearner': 'Błąd (uczeń)',
    'Dashboard.ContractProgress.Column.LearnersInvited': 'Zaproszeni uczniowie',
    'Dashboard.ContractProgress.Column.LearnersScheduledPercent': '10% uczniów zaplanowało',
    'Dashboard.ContractProgress.ColumnTooltip.Client': 'Imię klienta',
    'Dashboard.ContractProgress.ColumnTooltip.ContractName': 'Nazwa umowy',
    'Dashboard.ContractProgress.ColumnTooltip.PercentComplete': 'Procent zrealizowanych dni umowy',
    'Dashboard.ContractProgress.ColumnTooltip.CompletedUtilization': 'Symulacje ukończone jako odsetek symulacji zakupionych',
    'Dashboard.ContractProgress.ColumnTooltip.ScheduledUtilization': 'Ukończone i zaplanowane symulacje jako odsetek symulacji zakupionych',
    'Dashboard.ContractProgress.ColumnTooltip.RemainingUtilization': 'Symulacje pozostałe i zaplanowane jako liczba symulacji zakupionych',
    'Dashboard.ContractProgress.ColumnTooltip.MissedLearner': 'Symulacje, które zostały pominięte przez ucznia.',
    'Dashboard.ContractProgress.ColumnTooltip.ErrorLearner': 'Symulacje, które zakończyły się błędem po stronie połączenia ucznia',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersInvited': 'Uczniowie, którzy zostali zaproszeni do zaplanowania symulacji',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersScheduledPercent': 'Uczniowie, którzy zaplanowali co najmniej jedną symulację jako odsetek osób zaproszonych do zaplanowania symulacji',
    'Dashboard.ContractProgress.ColumnTooltip.Item': 'Pozycje zakontraktowane w umowie',
    'Dashboard.ContractProgress.ColumnTooltip.Purchased': 'Łączna liczba symulacji zakupionych w ramach umowy',
    'Dashboard.ContractProgress.ColumnTooltip.Remaining': 'Zakupione sesje pomniejszone o wszelkie ukończone sesje',
    'Dashboard.ContractProgress.ColumnTooltip.Completed': 'Symulacje, które zostały pomyślnie ukończone',
    'Dashboard.ContractProgress.ColumnTooltip.Scheduled': 'Nadchodzące symulacje, które są zaplanowane, ale nie zostały jeszcze ukończone (w tym te, które aktualnie trwają)',
    'Dashboard.ContractProgress.ColumnTooltip.Missed': 'Symulacje, które zostały pominięte przez ucznia',
    'Dashboard.ContractProgress.ColumnTooltip.LateCancellations': 'Sesje, które zostały anulowane z opóźnieniem (po upływie terminu anulowania) przez account ownera, facilitatora lub ucznia.',
    'Dashboard.ContractProgress.ColumnTooltip.TotalRescheduled': 'Całkowita liczba zaplanowanych symulacji',
    'Dashboard.ContractProgress.ColumnTooltip.RescheduledMakeup': 'Łączna liczba symulacji oznaczonych jako nadrobienie przełożonych',
    'Dashboard.ContractProgress.ColumnTooltip.LateRescheduledSimulations': 'symulacje, które zostały przełożone z opóźnieniem.',
    'Dashboard.ContractProgress.ColumnTooltip.EarlyRescheduledSimulations': 'symulacje, które zostały przełożone z wyprzedzeniem.',
    'Dashboard.ContractProgress.ColumnTooltip.Error': 'Symulacje, które zakończyły się błędem po stronie połączenia ucznia',
    'Dashboard.ContractProgress.ColumnTooltip.ProjectName': 'Nazwa projektu',
    'Dashboard.ContractProgress.ColumnTooltip.StartDate': 'Data rozpoczęcia umowy zgodnie z umową.',
    'Dashboard.ContractProgress.ColumnTooltip.EndDate': 'Data zakończenia umowy zgodnie z umową.',
    'Dashboard.ContractProgress.ColumnTooltip.ScenarioName': 'Nazwa scenariusza',
    'Dashboard.ContractProgress.ColumnTooltip.Standardized': 'To jest scenariusz standaryzowany',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersAssigned': 'Liczba uczniów, którym przypisano scenariusz',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersCompleted': 'Uczniowie, którzy ukończyli co najmniej jedną symulację jako odsetek osób zaproszonych do zaplanowania symulacji',
    'Dashboard.ContractProgress.ColumnTooltip.LearnersCompletedPercent': 'Procent uczniów, którzy ukończyli scenariusz',
    'Dashboard.ContractProgress.InfoBadge.ContractDates': 'Daty umowy:',
    'Dashboard.ContractProgress.InfoBadge.ContractID': 'ID umowy:',
    'Dashboard.SelfService.MainHeader': 'Wykorzystanie samoobsługowe',
    'Dashboard.SelfService.Column.SelfServiceProvider': 'Dostawca',
    'Dashboard.SelfService.Column.SelfServiceTotalHoursUtilized': 'Łączna liczba godzin wykorzystanych',
    'Dashboard.SelfService.Column.SelfServiceHoursCompleted': 'Godziny ukończone',
    'Dashboard.SelfService.Column.SelfServiceHoursMissed': 'Godziny pominięte',
    'Dashboard.SelfService.Column.SelfServiceHoursErrored': 'Godziny błędne',
    'Dashboard.SelfService.Column.SelfServiceSimulationsComplete': 'Symulacje ukończone',
    'Dashboard.SelfService.Column.SelfServiceSimulationsMissed': 'Symulacje pominięte',
    'Dashboard.SelfService.Column.SelfServiceSimulationsErrored': 'Symulacje błędne',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceProvider': 'Dostawca',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceTotalHoursUtilized': 'Symulacje, które zostały pomyślnie ukończone',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceHoursCompleted': 'Godziny ukończone',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceHoursMissed': 'Symulacje, które zostały pominięte przez ucznia',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceHoursErrored': 'Symulacje, które zakończyły się błędem po stronie połączenia ucznia',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceSimulationsComplete': 'Symulacje ukończone',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceSimulationsMissed': 'Symulacje pominięte',
    'Dashboard.SelfService.ColumnTooltip.SelfServiceSimulationsErrored': 'Symulacje błędne',
    'Dashboard.Learners.Column.Provider': 'Dostawca',
    'Dashboard.Learners.Column.Client': 'Klient',
    'Dashboard.Learners.Column.LearnerName': 'Imię ucznia',
    'Dashboard.Learners.Column.DateInvited': 'Data zaproszenia',
    'Dashboard.Learners.Column.LastLogin': 'Ostatnie logowanie',
    'Dashboard.Learners.Column.NoAssignedTeams': 'Liczba przypisanych zespołów',
    'Dashboard.Learners.Column.AssignedProjects': 'Przypisane projekty',
    'Dashboard.Learners.Column.AssignedTeams': 'Przypisane zespoły',
    'Dashboard.Learners.Column.AssignedScenarios': 'Przypisane scenariusze',
    'Dashboard.Learners.Column.ScheduledScenarios': 'Zaplanowane scenariusze',
    'Dashboard.Learners.Column.CompletedScenarios': 'Ukończone scenariusze',
    'Dashboard.Learners.Column.NoAssignedScenarios': 'Liczba przypisanych scenariuszy',
    'Dashboard.Learners.Column.NoCompletedScenarios': 'Liczba ukończonych scenariuszy',
    'Dashboard.Learners.Column.Completed': 'Ukończone',
    'Dashboard.Learners.Column.Scheduled': 'Zaplanowane',
    'Dashboard.Learners.Column.LateCancelled': 'Anulowane z opóźnieniem',
    'Dashboard.Learners.Column.EndedInError': 'Zakończone błędem',
    'Dashboard.Learners.Column.EarlyCancelled': 'Anulowane z wyprzedzeniem',
    'Dashboard.Learners.Column.LateReschedules': 'Opóźnione zmiany harmonogramu',
    'Dashboard.Learners.Column.EarlyReschedules': 'Wczesne zmiany harmonogramu',
    'Dashboard.Learners.Column.RescheduledMakeUp': 'Odrobienie przełożonych',
    'Dashboard.Learners.Column.ErrorSim': 'Błąd (SIM)',
    'Dashboard.Learners.Column.CancelledOps': 'Anulowane (operacje)',
    'Dashboard.Learners.Column.ProjectName': 'Nazwa projektu',
    'Dashboard.Learners.Column.ScenarioName': 'Nazwa scenariusza',
    'Dashboard.Learners.Column.ScheduledStartDate': 'Planowana data rozpoczęcia',
    'Dashboard.Learners.Column.ScheduledStartTime': 'Planowany czas rozpoczęcia',
    'Dashboard.Learners.Column.ScheduledEndTime': 'Planowany czas zakończenia',
    'Dashboard.Learners.Column.SimulationStatus': 'Status sesji',
    'Dashboard.Learners.Column.LearnerStatus': 'Status ucznia',
    'Dashboard.Learners.Column.LearnerTimeSpent': 'Czas poświęcony przez ucznia',
    'Dashboard.Learners.Column.SimSpecialistName': 'Imię Sim Specialist',
    'Dashboard.Learners.Column.SimSpecialistStatus': 'Status Sim Specialist',
    'Dashboard.Learners.Column.SimulationId': 'ID sesji',
    'Dashboard.Learners.Column.SimulationId.CopyHover': 'Skopiuj Id sesji',
    'Dashboard.Learners.Column.SSO_ID': 'SSO ID',
    'Dashboard.Learners.Column.Report': 'Raport',
    'Dashboard.Learners.Column.Recorded': 'Nagrane',
    'Dashboard.Learners.Column.LearnerRecordingConsent': 'Zgoda ucznia na nagrywanie',
    'Dashboard.Learners.Column.RecordingRecipients': 'Nagrywanie odbiorców',
    'Dashboard.Learners.Column.RecordingUploaded': 'Przesłano nagranie',
    'Dashboard.Learners.ColumnTooltip.Provider': 'Nazwa usługodawcy/licencjobiorcy',
    'Dashboard.Learners.ColumnTooltip.Client': 'Imię klienta',
    'Dashboard.Learners.ColumnTooltip.LearnerName': 'Imię i nazwisko użytkownika',
    'Dashboard.Learners.ColumnTooltip.DateInvited': 'Data pierwszego zaproszenia ucznia do zaplanowania scenariusza',
    'Dashboard.Learners.ColumnTooltip.LastLogin': 'Data ostatniego logowania użytkownika do portalu',
    'Dashboard.Learners.ColumnTooltip.AssignedProjects': 'Projekty, do których ten uczeń jest obecnie przypisany',
    'Dashboard.Learners.ColumnTooltip.AssignedTeams': 'Zespoły, do których ten uczeń jest obecnie przypisany',
    'Dashboard.Learners.ColumnTooltip.AssignedScenarios': 'Scenariusze, do których ten uczeń jest obecnie przypisany',
    'Dashboard.Learners.ColumnTooltip.ScheduledScenarios': 'Scenariusze zaplanowane przez tego ucznia, ale jeszcze nieukończone',
    'Dashboard.Learners.ColumnTooltip.CompletedScenarios': 'Scenariusze, które ukończył ten uczeń',
    'Dashboard.Learners.ColumnTooltip.NoAssignedTeams': 'Liczba zespołów przypisanych do ucznia',
    'Dashboard.Learners.ColumnTooltip.NoAssignedScenarios': 'Liczba odrębnych scenariuszy przypisanych do ucznia',
    'Dashboard.Learners.ColumnTooltip.NoCompletedScenarios': 'Liczba odrębnych scenariuszy, które zostały ukończone przez ucznia',
    'Dashboard.Learners.ColumnTooltip.Completed': 'Symulacje, które zostały pomyślnie ukończone',
    'Dashboard.Learners.ColumnTooltip.Scheduled': 'Nadchodzące symulacje, które są zaplanowane, ale nie zostały jeszcze ukończone (w tym te, które aktualnie trwają)',
    'Dashboard.Learners.ColumnTooltip.Missed': 'Symulacje, które zostały pominięte przez ucznia',
    'Dashboard.Learners.ColumnTooltip.LateCancelled': 'Sesje, które zostały anulowane z opóźnieniem (po upływie terminu anulowania) przez account ownera, facilitatora lub ucznia.',
    'Dashboard.Learners.ColumnTooltip.EndedInError': 'Symulacje, które zakończyły się błędem po stronie połączenia ucznia',
    'Dashboard.Learners.ColumnTooltip.EarlyCancelled': 'Symulacje, które zostały anulowane z wyprzedzeniem (przed upływem terminu anulowania) przez ucznia, facilitatora lub account ownera lub symulacje',
    'Dashboard.Learners.ColumnTooltip.LateReschedules': 'Symulacje, które zostały przełożone z opóźnieniem (po terminie anulowania) przez ucznia, facilitatora lub account ownera',
    'Dashboard.Learners.ColumnTooltip.RescheduledMakeUp': 'Symulacje, które wcześniej przegapił uczeń, facilitator, account owner lub symulacje',
    'Dashboard.Learners.ColumnTooltip.EarlyReschedules': 'Godziny symulacji, które zostały przełożone z wyprzedzeniem (przed upływem terminu anulowania) przez ucznia, facilitatora lub account ownera',
    'Dashboard.Learners.ColumnTooltip.ErrorSim': 'Symulacje, które zakończyły się błędem po stronie połączenia SIM',
    'Dashboard.Learners.ColumnTooltip.CancelledOps': 'Symulacje, które zostały anulowane przez Mursion',
    'Dashboard.Learners.ColumnTooltip.ProjectName': 'Nazwa projektu',
    'Dashboard.Learners.ColumnTooltip.ScenarioName': 'Nazwa scenariusza',
    'Dashboard.Learners.ColumnTooltip.ScheduledStartDate': 'Data zaplanowanego rozpoczęcia tej symulacji',
    'Dashboard.Learners.ColumnTooltip.ScheduledStartTime': 'Godzina zaplanowanego rozpoczęcia tej symulacji',
    'Dashboard.Learners.ColumnTooltip.ScheduledEndTime': 'Godzina zaplanowanego zakończenia tej symulacji',
    'Dashboard.Learners.ColumnTooltip.SimulationStatus': 'Stan końcowy symulacji',
    'Dashboard.Learners.ColumnTooltip.LearnerStatus': 'Końcowy status ucznia w symulacji',
    'Dashboard.Learners.ColumnTooltip.LearnerTimeSpent': 'Minuty, które uczeń spędził w symulacji. W przypadku uczniów Zoom i Meet są one szacowane przez SIM specialist',
    'Dashboard.Learners.ColumnTooltip.SimSpecialistName': 'Simulation specialist wyznaczony do przeprowadzenia symulacji',
    'Dashboard.Learners.ColumnTooltip.SimSpecialistStatus': 'Ostateczny status SIM specialist symulacji',
    'Dashboard.Learners.ColumnTooltip.SimulationId': 'Niepowtarzalny identyfikator symulacji',
    'Dashboard.Learners.ColumnTooltip.SSOID': 'Użytkownicy, którzy zostali przydzieleni',
    'Dashboard.Learners.ColumnTooltip.Report': 'Link do raportu z symulacji, aby wyświetlić (jeśli są dostępne) nagranie i analizę symulacji.',
    'Dashboard.Learners.ColumnTooltip.Recorded': 'Wskazuje, czy symulacja została zarejestrowana, czy też nie wyrażono na to zgody.',
    'Dashboard.Learners.ColumnTooltip.LearnerRecordingConsent': 'Wskazuje, czy uczeń wyraził zgodę na nagrywanie symulacji.',
    'Dashboard.Learners.ColumnTooltip.RecordingRecipients': 'Role, do których wysyłane jest nagranie symulacji.',
    'Dashboard.Learners.ColumnTooltip.RecordingUploaded': 'Wskazuje, czy nagranie symulacji zostało przesłane.',
    'RestorePassword.EnterEmailAddress': 'Wprowadź swój adres email i kliknij przycisk Odzyskaj - otrzymasz wiadomość e-mail z linkiem umożliwiającym zresetowanie hasła.',
    'RestorePassword.ResetPassword': 'Zresetuj hasło',
    'RestorePassword.EmailHint': 'wprowadź swój adres e-mail',
    'RestorePassword.Email': 'E-mail',
    'RestorePassword.Button.Cancel': 'Anuluj',
    'RestorePassword.Button.Restore': 'Przywróć',
    'RestorePassword.Modal.IfValidEmailAddressEntered': 'Jeśli wprowadzono prawidłowy adres e-mail, na powiązane konto została wysłana wiadomość e-mail z instrukcjami resetowania. Jeśli problem będzie się powtarzał, skontaktuj się z pomocą techniczną.',
    'RestorePassword.Modal.Button.GotIt': 'Rozumiem',
    'Trainings.Heading.AllTrainingSessions': 'wszystkie sesje szkoleniowe',
    'Requests.Table.Swap': 'ZAMIEŃ',
    'Requests.Table.SchedulingRequests': 'Prośby o zaplanowanie',
    'Requests.Total': 'RAZEM',
    'Requests.Table.NoSessionsFound': 'Nie znaleziono sesji.',
    'Requests.Table.NoUpcomingSwapSessions': 'Brak zbliżających się sesji SWAP.',
    'Requests.Table.NoUpcomingRequests': 'Nie masz żadnych nadchodzących próśb.',
    'GetTimeToEvents.Day': 'Dzień',
    'GetTimeToEvents.Days': 'Dni',
    'GetTimeToEvents.Minute': 'Minuta',
    'GetTimeToEvents.Minutes': 'Minuty',
    'GetTimeToEvents.Hour': 'Godzina',
    'GetTimeToEvents.Hours': 'Godziny',
    'GetTimeToEvents.LessThanOneMinute': 'Mniej niż 1 minuta',
    'SessionReports.SessionInformation': 'Informacje na temat sesji',
    'SessionReports.SessionInformation.Hover.Information': 'Informacja',
    'SessionReports.SmartMetrics': 'Szczegóły analizy głosowej',
    'SessionReports.SendVideoLink.Modal.ShareLinkToVideo': 'Udostępnij link do filmu',
    'SessionReports.Button.BackToReports': 'Powrót do raportów',
    'SessionReports.Button.BackToTrainings': 'Powrót do szkoleń',
    'SessionReports.Button.HideInfo': 'Ukryj informacje',
    'SessionReports.Button.ShowInfo': 'Pokaż informacje',
    'SessionReports.Button.SendVideoLink': 'Wyślij link do filmu',
    'SessionReports.ErrorOnLoadingVideo': 'Użyj laptopa lub komputera stacjonarnego, aby wyświetlić nagranie.',
    'SessionReports.TryAgain': 'Spróbuj ponownie',
    'SessionReports.VideoRecordingPermissionNotGranted': 'Ten film jest niedostępny, ponieważ uczestnik nie chciał być nagrywany.',
    'SessionReports.NoVideoAvailableForSession': 'Brak filmu dla tej sesji.',
    'SessionReports.Mode': 'Tryb:',
    'SessionReports.SessionLength': 'Długość sesji:',
    'SessionReports.LearnersAttended': 'Uczestniczący uczniowie:',
    'SessionReports.ScenarioTimeline': 'Oś czasu scenariusza:',
    'SessionReports.ActualSessionTime': 'Rzeczywisty czas sesji:',
    'SessionReports.ActualSimulationTime': 'Rzeczywisty czas symulacji:',
    'SessionReports.LastUserStatus': 'Ostatni status użytkownika:',
    'SessionReports.SessionStatus': 'Status sesji:',
    'SessionReports.Delivery': 'Dostawa',
    'SessionReports.SendVideoLink.Modal.AddEmails': 'Dodaj e-maile',
    'SessionReports.SendVideoLink.Modal.Button.AddEmail': 'Dodaj e-mail',
    'SessionReports.SendVideoLink.Modal.EmailPlaceHolder': 'example@domain.com',
    'SessionReports.SendVideoLink.Modal.Button.Cancel': 'Anuluj',
    'SessionReports.SendVideoLink.Modal.Button.Send': 'Wyślij',
    'SessionReports.FactSessionInfo': 'Informacje o sesji informacyjnej',
    'SessionReports.Text.NoTraineeParticipatedInSession': 'Żaden kursant nie wziął udziału w sesji',
    'SessionReports.Text.NoLearnerParticipatedInSession': 'Żaden uczeń nie wziął udziału w sesji',
    'SessionReports.ToolTip.TrackingYours': 'Najważniejsze jest, aby Twoje postrzeganie wyników lub strategii było zgodne z postrzeganiem awatara (awatarów). Oznacza to, że śledzisz swój wpływ na awatara (awatary), pomagając w ten sposób poprawić efektywność społeczną. Informacje te zawarte są w poniższej tabeli.',
    'SessionReports.ToolTip.TrackingLearners': 'Najważniejsze jest, aby postrzeganie wyników lub strategii przez ucznia było zgodne z postrzeganiem awatara (awatarów). Oznacza to, że uczeń śledzi swój wpływ na awatara (awatary), pomagając w ten sposób poprawić efektywność społeczną. Informacje te zawarte są w poniższej tabeli.',
    'SessionReports.ToolTip.AvatarsHeaderYours': 'Ta kolumna wskazuje, jak awatary postrzegają wydajność symulacji. Znacznik wskazuje, że awatary uznały, że udało Ci się osiągnąć wynik, podczas gdy znak krzyżyka oznacza, że istnieje pole do poprawy.',
    'SessionReports.ToolTip.AvatarsHeaderLearners': 'Ta kolumna wskazuje, jak awatary postrzegają wyniki symulacji ucznia. Znacznik wyboru wskazuje, że awatary postrzegały ucznia jako odnoszącego sukcesy w osiąganiu wyniku, podczas gdy krzyżyk oznacza pole do poprawy.',
    'SessionReports.ToolTip.SelfHeaderYours': 'Ta kolumna zawiera Twoją własną ocenę wydajności symulacji. Znacznik wyboru wskazuje, że uważasz, że odniosłeś sukces, a krzyżyk oznacza pole do poprawy.',
    'SessionReports.ToolTip.SelfHeaderLearners': 'Ta kolumna wskazuje ocenę ucznia dotyczącą jego wyników w symulacji. Znacznik wskazuje, że uczeń uważa, że odniósł sukces, podczas gdy krzyżyk oznacza pole do poprawy.',
    'SessionReports.ToolTip.VideoLink': 'Możesz udostępnić link do filmu z symulacji swoim współpracownikom. Aby to zrobić, po prostu wpisz ich adresy e-mail w wyskakującym okienku. Pamiętaj, że Twoje dane analityczne nie będą udostępniane Twoim współpracownikom.',
    'SessionReports.ToolTip.SmartMetrics': 'Inteligentne metryki są generowane przy użyciu zaawansowanych algorytmów. Obserwuj te generowane komputerowo miary, aby zobaczyć, czy mają one jakikolwiek wpływ na Twoje postępy podczas symulacji.',
    'SessionReports.ToolTip.MiScore': 'Mursion Index (MI) Score jest wskaźnikiem Twojej efektywności społecznej podczas tej rozmowy. Jest obliczany na podstawie różnych danych, w tym schematu przyjmowania zwrotów konwersacyjnych i jego wpływu na awatara (awatary). Spróbuj ocenić swój wpływ na awatara (awatary) i podchodź do rozmów na różne sposoby, aby zobaczyć, jak możesz pozytywnie wpłynąć na swój MI Score.',
    'SessionReports.ToolTip.ListeningSpeakingTime': 'Jest to wskaźnik pokazujący stosunek czasu spędzonego na mówieniu do czasu spędzonego na słuchaniu podczas rozmowy.',
    'SessionReports.ToolTip.Emotions': 'Emocje (beta): Obecnie badamy algorytmy, które pozwolą nam zinterpretować Twoje wzorce głosu w celu określenia ogólnego nastroju. Obserwuj te dane, aby zobaczyć, czy Twoje wzorce wokalne miały pozytywne, negatywne lub neutralne konotacje w miarę upływu czasu. Co ważniejsze, obserwuj wpływ, jaki wywarłeś na wzorce wokalne awatarów.',
    'SessionReports.ToolTip.TurnTracking': 'Pilnowanie kolejności to zestaw środków, które rzucają wgląd w naturę współpracy / efektywność społeczną między Tobą a awatarami.',
    'SessionReports.ToolTip.Pauses': 'Pauzy są pośrednią miarą twojej płynności podczas rozmowy.',
    'SessionReports.ToolTip.ArticulationRate': 'Jest to miara tempa dostarczania przez Ciebie ciągłego zestawu informacji.',
    'SessionReports.TableHeader.Outcome': 'Wynik',
    'SessionReports.TableHeader.Self': 'Ja',
    'SessionReports.TableHeader.Avatars': 'Awatar(y)',
    'SessionReports.TableHeader.Strategies': 'Strategie',
    'MursionPortal.NotAvailable': 'Nie dotyczy',
    'Dashboard.ProjectUtilization.MainHeader': 'Wykorzystanie projektów',
    'Calendar.Button.Schedule': 'Harmonogram',
    'Calendar.Button.Today': 'Dzisiaj',
    'MursionPortal.Availability': 'Dostępność',
    'Calendar.Button.Schedule.QualityAssurance': 'Zapewnienie jakości',
    'MursionPortal.Training': 'Szkolenie',
    'Calendar.Button.Schedule.EmergencySession': 'Sesja awaryjna',
    'Calendar.Button.Schedule.Session': 'Sesja',
    'Calendar.Button.Schedule.BreakPrep': 'Przerwa/przygotowanie',
    'MursionPortal.Month': 'Miesiąc',
    'MursionPortal.Week': 'Tydzień',
    'MursionPortal.Day': 'Dzień',
    'Calendar.Heading.Calendars': 'Kalendarze',
    'Calendar.Tip.SelectACalendar': 'Wybierz kalendarz, aby wyświetlić dostępność',
    'Calendar.ScheduledHours.SumOfAllScheduledSessions': 'Suma wszystkich zaplanowanych sesji w bieżącym widoku',
    'MursionPortal.ShortLabel.Hours': 'godz.',
    'Calendar.ScheduledHours.SumOfAllTrainingSessions': 'Suma wszystkich zaplanowanych sesji szkoleniowych w bieżącym widoku',
    'Calendar.ScheduledHours.Trainings': 'Szkolenia',
    'Calendar.ScheduledHours.SumOfRemainingAvailabilityHours': 'Suma wszystkich pozostałych godzin dostępności SS w bieżącym widoku',
    'Calendar.ScheduledHours.Available': 'Dostępne',
    'MursionPortal.Scheduled': 'Zaplanowane',
    'Calendar.Label.Display': 'Wyświetlacz',
    'MursionPortal.Client': 'Klient',
    'Calendar.Filter.Company': 'Firma',
    'MursionPortal.Sessions': 'Sesje',
    'MursionPortal.SessionRecording': 'Nagranie sesji',
    'MursionPortal.VoiceAnalytics': 'Analiza głosowa',
    'MursionPortal.VideoTab': 'Wideo',
    'MursionPortal.SessionInformation': 'Informacje na temat sesji',
    'MursionPortal.SmartMatrix': 'Szczegóły analizy głosowej',
    'MursionPortal.DeliveryDetails': 'Szczegóły dostawy',
    'MursionPortal.ConversationalFlow': 'Przepływ konwersacji',
    'MursionPortal.ConversationalFlowTooltip': 'Ten wykres przedstawia Twoją skuteczność podczas rozmowy. Składa się z kolejnych wkładów, które pokazują, jak dobrze idzie Ci uczestniczenie w konstruktywnej rozmowie.',
    'MursionPortal.BalancedTalkTime': 'Przerwanie rozmowy',
    'MursionPortal.BalancedFeedback': 'Zrównoważona informacja zwrotna',
    'MursionPortal.ConversationalDisruptions': 'Wtrącenie uczestnika',
    'MursionPortal.DisruptionsFeedback': 'Opinie dotyczące zakłóceń',
    'Calendar.Filter.SwapRequests': 'Prośby o zamianę',
    'MursionPortal.QA': 'QA',
    'Calendar.SearchFilter.FilterProjects': 'Filtruj projekty',
    'Calendar.SearchFilter.FilterClients': 'Filtruj klientów',
    'Calendar.SearchFilter.FilterSimSpecialists': 'Filtruj Sim Specialists',
    'MursionPortal.Search': 'Szukaj',
    'Calendar.IndustrySelector.Options.SelectAnIndustry': 'Wybierz branżę',
    'Calendar.IndustrySelector.Options.AllIndustries': 'Wszystkie branże',
    'Calendar.IndustrySelector.Options.NoIndustriesToSelect': 'Brak branż do wyboru',
    'MursionPortal.SelectAClient': 'Wybierz certyfikat',
    'Calendar.ClientSelector.Options.AllClients': 'Wszyscy klienci',
    'Calendar.ClientSelector.Options.NoClientToSelect': 'Brak klienta do wyboru',
    'MursionPortal.SelectACertificate': 'Wybierz certyfikat',
    'Calendar.CertificateSelector.Options.AllCertificates': 'Wszystkie certyfikaty',
    'Calendar.CertificateSelector.Options.NoCertificatesToSelect': 'Brak certyfikatów do wyboru',
    'Calendar.ClientsList.NoClientsFound': 'Nie znaleziono żadnych klientów.',
    'MursionPortal.UncheckAll': 'Odznacz wszystkie',
    'MursionPortal.CheckAll': 'Zaznacz wszystkie',
    'Calendar.SimSpecialistList.NoSimSpecialistsFound': 'Nie znaleziono żadnych simspecjalistów.',
    'MursionPortal.Projects': 'PROJEKTY',
    'Calendar.ProjectsList.CloseProjectOverview': 'Zamknij przegląd projektu',
    'Calendar.ProjectsList.AllProjects': 'WSZYSTKIE PROJEKTY',
    'MursionPortal.Bracketed.Scenarios': 'Scenariusz(e)',
    'Calendar.ProjectsList.Overview': 'Przegląd',
    'Calendar.ProjectsList.CloseClientOverview': 'zamknij przegląd klientów',
    'Calendar.ProjectsList.ShowOnlyActiveProjects': 'Pokaż tylko projekty aktywne',
    'MursionPortal.Learners': 'Uczniowie',
    'MursionPortal.Scenario': 'scenariusz',
    'Calendar.ScenariosList.NoScenarios': 'Brak scenariuszy',
    'MursionPortal.Delivery': 'Dostawa',
    'MursionPortal.Mode': 'Tryb',
    'MursionPortal.SessionLength': 'Długość sesji',
    'MursionPortal.Timeline': 'Oś czasu',
    'Calendar.ScenariosList.Description': 'Opis',
    'MursionPortal.Outcome': 'Wynik',
    'MursionPortal.Strategies': 'Strategie',
    'MursionPortal.ContentBundle': 'Pakiet treści',
    'MursionPortal.SimulationEnvironments': 'Środowiska symulacji',
    'MursionPortal.AvatarsInSimulations': 'Awatary w symulacjach',
    'Calendar.LearnersList.SessionNotScheduled': 'Sesja nie zaplanowana',
    'Calendar.LearnersList.SessionScheduled': 'Sesja zaplanowana',
    'Calendar.LearnersList.SessionComplete': 'Sesja zakończona',
    'Calendar.LearnersList.Learner': '{learnersCount} uczeń',
    'Calendar.LearnersList.Learners': '{learnersCount} uczniów',
    'Calendar.Button.CantSchedule': 'Nie można zaplanować',
    'Calendar.Button.ScheduleEvent': 'Zaplanuj wydarzenie',
    'Calendar.MainPanel.ConfirmText.AreYouSure': 'Czy na pewno chcesz anulować to wydarzenie?',
    'Calendar.MainPanel.CancelEvent': 'Anuluj wydarzenie',
    'Calendar.MainPanel.UpdateEvent': 'Zaktualizuj wydarzenie',
    'MursionPortal.Submit': 'Wyślij',
    'Calendar.MainPanel.NewEvent': 'Nowe wydarzenie',
    'MursionPortal.Project': 'Projekt',
    'MursionPortal.Scenarios': 'Scenariusze',
    'MursionPortal.Button.Cancel': 'Anuluj',
    'Settings.Config.Heading.Industries': 'BRANŻE',
    'Settings.Config.Button.New': 'Nowe',
    'Settings.Config.Note': 'UWAGA:',
    'Settings.Config.Note.Text.ConfigSettingsGlobal': 'Ustawienia konfiguracji są globalne. Zmiany zostaną zastosowane do wszystkich klientów i użytkowników.',
    'Settings.Config.Heading.SimSpecialist': 'POZIOMY SIMSPECIALIST',
    'Settings.Config.Heading.Scheduling': 'PLANOWANIE',
    'Settings.Config.Heading.Scheduling.Description': '(Sim-Specialist)',
    'Settings.Config.Scheduling.MaximumFullTimeSimSpecialistHours': 'Maksymalna liczba godzin pełnoetatowego Sim-Specialist',
    'Settings.Config.Scheduling.MaximumPartTimeSimSpecialistHours': 'Maksymalna liczba godzin niepełnoetatowego Sim-Specialist',
    'Settings.Config.Scheduling.MaximumContinuousSimSpecialistHours': 'Maksymalna liczba ciągłych godzin Sim-Specialist',
    'Settings.Config.Scheduling.LengthOfSimSpecialistRequiredBreak': 'Długość wymaganej przerwy Sim Specialist',
    'Settings.Config.Scheduling.FutureAvailabilityForSimSpecialist': 'Przyszłe okno dostępności dla Sim Specialists',
    'Settings.Config.Scheduling.SimSpecialistShiftTime': 'Czas zmiany Sim Specialist',
    'Settings.Config.Scheduling.AvailableTimeBlockMaximumPerDay': 'Maksymalny blok czasu dostępności na dzień',
    'Settings.Config.Scheduling.AvailableTimeBlockMinimumPerDay': 'Minimalny blok czasu dostępności na dzień',
    'Settings.Config.Scheduling.AvailabilityTimeStep': 'Przedział czasowy dostępności',
    'Settings.Config.Scheduling.AdvanceSSScheduleConfirmation': 'Z jakim wyprzedzeniem należy potwierdzić harmonogram SS',
    'Settings.Config.Scheduling.RequestUnfulfilledReminder': 'Kiedy wysłać przypomnienie „prośba nie została spełniona”.',
    'Settings.Config.Scheduling.FirstEmailReminder': 'Kiedy wysłać pierwsze przypomnienie e-mail',
    'Settings.Config.Scheduling.SecondEmailReminder': 'Kiedy wysłać drugie przypomnienie e-mail',
    'Settings.Config.Scheduling.BookedSessionWithNoAssignedLearner': 'Kiedy powiadomić o zabookowanej sesji bez przypisanego ucznia',
    'Settings.Config.Scheduling.UnfulfilledSessionSwapRequestReminder': 'Kiedy wysłać przypomnienie o niespełnionej prośbie o zamianę sesji',
    'Settings.Config.Scheduling.NextSessionReminder': 'Kiedy wysłać następne przypomnienie o sesji w zależności od czasu między utworzeniem sesji a zaplanowaną godziną rozpoczęcia sesji',
    'Settings.Config.Scheduling.ReservedAvailabilityExpired': 'Kiedy wygasła zarezerwowana dostępność',
    'Settings.Config.Scheduling.StartTimeForConsideringMissedSession': 'Czas po rozpoczęciu, kiedy sesja jest uważana za \„pominiętą\”',
    'Settings.Config.Scheduling.EndTimeForConsideringMissedSession': 'Czas po zakończeniu, kiedy sesja jest uważana za \„pominiętą\”',
    'Settings.Config.Scheduling.RejoinSessionAfterError': 'Czas, w którym uczestnicy sesji muszą ponownie dołączyć do sesji po wystąpieniu błędu',
    'Settings.Config.Scheduling.TimeDisabledForSessionScheduling': 'Ilość czasu wyłączonego dla planowania sesji',
    'Settings.Config.Scheduling.SessionTimeStep': 'Przedział czasowy sesji',
    'Settings.Config.Heading.ProjectAndScenario': 'PROJEKT I SCENARIUSZ',
    'Settings.Config.ProjectAndScenario.MaximumProjectTimelineDuration': 'Maksymalny czas trwania projektu',
    'Settings.Config.ProjectAndScenario.MinimumProjectTimelineDuration': 'Minimalny czas trwania projektu',
    'Settings.Config.ProjectAndScenario.MaximumScenarioTimelineDuration': 'Maksymalny czas trwania scenariusza',
    'Settings.Config.ProjectAndScenario.MinimumScenarioTimelineDuration': 'Minimalny czas trwania scenariusza',
    'Settings.Config.ProjectAndScenario.MinimumSessionLength': 'Minimalna długość sesji',
    'Settings.Config.ProjectAndScenario.MaximumSessionLength': 'Maksymalna długość sesji',
    'Settings.Config.ProjectAndScenario.MinimumSimulationLength': 'Minimalna długość symulacji',
    'Settings.Config.ProjectAndScenario.SessionLengthStep': 'Przedział długościowy sesji',
    'Settings.Config.ProjectAndScenario.MinimumLearnersInRemoteSession': 'Minimalna liczba uczniów w sesjach zdalnych',
    'Settings.Config.ProjectAndScenario.MaximumLearnersInRemoteSession': 'Maksymalna liczba uczniów w sesjach zdalnych',
    'Settings.Config.ProjectAndScenario.MinimumLearnersInFishbowlSession': 'Minimalna liczba uczniów w lokalnych sesjach Fishbowl',
    'Settings.Config.ProjectAndScenario.MaximumLearnersInFishbowlSession': 'Maksymalna liczba uczniów w lokalnych sesjach Fishbowl',
    'Settings.Config.ProjectAndScenario.MinimumLearnersInGroupSession': 'Minimalna liczba uczniów w sesjach grupowych',
    'Settings.Config.ProjectAndScenario.MaximumLearnersInGroupSession': 'Maksymalna liczba uczniów w sesjach grupowych',
    'Settings.Config.ProjectAndScenario.AllowedScenarioVersions': 'Dozwolone wersje scenariuszy',
    'Settings.Config.ProjectAndScenario.DefaultScenarioVersion': 'Domyślna wersja scenariusza',
    'Settings.Config.ProjectAndScenario.AllowMursionMagic': 'typ Mursion Magic',
    'Settings.Config.Heading.CompanyEvent': 'WYDARZENIE FIRMOWE',
    'Settings.Config.CompanyEvent.MaximumEventDuration': 'Maksymalny czas trwania wydarzenia',
    'Settings.Config.CompanyEvent.MinimumEventDuration': 'Minimalny czas trwania wydarzenia',
    'Settings.Config.CompanyEvent.EventTimeStep': 'Przedział czasowy wydarzenia',
    'Settings.Config.Heading.SessionReport': 'RAPORT Z SESJI',
    'Settings.Config.SessionReport.ShowSessionReport': 'Pokaż raport z sesji',
    'Settings.Config.SessionReport.ShowSmartMetrics': 'Analiza głosowa',
    'Settings.Config.Heading.FeedbackProvider': 'DOSTAWCA INFORMACJI ZWROTNYCH',
    'Settings.Config.FeedbackProvider.ZenDeskSnippetKey': 'Klucz snippet ZenDesk',
    'Settings.Config.Heading.SimulationContent': 'ZAWARTOŚĆ SYMULACJI',
    'Settings.Config.SimulationContent.SimulationContentFolder': 'Folder Simulation Content S3',
    'Settings.Config.Heading.Invitation': 'ZAPROSZENIE',
    'Settings.Config.Invitation.ExpireUserRegistrationInvitation': 'Kiedy wygasić zaproszenie do rejestracji użytkownika',
    'Settings.Config.Invitation.ReminderToAcceptRegistrationInvitation': 'Kiedy przypominać niezarejestrowanym użytkownikom o zaakceptowaniu zaproszenia do rejestracji',
    'Settings.Config.Invitation.RegistrationInvitationExpiresAfter': 'Zaproszenie do rejestracji wygasa po',
    'Settings.Config.IndustriesButton.Modal.EditIndustry': 'Edytuj branżę',
    'Settings.Config.NewButton.Modal.NewIndustry': 'Nowa branża',
    'Settings.Modal.Placeholder.IndustryName': 'Nazwa branży',
    'Settings.Config.IndustriesButton.Modal.Button.UpdateIndustry': 'Zaktualizuj branżę',
    'Settings.Config.NewButton.Modal.Button.CreateIndustry': 'Utwórz branżę',
    'Settings.Config.IndustriesButton.Modal.Button.RestoreIndustry': 'Przywróć branżę',
    'Settings.Config.IndustriesButton.Modal.Button.ArchiveIndustry': 'Branża archiwalna',
    'MursionPortal.Modal.Heading.Name': 'Imię',
    'Settings.Environment.Modal.PlaceHolder.EnvironmentName': 'Nazwa środowiska',
    'Settings.Environment.Modal.Description': 'Opis',
    'Settings.Environment.Modal.Industry': 'Branża',
    'Settings.Environment.Modal.Picture': 'Zdjęcie',
    'MursionPortal.Button.Delete': 'Usuń',
    'Settings.Environment.Modal.Button.UploadFile': 'Prześlij plik',
    'Settings.Environment.Button.CreateEnvironment': 'Utwórz środowisko',
    'Settings.Environment.ModalHeader.NewEnvironment': 'Nowe środowisko',
    'Settings.Environment.ModalHeader.Environment': 'Środowisko',
    'Settings.Environment.ModalHeader.Archived': '(Zarchiwizowane)',
    'Settings.Environment.ModalHeader.EditEnvironment': 'Edytuj środowisko',
    'Settings.Environment.Modal.Button.CreateEnvironment': 'Utwórz środowisko',
    'Settings.Environment.Modal.Button.UpdateEnvironment': 'Zaktualizuj środowisko',
    'Settings.Environment.Modal.ArchiveEnvironmentText': 'Czy na pewno chcesz zarchiwizować to środowisko?',
    'Settings.Environment.Modal.RestoreEnvironmentText': 'Czy na pewno chcesz przywrócić to środowisko?',
    'MursionPortal.Button.Archive': 'Archiwizuj',
    'MursionPortal.Button.Restore': 'Przywróć',
    'MursionPortal.Total': 'RAZEM',
    'Dashboard.SessionLearners.MainHeader': 'Uczniowie w sesji',
    'Dashboard.SessionLearners.TableHeader': 'Dane uczniów w sesji',
    'MursionPortal.Text.Total': 'RAZEM',
    'MursionPortal.Button.ExportToEmail.HoverTitle.ExportData': 'Eksportuj dane',
    'MursionPortal.Button.ExportToEmail.HoverTitle.ExportNotAvailable': 'Eksport jest niedostępny',
    'MursionPortal.Button.ExportToEmail': 'Eksportuj do wiadomości e-mail',
    'MursionPortal.WarningMessage.RequestNotImplemented': 'Żądanie danych nie zostało jeszcze zaimplementowane',
    'MursionPortal.FilterHeading.Filter': 'FILTR',
    'MursionPortal.FilterHeading.Graph': 'POKAŻ WYKRES PRZEPŁYWU',
    'MursionPortal.Table.ColumnHeader.Client': 'Klient',
    'MursionPortal.Table.ColumnHeader.Provider': 'Dostawca',
    'MursionPortal.Table.ColumnHeader.Project': 'Projekt',
    'MursionPortal.Table.ColumnHeader.Scenario': 'Scenariusz',
    'MursionPortal.Table.ColumnHeader.SoftwareVersion': 'Wersja software',
    'MursionPortal.Table.ColumnHeader.DeliveryMode': 'Metoda dostawy',
    'MursionPortal.Table.ColumnHeader.SessionStart': 'Rozpoczęcie sesji',
    'MursionPortal.Table.ColumnHeader.SessionEnd': 'Zakończenie sesji',
    'MursionPortal.Table.ColumnHeader.LearnerStatus': 'Status ucznia',
    'MursionPortal.Table.ColumnHeader.SessionStatus': 'Status sesji',
    'MursionPortal.Table.ColumnHeader.LearnerName': 'Imię ucznia',
    'MursionPortal.Table.ColumnHeader.LearnerEmail': 'E-mail ucznia',
    'MursionPortal.Table.LearnerEmail.HoverTitle.CopyEmail': 'Skopiuj e-mail',
    'MursionPortal.Table.LearnerEmail.HoverTitle.UserEmail': 'E-mail użytkownika',
    'MursionPortal.Table.ColumnHeader.SessionId': 'ID sesji',
    'MursionPortal.Table.SessionId.HoverTitle.CopySessionId': 'Skopiuj Id sesji',
    'MursionPortal.Table.ColumnHeader.SessionReport': 'RAPORT Z SESJI',
    'MursionPortal.Table.ColumnHeader.SimSpecialistName': 'Imię Sim Specialist',
    'MursionPortal.Table.ColumnHeader.LearnerTimeSpent': 'Czas poświęcony przez ucznia',
    'MursionPortal.Table.HoverTitle.SessionSummary': 'Podsumowanie sesji',
    'MursionPortal.Table.ScrollMessage': 'przewiń, aby zobaczyć więcej',
    'MursionPortal.Table.NoRecordsMessage': 'Nie znaleziono wyników!',
    'MursionPortal.EmptyMessage.NoSessionsYet': 'Nie ma jeszcze żadnych sesji',
    'MursionPortal.Button.ViewReport': 'Zobacz raport',
    'MursionPortal.DateTimePicker.Timezone': 'Strefa czasowa',
    'MursionPortal.DateTimePicker.StartTime': 'Czas rozpoczęcia',
    'MursionPortal.DateTimePicker.EndTime': 'Czas zakończenia',
    'MursionPortal.DateTimePicker.StartDate': 'Data rozpoczęcia',
    'MursionPortal.DateTimePicker.EndDate': 'Data zakończenia',
    'MursionPortal.DateTimePicker.TimeNotAvailable': 'Wybrany czas jest niedostępny.',
    'Mursion.Portal.Status.Requested': 'Wnioskowane',
    'Mursion.Portal.Status.Pending': 'Oczekujące',
    'Mursion.Portal.Status.PendingSwap': 'Oczekujące (Zamiana)',
    'Mursion.Portal.Status.PreBooked': 'Wstępnie zabookowane',
    'Mursion.Portal.Status.Booked': 'Zabookowane',
    'Mursion.Portal.Status.Active': 'Aktywne',
    'Mursion.Portal.Status.Complete': 'Zakończone',
    'Mursion.Portal.Status.Error': 'Błąd',
    'Mursion.Portal.Status.Missed': 'Pominięte',
    'Mursion.Portal.Status.LearnerMissed': 'Uczeń pominięty',
    'Mursion.Portal.Status.NeedsReview': 'Wymaga przeglądu',
    'Mursion.Portal.Status.Cancelled': 'Anulowane',
    'Mursion.Portal.Status.SystemCancelled': 'Anulowane',
    'Mursion.Portal.Status.LateCancelled': 'Anulowane z opóźnieniem',
    'Mursion.Portal.Status.EarlierCancelled': 'Anulowane z wyprzedzeniem',
    'Mursion.Portal.Status.Connected': 'POŁĄCZONO',
    'Mursion.Portal.Status.Late': 'PÓŹNO',
    'Mursion.Portal.Status.Immersing': 'WPROWADZENIE',
    'Mursion.Portal.Status.Left': 'OPUSZCZONE',
    'Mursion.Portal.Status.Declined': 'ODRZUCONE',
    'Dashboard.UsersActivity.Filter.Roles.Placeholder': 'Szukaj...',
    'Settings.Config.RestoreIndustry.Modal.ConfirmRestore': 'Czy na pewno chcesz przywrócić tą branżę?',
    'Settings.Config.ArchiveIndustry.Modal.ConfirmArchive': 'Czy na pewno chcesz zarchiwizować tą branżę?',
    'Settings.Config.Description.PerWeek': '(na tydzień)',
    'Settings.Config.Description.PerDay': '(na dzień)',
    'Settings.Config.Description.BeforeBreak': '(przed przerwą)',
    'Settings.Config.Description.Minutes': '(minuty)',
    'Settings.Config.Description.Days': '(dni)',
    'Settings.Config.Description.Hours': '(godziny)',
    'Settings.Config.TierButton.Modal.Button.UpdateTier': 'Zaktualizuj poziom',
    'Settings.Config.TierButton.Modal.Heading.EditTier': 'Edytuj poziom',
    'Settings.Config.TierButton.Modal.Placeholder.TierName': 'Nazwa poziomu',
    'Settings.Config.ArchiveIndustry.Modal.Heading.ConfirmArchive': 'Potwierdź archiwizację',
    'Settings.Config': 'Konfiguracja',
    'Settings.ScenarioBank': 'Bank scenariuszy',
    'Settings.Environments': 'Środowiska (ML2)',
    'Settings.Avatars': 'Awatary (ML2)',
    'Settings.SSO': 'SSO',
    'Settings.SSO.CopySsoDeepLink': 'Skopiuj Deep Link SSO',
    'Settings.SSO.CopySsoDeepLinkNotification': 'Twój link SSO:',
    'Settings.SSO.CopyDeepLinkNotification': 'Twój link:',
    'Settings.SSO.CopyScenarioLink': 'Skopiuj link zaproszenia do scenariusza',
    'Settings.Error.NoSettingsAvailable': 'Brak dostępnych ustawień',
    'MursionPortal.Error.SomethingWentWrong': 'Przepraszamy, coś poszło nie tak.',
    'MursionPortal.Error.ReloadingPage': 'Spróbuj załadować stronę ponownie. Błąd został zgłoszony.',
    'MursionPortal.Error.WorkingHard': 'Ciężko pracujemy, aby naprawić to tak szybko, jak to możliwe.',
    'MursionPortal.Error.SupportAvailable': 'Pomoc techniczna jest dostępna od poniedziałku do piątku',
    'MursionPortal.Error.TechnicalAssistance': 'Jeśli potrzebujesz pomocy technicznej, skontaktuj się z pomocą techniczną Mursion',
    'MursionPortal.At': 'na',
    'MursionPortal.Or': 'lub',
    'MursionPortal.Button.Reload': 'Załaduj ponownie',
    'Settings.ScenarioBank.Table.ColumnHeader.Name': 'Imię',
    'MursionPortal.DeliveryMode': 'Metoda dostawy',
    'Settings.ScenarioBank.Table.ColumnHeader.SessionSize': 'Rozmiar sesji',
    'Settings.ScenarioBank.Table.ViewEditScenario': 'Wyświetl/edytuj scenariusz',
    'Settings.ScenarioBank.Table.ThereAreNoScenarios': 'Nie ma jeszcze żadnych scenariuszy',
    'MursionPortal.Capitalized.Total': 'RAZEM',
    'Settings.ScenarioBank.Form.NewScenario': 'Nowy scenariusz',
    'Settings.ScenarioBank.Form.Scenario': 'Scenariusz',
    'Settings.ScenarioBank.Form.EditScenario': 'Edytuj scenariusz',
    'Settings.ScenarioBank.Form.Save': 'Zapisz',
    'Settings.ScenarioBank.Form.ConfirmArchiveScenario': 'Czy na pewno chcesz zarchiwizować ten scenariusz?',
    'Settings.ScenarioBank.Form.ConfirmRestoreScenario': 'Czy na pewno chcesz przywrócić ten scenariusz?',
    'Settings.ScenarioBank.Button.CreateScenario': 'Utwórz scenariusz',
    'MursionPortal.ShortLabel.Minutes': 'min.',
    'Settings.ScenarioBank.Modal.Scenarios': 'Scenariusz(e)',
    'Settings.ScenarioBank.Modal.CompleteProjectInfoFirst': 'Najpierw uzupełnij informacje o projekcie',
    'Settings.ScenarioBank.Modal.ScenarioName': 'Nazwa scenariusza',
    'Settings.ScenarioBank.Modal.Placeholder.EnterScenarioName': 'Wprowadź nazwę scenariusza',
    'Settings.ScenarioBank.Modal.Outcome': 'Wynik',
    'Settings.ScenarioBank.Modal.Strategies': 'Strategie',
    'Settings.ScenarioBank.Modal.Attachments': 'Załączniki',
    'Settings.ScenarioBank.Modal.SoftwareVersion': 'Wersja software',
    'Settings.ScenarioBank.Modal.WeAlreadyHaveSessions': 'Mamy już sesje dla tego scenariusza',
    'Settings.ScenarioBank.Modal.SelectDeliveryMode': 'Wybierz metodę dostawy',
    'Settings.ScenarioBank.Modal.ShouldBeAtLeast': 'Powinno być co najmniej {minutes} minut',
    'Settings.ScenarioBank.Modal.SelectSessionLength': 'Wybierz długość sesji',
    'Settings.ScenarioBank.Modal.SessionSize': 'Rozmiar sesji',
    'Settings.ScenarioBank.Modal.Learner': 'Uczeń',
    'Settings.ScenarioBank.Modal.Learners': 'Uczniowie',
    'Settings.ScenarioBank.Modal.SelectSessionSize': 'Wybierz rozmiar sesji',
    'MursionPortal.SimulationContent': 'Zawartość symulacji',
    'Settings.ScenarioBank.Modal.ToolTip.ThisIsTheArtContent': 'Jest to grafika 3D środowisk i awatarów, które zostaną użyte w symulacji. Zawartość tego folderu jest wyświetlana w sekcji zatytułowanej „pakiet zawartości” poniżej.',
    'Settings.ScenarioBank.Modal.ThisScenarioHasNoSimulation': 'Scenariusz nie ma przypisanej treści symulacji',
    'Settings.ScenarioBank.Modal.SelectSimulationContentForUse': 'Wybierz treść symulacji do użytku',
    'Settings.ScenarioBank.Modal.Label.ScenarioOutcome': 'Wynik scenariusza',
    'Settings.ScenarioBank.Modal.Placeholder.EnterAnOutcome': 'Wpisz wynik',
    'Settings.ScenarioBank.Modal.Description': 'Opis',
    'Settings.ScenarioBank.Modal.NoDescriptionSpecified': 'Nie podano opisu',
    'Settings.ScenarioBank.Modal.ScenarioDescription': 'Opis scenariusza',
    'Settings.ScenarioBank.Modal.AllowedCharactersLimit': 'Przekroczono dozwolony limit znaków ({maxLength} znaków).',
    'Settings.ScenarioBank.Modal.ScenarioStrategies': 'Strategie scenariuszy',
    'Settings.ScenarioBank.Modal.Placeholder.EnterAnItem': 'Wprowadź element',
    'Settings.ScenarioBank.Modal.Attachments.DragAndDrop': 'Przeciągnij \'n\' i upuść pliki tutaj lub kliknij, aby wybrać pliki',
    'Settings.ScenarioBank.Modal.Attachments.UploadedMaximumFiles': 'Przesłałeś maksymalną dostępną liczbę plików {maxFiles}.',
    'Settings.ScenarioBank.Modal.Attachments.UploadedFiles': 'Przesłane pliki:',
    'Settings.ScenarioBank.Modal.CertifiedSimSpecialists': 'Certyfikowani SIM Specialists',
    'Settings.ScenarioBank.Modal.NoCertifiedSimSpecialist': 'Brak Certyfikowanego Sim Specialist',
    'Settings.ScenarioBank.Modal.Button.Add': 'Dodaj',
    'Settings.ScenarioBank.Modal.Edit': 'edytuj',
    'Settings.ScenarioBank.Modal.AddAttachmentsToScenario': 'Dodaj załączniki do scenariusza',
    'MursionPortal.Cancel': 'Anuluj',
    'MursionPortal.OK': 'OK',
    'Settings.ScenarioBank.Modal.FileFormatsSupported': 'Obsługiwane są następujące formaty plików:',
    'Settings.ScenarioBank.Modal.Images': 'Obrazy',
    'Settings.ScenarioBank.Modal.Videos': 'Wideo',
    'Settings.ScenarioBank.Modal.Audios': 'Audio',
    'Settings.ScenarioBank.Modal.Documents': 'Dokumenty',
    'Settings.ScenarioBank.Modal.New': '(nowe)',
    'Settings.ScenarioBank.Modal.FileSizeMustNotExceed': 'Rozmiar pliku nie może przekraczać {size}',
    'Settings.ScenarioBank.Modal.CertifySimSpecialists': 'Certyfikuj Sim Specialists',
    'MursionPortal.Confirm': 'Potwierdź',
    'MursionPortal.Delete': 'Usuń',
    'Settings.ScenarioBank.Modal.Archived': '(Zarchiwizowane)',
    'Settings.ScenarioBank.Modal.Restore': 'Przywróć',
    'Settings.ScenarioBank.Modal.ConfirmArchive': 'Potwierdź archiwizację',
    'Settings.ScenarioBank.Modal.Archive': 'Archiwizuj',
    'Settings.ScenarioBank.Modal.ConfirmRestore': 'Potwierdź przywrócenie',
    'MursionPortal.PleaseWait': 'Proszę czekać...',
    'MursionPortal.Loading': 'Ładowanie...',
    'Settings.ScenarioBank.Modal.Attachments.FileTypeNotSupported': 'Typ pliku {extensions} nie jest obsługiwany',
    'Settings.ScenarioBank.Modal.Attachments.FileTypesNotSupported': 'Typy plików {extensions} nie są obsługiwane',
    'Settings.ScenarioBank.Modal.Attachments.FileExceedsSizeLimit': 'Plik {fileNames} przekracza limit rozmiaru',
    'Settings.ScenarioBank.Modal.Attachments.FilesExceedSizeLimit': 'Pliki {fileNames} przekraczają limit rozmiaru',
    'Dashboard.ProjectsActivity.MainHeader': 'Aktywność projektu',
    'MursionPortal.Label.Total': 'RAZEM',
    'MursionPortal.Button.HoverTitle.ExportData': 'Eksportuj dane',
    'MursionPortal.Button.HoverTitle.ExportNotAvailable': 'Eksport jest niedostępny',
    'MursionPortal.Heading.Filters': 'FILTRY:',
    'Dashboard.ProjectsTable.Checkbox.OnlyBillable': 'Tylko podlegające rozliczeniu',
    'MursionPortal.Filters.SoftwareVersion': 'Wersja software',
    'MursionPortal.Filters.DeliveryMode': 'Metoda dostawy',
    'MursionPortal.Filters.PlaceHolder.DeliveryMode': 'Wyszukaj metodę dostawy...',
    'MursionPortal.Filters.PlaceHolder.SearchVersion': 'Wyszukaj wersję...',
    'MursionPortal.Filters.Status': 'Status',
    'MursionPortal.Filters.PlaceHolder.SearchStatus': 'Wyszukaj status...',
    'Dashboard.ProjectsTable.ColumnHeader.Provider': 'Dostawca',
    'MursionPortal.Table.ColumnHeader.ProjectId': 'ID umowy',
    'Dashboard.ProjectsTable.ColumnHeader.Status': 'Status',
    'Dashboard.ProjectsTable.ColumnHeader.StarAt': 'Początek o',
    'Dashboard.ProjectsTable.ColumnHeader.EndAt': 'Koniec o',
    'Dashboard.ProjectsTable.ColumnHeader.Billable': 'Podlegające rozliczeniu',
    'Dashboard.ProjectsTable.ColumnHeader.CancellationWindow': 'Okno anulowania',
    'MursionPortal.Table.ColumnHeader.Scenarios': 'Scenariusze',
    'MursionPortal.Table.ColumnHeader.Teams': 'Zespoły',
    'MursionPortal.Table.ColumnHeader.SessionsScheduled': 'Zaplanowane sesje',
    'MursionPortal.Table.ColumnHeader.SessionsCompleted': 'Sesje zakończone',
    'MursionPortal.Table.ColumnHeader.SessionsMissed': 'Sesje pominięte',
    'MursionPortal.Table.ColumnHeader.LateCancellations': 'Anulowania z opóźnieniem',
    'MursionPortal.Table.ColumnHeader.Learners': 'Uczniowie',
    'Dashboard.ProjectsTable.ColumnHeader.SessionsRecorded': 'Sesje zarejestrowane',
    'MursionPortal.Table.ColumnHeader.SessionsError': 'Błąd sesji',
    'Dashboard.ProjectsTable.ColumnHeader.ContractedHours': 'Godziny zakontraktowane',
    'Dashboard.ProjectsTable.ColumnHeader.UtilizedHours': 'Godziny wykorzystane',
    'Dashboard.ProjectsTable.ColumnHeader.RemainingHours': 'Pozostałe godziny',
    'Dashboard.ProjectsTable.ColumnHeader.CompletedHours': 'Godziny ukończone',
    'Dashboard.ProjectsTable.ColumnHeader.MissedHours': 'Godziny pominięte',
    'Dashboard.ProjectsTable.HoverTitle.ViewEditProject': 'Wyświetl/edytuj projekt',
    'Dashboard.ProjectsTable.EmptyMessage.NoProjectsYet': 'Nie ma jeszcze żadnych projektów',
    'MursionPortal.Button.Clear': 'Wyczyść',
    'MursionPortal.Button.ClearAll': 'Wyczyść wszystko',
    'Dashboard.Projects.AlertMessage.DataRequestNotImplemented': 'Żądanie danych nie zostało jeszcze zaimplementowane',
    'MursionPortal.SelectorMenu.SelectedCountOfTotal': 'Wybrano {formattedSelectedCount} z {formattedTotal}',
    'MursionPortal.Dashboard.Chart.Registration': 'Rejestracja użytkownika',
    'MursionPortal.Dashboard.Chart.InviteAll': 'Zaproś wszystkich',
    'MursionPortal.Dashboard.Chart.Unregistered': 'Niezarejestrowany',
    'MursionPortal.Dashboard.Chart.Registered': 'Zarejestrowany',
    'MursionPortal.Dashboard.Chart.Login': 'Zaloguj się',
    'MursionPortal.Dashboard.Chart.NotLoggedIn': 'Niezalogowany',
    'MursionPortal.Dashboard.Chart.LoggedIn': 'Zalogowany',
    'MursionPortal.Dashboard.Chart.Scheduling': 'Zapisani użytkownicy',
    'MursionPortal.Dashboard.Chart.NotScheduled': 'Nie zaplanowane',
    'MursionPortal.Dashboard.Chart.Simulations': 'Symulacje',
    'MursionPortal.Dashboard.Chart.LateCancellations': 'Anulowania z opóźnieniem',
    'MursionPortal.Dashboard.Chart.Completed': 'Ukończone',
    'MursionPortal.Dashboard.Chart.Assigned': 'Przypisane',
    'MursionPortal.Dashboard.Chart.Errors': 'Błędy',
    'MursionPortal.Dashboard.Chart.Scheduled': 'Zaplanowane',
    'MursionPortal.Dashboard.Chart.Tooltip.Registered': 'Użytkownicy, którzy zostali zaproszeni lub przydzieleni i zalogowani użytkownicy SSO.',
    'MursionPortal.Dashboard.Chart.Tooltip.Unregistered': 'Użytkownicy, którzy zostali zaproszeni, ale jeszcze się nie zalogowali, lub przydzieleni, ale jeszcze nie zalogowani użytkownicy SSO.',
    'MursionPortal.Dashboard.Chart.Tooltip.NotScheduled': 'Zarejestrowani/przydzieleni użytkownicy SSO, którzy nie zaplanowali jeszcze symulacji',
    'MursionPortal.Dashboard.Chart.Tooltip.Scheduled': 'Zarejestrowani/przydzieleni użytkownicy SSO, którzy zaplanowali co najmniej jedną symulację',
    'MursionPortal.Dashboard.Chart.Tooltip.Utilized': 'Ukończone symulacje jako odsetek zakupionych symulacji.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Scheduled': 'Nadchodzące zaplanowane sesje, które nie zostały jeszcze ukończone (w tym sesje trwające)',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Completed': 'Sesje, które zostały pomyślnie ukończone.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.LearnerMissed': 'Sesje, które zostały pominięte przez ucznia.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.LateReschedule': 'Sesje, które zostały z opóźnieniem przełożone przez ucznia.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Error': 'Sesje, które zakończyły się błędem po stronie ucznia.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.LateCancellation': 'Sesje, które zostały anulowane z opóźnieniem (po upływie terminu anulowania) przez account ownera, facilitatora lub ucznia.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.EarlyCancellation': 'Sesje, które zostały anulowane z wyprzedzeniem (przed terminem anulowania) przez ucznia, facilitatora lub account ownera.',
    'MursionPortal.Dashboard.Chart.Tooltip.SimulationBreakdown.Simulation': 'Sesje z podziałem na poszczególne statusy.',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.Completed': 'Ukończone',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.Error': 'Błąd',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.LateReschedule': 'Przełożone z opóźnieniem',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.LateCancellation': 'Anulowane z opóźnieniem',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.EarlyCancellation': 'Anulowane z wyprzedzeniem',
    'MursionPortal.Dashboard.Chart.label.SimulationBreakdown.EstimatedRemaining': 'Szacunkowo do wykorzystania',
    'MursionPortal.Dashboard.Chart.PrivatePractice1': 'Prywatna praktyka 30m',
    'MursionPortal.Dashboard.Chart.PrivatePractice2': 'Prywatna praktyka 60m',
    'MursionPortal.Dashboard.Chart.Workshop1': 'Warsztat 60m',
    'MursionPortal.Dashboard.Chart.Workshop2': 'Warsztat 90m',
    'MursionPortal.Dashboard.Chart.StringType.Utilized': 'Wykorzystane',
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdown': 'Podział sesji',
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdownQuickView': 'Podział sesji – Szybki podgląd',
    'MursionPortal.Dashboard.Chart.Header.NoDataAvailable': 'Brak dostępnych danych',
    'MursionPortal.Dashboard.Chart.Header.LearnerStatus': 'Status ucznia (wszystkie umowy)',
    'MursionPortal.Dashboard.Chart.Header.LearnerSimulations': 'Liczba przypisanych scenariuszy',
    'MursionPortal.Dashboard.Table.Modal.Filter.Teams': 'Zespoły',
    'MursionPortal.Dashboard.Table.Modal.Placeholder.EnterTeamName': 'Wpisz nazwę zespołu',
    'MursionPortal.Dashboard.Table.Modal.TeamsEmptyMessage': 'Nie znaleziono żadnych zespołów',
    'MursionPortal.Dashboard.Table.Modal.Filter.NumberOfLearners': 'Liczba uczniów',
    'MursionPortal.Dashboard.Table.Modal.Filter.Scenarios': 'Scenariusze',
    'MursionPortal.Dashboard.Table.Modal.Placeholder.EnterScenarioName': 'Wprowadź nazwę scenariusza',
    'MursionPortal.Dashboard.Table.Modal.ScenariosEmptyMessage': 'Nie znaleziono żadnych scenariuszy',
    'MursionPortal.Dashboard.Table.Modal.Filter.Name': 'Imię',
    'MursionPortal.Dashboard.Table.Modal.Filter.Status': 'Status',
    'MursionPortal.Dashboard.Table.Modal.Filter.DeliveryMode': 'Metoda dostawy',
    'MursionPortal.Dashboard.Table.Modal.Filter.Learners': 'Uczniowie',
    'MursionPortal.Dashboard.Table.Modal.Placeholder.EnterLearnerName': 'Wprowadź imię ucznia',
    'MursionPortal.Dashboard.Table.Modal.LearnersEmptyMessage': 'Nie znaleziono uczniów',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsScheduled': 'Zaplanowane sesje',
    'MursionPortal.Dashboard.Table.Modal.SessionsEmptyMessage': 'Nie znaleziono sesji',
    'MursionPortal.Dashboard.Table.Modal.Filter.ScenarioName': 'Nazwa scenariusza',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionDateTime': 'Data/godzina sesji',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsCompleted': 'Sesje zakończone',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsError': 'Błąd sesji',
    'Dashboard.ProjectsActivityTable.Modal.Filter.SessionsRecorded': 'Sesje zarejestrowane',
    'MursionPortal.Dashboard.Table.Modal.Filter.SessionsMissed': 'Sesje pominięte',
    'MursionPortal.Dashboard.Table.Modal.Filter.LateCancellations': 'Opóźnione” anulowania',
    'MursionPortal.Dashboard.Table.Modal.Filter.CancelledBy': 'Anulowane przez',
    'MursionPortal.Dashboard.Table.Modal.Filter.DateScheduled': 'Zaplanowana data',
    'MursionPortal.Dashboard.Table.Modal.Filter.DateCancelled': 'Data anulowania',
    'MursionPortal.Dashboard.Table.Empty.Body.Message': 'szczegóły zostaną załadowane po dokonaniu wyboru',
    'MursionPortal.ProjectStatus.Active': 'Aktywne',
    'MursionPortal.ProjectStatus.Completed': 'Ukończone',
    'MursionPortal.ProjectStatus.Archived': 'Zarchiwizowane',
    'Settings.Avatars.Modal.Name': 'Imię',
    'Settings.Avatars.Modal.PlaceHolder.AvatarName': 'Imię awatara',
    'Settings.Avatars.Modal.Description': 'Opis',
    'MursionPortal.Name': 'Imię',
    'MursionPortal.Description': 'Opis',
    'MursionPortal.Environments': 'Środowiska',
    'Settings.Avatars.TableHover.ViewEditAvatar': 'Wyświetl/edytuj awatar',
    'Settings.Avatars.NoAvatarText': 'Nie ma jeszcze awatarów',
    'Settings.Avatars.ModalHeader.NewAvatar': 'Nowy awatar',
    'Settings.Avatars.ModalHeader.Avatar': 'Awatar',
    'Settings.Avatars.ModalHeader.EditAvatar': 'Edytuj awatara',
    'Settings.Avatars.Modal.Button.CreateAvatar': 'Stwórz awatara',
    'Settings.Avatars.Modal.Button.UpdateAvatar': 'Zaktualizuj awatara',
    'Settings.Avatars.Modal.ArchiveAvatarText': 'Czy na pewno chcesz zarchiwizować tego awatara?',
    'Settings.Avatars.Modal.RestoreAvatarText': 'Czy na pewno chcesz przywrócić tego awatara?',
    'Settings.Avatars.Modal.Button.Edit': 'edytuj',
    'Settings.Avatars.Modal.EditButton.Accept': 'Zaakceptuj',
    'Settings.Avatars.Modal.NoSelectedEnvironments': 'Brak wybranych środowisk',
    'Settings.Avatars.Modal.NoEnvironmentsSetForSelectedIndustry': 'Brak środowisk ustawionych dla wybranej branży.',
    'Settings.Avatars.Button.CreateAvatar': 'Stwórz awatara',
    'MursionPortal.Dashboard': 'Pulpit nawigacyjny',
    'MursionPortal.Dashboard.UpcomingSessions': 'Nadchodzące sesje',
    'MursionPortal.Tutorial': 'Instruktaż',
    'MursionPortal.Eula': 'Eula',
    'MursionPortal.Companies': 'Firmy',
    'MursionPortal.SessionFlow': 'Przebieg sesji',
    'MursionPortal.SessionReport': 'RAPORT Z SESJI',
    'MursionPortal.Monitoring': 'Monitorowanie',
    'MursionPortal.Monitoring.User.FirstLetter': 'U',
    'MursionPortal.Monitoring.Peer.Label': 'WSPÓŁPRACOWNIK - WSPÓŁPRACOWNIK',
    'MursionPortal.Monitoring.Peer.NoUser': 'Wybierz użytkownika, aby zobaczyć szczegóły',
    'MursionPortal.Monitoring.Sessions.Total': 'Suma sesji',
    'MursionPortal.Monitoring.Sessions.Displayed': 'Sesje ML3 (wyświetlane)',
    'MursionPortal.Monitoring.Sessions.NotDisplayed': 'Nie sesje ML3',
    'MursionPortal.Monitoring.Card.StartTime': 'Czas rozpoczęcia',
    'MursionPortal.Monitoring.Notification.NewSession': 'Dodano nową sesję.',
    'MursionPortal.ClickRefresh': 'Kliknij „Odśwież”, aby wyświetlić zmiany.',
    'MursionPortal.AssetSync': 'Synchronizacja zasobów',
    'MursionPortal.Users': 'Użytkownicy',
    'MursionPortal.TechRestrictions': 'Ograniczenia techniczne',
    'MursionPortal.AppDownload': 'Pobieranie App',
    'MursionPortal.Calendar': 'Kalendarz',
    'MursionPortal.Clients': 'Klienci',
    'MursionPortal.Trainings': 'Szkolenia',
    'MursionPortal.Requests': 'Prośby',
    'MursionPortal.Settings': 'Ustawienia',
    'MursionPortal.ArtDesigner': 'Projektant artystyczny',
    'MursionPortal.ArtDesigner.BodyContents': 'Otworzyliśmy nową kartę przeglądarki z aplikacją Mursion Designer.',
    'MursionPortal.Teams': 'Zespoły',
    'MursionPortal.Reports': 'Raporty',
    'MursionPortal.Downloads': 'Pobrania',
    'MursionPortal.Upcoming': 'nadchodzące',
    'MursionPortal.Auth': 'Oauth',
    'Settings.Avatars.Modal.ErrorMessage.ItemsNotAvailable': 'Nie więcej niż {max} elementów jest dostępnych do wyboru',
    'Settings.Avatars.Modal.ErrorMessage.ItemNotAvailable': 'Nie więcej niż {max} element jest dostępny do wyboru',
    'MursionPortal.Industry': 'Branża',
    'Settings.Environment.TableHover.ViewEditEnvironment': 'Wyświetl/edytuj środowisko',
    'Settings.Environment.NoEnvironmentText': 'Nie ma jeszcze żadnych środowisk',
    'MursionPortal.SortBy': 'Sortuj według',
    'MursionPortal.Placeholder.SortBy': 'sortuj-według',
    'Clients.Button.CreateClient': 'Utwórz klienta',
    'Clients.CreateClient.Header.NewClient': 'Nowy klient',
    'Clients.CreateClient.Button.CreateClient': 'Utwórz klienta',
    'Clients.CreateClient.Label.ClientName': 'Imię klienta',
    'Clients.CreateClient.Placeholder.CityCountry': 'Miasto, kraj',
    'MursionPortal.Timezone': 'Strefa czasowa',
    'Clients.CreateClient.Label.Logo': 'Logo',
    'Clients.Text.NoClientsFound': 'Nie znaleziono żadnych klientów.',
    'MursionPortal.Text.Active': 'Aktywne',
    'MursionPortal.Text.Archived': 'Zarchiwizowane',
    'Clients.Modal.Button.BackToClientList': 'Powrót do listy klientów',
    'Clients.Modal.EmptyMessage.NoClientsFound': 'Nie znaleziono klientów',
    'Clients.Modal.Placeholder.EnterClientName': 'Wprowadź imię klienta',
    'Clients.Modal.Header.ClientsList': 'LISTA KLIENTÓW',
    'Clients.Modal.Header.Client': 'KLIENT',
    'Clients.Modal.Header.AllClients': 'wszyscy klienci',
    'MursionPortal.Button.LoadMore': 'Załaduj więcej',
    'Clients.Modal.RestoreConfirmText': 'Czy na pewno chcesz przywrócić tego klienta?',
    'Clients.Modal.DeleteConfirmText': 'Czy na pewno chcesz zarchiwizować tego klienta?',
    'Clients.Modal.RelatedDataText': 'Wszystkie powiązane dane również zostaną zarchiwizowane.',
    'Clients.Modal.DeleteErrorText': 'Bieżący klient ma scenariusz(-e). Zarchiwizuj scenariusze projektu przed zarchiwizowaniem tego klienta.',
    'Clients.Button.RestoreClient': 'Przywróć klienta',
    'Clients.IconButton.Cancel': 'anuluj',
    'Clients.IconButton.Edit': 'edytuj',
    'Clients.IconButton.CancelEdit': 'Anuluj edycję',
    'Cleints.IconButton.EditScenarioName': 'Edytuj nazwę scenariusza',
    'Cleints.IconButton.ApplyNameChange': 'Zastosuj zmianę nazwy',
    'Clients.IconButton.Apply': 'zastosuj',
    'Clients.IconButton.EditClient': 'Edytuj klienta',
    'Clients.Button.ArchiveClient': 'Archiwizuj klienta',
    'Clients.Modal.Heading.Archived': '(Zarchiwizowane)',
    'Clients.Modal.Label.Name': 'Nazwa:',
    'Clients.Modal.Placeholder.ClientName': 'Imię klienta',
    'Clients.Modal.Label.Location': 'Lokalizacja:',
    'Clients.Modal.Label.Industry': 'Branża:',
    'Clients.Modal.Placeholder.SelectIndustry': 'Wybierz branżę',
    'Clients.Modal.Label.FacilitatorVisibility': 'Widok facilitatora:',
    'Clients.Modal.Placeholder.FacilitatorVisibility': 'Wybierz widok facilitatora',
    'Clients.Modal.Label.Timezone': 'Strefa czasowa:',
    'Clients.Modal.Label.SchedulingByRequest': 'Planowanie na żądanie:',
    'MursionPortal.Enabled': 'Aktywne',
    'MursionPortal.Disabled': 'Nieaktywne',
    'Clients.Modal.Label.SchedulingWindow': 'Okno planowania:',
    'Clients.Modal.Label.HowFarInAdvance': 'Z jakim wyprzedzeniem klienci powinni planować',
    'MursionPortal.Days': 'dni',
    'Clients.Modal.Label.SmartMetrics': 'Analiza głosowa:',
    'Clients.Modal.Label.AvailableSharedSSOTitle': 'Lista dostępnych udostępnionych SSO, których można użyć do logowania',
    'Clients.Modal.Label.AvailableSharedSSOLabel': 'Dostępne udostępnione SSO:',
    'Clients.Modal.EmptySelectedMessage': 'Nie wybrano SSO',
    'Clients.Modal.EmptyListMessage': 'Brak dostępnego SSO',
    'Clients.Modal.SSOTitle': 'Wybierz dostępne SSO',
    'Cleints.Modal.Label.AvailablePrivateSSOTitle': 'Lista dostępnych prywatnych SSO, których można użyć do logowania',
    'Cleints.Modal.Label.AvailablePrivateSSOLabel': 'Włącz prywatne SSO:',
    'Cleints.Modal.Label.DomainsTitle': 'Lista domen, których można użyć do logowania',
    'Cleints.Modal.Label.DomainsLabel': 'Domena Web klienta:',
    'Clients.Modal.Label.Logo': 'Logo klienta:',
    'Clients.Modal.Label.AccountOwners': 'Account Ownerzy:',
    'Clients.Modal.AccountOwner.EmptyListMessage': 'Brak Account Ownerów:',
    'Clients.Modal.AccountOwner.EmptySelectedMessage': 'Brak wybranego account ownera',
    'Clients.Modal.AccountOwnersTitle': 'Account Owner(zy)',
    'MursionPortal.Message.Updating': 'Aktualizowanie...',
    'MursionPortal.Button.SaveClientInfo': 'Zapisz informacje o kliencie',
    'MursionPortal.Add': 'Dodaj',
    'Clients.Modal.Label.EditSSO': 'Edytuj SSO',
    'Clients.Modal.Label.Edit': 'edytuj',
    'MursionPortal.NumberOfFacilitators': 'Liczba facilitatorów',
    'MursionPortal.NumberOfLearners': 'Liczba uczniów',
    'Clients.Modal.Button.CreateTeam': 'Utwórz zespół',
    'Clients.Portal.Button.EditMemberships': 'Edytuj członkostwa',
    'MursionPortal.NewTeam': 'Nowy zespół',
    'MursionPortal.NoTags': 'Brak tagów',
    'MursionPortal.NoDomains': 'Brak domen',
    'MursionPortal.Header.Tags': 'Tagi',
    'MursionPortal.Header.Domains': 'Domeny',
    'MursionPortal.AddTag': 'Dodaj tag',
    'MursionPortal.AddDomain': 'Dodaj domenę',
    'Clients.Modal.Teams.FacilitatorsEmptyListMessage': 'Brak wybranych facilitatorów',
    'Clients.Modal.Teams.LearnersEmptyListMessage': 'Brak wybranych uczniów',
    'MursionPortal.Facilitator': 'Facilitator',
    'MursionPortal.CreateTeam': 'Utwórz zespół',
    'Clients.Modal.Teams.ConfirmationText.RestoreTeam': 'Czy na pewno chcesz przywrócić ten zespół?',
    'Clients.Modal.Teams.ButtonTitle.RestoreTeam': 'Przywróć zespół',
    'Clients.Modal.Teams.ConfirmationText.ArchiveTeam': 'Czy na pewno chcesz zarchiwizować ten zespół?',
    'Clients.Modal.Teams.ButtonTitle.ArchiveTeam': 'Archiwizuj zespół',
    'Clients.Modal.Projects.Header.NewProject': 'Nowy projekt',
    'MursionPortal.ProjectName': 'Nazwa projektu',
    'MursionPortal.TimelineUTC': 'Oś czasu projektu<code>{word}</code> (UTC)',
    'MursionPortal.Contracted': 'Zakontraktowane',
    'MursionPortal.CancellationWindow': 'Okno anulowania',
    'MursionPortal.SoftwareVersion': 'Wersja software',
    'MursionPortal.Billing': 'Rozliczenie',
    'Clients.Modal.Projects.Checkbox.NonBillable': 'Nie podlegające rozliczeniu',
    'MursionPortal.ProjectId': 'ID umowy',
    'MursionPortal.Recording': 'Nagranie',
    'Clients.Modal.Projects.Checkbox.AllowRecording': 'Zezwól na nagrywanie',
    'Clients.Modal.Projects.Label.RecordedVideos': 'Nagrane filmy wracają do:',
    'Clients.Modal.Projects.Checkbox.RecordingPermission': 'Zgoda na nagrywanie leży po stronie ucznia',
    'Clients.Modal.Projects.Header.SessionSmartMetrics': 'Analiza głosowa sesji:',
    'Clients.Modal.Projects.Checkbox.EnableSmartMetrics': 'Włącz analizę głosową',
    'Clients.Modal.Projects.ButtonTitle.SaveProjectInfo': 'Zapisz informacje o projekcie',
    'Clients.Modal.Projects.Header.ProjectManager': 'Kierownik projektu',
    'Clients.Modal.Projects.ProjectsEmptyListMessage': 'Brak wybranych kierowników projektów',
    'MursionPortal.Header.Bracketed.Scenarios': 'Scenariusz(e)',
    'Clients.Modal.Projects.Text.CompleteProjectInfoFirst': 'Najpierw uzupełnij informacje o projekcie',
    'MursionPortal.Header.CreateScenario': 'Utwórz scenariusz',
    'Clients.Modal.Projects.ConfirmationText.RestoreProject': 'Czy na pewno chcesz przywrócić ten projekt?',
    'Clients.Modal.Projects.ConfirmationText.EditProject': 'Czy na pewno chcesz edytować ten projekt?',
    'Clients.Modal.Projects.ButtonTitle.RestoreProject': 'Przywróć projekt',
    'Clients.Modal.Projects.ButtonTitle.Edit': 'Edytuj projekt',
    'Clients.Modal.Projects.ButtonTitle.CancelEdit': 'Anuluj edycję',
    'Clients.Modal.Label.Cancel': 'anuluj',
    'Clients.Modal.Projects.ConfirmationText.ArchiveProject': 'Czy na pewno chcesz zarchiwizować ten projekt?',
    'Clients.Modal.Projects.ButtonTitle.ArchiveProject': 'Archiwizuj Projekt',
    'Clients.Modal.Projects.ArchiveErrorMessage': 'Bieżący projekt posiada aktywne scenariusze. Zarchiwizuj scenariusze projektu przed zarchiwizowaniem tego projektu.',
    'Clients.Modal.Projects.ButtonTitle.EditTags': 'Edytuj tagi',
    'Clients.Modal.Projects.ButtonTitle.EditDomains': 'Edytuj domeny',
    'Clients.Modal.Projects.DomainField.EnterDomain': 'Wpisz nazwę domeny (np. example.com)',
    'Clients.Modal.Projects.DomainField.InvalidDomain': 'Nieprawidłowy format domeny',
    'MursionPortal.Button.Confirm': 'Potwierdź',
    'Clients.Modal.Projects.Label.UtilizedMissedSessions': 'Wykorzystane pominięte sesje:',
    'Clients.Modal.Projects.Button.CreateNew': 'utwórz nowy',
    'Clients.Modal.Projects.Button.RepeatClient': 'Powtórz klienta',
    'Clients.Modal.Projects.Button.RepeatBank': 'Powtórz bank',
    'Clients.Modal.Projects.ButtonTitle.CreateNewScenario': 'Utwórz nowy scenariusz',
    'Clients.Modal.Projects.ButtonTitle.RepeatClientScenario': 'Powtórz scenariusz klienta',
    'Clients.Modal.Projects.ButtonTitle.ScenarioBank': 'Utwórz scenariusz z banku scenariuszy',
    'Clients.Modal.Button.Schedule': 'Harmonogram',
    'MursionPortal.Button.ActivateScenario': 'Aktywuj scenariusz',
    'MursionPortal.Button.ChangeScenario': 'Zmień scenariusz',
    'MursionPortal.Button.Next': 'Dalej',
    'Clients.IconButton.Confirm': 'potwierdź',
    'Clients.IconButton.ConfirmEdit': 'Potwierdź edycję',
    'Clients.Modal.Projects.HeaderText.ChooseScenario': 'Wybierz scenariusz do powtórzenia',
    'Clients.Modal.Projects.HeaderText.ChooseScenarioBank': 'Wybierz scenariusz z banku scenariuszy',
    'MursionPortal.Placeholder.LowerCase.EnterScenarioName': 'Wprowadź nazwę scenariusza',
    'MursionPortal.IconButton.ResetToDefault': 'Zresetuj do nazwy domyślnej',
    'MursionPortal.IconButton.Reset': 'zresetuj',
    'Clients.Modal.Header.CreatePlanning': 'Utwórz planowanie dla nowego scenariusza',
    'Clients.Modal.EmptyMessage.NoClientScenariosFound': 'Nie znaleziono żadnych scenariuszy klienta.',
    'Clients.Modal.EmptyMessage.NoBankScenariosFound': 'Nie znaleziono żadnych scenariuszy z banku.',
    'Clients.Modal.Message.NoTeamsSpecified': 'Nie określono zespołów',
    'Clients.Modal.EmptyMessage.NoSelectedTeams': 'Brak wybranych zespołów',
    'Clients.Modal.Message.NoAssignedTeams': 'Klient nie ma przypisanych zespołów',
    'Clients.Modal.Label.Certificate': 'Certyfikat',
    'Clients.Modal.Label.DeliveryMode': 'Metoda dostawy',
    'Clients.Modal.Label.SessionSize': 'Rozmiar sesji',
    'Clients.Modal.Label.SessionLength': 'Długość sesji',
    'Clients.Modal.Label.Outcome': 'Wynik',
    'Clients.Modal.Label.Strategies': 'Strategie',
    'Clients.Modal.Label.Description': 'Opis',
    'MursionPortal.Button.MoreInfo': 'Więcej informacji...',
    'MursionPortal.Button.HideAdditionalInfo': 'Ukryj dodatkowe informacje...',
    'MursionPortal.Header.Teams': 'Zespoły',
    'MursionPortal.Header.Timeline': 'Oś czasu',
    'MursionPortal.Header.Projects': 'Projekty',
    'MursionPortal.Header.Sessions': 'Sesje',
    'MursionPortal.Header.Users': 'Użytkownicy',
    'MursionPortal.Text.Yes': 'tak',
    'MursionPortal.Text.No': 'nie',
    'MursionPortal.Button.Google': 'Google',
    'MursionPortal.Button.Outlook': 'Outlook, iCal, inne',
    'MursionPortal.Heading.GoogleCalendar': 'Kalendarz Google',
    'MursionPortal.Heading.AddToCalendar': 'Dodaj do kalendarza',
    'Dashboard.Legacy.GoogleCalendarList.Authorized': 'Jeśli jeszcze tego nie zrobiłeś, zostaniesz poproszony o <code>autoryzację</code> portalu <code>{companyName}</code> w celu dodania wydarzeń do kalendarza.',
    'Dashboard.Legacy.GoogleCalendarList.EventAdd': 'Wydarzenie zostanie <code>dodane do Twojego kalendarza</code> i będzie nadal dostępne w portalu {companyName}.',
    'MursionPortal.Heading.OtherCalendars': 'Inne kalendarze',
    'Dashboard.Legacy.OtherCalendarList.Downloaded': 'Twoje wydarzenie zostanie <code>pobrane</code> jako <code>{companyName}</code>',
    'Dashboard.Legacy.OtherCalendarList.SelectCalendar': '<code>Kliknij prawym przyciskiem myszy</code>, wybierz <code>"Otwórz za pomocą"</code> i wybierz swoją aplikację kalendarza.',
    'Dashboard.Legacy.OtherCalendarList.ImportWithinCalendar': '(Możesz także wybrać opcję „Importuj” w swoim kalendarzu i zlokalizować pobrany plik)',
    'Dashboard.Legacy.OtherCalendarList.AddedToCalendar': 'Wydarzenie zostanie <code>dodane do Twojego kalendarza</code> i będzie nadal dostępne w portalu {companyName}',
    'MursionPortal.Text.NoLearnersAddedYet': 'Nie dodano jeszcze żadnych uczniów',
    'MursionPortal.Button.More': 'Więcej',
    'MursionPortal.Heading.Schedule': 'Harmonogram',
    'MursionPortal.Heading.Users': 'UŻYTKOWNICY',
    'MursionPortal.Heading.ProfServices': 'Usługi Profesjonalne',
    'MursionPortal.Heading.ProjectManagers': 'Kierownicy Projektów',
    'MursionPortal.Heading.SimSpecialist': 'Sim Specialiści',
    'MursionPortal.Heading.Clients': 'Klienci',
    'MursionPortal.Heading.Sessions': 'Sesje',
    'MursionPortal.Heading.Projects': 'Projekty',
    'MursionPortal.Heading.Teams': 'Zespoły',
    'Projects.Button.CreateProject': 'Utwórz projekt',
    'Projects.ListHeader.CreationDate': 'Data utworzenia',
    'Projects.ListHeader.ProjectManagers': 'Kierownicy Projektów',
    'MursionPortal.Capitalized.Active': 'AKTYWNE',
    'MursionPortal.Capitalized.Completed': 'UKOŃCZONE',
    'MursionPortal.Capitalized.Archived': 'ZARCHIWIZOWANE',
    'MursionPortal.Capitalized.All': 'WSZYSTKO',
    'MursionPortal.Label.ProjectId': 'ID umowy',
    'Projects.ProjectCard.BackToClient': 'Powrót do Klienta',
    'Projects.ProjectCard.NoProjectsFound': 'Nie znaleziono projektów',
    'Projects.ProjectCard.PlaceHolder.EnterProjectName': 'Wprowadź nazwę projektu',
    'Projects.ProjectCard.ProjectsList': 'LISTA PROJEKTÓW',
    'MursionPortal.Capitalized.Project': 'PROJEKT',
    'Projects.ScenarioList.ThereAreNoScenarios': 'Nie ma jeszcze żadnych scenariuszy.',
    'Projects.ScenarioList.NoScenariosFound': 'Nie znaleziono żadnych scenariuszy.',
    'Projects.ScenarioList.Button.Draft': 'Wersja robocza',
    'Projects.ScenarioList.Legend.ScenariosStatusTab': 'Karty statusów scenariuszy',
    'Projects.ScenarioList.Legend.CreateNew': 'Przyciski scenariusza Utwórz nowy, Powtórz klienta, Powtórz bank',
    'MursionPortal.Finalized': 'Sfinalizowane',
    'MursionPortal.Started': 'Rozpoczęte',
    'MursionPortal.Finished': 'Zakończone',
    'MursionPortal.Archived': 'Zarchiwizowane',
    'MursionPortal.EnterScenarioName': 'Wprowadź nazwę scenariusza',
    'Projects.AllProjects': 'wszystkie projekty',
    'MursionPortal.Label.LowerCase.Archived': 'zarchiwizowane',
    'MursionPortal.Label.Active': 'Aktywne',
    'MursionPortal.Label.Completed': 'Ukończone',
    'MursionPortal.Label.Opened': 'Otwarte',
    'MursionPortal.Label.ActedOn': 'Podjęto działanie',
    'MursionPortal.Label.Unread': 'Nieprzeczytane',
    'Projects.Tab.Basic': 'Podstawowe',
    'MursionPortal.Heading.ProjectName': 'Nazwa projektu',
    'MursionPortal.Label.Created': 'Utworzone',
    'MursionPortal.Label.Status': 'Status',
    'MursionPortal.Label.Learners': 'Uczniowie',
    'MursionPortal.Label.LearnersCount': '{count} uczniów',
    'Projects.Tab.NoLearners': 'Brak uczniów',
    'MursionPortal.Label.SoftwareVersion': 'Wersja software',
    'MursionPortal.Label.SoftwareType': 'Typ',
    'Projects.ProjectInfo.Label.Non-Billable': 'Nie podlegające rozliczeniu',
    'Projects.ProjectInfo.Label.Contracted': 'Zakontraktowane',
    'Projects.ProjectInfo.Label.CancellationWindow': 'Okno anulowania',
    'MursionPortal.Header.Recording': 'Nagranie',
    'Projects.ProjectInfo.Label.SurveyControl': 'Ankiety projektowe',
    'Projects.ProjectInfo.Checkbox.EnablePreSimulationSurvey': 'Włącz ankietę dla ucznia przed symulacją',
    'Projects.ProjectInfo.Checkbox.EnablePostSimulationSurvey': 'Włącz ankietę dla ucznia po symulacji',
    'Projects.ProjectInfo.Checkbox.EnablePostSimulationSurvey.OneToOne': 'Włącz ankietę zwrotną SIM po symulacji dla dostaw 1:1',
    'Projects.ProjectInfo.Checkbox.EnablePostSimulationSurvey.Workshop': 'Włącz ankietę zwrotną SIM po symulacji dla dostaw warsztatowych',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled': 'Ankiety projektowe',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PreSimulationSurvey': 'Ankieta dla ucznia przed symulacją',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PostSimulationSurvey': 'Ankieta dla ucznia po symulacji',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PostSimulationSurvey.OneToOne': 'Badanie opinii SIM po symulacji 1: 1 dostawy',
    'Projects.ProjectInfo.Checkbox.SurveyEnabled.PostSimulationSurvey.Workshop': 'Badanie opinii SIM po symulacji 1: 1 dostawy warsztatowej',
    'Projects.ProjectInfo.Checkbox.AllowRecording': 'Zezwól na nagrywanie',
    'Projects.ProjectInfo.Checkbox.AllowRecordingInfo': 'Nie spowoduje to usunięcia żadnych aktualnie zapisanych nagrań.',
    'MursionPortal.Label.VideoRecording': 'Nagranie wideo',
    'MursionPortal.Label.Allowed': 'Dozwolone',
    'MursionPortal.Label.Disallowed': 'Niedozwolone',
    'MursionPortal.Label.RecordedVideosGoBackTo': 'Nagrane filmy wracają do:',
    'MursionPortal.Checkbox.RecordingPermissionLiesWithLearner': 'Zgoda na nagrywanie leży po stronie ucznia',
    'MursionPortal.Label.LiesWithLearner': 'Leży po stronie ucznia',
    'MursionPortal.Heading.SessionSmartMetrics': 'Analiza głosowa sesji:',
    'MursionPortal.Checkbox.EnableSmartMetrics': 'Zezwól na analizę głosową ucznia w sesji',
    'MursionPortal.Label.VideoRecordingSharing': 'Udostępnianie lub pobieranie przez użytkowników nagrań wideo z sesji',
    'MursionPortal.Label.SharingOrDownloading': 'Nie zezwalaj na udostępnianie ani pobieranie',
    'Projects.ProjectInfo.Label.Enabled': 'Aktywne',
    'Projects.ProjectInfo.Label.Disabled': 'Nieaktywne',
    'MursionPortal.Heading.Tags': 'Tagi',
    'MursionPortal.Button.AreYouSureEditProject': 'Czy na pewno chcesz edytować ten projekt?',
    'MursionPortal.Button.SaveProjectInfo': 'Zapisz informacje o projekcie',
    'Projects.Tab.Utilization': 'Wykorzystanie',
    'MursionPortal.Label.Loading': 'Ładowanie...',
    'Projects.ProjectInfo.Label.NoProjectManagers': 'Brak kierowników projektów',
    'Projects.ProjectInfo.Label.NoSelectedProjectManagers': 'Brak wybranych kierowników projektów',
    'MursionPortal.Label.ProjectManagers': 'Kierownicy Projektów',
    'MursionPortal.Label.RecordingPermission': 'Pozwolenie na nagrywanie:',
    'MursionPortal.Label.Timeline': 'Oś czasu projektu',
    'Projects.ProjectInfo.Label.TimelineUtc': 'Oś czasu projektu (UTC)',
    'Projects.Utilization.Label.UtilizedSessions': 'Wykorzystane sesje:',
    'Projects.Utilization.Label.UtilizedMissedSessions': 'Wykorzystane pominięte sesje:',
    'Projects.Utilization.Label.UtilizedCancelledSessions': 'Wykorzystane anulowane sesje:',
    'Projects.Utilization.Label.UtilizedDuration': 'Wykorzystany czas trwania:',
    'Projects.Utilization.Label.RemainingDuration': 'Pozostały czas trwania:',
    'MursionPortal.ScenarioListItem.Label.Timeline': 'oś czasu',
    'MursionPortal.ScenarioListItem.Label.SessionLength': 'długość sesji',
    'MursionPortal.ScenarioListItem.Label.SessionSize': 'rozmiar sesji',
    'MursionPortal.ScenarioListItem.Label.DeliveryMode': 'metoda dostawy',
    'MursionPortal.ScenarioCard.BackToProject': 'Powrót do projektu',
    'MursionPortal.ScenarioCard.NoScenariosFound': 'Nie znaleziono scenariuszy',
    'MursionPortal.Placeholder.EnterScenarioName': 'Wprowadź nazwę scenariusza',
    'MursionPortal.ScenarioCard.Label.ScenariosList': 'LISTA SCENARIUSZY',
    'MursionPortal.ScenarioCard.Header.Scenario': 'SCENARIUSZ',
    'MursionPortal.ScenarioCard.Header.ChooseTypeOfEvent': 'Wybierz typ wydarzenia do zaplanowania',
    'MursionPortal.Label.Session': 'Sesja',
    'MursionPortal.Label.Training': 'Szkolenie',
    'MursionPortal.Button.Continue': 'Kontynuuj',
    'MursionPortal.Button.Schedule': 'Harmonogram',
    'MursionPortal.Label.Draft': 'Wersja robocza',
    'MursionPortal.Label.Finalized': 'Sfinalizowane',
    'MursionPortal.Label.Started': 'Rozpoczęte',
    'MursionPortal.Label.Finished': 'Zakończone',
    'MursionPortal.Label.Archived': 'Zarchiwizowane',
    'MursionPortal.Label.Cancel': 'anuluj',
    'MursionPortal.Label.Edit': 'edytuj',
    'MursionPortal.Label.CancelEdit': 'Anuluj edycję',
    'MursionPortal.Label.EditScenario': 'Edytuj scenariusz',
    'MursionPortal.Label.EditScenarioName': 'Edytuj nazwę scenariusza',
    'MursionPortal.ScenarioCard.Label.ApplyNameChange': 'Zastosuj zmianę nazwy',
    'MursionPortal.ScenarioCard.Label.Apply': 'zastosuj',
    'MursionPortal.ScenarioCard.Tab.Info': 'INFORMACJE',
    'MursionPortal.ScenarioCard.Tab.Learners': 'UCZNIOWIE',
    'MursionPortal.ScenarioCard.Tab.Sessions': 'SESJE',
    'MursionPortal.ScenarioCard.Tab.Legend.ViewModeTab': 'Karta trybu przeglądania',
    'MursionPortal.ScenarioCard.ConfirmButton.AreYouSureArchiveScenario': 'Czy na pewno chcesz zarchiwizować ten scenariusz? Wszystkie powiązane dane również zostaną zarchiwizowane.',
    'MursionPortal.ScenarioCard.ConfirmButton.ArchiveScenario': 'Archiwizuj scenariusz',
    'MursionPortal.Label.Delivery': 'Dostawa',
    'MursionPortal.ScenarioInfo.Header.ArchivedScenario': 'SCENARIUSZ ZARCHIWIZOWANY',
    'MursionPortal.ScenarioInfo.Button.YouMayOnlyEditTeams': 'Zespoły i oś czasu można edytować tylko po sfinalizowaniu scenariusza',
    'MursionPortal.ScenarioInfo.Button.FinalizeScenario': 'Sfinalizuj scenariusz',
    'MursionPortal.ScenarioInfo.Button.SaveScenario': 'Zapisz scenariusz',
    'MursionPortal.ScenarioInfo.Button.CreatePlanning': 'Utwórz plan',
    'MursionPortal.ScenarioInfo.Header.Description': 'Opis',
    'MursionPortal.ScenarioInfo.Label.SimLength': 'Długość Sim',
    'MursionPortal.Header.Outcome': 'Wynik',
    'MursionPortal.Header.Strategies': 'Strategie',
    'MursionPortal.Header.Attachments': 'Załączniki',
    'MursionPortal.ScenarioInfo.Button.SaveScenarioInfo': 'Zapisz informacje o scenariuszu',
    'MursionPortal.Label.ContentBundle': 'Pakiet treści',
    'MursionPortal.ScenarioInfo.Button.ShowLess': 'Pokaż mniej',
    'MursionPortal.ScenarioInfo.Button.ShowMore': 'Pokaż więcej',
    'MursionPortal.AssetSettings.Label.CurrentScenarioNoAssetSettings': 'Bieżący scenariusz nie posiada ustawień zasobów.',
    'MursionPortal.Teams.Label.NoSelectedTeams': 'Brak wybranych zespołów',
    'MursionPortal.Teams.Label.ClientHasNoAssignedTeams': 'Klient nie ma przypisanych zespołów',
    'MursionPortal.Label.Teams': 'Zespoły',
    'MursionPortal.Label.NoTeamsSpecified': 'Nie określono zespołów',
    'MursionPortal.Label.Capitalized.Draft': 'WERSJA ROBOCZA',
    'MursionPortal.Label.Capitalized.Finalized': 'SFINALIZOWANE',
    'MursionPortal.Label.Capitalized.Started': 'ROZPOCZĘTE',
    'MursionPortal.Label.Capitalized.Finished': 'ZAKOŃCZONE',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioStillInDevelopmentStage': 'Scenariusz jest wciąż w fazie rozwoju',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioDeployedTimelineUpcoming': 'Scenariusz został wdrożony, ale oś czasu jest wciąż przygotowywana',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioActiveLearnersCanSchedule': 'Scenariusz jest aktywny, a uczniowie mogą planować sesje',
    'MursionPortal.ScenarioInfo.Tooltip.ScenarioIsCompleted': 'Scenariusz jest ukończony',
    'MursionPortal.Button.Add': 'Dodaj',
    'MursionPortal.Label.Warning': 'Ostrzeżenie',
    'MursionPortal.SessionWizard.Label.UnableToCreateSession': 'Nie można utworzyć sesji dla wybranego {failedParam}.',
    'MursionPortal.SessionWizard.Label.PleaseContinueToFindAnother': 'Naciśnij kontynuuj, aby znaleźć kolejny <code>{failedParam}</code>.',
    'MursionPortal.SessionWizard.Label.ScheduleSession': 'Zaplanuj sesję',
    'MursionPortal.SessionWizard.Label.ScheduleTraining': 'Zaplanuj szkolenie',
    'MursionPortal.SessionWizard.Label.AddNotes': 'Dodaj uwagi',
    'MursionPortal.SessionWizard.Label.SelectSimulationContentForUse': 'Wybierz treść symulacji do użytku',
    'MursionPortal.SessionWizard.Label.SelectStep': 'Wybierz {step}',
    'MursionPortal.SessionWizard.Label.Expired': 'wygasły',
    'MursionPortal.SessionWizard.ConfirmButton.AreYouSureCreateEmergencySession': 'Czy na pewno chcesz utworzyć sesję awaryjną?',
    'MursionPortal.SessionWizard.Button.Create': 'Utwórz',
    'MursionPortal.SessionWizard.Label.RemainingTimeToBook': 'pozostało do zarezerwowania tego bloku czasowego przed wygaśnięciem wstrzymania.',
    'MursionPortal.SessionWizard.Label.TimeBlockHoldExpired': 'Wstrzymanie bloku czasowego wygasło.',
    'MursionPortal.Label.Date': 'Data',
    'MursionPortal.Label.Time': 'Czas',
    'MursionPortal.SessionWizard.Label.TimeNoLongerAvailableClickSubmit': 'Ta godzina nie jest już dostępna, czy chcesz wybrać inną opcję? Wróć, aby wybrać dostępną opcję lub kliknij Prześlij, aby poprosić o istniejącą opcję.',
    'MursionPortal.SessionWizard.Label.TimeNoLongerAvailable': 'Ta godzina nie jest już dostępna, czy chcesz wybrać inną opcję? Wróć, aby wybrać dostępną opcję.',
    'MursionPortal.SessionWizard.Button.GoBack': 'Wróć',
    'MursionPortal.Button.Submit': 'Wyślij',
    'MursionPortal.SessionWizard.Label.NoAvailableClients': 'Brak dostępnych klientów',
    'MursionPortal.SessionWizard.Label.NoAvailableProjects': 'Brak dostępnych projektów',
    'MursionPortal.SessionWizard.Label.NoAvailableScenarios': 'Brak dostępnych scenariuszy',
    'MursionPortal.SessionWizard.Label.PleaseSelectScenario': 'Najpierw wybierz scenariusz',
    'MursionPortal.SessionWizard.Label.NoImage': 'Brak obrazu',
    'MursionPortal.SessionWizard.EmptyMessage.NoAvailableSImSpecialists': 'Brak dostępnych Sim Specialist',
    'MursionPortal.SessionWizard.Label.ScenarioHasNoContentBundles': 'Scenariusz nie posiada przypisanych pakietów zawartości.',
    'MursionPortal.SessionWizard.Option.SelectAnAsset': 'Wybierz zasób',
    'MursionPortal.SimSpecialistStep.Label.NoSimSpecialistsAvailable': 'W wybranym czasie nie ma dostępnych sim-specialists. Sesja zostanie przydzielona na żądanie.',
    'MursionPortal.SsLearnersStep.ErrorMessage.YouCanAddMaxTrainees': 'Do tego typu sesji możesz dodać maksymalnie {maxLearners} kursantów.',
    'MursionPortal.SsLearnersStep.ErrorMessage.YouCanAddMaxTrainee': 'Do tego typu sesji możesz dodać maksymalnie {maxLearners} kursanta.',
    'MursionPortal.EmptyMessage.NoUsersSelected': 'Nie wybrano użytkowników',
    'MursionPortal.Placeholder.Search': 'Szukaj',
    'MursionPortal.SsLearnersStep.Label.NoTraineeToSelect': 'Brak kursantów do wyboru',
    'MursionPortal.LearnersStep.ErrorMessage.YouCanAddMaxLearners': 'Do tego typu sesji możesz dodać maksymalnie {maxLearners} uczniów.',
    'MursionPortal.LearnersStep.ErrorMessage.YouCanAddMaxLearner': 'Do tego typu sesji możesz dodać maksymalnie {maxLearners} ucznia.',
    'MursionPortal.LearnersStep.Label.NoLearnersToSelect': 'Brak uczniów do wyboru',
    'MursionPortal.LearnersStep.Label.NoUsersSelected': 'Nie wybrano użytkowników',
    'MursionPortal.LearnersStep.Option.AllTeams': 'Wszystkie zespoły',
    'MursionPortal.LearnersStep.Button.AddEntireTeam': 'Dodaj cały zespół',
    'MursionPortal.LearnersStep.Note.AllowedSessionSIze': 'Dozwolony rozmiar sesji to {maxLearners}. Wybierz konkretnych uczniów',
    'MursionPortal.LearnersStep.Checkbox.OnlyUnauthorizedUsers': 'Dołączą tylko nieuwierzytelnieni użytkownicy',
    'MursionPortal.NotesStep.SessionNotes': 'Uwagi do sesji',
    'MursionPortal.NotesStep.ErrorMessage.AllowedLimitExceeded': 'Przekroczono dozwolony limit znaków ({limit} znaków).',
    'MursionPortal.Label.Trainer': 'Instruktor',
    'MursionPortal.Label.Description': 'Opis',
    'MursionPortal.Label.Outcome': 'Wynik',
    'MursionPortal.Label.Strategies': 'Strategie',
    'MursionPortal.Label.DeliveryMode': 'Metoda dostawy',
    'MursionPortal.Label.SessionLength': 'Długość sesji',
    'MursionPortal.Label.NoDescriptionProvided': 'Brak opisu',
    'MursionPortal.Label.NoOutcomeProvided': 'Nie podano rezultatu',
    'MursionPortal.Label.NoStrategiesProvided': 'Nie podano strategii',
    'MursionPortal.Placeholder.Dotted.Search': 'Szukaj...',
    'MursionPortal.TimeStep.Label.NoAvailableTimeBlocks': 'Brak dostępnych bloków czasowych',
    'MursionPortal.TimeStep.Label.NoAvailableTimeBlocksInRange': 'Brak dostępnych bloków czasowych w wybranym zakresie',
    'MursionPortal.TimeStep.Footer.BySelectingThisTimeBlock': 'Wybierając ten blok czasowy prosisz o aktualnie niedostępny czas sesji. Dołożymy wszelkich starań, aby spełnić tę prośbę, jeśli będziesz kontynuować lub przesuń poniższy przełącznik „Wyłącz”, aby wybrać tylko z bloków czasowych o potwierdzonej dostępności.',
    'MursionPortal.TimeStep.Tooltip.SlideTheToggleOff': 'Przesuń przełącznik „Wyłącz”, aby wybrać tylko z dostępnych bloków czasowych.',
    'MursionPortal.TimeStep.Tooltip.SlideTheToggleOn': 'Przesuń przełącznik „Włącz”, aby poprosić o dodatkowy blok czasu.',
    'MursionPortal.Label.LowerCase.Client': 'klient',
    'MursionPortal.Label.Project': 'projekt',
    'MursionPortal.Label.Scenario': 'scenariusz',
    'MursionPortal.Projects.NoProjectsFound': 'Nie znaleziono żadnych projektów.',
    'MursionPortal.Projects.YourTeamsNotAssigned': 'Twoje zespoły nie zostały jeszcze przypisane do żadnych projektów.',
    'MursionPortal.Projects.NoProjectsCreated': 'Nie utworzono jeszcze żadnych projektów.',
    'MursionPortal.Teams.NoTeamsFound': 'Nie znaleziono żadnych zespołów.',
    'MursionPortal.Teams.YouHaveNotBeen': 'Nie zostałeś jeszcze dodany do żadnego zespołu.',
    'MursionPortal.Teams.YouHaveNoTeams': 'Nie masz jeszcze żadnych zespołów.',
    'MursionPortal.Teams.NoTeamsCreated': 'Nie utworzono jeszcze żadnych zespołów.',
    'Settings.SSO.ModalBody.SelectDestination': 'Wybierz miejsce docelowe linku',
    'Settings.SSO.ModalBody.ProjectSelector.DisabledTitle': 'Najpierw wybierz odpowiednią opcję',
    'Settings.SSO.ModalBody.ScenarioSelector.DisabledTitle': 'Najpierw wybierz projekt',
    'Settings.SSO.ModalBody.SelectDestination.SessionsTab': 'Karta Sesji',
    'Settings.SSO.ModalBody.SelectDestination.CalendarTab': 'Karta Kalendarza',
    'Settings.SSO.ModalBody.SelectDestination.ReportsTab': 'Karta Raportów',
    'Settings.SSO.ModalBody.SelectDestination.DashboardTab': 'Karta Pulpitu Nawigacyjnego',
    'Settings.SSO.ModalBody.SelectDestination.ScenarioCard': 'Karta Scenariusza',
    'Settings.SSO.ModalBody.SelectDestination.ScenarioTabSelectTitle': 'Najpierw wybierz projekt, a następnie scenariusz',
    'Settings.SSO.Modal.ConfigurationName': 'Nazwa konfiguracji',
    'Settings.SSO.Modal.AuthorizationEndpoint': 'Punkt końcowy autoryzacji',
    'Settings.SSO.Modal.LogoutEndpoint': 'Punkt końcowy wylogowywania',
    'Settings.SSO.Modal.ClientID': 'ID klienta',
    'Settings.SSO.Modal.ClientSecret': 'Sekret klienta',
    'Settings.SSO.Modal.Scope': 'Zakres',
    'Settings.SSO.Modal.UserInfoEndpoint': 'Punkt końcowy informacji o użytkowniku',
    'Settings.SSO.Modal.TokenEndpoint': 'Punkt końcowy tokena',
    'Settings.SSO.Modal.TokenHTTPMethod': 'Metoda HTTP tokena',
    'Settings.SSO.Modal.UserIDClaimName': 'Nazwa żądania ID użytkownika',
    'Settings.SSO.Modal.EmailClaimName': 'Nazwa żądania e-maila',
    'Settings.SSO.Modal.FirstNameClaimName': 'Nazwa żądania imienia',
    'Settings.SSO.Modal.LastNameClaimName': 'Nazwa żądania nazwiska',
    'Settings.SSO.Modal.EmployeeIDClaimName': 'Nazwa żądania ID pracownika',
    'Settings.SSO.Modal.LocationClaimName': 'Lokalizacja',
    'Settings.SSO.Modal.DepartmentClaimName': 'Dział',
    'Settings.SSO.Modal.PhoneClaimName': 'Numer telefonu',
    'Settings.SSO.Modal.TitleClaimName': 'Tytuł',
    'Settings.SSO.Modal.TimezoneClaimName': 'Strefa czasowa',
    'Settings.SSO.Modal.LanguageClaimName': 'Język',
    'Settings.SSO.Modal.LinkAllowed': 'Połączenie dozwolone',
    'Settings.SSO.Modal.UnlinkAllowed': 'Odłączenie dozwolone',
    'Settings.SSO.Modal.SingleLogoutEnabled': 'Pojedyncze wylogowanie włączone',
    'Settings.SSO.Modal.SingleSignOnServiceEndpoint': 'Punkt końcowy usługi pojedynczego logowania',
    'Settings.SSO.Modal.EntityID': 'ID podmiotu',
    'Settings.SSO.Modal.X509Certificate': 'Certyfikat X.509',
    'Settings.SSO.Button.CreateSsoConfiguration': 'Utwórz konfigurację SSO',
    'Settings.SSO.Button.CreateDeepLink': 'Utwórz Deep Link SSO',
    'Settings.SSO.Modal.Protocol': 'Protokół :',
    'Settings.SSO.Modal.Type': 'Typ :',
    'Settings.SSO.Modal.ID': 'ID :',
    'Settings.SSO.Modal.AssignedClients': 'Przypisani Klienci',
    'Settings.SSO.Modal.Button.DeleteSso': 'Usuń SSO',
    'Settings.SSO.ModalHeader.EditSsoConfiguration': 'Edytuj konfigurację SSO',
    'Settings.SSO.ModalHeader.DeepLink': 'Utwórz Deep Link SSO',
    'Settings.SSO.ModalHeader.NewSsoConfiguration': 'Nowa konfiguracja SSO',
    'Settings.SSO.Modal.Button.CreateSsoConfiguration': 'Utwórz konfigurację SSO',
    'Settings.SSO.Modal.Button.SaveSsoConfiguration': 'Zapisz konfigurację SSO',
    'Settings.SSO.Modal.DeletionConfirmationText': 'Czy na pewno chcesz usunąć tę konfigurację SSO?',
    'Settings.SSO.Modal.AddButton.GroupClaimName': 'Nazwa żądania grupy',
    'Settings.SSO.Modal.AddButton.Team': 'Zespół',
    'Settings.SSO.Modal.AddButton.PlaceHolder.GroupClaimValue': 'Nazwa żądania grupy',
    'Settings.SSO.Modal.SPInitiatedSsoUrl': 'Adres URL SSO zainicjowanego przez SP:',
    'Settings.SSO.Modal.Button.DownloadSPMetadata': 'Pobierz metadane SP',
    'Settings.SSO.Modal.Button.Public': 'Publiczne',
    'Settings.SSO.Modal.Button.Private': 'Prywatne',
    'Settings.SSO.Modal.ConfigurationIcon': 'Ikona konfiguracji:',
    'Settings.SSO.Modal.PlaceHolder.EnterTeamClaimName': 'Wprowadź nazwę żądania zespołu',
    'Settings.SSO.Modal.TeamClaimName': 'Nazwa żądania zespołu',
    'Settings.SSO.Modal.TeamMapping': 'Mapowanie zespołu:',
    'Settings.SSO.Modal.Button.Change': 'Zmień',
    'MursionPortal.PlaceHolder.SelectAClient': 'Wybierz klienta',
    'Settings.SSO.Modal.AddButton.PlaceHolder.SelectATeam': 'Wybierz zespół',
    'MursionPortal.Label.Client': 'Klient',
    'Settings.SSO.Modal.ConfirmDelete': 'Potwierdź usunięcie',
    'Settings.SSO.Table.ColumnHeader.ClientName': 'Imię klienta',
    'Settings.SSO.Table.ColumnHeader.ConfigurationName': 'Nazwa konfiguracji',
    'Settings.SSO.Table.ColumnHeader.Type': 'Typ',
    'Settings.SSO.Table.ColumnHeader.Protocol': 'Protokół',
    'Settings.SSO.Table.ColumnHeader.ClientsCount': 'Liczba klientów',
    'Settings.SSO.PlaceHolder.EnterSsoName': 'Wprowadź nazwę SSO',
    'Settings.SSO.Table.NoSsoSchemasFound': 'Nie znaleziono schematów SSO.',
    'Settings.SSO.Table.NoSsoConfigurationsFound': 'Nie znaleziono konfiguracji SSO.',
    'Settings.SSO.Table.HoverTitle.ViewEditSso': 'Wyświetl/edytuj SSO',
    'Settings.SSO.Modal.HoverTitle.CopySPInitiatedSsoUrl': 'Skopiuj adres URL SSO zainicjowanego przez SP',
    'Settings.SSO.Modal.CopyButton.ModalHeader.SPInitiatedSsoUrl': 'Adres URL SSO zainicjowanego przez SP',
    'MursionPortal.CopyText.CopiedToClipboard': '<code>{value}</code> {lineBreak} został skopiowany do schowka!',
    'Settings.SSO.Text.Public': 'Publiczne',
    'Settings.SSO.Text.Private': 'Prywatne',
    'Settings.SSO.Modal.NoClientsAssigned': 'Brak przypisanych klientów',
    'MursionPortal.Label.NotAvailable': 'nie dotyczy',
    'MursionPortal.SearchPlaceHolder.SearchClient': 'Wyszukaj klienta...',
    'Settings.SSO.Modal.AddButton.SearchPlaceHolder.SearchTeam': 'Wybierz zespół...',
    'Settings.SSO.Modal.Button.ChangeLabel': 'Zmień {label}',
    'MursionPortal.Label.Yes': 'Tak',
    'MursionPortal.Label.No': 'Nie',
    'Settings.SSO.Modal.AddButton.PlaceHolder.NoOptions': 'Brak opcji',
    'MyMessages.Button.ArchiveAll': 'Zarchiwizuj wszystko',
    'MyMessages.Button.ArchivedMessages': 'Zarchiwizowane wiadomości',
    'MyMessages.Button.ShowMore': 'Pokaż więcej...',
    'MyMessages.Label.NewMessages': '<code>{newMessagesCount}</code> Nowe wiadomości',
    'MyMessages.Label.NewMessage': '<code>{newMessagesCount}</code> Nowa wiadomość',
    'MyMessages.HoverTitle.InboxButton': 'przycisk skrzynki odbiorczej',
    'MyMessages.Text.InboxButton': 'przycisk skrzynki odbiorczej',
    'MyMessages.Text.HaveNewMessages': 'Masz {total} nowych wiadomości',
    'MyMessages.Text.HaveNewMessage': 'Masz nową wiadomość',
    'MursionPortal.CookiePolicy.Header.CookiePolicy': 'Polityka Cookie',
    'MursionPortal.CookiePolicy.Text.MursionUsesEssentialCookiesOnly': 'Mursion używa tylko niezbędnych plików cookie, które są wymagane dla Twojego bezpieczeństwa i działania naszej aplikacji web. Nie używamy plików cookie do zbierania jakichkolwiek danych osobowych ani innych informacji, takich jak adresy IP czy sposób korzystania ze strony internetowej.',
    'MursionPortal.CookiePolicy.Text.ForMoreInformation': 'Więcej informacji znajdziesz w <code>Polityce Prywatności</code>',
    'MursionPortal.Button.Ok': 'Ok',
    'MyProfile.Button.SaveChanges': 'Zapisz zmiany',
    'MyProfile.Label.ExternalProfiles': 'Profile zewnętrzne',
    'MyProfile.Label.UserRoles': 'Role użytkownika',
    'MyProfile.Label.UserRole': 'Rola użytkownika',
    'MyProfile.Heading.ReviewAccountInformation': 'Sprawdź informacje o swoim koncie',
    'MyProfile.HoverTitle.UnlinkProfile': 'Odłącz swój profil {linkName}',
    'MyProfile.HoverTitle.LinkProfile': 'Połącz profil {linkName}',
    'MyProfile.Button.Unlink': 'Odłącz',
    'MyProfile.Text.UnlinkConfirmationText': 'Czy na pewno chcesz odłączyć swój profil {name}? Pamiętaj, że zostaniesz również wylogowany z {name}.',
    'MyProfile.Title.ProfileUnlink': 'Odłącz profil {name}',
    'MyProfile.Text.SuccessfullyUnlinkedProfile': 'Pomyślnie odłączyłeś swój profil {name}',
    'MyProfile.Text.TimeZoneIsSetAutomatically': 'Twoja strefa czasowa jest ustawiona automatycznie na {getTimeZone}. Kliknij „Potwierdź”, aby zaakceptować ten wybór lub „Anuluj”, aby dokonać innego wyboru.',
    'MyProfile.Text.TimeZoneChange': 'W przypadku zmiany strefy czasowej prosimy o sprawdzenie istniejących wydarzeń i dostępności.',
    'MyProfile.Text.ProfileTimeZoneNotMatchComputer': 'Strefa czasowa Twojego obecnego profilu nie odpowiada ustawieniom daty i godziny komputera ({timeZone}). Kliknij „Zapisz zmiany”, aby mimo to kontynuować, lub wybierz inną opcję strefy czasowej profilu.',
    'MursionPortal.Button.MyProfile': 'Mój profil',
    'MursionPortal.Button.LogOut': 'Wyloguj',
    'MursionPortal.Label.ChangePassword': 'Zmień hasło',
    'MursionPortal.Label.ChangeYourPassword': 'Zmień swoje hasło',
    'MyProfile.Label.Language': 'Język',
    'MursionPortal.Label.Roles': 'Role',
    'MursionPortal.Label.Role': 'Rola',
    'Settings.SSO.Modal.ClientName': 'Imię klienta',
    'Teams.Modal.Header.NewTeam': 'Nowy zespół',
    'Teams.Modal.Header.AllTeams': 'wszystkie zespoły',
    'MursionPortal.Label.Name': 'Nazwa:',
    'MursionPortal.Label.Location': 'Lokalizacja:',
    'MursionPortal.Label.Tags': 'Tagi:',
    'MursionPortal.Header.Facilitators': 'Facilitatorzy:',
    'MursionPortal.Header.Scenarios': 'Scenariusze:',
    'MursionPortal.Button.SaveTeamInfo': 'Zapisz informacje o zespole',
    'MursionPortal.IconButton.Cancel': 'anuluj',
    'MursionPortal.IconButton.Edit': 'edytuj',
    'MursionPortal.ButtonTitle.CancelEdit': 'Anuluj edycję',
    'MursionPortal.ButtonTitle.EditTeam': 'Edytuj zespół',
    'MursionPortal.ConfirmationText.RestoreTeam': 'Czy na pewno chcesz przywrócić ten zespół?',
    'MursionPortal.ButtonTitle.RestoreTeam': 'Przywróć zespół',
    'MursionPortal.ConfirmationText.ArchiveTeam': 'Czy na pewno chcesz zarchiwizować ten zespół?',
    'MursionPortal.ButtonTitle.ArchiveTeam': 'Archiwizuj zespół',
    'MursionPortal.EmptyMessage.NoFacilitators': 'Brak facilitatorów',
    'MursionPortal.EmptyMessage.NoSelectedFacilitators': 'Brak wybranych facilitatorów',
    'MursionPortal.Title.Facilitators': 'Facilitatorzy',
    'MursionPortal.Heading.Archived': '(Zarchiwizowane)',
    'Teams.Modal.Header.AllLearners': 'Wszyscy uczniowie',
    'Teams.Modal.Placeholder.SearchLearners': 'Wyszukaj uczniów...',
    'Teams.Modal.EmptyMessage.NoLearnersToSelect': 'Brak uczniów do wyboru',
    'Teams.Modal.Header.TeamLearners': 'Uczniowie w zespole',
    'Teams.Modal.Placeholder.SearchTeamLearners': 'Wyszukaj uczniów z zespołu...',
    'Teams.Modal.EmptyMessage.NoTeamLearnersToSelect': 'Brak uczniów w zespole do wyboru',
    'Teams.Modal.Header.AssignLearners': 'Przypisz uczniów do zespołu „{teamName}”',
    'MursionPortal.Modal.Button.Add': 'Dodaj',
    'MursionPortal.Modal.Button.Remove': 'Usuń',
    'Teams.AssignLearners.Modal.Button.SelectAll': 'Zaznacz wszystkie',
    'Teams.AssignLearners.Modal.EmptyMessage.NoItems': 'Brak elementów do wyboru',
    'Teams.Modal.AlertMessage.Timezone': 'W przypadku zmiany strefy czasowej prosimy o sprawdzenie istniejących wydarzeń i dostępności.',
    'Teams.SelectorMenu.SelectedOf': 'Wybrano {selected} z {totalNumber}',
    'Teams.Modal.AlertMessage.ProfileTimezone': 'Strefa czasowa Twojego obecnego profilu nie odpowiada ustawieniom daty i godziny na Twoim komputerze ({updatedTimezone}). Kliknij „Zapisz zmiany”, aby mimo to kontynuować, lub wybierz inną opcję strefy czasowej profilu.',
    'MursionPortal.Label.Learner': 'Uczeń',
    'Teams.Modal.Button.More': '{overlappedAmount} więcej...',
    'MursionPortal.IconButton.Archive': 'archiwizuj',
    'Teams.ToggleButton.Learners': 'Uczniowie',
    'Teams.ToggleButton.Sessions': 'Sesje',
    'MursionPortal.Status.RegistrationStatus': 'Status rejestracji',
    'MursionPortal.Status.RegistrationAccepted': 'Rejestracja zaakceptowana',
    'MursionPortal.Status.RegistrationNotAccepted': 'Rejestracja nie została zaakceptowana',
    'Users.Table.Hover.ViewEditAcceptedUsers': 'Wyświetl/edytuj zaakceptowanych użytkowników',
    'Users.Table.Hover.ViewEditUnacceptedUsers': 'Wyświetl/edytuj niezaakceptowanych użytkowników',
    'MursionPortal.Label.Industry': 'Branża:',
    'MursionPortal.Label.Timezone': 'Strefa czasowa:',
    'MursionPortal.Header.Client': 'Klient',
    'MursionPortal.FilterByCertificate': 'Filtruj według certyfikatu',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Header.CreateQAEvent': 'Utwórz wydarzenie QA',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.TimeBlock': 'Blok(i) czasowe',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.Participants': 'Uczestnicy',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Button.SubmitNewEvent': 'Dodaj nowe wydarzenie',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Error.TimeBlockOutsideRange': 'Blok czasowy jest poza dostępnym zakresem',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Error.TimeBlockBeforeCurrentTime': 'Blok czasowy jest wcześniejszy niż czas bieżący',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.Start': 'Start',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.End': 'Koniec',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Label.Length': 'Długość',
    'Calendar.ScheduleButton.QualityAssurance.Modal.Error.NoAvailableTimeBlock': 'Brak dostępnych bloków czasowych dla wybranego dnia',
    'Calendar.Modal.Button.Client': 'Klient',
    'Calendar.Modal.Button.Project': 'Projekt',
    'Calendar.Modal.Button.Scenario': 'Scenariusz',
    'Calendar.Modal.Header.CompanyEvents': 'Wydarzenia firmowe',
    'MursionPortal.Header.Session': 'Sesja',
    'MursionPortal.Header.Events': 'Wydarzenia',
    'MursionPortal.Header.Event': 'Wydarzenie',
    'Calendar.Modal.Label.Time': 'Czas:',
    'Calendar.Label.SelectTimeZone': 'Wybierz strefę czasową',
    'Calendar.Text.Training': 'Szkolenie',
    'Calendar.Text.QA': 'QA',
    'Calendar.Modal.Text.ConfirmDeleteTime': 'Potwierdź usunięcie bloku czasowego',
    'Calendar.Button.NewTimeBlock': 'Nowy blok czasowy',
    'Calendar.Modal.Label.Capitalized.Time': 'CZAS',
    'Calendar.Modal.Label.Participants': 'UCZESTNICY',
    'Calendar.Modal.Text.NoParticipants': 'Brak uczestników',
    'Calendar.Modal.Label.SimulationSpecialist': 'SIMULATION SPECIALIST',
    'Calendar.Modal.Label.MeetingOrganizer': 'ORGANIZATOR SPOTKANIA',
    'Calendar.Error.ErrorPrefixMustBeBetween': '{errorPrefix} musi mieścić się w przedziale od {duration1} do {duration2}.',
    'Calendar.Error.SelectedTimeBlockOverlapsExistingTime': 'Wybrany blok czasowy nakłada się na istniejący blok czasowy.',
    'Calendar.Error.SelectedTimeBlockOverlapsUnavailableTime': 'Wybrany blok czasowy pokrywa się z niedostępnym przedziałem czasowym.',
    'Calendar.Error.SelectedTimeBlockIsNotMultipleOfEventGap': 'Wybrany blok czasowy nie jest wielokrotnością przerwy między wydarzeniami ({duration})',
    'Calendar.Text.AvailabilityUser': 'Dostępność {user}',
    'Calendar.Text.AvailabilityTime': 'Dostępność {time}',
    'Calendar.Text.AvailabilityDurationAvailabilityTime': '{availabilityDuration} Dostępność {time}',
    'Calendar.Text.TrainingsTime': '{eventsCount} Szkolenia {time}',
    'Calendar.Text.SessionsTime': '{eventsCount} Sesje {time}',
    'Calendar.Text.AvailabilityName': 'Dostępność {name}',
    'Calendar.Label.Learners': 'Uczniowie:',
    'Calendar.Text.FullAvailability': 'Pełna dostępność',
    'Calendar.Text.YourAvailability': 'Twoja dostępność',
    'Calendar.Text.Event': 'Wydarzenie',
    'Calendar.Header.Trainings': 'Szkolenia',
    'Calendar.Header.CompanyEvents': 'Wydarzenia firmowe',
    'Calendar.Header.Session': 'Sesja',
    'Calendar.Error.TimeBlockIsOutsideAvailableRange': 'Blok czasowy jest poza dostępnym zakresem',
    'Calendar.Error.TimeBlockIsBeforeCurrentTime': 'Blok czasowy jest wcześniejszy niż czas bieżący',
    'Calendar.Label.Break': 'Przerwa',
    'Calendar.Label.Prep': 'Przygotowania',
    'Calendar.Modal.Header.CreateBreakPrepEvent': 'Utwórz przerwę/przygotowania',
    'Calendar.Modal.Label.EventType': 'Rodzaj wydarzenia',
    'Calendar.Modal.Label.TimeBlocks': 'Blok(i) czasowe',
    'Calendar.Text.NoTimeBlocksInSelectedRange': 'Brak dostępnych bloków czasowych w wybranym zakresie',
    'Calendar.Text.NoAvailableTimeBlocks': 'Brak dostępnych bloków czasowych',
    'Calendar.Text.SelectingThisTimeBlock': 'Wybierając ten blok czasowy prosisz o aktualnie niedostępny czas sesji. Dołożymy wszelkich starań, aby spełnić tę prośbę, jeśli będziesz kontynuować lub przesuń poniższy przełącznik „Wyłącz”, aby wybrać tylko z bloków czasowych o potwierdzonej dostępności.',
    'Calendar.ToolTip.SlideToggleOff': 'Przesuń przełącznik „Wyłącz”, aby wybrać tylko z dostępnych bloków czasowych.',
    'Calendar.ToolTip.SlideToggleOn': 'Przesuń przełącznik „Włącz”, aby poprosić o dodatkowy blok czasu.',
    'Calendar.Label.SelectAnyTimeBlock': 'Wybierz dowolny blok czasowy',
    'Users.CreateUser.Label.GoogleMeetID': 'Google Meet ID',
    'Users.CreateUser.GoogleMeetId.Placeholder.GoogleMeetID': 'Google Meet ID',
    'Users.CreateUser.GoogleMeetID.Hover': 'Wpisz Google Meet ID',
    'Setting.Edit.Modal.Error.UnableToCreateSession': 'Nie można utworzyć sesji dla wybranego czasu {formattedStartTime} - {formattedEndTime}',
    'Session.Header.Session': 'Sesja',
    'Session.Header.AcceptSessionRequest': 'Potwierdź, czy chcesz zaakceptować tę prośbę o sesję i dodać ją do swojego harmonogramu.',
    'CommonComponent.RecurrenceForm.Label.UntilDate': 'Do daty',
    'CommonComponent.RecurrenceForm.Label.Period': 'Okres',
    'CommonComponent.RecurrenceForm.Option.Daily': 'Codziennie',
    'CommonComponent.RecurrenceForm.Option.Weekly': 'Co tydzień',
    'CommonComponent.RecurrenceForm.Label.Weekday': 'Dzień tygodnia',
    'CommonComponent.RecurrenceForm.Text.NoSelectedWeekdays': 'Brak wybranych dni tygodnia',
    'CommonComponent.RecurrenceForm.Error.PleaseSelectWeekdays': 'Proszę wybrać dni tygodnia',
    'Calendar.EventType.Availability': 'Dostępność',
    'Calendar.EventType.Session': 'Sesja',
    'Calendar.EventType.Training': 'Szkolenie',
    'Calendar.EventType.CompanyEvent': 'WYDARZENIE FIRMOWE',
    'Calendar.EventType.NewEvent': 'Nowe wydarzenie',
    'Calendar.Text.NewEventTime': 'Nowe wydarzenie {time}',
    'Calendar.Header.Training': 'Szkolenie',
    'Calendar.Modal.Text.Sessions': 'Sesje',
    'Calendar.Modal.Text.Trainings': 'Szkolenia',
    'Calendar.Modal.Text.Events': 'Wydarzenia',
    'Calendar.Modal.Text.PendingNoSS': '- Oczekujące (brak SS).',
    'Calendar.Modal.Text.PendingSwap': '- Oczekujące (zamiana).',
    'Calendar.Modal.Text.PreBooked': '- Wstępnie zabookowane.',
    'Calendar.Modal.Text.NeedsReview': '- Wymaga przeglądu.',
    'Calendar.Modal.Text.JoinSession': '- możesz dołączyć do sesji.',
    'Calendar.Modal.Text.NoLearnersInSession': '- w sesji nie ma uczniów.',
    'Calendar.Modal.Text.NoLearnersInSessionAfterBookedTime': '- w sesji po zabookowanym czasie nie ma uczniów.',
    'Calendar.Modal.Header.Events': 'Wydarzenia',
    'Calendar.Modal.Header.Session': 'sesja',
    'Calendar.Modal.Header.Training': 'szkolenie',
    'Calendar.Modal.Header.Availability': 'dostępność',
    'Calendar.Modal.Filter.Client': 'Klient',
    'Calendar.Modal.Filter.Project': 'Projekt',
    'Calendar.Modal.Filter.Time': 'Czas',
    'Calendar.Modal.Label.Recurrence': 'Powtarzanie się',
    'Calendar.Modal.Header.CreateAvailability': 'Utwórz dostępność',
    'Calendar.Modal.Text.Recurring': 'Powtarzający się',
    'Calendar.SimSpecialistButton.Modal.Header.Scheduled': 'Zaplanowane',
    'Calendar.SimSpecialistButton.Modal.Header.ThisPeriod': '(ten okres)',
    'Calendar.SimSpecialistButton.Modal.Label.Sessions': 'Sesje:',
    'Calendar.SimSpecialistButton.Modal.Label.Hours': 'Godziny:',
    'Calendar.SimSpecialistButton.Modal.Label.Trainings': 'Szkolenia:',
    'Calendar.SimSpecialistButton.Modal.Label.TrainingsHours': 'Godziny szkoleń:',
    'Calendar.SimSpecialistButton.Modal.Label.Available': 'Dostępne:',
    'Calendar.SimSpecialistButton.Modal.Header.Certification': 'Certyfikacja',
    'Calendar.SimSpecialistButton.Modal.Text.NoCertificates': 'Brak certyfikatów',
    'Calendar.SimSpecialistButton.Modal.Header.SimSpecialistOverviewPanel': 'Panel przeglądu Sim Specialists',
    'Calendar.SimSpecialistButton.Modal.HoverTitle.CloseSimSpecialistOverview': 'zamknij przegląd simspecialists',
    'MursionPortal.DeliveryMode.OneToOne': '1:01',
    'MursionPortal.DeliveryMode.OneToManyRemote': 'Zdalne',
    'MursionPortal.DeliveryMode.OneToManyLocal': 'Lokalne (Fishbowl)',
    'MursionPortal.DeliveryMode.Group': 'Dostawa grupowa',
    'MursionPortal.Projects.Header.ScenarioType': 'Typ scenariusza',
    'MursionPortal.Projects.Header.Client': 'Klient',
    'MursionPortal.Projects.Header.Project': 'Projekt',
    'MursionPortal.Projects.Header.Scenario': 'Scenariusz',
    'MursionPortal.Projects.Header.SimulationContent': 'Zawartość symulacji',
    'MursionPortal.Projects.Header.Date': 'Data',
    'MursionPortal.Projects.Header.Time': 'Czas',
    'MursionPortal.Projects.Header.Notes': 'Uwagi',
    'MursionPortal.Projects.Header.Trainees': 'Kursanci',
    'Projects.Placeholder.SimContentName': 'Wprowadź nazwę zawartości Sim',
    'Projects.Message.NoSimulationContentFound': 'Nie znaleziono treści symulacji',
    'MursionPortal.Button.UncheckAll': 'Odznacz wszystkie',
    'MursionPortal.Button.CheckAll': 'Zaznacz wszystkie',
    'Projects.Header.NewScenario': 'Projekt {projectName}: nowy scenariusz',
    'MursionPortal.Button.SaveAsDraft': 'Zapisz jako wersję roboczą',
    'MursionPortal.Button.Save': 'Zapisz',
    'Projects.Message.SelectEnvironmentFirst': 'Najpierw wybierz środowisko',
    'Projects.Message.LoadingAvatarsList': 'Ładowanie listy awatarów...',
    'Projects.Message.NoAssignedAvatars': 'Wybrane środowisko nie ma przypisanych awatarów',
    'Projetcs.EmptyMessage.NoSelectedAvatars': 'Brak wybranych awatarów',
    'Projects.Avatars.NoAvatarsSpecified': 'Nie określono awatarów',
    'Projects.Message.SelectIndustryFirst': 'Najpierw wybierz branżę',
    'Projects.Message.LoadingEnvironmentsList': 'Ładowanie listy środowisk...',
    'Projects.Message.NoAssignedEnvironments': 'Wybrana branża nie ma przypisanych środowisk',
    'Projects.Message.ScenarioHasNoAssignedEnvironment': 'Scenariusz nie ma przypisanego środowiska',
    'Projects.AltText.EnvironmentImage': 'obraz-środowiska',
    'Projects.Placeholder.SelectAnEnviroment': 'Wybierz środowisko',
    'Projects.Message.UnnamedIndustry': 'Branża nienazwana',
    'Projects.Placeholder.SelectAnIndustry': 'Wybierz branżę',
    'Projects.Header.NewProject': 'Nowy projekt',
    'Projects.AltText.ClientLogo': 'Logo klienta',
    'Projects.Message.SelectAnyTimeBlock': 'Wybierz dowolny blok czasowy',
    'Projects.Message.SelectScenarioFirst': 'Najpierw wybierz scenariusz',
    'MursionPortal.Text.Asset': 'Zasób V2',
    'MursionPortal.Button.Accept': 'Zaakceptuj',
    'Projects.Modal.EmptyMessage.NoLearner': 'Brak ucznia',
    'MursionPortal.Label.SelectAll': 'Zaznacz wszystkie',
    'Projects.Modal.Placeholder.ChooseATeam': 'Wybierz zespół',
    'MursionPortal.Placeholder.SearchTeams': 'Wyszukaj zespoły...',
    'Projects.Modal.Text.SessionSize': 'Dozwolony rozmiar sesji to {maxLearners}. Wybierz konkretnych uczniów',
    'Projects.Modal.Placeholder.SearchLearners': 'Wyszukaj uczniów',
    'MursionPortal.ErrorMessage.MaxLearners': 'Do tego typu sesji możesz dodać maksymalnie {maxLearners} uczniów.',
    'MursionPortal.ErrorMessage.MaxLearner': 'Do tego typu sesji możesz dodać maksymalnie {maxLearners} ucznia.',
    'MursionPortal.Label.ChooseMembers': 'Wybierz członków',
    'MursionPortal.Message.NoMembersToSelect': 'Brak członków do wyboru',
    'MursionPortal.Message.PleaseSelectATeamFirst': 'Najpierw wybierz zespół',
    'AssetSync.Header.AssetSync': 'Synchronizacja zasobów',
    'AssetSync.Text.AssetsSyncedSuccessfully': 'Zasoby zostały pomyślnie zsynchronizowane',
    'AssetSync.Button.Sync': 'Synchronizuj',
    'AssetSync.Button.TryAgain': 'Spróbuj ponownie',
    'AssetSync.SyncButton.Text.Syncing': 'Synchronizacja',
    'SessionFlow.Label.SessionExport': 'Eksport sesji',
    'MursionPortal.Button.Export': 'Eksport',
    'SessionFlow.Label.SessionFlowData': 'Dane przepływu sesji',
    'SessionFlow.Button.LoadSessionData': 'Załaduj dane sesji',
    'SessionFlow.Button.StartSessionAnalytics': 'Rozpocznij analizę sesji',
    'SessionFlow.Label.Checklist': 'Lista kontrolna',
    'SessionFlow.Label.RecordingSettings': 'Ustawienia nagrywania',
    'SessionFlow.Label.SessionVideoFact': 'Film z sesji',
    'SessionFlow.Label.UserStatus': 'Status użytkownika',
    'SessionFlow.SessionVideoFact.ColumnHeader.VideoAllowed': 'Nagranie wideo dozwolone',
    'MursionPortal.Table.ColumnHeader.UserName': 'Nazwa użytkownika',
    'MursionPortal.Table.ColumnHeader.Email': 'E-mail',
    'MursionPortal.Table.ColumnHeader.Status': 'Status',
    'SessionFlow.StartSessionAnalyticsButton.Text.EventStartingAnalytics': 'Zdarzenie rozpoczynające proces analizy zostało pomyślnie opublikowane',
    'SessionFlow.RecordingSettings.Label.VideoRecording': 'Nagranie wideo:',
    'SessionFlow.RecordingSettings.Text.Allowed': 'Dozwolone',
    'SessionFlow.RecordingSettings.Text.Disallowed': 'Niedozwolone',
    'SessionFlow.RecordingSettings.Label.RecordingPermission': 'Pozwolenie na nagrywanie:',
    'SessionFlow.RecordingSettings.Text.LiesWithLearner': 'Leży po stronie ucznia',
    'SessionFlow.RecordingSettings.Label.RecordedVideosGoBackTo': 'Nagrane filmy wracają do:',
    'SessionFlow.Error.CannotFindSession': 'Nie można znaleźć sesji o podanym id',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Type': 'Typ',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Date': 'Data',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Error': 'Błąd',
    'SessionFlow.DetailedEventLogButton.Table.ColumnHeader.Payload': 'Payload',
    'SessionFlow.SessionFlowData.PlaceHolder.SessionID': 'ID sesji',
    'SessionFlow.Button.HideDetailedEventLog': 'Ukryj szczegółowy dziennik zdarzeń...',
    'SessionFlow.Button.ShowDetailedEventLog': 'Pokaż szczegółowy dziennik zdarzeń...',
    'SessionFlow.Button.HideSessionHistory': 'Ukryj historię sesji...',
    'SessionFlow.Button.ShowSessionHistory': 'Pokaż historię sesji...',
    'SessionFlow.Text.NoEvents': 'Brak wydarzeń',
    'SessionFlow.SessionVideoFact.Label.VideoExists': 'Film istnieje:',
    'SessionFlow.Text.NoLearners': 'Brak uczniów',
    'SessionFlow.Checklist.DataRecording': 'zapis danych',
    'SessionFlow.Checklist.DataUploading': 'ładowanie danych',
    'SessionFlow.Checklist.Analytics': 'dane analityczne',
    'Companies.ArchiveIconButton.Modal.ConfirmationText.ArchiveCompany': 'Czy na pewno chcesz zarchiwizować tę firmę?',
    'Companies.ArchiveIconButton.Modal.Header.ArchiveCompany': 'Zarchiwizuj firmę',
    'Companies.Label.CompanyInfo': 'Informacje o firmie',
    'Companies.CompanyInfo.Archived': '(Zarchiwizowane)',
    'Companies.CancelIconButton.HoverTitle.CancelEdit': 'Anuluj edycję',
    'Companies.EditIconButton.HoverTitle.EditCompany': 'Edytuj firmę',
    'Companies.Button.InviteProfService': 'Zaproś Usługi Profesjonalne',
    'Companies.InviteProfServiceButton.Modal.Header.NewProfService': 'Nowe Usługi Profesjonalne',
    'Companies.Label.Config': 'KONFIGURACJA',
    'Companies.EditIconButton.HoverTitle.EditConfig': 'Edytuj konfigurację',
    'Companies.Config.Button.Test': 'Test',
    'Companies.Config.Button.SaveCompanyConfig': 'Zapisz konfigurację firmy',
    'Companies.CompanyInfo.Button.SaveCompanyInfo': 'Zapisz informacje o firmie',
    'Companies.ValidationError': 'Wystąpiły błędy walidacji',
    'Companies.Config.SaveCompanyConfigButton.FailedToSaveConfig': 'Nie udało się zapisać konfiguracji. Proszę o poprawienie błędów.',
    'Companies.Config.Text.TestPassedSuccessfully': 'Test zakończony pomyślnie',
    'Companies.CreateUserButton.PlaceHolder.CompanyName': 'Nazwa firmy',
    'Companies.Button.CreateCompany': 'Utwórz firmę',
    'Companies.Error.NoCompanies': 'Brak firm',
    'Companies.InviteProfServiceButton.Modal.Label.ProfServices': 'Usługi Profesjonalne',
    'Companies.InviteProfServiceButton.Modal.Button.AddUser': 'Dodaj użytkownika',
    'Companies.CreateUserButton.Modal.Header.NewCompany': 'Nowa firma',
    'Companies.Header.AllCompanies': 'wszystkie firmy',
    'MursionPortal.Roles': 'Role',
    'Users.ArchiveButton.Modal.Header.ArchivedGlobalUser': 'Zarchiwizowany użytkownik globalny',
    'Users.ActiveButton.Modal.Header.EditGlobalUser': 'Edytuj użytkownika globalnego',
    'Users.CreateUserButton.Modal.Header.NewGlobalUser': 'Nowy użytkownik globalny',
    'Users.NoGlobalUserFoundError': 'Nie znaleziono żadnych użytkowników globalnych.',
    'Users.Table.ColumnHeader.Department': 'Dział',
    'MursionPortal.TechSupport.HoverTitle.ShowFeedBack': 'pokaż opinie',
    'MursionPortal.TechSupport.HoverTitle.HideFeedBack': 'ukryj opinie',
    'MursionPortal.TechSupport': 'Pomoc techniczna',
    'MursionPortal.TechSupport.Header.ContactSupport': 'Skontaktuj się z pomocą techniczną',
    'MursionPortal.TechSupport.Label.Phone': 'Telefon:',
    'MursionPortal.TechSupport.Label.Email': 'E-mail:',
    'MursionPortal.TechSupport.Label.Hours': 'Godziny:',
    'MursionPortal.TechSupport.Link.MursionSupport': 'Pomoc techniczna Mursion',
    'Downloads.Text.ClickDownloadButton': 'Kliknij przycisk pobierania poniżej, aby pobrać najnowszą wersję instalatora. Plik zostanie zapisany w domyślnej lokalizacji pobierania przeglądarki (np. w folderze pobrane).',
    'Downloads.Text.OnceDownloadIsComplete': 'Po zakończeniu pobierania kliknij pobrany plik i postępuj zgodnie z instrukcjami wyświetlanymi na ekranie, aby ukończyć instalację.',
    'Downloads.Text.AppInstalled': 'Po zainstalowaniu app możesz dwukrotnie kliknąć ikonę Mursion następnym razem, gdy będziesz chcieć ją uruchomić.',
    'Downloads.Button.Download': 'Pobierz',
    'Downloads.Text.PlatformNotCurrentlySupported': 'Przepraszamy, ale Twoja platforma nie jest obecnie obsługiwana.',
    'Downloads.Text.FollowOnScreenInstructions': 'Kliknij {info} i postępuj zgodnie z instrukcjami wyświetlanymi na ekranie, aby zainstalować aplikację',
    'Downloads.Text.ShortcutCreatedOnInstallationComplete': 'Po zakończeniu instalacji na pulpicie zostanie utworzony skrót do Mursion. {lineBreak} Możesz dwukrotnie kliknąć skrót {image}, gdy będziesz chcieć uruchomić aplikację, aby wziąć udział w zaplanowanej symulacji.',
    'Filters.Date.LastWeek': 'Zeszły tydzień',
    'MursionPortal.Text.Mins': 'min',
    'MursionPortal.Text.Min': 'min.',
    'Tutorial.Header.ManageYourTeams': 'Zarządzaj swoimi zespołami',
    'MursionPortal.Button.Awesome': 'Wspaniale',
    'Tutorial.Header.ReviewYourProjects': 'Przejrzyj swoje projekty',
    'MursionPortal.Button.LooksGood': 'Wygląda dobrze',
    'Tutorial.Header.LearnerScheduling': 'Planowanie harmonogramu ucznia',
    'MursionPortal.Button.AllDone': 'Wszystko gotowe!',
    'Tutorial.Header.InviteYourUsers': 'Zaproś swoich użytkowników',
    'MursionPortal.Button.GotIt': 'Rozumiem',
    'Tutorial.Header.CreateATeam': 'Utwórz zespół',
    'Tutorial.Header.ManageYourProjects': 'Zarządzaj swoimi projektami',
    'Tutorial.Header.YourTeams': 'Twoje zespoły',
    'Tutorial.Header.YourProjects': 'Twoje projekty',
    'Tutorial.Header.SessionCalendar': 'Kalendarz sesji',
    'Tutorial.Tooltip.TeamsOrganize': 'Zespoły organizują uczniów i zostaną dodane do scenariuszy',
    'Tutorial.Text.ViewSessions': 'Zobacz <code>nadchodzące i zakończone</code> sesje zespołu',
    'Tutorial.Tooltip.ContactManager': 'lub skontaktuj się z kierownikiem projektu {companyName}',
    'Tutorial.Tooltip.YourPointOfContact': 'Twój punkt kontaktowy we wszystkich kwestiach związanych z nauką',
    'Tutorial.Text.TeamAdded': 'Zespół zostanie <code>dodany do scenariusza</code>, aby rozpocząć planowanie',
    'Tutorial.Text.ViewLearners': 'Zobacz <code>planowane aktywności</code> i <code>status</code> uczniów',
    'Tutorial.Text.EnterEmail': 'Wprowadź <code>adres e-mail</code> dla każdego nowego użytkownika portalu {companyName}',
    'Tutorial.Text.RegistrationEmail': '<code>e-mail rejestracyjny</code> zostanie wysłany na ich skrzynkę odbiorczą',
    'Tutorial.Text.TeamsOrganize': 'Zespoły <code>organizują Twoich uczniów</code> i są zarządzane przez facilitatora',
    'Tutorial.Text.UniqueName': 'Nadaj swojemu zespołowi <code>unikalną nazwę</code> i wprowadź lokalizację',
    'Tutorial.Text.SessionDelivery': 'Później zespół zostanie <code>dodany do scenariusza</code> w celu <code>dostarczenia sesji</code>',
    'Tutorial.Tooltip.Description': 'Opis, wynik i strategie',
    'Tutorial.Text.BeginScheduling': 'Zespół musi zostać <code>dodany do scenariusza</code>, aby rozpocząć planowanie',
    'Tutorial.Text.MonitorSessions': 'Monitoruj <code>nadchodzące sesje</code> i <code>dostępność</code> każdego projektu',
    'Tutorial.Text.LearnersWithoutSessions': 'Zobacz uczniów, którzy <code>jeszcze nie zaplanowali</code> sesji',
    'Tutorial.Tooltip.LearnersSchedule': 'Uczniowie mogą również planować samodzielnie',
    'Tutorial.Text.AddedToLearners': 'Zostaniesz dodany do <code>1 lub więcej</code> zespołów uczniów',
    'Tutorial.Tooltip.TeamAdmin': 'Twój administrator zespołu i osoba kontaktowa',
    'Tutorial.Text.TeamAddedBeforeProject': 'Twój zespół zostanie <code>dodany do scenariusza</code> przed rozpoczęciem projektu',
    'Tutorial.Text.AddRemoveLearners': '<code>Dodaj lub usuń</code> uczniów ze swoich <tooltip>zespołów</tooltip>',
    'Tutorial.Text.AddedByCompany': 'Zostaniesz dodany do scenariusza/projektu przez <tooltip>Account Ownera</tooltip> Twojej firmy',
    'Tutorial.Text.ViewYourHours': 'Zobacz swoje godziny pracy, scenariusze lub skontaktuj się ze swoim <tooltip><code>Kierownikiem Projektu</code></tooltip>',
    'Tutorial.Text.ScenariosDetail': 'Scenariusze zawierają <tooltip >szczegóły</tooltip >, które wpływają na <code> doświadczenia {companyName}</code>',
    'Tutorial.Text.SelectTimeSlot': 'Wybierz otwarty przedział czasowy, aby <tooltip><code>zapisać ucznia</code></tooltip> na sesję',
    'Tutorial.Text.ChooseRole': 'Wybierz rolę (<tooltip>Account Owner</tooltip> <secondTooltip>Facilitator</secondTooltip> lub <thirdTooltip>Uczeń</thirdTooltip>) dla każdego nowego użytkownika',
    'Tutorial.Tooltip.AdminManages': 'Administrator zarządzający użytkownikami i projektami',
    'Tutorial.Tooltip.ManagesScheduling': 'Zarządza planowaniem i dostarczaniem sesji',
    'Tutorial.Tooltip.SchedulesSessions': 'Planuje i uczestniczy w sesjach',
    'Tutorial.Text.ScenariosContain': 'Scenariusze zawierają <tooltip >szczegóły</tooltip >, które wpływają na <code> doświadczenia {companyName}</code>',
    'Tutorial.Text.SelectOpenTimeSlot': 'Wybierz otwarty przedział czasowy, aby <tooltip><code>zapisać ucznia</code></tooltip>',
    'Tutorial.Text.AdminOfTeam': '<tooltip>Facilitator</tooltip> jest administratorem i osobą kontaktową dla Twojego zespołu',
    'Tutorial.Text.ProjectsContain': 'Projekty zawierają <code>scenariusze</code> z <tooltip >szczegółami</tooltip>, które wpływają na <code>naukę</code>',
    'Tutorial.Text.ViewAvailability': 'Zobacz dostępność i <code>zaplanuj sesje</code>',
    'Tutorial.Text.TrackSessions': 'Śledź <code>nadchodzące sesje</code> dla każdego projektu. W razie potrzeby anuluj/przełóż',
    'Tutorial.Text.ReviewPerformance': 'Sprawdź swoją <code>wydajność</code> i <code>ukończenie strategii</code> po sesji',
    'Tutorial.Header.Complete': 'Zakończone',
    'Tutorial.Text.RevisitThisGuide': 'Możesz wrócić do tego przewodnika i innych pomocnych informacji {lineBreak} <code>w każdej chwili</code> z <code>Bazy wiedzy. </kod>',
    'Tutorial.Button.TakeMeToThePortal': 'Zabierz mnie do Portalu',
    'Tutorial.Header.DownloadInstallMursionSimulator': 'Pobierz i zainstaluj {lineBreak} Mursion Simulator',
    'Tutorial.Text.PlanningToUseMursionOnComputer': 'Jeśli planujesz używać tego komputera do uruchamiania symulacji Mursion, zdecydowanie zalecamy zainstalowanie niezbędnego software teraz, klikając przycisk „Zainstaluj Mursion” poniżej.',
    'Tutorial.Text.InstallSoftwareFromPortal': 'Możesz również zainstalować software później korzystając bezpośrednio z portalu. Należy pamiętać, że instalacja immersyjnego symulatora Mursion może zająć kilka minut. Zainstaluj software na co najmniej 30 minut przed rozpoczęciem symulacji, aby zapewnić wystarczającą ilość czasu, pozwalającą w pełni cieszyć się doświadczeniem z Mursion.',
    'Tutorial.Text.ProvideInstructionsForInstallation': 'Będziemy od czasu do czasu dostarczać instrukcje dotyczące instalacji i innych przepływów pracy za pośrednictwem wiadomości e-mail i tutoriali w portalu i symulatorze Mursion. Mamy nadzieję, że symulacje spodobają Ci się tak samo, jak nam ich tworzenie!',
    'Tutorial.Text.TheMursionTeam': 'Zespół Mursion.',
    'Tutorial.Button.InstallLater': 'Zainstaluj później',
    'Tutorial.Button.InstallMursion': 'Zainstaluj Mursion',
    'Tutorial.Header.LetsGetStarted': 'Zaczynajmy',
    'Tutorial.Text.ShortIntro': 'Po pierwsze, oto <code>krótkie wprowadzenie</code> przedstawiające niektóre z {lineBreak} typowych funkcji <code>Twojej roli użytkownika</code>:',
    'Tutorial.Button.Proceed': 'Kontynuuj',
    'Tutorial.Label.Step': 'KROK:',
    'Tutorial.Label.NoSound': 'Ten film nie ma dźwięku.',
    'Tutorial.Button.SkipTutorial': 'Pomiń tutorial',
    'Tutorial.Button.CloseTutorial': 'Zamknij tutorial',
    'MursionPortal.Text.On': 'Włączony',
    'MursionPortal.Text.Off': 'Wyłączony',
    'MursionPortal.Text.MursionIcon': 'mursion-ikona',
    'MursionPortal.AriaLabel.CreateNew': 'utwórz nowy',
    'MursionPortal.Legend.VisibilityHidden.Buttons': 'Przyciski scenariusza Utwórz nowy, Powtórz klienta, Powtórz bank',
    'MursionPortal.Label.VisibilityHidden.EnterEmailId': 'Wprowadź id e-maila',
    'MursionPortal.Label.VisibilityHidden.SelectRegistrationStatus': 'Wybierz status rejestracji',
    'MursionPortal.Label.VisibilityHidden.ClientLogoUpload': 'przesyłanie logo klienta',
    'MursionPortal.Header.VisibilityHidden.CompanyUsers': 'Użytkownicy firmy',
    'MursionPortal.Legend.VisibilityHidden.StationType': 'Rodzaj stanowiska',
    'MursionPortal.Legend.VisibilityHidden.Hours': 'Godziny',
    'MursionPortal.Caption.VisibilityHidden.CompanyUsersList': 'lista użytkowników firmy',
    'MursionPortal.AriaLabel.Search': 'szukaj',
    'MursionPortal.AriaLabel.From': 'Od',
    'MursionPortal.AriaLabel.To': 'Do',
    'MursionPortal.AriaLabel.InboxMessagePanel': 'panel wiadomości skrzynki odbiorczej',
    'MursionPortal.AriaLabel.Breadcrumb': 'breadcrumb',
    'MursionPortal.Message.NoItemsHaveBeenFound': 'Nie znaleziono żadnych elementów.',
    'MursionPortal.AriaLabel.Add': 'dodaj',
    'MursionPortal.AriaLabel.SortByDescendingOrder': 'sortuj malejąco',
    'MursionPortal.AriaLabel.SortByAscendingOrder': 'sortuj rosnąco',
    'MursionPortal.AriaLabel.AddButton': 'dodaj przycisk',
    'MursionPortal.AriaLabel.Timezone': 'strefa czasowa',
    'MursionPortal.AriaLabel.Combobox': 'combobox',
    'MursionPortal.AriaLabel.PreviousMonth': 'poprzedni miesiac',
    'MursionPortal.AriaLabel.NextMonth': 'następny miesiąc',
    'MursionPortal.AriaLabel.SearchInput': 'pole wyszukiwarki',
    'MursionPortal.AriaLabel.Informational': 'Informacyjne',
    'MursionPortal.Text.RedirectToCalendarPage': 'przekierowanie na stronę kalendarza',
    'MursionPortal.AriaLabel.StartDate': 'data rozpoczęcia {startFormattedDate}',
    'MursionPortal.AriaLabel.EndDate': 'data zakończenia {endFormattedDate}',
    'MursionPortal.AriaLabel.CreateProject': 'utwórz projekt',
    'MursionPortal.AriaLabel.ContentInfo': 'informacje o zawartości',
    'MursionPortal.AriaLabel.DraftSuperScript': 'Wersja robocza indeksu górnego {statusConfiguration}',
    'MursionPortal.AriaLabel.FinalizedSuperScript': 'Sfinalizowano indeks górny {statusConfiguration}',
    'MursionPortal.AriaLabel.StartedSuperScript': 'Uruchomiono indeks górny {statusConfiguration}',
    'MursionPortal.AriaLabel.FinishedSuperScript': 'Zakończono indeks górny {statusConfiguration}',
    'MursionPortal.AriaLabel.ArchivedSuperScript': 'Zarchiwizowany indeks górny {statusConfiguration}',
    'MursionPortal.AriaLabel.EnterScenarioName': 'wprowadź nazwę scenariusza',
    'MursionPortal.AriaLabel.Capitalized.Search': 'Szukaj',
    'MursionPortal.AriaLabel.Close': 'Zamknij',
    'MursionPortal.AriaLabel.CreateTeam': 'utwórz zespół',
    'MursionPortal.AriaLabel.CreateClient': 'utwórz klienta',
    'MursionPortal.AriaLabel.CreateUser': 'utwórz użytkownika',
    'MursionPortal.AriaLabel.ZoomId': 'Zoom ID',
    'MursionPortal.AriaLabel.PrimaryContent': 'Zawartość podstawowa',
    'MursionPortal.AriaLabel.RegistrationStatus': 'status rejestracji',
    'MursionPortal.AriaLabel.EnterTagName': 'wpisz nazwę tagu',
    'MursionPortal.AriaLabel.EnterUserName': 'wpisz nazwę użytkownika',
    'MursionPortal.AriaLabel.SearchProjectManagers': 'wyszukaj kierowników projektów',
    'MursionPortal.AriaLabel.SearchSSO': 'wyszukaj SSO',
    'MursionPortal.AriaLabel.SearchAccountOwner': 'wyszukaj account ownera',
    'MursionPortal.WarningMessage.AllowedCharacterLimit': 'Przekroczono dozwolony limit znaków ({maximumLength} znaków).',
    'MursionPortal.WarningMessage.InvalidCharactersDetected': 'Wykryto nieprawidłowe znaki',
    'MursionPortal.Days.Sunday': 'Niedziela',
    'MursionPortal.Days.Monday': 'Poniedziałek',
    'MursionPortal.Days.Tuesday': 'Wtorek',
    'MursionPortal.Days.Wednesday': 'Środa',
    'MursionPortal.Days.Thursday': 'Czwartek',
    'MursionPortal.Days.Friday': 'Piątek',
    'MursionPortal.Days.Saturday': 'Sobota',
    'MursionPortal.Button.AddNew': 'Dodaj nowe',
    'MursionPortal.Title.EditNotes': 'Edytuj uwagi',
    'MursionPortal.IconButton.Remove': 'usuń',
    'MursionPortal.Filter.ProviderAll': 'Dostawca',
    'MursionPortal.Placeholder.SearchProviders': 'Wyszukaj dostawców...',
    'MursionPortal.Placeholder.ProvidersValue': 'Wybierz dostawcę',
    'MursionPortal.AriaLabel.InboxButton': 'przycisk skrzynki odbiorczej masz {messageCount} nowych wiadomości',
    'MursionPortal.AriaLabel.Event': 'wydarzenie',
    'MursionPortal.Title.SelectedDate': 'Wybrana data',
    'MursionPortal.Title.CurrentDate': 'Bieżąca data',
    'MursionPortal.Title.OffRangeDate': 'data poza zakresem',
    'MursionPortal.Label.ClientLevel': 'Poziom klienta',
    'MursionPortal.Label.TeamLevel': 'Poziom zespołu',
    'MursionPortal.AltText.UserImage': 'obraz-użytkownika',
    'MursionPortal.AltText.KbMenu': 'kb-menu',
    'MursionPortal.AltText.AvatarImage': 'obraz-awatara',
    'MursionPortal.AltText.EnvironmentImage': 'obraz-środowiska',
    'MursionPortal.AriaLabel.Cancel': 'anuluj',
    'MursionPortal.AriaLabel.Edit': 'edytuj',
    'MursionPortal.AriaLabel.EditAccountOwner': 'edytuj account ownera',
    'MursionPortal.AriaLabel.RemoveAccountOwner': 'usuń account ownera',
    'MursionPortal.AriaLabel.Remove': 'usuń',
    'MursionPortal.AriaLabel.SearchFilter': 'filtr wyszukiwania',
    'MursionPortal.AriaLabel.TierCombobox': 'pole combobox',
    'MursionPortal.AriaLabel.SortByAscending': 'sortuj rosnąco',
    'MursionPortal.AriaLabel.SortByDescending': 'sortuj malejąco',
    'MursionPortal.Message.VisibilityHidden.SelectingDeliveryMode': 'wybór metody dostawy umożliwi ustawienie długości sesji i ustawienie rozmiaru sesji',
    'MursionPortal.AltText.DownloadComputerIcon': 'Ikona pobierania Mursion na komputer',
    'MursionPortal.AltText.DownloadIcon': 'Ikona pobierania Mursion',
    'MursionPortal.AltText.MursionLogo': 'Logo Mursion',
    'MursionPortal.AriaLabel.MursionSupport': 'pomoc techniczna mursion',
    'MursionPortal.AltText.MursionDashboard': 'Mursion-Pulpit nawigacyjny',
    'MursionPortal.AltText.MursionPortal': 'Portal Mursion',
    'MursionPortal.AltText.BackgroundMaleUser': 'Użytkownik Mursion w tle płci męskiej',
    'MursionPortal.AltText.BackgroundGroupUser': 'Grupowy użytkownik Mursion w tle',
    'MursionPortal.AltText.BackgroundFemaleUser': 'Użytkownik Mursion w tle płci żeńskiej',
    'MursionPortal.AriaLabel.ToggleMenu': 'menu przełączania',
    'MursionPortal.ClientListItem.RedirectToUserDetails': 'Przekieruj do szczegółów {name}',
    'MursionPortal.Caption.ScenarioBankUsersList': 'lista użytkowników banku scenariuszy',
    'MursionPortal.Caption.SsoConfigurationList': 'lista konfiguracji sso',
    'MursionPortal.AriaLabel.DateRangeCalendarButton': 'naciśnij enter, aby wejść w interakcję z kalendarzem i wybrać datę rozpoczęcia i zakończenia',
    'MursionPortal.AriaLabel.PreviousWeek': 'poprzedni tydzień',
    'MursionPortal.AriaLabel.PreviousDay': 'poprzedni dzień',
    'MursionPortal.AriaLabel.NextWeek': 'następny tydzień',
    'MursionPortal.AriaLabel.NextDay': 'poprzedni dzień',
    'Dashboard.LeftPane.CategoryHeader.SessionsDashboard': 'Pulpit nawigacyjny sesji',
    'MursionPortal.Title.SessionsPage': 'Strona sesji',
    'Clients.heading.Clients': 'Klienci',
    'MursionPortal.AriaLabel.Client': 'klient',
    'MursionPortal.AriaLabel.SimSpecialist': 'SimSpecialist',
    'MursionPortal.Project.TimeZoneStartDate': 'Data rozpoczęcia strefy czasowej',
    'MursionPortal.Project.TimeZoneEndDate': 'Data zakończenia strefy czasowej',
    'MursionPortal.AriaLabel.SelectTimezoneCombobox': 'wybierz strefę czasową combo box',
    'MursionPortal.ErrorMessage.CharacterLimitExceeded': 'Przekroczono dozwolony limit znaków ({maximumLength} znaków).',
    'MursionPortal.ErrorMessage.InvalidCharactersDetected': 'Wykryto nieprawidłowe znaki',
    'MursionPortal.ErrorMessage.EnterValidPhoneNumber': 'Musisz podać prawidłowy numer telefonu.',
    'MursionPortal.Message.InvitationLinkInvalid': 'Twój link z zaproszeniem jest nieprawidłowy lub mógł wygasnąć. {lineBreak} Jeśli jeszcze nie ukończyłeś rejestracji {lineBreak}, poproś swojego kierownika o ponowne zaproszenie.',
    'MursionPortal.Header.SetYourPassword': 'Ustaw swoje hasło',
    'MursionPortal.Message.PasswordMessage': 'Twoje hasło musi zawierać następujące informacje:',
    'MursionPortal.Label.NewPassword': 'Nowe hasło',
    'MursionPortal.Label.NewChangePassword': 'Wpisz swoje nowe hasło',
    'MursionPortal.Label.OldPassword': 'Wpisz swoje obecne hasło',
    'MursionPortal.Label.RepeatChangePassword': 'Wpisz nowe hasło ponownie',
    'MursionPortal.Label.RepeatPassword': 'Powtórz hasło',
    'MursionPortal.Button.SetPassword': 'Ustaw hasło',
    'MursionPortal.Header.ErrorMessage.SomethingWentWrong': 'Coś poszło {lineBreak} nie tak, {lineBreak} skontaktuj się z pomocą techniczną',
    'MursionPortal.Header.ContactSupport': 'SKONTAKTUJ SIĘ Z POMOCĄ TECHNICZNĄ',
    'MursionPortal.Label.Phone': 'Telefon:',
    'MursionPortal.Label.Email': 'E-mail:',
    'MursionPortal.Label.Hours': 'Godziny:',
    'MursionPortal.Message.EnterPasswordToConfirm': 'Wprowadź hasło, aby potwierdzić zmianę',
    'MursionPortal.Label.Password': 'Hasło',
    'MursionPortal.Message.ClickToOpenApplicationDefault': 'Kliknij poniżej, aby wziąć udział w symulacji',
    'MursionPortal.Message.ClickToOpenApplicationDesktopWeb': 'Kliknij poniższy przycisk, aby uzyskać dostęp do symulacji za pośrednictwem aplikacji komputerowej Mursion:',
    'MursionPortal.Message.OpenWebApplication': 'Możesz również uzyskać dostęp do swojej symulacji w Mursion Magic, naszej aplikacji web:',
    'MursionPortal.Message.BrowserDialog': 'Jeśli nie widzisz okna dialogowego przeglądarki, kliknij <code>Otwórz Mursion</code> poniżej {lineBreak} lub użyj skrótu Mursion na pulpicie, aby otworzyć aplikację Mursion.',
    'MursionPortal.Button.OpenMursion': 'Otwórz komputerową App ML3',
    'MursionPortal.Button.OpenMursionWeb': 'Uruchom Mursion Magic',
    'MursionPortal.Message.Or': 'LUB',
    'MursionPortal.Message.FollowInstructions': 'Kliknij {appName} i postępuj zgodnie z instrukcjami wyświetlanymi na ekranie, aby zainstalować aplikację',
    'MursionPortal.Text.PositiveImpact': 'Jeśli uznasz, że wywierasz pozytywny wpływ, kliknij przycisk <code>{icon}</code>.',
    'MursionPortal.Text.NegativeImpact': 'Jeśli uznasz, że wywierasz negatywny wpływ, kliknij przycisk <code>{icon}</code>.',
    'MursionPortal.Text.NeutralImpact': 'Jeśli uważasz, że Twój wpływ nie był ani pozytywny, ani negatywny, kliknij przycisk <code>{icon}</code>.',
    'MursionPortal.Title.Next': 'Dalej',
    'MursionPortal.Title.Start': 'Start',
    'MursionPortal.Text.UpdateYourRating': 'Podczas oglądania filmu możesz w dowolnym momencie zaktualizować swoją ocenę, jeśli uznasz, że masz inny wpływ na awatara.',
    'MursionPortal.Text.SeeCurrentRating': 'Wykres pod filmem zostanie zaktualizowany, abyś mógł zobaczyć swoją obecną ocenę i zmienić ją, jeśli uważasz, że zmienił się Twój wpływ.',
    'MursionPortal.Text.ClickStartToBegin': 'Kliknij „Start”, aby rozpocząć. Po ocenieniu całej symulacji możesz kliknąć przycisk „Zakończ”. Należy pamiętać, że funkcja wyszukiwania wideo może być wyłączona w niektórych częściach tego filmu, aby zachować integralność danych.',
    'MursionPortal.Header.HowWouldYouProceed': 'Jak chcesz kontynuować?',
    'MursionPoral.Button.SwitchUser': 'Zmień użytkownika',
    'MursionPortal.Button.ContinueAs': 'Kontynuuj jako <code>{userName}</code>',
    'MursionPortal.Text.ToContinue': 'Aby kontynuować jako <code>{loginData}</code>, kliknij <code>Przełącz użytkownika</code>.',
    'MursionPortal.Message.YouAreSignedIn': 'Jesteś obecnie zalogowany jako <code>{userName}</code> ({userProfile}).',
    'MursionPortal.Title.ContinueAs': 'Kontynuuj jako {userName}',
    'MursionPortal.Message.OpenLink': 'Otwórz link',
    'MursionPortal.Message.Allow': 'Zezwalaj',
    'MursionPortal.Message.Open': 'Otwórz',
    'MursionPortal.Message.Yes': 'Tak',
    'MursionPortal.Message.OpenMursionApp': 'Otwórz MursionApp',
    'MursionPortal.PasswordRequirementMessage.MinimumCharacters': 'co najmniej {PASSWORD_MIN_LENGTH} znaków',
    'MursionPortal.Message.PortalHeading': 'Projektuj, planuj i zarządzaj swoimi',
    'MursionPortal.Message.PortalHeadingText': 'Symulacje szkolenia EQ',
    'MursionPortal.PasswordRequirementMessage.UppercaseLetters': 'wielkie + małe litery',
    'MursionPortal.PasswordRequirementMessage.Numbers': 'dowolna kombinacja liczb',
    'MursionPortal.PasswordRequirementMessage.SpecialCharaters': 'przynajmniej jeden znak specjalny',
    'MursionPortal.ErrorMessage.InvalidEmailFormat': 'Niewłaściwy format wiadomości e-mail.',
    'MursionPortal.ErrorMessage.EmailCanNotBeEmpty': 'E-mail nie może być pusty.',
    'MursionPortal.ErrorMessage.EnterValidEmailAddress': 'Proszę wpisać aktualny adres e-mail.',
    'MursionPortal.ErrorMessage.PasswordCannotBeEmpty': 'Hasło nie może być puste',
    'MursionPortal.ErrorMessage.UrlCannotBeEmpty': 'Adres URL nie może być pusty',
    'MursionPortal.ErrorMessage.UrlIsNotValid': 'Adres URL jest nieprawidłowy',
    'MursionPortal.ErrorMessage.NoMeetingId': 'Nie zawiera id spotkania',
    'MursionPortal.ValidationErrorMessage.FileRequestFailed': 'Żądanie pliku nie powiodło się',
    'MursionPortal.ValidationErrorMessage.FileExceedsLimit': 'Plik przekracza limit 5 MB',
    'MursionPortal.ValidationErrorMessage.EnterMessage': 'Wprowadź wiadomość przed wysłaniem',
    'MursionPortal.ValidationErrorMessage.MessageTooLong': 'Wiadomość jest za długa',
    'MursionPortal.ValidationErrorMessage.InvalidUrl': 'Adres URL pliku jest nieprawidłowy',
    'MursionPortal.Button.SubmitFeedback': 'Prześlij opinię',
    'MursionPortal.Header.Feedback': 'Opinia',
    'MursionPortal.Text.AdditionalComments': 'Dodatkowe uwagi lub sugestie',
    'MursionPortal.Header.ThanksForFeedback': 'Dziekuję za odpowiedź!',
    'MursionPortal.Button.Close': 'Zamknij',
    'MursionPortal.Title.ShowFeedback': 'pokaż opinie',
    'MursionPortal.Title.HideFeedback': 'ukryj opinie',
    'MursionPortal.ErrorMessage.FileTypeNotSupported': 'Plik typu {type} nie jest obsługiwany',
    'MursionPortal.ErrorMessage.ScenarioDuration': 'Czas trwania scenariusza musi mieścić się w przedziale od {durationMinimum} do {durationMaximum}.',
    'MursionPortal.ErrorMessage.ScenarioScheduledSessions': 'Dla tego scenariusza zaplanowano {sessionsCount} sesji. Anuluj te sesje przed zarchiwizowaniem tego scenariusza.',
    'MursionPortal.ErrorMessage.ScenarioScheduledSession': 'Dla tego scenariusza zaplanowano sesję {sessionsCount}. Anuluj tę sesję przed zarchiwizowaniem tego scenariusza.',
    'MursionPortal.ErrorMessage.ClientScheduledSessions': 'Dla tego klienta zaplanowano {sessionsCount} sesji. Anuluj te sesje przed zarchiwizowaniem klienta.',
    'MursionPortal.ErrorMessage.ClientScheduledSession': 'Dla tego klienta zaplanowano {sessionsCount} sesję. Anuluj tę sesję przed zarchiwizowaniem klienta.',
    'MursionPortal.EulaAgreement.EndUserAgreement': 'Niniejsza Umowa użytkownika końcowego („Umowa”) jest umową prawną między użytkownikiem, a firmą Mursion, Inc. („Mursion”) i podlega warunkom Umowy dotyczącej Mursion Software jako usługi zawartej między firmą Mursion a podmiotem klienta Mursion, za pośrednictwem którego korzystasz z Mursion Software. Dla jasności, potwierdzasz i zgadzasz się, że jako warunek korzystania i dostępu do Mursion Software („Mursion Software”), zarówno Ty, jak i podmiot, który zapewnia Ci dostęp do Mursion Software, podlegacie i jesteście związani warunkami niniejszej Umowy.',
    'MursionPortal.EulaAgreement.TermsAndConditions': 'Z zastrzeżeniem warunków niniejszej Umowy i umowy dotyczącej Mursion Software jako usługi, Mursion niniejszym zezwala użytkownikowi na ograniczone, niewyłączne, nieprzenoszalne i niepodlegające sublicencjonowaniu prawo dostępu do Mursion Software i korzystania z niego.',
    'MursionPortal.EulaAgreement.AgreeAndAcknowledge': 'Zgadzasz się i potwierdzasz, że nie masz prawa i nie będziesz kopiować, modyfikować, odtwarzać kodu źródłowego, dekompilować, dezasemblować ani tworzyć dzieł pochodnych opartych, w całości lub w części, na Mursion Software lub powiązanych materiałach. Zgadzasz się nie pokonywać, wyłączać ani obchodzić żadnych mechanizmów ochronnych związanych z Mursion Software. Po dowiedzeniu się o jakimkolwiek nieupoważnionym posiadaniu, używaniu lub dostępie do Mursion Software, niezwłocznie powiadomisz Mursion, a ponadto zgadzasz się na niezwłoczne dostarczenie szczegółów każdego takiego zdarzenia, aby rozsądnie pomóc w zapobieganiu ich ponownemu wystąpieniu (w możliwym zakresie) oraz do pełnej współpracy we wszelkich postępowaniach podejmowanych w celu ochrony praw Mursion.',
    'MursionPortal.EulaAgreement.ConstruedAsGranting': 'Poza tym, co wyraźnie określono w niniejszym dokumencie, żadne postanowienie niniejszej Umowy nie będzie interpretowane jako przyznanie, w sposób dorozumiany lub w inny sposób, jakichkolwiek dodatkowych praw do własności intelektualnej firmy Mursion bez uprzedniej pisemnej zgody firmy Mursion.',
    'MursionPortal.EulaAgreement.NonDisclosure': 'Zgadzasz się nie pokazywać ani nie ujawniać Mursion Software ani żadnej jego części nikomu w jakimkolwiek celu innym niż wyraźnie dozwolony w niniejszym dokumencie.',
    'MursionPortal.EulaAgreement.AgreeAndRelease': 'Zgadzasz się i niniejszym zwalniasz i w inny sposób chronisz firmę Mursion, jej pracowników, funkcjonariuszy, agentów, podmioty stowarzyszone, funkcjonariuszy i dyrektorów przed wszelkimi znanymi i nieznanymi roszczeniami, kiedykolwiek powstałymi, wynikającymi z, lub w inny sposób odnoszącymi się do korzystania z Mursion Software.',
    'MursionPortal.EulaAgreement.UnderstandAndAcknowledge': 'Rozumiesz i potwierdzasz, że Mursion może gromadzić, utrzymywać, wykorzystywać, przechowywać i analizować informacje i dane zebrane podczas korzystania z Mursion Software, z zastrzeżeniem warunków polityki prywatności Mursion, które można znaleźć tutaj: <code>https:// www.mursion.com/privacynotice/</code>.',
    'MursionPortal.EulaAgreement.AccessRights': 'Prawa do użytkowania i dostępu przyznane użytkownikowi przez firmę Mursion wygasają natychmiast po wygaśnięciu lub rozwiązaniu umowy dotyczącej Mursion Software jako usługi, na mocy której zapewniono użytkownikowi dostęp do Mursion Software, lub natychmiast po naruszeniu przez użytkownika któregokolwiek z postanowień niniejszej umowy.',
    'MursionPortal.EulaAgreement.AgreementGoverned': 'Niniejsza Umowa podlega prawu stanu Delaware i jest interpretowana zgodnie z nim, bez względu na reguły kolizyjne. Wszelkie spory wynikające lub związane z korzystaniem z Mursion Software lub niniejszej umowy będą rozstrzygane przez sąd stanowy lub federalny o właściwej jurysdykcji w stanie Delaware, a użytkownik niniejszym wyraża zgodę na osobistą jurysdykcję w takich sądach.',
    'MursionPortal.EulaAgreement.ProvisionsDeclaredInvalid': 'Jeśli którekolwiek z postanowień niniejszej Umowy zostanie uznane za nieważne, takie postanowienia zostaną oddzielone od niniejszej Umowy, a pozostałe postanowienia niniejszej Umowy pozostaną w pełnej mocy.',
    'MursionPortal.EulaAgreement.AgreementConflict': 'W przypadku konfliktu warunków niniejszej Umowy z warunkami umowy dotyczącej Mursion Software jako usługi, zastosowanie mają warunki zawarte w niniejszym dokumencie.',
    'MursionPortal.EulaAgreement.AccessToMursionSofware': 'Korzystanie z Mursion Software lub dostęp do niego oznacza zgodę na niniejszą Umowę oraz określone w niej warunki.',
    'MursionPortal.Header.MursionUserAgreement': 'Umowa pomiędzy Mursion i Użytkownikiem',
    'MursionPortal.Button.Back': 'Wstecz',
    'MursionPortal.Button.Skip': 'Pomiń',
    'MursionPortal.Title.BackToSessions': 'Powrót do sesji',
    'MursionPortal.Title.Proceed': 'Kontynuuj',
    'MursionPortal.Message.SkipActivity': 'Jeśli zdecydujesz się pominąć, utracisz informacje o wydajności, które mogą być dostarczane przez nasz silnik analizy behawioralnej. Zalecamy, aby nie pomijać tej czynności.',
    'MursionPortal.HeaderTitle.ConfirmCancellation': 'Potwierdź anulowanie',
    'MursionPortal.Label.Delete': 'usuń',
    'MursionPortal.Label.Restore': 'przywróć',
    'MursionPortal.HeaderTitle.ConfirmRestore': 'Potwierdź przywrócenie',
    'MursionPortal.Label.Archive': 'archiwizuj',
    'MursionPortal.Button.CancelTraining': 'Anuluj szkolenie',
    'MursionPortal.Button.No': 'Nie',
    'MursionPortal.Button.CancelEvent': 'Anuluj wydarzenie',
    'MursionPortal.Label.Update': 'aktualizacja',
    'MursionPortal.HeaderTitle.ConfirmUpdate': 'Potwierdź aktualizację',
    'MursionPortal.Button.UpdateSession': 'Zaktualizuj sesję',
    'MursionPortal.Label.Swap': 'zamień',
    'MursionPortal.HeaderTitle.ConfirmAcceptedSwap': 'Potwierdź zaakceptowaną zamianę',
    'MursionPortal.Button.AcceptSwap': 'Zaakceptuj zamianę',
    'MursionPortal.Label.Reason': 'Powód',
    'MursionPortal.Placeholder.ReasonForSwapping': 'Proszę o podanie powodu zamiany.',
    'MursionPortal.Label.Request': 'prośba',
    'MursionPortal.HeaderTitle.ConfirmAcceptedRequest': 'Potwierdź zaakceptowaną prośbę',
    'MursionPortal.Button.AcceptRequest': 'Zaakceptuj prośbę',
    'MursionPortal.Placeholder.ReasonForAccepting': 'Proszę o podanie powodu akceptacji.',
    'MursionPortal.Button.ConfirmProjectChange': 'Potwierdź zmianę projektu',
    'MursionPortal.HeaderTitle.Confirm': 'Potwierdź',
    'MursionPortal.Button.ConfirmCreateSession': 'Potwierdź utworzenie sesji',
    'MursionPortal.Placeholder.ReasonForCreation': 'Proszę o podanie przyczyny utworzenia.',
    'MursionPortal.Button.ConfirmAssigningSimSpecialist': 'Potwierdź przypisanie Sim Specialist',
    'MursionPortal.Placeholder.ReasonForAssigning': 'Proszę o podanie przyczyny przypisania.',
    'MursionPortal.Button.ConfirmChangingSimSpecialist': 'Potwierdź zmianę Sim Specialist',
    'MursionPortal.Placeholder.ReasonForChanging': 'Proszę o podanie przyczyny zmiany.',
    'MursionPortal.HeaderTitle.ConfirmTimeZone': 'Potwierdź strefę czasową',
    'MursionPortal.Message.DontHaveMursionInstalled': 'Nie masz zainstalowanej ML3 App? Kliknij <code>Pobierz</code>, aby zainstalować.',
    'MursionPortal.ErrorMessage.CannotContainCharacter': 'Nazwa scenariusza nie powinna zawierać znaku „_”.',
    'MursionPortal.ErrorMessage.EnterDifferentName': '{capitalized} {fieldName} już istnieje. Wprowadź inną {objectName} {fieldName}.',
    'MursionPortal.ErrorMessage.EnterDifferentEmail': 'Adres e-mail użytkownika jest już zarejestrowany. Wprowadź inny adres e-mail.',
    'MursionPortal.ErrorMessage.NameExists': 'Użytkownik z tym samym {fieldName} już istnieje. Wprowadź inną {fieldName}.',
    'MursionPortal.ErrorMessage.UnableToRemove': 'Nie można usunąć {entity}. Ten {entity} jest obecnie używany w aktywnym {depEntity}.',
    'MursionPortal.ErrorMessage.OverlapsExistingTimeBlock': 'Wybrany blok czasowy nakłada się na istniejący blok czasowy.',
    'MursionPortal.ErrorMessage.AccessIsDenied': 'Odmowa dostępu.',
    'MursionPortal.ErrorMessage.SomethingMustBeBroken': 'Coś musi być uszkodzone. Spróbuj ponownie później.',
    'MursionPortal.ErrorMessage.Unauthorized': 'Nieautoryzowany',
    'MursionPortal.ErrorMessage.RefreshTokenNotFound': 'Nie znaleziono tokena odświeżania',
    'MursionPortal.ErrorMessage.CannotRefreshToken': 'Nie można odświeżyć tokena',
    'MursionPortal.ErrorMessage.UsersUnavailable': 'Niektórzy z Twoich użytkowników są w wybranym czasie niedostępni.',
    'MursionPortal.QADialog.NoParticipants': 'Brak uczestników',
    'MursionPortal.ErrorMessage.SomethingWrongWithResponse': 'Coś jest nie tak z odpowiedzią.',
    'MursionPortal.ErrorMessage.AuthorizationHeaderIsEmpty': 'Nagłówek autoryzacji jest pusty',
    'MursionPortal.ErrorMessage.CannotRefreshAccessToken': 'Nie można odświeżyć tokena dostępu',
    'MursionPortal.ErrorMessage.AuthorizationTokenIsEmpty': 'Token autoryzacji jest pusty',
    'MursionPortal.ErrorMessage.AuthenticationFailed': 'Uwierzytelnianie nie powiodło się.',
    'MursionPortal.ErrorMessage.UnsupportedResponseFormat': 'Nieobsługiwany format odpowiedzi.',
    'MursionPortal.ErrorMessage.AccountBlocked': 'Twoje konto zostało zablokowane do {blockedDate}. Spróbuj zalogować się później.',
    'MursionPortal.ErrorMessage.AttemptsRemaining': 'Pozostało {attempts} prób przed 24-godzinną blokadą. Jeśli potrzebujesz pomocy, zresetuj hasło.',
    'MursionPortal.ErrorMessage.ClientIdMustBeSpecified': 'Id klienta musi być określone',
    'MursionPortal.ErrorMessage.ProjectIdMustBeSpecified': 'Id projektu musi być określone',
    'MursionPortal.ErrorMessage.SessionIdMustBeSpecified': 'Id sesji musi być określone',
    'MursionPortal.ErrorMessage.SSOIdMustBeSpecified': 'SSO id musi być określone',
    'MursionPortal.ErrorMessage.LearnerIdMustBeSpecified': 'Id uczestnika musi być określone',
    'MursionPortal.ErrorMessage.UserIdMustBeSpecified': 'Id użytkownika musi być określone',
    'MursionPortal.ErrorMessage.TeamIdMustBeSpecified': 'Id zespołu musi być określone',
    'MursionPortal.ErrorMessage.AssetProjectIdMustBeSpecified': 'Należy określić id projektu zasobów',
    'MursionPortal.ErrorMessage.AssetSettingsIdMustBeSpecified': 'Należy określić id ustawień zasobów',
    'MursionPortal.ErrorMessage.ScenarioIdMustBeSpecified': 'Id scenariusza musi być określone',
    'MursionPortal.ErrorMessage.ScenarioTemplateIdMustBeSpecified': 'Należy określić id szablonu scenariusza',
    'MursionPortal.ErrorMessage.LicenseIdMustBeSpecified': 'pobierzListęUżytkowników: Należy podać id licencjobiorcy',
    'MursionPortal.ErrorMessage.LicenseCertificateIdMustBeSpecified': 'pobierzCertyfikatyLicencjobiorcy: Należy podać id licencjobiorcy',
    'MursionPortal.ErrorMessage.LicenseConfigIdMustBeSpecified': 'pobierzKonfigurację: Należy podać id licencjobiorcy',
    'MursionPortal.ErrorMessage.LicenseUpdateConfigIdMustBeSpecified': 'aktualizujKonfigurację: Należy określić id licencjobiorcy',
    'MursionPortal.ErrorMessage.LicenseTestConfigIdMustBeSpecified': 'testujKonfigurację: Należy określić id licencjobiorcy',
    'MursionPortal.ErrorMessage.LicenseFetchTiersIdMustBeSpecified': 'pobierzPoziomy: Należy podać id licencjobiorcy',
    'MursionPortal.ErrorMessage.LicenseUpdateTiersIdMustBeSpecified': 'aktualizujPoziomy: Należy określić id licencjobiorcy',
    'MursionPortal.ErrorMessage.GlobalUserIdMustBeSpecified': 'Należy określić id użytkownika globalnego',
    'MursionPortal.ErrorMessage.ScenarioTemplateListLicenseIdMustBeSpecified': 'obierzListęSzablonówScenariuszy: Należy podać id licencjobiorcy',
    'MursionPortal.ErrorMessage.ScenarioAttachmentListIdMustBeSpecified': 'pobierzLinkDoZałącznikaScenariusza: należy określić id scenariusza i id załącznika',
    'MursionPortal.ErrorMessage.FetchScenarioIdMustBeSpecified': 'pobierzScenariusz: należy podać id scenariusza',
    'MursionPortal.Support.PhoneNumber': '1-855-999-5818',
    'MursionPortal.Message.LearnerEmpty': 'Wybierz uczniów, którzy uczestniczyli w sesji',
    'Dashboard.ProjectsTable.StatusValue.Active': 'AKTYWNE',
    'Dashboard.ProjectsTable.StatusValue.Completed': 'UKOŃCZONE',
    'Dashboard.ProjectsTable.StatusValue.Archived': 'ZARCHIWIZOWANE',
    'MursionPortal.Button.Refresh': 'Odśwież',
    'MursionPortal.Prompt.CalendarUpdated': 'Kalendarz został zaktualizowany.',
    'MursionPortal.Prompt.ClickRefresh': 'Kliknij „Odśwież”, aby wyświetlić zmiany.',
    'MursionPortal.Label.SessionAttendance': 'Obecność na sesji',
    'MursionPortal.Label.SessionVideoUrl': 'Adres URL wideo z sesji',
    'MursionPortal.Label.LearnerAttendance': 'Frekwencja ucznia',
    'MursionPortal.Text.UserProfileUpdated': 'Profil użytkownika został zaktualizowany!',
    'MursionPortal.Text.ClientUpdated': 'Klient został zaktualizowany!',
    'MursionPortal.Text.ProjectUpdated': 'Projekt został zaktualizowany!',
    'MursionPortal.Text.ScenarioUpdated': 'Scenariusz został zaktualizowany!',
    'MursionPortal.Text.LicenseeConfigUpdated': 'Konfiguracja licencjobiorcy została zaktualizowana!',
    'MursionPortal.Prompt.TrainingScheduled': '<code>Szkolenie</code> zostało zaplanowane!',
    'MursionPortal.Prompt.SessionScheduled': '<code>Sesja</code> została anulowana!',
    'MursionPortal.SuccessDialog.Title': 'Dialog sukcesu',
    'MursionPortal.Dialog.CreateSSOConfiguartion.SuccessMessage': 'Pomyślnie utworzono konfigurację SSO klienta.',
    'MursionPortal.Modal.Header.SessionTimeout': 'Przekroczenie limitu czasu trwania sesji',
    'MursionPortal.Modal.Body.SessionTimeout': 'Your session will timeout in 1 minute, please continue to extend your session', // TODO
    'MursionPortal.Text.UserCreatedSuccessfully': 'Użytkownik został pomyślnie utworzony',
    'MursionPortal.Text.UserNotCreated': 'Użytkownik nie został utworzony',
    'MursionPortal.Text.AssignedTeams': 'Przypisane zespoły',
    'MursionPortal.Client.EmailSettings.Invitation': 'Zaproszenie',
    'MursionPortal.Client.EmailSettings.InvitationReminder': 'Przypomnienie o zaproszeniu',
    'MursionPortal.Client.EmailSettings.Confirmation': 'Potwierdzenie',
    'MursionPortal.Client.EmailSettings.Cancellation': 'Anulowanie',
    'MursionPortal.Client.EmailSettings.FirstReminder': 'Pierwsze przypomnienie',
    'MursionPortal.Client.EmailSettings.SecondReminder': 'Drugie przypomnienie',
    'MursionPortal.Client.EmailSettings.SessionRecording': 'Nagranie sesji jest gotowe dla Account Ownera/ Facilitatora',
    'MursionPortal.Client.EmailSettings.LearnerSession': 'Nagranie sesji ucznia jest gotowe',
    'MursionPortal.Client.EmailSettings.SimulationSessionVideoRejected': 'Odrzucono nagranie wideo z sesji symulacyjnej',
    'MursionPortal.Client.EmailSettings.SimulationSessionVideoDisallowed': 'Nagrywanie sesji symulacyjnej jest niedozwolone',
    'MursionPortal.Client.EmailSettings.SimulationSessionVideoRejectedUser': 'Nagranie wideo z sesji symulacyjnej zostało odrzucone przez niektórych użytkowników',
    'MursionPortal.Client.EmailSettings.RequestRecieved': 'Poproś o otrzymane potwierdzenie',
    'MursionPortal.Client.EmailSettings.RequestNotFullfilled': 'Prośba nie została spełniona',
    'MursionPortal.Client.EmailSettings.RequestDateTime': 'Wymagana zmiana daty/godziny',
    'MursionPortal.Client.EmailSettings.Title.SelectEmails': 'Wybierz e-maile, które chcesz wysłać do uczniów. Mursion zaleca pozostawienie włączonych wszystkich wiadomości e-mail.',
    'MursionPortal.Client.EmailSettings.Header.Registration': 'Rejestracja',
    'MursionPortal.Client.EmailSettings.Header.TrainingSession': 'Sesja szkoleniowa',
    'MursionPortal.Client.EmailSettings.Header.Videos': 'Wideo',
    'MursionPortal.Client.EmailSettings.Header.Requests': 'Prośby',
    'MursionPortal.AriaLabel.ToggleButton': 'Przycisk przełączania',
    'MursionPortal.Client.EmailSettings.Hint.RecordingEnabled': 'tylko jeśli nagrywanie jest włączone',
    'MursionPortal.Client.EmailSettings.Hint.RequestFeature': 'tylko jeśli funkcja żądania jest włączona',
    'MursionPortal.Status.Undefined': 'Nieokreślony',
    'MursionPortal.Status.Orphan': 'Osierocone',
    'MursionPortal.Status.Reserved': 'Zarezerwowane',
    'MursionPortal.Status.Waif': 'Porzucone',
    'MursionPortal.Status.Booked': 'Zabookowane',
    'MursionPortal.Status.Upcoming': 'Nadchodzące',
    'MursionPortal.Status.Pending': 'Oczekujące',
    'MursionPortal.Status.Running': 'Trwające',
    'MursionPortal.Completed': 'Ukończone',
    'MursionPortal.Status.Missed': 'Pominięte',
    'MursionPortal.Status.NeedsReview': 'Wymaga przeglądu',
    'MursionPortal.Status.Reviewed': 'Ocenione',
    'MursionPortal.Status.Cancelled': 'Anulowane',
    'MursionPortal.Status.LateCancelled': 'Anulowane z opóźnieniem',
    'MursionPortal.Status.EarlyCancelled': 'Anulowane z wyprzedzeniem',
    'MursionPortal.Status.LicenseCancelled': 'Anulowane',
    'MursionPortal.Status.Error': 'Błąd',
    'MursionPortal.Status.Capitalized.Upcoming': 'NADCHODZĄCE',
    'MursionPortal.Status.Capitalized.Undefined': 'NIEOKREŚLONY',
    'MursionPortal.Status.Capitalized.Orphan': 'OSIEROCONE',
    'MursionPortal.Status.Capitalized.Waif': 'PORZUCONE',
    'MursionPortal.Status.Capitalized.Reserved': 'ZAREZERWOWANE',
    'MursionPortal.Status.Capitalized.Booked': 'ZABOOKOWANE',
    'MursionPortal.Status.Capitalized.Pending': 'OCZEKUJĄCE',
    'MursionPortal.Status.Capitalized.Running': 'TRWAJĄCE',
    'MursionPortal.Status.Capitalized.Missed': 'POMINIĘTE',
    'MursionPortal.Status.Capitalized.NeedsReview': 'WYMAGA_PRZEGLĄDU',
    'MursionPortal.Status.Capitalized.Reviewed': 'OCENIONE',
    'MursionPortal.Status.Capitalized.Cancelled': 'ANULOWANE',
    'MursionPortal.Status.Capitalized.LicenseCancelled': 'ANULOWANE',
    'MursionPortal.Status.Capitalized.LateCancelled': 'PÓŹNO_ANULOWANE',
    'MursionPortal.Status.Capitalized.EarlyCancelled': 'WCZEŚNIE_ANULOWANE',
    'MursionPortal.Status.Capitalized.Error': 'BŁĄD',
    'MursionPortal.Status.Capitalized.SimError': 'ERROR',// TODO add translations
    'MursionPortal.Status.Capitalized.Offline': 'OFFLINE',
    'MursionPortal.Status.Abbreviation.PendingNoLearners': 'P',
    'MursionPortal.Status.Abbreviation.PendingNoParticipants': 'R',
    'MursionPortal.Status.Abbreviation.Swap': 'SEK.',
    'MursionPortal.Status.Abbreviation.Prebooked': 'PB',
    'MursionPortal.Status.Abbreviation.Booked': 'B',
    'MursionPortal.Status.Abbreviation.Active': 'A',
    'MursionPortal.Status.Abbreviation.Completed': 'C',
    'MursionPortal.Status.Abbreviation.Missed': 'MIES.',
    'MursionPortal.Status.Abbreviation.NeedsReview': 'NR',
    'MursionPortal.Status.Abbreviation.Error': 'E',
    'MursionPortal.Status.Abbreviation.LateCancelled': 'LC',
    'MursionPortal.Status.Abbreviation.EarlyCancelled': 'EC',
    'MursionPortal.Status.Abbreviation.LicenseCancelled': 'C',
    'MursionPortal.Status.Abbreviation.Cancelled': 'C',
    'MursionPortal.Status.Empty': '',
    'MursionPortal.Status.PendingNoSS': 'Oczekujące (bez SS)',
    'MursionPortal.Status.PendingNoL': 'Oczekujące bez L)',
    'MursionPortal.Status.PendingNoLearners': 'Oczekujące (brak uczestników)',
    'MursionPortal.Status.PendingNoLSS': 'Oczekujące (bez L/SS)',
    'MursionPortal.Text.Team': 'zespół',
    'MursionPortal.Text.Industry': 'branża',
    'MursionPortal.Text.Environment': 'środowisko',
    'MursionPortal.Text.Avatar': 'awatar',
    'MursionPortal.Label.SelfReviewTracker': 'TRACKER SAMOPOGLĄDOWY',
    'MursionPortal.ToggleButton.All': 'wszystko',
    'MursionPortal.Label.PerformanceTracker': 'TRACKER WYDAJNOŚCI',
    'MursionPortal.ToggleButton.TurnTaking': 'PILNOWANIE KOLEJNOŚCI',
    'MursionPortal.Tooltip.Collaborative': 'Jest to miara czasu, w którym zarówno ty, jak i awatar (awatary) zamienialiście się, nie przerywając sobie nawzajem podczas rozmowy.',
    'MursionPortal.Tooltip.Disruptive': 'Jest to miara czasu, w którym ty lub awatary przerywaliście sobie nawzajem podczas rozmowy.',
    'MursionPortal.Tooltip.Speaking': 'Jest to miara czasu, jaki spędziłeś rozmawiając z awatarem (awatarami) podczas rozmowy.',
    'MursionPortal.Tooltip.Listening': 'Jest to miara czasu spędzonego na słuchaniu awatara (awatarów) podczas rozmowy.',
    'MursionPortal.Tooltip.MiScore': 'Twoja efektywność społeczna podczas rozmowy.',
    'MursionPortal.Tooltip.Percentile': 'dołącz opis tego, co to oznacza gdzieś w UI',
    'MursionPortal.Label.MiScore': 'Wynik MI',
    'MursionPortal.Label.Percentile': 'percentyl',
    'MursionPortal.Label.NotEnoughData': 'Niewystarczająca ilość danych',
    'MursionPortal.Label.Listening': 'Słuchanie',
    'MursionPortal.Label.LearnerListening': 'Słuchanie przez ucznia',
    'MursionPortal.Label.Speaking': 'Mówienie',
    'MursionPortal.Label.Silence': 'Cisza',
    'MursionPortal.Label.Collaborative': 'Współpraca',
    'MursionPortal.Label.Disruptive': 'Zakłócenia',
    'MursionPortal.ToggleButton.LocalLeaderboard': 'Lokalna tablica wyników',
    'MursionPortal.ToggleButton.GlobalLeaderboard': 'Globalna tablica wyników',
    'MursionPortal.ToggleButton.DeepInsights': 'Głębokie spostrzeżenia',
    'MursionPortal.ErrorMessage.DataBeingProcessed': 'Dane analizy sesji są w tej chwili przetwarzane. Dane zostaną wyświetlone na stronie po zakończeniu przetwarzania.',
    'MursionPortal.ErrorMessage.UnsupportedFormat': 'Analiza sesji jest pusta lub ma nieobsługiwany format.',
    'MursionPortal.ErrorMessage.NoDataAvailable': 'Nie było wystarczającej ilości danych z sesji, aby przeprowadzić analizę sesji.',
    'MursionPortal.Message.NotEnoughDataToDisplay': 'Za mało danych z sesji.',
    'MursionPortal.Message.Notdata.SessionMatrix': 'Nie było wystarczającej ilości danych z sesji, aby przeprowadzić analizę sesji.',
    'MursionPortal.Message.NotEnoughDataToDisplay.ConversationalFlow': 'Nie było wystarczającej ilości danych z sesji, aby utworzyć wykres',
    'MursionPortal.Label.Positive': 'Pozytywny',
    'MursionPortal.Label.Negative': 'Negatywny',
    'MursionPortal.Label.Neutral': 'Neutralny',
    'MursionPortal.Header.Tracking': 'Śledzenie',
    'MursionPortal.Button.ReadMore': 'Czytaj więcej',
    'MursionPortal.Button.Hide': 'Ukryj',
    'MursionPortal.Title.Interchange': 'Wymiana zdań. Naturalna rozmowa i zwracanie się do siebie bez interwencji i wtrąceń.',
    'MursionPortal.Title.InterventionByYou': 'Twoja interwencja. Pomyślne przerwanie komuś, kto mówi, uniemożliwiając mu kontynuowanie wypowiedzi.',
    'MursionPortal.Title.InterventionByAvatars': 'Przerwanie przez awatary. Pomyślne przerwanie komuś, kto mówi, uniemożliwiając mu kontynuowanie.',
    'MursionPortal.Title.InterjectionByYou': 'Przerwanie wypowiedzi przez ucznia. Próba bezskutecznego przerwania komuś, kto mówi.',
    'MursionPortal.Title.InterjectionByAvatars': 'Wtrącenie się przez awatary. Próba bezskutecznego przerwania komuś, kto mówi.',
    'MursionPortal.Title.Pauses': 'Pauzy. Dłuższe niż 0,2 s i krótsze niż 0,75 s',
    'MursionPortal.Title.Silence': 'Przerwa w mówieniu dłuższa niż 10 sek',
    'MursionPortal.Label.VoiceSelf': 'Głos własny',
    'MursionPortal.Label.VoiceOthers': 'Głosy innych',
    'MursionPortal.Label.Avatars': 'Awatar',
    'MursionPortal.Label.You': 'Uczeń',
    'MursionPortal.ColumnHeader.User': 'Użytkownik',
    'MursionPortal.ColumnHeader.Percentile': 'Percentyl',
    'MursionPortal.ColumnHeader.ScenariosCompleted': 'Scenariusze ukończone',
    'MursionPortal.Message.LeaderboardsUpdated': 'Tabele wyników będą aktualizowane, gdy więcej osób ukończy swoje symulacje.',
    'MursionPortal.Message.CheckBack': 'Zaglądaj tu od czasu do czasu, aby zobaczyć, na czym stoisz!',
    'MursionPortal.Label.SimStart': 'Start Sim',
    'MursionPortal.Label.SimEnd': 'Koniec Sim',
    'MursionPortal.Tooltip.Summary.Speaking': 'Czas spędzony na rozmowie z awatarem (awatarami) podczas całej rozmowy',
    'MursionPortal.Tooltip.Summary.Listening': 'Czas spędzony na słuchaniu awatarów podczas całej rozmowy',
    'MursionPortal.Capitalized.NotAvailable': 'Nie dotyczy',
    'MursionPortal.ColumnHeader.Trait': 'Cecha',
    'MursionPortal.ColumnHeader.Me': 'Ja',
    'MursionPortal.ColumnHeader.OthersAvg': 'Inni (średnia)',
    'MursionPortal.TooltipText.Speaking': 'Mówisz więcej niż {percentage} osób.',
    'MursionPortal.TooltipText.Listening': 'Słuchasz więcej niż {percentage} osób.',
    'MursionPortal.TooltipText.Collaborative': 'Współpracujesz chętniej niż {percentage} osób.',
    'MursionPortal.TooltipText.Disruptive': 'Jesteś bardziej przeszkadzający niż {percentage} osób.',
    'MursionPortal.Label.FeedbackValue': 'Wartość opinii',
    'MursionPortal.Button.AnalyticsData': 'Dane analityczne',
    'MursionPortal.Button.RawAnalyticsData': 'Surowe dane analityczne',
    'MursionPortal.Header.SocialEffectiveness': 'Efektywność społeczna',
    'MursionPortal.Label.Current': 'Aktualne',
    'MursionPortal.Header.ArticulationRate': 'Współczynnik artykulacji',
    'MursionPortal.Label.Average': 'Średnia:',
    'MursionPortal.Header.Conversation': 'Rozmowa',
    'MursionPortal.Header.SpeakingListening': 'Mówienie / Słuchanie',
    'MursionPortal.Header.CumulativeTurnTaking': 'Skumulowane pilnowanie kolejności',
    'MursionPortal.Header.InstantaneousTurnTaking': 'Natychmiastowe pilnowanie kolejności',
    'MursionPortal.ColumnHeader.ScenarioTemplate': 'SZABLON_SCENARIUSZA',
    'MursionPortal.ColumnHeader.Provider': 'DOSTAWCA',
    'MursionPortal.Label.AbbreviatedMinute': 'mies.',
    'MursionPortal.Header.Mursion': '<code>M</code>ursion',
    'MursionPortal.Header.Index': '<code>I</code>ndeks',
    'MursionPortal.Header.Score': 'Wynik',
    'MursionPortal.visibilityHidden.Selected': 'wybrane',
    'MursionPortal.AriaLabel.CloseTechSupport': 'zamknij pomoc techniczną',
    'Mursion.Portal.SessionUserStatus.Connected': 'POŁĄCZONO',
    'Mursion.Portal.SessionUserStatus.Late': 'PÓŹNO',
    'Mursion.Portal.SessionUserStatus.Immersing': 'WPROWADZENIE',
    'Mursion.Portal.SessionUserStatus.Left': 'OPUSZCZONE',
    'Mursion.Portal.SessionUserStatus.Declined': 'ODRZUCONE',
    'MursionPortal.AriaLabel.DraftScenarioSuperScript': 'Indeks górny wersji roboczej scenariusza {statusConfiguration}',
    'MursionPortal.AriaLabel.FinalizedScenarioSuperScript': 'Indeks górny sfinalizowanego scenariusza {statusConfiguration}',
    'MursionPortal.AriaLabel.StartedScenarioSuperScript': 'Rozpoczęty indeks górny scenariusza {statusConfiguration}',
    'MursionPortal.AriaLabel.FinishedScenarioSuperScript': 'Ukończony indeks górny scenariusza {statusConfiguration}',
    'MursionPortal.AriaLabel.ArchivedScenarioSuperScript': 'Zarchiwizowany indeks górny scenariusza {statusConfiguration}',
    'Calendar.Button.ScheduleEventON': 'Zaplanuj wydarzenie na {thisDate}',
    'Calendar.Button.CantScheduleON': 'Nie można zaplanować wydarzenia na {thisDate}',
    'MursionPortal.AriaLabel.ChooseFileButton': 'przycisk wyboru pliku',
    'MursionPortal.Label.VisibilityHidden.FileUploaded': 'Plik przesłany',
    'MursionPortal.VisibilityHidden.EntityAdded': 'Dodano podmiot',
    'MursionPortal.VisibilityHidden.EntityRemoved': 'Podmiot usunięty',
    'MursionPortal.Legend.VisibilityHidden.StationTypeHomeOffice': 'Typ stacji (biuro, dom)',
    'MursionPortal.Legend.VisibilityHidden.HoursFullTimePartTime': 'Godziny (pełny etat, pół etatu)',
    'MursionPortal.AriaLabel.ShowTechSupport': 'pokaż pomoc techniczną',
    'MursionPortal.Monitoring.Card.UntilTheEnd': 'Pozostały czas',
    'MursionPortal.Monitoring.Card.OverTime': 'Nadgodziny',
    'MursionPortal.MyProfile.Hover.Profile': 'profil {userName}',
    'MursionPortal.AriaDescribedby.StartDate': 'Data rozpoczęcia: naciśnij klawisz znaku zapytania, aby uzyskać skróty klawiaturowe do zmiany dat.',
    'MursionPortal.AriaDescribedby.EndDate': 'Data zakończenia: naciśnij klawisz znaku zapytania, aby uzyskać skróty klawiaturowe do zmiany dat.',
    'MursionPortal.VisibilityHidden.Footer': 'Stopka',
    'MursionPortal.VisibilityHidden.Header': 'Nagłówek',
    'MursionPortal.AriaLabel.FullScreen': 'pełny ekran',
    'MursionPortal.AriaLabel.ClientNameEventsDisplayed': 'Wydarzenia klienta {clientName} wyświetlane w kalendarzu',
    'MursionPortal.AriaLabel.SideNavigationToggle': 'Boczny przełącznik nawigacji',
    'MursionPortal.AriaLabel.AddTags': 'Dodaj tagi',
    'MursionPortal.VisibilityHidden.Navigation': 'Nawigacja',
    'MursionPortal.AriaLabel.SortByDescendingOrderButton': 'przycisk sortowania według kolejności malejącej',
    'MursionPortal.AriaLabel.SortByAscendingOrderButton': 'przycisk sortowania według kolejności rosnącej',
    'MursionPortal.VisibilityHidden.ProjectsTabSelected': 'Wybrano kartę Projekty',
    'MursionPortal.VisibilityHidden.TeamsTabSelected': 'Wybrano kartę Zespoły',
    'MursionPortal.VisibilityHidden.SessionsTabSelected': 'Wybrano kartę Sesje',
    'MursionPortal.VisibilityHidden.UsersTabSelected': 'Wybrano kartę Użytkownicy',
    'MursionPortal.Title.DemandBasedSessionScheduling': 'Planowanie sesji na żądanie',
    'MursionPortal.CheckboxLabel.SchedulingByDemand': 'Planowanie według zapotrzebowania',
    'MursionPortal.CheckboxLabel.ProjectTimezone': 'Strefa czasowa projektu',
    'MursionPortal.CheckboxLabel.DemandTime': 'Czas na żądanie',
    'MursionPortal.Label.DemandTimeStart': 'Rozpoczęcie czasu na żądanie',
    'MursionPortal.Label.DemandTimeEnd': 'Zakończenie czasu na żądanie',
    'MursionPortal.Label.DemandWindow': 'Okno żądania',
    'MursionPortal.Tooltip.SchedulingWindow': 'Użytkownicy końcowi mogą ustawić tę wartość w dniach, godzinach, minutach, po których tylko użytkownik będzie mógł zaplanować sesję.',
    'MursionPortal.DemandBasedScheduling.SubmitPage.ThankYou': 'Dziękujemy!',
    'MursionPortal.DemandBasedScheduling.SubmitPage.GoToDashboard': 'Przejdź do Mojego pulpitu nawigacyjnego',
    'MursionPortal.DemandBasedScheduling.ScheduleASession': 'Zaplanuj symulację',
    'MursionPortal.DemandBasedScheduling.Details': 'DETALE',
    'MursionPortal.DemandBasedScheduling.Slot': 'PRZEDZIAŁ {count}',
    'MursionPortal.DemandBasedScheduling.PickDateAndTime': 'Wybierz datę + godzinę do ustawienia',
    'MursionPortal.DemandBasedScheduling.Button.Set': 'USTAW',
    'MursionPortal.DemandBasedScheduling.Button.Clear': 'WYCZYŚĆ',
    'MursionPortal.DemandBasedScheduling.Jumbotron.Text': 'Szczegóły scenariusza zostaną załadowane po dokonaniu wyboru',
    'MursionPortal.Error.PageNotFound': '404 Nie znaleziono strony',
    'MursionPortal.Text.PageNotFound': 'Strona nie znaleziona. Sprawdź, czy masz uprawnienia do przeglądania strony, jeśli link jest poprawny.',
    'MursionPortal.Text.Success': 'Powodzenia!',
    'MursionPortal.Text.YouAreNowSignedIn': 'Jesteś teraz zalogowany do software do symulacji Mursion.',
    'MursionPortal.Text.PleaseReturnToDesktop': 'Wróć do aplikacji komputerowej, klikając {linebreak} na ikonie \'M\' na pasku zadań komputera (Windows) lub w Docku (Mac).',
    'MursionPortal.Client.EmailSettings.Header.DemandBased': 'Na żądanie',
    'MursionPortal.Client.EmailSettings.TimeSlotsReceived': 'Otrzymane przedziały czasowe',
    'MursionPortal.Label.Past': 'Minione',
    'MursionPortal.Monitoring.SessionsSummary.Preparation': 'Przygotowanie',
    'MursionPortal.Monitoring.SessionsSummary.Normal': 'Normalne',
    'MursionPortal.Monitoring.SessionsSummary.Error': 'Błąd',
    'MursionPortal.Monitoring.SessionsSummary.Issue': 'Problem',
    'MursionPortal.Monitoring.SessionsSummary.NotLoggedIn': 'Niezalogowany',
    'MursionPortal.Monitoring.Legend.Label': 'Legenda',
    'MursionPortal.Monitoring.Legend.Dashboard': 'Pulpit nawigacyjny',
    'MursionPortal.Label.SimSpecialist': 'Sim Specialist',
    'MursionPortal.Monitoring.SessionsSummary.ConnectedToPeers': 'Połączony z współpracownikami',
    'Requests.Table.CertifiedScenarios': 'Certyfikowane scenariusze',
    'Requests.Table.UncertifiedScenarios': 'Niecertyfikowane scenariusze',
    'Requests.Table.NoUpComingDemands': 'Nie masz próśb opartych na zapotrzebowaniu spełniających powyższe kryteria.',
    'Requests.Table.DeclineReason': 'Odrzuć — wybierz powód',
    'Requests.Table.UnAvailable': 'Niedostępne',
    'Requests.Table.NotPrepared': 'Nieprzygotowane',
    'Requests.Table.Other': 'Inne',
    'Requests.Table.Availability': 'Wybierz dostępność',
    'Requests.Table.Declined': 'Odrzucone',
    'Requests.Table.SuccessfullySent': 'Prośba wysłana pomyślnie',
    'Requests.Table.RequestCertificate': 'Poproś o certyfikat',
    'Requests.Table.DeclineReasonText': 'Powód odrzucenia',
    'Requests.Table.AvailabilitySlot': 'Przedział dostępności',
    'Requests.Table.DemandBased': 'Oparte na zapotrzebowaniu',
    'MursionPortal.Setting.Integrations': 'Integracja',
    'MursionPortal.Setting.Integrations.Title': 'Wybierz integrację',
    'MursionPortal.Setting.Integrations.Header.PlatformConfigurations': 'Konfiguracje platformy',
    'MursionPortal.Button.AddConfigurations': 'Dodaj konfiguracje',
    'Settings.LTI.PlaceHolder.EnterConfigName': 'Wprowadź nazwę konfiguracji',
    'Settings.LTI.Table.ColumnHeader.DeploymentID': 'ID wdrożenia',
    'Settings.LTI.Table.ColumnHeader.Version': 'Wersja',
    'Settings.LTI.Table.Caption': 'Lista konfiguracji lti',
    'Settings.LTI.Table.Hover.ViewEditLti': 'Wyświetl/edytuj Lti',
    'Settings.LTI.Table.NoLtiConfigFound': 'Nie znaleziono konfiguracji LTI',
    'MursionPortal.EditLtiModal.Button.DeleteLti': 'Usuń konfigurację',
    'MursionPortal.EditLtiModal.Button.SaveLtiConfig': 'Zapisz konfigurację',
    'MursionPortal.Lti.NewLTIConfig': 'Nowa konfiguracja LTI',
    'MursionPortal.Lti.CreateLTIConfig': 'Utwórz konfigurację LTI',
    'Settings.LTI.Modal.PlatformIssuerIdentifier': 'Identyfikator wydawcy platformy',
    'Settings.LTI.Modal.PublicKeysetEndpoint': 'Punkt końcowy zestawu kluczy publicznych',
    'Settings.LTI.Modal.DeploymentId': 'ID wdrożenia',
    'Settings.LTI.Modal.RolesMapping': 'Mapowanie ról',
    'Settings.LTI.Modal.RolesMapping.PortalRole': 'Rola portalu',
    'Settings.LTI.Modal.RolesMapping.LTIRole': 'Rola LTI',
    'MursionPortal.Setting.Integrations.LTIVersion': 'Wersja LTI',
    'MursionPortal.ErrorMessage.LtiIdMustBeSpecified': 'Id LTI musi być określone.',
    'MursionPortal.Dialog.CreateLTIConfiguration.SuccessMessage': 'Pomyślnie utworzono konfigurację LTI.',
    'MursionPortal.DemandBasedScheduling.TimeBlockUnavailableReserved': 'Blok czasowy niedostępny — zarezerwowany dla innej zaplanowanej symulacji.',
    'MursionPortal.DemandBasedScheduling.TimeBlockUnavailableSelectDate': 'W tej chwili nie mamy wolnych terminów. Spróbuj ponownie później. <code1></code1>Skontaktuj się z <code>pomocą techniczną</code>, jeśli potrzebujesz natychmiastowej pomocy.',
    'Requests.Table.ViewAvailability': 'Zobacz dostępność',
    'Settings.LTI.Modal.DeletionConfirmationText': 'Czy na pewno chcesz usunąć to mapowanie ról?',
    'Settings.ScenarioBank.Modal.InactiveCertification': 'Nieaktywna certyfikacja',
    'Settings.ScenarioBank.Modal.NoInactiveSimSpecialist': 'Brak nieaktywnych Sim Specialist',
    'Settings.ScenarioBank.Modal.EditInactiveCertification': 'Edytuj nieaktywny certyfikat',
    'Settings.ScenarioBank.Modal.NoInactiveCertification': 'Brak nieaktywnych certyfikatów',
    'MursionPortal.RequestStatus.All': 'Status prośby: wszystkie',
    'MursionPortal.RequestStatus.Accepted': 'Status prośby: zaakceptowane',
    'MursionPortal.RequestStatus.Declined': 'Status prośby: odrzucone',
    'MursionPortal.RequestStatus.Pending': 'Status prośby: oczekujące',
    'Requests.Table.RequestCertification': 'Poproś o certyfikację',
    'Requests.Table.RequestTime': 'Czas oczekiwania',
    'Requests.Table.ScheduleType': 'Typ harmonogramu',
    'Requests.Table.AcceptTime': 'Zaakceptuj czas',
    'Requests.Table.Scheduling': 'Planowanie',
    'Requests.Table.FulfilledIn': 'Spełnione w',
    'MursionPortal.DemandBasedScheduling.Timezone': 'Strefa czasowa',
    'MursionPortal.DemandBasedScheduling.Timezone.Tooltip': 'Przejdź do ustawień profilu, aby zmienić strefę czasową.',
    'MursionPortal.Results.Found': 'Znaleziono {count} wyników',
    'MursionPortal.Header.VisibilityHidden.ClientUsers': 'Użytkownicy Klienta',
    'MursonPortal.SchedulingType.Auto': 'AUTOMATYCZNY',
    'MursonPortal.SchedulingType.Manual': 'MANUALNY',
    'MursionPortal.ScheduleTypeSelector.All': 'Harmonogram: wszystkie',
    'MursionPortal.ScheduleTypeSelector.Auto': 'Harmonogram: automatyczny',
    'MursionPortal.ScheduleTypeSelector.Manual': 'Harmonogram: manualny',
    'MursionPortal.ScheduleTypeSelector.NA': 'Harmonogram: nie dotyczy',
    'Integrations.LTI.AppId': 'App Id',
    'Integrations.LTI.ConfigurationId': 'Id konfiguracji',
    'Settings.LTI.ModalHeader.EditLtiConfiguration': 'Edytuj konfigurację LTI',
    'Settings.LTI.Modal.DeletionConfigConfirmationText': 'Czy na pewno chcesz usunąć tę konfigurację LTI?',
    'MursionPortal.Rescheduling.RescheduleSimulation': 'Przełóż symulację',
    'MursionPortal.Rescheduling.SlotSelectionMessage': 'Przykro nam, że nie możesz przeprowadzić symulacji. Wybierz dzień i godzinę, które Ci odpowiadają!',
    'MursionPortal.IVSBrowserWarning.Safari17.Message1': 'The browser version you are currently using is not supported for your Mursion session',  // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Message2': 'When joining your Mursion simulation, use one of these browsers:',  // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Firefox': "Firefox",  // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Chrome': "Chrome", // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Chrome.Subtitle': "Recommended", // TODO: add translations
    'MursionPortal.IVSBrowserWarning.Safari17.Edge': "Edge", // TODO: add translations
    'MursionPortal.Result.Found': 'Znaleziono {count} wyników',
    'MursionPortal.Project.Rescheduling': 'Zmiana terminu',
    'MursionPortal.Project.SameDay': 'Ten sam dzień',
    'Session.Edit.Modal.MissedSession': 'SESJA POMINIĘTA',
    'Session.Edit.Modal.NoLongerRequired': 'nie jest już wymagane',
    'Session.Edit.Modal.TechDifficulties': 'mające problemy techniczne',
    'Session.Edit.Modal.Other': 'inne',
    'Session.Edit.Modal.CancelBecause': 'Tak, anuluj sesję, ponieważ',
    'Session.Edit.Modal.CancelSimulation': 'Anuluj symulację',
    'MursionPortal.UserConsent.Heading': 'Uprawnienia do nagrywania',
    'MursionPortal.UserConsent.Title': 'Czy chcesz nagrać tę sesję symulacyjną na własne potrzeby?',
    'MursionPortal.UserConsent.Description': 'Jeśli powiesz „Zgadzam się”, Mursion software przetworzy Twoją symulację na żywo i wykona nagranie wideo sesji symulacyjnej do przejrzenia w portalu Mursion software. Należy pamiętać, że na prośbę klienta, który licencjonował Mursion software w Twoim imieniu, kopia nagranych sesji symulacyjnych zostanie udostępniona trenerowi, mentorowi lub recenzentowi wybranemu przez klienta. Wszyscy recenzenci są umownie zobowiązani do przestrzegania <code>polityki prywatności Mursion</code>, chyba że zgodzili się Państwo na inne warunki prywatności. Z wyjątkiem przypadków wymaganych przez obowiązujące prawo, Mursion nie będzie rozpowszechniać ani ujawniać Twojego nagrania wideo bez Twojej zgody. Mursion wykorzysta anonimowe dane z korzystania z software w celu ulepszenia technologii symulacji.',
    'MursionPortal.UserConsent.IConsent': 'Zgadzam się',
    'MursionPortal.UserConsent.IDoNotConsent': 'Nie wyrażam zgody',
    'MursionPortal.RecordingLinkPermisssion.Title': 'Uprawnienia do nagrywania',
    'MursionPortal.RecordingLinkPermisssion.DescriptionOne': 'Czy chcesz otrzymać nagranie tej sesji?',
    'MursionPortal.RecordingLinkPermisssion.DescriptionTwo': 'Jeśli tak, sprawdź swoją skrzynkę odbiorczą i zweryfikuj swój adres e-mail. Jutro wyślemy Ci link, jeśli będzie dostępny.',
    'MursionPortal.PublicSession.PageTitle': 'Wprowadź swoje dane, aby dołączyć do symulacji',
    'MursionPortal.PublicSession.Form.label.FirstName': 'Imię',
    'MursionPortal.PublicSession.Form.label.LastName': 'Nazwisko',
    'MursionPortal.PublicSession.Form.label.Email': 'E-mail (opcjonalnie)',
    'PublicSession.Form.Button.Join': 'Dołącz',
    'MursionPortal.PublicSession.Form.Placeholder.FirstName': 'Twoje imię',
    'MursionPortal.PublicSession.Form.Placeholder.LastName': 'Twoje nazwisko',
    'MursionPortal.PublicSession.Form.Placeholder.Email': 'name@provider.com',
    'MursionPortal.PublicSession.Form.Placeholder.HaveAccount': 'Posiadasz już konto?',
    'MursionPortal.PublicSession.Form.Placeholder.LogInHere': 'Zaloguj się tutaj',
    'Session.Edit.Modal.ToolTip.Google': 'Dodaj wydarzenie do swojego kalendarza Google. W razie potrzeby potwierdź autoryzację',
    'Session.Edit.Modal.ToolTip.OutlookIcalOther': 'Kliknij, aby pobrać wydarzenie na pulpit i dodać je do app kalendarza',
    'Session.Edit.Modal.SimSpecialistAssigned': 'Przydzielony Sim Specialist',
    'Session.Edit.Modal.SessionSimSpecialistField.AddSIMSpecialist': 'Dodaj Sim Specialist',
    'Session.Edit.Modal.DemandBasedTimeSlots': 'Przedziały czasowe na żądanie',
    'Session.Edit.Modal.Reschedule': 'Zmień termin',
    'Session.Edit.Modal.JoinSession': 'Rozpocznij sesję',
    'Session.Edit.Modal.AddLearner': 'Dodaj ucznia',
    'MursionPortal.Label.SearchOrSelectLearners': 'Wyszukaj lub wybierz uczniów',
    'MursionPortal.Label.SIMSpecialist': 'Sim Specialist',
    'MursionPortal.Client.EmailSettings.Header.Rescheduling': 'Zmiana terminu',
    'MursionPortal.Client.EmailSettings.RescheduleMissedSession': 'Zmień termin pominiętej sesji',
    'Session.Edit.Modal.OtherPrioritiesAtWork': 'inne priorytety w pracy',
    'MursionPortal.Modal.Header.Sorry': 'Przepraszam!',
    'MursionPortal.Modal.UnschedulableSessionMsg': 'Sesji nie da się zaplanować.',
    'MursionPortal.Rescheduling.Slot.NoLongerAvailable': 'Wybrany czas nie jest już dostępny, wybierz inną opcję.',
    'MursionPortal.EmailVerificationPage.Success': 'Weryfikacja adresu e-mail powiodła się!',
    'MursionPortal.EmailVerificationPage.SuccessDesc': 'Twój adres e-mail został pomyślnie zweryfikowany',
    'MursionPortal.EmailVerificationPage.Error': 'Weryfikacja adresu e-mail nie powiodła się!',
    'MursionPortal.EmailVerificationPage.ErrorDesc': 'Coś poszło nie tak. Spróbuj ponownie, aby zweryfikować swój adres e-mail',
    'Settings.Config.Heading.SplashPageAttendance': 'FREKWENCJA',
    'Clients.Modal.Label.ShowSowReporting': 'Pokaż raporty wykorzystania:',
    'Clients.Modal.Label.ShowSurveyEnabled': 'Wyślij ankietę po symulacji dla uczestnika:',
    'Clients.Modal.Label.MursionSocialAttendance': 'Frekwencja Mursion Social:',
    'Clients.Modal.Label.MursionSocialAttendance.ToolTip': 'Gromadź frekwencję uczniów podczas sesji Mursion Social.',
    'Settings.Config.Invitation.MursionSocialAttendance': 'Gromadź frekwencję Mursion Social.',
    'Settings.Config.External.Users': '(Musi być również włączone w konfiguracji klienta)',
    'Settings.Config.Invitation.ExternalUser': 'Do udziału użytkowników zewnętrznych',
    'MursionPortal.Scenario.Vignette.ButtonText.ShowMore.SessionDetails': 'Szczegóły sesji',
    'MursionPortal.DemandBasedScheduling.ProjectDropdown.Placeholder': 'Wybierz projekt',
    'MursionPortal.DemandBasedScheduling.ScenarioDropdown.Placeholder': 'Wybierz scenariusz',
    'Session.Edit.Modal.Label.SearchOrSelectASimSpecialist': 'Wyszukaj lub wybierz SIM Specialist',
    'MursionPortal.Button.Exclamation.GotIt': 'Rozumiem!',
    'MursionPortal.Modal.UnavailableSlotMsg': 'Wybrany czas jest teraz niedostępny. Wybierz nowy przedział czasowy.',
    'MursionPortal.SimAttendance.NoLongerAvailable': 'Nie możesz już edytować obecności w tej symulacji.',
    'MursionPortal.SimAttendance.Midnight': 'Każdego dnia do północy musisz wypełnić listę obecności. Obecność w tej symulacji będzie domyślnie odpowiadać statusom systemowym. Skontaktuj się z pomocą techniczną, jeśli wystąpią jakiekolwiek nieprawidłowości.',
    'MursionPortal.SimAttendance.Instructions': 'Instrukcje',
    'MursionPortal.SimAttendance.Attendee': 'Uczestnik',
    'MursionPortal.SimAttendance.Tooltip.Completed': 'Uczeń ukończył część symulacyjną sesji (bez podsumowania na koniec sesji).',
    'MursionPortal.SimAttendance.Tooltip.Missed': 'Uczeń nigdy nie był podłączony do symulacji.',
    'MursionPortal.SimAttendance.Tooltip.Error': 'Session was unable to be completed due to technical difficulties on the Learner’s side. (if the session was able to be completed after an error this session should be marked complete and not error).',// TODO add translations
    'MursionPortal.SimAttendance.Tooltip.Late': 'Uczeń połączył się zbyt późno, aby ukończyć symulację.',
    'MursionPortal.SimAttendance.Tooltip.Left': 'Uczeń celowo rozłączył się przed ukończeniem symulacji. Może to być spowodowane nieprzygotowaniem ucznia lub jakimś nagłym wypadkiem.',
    'MursionPortal.SimAttendance.Tooltip.Declined': 'Uczeń połączył się z symulacją, ale odmówił udziału i opuścił ją przed ukończeniem co najmniej 50% symulacji.',
    'MursionPortal.SimAttendance.Add.Attendee': 'Dodaj uczestnika',
    'Session.Edit.Modal.EditAttendance': 'Edytuj frekwencję',
    'Session.Edit.Modal.CompleteAttendance': 'Pełna frekwencja',
    'MursionPortal.SimAttendance.Late': 'Późno',
    'MursionPortal.SimAttendance.Left': 'Opuszczone',
    'MursionPortal.Instructions.First': 'Wybierz status obecności dla każdego uczestnika (najedź kursorem myszy na status, aby dowiedzieć się więcej). Jeśli ucznia nie ma na liście, kliknij',
    'MursionPortal.Instructions.Second': 'funkcja w lewym dolnym rogu.',
    'MursionPortal.TableColumn.SimsAction': 'Akcja Sim',
    'Session.Edit.Modal.Label.SelectTrainer': 'Wyszukaj lub wybierz instruktora',
    'MursionPortal.Project.DemandSlots': 'Przedziały czasowe na żądanie',
    'Dashboard.SimulationTable.Column.Provider': 'Dostawca',
    'Dashboard.SimulationTable.Column.Client': 'Klient',
    'Dashboard.SimulationTable.Column.Project': 'Nazwa projektu',
    'Dashboard.SimulationTable.Column.ScenarioName': 'Nazwa scenariusza',
    'Dashboard.SimulationTable.Column.ScheduledStartDate': 'Planowana data rozpoczęcia',
    'Dashboard.SimulationTable.Column.ScheduledStartTime': 'Planowany czas rozpoczęcia',
    'Dashboard.SimulationTable.Column.ScheduledEndTime': 'Planowany czas zakończenia',
    'Dashboard.SimulationTable.Column.SimulationStatus': 'Status sesji',
    'Dashboard.SimulationTable.Column.LearnersNames': 'Imiona uczniów',
    'Dashboard.SimulationTable.Column.LearnersEmails': 'E-maile uczniów',
    'Dashboard.SimulationTable.Column.LearnersStatus': 'Statusy uczniów',
    'Dashboard.SimulationTable.Column.LearnersTimeSpent': 'Czas spędzony przez uczniów',
    'Dashboard.SimulationTable.Column.SimSpecialistName': 'Imię Sim Specialist',
    'Dashboard.SimulationTable.Column.SimSpecialistStatus': 'Status Sim Specialist',
    'Dashboard.SimulationTable.Column.SimSpecialistTimeSpent': 'Czas spędzony przez SIM Specialist',
    'Dashboard.SimulationTable.Column.ScheduledBy': 'Zaplanowane przez',
    'Dashboard.SimulationTable.Column.ScheduledAt': 'Zaplanowane na',
    'Dashboard.SimulationTable.Column.TimesLateRescheduled': 'Opóźnienia przełożone na inny termin',
    'Dashboard.SimulationTable.Column.Recorded': 'Nagrane',
    'Dashboard.SimulationTable.Column.ClientId': 'ID klienta',
    'Dashboard.SimulationTable.Column.LearnerName': 'Imię ucznia',
    'Dashboard.SimulationTable.Column.LearnerName.ButtonHoverTitle': 'Skopiuj adres e-mail ucznia',
    'Dashboard.SimulationTable.Column.LearnerEmail': 'E-mail ucznia',
    'Dashboard.SimulationTable.Column.LearnerStatus': 'Status ucznia',
    'Dashboard.SimulationTable.Column.LearnerTimeSpent': 'Czas spędzony przez ucznia',
    'Dashboard.SimulationTable.Column.LearnerRecordingConsent': 'Zgoda ucznia na nagrywanie',
    'Dashboard.SimulationTable.Column.RecordingRecipients': 'Nagrywanie odbiorców',
    'Dashboard.SimulationTable.Column.RecordingUploaded': 'Przesłano nagranie',
    'Dashboard.SimulationTable.ColumnTooltip.Provider': 'Nazwa usługodawcy/licencjobiorcy',
    'Dashboard.SimulationTable.ColumnTooltip.Client': 'Imię klienta',
    'Dashboard.SimulationTable.ColumnTooltip.Project': 'Nazwa projektu',
    'Dashboard.SimulationTable.ColumnTooltip.ScenarioName': 'Nazwa scenariusza',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledStartDate': 'Data zaplanowanego rozpoczęcia tej symulacji',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledStartTime': 'Godzina zaplanowanego rozpoczęcia tej symulacji',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledEndTime': 'Godzina zaplanowanego zakończenia tej symulacji',
    'Dashboard.SimulationTable.ColumnTooltip.SimulationStatus': 'Stan końcowy symulacji',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersNames': 'Imiona uczniów zarejestrowanych i niezarejestrowanych do symulacji',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersEmails': 'E-maile uczniów zarejestrowanych i niezarejestrowanych do symulacji',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersStatus': 'Końcowy status ucznia w symulacji',
    'Dashboard.SimulationTable.ColumnTooltip.LearnersTimeSpent': 'Czas spędzony w połączeniu z symulacją przez uczniów, którzy dołączyli do symulacji',
    'Dashboard.SimulationTable.ColumnTooltip.SimSpecialistName': 'Simulation specialist wyznaczony do przeprowadzenia symulacji',
    'Dashboard.SimulationTable.ColumnTooltip.SimSpecialistStatus': 'Ostateczny status SIM specialist symulacji',
    'Dashboard.SimulationTable.ColumnTooltip.SimSpecialistTimeSpent': 'Rzeczywista długość tej symulacji w minutach',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledBy': 'Nazwa użytkownika, który zaplanował symulację',
    'Dashboard.SimulationTable.ColumnTooltip.ScheduledAt': 'Data i godzina zaplanowania tej symulacji',
    'Dashboard.SimulationTable.ColumnTooltip.TimesLateRescheduled': 'Czas, w którym ta symulacja została z opóźnieniem przełożona',
    'Dashboard.SimulationTable.ColumnTooltip.Recorded': 'Wskazuje, czy symulacja została zarejestrowana, czy też nie wyrażono na to zgody.',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerName': 'Imiona uczniów zarejestrowanych i niezarejestrowanych do symulacji',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerStatus': 'Końcowy status ucznia w symulacji',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerTimeSpent': 'Minuty, które uczeń spędził w symulacji. W przypadku uczniów Zoom i Meet są one szacowane przez SIM specialist',
    'Dashboard.SimulationTable.ColumnTooltip.LearnerRecordingConsent': 'Wskazuje, czy uczeń wyraził zgodę na nagrywanie symulacji',
    'Dashboard.SimulationTable.ColumnTooltip.RecordingRecipients': 'Role, do których wysyłane jest nagranie symulacji',
    'Dashboard.SimulationTable.ColumnTooltip.RecordingUploaded': 'Wskazuje, czy nagranie symulacji zostało przesłane',
    'MursionPortal.CompanyCard.InviteOperations': 'Operacje zapraszania',
    'MursionPortal.CompanyCard.Modal.Header.NewOperations': 'Nowe operacje',
    'Settings.Config.Invitation.ShowContractFeature': 'Zezwalaj na dodawanie umów',
    'Settings.Config.Contract.Description': '(do śledzenia postępu umowy)',
    'MursionPortal.Contract.Edit': 'Edytuj',
    'MursionPortal.Contract.ContractName': 'Nazwa umowy',
    'MursionPortal.Contract.ContractID': 'ID umowy',
    'MursionPortal.Contract.ContractID.PlaceHolder': 'Wprowadź ID umowy z Hubspot',
    'MursionPortal.Contract.StartAndEndDates': 'Daty rozpoczęcia i zakończenia umowy',
    'MursionPortal.Contract.Notes.PlaceHolder': 'Wprowadź inne informacje, które mają być widoczne tylko dla CSM i BDD.',
    'MursionPortal.Contract.LineItem.Add': 'Dodaj pozycję umowy',
    'MursionPortal.Contract.LineItem.Add.Instruction': 'Można dodać wiele pozycji symulacji. Wszystkie inne typy mogą mieć tylko jedną pozycję.',
    'MursionPortal.Contract.LineItemType': 'Typ pozycji',
    'MursionPortal.Contract.LineItemType.PlaceHolder': 'Wybierz typ pozycji',
    'MursionPortal.Contract.Quantity': 'Ilość',
    'MursionPortal.Contract.Quantity.PlaceHolder': 'Wprowadź ilość',
    'MursionPortal.Contract.DeliveryMode.PlaceHolder': 'Wybierz metodę dostawy',
    'MursionPortal.Contract.SaveContract': 'Zapisz szczegóły umowy',
    'MursionPortal.Contract.Length.PlaceHolder': 'Wybierz długość',
    'MursionPortal.Contract.SowName.PlaceHolder': 'Wprowadź nazwę umowy',
    'MursionPortal.Client.Tab.Contracts': 'Umowy',
    'MursionPortal.Client.Contracts': 'Umowy Klienta',
    'MursionPortal.Client.Create.Contracts': 'Utwórz umowę',
    'MursionPortal.Client.Contract.ID': 'ID umowy',
    'MursionPortal.NewUser.Optional': '(opcjonalnie)',
    'MursionPortal.Text.Congratulation': 'Gratulacje!',
    'MursionPortal.Text.PasswordChanged': 'Twoje hasło zostało zmienione',
    'MursionPortal.Text.PasswordShouldBeSame': 'Twoje nowe hasła muszą być zgodne.',
    'MursionPortal.NewUser.SuchAGoogleAccountOrOtherSsoProvider.TooltipText': 'Takie jak konto Google lub inny dostawca SSO',
    'MursionPortal.UserConsentDeclineConfirmation.Heading': 'Potwierdź swój wybór.',
    'MursionPortal.UserConsentDeclineConfirmation.PermissionsLiesWithLearner': 'Jesteś pewien? Aby kontynuować bez nagrywania, kliknij przycisk Potwierdź. Aby zmienić zgodę na nagrywanie, kliknij przycisk Anuluj.',
    'MursionPortal.UserConsentDeclineConfirmation.PermissionDoesNotLieWithLearner': 'Wskaż, czy wyrażasz zgodę na kontynuowanie i rejestrowanie symulacji. Jeśli nie wyrażasz zgody, nie możesz kontynuować sesji symulacyjnej, a software zostanie zamknięte. Jeśli wyrazisz zgodę, Twoje sesje symulacyjne zostaną nagrane.',
    'Settings.Config.Invitation.ShowSowFeature': 'Zezwalaj na dodawanie SOW',
    'Settings.Config.SOW.Description': '(do śledzenia postępów SOW)',
    'MursionPortal.ScenarioBank.StandardizedScenario': 'Scenariusz standaryzowany',
    'MursionPortal.ScenarioBank.ShowOnlyBundleScenario': 'Pokaż tylko scenariusze standaryzowane',
    'Dashboard.LeftPane.CategoryHeader.SupplyManagement': 'SIM Workforce Mgmt',
    'Dashboard.LeftPane.SupplyManagement.Scheduling': 'Planowanie',
    'Dashboard.SupplyManagement.Scheduling.MainHeader': 'Planowanie',
    'Dashboard.SupplyManagement.Scheduling.TableHeader': 'Dane planowania',
    'Dashboard.LeftPane.SupplyManagement.ClientRoster': 'Lista klientów',
    'Dashboard.SupplyManagement.ClientRoster.MainHeader': 'Lista klientów',
    'Dashboard.SupplyManagement.ClientRoster.TableHeader': 'Dane listy klientów',
    'MursionPortal.Table.ColumnHeader.Time': 'Czas',
    'MursionPortal.Table.ColumnHeader.AllScheduledSessions': 'Wszystkie zaplanowane (sesje)',
    'MursionPortal.Table.ColumnHeader.SimsAvailable': 'Dostępność SIM (Ludzie)',
    'MursionPortal.Table.ColumnHeader.AvailabilityHours': 'Dostępność (Godziny)',
    'MursionPortal.Table.ColumnHeader.DemandBasedRequests': 'Wszystkie prośby oparte na zapotrzebowaniu (łącznie — wszystkie 3 prośby)',
    'MursionPortal.Table.ColumnHeader.SlotOneRequests': 'Prośby o przedział 1',
    'MursionPortal.Table.ColumnHeader.SlotTwoRequests': 'Prośby o przedział 2',
    'MursionPortal.Table.ColumnHeader.SlotThreeRequests': 'Prośby o przedział 3',
    'MursionPortal.Table.ColumnHeader.PendingRequests': 'Prośby oczekujące (sesje)',
    'MursionPortal.Table.ColumnHeader.Auto': 'Automatyczny',
    'MursionPortal.Table.ColumnHeader.Manual': 'Manualny',
    'MursionPortal.Table.ColumnHeader.CapacityIndicator': 'Wskaźnik dostępności',
    'MursionPortal.EmptyMessage.NoSupplyManagementDataYet': 'Nie ma jeszcze danych dotyczących zarządzania siłą roboczą SIM',
    'MursionPortal.Table.ColumnHeader.SimCertified': 'Certyfikowane SIM',
    'MursionPortal.Table.ColumnHeader.ScheduledHours': 'Zaplanowane (Godziny)',
    'MursionPortal.Table.ColumnHeader.Sessions': 'Sesje',
    'MursionPortal.Table.ColumnHeader.Cancelled': 'Anulowane',
    'MursionPortal.Table.ColumnHeader.Missed': 'Pominięte',
    'MursionPortal.Table.ColumnHeader.Reschedules': 'Zmiany terminów',
    'MursionPortal.Table.ColumnHeader.Error': 'Błąd',
    'MursionPortal.Table.ColumnHeader.Completed': 'Ukończone',
    'MursionPortal.Table.ColumnHeader.Other': 'Inne',
    'MursionPortal.Table.ColumnHeader.SchedulingWindow': 'Okno planowania',
    'MursionPortal.Table.ColumnHeader.RequestDemand': 'Liczba próśb (popyt)',
    'MursionPortal.Table.ColumnHeader.AutoFulfilled': 'Automatycznie wypełnione',
    'MursionPortal.Table.ColumnHeader.Pending': 'Oczekujące',
    'MursionPortal.Table.ColumnHeader.AvgFullfilled': 'Średnia wypełniona w',
    'MursionPortal.Table.ColumnHeader.SlotOneFullFillment': 'Wypełnienie przedziału 1',
    'MursionPortal.Table.ColumnHeader.SlotTwoFullFillment': 'Wypełnienie przedziału 2',
    'MursionPortal.Table.ColumnHeader.SlotThreeFullFillment': 'Wypełnienie przedziału 3',
    'MursionPortal.Table.ColumnHeader.UnutilizedAvailability': 'Niewykorzystana dostępność (godziny)',
    'MursionPortal.Table.ColumnHeader.TotalAvailabilityEntered': 'Wprowadzono całkowitą dostępność',
    'MursionPortal.Table.ColumnHeader.AverageHoursWeek': 'Średnia liczba godzin/tydzień',
    'MursionPortal.Table.ColumnHeader.AvailabilityUtilization': 'Wykorzystanie dostępności',
    'MursionPortal.SupplyManagement.Above': 'Powyżej',
    'MursionPortal.SupplyManagement.Below': 'Poniżej',
    'MursionPortal.SupplyManagement.At': 'Na',
    'MyProfile.ProfServiceRole.Tooltiptext': 'Użytkownicy z naszym najwyższym poziomem dostępu',
    'MyProfile.AccManager.Tooltiptext': 'Tworzy scenariusze, zespoły, dodaje użytkowników i przydziela zespoły do symulacji',
    'MyProfile.SimSpecialist.Tooltiptext': 'Ta rola, znana również jako „Sim”, wykonuje symulacje dla uczniów',
    'MyProfile.Buyer.Tooltiptext': 'Użytkownik zarządzający projektem Mursion w swojej organizacji',
    'MyProfile.Facilitator.Tooltiptext': 'Uczestnik symulacji, który pomaga w przeprowadzeniu symulacji',
    'MyProfile.Learner.Tooltiptext': 'Uczestnik symulacji',
    'MyProfile.Operations.Tooltiptext': 'Pracownik Mursion posiadający możliwość dodawania i edytowania umów',
    'MyProfile.SimDesigner.Tooltiptext': 'Użytkownik posiadający możliwość tworzenia i edytowania scenariuszy bankowych',
    'Settings.Config.Invitation.SimWorkforceManagement': 'Zarządzanie pracownikami SIM',
    'Settings.Config.SimWorkforceManagement.Description': 'Włączona oznacza, że raporty są dostępne na stronie pulpitu nawigacyjnego, a wyłączona oznacza, że raporty w ogóle nie są dostępne.',
    'MursionPortal.ScenarioBank.BundleScenario': 'Zestaw scenariuszy',
    'MursionPortal.Scheduling.SimAvailable.TimeSlotsTooltipMsg': 'Wybierz ten przedział czasowy, aby automatycznie potwierdzić rezerwację scenariusza!',
    'MursionPortal.Label.AssignedUserRoles': 'Przypisane role użytkowników',
    'MursionPortal.Label.AssignedUserRole': 'Przypisana rola użytkownika',
    'Clients.Modal.Projects.Button.CreateFromBank': 'Utwórz z banku',
    'MursionPortal.Legend.VisibilityHidden.ScenarioPanel.Buttons': 'przyciski Utwórz nowy, Powtórz klienta, Utwórz z scenariusza bankowego',
    'MursionPortal.Label.ArtBundle': 'Pakiet artystyczny',
    'Settings.ScenarioBank.Modal.ToolTip.ThisIsTheArtContentMsg': 'Jest to grafika 3D przedstawiająca środowiska i awatary, które zostaną użyte w symulacji. Zawartość tego folderu jest wyświetlana w sekcji zatytułowanej „Pakiet artystyczny” poniżej.',
    'MursionPortal.SessionWizard.Label.ScenarioHasNoArtBundles': 'Scenariusz nie ma przypisanych pakietów artystycznych.',
    'MursionPortal.Label.ArtProject': 'Projekt artystyczny',
    'MursionPortal.Label.UpperCase.ArtProject': 'PROJEKT ARTYSTYCZNY',
    'Settings.Config.SimulationContent.ArtProjectFolder': 'folder Art Project S3',
    'Settings.ScenarioBank.Modal.ThisScenarioHasNoArtProject': 'Scenariusz nie ma przypisanego projektu artystycznego',
    'MursionPortal.SessionWizard.Label.SelectArtContentForUse': 'Wybierz projekt artystyczny do użytku',
    'Projects.Message.NoArtProjectFound': 'Nie znaleziono projektu artystycznego',
    'Settings.ScenarioBank.Form.ConfirmArchiveScenarioBank': 'Istnieją aktywne scenariusze podrzędne utworzone na podstawie tego scenariusza bankowego. Musisz zarchiwizować każdy z nich osobno.',
    'Settings.StandardizedScenarioBank.EditConfirmation': 'Czy na pewno chcesz zaktualizować wszystkie istniejące scenariusze utworzone na podstawie tego scenariusza?',
    'Settings.StandardizedScenarioBank.AttachmentsEditConfirmation': 'Aktualizacja załączników zmieni wszystkie istniejące scenariusze utworzone na podstawie tego.',
    'MursionPortal.Contract.Ending': 'Kończy się',
    'MursionPortal.ProjectContracts.DateErrorHeading': 'Dla tych dat projektu nie istnieje żadna umowa',
    'MursionPortal.Contract.ViewDetails': 'Pokaż szczegóły',
    'MursionPortal.Contract.PleaseAddContractToProject': 'Proszę dodać umowę do projektu',
    'MursionPortal.Contract.AddContractToProject': 'Dodaj umowę do projektu',
    'MursionPortal.Contract.SelectContractToProject': 'Wybierz umowę, którą chcesz dodać do tego projektu — dodawaj pojedynczo',
    'MursionPortal.Contract.AddSelectedContract': 'Dodaj wybraną umowę',
    'MursionPortal.Contract.AddContract': 'Dodaj umowę',
    'MursionPortal.Contract.LineItemList': 'Lista pozycji',
    'MursionPortal.DemandBasedScheduling.InstantBooking': 'Natychmiastowa rezerwacja',
    'MursionPortal.DemandBasedScheduling.AllSlots': 'Prześlij przedziały czasowe',
    'MursionPortal.DemandBasedScheduling.ScheduleYourScenario': 'Zaplanujemy Twój scenariusz w jednym z wybranych przez Ciebie przedziałów czasowych.',
    'MursionPortal.DemandBasedScheduling.SelectOneOfTheseSlotsForAnInstantBooking': 'Wybierz jeden z tych przedziałów czasowych, aby dokonać natychmiastowej rezerwacji.',
    'MursionPortal.Label.VideoTimeLineSlice': 'Fragmenty osi czasu filmu',
    'MursionPortal.Label.SpeakingMoreThanSixty': 'wynosi ponad 60%',
    'MursionPortal.Label.SpeakingBetweenSixty': 'wynosi od 40% do 60%',
    'MursionPortal.Label.SpeakingLessThanFourty': 'wynosi mniej niż 40%',
    'MursionPortal.Label.SpeakingIsZero': 'wynosi 0%',
    'MursionPortal.Label.InterruptionMoreThanSixty': 'czyli ponad 60%',
    'MursionPortal.Label.InterruptionBetweenSixty': 'czyli między 40% a 60%',
    'MursionPortal.Label.InterruptionLessThanFourty': 'czyli mniej niż 40%',
    'MursionPortal.Label.InterruptionIsZero': 'czyli 0%',
    'MursionPortal.Label.Interruption.ByAvatar': 'Awatar',
    'MursionPortal.Label.Interruption.ByLearner': 'Uczeń',
    'MursionPortal.Label.InterruptionCount': 'Czasy',
    'MursionPortal.ConversationalFlow.label.videoTimeLine': 'Oś czasu filmu',
    'MursionPortal.ConversationalFlow.label.zoomTimeLine': 'Widok osi czasu',
    'MursionPortal.ConversationalFlow.label.zoom.all': 'Wszystko',
    'MursionPortal.ConversationalFlow.label.zoom': 'Powiększenie',
    'MursionPortal.DemandBasedScheduling.TheseSlotDoNotwork': 'Jeśli te przedziały nie działają, wybierz „Prześlij przedziały”, aby przesłać godziny, które Ci odpowiadają.',
    'MursionPortal.AltText.CrossButton': 'obraz przycisku krzyżykowego',
    'MursionPortal.Title.BankScenario': 'Scenariusz bankowy',
    'Session.Edit.Modal.JoinSessionBtnTooltip': 'Przycisk rozpocznij sesję zostanie włączony na <code>{minutesBeforeJoin}</code> minut przed rozpoczęciem sesji.',
    'Dashboard.LeftPane.SupplyManagement.SimRoster': 'Lista Sim',
    'Dashboard.SupplyManagement.SimRoster.MainHeader': 'Lista Sim',
    'Dashboard.SupplyManagement.SimRoster.TableHeader': 'Dane listy Sim',
    'MursionPortal.Table.ColumnHeader.SimFullName': 'Imię i nazwisko SIM',
    'MursionPortal.Table.ColumnHeader.LanguagesDelivered': 'Obsługiwane języki ( poświadczone certyfikatem językowym)',
    'MursionPortal.Table.ColumnHeader.Tier': 'Poziom',
    'MursionPortal.Table.ColumnHeader.Timezone': 'Strefa czasowa',
    'MursionPortal.Table.ColumnHeader.Scheduled': 'Zaplanowane',
    'MursionPortal.Table.ColumnHeader.ProjectsHoursWeekTotal': 'Przewidywane godziny/tydzień łącznie',
    'MursionPortal.Table.ColumnHeader.ActiveClientCertifications': 'Aktywne certyfikaty klienta (do rozliczenia)',
    'MursionPortal.Table.ColumnHeader.InactiveCertifications': 'Nieaktywna certyfikacja',
    'MursionPortal.Table.ColumnHeader.Declined': 'Odrzucone',
    'MursionPortal.Table.ColumnHeader.AvgFullfillTimeForManual': 'Średni czas wypełnienia dla trybu ręcznego',
    'MursionPortal.Table.ColumnHeader.Queue': 'Kolejka',
    'MursionPortal.Table.ColumnHeader.Availability': 'Dostępność',
    'MursionPortal.Filters.Tier': 'Poziom',
    'MursionPortal.Filters.PlaceHolder.SearchTier': 'Poziom wyszukiwania',
    'MursionPortal.TierOptions.Tier1': 'Poziom 1',
    'MursionPortal.TierOptions.Tier2': 'Poziom 2',
    'MursionPortal.TierOptions.Tier3': 'Poziom 3',
    'MursionPortal.TierOptions.Tier4': 'Poziom 4',
    'MursionPortal.TierOptions.LeadSimulation': 'Główny Simulation Specialist',
    'MursionPortal.Contract.BackToCLient': 'Powrót do Klienta',
    'MursionPortal.DeliveryMode.Workshop': 'Warsztat',
    'MursionPortal.DeliveryMode.PrivatePractice': 'Praktyka prywatna',
    'MursionPortal.DemandBasedScheduling.SimOnlySlotSelectionMessage': 'Wybierz dzień i przedział czasowy, który Ci odpowiada.',
    'MursionPortal.Projects.NoContractsFound': 'Nie znaleziono żadnych umów.',
    'MursionPortal.Projects.ProjectContracts': 'Umowy projektowe',
    'MursionPortal.Tooltip.AvatarInterrupt': 'Awatar skutecznie przerywa mówcy.',
    'MursionPortal.Tooltip.LearnerInterrupt': 'Uczeń skutecznie przerywa mówcy.',
    'MursionPortal.Tooltip.Pauses': 'Pauzy. Dłuższe niż 0,2 s i krótsze niż 0,75 s',
    'MursionPortal.Tooltip.Silence': 'Przerwa w mówieniu dłuższa niż 10 sek',
    'MursionPortal.Tooltip.AvatarSpeaking': 'Awatar z powodzeniem próbuje przemówić.',
    'MursionPortal.Tooltip.LearnerSpeaking': 'Uczeń z powodzeniem próbuje przemówić.',
    'MursionPortal.label.LearnerInterjects': 'Uczeń wtrąca się',
    'MursionPortal.label.AvatarInterjects': 'Awatar wtrąca się',
    'MursionPortal.label.LearnerInterrupted': 'Uczeń wtrącił się',
    'MursionPortal.label.AvatarInterrupted': 'Awatar wtrącił się',
    'MursionPortal.label.Pause': 'Pauza',
    'MursionPortal.label.Silence': 'Cisza',
    'MursionPortal.label.AvatarSpeaking': 'Awatar przemawia',
    'MursionPortal.label.LearnerSpeaking': 'Uczeń przemawia',
    'MursionPortal.Contract.DuplicateLineItem': 'Pozycja dla tej długości symulacji i trybu wyświetlania już istnieje',
    'MursionPortal.Contract.DeleteLineItemIcon': 'Usuń ikonę pozycji',
    'SessionReports.Label.ShareVideoLink': 'Udostępnij link do filmu',
    'SessionReports.Label.CopyVideoLink': 'Skopiuj link do filmu',
    'MursionPortal.SessionNotStarted.Heading': 'Jesteś trochę za wcześnie',
    'MursionPortal.SessionNotStarted.DescriptionOne': 'Podczas oczekiwania możesz:',
    'MursionPortal.SessionNotStarted.AudioVideoSettings': 'Sprawdź ustawienia audio i wideo',
    'MursionPortal.SessionNotStarted.ScenarioDetails': 'Przejrzyj <code>szczegóły scenariusza</code>',
    'MursionPortal.SessionNotStarted.PreSimTips': 'Zapoznaj się z tymi <code>wskazówkami dotyczącymi wstępnej symulacji</code>',
    'MursionPortal.SessionNotStarted.DescriptionTwo': 'To wyskakujące okienko zniknie <code>{value}</code> minut przed symulacją. Kliknij Odśwież, jeśli strona nie zaktualizuje się w tym czasie automatycznie.',
    'Settings.LTI.Modal.SendScore': 'Wyślij wynik',
    'MursionPortal.EndDate': 'Data zakończenia',
    'MursionPortal.StartDate': 'Data rozpoczęcia',
    'MursionPortal.LearnerSimSelector.Tab.Search.Learner': 'Wyszukaj lub wybierz ucznia',
    'MursionPortal.LearnerSimSelector.Tab.Search.Sim': 'Wyszukaj lub wybierz SIM specialist',
    'MursionPortal.LearnerSimSelector.Learner': 'Uczniowie',
    'MursionPortal.LearnerSimSelector.Sim.Available': 'Dostępne',
    'MursionPortal.LearnerSimSelector.Sim.Unavailable': 'Niedostępne',
    'MursionPortal.LearnerSimSelector.Learner.Selected': 'Wybrany uczeń',
    'MursionPortal.LearnerSimSelector.Sim.Selected': 'Sim Specialist',
    'MursionPortal.Label.FilterSIMsBy': 'Filtruj SIM według',
    'MursionPortal.Scheduling.Label.DateTimeTab': 'Data + godzina',
    'MursionPortal.ProjectContract.Confirmation': 'Czy na pewno chcesz usunąć umowę z tego projektu?',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.NotYetScheduled': 'Uczniowie, którzy mają dostępne scenariusze, ale nie zaplanowali jeszcze symulacji',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.CompletedAllAvailable': 'Uczniowie, którzy ukończyli wszystkie dostępne scenariusze',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.Scheduled': 'Uczniowie, którzy mają zaplanowaną jedną lub więcej symulacji, których jeszcze nie ukończyli',
    'MursionPortal.Dashboard.DonutChart.Tooltip.ContractProgress.NoneAvailableToSchedule': 'Uczniowie, którzy nie mieli jeszcze dostępnego scenariusza do zaplanowania',
    'MursionPortal.Title.Hours': 'Godzin',
    'MursionPortal.Title.Minutes': 'Minut',
    'MursionPortal.Dashboard.UpcomingSession': 'Nadchodząca sesja',
    'MursionPortal.Button.View': 'Widok',
    'MursionPortal.Dashboard.NewSimulationAssigned': 'Przypisano nową symulację',
    'MursionPortal.Dashboard.MakeUpSimulation': 'Nadrobienie przełożonej symulacji',
    'MursionPortal.Dashboard.MissedSimulation': 'Pominięte warsztaty',
    'MursionPortal.Dashboard.CompletedSimulations': 'Ukończone symulacje',
    'MursionPortal.Dashboard.CompletedSessions': 'Sesje zakończone',
    'MursionPortal.Dashboard.ViewAll': 'Pokaż wszystkie',
    'MursionPortal.Dashboard.BackToDashboard': 'Powrót do pulpitu nawigacyjnego',
    'MursionPortal.Dashboard.DateOfSimulation': 'Data sesji',
    'MursionPortal.Dashboard.CancelledSimulation': 'Warsztat anulowany',
    'MursionPortal.CodeOfConduct.Text': 'Decydując się na zaplanowanie sesji Mursion, zgadzasz się przestrzegać i szanować <code>kodeks postępowania</code> Mursion.',
    'MursionPortal.SessionFlow.Label.ScenarioDetails': 'Szczegóły scenariusza',
    'MursionPortal.SessionFlow.Label.ProviderName': 'Nazwa dostawcy',
    'MursionPortal.SessionFlow.Label.SessionStartEndDateTime': 'Data i godzina rozpoczęcia i zakończenia sesji',
    'MursionPortal.SessionFlow.Label.DeleteRecording': 'Usuń nagranie',
    'MursionPortal.SessionFlow.Label.YouAreAboutToDeletevideoRecording': 'Zamierzasz usunąć nagranie wideo',
    'MursionPortal.SessionFlow.Label.BeforeDeletionPleaseEnsureTheFollowing': 'Przed usunięciem upewnij się, że:',
    'MursionPortal.SessionFlow.Label.BeforeDeletionPleaseEnsureTheFollowing.Text1': 'Sprawdziłeś, czy żądający użytkownik ma uprawnienia do usuwania (uwaga na symulacje grupowe i zgodę ucznia).',
    'MursionPortal.SessionFlow.Label.BeforeDeletionPleaseEnsureTheFollowing.Text2': 'Utworzyłeś zgłoszenie do pomocy technicznej, w którym podano, kto prosi o usunięcie, i w razie potrzeby skontaktowałeś się z Account Ownerem.',
    'MursionPortal.SessionFlow.Label.SupportCase': 'Zgłoszenie do pomocy technicznej nr*',
    'MursionPortal.SessionFlow.Label.ReminderThisCanNotBeUndone': 'PRZYPOMNIENIE: Nie można tego cofnąć!',
    'MursionPortal.SessionFlow.Label.AreYouSure': 'Jesteś pewien?',
    'SessionReports.VideoRecordingDeletedWithSupportCase': 'Niestety, tego filmu nie można obejrzeć z powodu oczekującej sprawy #{supportCase}.',
    'MursionPortal.Scheduling.NoAvailabilityForCertifiedAndAvailableSimSpecialist': 'Certyfikowany SIM nie jest dostępny.',
    'MursionPortal.DateRangeSelector.Label.DateRange': 'Zakres dat',
    'Session.Edit.Modal.LastEmailTrigger': 'Ostatni e-mail wysłany do ucznia: {sentDate}',
    'Session.Edit.Modal.LastEmailTrigger.Sender.UserName': 'Przez: {userName} {sentTime}',
    'Session.Rescheduling.Email.Reminder.Msg': 'Wysyłanie wiadomości e-mail dotyczącej zmiany terminu do ucznia!',
    'MursionPortal.Scheduling.NoAvailabilityForCertifiedSimSpecialist': 'Brak dostępnych certyfikowanych Simów',
    'MursionPortal.Survey.Thankyou.Msg': 'Dziękujemy za czas poświęcony na wypełnienie tej ankiety. Twoja odpowiedź została zarejestrowana.',
    'Session.Edit.Modal.JoinSimulation': 'Dołącz do sesji',
    'Session.Edit.Modal.JoinSimulationBtnTooltip': 'Będziesz mógł dołączyć na <code>{minutesBeforeJoin}</code> minut przed rozpoczęciem symulacji.',
    'MursionPortal.DemandBasedScheduling.TimeBlockUnavailableReservedText': 'Już wybrany - wybierz inny blok czasowy.',
    'MursionPortal.Dashboard.Join.Tooltip': 'Gdy ta sesja stanie się dostępna, będziesz mógł do niej dołączyć',
    'MursionPortal.ScenarioSearchList.Dialog.Title': 'W tym czasie masz zaplanowaną dostępność cykliczną. Czy chcesz również zaktualizować swoją dostępność cykliczną?',
    'MursionPortal.ScenarioSearchList.Dialog.UpdateText': 'Chcę tylko zaktualizować swoją dostępność na {startDate}',
    'MursionPortal.ScenarioSearchList.Dialog.UpdateText.ToDate': 'Chcę zaktualizować moją cykliczną dostępność od {date}',
    'Clients.Modal.Label.UserRecordingConsent': 'Zgoda użytkownika na nagrywanie:',
    'Clients.Modal.Label.UserRecordingConsent.ToolTip': 'Zapytaj użytkownika o zgodę na nagrywanie',
    'MursionPortal.Notset': 'Nie ustawiono',
    'Clients.Modal.Label.VideoRecordingDataRetentionDays': 'Przechowywanie danych nagrań wideo (w dniach):',
    'Clients.Modal.Label.VideoRecordingDataRetentionPolicy': 'Zasady przechowywania danych dotyczących nagrań wideo:',
    'Clients.Modal.Label.UserML3SocialRecordingConsent': 'Zgoda użytkownika Mursion Social na nagrywanie:',
    'Clients.Modal.Label.UserML3SocialRecordingConsent.ToolTip': 'Poproś użytkownika o zgodę na nagrywanie. Jeśli ta opcja nie jest włączona, SIM Specialist zapyta o to ustnie.',
    'MursionPortal.LearnerSimSelector.Sim.Available.ToolTip': 'Certyfikowani SIM, którzy nie są zabookowani na sesję i są dostępni w wybranym terminie',
    'MursionPortal.LearnerSimSelector.Sim.Unavailable.ToolTip': 'Certyfikowani SIM, którzy nie są dostępni w systemie i nie są zabookowani na kolejną sesję w wybranym terminie',
    'Settings.Config.Client.VideoRecordingDataRetentionPolicy': 'Pokaż konfigurację zasad przechowywania danych nagrań wideo',
    'MursionPortal.DemandBasedScheduling.BackButton.BackToScenario': 'Powrót do scenariusza',
    'MursionPortal.DemandBasedScheduling.BackButton.BackToCalendar': 'Powrót do kalendarza',
    'SessionReports.VideoRecordingDeletedDueToDataRetentionPolicy': 'Ten film jest niedostępny ze względu na zasady przechowywania danych obowiązujące w Twojej firmie.',
    'SessionReports.VideoRecordingAvailabilityMessage': 'Ten film będzie można oglądać do {videoAvailabilityDate}',
    'SessionReports.SessionOrLearnerStatusError': 'Podczas tej sesji nie nagrano żadnego filmu z powodu błędu technicznego.',
    'Clients.ImportUsers.NextStepButton': 'Następny krok',
    'Clients.ImportUsers.CancelButton': 'Anuluj',
    'Clients.ImportUsers.UploadValidateButton': 'Prześlij + Sprawdź',
    'Clients.ImportUsers.DownloadErrorsButton': 'Pobierz błędy',
    'Session.Table.Column.Attendance': 'Frekwencja',
    'Session.Table.Column.Value.CompleteAttendance': 'Pełna frekwencja',
    'Session.Table.Column.Value.AttendanceComplete': 'Obecność zakończona',
    'Session.Table.Column.Value.NotApplicable': '(Nie dotyczy)',
    'Session.Table.Column.Survey': 'Ankieta',
    'Session.Table.Column.Value.CompleteGoogleForm': 'Wypełnij formularz Google',
    'Session.Table.Column.Value.CompleteSurvey': 'Wypełnij ankietę',
    'Session.Table.Column.Value.SurveyComplete': 'Ankieta zakończona',
    'Projects.Modal.Text.LearnerSelected': 'Należy wybrać co najmniej {maxLearners} uczniów',
    'Session.Table.Column.Value.SessionNotCompleted': 'Sesja nie została zakończona',
    'MursionPortal.Project.InviteToSchedule': 'Zaproś do zaplanowania',
    'MursionPortal.Project.InvitedToday': 'Zaproszeni dzisiaj',
    'MursionPortal.Project.YourInvitationsText': 'Twoje zaproszenia zostaną wysłane.',
    'MursionPortal.Project.InviteToSchedule.Tooltip': 'Zaproszenia do scenariusza zostały już wysyłane. Od jutra ponownie będzie można wysyłać zaproszenia.',
    'MursionPortal.Project.InvitedToday.Tooltip': 'Uczniowie, którzy nie zaplanowali ani nie anulowali/pominęli tego scenariusza, otrzymają e-mail z zaproszeniem do planowania.',
    'MursionPortal.Project.InviteMessage.SentDate': 'Ostatnio wysłane {sentDate}',
    'ITManager.TechRestriction.EditDialog.ProviderErrorMsg': 'Wybierz dostawcę',
    'ITManager.TechRestriction.EditDialog.GlobalRestriction': 'Ograniczenie globalne',
    'ITManager.TechRestriction.EditDialog.HolidayRestriction': 'Ograniczenie urlopowe',
    'ITManager.TechRestriction.Table.Provider': 'Dostawcy',
    'Session.Table.Column.Survey.HoverText': 'Jeśli ta opcja jest włączona, SIM otrzymają link do uzupełnienia opinii o uczniu',
    'MursionPortal.Project.InviteMessage.InvitedBy': 'Zaproszony przez {user} - {role}',
    'MursionPortal.Project.LearnersNotYetScheduled': '{learners} uczniów nadal musi zaplanować',
    'Session.Table.Column.Value.AttendanceRequired': 'Obecność wymagana',
    'MursionPortal.Button.Schedule.Another': 'Zaplanuj kolejne',
    'MursionPortal.EulaAgreement.CodeOfConduct': 'Każda symulacja Mursion obejmuje obecność jednego Simulation Specialist, który zapewnia dialogi mówione dla maksymalnie pięciu wirtualnych awatarów podczas sesji szkoleniowej. Zadaniem Simulation Specialist jest przedstawianie konwersacyjnych wyzwań, aby umożliwić uczniom, takim jak Ty, ćwiczenie złożonych umiejętności w każdym scenariuszu. W Mursion zdajemy sobie sprawę, jak ważne jest stworzenie bezpiecznej przestrzeni, w której ludzie mogą odważnie ćwiczyć potencjalnie stresujące, pełne niuansów i złożone rozmowy. Aby upewnić się, że zarówno uczniowie, jak i Simulation Specialist mogą w pełni uczestniczyć w zajęciach, prosimy osoby z obu grup o zobowiązanie się do przestrzegania podstawowego Kodeksu Postępowania.',
    'MursionPortal.EulaAgreement.CodeOfConductCheckBox.Text': 'Wyrażam zgodę na metodę dostarczania symulacji Mursion i potwierdzam przestrzeganie Kodeksu Postępowania Mursion we wszystkich symulacjach, w których biorę udział. Przeczytaj więcej o symulacjach Mursion i naszym Kodeksie Postępowania <code>tutaj</code>',
    'MursionPortal.EulaAgreement.CodeOfConduct.Header': 'Przegląd metodologii i Kodeks Postępowania',
    'MursionPortal.EulaAgreement.UserAgreement.Header': 'Zgoda użytkownika',
    'MursionPortal.Import.BackToUser': 'Powrót do użytkownika',
    'MursionPortal.Import.BackToTeam': 'Powrót do zespołu',
    'Mursion.Portal.Edit.Team.Upload.AccurateUploading': 'Przed przesłaniem upewnij się, że Twoje dane są prawidłowe.',
    'Mursion.Portal.Edit.Team.Upload.AskedReUpload': 'Jeśli w pliku występują błędy, zostaną one pokazane w arkuszu kalkulacyjnym analizy wyników w kroku 4. Postępuj zgodnie z instrukcjami w kroku 4, aby poprawić błędy w oryginalnym szablonie i ponownie prześlij plik.',
    'Mursion.Portal.Edit.Team.Upload.IncludeNewUser': 'Możesz także przyłączyć do zaimportowania nowych użytkowników oraz tych, których wcześniejszy import nie powiódł się.',
    'Mursion.Portal.Edit.Team.ReadyToUpload': 'Gdy plik będzie gotowy i odpowiednio sformatowany, prześlij go do sprawdzenia:',
    'Mursion.Portal.Edit.Team.UploadAndValidationFile': 'Przesyłanie + sprawdzanie poprawności pliku',
    'Mursion.Portal.Edit.Team.TeamAssignmentsUpdateSuccessfully': 'Pomyślnie zaktualizowano zadania zespołu',
    'Mursion.Portal.Edit.Team.UploadSuccessTitle': 'Zaimportowano {userCount} użytkowników!',
    'Mursion.Portal.Edit.Team.UploadFailureTitle': 'Błąd!',
    'Mursion.Portal.Edit.Team.UploadSuccess': 'Wszyscy użytkownicy zostali pomyślnie dodani do portalu.',
    'Mursion.Portal.Edit.Team.DoneButton': 'Zrobione',
    'Mursion.Portal.Edit.Team.DownloadSuccessful': 'Pobieranie pomyślne — przejdź do następnego kroku',
    'Mursion.Portal.Edit.Team.DownloadError': 'Błąd pobierania — spróbuj ponownie',
    'Mursion.Portal.Edit.Team.UploadFileAgain': 'Prześlij plik ponownie',
    'Mursion.Portal.Edit.Team.ContinueWithoutCorrecting': 'Kontynuuj bez poprawiania błędów',
    'Mursion.Portal.Edit.Team.SuccessfulImportTitle': 'Import zakończony pomyślnie',
    'Mursion.Portal.Edit.Team.UnSuccessfulImportTitle': 'Importowanie nie powiodło się',
    'Mursion.Portal.Import.User.HeaderTitle': 'Importuj użytkowników',
    'Mursion.Portal.Import.User.Upload.AskedReUpload': 'Jeśli w pliku występują błędy, zostaną one wykazane w arkuszu kalkulacyjnym analizy wyników w kroku 4. Postępuj zgodnie z instrukcjami w kroku 4, aby poprawić błędy w <strong>oryginalnym szablonie</strong> i prześlij plik ponownie.',
    'Mursion.Portal.Import.User.ReadyToUpload': 'Gdy plik będzie gotowy i odpowiednio sformatowany, prześlij go do weryfikacji:',
    'Mursion.Portal.Import.User.ValidatingYourDataUntilTheUpload': 'Weryfikujemy dane i importujemy użytkowników. Sprawdź ponownie za 15 minut lub po otrzymaniu powiadomienia o zakończeniu tego procesu.',
    'Mursion.Portal.Import.User.UploadSuccessTitle': 'Zaimportowano {successCount} użytkowników!',
    'Mursion.Portal.Import.User.ErrorContinueTitle': 'Kontynuuj bez poprawiania błędów!',
    'Mursion.Portal.Import.User.ErrorContinue': '{failedCount} users failed to import as the errors were not fixed.', // TODO add translations
    'Settings.ScenarioBank.Form.ArtBundlesWarning': 'Zmień nazwy pakietów artystycznych z nazw domyślnych',
    'Mursion.Portal.Import.User.Instruction.Upload': 'Kliknij <strong>pobierz szablon programu Excel</strong> poniżej i otwórz pobrany plik (<strong>client_internal_users.xlsx</strong> lub <strong>client_external_users.xlsx</strong>).',
    'Mursion.Portal.Import.User.Instruction.Format': '<strong>W szablonie pola adresu e-mail, roli i ID SSO są wymagane — nie pozostawiaj ich pustych. Dodaj poprawne informacje o użytkowniku pod każdym nagłówkiem.</strong> NIE zmieniaj nagłówków, struktury szablonu ani nazwy pliku, ponieważ może to spowodować niepowodzenie importu.',
    'Mursion.Portal.Import.User.Instruction.NewUser': 'W <strong>Kolumnach J-S lub F-O</strong> dodaj nazwy zespołów, do których użytkownik ma zostać przypisany (upewnij się, że pisownia dokładnie odpowiada nazwie).',
    'Mursion.Portal.Import.User.Instruction.Error': 'Po wprowadzeniu wszystkich użytkowników zapisz plik na pulpicie lub w innym miejscu, o którym będziesz pamiętać i kliknij <strong>kontynuuj</strong>.',
    'Mursion.Portal.Import.User.Instruction.OriginalTemplate': 'Jeśli w pliku występują błędy, zostaną one wykazane w arkuszu kalkulacyjnym analizy wyników w kroku 4. Postępuj zgodnie z instrukcjami, aby poprawić błędy w <strong>oryginalnym szablonie</strong> i prześlij plik ponownie.',
    'Mursion.Portal.Import.User.Instruction.UploadList': 'Jeśli zdecydujesz się kontynuować bez naprawiania błędów, plik <strong>lista nieudanych prób przesyłania</strong> będzie dostępny tylko przez 7 dni.',
    'Mursion.Portal.Import.User.DownloadExcelTemplate': 'Pobierz szablon Excel',
    'Mursion.Portal.Import.User.UploadFileStatus': 'Przesyłanie pliku',
    'Mursion.Portal.Import.User.DoNotCloseUntilUpload': 'Weryfikujemy dane i importujemy użytkowników. Ten proces będzie kontynuowany w tle, więc możesz przejść do innej karty lub okna i wrócić do tego ekranu w dowolnym momencie. Proces importowania powinien zająć mniej niż 5 minut.',
    'Mursion.Portal.Import.User.FileStatus.validation': 'Importowanie użytkowników',
    'Mursion.Portal.Import.User.CorrectErrorSubHeading': 'Ostatni krok: Popraw błędy',
    'Mursion.Portal.Import.User.SuccessCount': '{successCount} users were successfully imported; {failedCount} users failed to import – please review.', // TODO add translations
    'Mursion.Portal.Import.User.DownloadFile': 'Click the <strong>Download Errors</strong> button and open the file to view the errors.', // TODO add translations
    'Mursion.Portal.Import.User.FileTabs': '<strong>W szablonie pola adresu e-mail i roli są wymagane — nie pozostawiaj ich pustych</strong>. NIE zmieniaj nagłówków, struktury szablonu ani nazwy pliku, ponieważ może to spowodować niepowodzenie importu.',
    'Mursion.Portal.Import.User.ReviewCorrect': 'Popraw błędy w <strong>oryginalnym szablonie</strong> (<strong>client_internal_users.xlsx</strong> lub <strong>client_external_users.xlsx</strong>) i prześlij plik ponownie.',
    'Mursion.Portal.Import.User.DownloadErrorButton': 'Pobierz błędy',
    'Mursion.Portal.Edit.Team.EditTeamTitle': 'Edytuj członkostwa w zespole',
    'Mursion.Portal.Edit.Team.Instruction.ExportFile': 'Kliknij <strong>pobierz bieżące członkostwa</strong> poniżej, aby zaktualizować zadania zespołu dla uczniów (<strong>edit_learner_team_memberships.xlsx</strong>).',
    'Mursion.Portal.Edit.Team.Instruction.UpdateFile': 'W <strong>kolumnach od L do U</strong> dodaj lub usuń nazwy zespołów, do których uczeń powinien zostać przypisany (tj. zespołów, do których ma zostać dodany lub zaktualizowany), po jednej kolumnie na zespół. W razie potrzeby możesz pozostawić niektóre kolumny zespołu puste. Upewnij się, że zostało to wykonane poprawnie, ponieważ <strong>spowoduje to zastąpienie istniejących przypisań do zespołów</strong>.',
    'Mursion.Portal.Edit.Team.Instruction.Process': 'Przesyłaj jeden plik na raz — ogranicz liczbę użytkowników do 5000 na plik.',
    'Mursion.Portal.Edit.Team.Instruction.ProfileFields': 'Pola adresu e-mail i roli są wymagane — nie pozostawiaj ich pustych. Nie zmieniaj nagłówków, struktury szablonu ani nazwy pliku, ponieważ może to spowodować niepowodzenie importu.',
    'Mursion.Portal.Edit.Team.Instruction.ErrorOccur': 'Obsługiwane formaty plików to tylko .xlsx.',
    'Mursion.Portal.Edit.Team.DownloadExcelTemplate': 'Pobierz aktualne członkostwa',
    'Mursion.Portal.Edit.Team.DoNotCloseUntilUpload': 'Weryfikujemy dane i aktualizujemy przydziały zespołów. Ten proces będzie kontynuowany w tle, więc możesz przejść do innej karty lub okna i wrócić do tego ekranu w dowolnym momencie. Proces powinien zająć mniej niż 5 minut.',
    'Mursion.Portal.Edit.Team.FileStatus.validation': 'Aktualizowanie członkostw w zespole',
    'Mursion.Portal.Edit.Team.UploadFailureNotification': 'Twój plik zawiera ponad 5 tys. użytkowników. Skontaktuj się z pomocą techniczną lub prześlij kilka osobnych plików z liczbą użytkowników mniejszą niż 5 tys.',
    'Mursion.Portal.Edit.Team.ReviewCorrect': '<strong>W szablonie pola adresu e-mail i roli są wymagane — nie pozostawiaj ich pustych.</strong> NIE zmieniaj nagłówków, struktury szablonu ani nazwy pliku, ponieważ może to spowodować niepowodzenie importu.',
    'Mursion.Portal.Edit.Team.DownloadFile': 'Popraw błędy w oryginalnym pliku szablonu (<strong>edit_learner_team_memberships.xlsx</strong>) – NIE zmieniaj nagłówków, struktury szablonu ani nazwy pliku, ponieważ może to spowodować niepowodzenie importu.',
    'Mursion.Portal.Edit.Team.DownloadErrorButton': 'Pobierz błędy',
    'Mursion.Settings.ScenarioBank.Modal.ChecklistURL': 'Adres URL listy kontrolnej SIM',
    'Mursion.Settings.ScenarioBank.Modal.MustBeAValidURL': 'Adres URL musi być prawidłowy',
    'MursionPortal.CodeOfConduct.SchedulingFlow.Text': 'Decydując się na zaplanowanie sesji Mursion, zgadzasz się na metodę dostarczania symulacji Mursion i potwierdzasz, że będziesz przestrzegać i szanować Kodeks Postępowania Mursion. Przeczytaj więcej <code>tutaj</code>.',
    'Mursion.Portal.Import.User.InvalidFile': 'Zostanie przesłany tylko szablon csv lub excel.',
    'Mursion.Portal.Import.User.MaxFileSize': 'Rozmiar pliku nie może przekraczać 5 MB',
    'Mursion.Portal.Import.User.Tooltip': 'Kliknij tutaj, aby zaimportować nowych użytkowników lub zaktualizować istniejących użytkowników z pliku eksportu.',
    'Mursion.Portal.Edit.Team.Tooltip': 'Kliknij tutaj, aby zbiorczo przypisać lub zmienić przypisanie członkostwa w zespole.',
    'MursionPortal.Label.Completion': 'Stopień ukończenia',
    'MursionPortal.Project.InviteLearnersToSchedule': 'Zaproś uczniów do planowania',
    'MursionPortal.CheckboxLabel.SchedulingByBookIt': 'Planowanie przez BookIt',
    'Projects.ProjectInfo.CheckBox.ScenarioSequencing': 'Sekwencjonowanie scenariuszy',
    'Projects.ProjectInfo.CheckBox.ScenarioSequencing.Text': 'Kliknij przycisk, aby wyświetlić i skonfigurować kolejność scenariuszy w tym projekcie.',
    'Projects.ProjectInfo.Button.ScenarioSequence': 'Sekwencja scenariusza',
    'MursionPortal.Projects.DragAndDropModal.Text': 'Przeciągnij i upuść scenariusze w preferowanej kolejności:',
    'MursionPortal.Projects.DragAndDropModal.DragHandlerImage': 'Obraz uchwytu przeciągania',
    'MursionPortal.Projects.DragAndDropModal.ModalCloseIcon': 'Zamknij ikonę',
    'MursionPortal.Project.Scheduled.NextDate': 'Zaplanowano na {nextDate}',
    'Dashboard.Report.SendReport': 'Wyślij raport',
    'Dashboard.Report.ScheduleReport': 'Zaplanuj raport',
    'Dashboard.Report.EditSchedule': 'Edytuj harmonogram',
    'Dashboard.Report.ViewSchedule': 'Zobacz harmonogram',
    'Dashboard.Report.DeleteSchedule': 'Usuń harmonogram',
    'Dashboard.Report.Filter.Title': 'Wybrane filtry',
    'Dashboard.Report.SelectReportFrequency.Label': 'Wybierz częstotliwość raportów',
    'Dashboard.Report.SelectSessions.Label': 'Wybierz sesje do uwzględnienia',
    'Dashboard.Report.SelectReportStartDate.Label': 'Wybierz datę rozpoczęcia raportu',
    'Dashboard.Report.SelectReportStartDate.Tooltip': 'Jeśli wybrałeś cotygodniowe otrzymywanie raportów, raport będzie przychodził co tydzień w wybranym przez Ciebie dniu. Jeśli wybrałeś otrzymywanie raportów co miesiąc, będzie przychodzić w tym dniu co miesiąc.',
    'Dashboard.Report.SelectAccountOwner.Label': 'Wybierz account ownera lub facilitatora',
    'Dashboard.Report.Dropdown.Select.Placeholder': 'Wybierz',
    'Dashboard.Report.ManuallyAddRecipients.Label': 'Dodaj odbiorców ręcznie',
    'Dashboard.Report.ManuallyAddRecipients.Label.Placeholder': 'Dodaj e-mail tutaj',
    'Dashboard.Report.RecipientsBox.Label': 'Odbiorcy',
    'Dashboard.Report.RecipientsBox.RecipientMaxLimit': 'Odbiorcy nie mogą mieć więcej niż 30 lat',
    'Dashboard.Report.SendReport.ConfirmationModal.Text': 'Raport został wysłany do odbiorców.',
    'Dashboard.Report.ScheduleReport.ConfirmationModal.HeadingText': 'Twój raport został zaplanowany!',
    'Dashboard.Report.ScheduleReport.ConfirmationModal.Text': 'Zatrzymaj lub edytuj w dowolnym momencie, klikając <strong>Edytuj harmonogram.</strong>',
    'Dashboard.Report.EditSchedule.ConfirmationModal.HeadingText': 'Zmiany zostały zapisane!',
    'Dashboard.Report.EditSchedule.ConfirmationModal.Text': 'Zatrzymaj raporty w dowolnym momencie, klikając <strong>Edytuj harmonogram.</strong>',
    'Dashboard.Report.DeleteSchedule.ConfirmationModal.Text': 'Harmonogram został usunięty',
    'Dashboard.Report.DeleteSchedule.ConfirmationModal.ConfirmationText': 'Czy na pewno chcesz usunąć harmonogram dla tego raportu?',
    'Dashboard.Report.ConfirmationModal.Button': 'Rozumiem!',
    'Dashboard.Report.DeleteSchedule.ConfirmationButton': 'Tak, usuń',
    'Dashboard.Report.EditSchedule.EditButton': 'Zapisz zmiany w harmonogramie',
    'Dashboard.Report.ReportFrequencyOption.Daily': 'CODZIENNIE',
    'Dashboard.Report.ReportFrequencyOption.Weekly': 'CO TYDZIEŃ',
    'Dashboard.Report.ReportFrequencyOption.Monthly': 'CO MIESIĄC',
    'Dashboard.Report.ReportSessionOption.AllDays': 'Wszystkie sesje',
    'Dashboard.Report.ReportSessionOption.PreviousDay': 'Sesje z dnia poprzedniego',
    'Dashboard.Report.ReportSessionOption.Previous7Days': 'Sesje z ostatnich 7 dni',
    'Dashboard.Report.ReportSessionOption.CurrentSessions': 'Sesje w dniu bieżącym',
    'Dashboard.Report.ReportSessionOption.Next7Days': 'Sesje w ciągu najbliższych 7 dni',
    'Dashboard.Report.ReportSessionOption.MonthToDate': 'Sesje od początku miesiąca',
    'Dashboard.Report.ReportSessionOption.PreviousFullMonth': 'Sesje z poprzedniego pełnego miesiąca',
    'Dashboard.Report.ReportSessionOption.Previous30Days': 'Sesje z ostatnich 30 dni',
    'MursionPortal.Project.Sequencing': 'Sekwencjonowanie',
    'MursionPortal.Project.Checkbox.Sequencing': 'Włącz sekwencjonowanie',
    'MursionPortal.Project.InviteToScheduleProject.Tooltip': 'Uczniowie, którzy nie zaplanowali lub nie anulowali/pominęli scenariuszy w tym projekcie, otrzymają e-mail z zaproszeniem do ich zaplanowania.',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts': 'Skróty klawiszowe',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.SpaceBar': 'Klawisz spacji',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.SpaceBar.Text': 'Wybierz lub upuść scenariusz.',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.ArrowKeys': 'Klawisze strzałek w prawo i w lewo lub w górę i w dół',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.ArrowKeys.Text': 'Przeciągnij scenariusz w lewo i w prawo.',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Esc': 'klawisz Esc',
    'MursionPortal.Esc': 'Esc',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Esc.Text': 'Zamknij sekwencję scenariusza modalnego',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Enter': 'Przycisk Enter',
    'MursionPortal.Projects.DragAndDropModal.KeyboardShortcuts.Enter.Text': 'Zamknij podpowiedzi skrótów klawiaturowych',
    'MursionPortal.Projects.DragAndDropModal.Tooltip': 'Otwórz podpowiedzi skrótów klawiaturowych',
    'Dashboard.Report.DuplicateEntry.ErrorMessage': 'Duplikat wpisu jest niedozwolony',
    'Projects.ProjectInfo.EnableSessionTimeStep': 'Włącz przedział czasowy sesji',
    'Projects.ProjectInfo.SessionTimeStepInfo': 'Przedział czasowy sesji na poziomie licencjobiorcy zostanie zastosowany, jeśli wartość przedziału czasowego sesji nie zostanie ustawiona na poziomie projektu',
    'MursionPortal.Selector.RequestStatus': 'Status prośby',
    'MursionPortal.Dashboard.RetakeSimulation': 'Wykonaj symulację ponownie',
    'MursionPortal.Dashboard.NextSimulationAssigned': 'Przydzielono następną symulację',
    'MursionPortal.AriaLabel.SelectTime': 'Wybierz czas',
    'Dashboard.Report.Frequency.Date.Select.Placeholder': 'Wybierz datę rozpoczęcia',
    'MursionPortal.InstructoScenarioTeamMapping.Header': 'Wybierz scenariusz i drużynę, którą chcesz zmapować',
    'MursionPortal.InstructoScenarioTeamMapping.MappingAlreadyExistMessage': 'Ten scenariusz i zespół zostały już zmapowane do innego kontekstu.',
    'MursionPortal.DataSummaryPoints.manualFulFilled': 'Wypełnione ręcznie',
    'MursionPortal.DataSummaryPoints.pendingRequest': 'Oczekujące prośby oparte na zapotrzebowaniu',
    'MursionPortal.DataSummaryPoints.declined': 'Prośby odrzucone',
    'MursionPortal.DataSummaryPoints.avgFulfilled': 'Średni czas wypełniania dla trybu ręcznego',
    'MursionPortal.DataSummaryPoints.totalSwap': 'Wszystkie zamiany',
    'MursionPortal.DataSummaryPoints.swapAccepted': 'Zaakceptowane zamiany',
    'MursionPortal.Dashboard.MissedSimulations': 'Pominięte symulacje',
    'MursionPortal.Dashboard.RescheduledSimulations': 'Przełożone symulacje',
    'MursionPortal.Dashboard.BookedSimulations': 'Zabookowane symulacje',
    'MursionPortal.Dashboard.CancelledSimulations': 'Anulowane symulacje',
    'Dashboard.UserTable.Column.In': 'W',
    'Projects.ProjectInfo.ConfirmationDialog.ScenarioSequencing.Text': 'Czy na pewno chcesz wyłączyć sekwencjonowanie? Spowodowałoby to również usunięcie numeracji sekwencyjnej w każdym scenariuszu.',
    'Projects.ProjectInfo.Disable.Sequencing': 'Wyłącz sekwencjonowanie',
    'MursionPortal.ConfirmationModal.Project.InvitationsConfirmationText': 'Czy na pewno chcesz zaprosić <strong>wszystkich przypisanych uczniów</strong>, którzy nie ukończyli jeszcze scenariuszy w projekcie? Otrzymają e-mail z zaproszeniem do umówienia się.',
    'MursionPortal.ConfirmationModal.Scenario.InvitationsConfirmationText': 'Czy na pewno chcesz zaprosić <strong>wszystkich przypisanych uczniów</strong>, którzy jeszcze nie ukończyli tego scenariusza? Otrzymają e-mail z zaproszeniem do umówienia się.',
    'MursionPortal.ErrorMessage.TechnicalIssueMsg': 'Proszę spróbuj ponownie. Wystąpił problem techniczny.',
    'SessionFlow.Text.Event.DeletedByUsername': 'Usunięte przez {user}',
    'MursionPortal.Dashboard.NextOnProject': 'Scenariusze do zaplanowania',
    'MursionPortal.Dashboard.NextOnProject.Footer.EndDate': 'Masz czas do {endDate} na ukończenie tego scenariusza.',
    'MursionPortal.Dashboard.NextOnProject.Footer.CompletionRate': 'Masz czas do {endDate}, aby ukończyć maksymalnie {completionRate} razy.',
    'Settings.ScenarioBank.Modal.timestepShouldBeAtLeast': 'Usuń zaznaczenie opcji „Włącz przedział czasowy sesji” lub wprowadź wartość przeedziału czasowego wynoszącą co najmniej {minutes} minut',
    'MursionPortal.Dashboard.NothingPlanned.text1': 'Jesteś na bieżąco! Kiedy będziesz mieć',
    'MursionPortal.Dashboard.NothingPlanned.text2': 'coś do zaplanowania, pojawi się tutaj.',
    'MursionPortal.Learner.Missed': 'UCZEŃ POMINIĘTY',
    'MursionPortal.AriaImage.Attribute.Previous': 'Poprzednie',
    'MursionPortal.AriaImage.Attribute.Next': 'Dalej',
    'MursionPortal.AriaImage.Attribute.Close': 'Zamknij',
    'MursionPortal.AriaImage.Attribute.Back': 'Wstecz',
    'MursionPortal.AriaImage.Attribute.Delete': 'Usuń',
    'MursionPortal.AriaImage.Attribute.Notify': 'Powiadom',
    'MursionPortal.AriaImage.Attribute.Upload': 'Prześlij',
    'MursionPortal.AriaImage.Attribute.Remove': 'Usuń',
    'MursionPortal.AriaImage.Attribute.Document': 'Dokument',
    'MursionPortal.AriaImage.Attribute.Video': 'Wideo',
    'MursionPortal.AriaImage.Attribute.DeleteDocument': 'Usuń dokument',
    'MursionPortal.Project.InviteToSchedule.Sequencing.Tooltip': 'Sekwencjonowanie dla tego scenariusza jest ustawione i żaden uczeń nie zaplanował jeszcze poprzedniego scenariusza.',
    'MursionPortal.Report.FilterHeading.SoftwareVersions': 'Wersje software',
    'MursionPortal.Report.FilterHeading.DeliveryModes': 'Metody dostawy',
    'MursionPortal.Report.FilterHeading.LearnerSessionStatuses': 'Statusy sesji ucznia',
    'MursionPortal.Report.FilterHeading.LearnerStatuses': 'Statusy uczniów',
    'MursionPortal.Report.FilterHeading.SimulationStatus': 'Status symulacji',
    'MursionPortal.Project.EmailMissedLink.TooLateJoin.ClickRescheduleTxt': 'Kliknij poniżej, aby przełożyć symulację.',
    'MursionPortal.ScenarioInfo.Label.Until': 'Masz czas do {date}, aby wykonać to maksymalnie {completionRate} razy.',
    'MursionPortal.ScenarioInfo.CompletionRate': '{completionRate} razy',
    'Clients.Modal.Button.Schedule.Tooltip.Text': 'Najpierw musisz zaplanować poprzedni scenariusz',
    'MursionPortal.Scenario.Name': 'Nazwa scenariusza',
    'MursionPortal.ScenarioCard.BackToProjects.Button': 'Powrót do projektów',
    'MursionPortal.ScenarioCard.ViewMore.Button': 'Zobacz więcej',
    'MursionPortal.Project.DuplicateProjectBtnTooltip': 'Spowodowałoby to zduplikowanie wszystkich ustawień i konfiguracji tego projektu do nowego projektu',
    'MursionPortal.Project.DuplicateProjectBtnText': 'Duplikuj do Magic Project',
    'MursionPortal.Project.MissedAlert.TooLateJoin': 'Przybyłeś trochę za późno, aby dołączyć',
    'MursionPortal.Project.MissedAlert.DontWorry': 'Nie martw się! Zdarza się.',
    'MursionPortal.Project.MissedAlert.ClickReschedule': 'Kliknij przełóż symulację.',
    'MursionPortal.ScenarioInfo.Documents': 'Dokumenty dotyczące scenariusza',
    'MursionPortal.ScenarioInfo.Documents.File': 'Plik {extension}',
    'MursionPortal.ScenarioCard.ViewSessions': 'Zobacz sesje',
    'MursionPortal.Label.ScenarioPreview': 'Podgląd scenariusza',
    'MursionPortal.ScenarioInfo.AssignedTeams': 'Wyszukaj lub wybierz zespoły:',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialists': 'Certyfikowani SIM Specialists',
    'MursionPortal.ScenarioInfo.AddSimSpecialist': 'Dodaj Sim Specialist',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.PencilIcon': 'Edytuj ikonę',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.DeleteButton': 'Usuń SIM',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.SearchText': 'Wyszukaj lub wybierz SIM Specialists:',
    'MursionPortal.ScenarioInfo.CertifiedSIMSpecialist.AddSim': 'Dodaj Sim',
    'MursionPortal.Label.InactiveSIMSpecialists': 'Nieaktywni SIM Specialiści',
    'MursionPortal.Label.Search.InactiveSIMSpecialists': 'Wyszukaj lub wybierz nieaktywnych SIM Specialists:',
    'MursionPortal.ErrorMessage.InvalidCaptcha': 'Niepoprawna Captcha. Proszę spróbuj ponownie.',
    'MursionPortal.Password.Strength.Tooweak': 'Hasło jest za słabe',
    'MursionPortal.Password.Strength.Weak': 'Hasło jest słabe',
    'MursionPortal.Password.Strength.Medium': 'Hasło jest średnie',
    'MursionPortal.Password.Strength.Strong': 'Hasło jest silne',
    'Clients.Modal.Label.FacilitatorView.Tooltip': 'W przypadku ustawienia na poziom klienta facilitatorzy będą mogli zobaczyć szczegóły poziomu account ownera. W przypadku ustawienia na poziom zespołu facilitator widzi tylko informacje o zespołach, do których jest przypisany.',
    'Clients.Modal.Label.VoiceAnalytics.Tooltip': 'Zapewnia uczniom automatyczną analizę ich sesji.',
    'Clients.Modal.Label.Show.Utilization.Reporting.Tooltip': 'Dodaje raporty wykorzystania do pulpitu nawigacyjnego Account Ownera.',
    'Clients.Modal.Label.VideoRecordingDataRetentionDays.Tooltip': 'Liczba dni, przez które klient chciałby przechowywać swoje dane nagrań wideo. Po upływie określonej liczby dni nagrania wideo zostaną usunięte.',
    'Clients.Modal.Label.VideoRecordingDataRetentionPolicy.Tooltip': 'Włącz, jeśli klient ma niestandardowe zasady przechowywania danych.',
    'MursionPortal.Button.RefreshPage': 'Odśwież stronę',
    'MursionPortal.Prompt.CalendarUpdatedOutdatedNotification': 'Czy na pewno chcesz zamknąć, strona kalendarza pozostanie nieaktualna.',
    'Reports.SessionListHeader.Label.AverageTime': 'Średni czas <code>w każdej sesji</code>',
    'MursionPortal.Users.Archive.HasSessions': 'Nie można zarchiwizować Simulation Specialist, ponieważ dla tego Simulation Specialist zaplanowano co najmniej jedną sesję. Proszę zamień lub zmień Sim Specialist.',
    'MursionPortal.Label.InactiveSIMCertification.Tooltip': 'Użyj, jeśli w tej symulacji jest Sim, który posiada certyfikat, ale który nie jest dostępny do nowych symulacji.',
    'MursionPortal.Label.InactiveSIMCertification': 'Nieaktywna certyfikacja Sim',
    'Dashboard.Filters.FilterBySessionDate': 'FILTRUJ WEDŁUG DATY SESJI',
    'Dashboard.LeftPane.CategoryHeader.Emails': 'E-MAILE',
    'MursionPortal.Label.SoftwareType.Tooltip': '„Tylko Web” oznacza, że uczniowie mogą uzyskiwać dostęp do swoich sesji tylko za pośrednictwem Mursion Magic, platformy opartej na przeglądarce.',
    'Projects.ProjectInfo.Label.CancellationDeadline': 'Termin anulowania',
    'Projects.ProjectInfo.Label.CancellationDeadline.Tooltip': 'Jest to liczba dni/godzin/minut, które uczeń musiałby anulować po zarejestrowaniu się do symulacji w ramach projektu. Jeśli uczestnicy anulują w wyznaczonym terminie, sesja zostanie uznana za zakończoną do celów rozliczeniowych.',
    'Projects.ProjectInfo.Checkbox.AllowSessionRecording': 'Zezwól na nagrywanie sesji',
    'Projects.ProjectInfo.Checkbox.AllowSessionRecording.Tooltip': 'Usuń zaznaczenie, aby zapobiec nagrywaniu.',
    'MursionPortal.Label.SendRecordedVideosTo': 'Wyślij nagrane filmy do:',
    'MursionPortal.Checkbox.AllowLearnerToRefuseRecording': 'Zezwól uczniowi na dołączenie do sesji, jeśli uczeń nie wyraża zgody na nagrywanie',
    'Projects.ProjectInfo.label.Tags.Tooltip': 'Tagi to grupy organizacyjne umożliwiające szybkie znajdowanie podobnych projektów na poziomie klienta.',
    'Projects.ProjectInfo.label.DemandTime.Tooltip': 'Ramy czasowe, w których uczniowie mogą zażądać symulacji dla wybranych przedziałów czasowych',
    'Projects.ProjectInfo.label.DemandSlots.Tooltip': 'Ustawia liczbę opcji, które uczeń widzi podczas planowania. Jeśli ustawione jest na zero, uczeń będzie mógł natychmiast zarezerwować wolny przedział czasowy.',
    'Projects.ProjectInfo.label.Rescheduling.Tooltip': 'Umożliwia uczniom przełożenie sesji. Należy pamiętać, że uczeń nie może zarezerwować sesji na ten sam dzień, chyba że zaznaczono opcję ten sam dzień.',
    'MursionPortal.Project.SameDay.Tooltip': 'Umożliwia uczniom przełożenie na później w tym samym dniu kalendarzowym.',
    'Projects.ProjectInfo.Label.Timeline.Tooltip': 'Wszystkie sesje dla tego projektu muszą zostać zakończone w ramach osi czasu projektu.',
    'MursionPortal.Checkbox.AllowLearnerToRefuseRecording.Tooltip': 'Uczniowie mogą zrezygnować z nagrywania. Jeśli opcja ta nie jest zaznaczona, a uczeń odmówi zgody na nagrywanie, nie będzie mógł uczestniczyć w sesji.',
    'Projects.ProjectInfo.SchedulingInterval': 'Interwał planowania',
    'Projects.ProjectInfo.SchedulingInterval.Tooltip': 'Umożliwia planowanie zgodnie z rytmem innym niż czas trwania sesji.',
    'Projects.ProjectInfo.SchedulingIntervalLength': 'Planowanie długości interwału',
    'Projects.ProjectInfo.SchedulingIntervalLength.tooltip': 'Określa częstotliwość bloków do zaplanowania, które widzi uczeń. Jeśli ustawiono 15, uczniowie zobaczą bloki do zaplanowania w odstępach 15-minutowych. Nie ustawia to czasu trwania sesji, tylko częstotliwość uruchamiania sesji. Przedział czasowy dla sesji na poziomie licencjobiorcy zostanie użyty, jeśli wartość przedziału czasowego sesji nie zostanie ustawiona na poziomie projektu',
    'Projects.ProjectInfo.DeliveryMode.tooltip': 'Platforma używana przez Twoich uczniów. Ustaw na ML3, jeśli chcesz włączyć software Mursion Magic i/lub ML3 do bezpośredniego połączenia. ML3z = Zoom. Mursion Meet = Google Meet.',
    'MursionPortal.Label.WebOnly': 'Tylko Web',
    'MursionPortal.Label.DesktopOnly': 'Tylko komputer stacjonarny',
    'MursionPortal.Label.DesktopAndWeb': 'Komputer stacjonarny i Web',
    'MursionPortal.EmailCategory.CallsToAction': 'Wezwania do działania',
    'MursionPortal.Dashboard.EmailAnalytics.Label.Sent': 'Wysłano',
    'MursionPortal.Dashboard.EmailAnalytics.Label.AllEmails': 'Wszystkie e-maile',
    'MursionPortal.Dashboard.EmailAnalytics.Label.Trends': 'Trendy',
    'MursionPortal.Dashboard.EmailAnalytics.Label.CategorySummary': 'Podsumowania kategorii',
    'MursionPortal.Dashboard.EmailAnalytics.Label.RegistrationEmail': 'E-maile rejestracyjne',
    'MursionPortal.Dashboard.EmailAnalytics.Label.CallToActionEmail': 'E-maile wzywające do działania',
    'MursionPortal.Dashboard.EmailAnalytics.Label.SchedulingEmail': 'Planowanie e-maili',
    'MursionPortal.Dashboard.EmailAnalytics.Label.ReSchedulingEmail': 'Zmiana harmonogramu wiadomości e-mail',
    'MursionPortal.Dashboard.EmailAnalytics.Label.VideoEmail': 'E-maile wideo',
    'MursionPortal.Dashboard.EmailAnalytics.Label.EmailSent': 'E-mail wysłany',
    'MursionPortal.Dashboard.EmailAnalytics.Info.EmailAnalytics': 'Analiza poczty e-mail zapewnia przegląd tego, w jaki sposób uczniowie angażują się w <link>komunikację e-mail od Mursion</link>. E-maile mogą należeć do jednej z trzech poniższych kategorii',
    'MursionPortal.Dashboard.EmailAnalytics.Info.EmailOpened': 'Uczeń otworzył jeden lub więcej wysłanych do niego e-maili.',
    'MursionPortal.Dashboard.EmailAnalytics.Info.EmailActedOn': 'Uczeń otworzył wiadomość e-mail i kliknął jeden lub więcej linków, np. <strong>Zaplanuj</strong> lub <strong>Dołącz do symulacji</strong>.',
    'MursionPortal.Dashboard.EmailAnalytics.Info.Unread': 'Uczeń nie otworzył wiadomości e-mail. Jeśli istnieje duża liczba nieprzeczytanych wiadomości e-mail, potwierdź, że Twoi uczniowie je otrzymują. Skontaktuj się z zespołem IT, aby upewnić się, że adres <color>no-reply@mursion.com</color> nie jest blokowany przez filtry spamu Twojej organizacji.',
    'MursionPortal.Dashboard.Tooltip.RegisterEmail': 'E-maile do użytkownika z zaproszeniem do utworzenia Konta Mursion',
    'MursionPortal.Dashboard.Tooltip.CallToActionEmail': 'Wiadomości e-mail z przypomnieniem wysyłane przez Account Ownera ze strony projektu lub scenariusza',
    'MursionPortal.Dashboard.Tooltip.SchedulingEmail': 'E-maile dotyczące danej sesji. Zaproszenie do planowania i inne wiadomości e-mail dotyczące dołączenia do sesji',
    'MursionPortal.Dashboard.Tooltip.ReschedulingEmail': 'Wiadomości e-mail z monitem o zmianę terminu po pominiętej lub anulowanej symulacji',
    'MursionPortal.Dashboard.Tooltip.VideoEmail': 'E-maile informujące ucznia, że film z jego symulacji jest dostępny do obejrzenia',
    'MursionPortal.Dashboard.EmailCategoryFilter': 'Kategoria e-mail',
    'MursionPortal.Dashboard.EmailCategoryFilter.Search': 'Wyszukaj kategorie e-maili...',
    'MursionPortal.Dashboard.EmailTypeFilter.SessionRescheduled': 'Zmiana terminu sesji',
    'MursionPortal.Dashboard.EmailTypeFilter.Search': 'Wyszukaj typy e-maili...',
    'MursionPortal.Text.NoImageAvailable': 'Obraz niedostępny',
    'MursionPortal.Project.CancelationDeadline.Validation': 'Termin anulowania powinien być większy niż 0.',
    'ClientUserTable.AddNewUsers.RolesHeading': 'Możesz dodać tylko jednego rodzaju użytkownika na raz. Wybierz rodzaj użytkownika, którego chcesz dodać.',
    'ClientUserTable.AddNewUsers.Roles.AccountOwner.Tooltip': 'Account Owner jest głównym administratorem całej Twojej organizacji.',
    'ClientUserTable.AddNewUsers.Roles.Facilitator.Tooltip': 'Facilitator to administrator na poziomie zespołu, który zarządza określonym zespołem lub zespołami.',
    'ClientUserTable.AddNewUsers.Roles.Learner.Tooltip': 'Uczeń to każdy, kto bierze udział w symulacji Mursion. To będzie większość Twojej bazy użytkowników.',
    'ClientUserTable.AddNewUsers.Heading': 'Dodaj nowych użytkowników',
    'ClientUserTable.AddNewUsers.Roles.Heading': 'Przypisz role użytkownikom',
    'ClientUserTable.AddNewUsers.ImportUsers': 'Wykonaj poniższe czynności dla maksymalnie 500 użytkowników. Jeśli importujesz więcej niż 500 użytkowników, skorzystaj z funkcji „<code>Importuj użytkowników</code>”.',
    'ClientUserTable.AddNewAttributes.label': 'Dodaj atrybuty użytkownika',
    'ClientUserTable.AddNewAttributes.Header.Text': 'Pamiętaj, że te atrybuty zostaną zastosowane do wszystkich użytkowników na tej liście.',
    'ClientUserTable.UserAuthentication.label': 'Uwierzytelnianie użytkownika',
    'ClientUserTable.NewUsersAdded.Heading': 'Dodano nowych użytkowników!',
    'ClientUserTable.AddNewUsers.AssignTeams.Heading': 'Przypisz zespoły do użytkowników',
    'ClientUserTable.AddNewUsers.AssignTeams.CreateTeam': 'Uczestnicy i facilitatorzy muszą być przypisani do zespołu. Jeśli nie utworzyłeś jeszcze zespołu, <code>kliknij tutaj.</code>',
    'ClientUserTable.AddNewUsers.AssignTeams.SelectTeams': 'Wybierz zespoły',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Validation': 'Dodaj 500 lub mniej użytkowników. Aby zaimportować ponad 500 użytkowników, możesz skorzystać z naszej funkcji „Importuj użytkowników”.',
    'ClientUserTable.AddNewUsers.AssignTeams.Validation': 'Musisz wybrać co najmniej jeden zespół.',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Heading': 'Dodaj adresy e-mail użytkowników',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.SubHeading': 'Możesz dodać jeden adres e-mail na raz lub wkleić listę. E-maile powinny być oddzielone spacją, przecinkiem lub średnikiem.',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Placeholder': 'Wpisz lub wklej adresy e-mail tutaj.',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.MaximumUsersAllowed': '{maximumUsersAllowed} użytkowników jednocześnie',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Total': 'Razem',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Duplicate': 'Duplikuj',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.AlreadyExists': 'Już istnieje',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Invalid': 'Nieprawidłowy',
    'ClientUserTable.AddNewUsers.AddNewAttributes.SignLanguageInterpreter': 'Tłumacz języka migowego',
    'ClientUserTable.AssignTeams.TotalUsers': 'Zamierzasz dodać <code>{users} nową {role}</code>',
    'ClientUserTable.AssignTeams.AddAndUpdateUsers': 'Zamierzasz dodać <code>{users} nową {role}</code> i zaktualizować <code>{existingUsers} istniejącą {role}</code>',
    'ClientUserTable.AssignTeams.UpdateUsers': 'Zamierzasz zaktualizować istniejącą rolę <code>{existingUsers} {role}</code>',
    'ClientUserTable.AssignTeams.NumberOfTeams': 'Zostanie przypisany do <code>zespołów {numberOfTeams}</code>',
    'ClientUserTable.Confirmation.Heading': 'Potwierdź przed dodaniem',
    'MursionPortal.InviteToSchedule.Confirmation.Text': 'Dowiedz się więcej tutaj: <code>Zaproś do planowania</code>',
    'MursionPortal.AccountOwnerGuides.Confirmation.Text': 'Przewodniki dla account ownerów',
    'MursionPortal.FacilitatorGuides.Confirmation.Text': 'Przewodniki dla facilitatorów',
    'MursionPortal.Users.Confirmation.Text': 'Twoi użytkownicy zostali dodani do portalu Mursion.',
    'MursionPortal.Confirmation.Header.Text.BackToUsers': 'Powrót do użytkowników',
    'MursionPortal.Users.Next.Steps.Text': 'Następne kroki',
    'MursionPortal.Users.StepOne.Text': 'Krok 1:',
    'MursionPortal.Users.StepTwo.Text': 'Krok 2:',
    'MursionPortal.Users.StepThree.Text': 'Krok 3:',
    'MursionPortal.Users.ThatIt.Text': 'To wszystko!',
    'MursionPortal.Users.InviteToScheduleLearner.Text': 'Zaproś uczniów do planowania',
    'MursionPortal.Users.LearnersLink.Text': 'Możesz wysłać ten link swoim uczniom',
    'MursionPortal.Users.InviteToSchedule.ProjectorScenario.Text': 'Użyj przycisku <strong>Zaproś do planowania</strong> w dowolnym projekcie lub scenariuszu.',
    'MursionPortal.Users.CopyInviteLink.Text': 'Skopiuj link z zaproszeniem',
    'MursionPortal.Users.ShareContent.Text': 'Udostępnij zawartość pomocy swoim administratorom',
    'MursionPortal.Users.AOAndFacilitators.Responsibilities.Text': 'Account Ownerzy i Facilitatorzy mają różne obowiązki i być może będą musieli dowiedzieć się więcej o portalu. Udostępnij te linki swoim Account Ownerom i Facilitatorom, aby mogli znaleźć odpowiedzi na wszelkie pytania, które mogą im się nasunąć podczas pracy z Mursion.',
    'MursionPortal.Users.Administrators.Text': 'Twoi administratorzy będą teraz mieli dostęp do portalu Mursion.',
    'MursionPortal.Users.Progress.Text': 'Twoi uczniowie zaczną planować swoje symulacje. Możesz śledzić ich postępy na swoim pulpicie nawigacyjnym.',
    'MursionPortal.Users.Access.Text': 'Upewnij się, że Twój dział IT dał tym użytkownikom dostęp do Mursion App',
    'MursionPortal.Users.Registrationemail.Text': 'Twoi użytkownicy powinni właśnie otrzymać e-mail rejestracyjny z adresu no-reply@mursion.com.',
    'MursionPortal.AddMoreUsers.Btn.Text': 'Dodaj więcej użytkowników',
    'MursionPortal.Users.Ok.Btn.Text': 'Ok, rozumiem!',
    'Users.Button.AddNewUsers': 'Dodaj nowych użytkowników',
    'MursionPortal.Project.CancelationDeadline.UpperLimit.Validation': 'Termin anulowania nie może być dłuższy niż 365 dni.',
    'MursionPortal.Dashboard.EmailTypeFilter.PasswordReset': 'Resetowanie hasła',
    'MursionPortal.Dashboard.EmailTypeFilter.Verification': 'Weryfikacja',
    'MursionPortal.Dashboard.EmailTypeFilter.InviteToSchedule': 'Zaproszenie do planowania',
    'MursionPortal.Dashboard.EmailTypeFilter.Summary': 'Streszczenie',
    'MursionPortal.Dashboard.EmailTypeFilter.RescheuleMissed': 'Zmiana terminu została pominięta',
    'MursionPortal.Dashboard.EmailTypeFilter.RescheuleCancelled': 'Zmiana terminu została anulowana',
    'MursionPortal.Dashboard.EmailTypeFilter.PSRescheule': 'Zmiana terminu PS',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingCorrupted': 'Nagrania wideo uszkodzone',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingNotUploaded': 'Nagrania wideo nie zostały przesłane',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingAvailable': 'Nagranie wideo dostępne',
    'MursionPortal.Dashboard.EmailTypeFilter.VRecordingShared': 'Nagranie wideo udostępnione',
    'MursionPortal.Dashboard.EmailTypeFilter.Booking': 'Rezerwacja',
    'MursionPortal.Dashboard.EmailTypeFilter.CodeOfConuct': 'Kodeks Postępowania',
    'MursionPortal.Dashboard.EmailTypeFilter.UserRemoved': 'Użytkownik usunięty',
    'MursionPortal.Dashboard.EmailTypeFilter.AttendanceReminer': 'Przypomnienie o uczestnictwie',
    'Users.Button.AddNewLearners': 'Dodaj nowych uczniów',
    'MursionPortal.Scenario.Label.LastDelivered': 'Ostatnio dostarczone',
    'MursionPortal.Scenario.Label.TotalDeliveries': 'Dostawy ogółem',
    'MursionPortal.Scenario.Text.NotYetDelivered': 'Jeszcze nie dostarczone',
    'ClientUserTable.AddNewUsers.UpdateUserWarning': 'Ci użytkownicy {existingUsers} już istnieją. Kontynuowanie spowoduje dodanie tych użytkowników do wybranych zespołów (oprócz zespołów, do których są już przypisani).',
    'MursionPortal.AddNewUsers.Facilitators': 'Facilitatorzy',
    'ClientUserTable.AddNewUsers.Learners': 'Uczniowie',
    'MursionPortal.Scheduling.TimeSlotsUnavailableMessage': 'Wybrane przedziały czasowe nie są już dostępne. Odśwież, aby wyświetlić możliwe do zaplanowania przedziały czasowe',
    'MursionPortal.Client.EmailTypeFilter.SessionFirstReminder': 'Pierwsze przypomnienie o sesji',
    'MursionPortal.Client.EmailTypeFilter.SessionSecondReminder': 'Drugie przypomnienie o sesji',
    'Projects.ProjectInfo.Label.ShowSIMAvailaiblity': 'Pokaż dostępność SIM',
    'Projects.ProjectInfo.Label.ShowAvailaiblity.Tooltip': 'Kiedy uczeń planuje, zobaczy dostępność SIM Specialist dla wybranego przez Ciebie zakresu.',
    'Projects.InputValidation.GreaterThanZero': 'Powinien być większy niż 0.',
    'MursionPortal.Weeks': 'Tydzień (tygodnie)',
    'MursionPortal.Scenario.CompletionRateFulfilled': 'You cannot schedule this simulation as you have completed the maximum number of simulations for this scenario or engagement as set by your organization.',// TODO add translations
    'MursionPortal.Dashboard.EmailTypeFilter.TimeSlotRequest': 'Prośba o przedział czasowy',
    'MursionPortal.Dashboard.EmailAnalytics.OpenAndActed.Label': 'Dane dotyczące otwartych i zatwierdzonych ofert są aktualne na dzień 24 listopada 2022 r.',
    'MursionPortal.Dashboard.EmailAnalytics.Prior.Label': 'Wszystkie wiadomości e-mail przed tą datą są kategoryzowane jako nieprzeczytane.',
    'MursionPortal.Learners.Filters.LearnerCompletion': 'Ukończenie przez ucznia',
    'MursionPortal.Learners.Filters.LearnerCompletion.NotYetCompleted': 'Jeszcze nie ukończone',
    'MursionPortal.Learners.Filters.LearnerCompletion.CompletedOneSession': 'Ukończono 1 sesję',
    'MursionPortal.Learners.Filters.LearnerCompletion.CompletedTwoPlusSessions': 'Ukończono 2+ sesje',
    'MursionPortal.Learners.Filters.MissedLateActivity': 'Pominięta/opóźniona aktywność',
    'MursionPortal.Learners.Filters.MissedLateActivity.1Session': 'sesja 1',
    'MursionPortal.Learners.Filters.MissedLateActivity.2Sessions': '2 sesje',
    'MursionPortal.Learners.Filters.MissedLateActivity.3Sessions': '3 sesje',
    'MursionPortal.Learners.Filters.MissedLateActivity.4PlusSessions': '4+ sesje',
    'MursionPortal.BackToCompletedSessions': 'Powrót do zakończonych sesji',
    'MursionPortal.Scenario.InviteToSchedule.Modal.Text': 'Czy chcesz wysłać zaproszenia do <strong>wszystkich</strong> przypisanych uczniów, którzy jeszcze nie ukończyli tego scenariusza, czy też <strong>wybrać uczniów</strong> do zaproszenia?',
    'ClientUserTable.AddNewUsers.Procced.Button.Tooltip': 'Zanim przejdziesz dalej, usuń zduplikowane lub nieprawidłowe wiadomości e-mail.',
    'MursionPortal.BackToSessionSummaries': 'Powrót do podsumowań sesji',
    'MursionPortal.BackToLearnerSessions': 'Powrót do sesji ucznia',
    'MursionPortal.LearnerSessionReport.SessionObjective': 'Cel sesji',
    'MursionPortal.LearnerSessionReport.SessionStrategy': 'Jak dobrze zastosowałeś strategie?',
    'MursionPortal.LearnerSessionReport.SessionStrategyIcon': 'Cel strategii sesji',
    'MursionPortal.LearnerSessionReport.SessionPerspective.Title': 'Tak trzymaj!',
    'MursionPortal.LearnerSessionReport.SessionDetail': 'Opis scenariusza',
    'MursionPortal.LearnerSessionReport.SessionDate': 'Data sesji',
    'MursionPortal.BackToSessionDetail': 'Powrót do szczegółów sesji',
    'MursionPortal.SimAttendance.Instructions.Label': 'Mursion Simulation Specialists:',// TODO add translations
    'MursionPortal.SimAttendance.Instructions.LabelText': 'Select an attendance status for each attendee (mouse over the status to learn more). Your status is gathered automatically – these statuses are for the learner ONLY.',// TODO add translations
    'MursionPortal.SimAttendance.Instructions.Label1': 'Software License Simulation Specialists:',// TODO add translations
    'MursionPortal.SimAttendance.Instructions.LabelText1': 'Select an attendance status for each attendee (mouse over the status to learn more). If you experienced an error in the session, please also provide the error details. To do this, select the type of error under the “SIM Reported Error” section and add context in the Additional Details text box. Additional details about attendance--even if it’s not an Error--are encouraged, but are optional. If you have any further questions about attendance, please reach out to your Simulation Program Admin.',// TODO add translations
    'MursionPortal.Dashboard.EmailAnalytics.Label.EmailAnalytics': 'Analiza wiadomości e-mail',
    'MursionPortal.Session.LearnerSession.Report': 'Raport',
    'MursionPortal.Session.LearnerSession.Recording': 'Nagranie',
    'MursionPortal.Session.LearnerSession.SessionDetails': 'Szczegóły sesji',
    'MursionPortal.Session.LearnerSessionReport': 'Raport z sesji ucznia',
    'MursionPortal.Scenario.InviteToScedule.Modal.SelectLearners': 'Wybierz uczniów',
    'MursionPortal.Scenario.Button.Label': 'Kopiuj wiadomości e-mail uczniów',
    'MursionPortal.VideoStatusFilter.Label': 'Status wideo',
    'MursionPortal.TooltipText.VideoNotUploaded': 'Jeśli film nie zostanie przesłany po 48 godzinach, skontaktuj się z pomocą techniczną.',
    'MursionPortal.VideoStatusFilter.SearchText': 'Wyszukaj status wideo...',
    'MursionPortal.VideoStatusFilter.Uploaded': 'Przesłane',
    'MursionPortal.VideoStatusFilter.NotUploaded': 'Nie przesłane',
    'MursionPortal.Scenario.Invite.Modal.Button.Label': 'Zaproś',
    'MursionPortal.Scenario.CopyEmails.Modal.Button.Label': 'Kopiuj e-maile',
    'MursionPortal.Scenario.AllLearners.InvitationsSentOvernight': 'Twoje zaproszenia zostaną wysłane, jeśli uczeń niczego jeszcze nie zaplanował ani nie otrzymał zaproszenia w ciągu ostatnich 2 dni.',
    'MursionPortal.Scenario.SelectedLearners.InvitationsSentOvernight': '<code>Wybrani uczniowie</code> ({count}) otrzymają e-maila z zaproszeniem do planowania, jeśli jeszcze niczego nie zaplanowali lub nie otrzymali zaproszenia w ciągu ostatnich 2 dni.',
    'MursionPortal.Scenario.SelectedLearners.Confirm.Text': 'Czy na pewno chcesz zaprosić <code>wszystkich wybranych uczniów</code>',
    'ClientUserTable.Column.LastInvited': 'Ostatnio zaproszeni',
    'ClientUserTable.ColumnTooltip.LastInvited': 'Data wysłania ostatniego e-maila z zaproszeniem do planowania. Może to być data jutrzejsza, jeśli zaproszenie zostało dopiero wysłane.',
    'MursionPortal.Scenario.SelectedLearners.EmailsCopied': 'Adresy e-mail wybranych uczniów zostały skopiowane do Twojego schowka',
    'MursionPortal.ScenarioInfo.MarkAllInactive': 'Zaznacz wszystkie nieaktywne',
    'MursionPortal.ScenarioInfo.DecertifyAll': 'Decertyfikuj wszystkich',
    'MursionPortal.ScenarioInfo.MarkAllInactive.Confirmation.Text': 'Czy na pewno chcesz dezaktywować wszystkich SIM Specialists?',
    'MursionPortal.ScenarioInfo.DecertifyAll.Confirmation.Text': 'Czy na pewno chcesz cofnąć certyfikat wszystkim SIM Specialists?',
    'Dashboard.Button.ViewSession': 'Zobacz sesję',
    'MursionPortal.SessionReport.NotAvailable': 'Niedostępne',
    'Projects.ProjectInfo.Checkbox.EnableReportTabSurvey.OneToOne': 'Włącz kartę raportu dla dostaw 1:1',
    'MursionPortal.Learner.Session.Report': 'Raport z sesji ucznia',
    'MursionPortal.Scenario.Sim.InactivedAll': 'Dezaktywowano {effectedSims} z {totalSims} SIM.',
    'MursionPortal.Scenario.Sim.DecertifiedAll': 'Decertyfikowano {effectedSims} z {totalSims} SIM.',
    'MursionPortal.Scenario.Sim.DecertifiedFew': '{remainingSims} SIM nie można cofnąć certyfikatu, ponieważ mają zaplanowane sesje. Oznacz ich jako nieaktywnych, aby uszanować ich sesje i zapobiec przypisaniu ich w przyszłości.',
    'MursionPortal.AriaLabel.ViewMore': 'Zobacz więcej',
    'MursionPortal.SimAttendance.SimulationAttendance': 'Frekwencja ucznia w symulacji',
    'Mursion.Portal.Status.LearnerTechnicalError': 'Learner Technical Error',// TODO add translations
    'Mursion.Portal.Status.TooLateToComplete': 'Za późno na ukończenie',
    'Mursion.Portal.Status.LearnerElectedToLeave': 'Learner Elected to Leave',// TODO add translations
    'Mursion.Portal.Status.Unprepared.Tooltip': 'Uczeń dołączył, ale nie ukończył symulacji z powodu nieprzygotowania lub z innych powodów.',
    'MursionPortal.Label.SchedulingRate': 'Współczynnik planowania',
    'Mursion.Portal.Status.No.Show': 'Nieobecny',
    'MursionPortal.ScenarioInfo.SchedulingRate.Text': '{code} razy',
    'MursionPortal.Scenario.Modal.Search.Placeholder': 'Wyszukaj adres e-mail, imię, nazwisko',
    'MursionPortal.Scenario.SchedulingRateFulfilled': 'Najpierw musisz ukończyć zaplanowaną sesję.',
    'MursionPortal.Scenario.InviteToScedule.Modal.Button.Tooltip': 'Wybierz ucznia, którego chcesz zaprosić',
    'MursionPortal.Learner.Session.Report.Report.SimulationRecording': 'Zastanów się nad rozmową, odtwarzając nagranie symulacji',
    'Dashboard.SimulationTable.ColumnTooltip.SoftwareVersion': 'Oznacza software używane do obsługi sesji',
    'Dashboard.SimulationTable.Column.SoftwareVersion': 'Software',
    'MursionPortal.Users.EditUser.InactivatedAll.Message': '{code} Certyfikaty zostały zdezaktywowane.',
    'MursionPortal.Users.EditUser.InactivateAll.Confirmation': 'Czy na pewno chcesz zdezaktywować wszystkie certyfikaty tego SIM Specilaist?',
    'MursionPortal.Users.Modal.ActiveCerts.EmptyMessage': 'Brak aktywnych certyfikatów',
    'MursionPortal.Users.Modal.InactiveCerts.EmptyMessage': 'Brak nieaktywnych certyfikatów',
    'MursionPortal.Users.Modal.ActiveCerts': 'Aktywne certyfikaty',
    'MursionPortal.Users.Modal.InactiveCerts': 'Nieaktywne certyfikaty',
    'MursionPortal.Users.ActiveCerts.MarkAllInactive.Button': 'Zaznacz wszystkie nieaktywne',
    'MursionPortal.LearnerSessionReport.YourPerspective': 'Twoja perspektywa',
    'MursionPortal.LearnerSessionReport.YourPerspectiveDescription': 'Twoja pewność siebie przed symulacją wynosiła <strong>{preConfidenceLevel}</strong>, a po symulacji <strong>{postConfidenceLevel}.</strong>',
    'MursionPortal.LearnerSessionReport.YourPerspective.VeryLow': 'bardzo niska',
    'MursionPortal.LearnerSessionReport.YourPerspective.Low': 'niska',
    'MursionPortal.LearnerSessionReport.YourPerspective.Moderate': 'umiarkowana',
    'MursionPortal.LearnerSessionReport.YourPerspective.High': 'wysoka',
    'MursionPortal.LearnerSessionReport.YourPerspective.VeryHigh': 'bardzo wysoka',
    'MursionPortal.LearnerSessionReport.Strategies.SuccessfullyStrategy': '<strong>Pomyślnie</strong> zademonstrowałeś tę strategię',
    'MursionPortal.LearnerSessionReport.Strategies.BenefitStrategy': '<strong>Przydałoby się więcej praktyki</strong> we wdrażaniu tej strategii',
    'MursionPortal.AriaLabel.NormalFont': 'Normalna czcionka',
    'MursionPortal.AriaLabel.MediumFont': 'Średnia czcionka',
    'MursionPortal.AriaLabel.LargeFont': 'Duża czcionka',
    'MursionPortal.AriaLabel.Font': 'Czcionka',
    'Dashboard.LeftPane.CategoryHeader.RescheduledAndCancelled': 'Przełożone i anulowane',
    'MursionPortal.SchedulingRate.ValidationMessage': 'Współczynnik planowania musi być mniejszy lub równy wskaźnikowi ukończenia.',
    'MursionPortal.LearnerSessionReport.Footer.FooterTitle': 'Kolejne kroki: Praktyka czyni mistrza!',
    'MursionPortal.LearnerSessionReport.Footer.FooterSubTitle': 'Powtarzanie jest kluczem do uczynienia tych umiejętności Twoją drugą naturą.',
    'MursionPortal.LearnerSessionReport.SessionObjective.Body': 'Twój raport będzie wkrótce gotowy',
    'MursionPortal.Label.MissCancellationRate': 'Wskaźnik pominięć/anulowań',
    'MursionPortal.sessionTable.Column.OriginalSim': 'Oryginalny SIM',
    'MursionPortal.sessionTable.Column.NewSim': 'Nowy SIM',
    'MursionPortal.Dashboard.Summary.Certifications': 'Certyfikaty',
    'MursionPortal.SessionsTable.ActionTitle.Pickup': 'zacznij od nowa',
    'MursionPortal.Table.ColumnSelection.Button.Label': 'Kolumny',
    'Session.Table.Column.SubmitRequest': 'Złoż wniosek',
    'MursionPortal.SurveyFilter.Incomplete': 'Niekompletne',
    'MursionPortal.SurveyFilter.SearchText': 'Wyszukaj ankietę...',
    'MursionPortal.Dashboard.SurveyReminder.Text': 'Masz {surveyCount} ankiet do wypełnienia',
    'MursionPortal.Dashboard.RemindMeLater': 'Przypomnij mi później',
    'MursionPortal.Dashboard.SurveysIncomplete': 'Ankiety niekompletne',
    'MursionPortal.Scenario.CharacterLimitExceeded.Message': 'Przekroczyłeś limit znaków o {exceededCharLength} znaków. Prosimy o poprawienie.',
    'MursionPortal.Scenario.AddedCharacter.Message': '{added} z {totalChar}',
    'MursionPortal.Scenario.CharacterLimit.Message': 'Limit znaków: {maxLength}',
    'MursionPortal.Scenario.Description.block.default.text': 'Dodaj opis tutaj',
    'MursionPortal.Scenario.AttachmentField.text': 'Możesz dodać więcej plików',
    'MursionPortal.Dashboard.NextOnProject.Footer.schedulingRateFulfilled.Text': 'You cannot schedule this simulation at this time as you have currently reached the scheduling limit for this scenario or engagement set by your organization.',// TODO add translations
    'MursionPortal.Label.NotAllowed': 'Niedozwolone',
    'Dashboard.LeftPane.SupplyManagement.SimView': 'Widok Sim',
    'Dashboard.LeftPane.SupplyManagement.SchedulingView': 'Widok planowania',
    'Filters.SimSpecialistStatus': 'Status Sim Specialist',
    'Filters.SimSpecialistStatusPlaceHolderHint': 'Wyszukaj filtr statusu Sim Specialist...',
    'Users.TableModal.SSOUserID': 'ID użytkownika SSO',
    'MursionPortal.UnavailableSlotsError.SorryText': 'Cześć! Tak mi przykro!',
    'MursionPortal.UnavailableSlotsError.BusyText': 'Wygląda na to, że jesteśmy zajęci symulowaniem z innymi uczniami.',
    'MursionPortal.UnavailableSlotsError.TryAgainText': 'Spróbuj ponownie jutro lub innego dnia!',
    'MursionPortal.Session.Survey.ClickHere': 'Kliknij tutaj',
    'MursionPortal.Session.SurveyNotLoading': 'Ankieta się nie ładuje?',
    'Users.Table.Heading.Viewonly': '(Tylko podgląd)',
    'MursionPortal.BookItUI.BusyText': 'Wygląda na to, że w tej chwili jesteśmy zajęci symulowaniem z innymi uczniami.',
    'MursionPortal.BookItUI.TryLaterText': 'Wkrótce powinno się otworzyć więcej sesji symulacyjnych. Spróbuj ponownie później.',
    'MursionPortal.BookItUI.MoreSimulationsText': 'Wkrótce powinno zostać otwartych więcej sesji symulacyjnych.',
    'MursionPortal.BookItUI.ComeBackText': 'Wróć, jeśli będziesz chcieć przeprowadzić swoją symulację później.',
    'ClientUserTable.AddNewUsers.AssignTeams.UserEmails.Archived': 'Użytkownik zarchiwizowany',
    'ClientUserTable.AddNewUsers.UpdateArchivedUserWarning': '{archivedUsers} użytkowników jest obecnie zarchiwizowanych. Kontynuacja spowoduje dodanie tych użytkowników do wybranych zespołów i przywrócenie ich z archiwum.',
    'ClientUserTable.AddNewUsers.UpdateExistingArchivedUserWarning': 'Użytkownicy {existingUsers} już istnieją, a użytkownicy {archivedUsers} są obecnie zarchiwizowani. Kontynując dodasz tych użytkowników do wybranych zespołów, a także cofniesz archiwizację zarchiwizowanych użytkowników.',
    'MursionPortal.ProjectCreateStep.UpperLimitValidation.Hours': 'Nie może być większa niż {code} godzin',
    'MursionPortal.ProjectCreateStep.UpcomingAvailability': 'Dostępność w najbliższym czasie',
    'MursionPortal.Table.ColumnHeader.TotalSession': 'Całkowita sesja',
    'MursionPortal.Table.ColumnHeader.ActiveCertifications': 'Aktywne certyfikaty',
    'MursionPortal.Button.Decline': 'Odmowa',
    'MursionPortal.Having.Issues.Logging.In': 'Masz problem z logowaniem?',
    'MursionPortal.Label.Call': 'Zadzwoń:',
    'MursionPortal.Project.Hours': 'Godzin',
    'Users.Button.ProfService': 'Usługi profesjonalne',
    'Users.Button.Operations': 'Usługi operacyjne',
    'Users.Button.SimDesigner': 'Sim Designer',
    'MursionPortal.ArtProject.Placeholder.EnterOrSelect': 'Wybierz lub Wejdź',
    'MursionPortal.Setting.LearnerCommunicationPersonalization': 'Personalizacja komunikacji ucznia',
    'MursionPortal.LearnerCommunicationPersonalization.Heading': 'Tutaj możesz dostosować określone wiadomości dla swoich uczniów.',
    'MursionPortal.SupplyBasedScheduling.SubmitPage.SimulationConfirmation': 'Twoja symulacja została potwierdzona! Zalecamy przybycie na kilka minut przed godziną rozpoczęcia.',
    'MursionPortal.DemandBasedScheduling.SubmitPage.LearnerConfirmed': '{learners} potwierdziło udział w symulacji!',
    'MursionPortal.DemandBasedScheduling.SubmitPage.DateAndTime': 'Data i godzina',
    'MursionPortal.Personalized.LearnerDashboard.Section': 'Wiadomość, którą chcesz, aby wszyscy Twoi uczniowie widzieli na swoim pulpicie nawigacyjnym',
    'MursionPortal.Personalized.SchedulingPage.Section': 'Wiadomość, którą chcesz, aby wszyscy Twoi uczniowie widzieli na stronie planowania',
    'MursionPortal.Personalized.Section.Tooltip': 'Wiadomość zostanie zaktualizowana we wszystkich miejscach',
    'MursionPortal.CheckboxLabel.JoinNow': 'Dołącz teraz',
    'MursionPortal.ProjectCreateStep.UpcomingAvailability.Tooltip': 'Role klientów byłyby pokazane jako wyłączone dla określonych godzin „Dostępności w najbliższym czasie” po „Dostępności SIM”. Jeśli „Dostępność w najbliższym czasie” ma wartość 0, żadne wyłączone przedziały czasowe nie zostaną pokazane, poza „Dostępnością SIM”. Jeśli „Dostępność w najbliższym czasie” to 24 godziny, wszystkie dostępne przedziały po godzinach „Dostępności SIM” będą przez jeden dzień pokazane jako wyłączone.',
    'MursionPortal.LearnerDashboard.JoinNow.OutsideOfficeHoursText': 'Wróć później, w godzinach pracy Twojej organizacji, aby wziąć udział w tym scenariuszu.',
    'MursionPortal.Setting.Personalization.LastUpdated': 'Ostatnio zaktualizowane:',
    'MursionPortal.CheckboxLabel.JoinNowRequests': 'Prośby o dołączenie teraz',
    'MursionPortal.LearnerDashboard.JoinNow.HeaderText': 'Dopasowujemy Cię do awatara <code>do symulacji na żywo</code>. Zapoznaj się z poniższymi materiałami, aby się przygotować.',
    'MursionPortal.LearnerDashboard.JoinNow.SorryText': 'Przepraszamy!',
    'MursionPortal.LearnerDashboard.JoinNow.AvatarsBusyText': 'Wszystkie nasze awatary są zajęte innymi uczniami. Wróć później lub spróbuj ponownie za kilka minut.',
    'MursionPortal.LearnerDashboard.JoinNow.CongratsText': 'Gratulacje! Zostałeś dopasowany do awatara!',
    'MursionPortal.LearnerDashboard.JoinNow.AvatarsMatchText': 'Awatar czeka na Ciebie! Kliknij opcję Dołącz do symulacji na żywo, gdy będziesz gotów. W przeciwnym razie symulacja rozpocznie się automatycznie po odliczaniu.',
    'MursionPortal.LearnerDashboard.JoinNow.StartLearning': 'If you are not able to get connected, click below to start learning.',
    'MursionPortal.LearnerDashboard.JoinNow.StarBucks.MyLearning': 'Starbucks MyLearning',
    'MursionPortal.LearnerDashboard.JoinNow.StarBucks.LiveSimulationExp': 'This session is a live simulation experience. Partners can now choose to take an eLearning on de-escalating conflict in MyLearning, as an alternative to the live safety simulation.',
    'MursionPortal.Button.Leave': 'Wyjdź',
    'MursionPortal.Personalized.ScenarioPage.Section': 'Wiadomość, którą uczniowie mają widzieć na stronie scenariusza',
    'MursionPortal.Personalized.CreateNewScenario.Button.Label': 'Utwórz nowe dla określonego scenariusza',
    'MursionPortal.Personalized.Scenario.Search.Placeholder': 'Wyszukaj lub wybierz scenariusz(e):',
    'MursionPortal.Personalized.Scenario.Selection.Title': 'Dodaj nowy scenariusz',
    'MursionPortal.Personalized.NoMessage': 'Obecnie nie ma ustawionej wiadomości',
    'MursionPortal.Personalized.MetaData.NoHistory': 'Nie ma jeszcze historii.',
    'MursionPortal.JoinNow.AlreadyBooked.Message': '{code} jest w tej chwili zabookowane na sesję.',
    'Users.Table.SendInvite.TooltipText': 'Wyłączenie e-maili rejestracyjnych powoduje również wyłączenie przycisków „kliknij, aby zaprosić” w portalu',
    'MursionPortal.JoinNow.AlreadyBooked.JoinSession.Message': 'Kliknij opcję Dołącz do symulacji na żywo, aby dołączyć do sesji lub ją wznowić.',
    'Dashboard.LeftPane.CategoryHeader.Scenarios': 'SCENARIUSZE',
    'Dashboard.LeftPane.Learners.TeamAndProjectActivity': 'Aktywność zespołu i projektu',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.NotYetScheduled': 'Jeszcze nie zaplanowane',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.Scheduled': 'Jeszcze nie ukończone',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.LearnerCompleted': 'Ukończone',
    'MursionPortal.Dashboard.Chart.label.LearnerStatus.NotYetAssigned': 'Jeszcze nie przydzielone',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.NotYetScheduled': 'Liczba uczniów, którzy są przypisani do co najmniej jednego zespołu, który jest przypisany do co najmniej jednego scenariusza, ale którzy nigdy nie zaplanowali żadnej sesji.',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.Scheduled': 'Liczba uczniów, którzy zaplanowali co najmniej jedną sesję, ale jeszcze w żadnej nie uczestniczyli i nie ukończyli co najmniej jednej sesji.',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.CompletedAssigned': 'Liczba uczniów, którzy ukończyli co najmniej jedną sesję.',
    'MursionPortal.Dashboard.Chart.Tooltip.LearnerStatus.NotYetAssigned': 'Liczba uczniów, którzy nie są przypisani do żadnego zespołu lub uczniów, którzy są częścią zespołu, a którzy nie są przypisani do żadnego scenariusza.',
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerStatus': 'Ukończenie przez ucznia',
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerSequence': 'Wyjątkowi uczniowie',
    'MursionPortal.Dashboard.ScenarioLearner.Header.SequenceCount': '(Maksymalna liczba sesji: {sessionCount})',
    'Dashboard.SequenceOrder.Header.Title': 'Pominięte, anulowanie z opóźnieniem i przełożone z opóźnieniem',
    'MursionPortal.Integrations': 'Integracje',
    'MursionPortal.Personalized.Message.Delete.Title': 'Czy na pewno chcesz usunąć wiadomość?',
    'MursionPortal.Personalized.Message.Delete.Tooltip': 'Usuń wiadomość',
    'MursionPortal.Personalized.Scenario.Message.OnlyOneAtTime': 'Jednorazowo można wprowadzać zmiany tylko w jednej wiadomości. Zapisz lub anuluj tą, która jest już w trybie edycji.',
    'MursionPortal.Error.Time': '6:00 - 22:00 ET',
    'MursionPortal.CheckboxLabel.JoinNow.Tooltip.Text': 'Planowanie przez BookIt jest możliwe zawsze, gdy włączona jest opcja Dołącz teraz.',
    'MursionPortal.JoinNow.FinalizedScenario.Message': 'Scenariusz został sfinalizowany, wróć, gdy scenariusz będzie aktywny.',
    'MursionPortal.CheckboxLabel.sessionMissedTime': 'Utracony czas sesji',
    'MursionPortal.Title.Seconds': 'Sekunda(y)',
    'MursionPortal.RequestTable.Accept.ErrorMessage': 'Przepraszamy, sesja została anulowana.',
    'MursionPortal.Client.EmailTypeFilter.SchedulingLocked': 'Planowanie zablokowane',
    'MursionPortal.Client.EmailTypeFilter.SchedulingUnlocked': 'Planowanie odblokowane',
    'MursionPortal.ScenarioInfo.SchedulingLockout.Time': 'Blokada planowania',
    'MursionPortal.Table.ColumnHeader.SimAvailable': 'SIM dostępny',
    'MursionPortal.CheckboxLabel.sessionMissedTime.Tooltip.Text': 'Czas, po którym sesja zostanie oznaczona jako pominięta, jeśli nie dołączy do niej żaden uczeń SIM.',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.NotYetCompleted': 'Jeszcze nie ukończone',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.completedOneSession': 'Ukończono 1 sesję',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.completedTwoPlusSession': 'Ukończono 2+ sesje',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.OneSession': 'sesja 1',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.TwoSession': 'Sesja 2',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.ThreeSession': 'Sesja 3',
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.FourPlusSession': 'Sesja 4+',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.NotYetCompleted': 'Liczba uczniów przypisanych do tego scenariusza, którzy jeszcze go nie ukończyli',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.completedOneSession': 'Liczba uczniów przypisanych do tego scenariusza, którzy ukończyli go co najmniej raz.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.completedTwoPlusSession': 'Liczba uczniów przypisanych do tego scenariusza, którzy ukończyli go co najmniej dwa razy.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.OneSession': 'Liczba uczniów, którzy pominęli, anulowali z opóźnieniem lub przełożyli z opóźnieniem co najmniej jedną sesję.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.TwoSession': 'Liczba uczniów, którzy pominęli, anulowali z opóźnieniem lub przełożyli z opóźnieniem co najmniej dwie sesje.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.ThreeSession': 'Liczba uczniów, którzy pominęli, anulowali z opóźnieniem lub przełożyli z opóźnieniem co najmniej trzy sesje.',
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.FourPlusSession': 'Liczba uczniów, którzy pominęli, anulowali z opóźnieniem lub przełożyli z opóźnieniem co najmniej cztery lub więcej sesji.',
    'MursionPortal.ScenarioBank.ItemBank.Label': 'Bloki konstrukcyjne',
    'MursionPortal.ScenarioBank.ItemBank.Skills': 'Umiejętności',
    'MursionPortal.ScenarioBank.ItemBank.Domains': 'Domeny',
    'MursionPortal.ScenarioBank.ItemBank.Events': 'Wydarzenia',
    'MursionPortal.ScenarioBank.ItemBank.Pathways': 'Ścieżki',
    'MursionPortal.ScenarioBank.ItemBank.Resources': 'Zasoby',
    'MursionPortal.ScenarioBank.ItemBank.Outcomes': 'Wyniki',
    'MursionPortal.ScenarioBank.ItemBank.Mindsets': 'Sposoby myślenia',
    'MursionPortal.ScenarioBank.ItemBank.Avatars': 'Awatary',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummaries': 'Podsumowania badań',
    'MursionPortal.ScenarioBank.ItemBank.LearnerInstructions': 'Instrukcje dla ucznia',
    'MursionPortal.ScenarioBank.ItemBank.Column.Level': 'Poziom',
    'MursionPortal.ScenarioBank.ItemBank.Column.Domain': 'Domena',
    'MursionPortal.ScenarioBank.ItemBank.Column.Translations': 'Tłumaczenia',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Skill': 'Utwórz umiejętność',
    'MursionPortal.Dashboard.Label.ScenarioActivity.ErrorMessage': 'Wybierz co najmniej jeden projekt i scenariusz z filtrów',
    'MursionPortal.Learner.Onboarding': 'Wdrażanie ucznia',
    'MursionPortal.Tab.Onboarding': 'WDRAŻANIE',
    'MursionPortal.OrganizationName.Placeholder': 'wpisz nazwę swojej organizacji',
    'Dashboard.LeftPane.Learners.LearnerLockedOut': 'Uczeń zablokowany',
    'Dashboard.Learners.LearnerLockedOut.TableHeader': 'Uczniowie, którzy mają zablokowany dostęp do planowania z powodu wskaźnika pominięć/anulowań',
    'Dashboard.Learners.LearnerLockedOut.LockedOutColumn': 'Data/godzina zablokowania planowania',
    'Dashboard.Learners.LearnerLockedOut.AutoReleaseColumn': 'Data/godzina do automatycznego odblokowania',
    'MursionPortal.DontHaveEmail.Text': 'Nie masz adresu e-mail w swojej organizacji?',
    'MursionPortal.InvalidCompanyName.Error': 'Twoja organizacja wymaga, aby użytkownicy logowali się przy użyciu adresu e-mail. Wpisz go powyżej.',
    'MursionPortal.LearnerOnboardingPage.Text.Welcome': 'Witaj',
    'MursionPortal.LearnerOnboardingPage.Text.MursionVideoOverview': 'Przegląd filmów o Mursion',
    'MursionPortal.LearnerOnboardingPage.Text.KeyThingsToKnow': 'Najważniejsze rzeczy, które należy wiedzieć',
    'MursionPortal.LearnerOnboardingPage.Welcome.Section.Text': 'Cieszymy się, że możemy powitać Cię w programie edukacyjnym Mursion! Mursion wykorzystuje wciągające symulacje napędzane technologią human-in-the-loop, aby zapewnić kompleksowe doświadczenie edukacyjne, które wyposaży Cię w niezbędne narzędzia i techniki, pozwalające odnieść sukces w stale ewoluującym świecie.',
    'MursionPortal.LearnerOnboardingPage.Welcome.Section.Beginner.Text': 'Niezależnie od tego, czy jesteś początkującym, czy doświadczonym profesjonalistą, nasz program ma coś do zaoferowania dla każdego. Bardzo cieszymy się, że zaczynasz swoją przygodę z Mursion.',
    'MursionPortal.LearnerOnboardingPage.Video.Section.Text': 'Najpierw obejrzyj ten przegląd filmów <strong>o Mursion! </strong>.',
    'MursionPortal.LearnerOnboardingPage.Scheduled.Section.Text': '<strong>Teraz możesz:</strong>zaplanować swoją pierwszą symulację!',
    'MursionPortal.LearnerOnboardingPage.keysThingsToKnow.Section.Heading': 'Następnie omówmy kilka kluczowych rzeczy, o których warto wiedzieć!',
    'MursionPortal.LearnerOnboardingPage.Mursion.Methodology': 'Metodologia Mursion',
    'MursionPortal.LearnerOnboardingPage.Mursion.Methodology.Summary': 'Mursion opiera się na badaniach i udowodniono, że prowadzi do zmiany zachowań. Nasze symulacje oparte na awatarach pomogą ci nauczyć się odpowiednio reagować w sytuacjach wywołujących silne emocje.',
    'MursionPortal.LearnerOnboardingPage.Mursion.WhyAvatars.Text': 'Dlaczego Awatary',
    'MursionPortal.LearnerOnboardingPage.Mursion.WhyAvatars.Summary': 'Mursion jest narzędziem skuteczniejszym niż metody tradycyjne czy odgrywanie ról. Badania pokazują, że ludzie stają się bardziej ufni i zaciekawieni w kontaktach z awatarami niż w kontaktach z współpracownikami czy kierownikami.',
    'MursionPortal.LearnerOnboardingPage.Human.Text': 'Human in the Loop',
    'MursionPortal.LearnerOnboardingPage.Human.Summary': 'W każdej symulacji Mursion udział bierze Simulation Specialist, który generuje mówione dialogi i ruchy aż pięciu wirtualnych awatarów w każdej symulacji.',
    'MursionPortal.LearnerOnboardingPage.Practice.Text': 'Dlaczego praktyka ma znaczenie',
    'MursionPortal.LearnerOnboardingPage.Practice.Text.Summary': 'Doskonalenie umiejętności przywódczych nie jest łatwym zadaniem. Badania pokazują jednak, że nauka przynosi najlepsze efekty, gdy obejmuje również praktykę. Mursion traktuje praktykę priorytetowo, tworząc przestrzenie, które są bezpieczne, dynamiczne i przystępne.',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Domain': 'Utwórz domenę',
    'MursionPortal.ScenarioBank.ItemBank.Level.L1': 'L1',
    'MursionPortal.ScenarioBank.ItemBank.Level.L2': 'L2',
    'MursionPortal.ScenarioBank.ItemBank.Level.L3': 'L3',
    'MursionPortal.Label.OrganizationName': 'Nazwa organizacji',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Pathway': 'Utwórz ścieżkę',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Event': 'Utworz wydarzenie',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Resource': 'Utwórz źródło',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Outcome': 'Utwórz wynik',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Mindset': 'Utwórz sposób myślenia',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.Avatar': 'Utwórz imię awatara',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.ResearchSummary': 'Utwórz podsumowanie badań',
    'MursionPortal.ScenarioBank.ItemBank.Button.Create.LearnerInstruction': 'Utwórz instrukcję dla ucznia',
    'MursionPortal.ScenarioBank.ItemBank.BackButton.Label': 'Powrót do banku scenariuszy',
    'MursionPortal.ScenarioBank.ItemBank.Title.Placeholder': 'Pisz tutaj',
    'MursionPortal.Dashboard.NextOnProject.Footer.MissOrCancelationRateFulfilled.Text': 'You cannot schedule this simulation as you have reached the missed or cancellation limit set by your organization. Please reach out to your program admin to allow scheduling.',// TODO add translations
    'MursionPortal.ScenarioInfo.Show': 'Pokaż',
    'MursionPortal.ScenarioInfo.CertificationHistory': 'Historia certyfikacji',
    'MursionPortal.Session.AlreadyBooked': 'Przepraszamy, sesja została już zaakceptowana przez innego SIM.',
    'MursionPortal.CustomizeInvitation.Title': 'Dostosuj swoje zaproszenia',
    'MursionPortal.CustomizeInvitation.SubTitle': 'Aby dostosować, zastąp tekst w każdym polu poniżej lub pozostaw tekst domyślny.',
    'MursionPortal.CustomizeInvitation.IntroMessage': 'Wiadomość wprowadzająca dla uczniów (dostępne scenariusze zostaną automatycznie umieszczone poniżej tej wiadomości):',
    'MursionPortal.CustomizeInvitation.Label.SenderName': 'Imię nadawcy (adres e-mail to noreply@mursion.com)',
    'MursionPortal.CustomizeInvitation.Label.Subject': 'Temat',
    'MursionPortal.InviteLearnersTeam.Section.Title': 'Jakie zespoły chcesz zaprosić do planowania?',
    'MursionPortal.InviteLearnersTeam.Section.SubTitle': 'Uczniowie zostaną zaproszeni do wszystkich scenariuszy w tym projekcie, które mogą zaplanować',
    'MursionPortal.InviteLearnersTeam.ScenariosAssigned.Text': 'przypisane scenariusze',
    'MursionPortal.Confirmation.Modal.Invitations.Text': 'Twoje zaproszenia zostały zaplanowane!',
    'MursionPortal.ScenarioSetting.ValidationMessage': 'musi być mniejsza lub równa temu samemu ustawieniu na poziomie klienta i/lub projektu.',
    'MursionPortal.ScenarioBank.EventDetail.Details': 'Detale',
    'MursionPortal.ScenarioBank.CharacterLimit.TitleMessage': 'Limit znaków: {titleMaxLength}',
    'MursionPortal.ScenarioBank.CharacterLimit.DescriptionMessage': 'Limit znaków: {descriptionMaxLength}',
    'MursionPortal.ScenarioBank': 'Szablon wydarzenia',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header': 'Wzbudzaj zaufanie i pewność innych, że mogą dzielić się informacjami o swojej pracy',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title': 'Szczegóły domeny',
    'MursionPortal.ScenarioBank.ItemBank.Edit.DescriptionOfUpdate.Label': 'Zapisanie tych zmian spowoduje zaktualizowanie {type} we wszystkich scenariuszach. Proszę dodać krótki opis zmian:',
    'Dashboard.Learners.LearnerLockedOut.LearnerColumn.Tooltip': 'Nazwa użytkownika lub adres e-mail',
    'MursionPortal.ScenarioInfo.CertificationHistory.Column.Certified': 'Certyfikowany',
    'MursionPortal.ScenarioInfo.CertificationHistory.Column.Decertified': 'Zdecertyfikowany',
    'MursionPortal.ScenarioInfo.CertificationHistory.Column.Inactive': 'Nieaktywny',
    'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease': 'Odblokuj uczniowi możliwość planowania',
    'MursionPortal.CustomizeInvitation.Sender.Name': 'Mursion',
    'MursionPortal.CustomizeInvitation.Subject.Title': 'Przygotuj się na ćwiczenie swoich umiejętności! Twoje sesje Mursion są gotowe do zaplanowania.',
    'MursionPortal.CustomizeInvitation.Intro.Message': 'Zostałeś zaproszony do udziału w symulacji w Mursion. Kliknij poniżej, aby zaplanować sesję, lub odwiedź pulpit nawigacyjny i poznaj szczegóły.',
    'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease.ConfirmationText': 'Czy na pewno chcesz odblokować możliwośc planowania {code}uczniom ?',
    'MursionPortal.Dashboard.LearnerLockedOut.ManualRelease.SuccessText': '{code} uczniom odblokowano możliwość planowania.',
    'Dashboard.Learners.LearnerLockedOut.LockedOutColumn.Tooltip': 'Data/godzina, kiedy uczniowi zablokowano możliwość planowania',
    'Dashboard.Learners.LearnerLockedOut.AutoReleaseColumn.Tooltip': 'Data/godzina automatycznego odblokowania ucznia',
    'MursionPortal.ScenarioBank.ItemBank.NextGenScenario': 'Scenariusz NextGen',
    'MursionPortal.ScenarioBank.ResourceFilter.Book': 'Książka',
    'MursionPortal.ScenarioBank.ResourceFilter.Article': 'Artykuł',
    'MursionPortal.ScenarioBank.Filter.Label.Publisher': 'Wydawca',
    'MursionPortal.ScenarioBank.ItemBank.Level.Placeholder': 'Wybierz poziom',
    'MursionPortal.ScenarioBank.ItemBank.Domain.Placeholder': 'Wyszukaj lub wybierz domenę:',
    'MursionPortal.Label.CompletionRate.Tooltip': 'Współczynnik ukończenia określa, ile razy uczeń może ukończyć symulację, zanim utraci możliwość planowania (zostanie zablokowany)',
    'MursionPortal.Label.SchedulingRate.Tooltip': 'Współczynnik planowania określa, ile razy uczeń może zaplanować symulację, zanim utraci możliwość planowania (zostanie zablokowany)',
    'MursionPortal.Label.MissCancellationRate.Tooltip': 'Współczynnik pominięć/anulowań określa, ile razy uczeń może pominąć lub anulować symulację, zanim utraci możliwość planowania (zostanie zablokowany)',
    'MursionPortal.ScenarioInfo.SchedulingLockout.Time.Tooltip': 'Godziny blokady planowania określają, ile godzin będzie trwała blokada planowania',
    'MursionPortal.ScenarioBank.ItemBank.Mindset.Type.Derailing': 'Wypaczone',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Skill': 'Szczegóły umiejętności',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Mindset': 'Szczegóły sposobu myślenia',
    'MursionPortal.ProjectCreate.DeliveryHours': 'Godziny dostawy',
    'MursionPortal.ProjectCreate.DeliveryHours.Tooltip': 'Ramy czasowe, w których uczniom będą wyświetlane natychmiastowe przedziały czasowe',
    'MursionPortal.ProjectCreate.DeliveryHours.Start': 'Rozpoczęcie godzin dostawy',
    'MursionPortal.ProjectCreate.DeliveryHours.End': 'Koniec godzin dostawy',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.LearnerInstruction': 'Szczegóły instrukcji dla ucznia',
    'MursionPortal.ScenarioBank.ItemBank.Avatar.Edit.Header.Title': 'Szczegóły awatara',
    'MursionPortal.ScenarioBank.Label.AddLink': 'Dodaj link',
    'MursionPortal.ScenarioBank.Year.placeholderText': 'Rok',
    'MursionPortal.ScenarioBank.ItemBank.Resource.Edit.Header.Title': 'Szczegóły zasobów',
    'MursionPortal.ScenarioBank.ItemBank.Outcome.Edit.Header.Title': 'Szczegóły wyniku',
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.Advanced': 'Zaawansowany',
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.Intermediate': 'Średniozaawansowany',
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.Foundational': 'Podstawowy',
    'MursionPortal.ScenarioBank.ItemBank.Skill': 'Umiejętność',
    'MursionPortal.Resources.Search.Text': 'Wyszukaj lub wybierz zasoby',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummaries.Details': 'Szczegóły podsumowania badań',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummary': 'Podsumowanie badań',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Pathway': 'Szczegóły ścieżki',
    'MursionPortal.ScenarioBank.ItemBank.Pathway': 'Ścieżka',
    'MursionPortal.ScenarioBank.ConfirmationText.Archive': 'Czy na pewno chcesz zarchiwizować ten {block}?',
    'MursionPortal.ScenarioBank.ConfirmationText.Unarchived': 'Czy na pewno chcesz cofnąć archiwizację tego {block}?',
    'MursionPortal.ScenarioBank.Unarchived': 'Cofnij archiwizację',
    'MursionPortal.ScenarioBank.ItemBank.Resource': 'Źródło',
    'MursionPortal.ScenarioBank.ItemBank.Mindset': 'Sposób myślenia',
    'MursionPortal.ScenarioBank.ItemBank.LearnerInstruction': 'Instrukcja dla ucznia',
    'MursionPortal.ScenarioBank.ItemBank.Edit.Header.Title.Event': 'Szczegóły wydarzenia',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives': 'Perspektywy awatara',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives.Intro': 'Wprowadzenie',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives.DiscProfile': 'Disc Profile',
    'MursionPortal.ScenarioBank.NextGen.AvatarPerspectives.VerbalStyle': 'Styl werbalny',
    'MursionPortal.ScenarioBank.Content': 'Zawartość',
    'MursionPortal.ScenarioBank.Content.ScenarioTitle': 'Tytuł scenariusza',
    'MursionPortal.ScenarioBank.Content.ScenarioCoverStory': 'Okładka scenariusza',
    'MursionPortal.ScenarioBank.ItemBank.Outcome.Placeholder': 'Wybierz wynik bankowy',
    'MursionPortal.ScenarioBank.Content.WhyThisMatters': 'Dlaczego to ma znaczenie',
    'MursionPortal.ScenarioBank.NextGen.AvatarGuide': 'Przewodnik po awatarach',
    'MursionPortal.ScenarioBank.NextGen.ViewScenario': 'Zobacz scenariusz',
    'MursionPortal.ScenarioBank.NextGen.Event': 'Ikona wydarzenia',
    'MursionPortal.NextOnProject.Card.JoinNow': 'Dołącz do symulacji na żywo',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Title': 'Jak to działa',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Intro': 'Cześć! Wkrótce zostaniesz dopasowany do awatara, aby <code>dołączyć do symulacji na żywo</code>.',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Matched': 'Po dopasowaniu awatara kliknij <code>„Dołącz do symulacji na żywo”</code>',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.Allow': 'Kliknij <code>„Zezwalaj”</code>, gdy pojawi się monit, aby uzyskać dostęp do mikrofonu/kamery',
    'MursionPortal.JoinNowWaitingPage.HowItWorks.StartSession': 'Kliknij <code>„Rozpocznij sesję”</code> i poczekaj, aż awatar rozpocznie symulację',
    'MursionPortal.ScenarioBank.NextGen.OutOfSite.Label': 'Poza zasięgiem, na pierwszym miejscu',
    'MursionPortal.FirstGen.Scenario.Button.Text': '1 Gen',
    'MursionPortal.NextGen.Scenario.Button.Text': '2 Gen',
    'MursionPortal.NextGen.Scenario.Assessment': 'Ocena',
    'MursionPortal.NextGen.Scenario.OutOfSite': 'Poza zasięgiem, na pierwszym miejscu',
    'MursionPortal.NextGen.Scenario.Synopsis': 'Streszczenie',
    'MursionPortal.NextGen.Scenario.MinimumPoints': 'Minimalna liczba punktów wymagana do uzyskania pozytywnego wyniku',
    'MursionPortal.NextGen.Scenario.Successful': 'Pozytywny',
    'MursionPortal.NextGen.Scenario.OpportunityDevelop': 'Możliwość dalszego rozwoju',
    'MursionPortal.NextGen.Scenario.LibraryInfo': 'Informacje o bibliotece',
    'MursionPortal.NextGen.Scenario.LibraryInfo.DifficultyLevel': 'Poziom trudności',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ScenarioCoverImage': 'Obraz okładki scenariusza',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ScenarioThumbnailImage': 'Obraz miniatury scenariusza',
    'MursionPortal.NextGen.Scenario.LibraryInfo.CoverImageDescription': 'Opis obrazu okładki',
    'MursionPortal.NextGen.Scenario.LibraryInfo.ThumbnailImageDescription': 'Opis obrazu miniatury',
    'MursionPortal.ScenarioBank.CharacterLimit.ResponseMessage': 'Limit znaków: {responseMaxLength}',
    'MursionPortal.ScenarioBank.EventDetail.AvatarResponseTitle': 'Możliwe odpowiedzi Awatara',
    'MursionPortal.NextGen.Scenario.ReflectionGuide': 'Przewodnik po refleksji',
    'MursionPortal.NextGen.Scenario.Scoring': 'Punktacja',
    'MursionPortal.ErrorMessage.buildingBlock': 'Id bloku konstrukcyjnego musi być określone',
    'MursionPortal.NextGen.Scenario.LibraryInfo.DragAndDropText': 'Przeciągnij i upuść plik tutaj lub kliknij, aby wybrać pliki',
    'MursionPortal.NextGen.Scenario.Publish': 'Publikuj',
    'MursionPortal.NextGen.Scenario.Publish.Label': 'Wypełnij wymagane pola (*), aby opublikować.',
    'MursionPortal.NextGen.Scenario.Last.Publish.Label': 'Ostatnio opublikowane {publishDate} przez {publishedBy}',
    'MursionPortal.JoinNow.Cancellation.FirstOption.Text': 'Nie mam teraz czasu, żeby to zrobić',
    'MursionPortal.JoinNow.Cancellation.SecondOption.Text': 'nie wiem co to jest',
    'MursionPortal.JoinNow.Cancellation.ThirdOption.Text': 'Wrócę, aby to zrobić później',
    'MursionPortal.JoinNow.Cancellation.FirstOption.Description.Text': 'Większość osób, które przeprowadziły tę symulację, uznała ją za wartościowe wykorzystanie czasu. Zajmie ona 30 minut lub mniej.',
    'MursionPortal.JoinNow.Cancellation.SecondOption.Description.Text': 'Zamiast nagrania wideo, ta interaktywna praktyka pomaga rozwijać kompetencje i umiejętności w bezpiecznej i dynamicznej przestrzeni.',
    'MursionPortal.JoinNow.Cancellation.ThirdOption.Description.Text': 'Jasne! Możesz wrócić w dowolnym momencie w godzinach ustalonych przez organizacje i dołączyć do symulacji na żywo, aby ćwiczyć swoje umiejętności!',
    'MursionPortal.JoinNow.CancellationText.Heading': 'Dlaczego chcesz anulować?',
    'MursionPortal.JoinNow.CancellationPage.Title': 'Okazja do ćwiczeń z awatarem!',
    'MursionPortal.JoinNow.CancellationPage.Text': 'Zidentyfikuj pozytywne sposoby rozwiązania sytuacji w ramach zasad i wytycznych Starbucks oraz poszukaj możliwości stworzenia najlepszych chwil dla wszystkich klientów.',
    'MursionPortal.JoinNow.JoinSimulation.Button.Text': 'Kontynuuj, aby dołączyć do symulacji',
    'MursionPortal.LearnerDashboard.JoinNow.SearchingAvatar': 'Szukamy awatara <code>do Twojej symulacji na żywo!</code>',
    'MursionPortal.LearnerDashboard.JoinNow.SuccessText': '<code>Gratulacje</code>, zostałeś dopasowany do awatara do symulacji na żywo!',
    'MursionPortal.LearnerDashboard.JoinNow.ConnectingText': '<code>Łączymy Cię z Twoim awatarem</code>, a wkrótce rozpocznie się Twoja symulacja na żywo!',
    'MursionPortal.LearnerDashboard.JoinNow.DoNotCloseText': 'Nie zamykaj okna przeglądarki!',
    'MursionPortal.LearnerDashboard.JoinNow.StrategiesToUse': 'Strategie do wykorzystania',
    'MursionPortal.LearnerDashboard.JoinNow.PracticeOpportunityText': 'Okazja do ćwiczeń z awatarem!',
    'MursionPortal.Text.Sorry': 'Przepraszam',
    'MursionPortal.Text.Matching': 'Dopasowanie',
    'MursionPortal.Text.Connecting': 'Łączenie',
    'MursionPortal.JoinNowScenarioInfoCard.Text': 'Wróć później lub spróbuj ponownie za kilka minut!',
    'MursionPortal.JoinNowScenarioInfoCard.HeaderText': 'Wszystkie nasze awatary są zajęte innymi uczniami!',
    'MursionPortal.JoinNow.CancellationPage.GoBackButton.Text': 'Anuluj i wróć do pulpitu nawigacyjnego',
    'MursionPortal.NextGen.Scenario.Event.AddMessage': 'Dodaj minimum 2',
    'MursionPortal.ScenarioBank.ItemBank.Events.AddButton': 'Dodaj',
    'MursionPortal.NextGen.ScenarioBank.Event.Select.Placeholder': 'Wybierz wydarzenie',
    'MursionPortal.NextGen.ScenarioBank.Skill.Select.Placeholder': 'Wybierz umiejętność',
    'MursionPortal.NextGen.ScenarioBank.Event.AvatarBehavior.Label': 'Zachowanie awatara*',
    'MursionPortal.NextGen.ScenarioBank.Event.LearnerResponse.Label': 'Oczekiwana odpowiedź ucznia*',
    'MursionPortal.NextGen.ScenarioBank.Event.AvatarAdd.Button': 'Dodaj odpowiedź',
    'MursionPortal.NextGen.ScenarioBank.AvatarResponseName.Select.Placeholder': 'Wybierz awatara',
    'MursionPortal.NextGen.ScenarioBank.Label.AvatarPossibleResponse': 'Możliwe odpowiedzi Awatara',
    'MursionPortal.NextGen.ScenarioBank.Event.AssessmentObservedAction.Label': 'Opisz zaobserwowane działanie',
    'MursionPortal.NextGen.ScenarioBank.Event.AssessmentImapct.Label': 'Wyjaśnij wpływ',
    'MursionPortal.NextGen.ScenarioBank.StrengthAssessment': 'Ocena wytrzymałości*',
    'MursionPortal.NextGen.ScenarioBank.Button.StrengthAssessment': 'Dodaj ocenę wytrzymałości',
    'MursionPortal.NextGen.ScenarioBank.Label.Strength': 'Wytrzymałość',
    'MursionPortal.NextGen.ScenarioBank.OpportunityAssessment': 'Ocena możliwości*',
    'MursionPortal.NextGen.ScenarioBank.Button.OpportunityAssessment': 'Dodaj ocenę możliwości',
    'MursionPortal.NextGen.ScenarioBank.Label.Opportunity': 'Możliwość',
    'MursionPortal.NextGen.Scenario.Event.Mindset.Select.Placeholder': 'Wybierz pozytywne nastawienie',
    'MursionPortal.NextGen.Scenario.Event.Derailing.Select.Placeholder': 'Wybierz wypaczony sposób myślenia',
    'MursionPortal.NextGen.Scenario.Event.Scoring.Title': 'Punktacja wydarzeń',
    'MursionPortal.NextGen.Scenario.Event.Mindset.Title': 'Dodaj pozytywne i wypaczone nastawienie*',
    'MursionPortal.NextGen.Scenario.Event.Positive.Button.Text': 'Dodaj dodatkowe pozytywne nastawienie',
    'MursionPortal.NextGen.Scenario.Event.Derailing.Button.Text': 'Dodaj dodatkowe wypaczone nastawienie',
    'MursionPortal.Text.Searching': 'Wyszukiwanie',
    'MursionPortal.NextGen.Scenario.Event.MindsetsNavigated': 'Nawigacja sposobów myślenia',
    'MursionPortal.NextGen.SelectorText.Race': 'Rasa',
    'MursionPortal.NextGen.SelectorText.Ethnicity': 'Pochodzenie etniczne',
    'MursionPortal.NextGen.SelectorText.None': 'Brak',
    'MursionPortal.NextGen.SelectorText.Woman': 'Jeden musi być kobietą',
    'MursionPortal.NextGen.SelectorText.Man': 'Jeden musi być mężczyzną',
    'MursionPortal.NextGen.SelectorText.Wheelchair': 'Jeden musi poruszać się na wózku inwalidzkim',
    'MursionPortal.NextGen.SelectorText.agedText': 'Jeden musi być w wieku 55+',
    'MursionPortal.NextGen.Avatar.Restriction': 'Ograniczenie awatara',
    'MursionPortal.NextGen.AvatarOrScenarioComposition': 'Kompozycja awatara/scenariusza',
    'MursionPortal.Label.Opening': 'Otwieranie',
    'MursionPortal.Label.Closing': 'Zamykanie',
    'MursionPortal.Label.Introduction': 'Wprowadzenie',
    'MursionPortal.ReflectionGuide.LearnerAssessment': 'Materiały do oceny ucznia',
    'MursionPortal.ReflectionGuide.HostAssessment': 'Ocena hosta',
    'MursionPortal.ReflectionGuide.HostOpportunity': 'Możliwości hosta',
    'MursionPortal.ReflectionGuide.Introduction.Text': 'Witamy spowrotem! Czy czujesz się gotowy, aby zastanowić się nad swoim doświadczeniem? (pozwól uczniowi na wyrażenie zgody przed kontynuowaniem)',
    'MursionPortal.Label.Say': 'POWIEDZ',
    'MursionPortal.Label.Ask': 'ZAPYTAJ',
    'MursionPortal.Label.Bullet': 'Pocisk',
    'MursionPortal.ReflectionGuide.LearnerPreparation.FirstQuestion': 'Jak poszła rozmowa?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.SecondQuestion': 'W jaki sposób rzeczywistość rozmowy odwzorowuje Twój plan rozmowy?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.ThirdQuestion': 'Co się stało?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.FourthQuestion': 'Czy awatar dawał ci jakieś wskazówki (werbalne i niewerbalne), które podpowiadały Ci, jak potoczy się rozmowa?',
    'MursionPortal.ReflectionGuide.LearnerPreparation.FifthQuestion': 'Czy awatar dawał ci jakieś wskazówki (werbalne i niewerbalne), które podpowiadały Ci, jak potoczy się rozmowa?',
    'MursionPortal.ReflectionGuide.LearnerAssessment.FirstSay': 'Ta rozmowa wymagała od Ciebie poradzenia sobie z trudną dynamiką - Mickey był sfrustrowany.',
    'MursionPortal.ReflectionGuide.LearnerAssessment.SecondSay': 'Często widzę, że liderzy, choć mają dobre intencje, reagują w niezbyt produktywny sposób:',
    'MursionPortal.ReflectionGuide.LearnerAssessment.ThirdSay': 'Widziałem również, jak liderzy reagują w bardziej produktywny sposób poprzez:',
    'MursionPortal.ReflectionGuide.LearnerAssessment.FourthSay': 'Ta rozmowa wymagała od ciebie poradzenia sobie z trudną dynamiką',
    'MursionPortal.ReflectionGuide.LearnerAssessment.Ask': 'Jak myślisz, gdzie wylądowałeś? (kontynuacja: Dlaczego/Jak to/Skąd wiesz?): Jak myślisz, gdzie wylądowałeś? (kontynuacja: Dlaczego / Jak to / Skąd wiesz?)',
    'MursionPortal.ReflectionGuide.HostAssessment.Opening': 'Chciałbym podkreślić jedną z Twoich mocnych stron, które zaprezentowałeś podczas spotkania (wybierz jedną):',
    'MursionPortal.ReflectionGuide.HostAssessment.Closing': 'Czy moje refleksje pokrywają się z Twoimi refleksjami? Dlaczego tak lub dlaczego nie?',
    'MursionPortal.ReflectionGuide.HostAssessment.Opportunity.Opening': 'Gdybym mógł wymienić jedną z możliwości rozwoju, które zaobserwowałem (wybierz jedną):',
    'MursionPortal.ReflectionGuide.Closing.Say': 'TO SĄ trudne rzeczy i dziękuję Ci za ćwiczenie dziś tych ważnych umiejętności z Mursion!',
    'MursionPortal.ReflectionGuide.Closing.Ask': 'Jaka jest jedna rzecz, którą wyniesiesz z tego doświadczenia, którą wykorzystasz, gdy następnym razem staniesz w obliczu podobnej sytuacji w prawdziwym życiu?',
    'MursionPortal.ScenarioBank.ItemBank.ResearchSummary.Placeholder': 'Wybierz podsumowanie badania',
    'MursionPortal.ScenarioBank.ItemBank.LearnerInstruction.Placeholder': 'Wybierz instrukcję dla ucznia',
    'MursionPortal.EngagementHub.EmailLearners': 'Email Learners', // TODO add translations
    'MursionPortal.EngagementHub.InviteToTeam': 'Invite to Team', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearnersToScheduleTitle': 'Invite your learners to start or continue their practice', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearnersToScheduleSummary': 'Engage your learners via email or by embedding shared links in your own communications.', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations': 'View Invitations', // TODO add translations
    'MursionPortal.EngagementHub.CreateInvite': 'Create Invite', // TODO add translations
    'MursionPortal.ScenarioContent.Label': 'Dodaj minimum {min} i maksymalnie {max}',
    'MursionPortal.Search.Text.Skills': 'Wyszukaj lub wybierz umiejętności',
    'MursionPortal.Search.Text.Avatar': 'Wyszukaj lub wybierz awatar(y)',
    'MursionPortal.NextGen.Scenario.TimelineAndScheduling': 'Oś czasu i planowanie',
    'MursionPortal.NextGen.Session.Replay': 'Powtórka sesji',
    'MursionPortal.NextGen.StrengthsAndOpportunities': 'Mocne strony i możliwości',
    'MursionPortal.NextGen.Recommendations': 'Zalecenia',
    'MursionPortal.NextGen.MoreToDiscover': 'Więcej do odkrycia',
    'MursionPortal.NextGen.MissionAccomplished': 'Misja zakończona!',
    'MursionPortal.NextGen.Learner.ScenarioMaterials': 'Materiały do scenariusza',
    'MursionPortal.NextGen.Learner.PathwayAffiliation': 'Przynależność do ścieżki',
    'MursionPortal.NextGen.Learner.SkillLevel': 'Poziom umiejętności',
    'MursionPortal.NextGen.Learner.YourMission': 'Twoja misja',
    'MursionPortal.ScenarioBank.ShowOnlySecondGenscenarios': 'Pokaż tylko scenariusze 2 Gen',
    'MursionPortal.NextGen.CreateNewSkill.Header.Text': 'Utwórz nową umiejętność bankową',
    'MursionPortal.NextGen.Learner.StartSimulation': 'Rozpocznij symulację',
    'MursionPortal.SimMaterials.Label.CoverStory': 'Historia',
    'MursionPortal.SimMaterials.Label.LearnerMission': 'Misja ucznia',
    'MursionPortal.SimMaterials.Label.Background': 'Tło',
    'MursionPortal.SimMaterials.Label.PerspectivesAndIdentities': 'Perspektywy i tożsamości',
    'MursionPortal.SimMaterials.HeaderText.SimDashboard': 'Pulpit nawigacyjny SIM',
    'MursionPortal.NextGen.Learner.ToStart': 'zacząć',
    'MursionPortal.Project.DeliveryHours.Standard': 'Standardowe godziny dostawy',
    'MursionPortal.Project.DeliveryHours.Global': 'Globalne godziny dostawy',
    'MursionPortal.Label.Monday.ShortForm': 'Pn.',
    'MursionPortal.Label.Tuesday.ShortForm': 'Wt.',
    'MursionPortal.Label.Wednesday.ShortForm': 'Śr.',
    'MursionPortal.Label.Thursday.ShortForm': 'Czw.',
    'MursionPortal.Label.Friday.ShortForm': 'Pt.',
    'MursionPortal.Label.Saturday.ShortForm': 'Sob.',
    'MursionPortal.Label.Sunday.ShortForm': 'Nd.',
    'MursionPortal.NextGen.CreateFromBank.Customized.Label': 'Dostosowany ze scenariusza bankowego',
    'MursionPortal.NextGen.CreateFromBank.CustomizedFromBank.Tooltip': 'Ten scenariusz został dostosowany w stosunku do oryginalnego scenariusza bankowego. Wszelkie aktualizacje wprowadzone do scenariusza bankowego nie będą propagowane do tego scenariusza.',
    'MursionPortal.BuildingBlock.Label.PublishedDate': 'Data publikacji',
    'MursionPortal.Label.CopyTeamInviteLink': 'Skopiuj link z zaproszeniem do zespołu',
    'MursionPortal.NextGen.SessionReplayText': 'Ta rozmowa była dla ciebie okazją do ćwiczenia rozmowy, która ma na celu wsparcie członka zespołu w trudnym dla niego momencie. Twój sukces był zależny od twojej zdolności do empatii wobec Jo.',
    'MursionPortal.NextGen.TheMission': 'Misja:',
    'MursionPortal.NextGen.DemonstratedStrength': 'Demonstrowana siła',
    'MursionPortal.NextGen.OpportunityForFutureGrowth': 'Możliwości rozwoju',
    'MursionPortal.NextGen.CompetenciesFocus': 'Kompetencje, na których się skupiono, to:',
    'MursionPortal.NextGen.StrengthAndOpportunitiesText': 'Podczas podsumowania rozmowy zidentyfikowano zaobserwowane mocne strony i możliwości rozwoju:',
    'MursionPortal.NextGen.WeObserved': 'Zaobserwowaliśmy....',
    'MursionPortal.NextGen.WhyThisMatters': 'Dlaczego to ma znaczenie',
    'MursionPortal.ScenarioSetting.MissOrCancelationRate.ValidationMessage': 'Czas blokady planowania nie może być równy zeru, jeśli wskaźnik pominięć/anulowań nie jest równy zeru.',
    'MursionPortal.NextGen.CreateFromBank.Customized.Modal.Text': 'Dostosowałeś scenariusz bankowy i wszelkie przyszłe zmiany w scenariuszu bankowym nie zostaną w tym scenariuszu odzwierciedlone. Czy chcesz kontynuować?',
    'MursionPortal.SimMaterials.Event.SkillsInFocus.Label': 'Umiejętności w centrum uwagi',
    'MursionPortal.SimMaterials.Label.SimulationEvents': 'Wydarzenia symulacyjne',
    'MursionPortal.SimMaterials.Event.AvatarBehavior.Label': 'Zachowanie awatara',
    'MursionPortal.SimMaterials.Event.LearnerResponse.Label': 'Oczekiwana odpowiedź ucznia',
    'MursionPortal.Label.NoTimeSelected': 'Nie wybrano czasu',
    'MursionPortal.Label.StartTime.EndTime.Selected': 'Należy wybrać zarówno czas rozpoczęcia, jak i czas zakończenia',
    'MursionPortal.SimMaterials.Label.ReflectionAndDebrief': 'Refleksja i podsumowanie',
    'MursionPortal.NextGen.Scenario.Planning': 'Planowanie',
    'MursionPortal.HowItWorks.Experience.Text': 'Twoje doświadczenie rozpocznie się automatycznie za kilka sekund!',
    'MursionPortal.HowItWorks.MicCamera': 'Mikrofon/kamera',
    'MursionPortal.HowItWorks.Prompt.Text': 'Po wyświetleniu monitu kliknij <strong>„Zezwól”</strong> w celu uzyskania dostępu do mikrofonu/kamery, aby awatar mógł Cię widzieć i słyszeć!',
    'MursionPortal.HowItWorks.StartSession.Text': 'Kliknij <strong>„Rozpocznij sesję”</strong> i przejrzyj opis scenariusza, aby się przygotować!',
    'MursionPortal.LearnerMaterials.KeyCompetencies': 'Kluczowe kompetencje',
    'MursionPortal.LearnerMaterials.ResearchMaterials': 'Materiały badawcze',
    'MursionPortal.LearnerMaterials.PracticeOpportunity': 'Możliwość praktyki',
    'MursionPortal.ScenarioInfo.Label.ScenarioBank': 'Scenariusz jest utworzony z banku',
    'MursionPortal.DemandBasedScheduling.SlotSelectionMessage': 'Wybierz przedziały czasowe, które będą dla Ciebie odpowiednie. Zaplanujemy Twój scenariusz w jednym z wybranych przedziałów czasowych.',
    'MursionPortal.ProjectContracts.DateError': 'Umowy, które próbujesz przypisać do tego projektu, nie mieszczą się w wybranych terminach projektu. Dostosuj daty projektu lub skontaktuj się z działem operacyjnym w sprawie dat umów.',
    'MursionPortal.Rescheduling.SubmitPage.Message': 'Za chwilę wyślemy Ci e-maila z dodatkowymi szczegółami. Do zobaczenia wkrótce!',
    'MursionPortal.DemandBasedScheduling.SubmitPage.Message': 'Pracujemy nad zakończeniem symulacji na podstawie podanych przez Ciebie przedziałów czasowych. Oczekuj od nas e-maila z dalszymi szczegółami. Do zobaczenia wkrótce!',
    'MursionPortal.Project.Days': 'Dni',
    'MursionPortal.ProjectContracts.DuplicateError': 'Wybrana przez Ciebie umowa pokrywa się z inną umową dodaną do tego projektu. Wybierz inną lub skontaktuj się z działem operacyjnym, aby poprosić o zlecenie zmiany',
    'MursionPortal.NextGen.ScenarioBank.SubStrengthAssessment': 'Mocna strona',
    'MursionPortal.NextGen.ScenarioBank.SubOpportunityAssessment': 'Możliwość',
    // needs translations
    'MursionPortal.NextGen.replayConversation':'Replay the Conversation',// TODO add translations
    'MursionPortal.NextGen.Scenario.LibraryInfo.ClinkHereToReplaceThisFile': 'Click here to replace this file.',// TODO add translations
    'MursionPortal.EngagementHub.BackTo': 'Back to {clientName}', // TODO add translations
    'MursionPortal.EngagementHub.LearningPotential': 'At Mursion, we believe in maximizing every learner\'s potential.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationLearnMore': 'Communication Is Key! Learn more', // TODO add translations
    'MursionPortal.EngagementHub.UnlockTeamPotential': 'Unlock Your Team’s Potential', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearners.Title': 'Invite your learners to schedule their simulations', // TODO add translations
    'MursionPortal.EngagementHub.InviteLearners.Summary': '58% of users are more likely to sign up for their simulation if you send a custom invitation to schedule.', // TODO add translations
    'MursionPortal.EngagementHub.ManageEmailSettings.Label': 'Your Email, Your Way!', // TODO add translations
    'MursionPortal.EngagementHub.ManageEmailSettings.Title': 'Manage email settings and gain insights', // TODO add translations
    'MursionPortal.EngagementHub.Customize': 'Customize', // TODO add translations
    'MursionPortal.EngagementHub.EmailConfigurations.Title': 'Email Configurations', // TODO add translations
    'MursionPortal.EngagementHub.EmailConfigurations.Summary': 'Customize email messages that resonate with your learners and drive adoption and engagement.', // TODO add translations
    'MursionPortal.EngagementHub.PowerfulEmailAnalytics.Title': 'Powerful Email Analytics', // TODO add translations
    'MursionPortal.EngagementHub.PowerfulEmailAnalytics.Summary': 'Measure email open, click, and unread rates to discover insights and make data-driven decisions.', // TODO add translations
    'MursionPortal.EngagementHub.PersonalizeMessaging.Label': 'Add Personal Touch', // TODO add translations
    'MursionPortal.EngagementHub.PersonalizeMessaging.Title': 'Personalize messaging in the Mursion Portal', // TODO add translations
    'MursionPortal.EngagementHub.LearnerDashboard': 'Learner Dashboard', // TODO add translations
    'MursionPortal.EngagementHub.ScenarioPage': 'Scenario Pages', // TODO add translations
    'MursionPortal.EngagementHub.SchedulingPage': 'Scheduling Page', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerFeedbackTooltip': 'This email will only go out if feedback report is enabled at project level. Please reach out to your Mursion representative to learn more.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Title': 'Email Settings', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Summary': 'Use these tools to help customize different emails.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EmailName': 'Email Name', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.SenderName': 'Sender Name (email will be no-reply@mursion.com)', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.ViewCustomize': 'View/Customize', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EnableDisable': 'Enable/Disable', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerLockout': 'Learner Lockout', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.VideoFeedback': 'Video & Feedback', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.ModifySettings': 'Click edit to modify the settings', // TODO add translations
    'MursionPortal.EngagementHub.BackTitle': 'Back to Engagement Hub', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey': 'Communication Is Key!', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.summary': 'A great Mursion rollout starts with effective communication to your learners. It\'s important to ensure they are prepared and understand the connection between Mursion and your learning and development program goals.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.TItle': 'Elements of Effective Learner Communication', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LearnerBenefits.Title': 'Lead with Learner Benefits', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LearnerBenefits.Summary': 'Lead with learner benefits, not product features. Share learner testimonials and tie the skills they are developing to business objectives.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.PracticeProgress.Title': 'Practice makes Progress', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.PracticeProgress.Summary': 'Practice is essential to developing new skills. Ensure your message highlights The Importance of Practice - And Our Reluctance To Do It to acknowledge challenges that learners face.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LeadersMessage.Title': 'Message from Leaders', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.LeadersMessage.Summary': 'Ask senior leaders to communicate (meetings, written, video, etc) why Mursion sessions are valuable and how developing the target skills is tied to business priorities and growth opportunities.', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.SetExpectations.Title': 'Set Expectations', // TODO add translations
    'MursionPortal.EngagementHub.CommunicationKey.SetExpectations.Summary': 'Mursion is a new technology that uses avatars powered by humans. Prepare learners for these conversations by sharing why and how avatars create psychology safety and is an effective way to practice.', // TODO add translations
    'MursionPortal.Tab.Engagement': 'Engagement', // TODO add translations
    'MursionPortal.Tab.EngagementHub': 'Engagement Hub', // TODO add translations
    'MursionPortal.Tab.EmailManagement': 'Email Management', // TODO add translations
    'MursionPortal.Tab.PortalMessaging': 'Portal Messaging', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.BackButton.Label': 'Back to Invite to Schedule', // TODO add translations
    'MursionPortal.Portal.Messaging.Heading.Text': 'Inform, engage, and delight learners using your organization’s unique brand voice.',  // TODO add translations
    'MursionPortal.Portal.Messaging.SubHeading.Text': 'Add custom messaging throughout the Portal to engage your learners.',  // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.InvitationDate': 'Invitation Date', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.MessageToLearners': 'Message to Learners', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.ExcludedLearners': 'Excluded Learners', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Column.CreatedBy': 'Created By', // TODO add translations
    'MursionPortal.Portal.EngagementHub.InviteToScheuled.Text': '{scenariosCount} scenarios assigned to {learnerCount} learners', // TODO add translations
    'MursionPortal.Portal.EngagementHub.InviteToScheuled.Text.AddLearners': 'Add Learners', // TODO add translations
    'MursionPortal.Tab.EmailManagement.Analytics': 'Analytics', // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.Teams.Invitation.Message': 'The following team(s) already have scheduled an invitation on this day:', // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.New.Invitation.Message': 'Please make a note and start a new invitation', // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.Learners.Invitation.Text': 'Learners will receive the invitation in the early morning hours (Pacific time) on this day.', // TODO add translations
    'MursionPortal.CustomizeLearnerInvitations.SendDate.Label': 'Send Date', // TODO add translations
    'MursionPortal.Scenario.Generation.FilterType.Label' : 'Generation', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.CancelInvitation.Modal.Text': 'Are you sure you want to cancel the invitation created by {createdBy}?', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerFeedbackReport': 'Learner Feedback Report', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.CancellationByLearner': 'Cancellation by Learner', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.MissedSessionByLearner': 'Missed by Learner', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerReleased': 'Learner Released', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerLockOut': 'Learner Lockout', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.SessionConfirmation': 'Session Confirmation', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Invitation': 'Welcome to Mursion - a platform for you to practice your skills', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.InvitationReminder': `Reminder:You're invited to {licenseeName}`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Confirmation': 'Thanks for signing up! Your Simulation is confirmed.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.FirstReminder': `Don't forget! Your Mursion simulation is soon!`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.SecondReminder': `Get excited! Your Mursion simulation starts in {upcomingBeforeStart} minutes.`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.CancellationByByLearner': 'Your simulation was canceled', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.MissedSessionByLearner': 'We missed you today!', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerLockedOut': 'You missed/cancelled this scenario too many times', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerReleased': 'Your session is available to schedule', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerSessionRecordingIsReady': 'Want to see how you did? Visit your Mursion Dashboard.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.LearnerFeedbackReport': 'Want to see how you did? Review your session report.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.LearnerSessionRecordingReady': 'Learner Session Recording Ready', // TODO add translations
    'MursionPortal.EngagementHub.BackToEmailSettings': 'Back to Email Settings', // TODO add translations
    'MursionPortal.EngagementHub.CustomizeEmail.Text': 'Learners will start receiving this email after you save.', // TODO add translations
    'MursionPortal.NextGen.Learner.FeedbackFrom': 'Feedback from', // TODO add translations
    'MursionPortal.NextGen.Scenario.SimMaterials': 'SIM Materials', // TODO add translations
    'MursionPortal.NextGen.Scenario.LearnerMaterials': 'Learner Materials', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Modal.BodyText': 'Are you sure you want to save the Enable/Disable settings', // TODO add translations
    'MursionPortal.Client.EmailSettings.SessionRescheduledByPS': 'Session Rescheduled by PS', // TODO add translations
    'MursionPortal.EngagementHub.EmailPreview.Sender': 'Sender', // TODO add translations
    'MursionPortal.Scenario.CoverStory.Tooltip.Text': 'Cover Story will be visible to clients in the scenario library as well as learners and SIMs in their materials',// TODO add translations
    'MursionPortal.Scenario.Summary.Tooltip.Text':'Summary will be visible to clients in the scenario library',// TODO add translations
    'MursionPortal.NextGen.Event.positiveMindset': 'There must be at least ({startCount}) positive mindset defined. You can specify a maximum of ({endCount}) positive mindsets.', // TODO add translations
    'MursionPortal.NextGen.Event.derailingMindset': 'There must be at least ({startCount}) derailing mindset defined. You can specify a maximum of ({endCount}) derailing mindsets.', // TODO add translations
    'MursionPortal.NextGen.Event.StrengthAssessment': 'There must be at least ({startCount}) strength assessment defined. You can specify a maximum of ({endCount}) strength assessments.', // TODO add translations
    'MursionPortal.NextGen.Event.OpportunityAssessment': 'There must be at least ({startCount}) opportunity assessment defined. You can specify a maximum of ({endCount}) opportunity assessments.', // TODO add translations
    'MursionPortal.NextGen.Event.StrengthAssessment.AvatarResponse': 'You have added {addedCount} avatar response for strength assessment(s), you must add at least {totalCount} avatar response for strength assessment(s).', // TODO add translations 
    'MursionPortal.NextGen.Event.OpportunityAssessment.AvatarResponse': 'You have added {addedCount} avatar response for opportunity assessment(s), you must add at least {totalCount} avatar response for opportunity assessment(s).', // TODO add translations 
    'MursionPortal.Label.ClientLevelMissCancelSetting': 'Client Level Miss/Cancel Setting', // TODO add translations 
    'MursionPortal.Label.ProjectLevelMissCancelSetting':'Project Level Miss/Cancel Setting', // TODO add translations 
    'MursionPortal.Label.ScenarioLevelMissCancelSetting':'Scenario Level Miss/Cancel Setting', // TODO add translations 
    'MursionPortal.Label.MissedCancelledSessions':'Missed/Cancelled Sessions', // TODO add translations 
    'MursionPortal.EngagementHub.ViewInvitations.Column.SenderName': 'Sender Name', // TODO add translations,
    'MursionPortal.NextGen.Event.Delete.ConfirmBox': 'Are you sure you want to delete this {message}?', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.PositiveMindset': 'Positive Mindset', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.DerailingMindset': 'Derailing Mindset', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.StrengthAssessment': 'Strength assessment', // TODO add translations,
    'MursionPortal.NextGen.Event.Message.OpportunityAssessment': 'Opportunity assessment', // TODO add translations,
    'MursionPortal.PersonalizedMessageTitle': 'Personalized message header. This will appear before every personalized message in Portal.', // TODO add translations
    'MursionPortal.NextGen.ScenarioReferences': 'Scenario References', // TODO add translations 
    'MursionPortal.NextGen.ChildScenarios': 'Child Scenarios', // TODO add translations 
    'MursionPortal.NextGen.Label.Customized': 'Customized', // TODO add translations  
    'MursionPortal.EngagementHub.EmailSettings.InvitationPasswordUsers': 'Invitation (Password Users)', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.InvitationReminderPasswordUsers': 'Invitation Reminder (Password Users)', // TODO add translations
    'MursionPortal.Label.Clicked': 'Clicked', // TODO add translations
    'MursionPortal.Client.EmailSettings.SessionRescheduledByLearner': 'Session Rescheduled by Learner', // TODO add translations
    'MursionPortal.Client.Team.CopyTeamInviteLink.Tooltip':'Users who have not yet registered or have not yet been added to the Portal will not be able to access this link. Please click the "Add Learners" button.', // TODO add translations
    'Settings.Config.LearnerSessionReportDelay': 'Learner Session Report Delay', // TODO add translations
    'MursionPortal.SkillsReferences.Label': 'Skills References', // TODO add translations
    'MursionPortal.ResearchSummariesReferences.Label': 'Research Summaries References', // TODO add translations
    'MursionPortal.Search.Text': 'Search or select', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.Event': 'Please select Event', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.Skill': 'Please select Skill', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.AvatarBehavior': 'Avatar Behavior is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.ExpectedLearnerResponse': 'Expected Learner Response is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.PositiveMindset': 'Please Select Positive Mindset', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.PositiveMindsetDescription': 'Positive mindset is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.DerailingMindset': 'Please Select Derailing Mindset', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.DerailingMindsetDescription': 'Derailing mindset is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.DescribeObservedAction': 'Describe Observed Action is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.ExplainImpact': 'Explain Impact is required', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.AvatarName': 'Please select avatar name', // TODO add translations
    'MursionPortal.NextGen.ScenarioBank.ErrorMessage.PossibleAvatarResponse':'Possible Avatar Response is required', // TODO add translations
    'MursionPortal.Label.SchedulingLockoutDate': 'Scheduling Lockout Date', // TODO add translations
    'MursionPortal.Label.SchedulingLockoutDate.Tooltip': 'Lockout effective date for all learners who miss and/or late cancel', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.MinimumScore': 'You’ve changed the number of events. Please update the minimum score.', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.SynopsisDescription': 'Synopsis description is required', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.SuccessfulAssessment': 'Successful Assessment is required', // TODO add translations
    'MursionPortal.NextGen.scorining.Message.OpportunityAssessment': 'Opportunity Assessment is required', // TODO add translations
    'MursionPortal.NextGen.Scenario.LibraryInfo.PathwaysAffiliation': 'Pathway Affiliation(s)', // TODO add translations
    'MursionPortal.NextGen.BankScenarioReferences': 'Bank Scenario References', // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerActivationStatus':'Learner Activation',  // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerInviteTitle':'Unlock Your Team’s Potential',  // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerInviteMessage':'Target invitations to your learners with custom messaging',  // TODO add translations
    'MursionPortal.Dashboard.ScenarioLearner.Header.LearnerInvite':'Invite to Schedule',  // TODO add translations
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.NotYetRegistered':'Not Yet Registered', // TODO add translations
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.NotYetScheduled':'Not Yet Scheduled', // TODO add translations
    'MursionPortal.Dashboard.Chart.label.ScenarioActivity.ScheduledNotYetCompleted':'Scheduled - Not Yet Completed', // TODO add translations
    'MursionPortal.NextGen.ViewAndInviteLearners': 'View & Invite Learners', // TODO add translations
    'MursionPortal.Dashboard.Tooltip.Label.SchedulingEmail': 'Session Specific Emails and other Join Session Emails', // TODO add translations
    'MursionPortal.Dashboard.EmailAnalytics.Label.InviteToScheduleEmail': 'Invite-to-Schedule Emails', // TODO add translations
    'MursionPortal.Dashboard.Tooltip.Label.CallToActionEmail': 'Emails sent to learners inviting them to schedule scenario(s)', // TODO add translations
    'MursionPortal.NextGen.Scenario.ClientCustomizations': 'Client Customizations', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.InviteToSchedule.Tooltip': 'Invite to Schedule emails are able to be customized each time you send them.', // TODO add translations
    'MursionPortal.Contract.ContractTimezone': 'Contract Timezone', // TODO add translations
    'Dashboard.LeftPane.Simulations.SessionSearch':'Search for Session', // TODO add translations
    'MursionPortal.NextGen.Scenario.ClientCustomizations.ClientScenarioTitle': 'Client Scenario Title', // TODO add translations
    'MursionPortal.NextGen.Scenario.ClientCustomizations.ClientScenarioTitle.Tooltip': 'Client learners and program admins will see this title only. SIMs will see the bank scenario title and client scenario title in their SIM materials.', // TODO add translations
    'MursionPortal.NextGen.Event.DeleteMessage': 'Minimum 2 Events are mandatory for a Scenario.', // TODO add translations
    'Settings.Config.ProjectAndScenario.AllowSimPracticeSession': 'SIM Practice Session', // TODO add translations
    'MursionPortal.Calendar.Button.Practice.Session': 'Practice Session', // TODO add translations
    'MursionPortal.Scenario.InTrainingSimSpecialist': 'In Training SIM Specialists', // TODO add translations
    'MursionPortal.Scenario.NoInTrainingSimSpecialist': 'No In Training Sim Specialist', // TODO add translations
    'MursionPortal.Simspecialist.Thankyou.summary': 'You are scheduled for your practice simulation', // TODO add translations
    'MursionPortal.Button.DoNotSaveMyWork': 'Don’t Save my Work', // TODO add translations
    'MursionPortal.NextGen.Confirm.BrowserBack': 'You have not saved your changes, and navigating away will cause you to lose your work. Please complete required fields.', // TODO add translations
    'MursionPortal.UserConsent.Learner.Description': 'Jeśli powiesz „Wyrażam zgodę”, oprogramowanie Mursion przetworzy Twoją symulację na żywo i utworzy nagranie wideo z sesji symulacyjnej do wglądu w portalu oprogramowania Mursion. Z wyjątkiem przypadków wymaganych przez obowiązujące prawo lub w celach zapewnienia jakości, Mursion nie będzie rozpowszechniać ani ujawniać Twojego nagrania wideo bez Twojej zgody. Mursion będzie wykorzystywać zanonimizowane dane dotyczące korzystania przez Ciebie z oprogramowania w celu udoskonalenia technologii symulacji.', // TODO add translations, this translation was generated using the Google Translate, retranslation required in the future
    'MursionPortal.UserConsent.LearnerAndAccountOwnerOrFacilitator.Description': 'Jeśli powiesz „Wyrażam zgodę”, oprogramowanie Mursion przetworzy Twoją symulację na żywo i utworzy nagranie wideo z sesji symulacyjnej do wglądu w portalu oprogramowania Mursion. Należy pamiętać, że na prośbę klienta, który w Twoim imieniu udzielił licencji na oprogramowanie Mursion, kopia zarejestrowanych sesji symulacyjnych zostanie udostępniona trenerowi, mentorowi lub recenzentowi wybranemu przez klienta. Wszyscy recenzenci są zobowiązani umową do przestrzegania <code>Informacji o ochronie prywatności Mursion</code>, chyba że zgodziłeś się na inne warunki prywatności. Z wyjątkiem przypadków wymaganych przez obowiązujące prawo lub w celach zapewnienia jakości, Mursion nie będzie rozpowszechniać ani ujawniać Twojego nagrania wideo bez Twojej zgody. Mursion będzie wykorzystywać zanonimizowane dane dotyczące korzystania przez Ciebie z oprogramowania w celu udoskonalenia technologii symulacji.', // TODO add translations, this translation was generated using the Google Translate, retranslation required in the future
    'MursionPortal.UserConsent.AccountOwnerOrFacilitator.Description': 'Twoje sesje symulacyjne zostaną nagrane zarówno w formacie audio, jak i wideo, na żądanie klienta, który w Twoim imieniu udzielił licencji na Oprogramowanie Mursion. Jeśli wyrazisz zgodę na kontynuację, kopia nagranych sesji symulacyjnych zostanie udostępniona wybranej przez klienta stronie trzeciej w celu analizy i oceny nagrań. Osoby te są zobowiązane umową do przestrzegania <code>Informacji o ochronie prywatności Mursion</code>, chyba że zgodziłeś się na inne warunki prywatności. Nie otrzymasz jednak kopii zarejestrowanych sesji symulacyjnych ani nie będziesz mieć do nich dostępu zgodnie z warunkami zadania lub oceny, którą zgodziłeś się ukończyć. Mursion może wykorzystywać Twoje nagranie wideo do celów zapewnienia jakości i będzie wykorzystywać anonimowe dane dotyczące korzystania przez Ciebie z oprogramowania w celu ulepszenia technologii symulacji.', // TODO add translations, this translation was generated using the Google Translate, retranslation required in the future
    'MursionPortal.ProjectCreate.DeliveryHours.Validation': 'Delivery Hours Start time should be less than End time.', // TODO add translations
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.NotYetRegistered':'Unique number of learners who have been added to the Portal but have not yet completed registration', // TODO add translations
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.NotYetScheduled':'Unique number of learners who have registered but have not yet scheduled a session', // TODO add translations
    'MursionPortal.Dashboard.Chart.Tooltip.ScenarioActivity.ScheduledNotYetCompleted':'Unique number of learners who have registered and scheduled at least one session but have not yet completed a session', // TODO add translations
    'MursionPortal.Restriction': 'Restriction', // TODO add translations
    'MursionPortal.Dashboard.SessionSearch.Placeholder' : 'Paste a session ID and hit return',  // TODO add translations
    'Operations.TechRestriction.CreateDialog.Title': 'New Holiday Tech Restriction', // TODO add translations
    'Operations.TechRestriction.EditDialog.Title': 'Edit Holiday Tech Restriction', // TODO add translations
    'Operations.TechRestriction.ViewDialog.Title': 'View Holiday Tech Restriction', // TODO add translations
    'MursionPortal.Client.EmailSettings.TriggerHours': 'Trigger (Hours)', // TODO add translations
    'MursionPortal.Client.EmailSettings.TriggerMin': 'Trigger (Minutes)', // TODO add translations
    'MursionPortal.Duplicate.Scenario.Confirmation.Text': 'Are you sure you want to duplicate this scenario?',  // TODO add translations
    'MursionPortal.Contract.LineItem.ToolTip': 'A contract can only contain one type of line item. If your contract has both sessions and hours, please convert the sessions into hours and add it as an hours line item.', // TODO add translations
    'MursionPortal.ReflectionGuide.LearnerPerspective': 'Learner Perspective', // TODO add translations
    'MursionPortal.Dashboard.SessionSearch.Table.Placeholder': 'Search for Session will load once you search session ID', // TODO add translations
    'MursionPortal.Dashboard.SessionSearch.EmptySession': 'No sessions match that ID', // TODO add translations
    'MursionPortal.SimMaterials.Label.IntroAndPreReflection': 'Intro & Pre-Reflection', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.LearnerBreakdown': 'Learner Breakdown', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.LearnerBreakdown.Activated': 'Activated', // TODO add translations
    'MursionPortal.NextGen.Scenario.LibraryInfo.VersionNumber': 'Version Number', // TODO add translations
    'MursionPortal.NextGen.Scenario.LibraryInfo.VersionDetails': 'Version Details', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EmailReminderHours': 'Trigger {tiggerValue} hours before Session.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.EmailReminderMinutes': 'Trigger {tiggerValue} minutes before Session.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.FirstEmailReminder.Tooltip': 'Time before Session when First Reminder would go. If not set Licensee Setting would be used.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.SecondEmailReminder.Tooltip': 'Time before Session when Second Reminder would go. If not set Licensee Setting would be used.', // TODO add translations
    'MursionPortal.SimAttendance.MissedSimulation.Message': 'You missed this simulation so you are unable to edit attendance. Please contact Support if you did not miss the simulation or had a technical issue that caused you to miss the simulation.', // TODO add translations
    'MursionPortal.EngagementHub.ViewInvitations.Disclaimer': 'Invites are visible for last 30 days only', // TODO add translations
    'MursionPortal.PortalVersion': 'Portal Version', // TODO add translations
    'MursionPortal.ScenarioInfo.Label.AutoSIM': 'AutoSIM', // TODO add translations
    'MursionPortal.ProjectContract.AddConfirmation': 'Are you sure you want to add the contract to this project?', // TODO add translations
    'MursionPortal.ProjectContract.CompletedContract.AddConfirmation': 'Are you sure you want to add the completed contract to this project?', // TODO add translations
    'MursionPortal.Label.NewPortal' : 'New Portal', // TODO add translations
    'MursionPortal.Label.OldPortal' : 'Old Portal', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.ReminderValidation': 'Second Reminder should be less than First Reminder', // TODO add translations
    'MursionPortal.Project.InviteToSchedule.EmailDisabled.Tooltip' :'Invite to Schedule is disabled as email settings are disabled. Enable them to send this invite', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimReportedError' :'SIM Reported Error (Does not impact session status)', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimLateOrNoShow' :'SIM late or no show', // TODO add translations
    'MursionPortal.SimAttendance.Error.SessionStartedTooEarly' :'Session started too early', // TODO add translations
    'MursionPortal.SimAttendance.Error.AvatarIssue' :'Avatar Issue', // TODO add translations
    'MursionPortal.SimAttendance.Error.MouthlipSyncIssueDuringSession' :'Mouth/lip sync issue during session', // TODO add translations
    'MursionPortal.SimAttendance.Error.Hardware' :'Hardware', // TODO add translations
    'MursionPortal.SimAttendance.Error.AudioVideoIssueForSim' :'Audio/video issue for SIM', // TODO add translations
    'MursionPortal.SimAttendance.Error.AudioVideoIssueForLearner' :'Audio/video issue for Learner', // TODO add translations
    'MursionPortal.SimAttendance.Error.OtherSimHardwareIssues' :'Other SIM hardware issues (controller, laptop, etc.)', // TODO add translations
    'MursionPortal.SimAttendance.Error.LearnerAndSimBothJoined' :'Learner and SIM both joined, but no connection established', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimMomentIssue' :'SIM Moment issue', // TODO add translations
    'MursionPortal.SimAttendance.Error.WifiIssue' :'Wifi Issue', // TODO add translations
    'MursionPortal.SimAttendance.Error.LearnerWifi' :'Learner Wifi', // TODO add translations
    'MursionPortal.SimAttendance.Error.SimWifi' :'SIM Wifi', // TODO add translations
    'MursionPortal.SimAttendance.Error.AdditionalDetails' :'Additional Details', // TODO add translations
    'MursionPortal.SimAttendance.Error.DescribeYourError' :'Describe your error...', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.CancellationByProfessionalServiceOrAccountOwner': 'Cancellation by PS or AO', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.CompleteLearnerSurvey': 'Complete Learner Survey', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.CompleteLearnerSurvey': 'How\'d it go?', // TODO add translations
    'MursionPortal.CustomizeInvitation.ButtonText.Label': 'Button Text', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Client.LearnerLockedOut': 'You are locked out!', // TODO add translations
    'MursionPortal.Filter.ReleaseLevel.Placeholder': 'Release Level', // TODO add translations
    'MursionPortal.TOTP.Page.Heading': 'We just sent a security code to your {email}. Enter the code to sign in.', // TODO add translations
    'MursionPortal.TOTP.ResendOTP.Text': 'Resend Code', // TODO add translations
    'MursionPortal.TOTP.Code.Label': 'Code', // TODO add translations
    'MursionPortal.TOTP.IncorrectCode.ErrorMsg': 'The code is incorrect. Enter the correct code.', // TODO add translations
    'MursionPortal.TOTP.ResendNewCode.ErrorMsg': 'The code has expired, click "Resend" to receive a new code.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.Subject.Client.LearnerReleased': 'You are ready to schedule again.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.FirstReminder': `You’ve an upcoming Mursion Session in {firstReminderTime} hours!`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.SecondReminder': `Your Mursion Session is starting in {upcomingBeforeStart} minutes!`, // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.LearnerFeedbackReport': 'Mursion - Review Your Session Performance Report.', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.LearnerSessionRecordingIsReady': 'Your Session Recording is now up! Review how you did', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.Confirmation': 'Your Mursion Session is Confirmed!', // TODO add translations
    'MursionPortal.EngagementHub.EmailSettings.NewPortal.Subject.CancellationByLearnerOrPSOrAO': 'Your {licenseeName} session was canceled', // TODO add translations
    'MursionPortal.Personalized.PathwayPage.Section': 'Message you want learners to see on Pathway Page', // TODO add translations
    'MursionPortal.Personalized.CreateNewPathway.Button.Label': 'Create new for specific pathway', // TODO add translations
    'Projects.ScenarioList.NoPathwaysFound': 'No Pathways have been found.',// TODO add translations
    'MursionPortal.Personalized.Pathway.Search.Placeholder': 'Search or select Pathway(s):',// TODO add translations
    'Mursion.Portal.Status.SimTechnicalError': 'Sim Technical Error',// TODO add translations
    'MursionPortal.SimAttendance.Sim.Tooltip.Error': 'Session was unable to be completed due to technical difficulties on the Sim’s side. (if the session was able to be completed after an error this session should be marked complete and not error).',// TODO add translations
    'Session.Edit.Modal.Notes.BlankNoteValidation': 'Either add a valid note or remove this block', // TODO add translations
    'MursionPortal.Scenario.Error.Msg': 'Please complete required field.', // TODO add translations
    'MursionPortal.Scenario.Skills.Error.Msg': 'The number of skills must be in the range of {minSkills} and {maxSkills}.', // TODO add translations
    'MursionPortal.Capitalized.Incomplete': 'INCOMPLETE', // TODO add translations
    'MursionPortal.Dashboard.Chart.LicenseBreakdown.Title': 'License Breakdown – Contract Item',  // TODO add translations
    'MursionPortal.Dashboard.Chart.LearnerLicenses': 'Learner Licenses',  // TODO add translations
    'MursionPortal.Dashboard.Chart.LearnersRegistered': 'Learners Registered',  // TODO add translations
    'MursionPortal.Dashboard.Chart.LearnersScheduled': 'Learners Scheduled',  // TODO add translations
    'MursionPortal.SessionAttendance.AddAttendees': 'Add attendees',  // TODO add translations
    'MursionPortal.SessionAttendance.SimulationSpecialist': 'Simulation Specialist',  // TODO add translations
    'MursionPortal.Dashboard.SimulationSpecialist.Status': 'Simulation Specialist Status',  // TODO add translations
    'MursionPortal.Dashboard.Technical.Issue.Summary': 'If a technical issue occurred during this session, please indicate the type(s) of issue encountered:',  // TODO add translations
    'MursionPortal.Dashboard.Provide.Details': 'Please provide more details on what happened',  // TODO add translations
    'MursionPortal.SessionAttendance.SwitchToSimRole.Message': 'Please switch role to Sim Specialist to view the page!', // TODO add translations
    'MursionPortal.SessionAttendance.Heading': '<code>Instructions:</code> Review the Session, Simulation Specialist, and Learner Status\' below and update as required. This form may be edited for 24 hours after the end of the session', // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.Label': 'This is automatically determined by the Learner and Simulation Specialist status. No action required.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionDetails.Heading': '(selections below do not impact session status)', // TODO add translations
    'MursionPortal.SessionAttendance.SessionDetails.Instructions': '<code>Instructions:</code> Session details <code>must</code> be provided for any session that has a Learner Status other than ‘Complete’ or ‘No Show’. To complete, select one or more issue type(s) and describe details in the text box. Session detail information does not impact the Learner, Simulation Specialist, or Session status.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionDetails.Text': `Mursion Simulation Specialists only: If you filled out a UTS ticket for this session, please include the link to the Slack post in the text box (click the 3 vertical dots to the right of the post and select ‘Copy link').`, // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.Simspecialist.Label': 'This is automatically determined by the system. If you encountered an error that prevented the session from being completed and the indicated status is ‘Left’, select ‘Technical Error (SIMS)’. Otherwise, no action required.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.Learner.Label': 'This is automatically determined by the system. If the indicated status is inaccurate, select the correct status and explain the reason for the revision in Session Details. Otherwise, no action required.', // TODO add translations
    'MursionPortal.SessionAttendance.SessionStatus.SimStatus.Label': 'Technical Error (SIMs)', // TODO add translations
    'Mursion.Portal.Status.UnableToComplete': 'Unable to Complete', // TODO add translations
    'Mursion.Portal.Status.ElectedToLeave': 'Elected to Leave', // TODO add translations
    'Mursion.Portal.Status.TechnicalErrorLearner': 'Technical Error (Learner)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.Audio': 'Audio', // TODO add translations
    'MursionPortal.SessionAttendance.Error.ConnectionInternet': 'Connection / Internet', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerTechnicalIssues': 'Learner technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.MursionTechnicalIssues': 'Mursion technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.OtherTechnicalIssues': 'Other technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.MomentSoftware': 'Moment Software', // TODO add translations
    'MursionPortal.SessionAttendance.Error.AvatarOrEnvironmentLipSyncMovementEtc': 'Avatar or Environment (lip sync, movement, etc.)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.HardwareControllerLaptopEtc': 'Hardware (controller, laptop, etc.)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerAndSimulationSpecialistBothJoined': 'Learner and Simulation Specialist both joined, no connection established', // TODO add translations
    'MursionPortal.SessionAttendance.Error.OtherPleaseExplainBelow': 'Other (please explain below)', // TODO add translations
    'MursionPortal.SessionAttendance.Error.NonTechnicalIssues': 'Non-technical issues', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerMaterialsOrPreparation': 'Learner materials or preparation ', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerLanguageFluency': 'Learner language fluency', // TODO add translations
    'MursionPortal.SessionAttendance.Error.LearnerElectedToLeave': 'Learner elected to leave', // TODO add translations
    'MursionPortal.Dashboard.Overview.Empty.Body.Message': 'Contract report will load once you make a selection', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdown.ContractItem.Simulation':'Session Breakdown – Simulation Contract Item', // TODO add translations
    'MursionPortal.Dashboard.Chart.Header.SimulationBreakdown.ContractItem.Hour':'Session Breakdown – Hour Contract Item', // TODO add translations
    'MursionPortal.Copy.Tooltip.Text': 'Copy (plain text only, no formatting)', // TODO add translations
    'MursionPortal.RTF.DropDown.Text.Green': 'Green', // TODO add translations
    'MursionPortal.RTF.DropDown.Text.Thick': 'Thick', // TODO add translations
    'MursionPortal.Status.Connected.Label': 'Connected',  // TODO add translations
    'MursionPortal.Status.Immersing.Label': 'Immersing',  // TODO add translations
    'MursionPortal.Dashboard.NonTechnical.Issue.Summary': 'If a non-technical issue occurred during this session, please indicate the type(s) of issue encountered:',  // TODO add translations
    'MursionPortal.AutoSim.Scenario.TooltipLabel': 'AutoSim scenario can’t be scheduled.', // TODO add translations
    'MursionPortal.Scenario.Planing.AutoAssignTeam': 'Auto-Assign Team', // TODO add translations
    'MursionPortal.Scenario.Planing.AutoAssignTeam.Tooltip': 'Automatically assign this scenario to the selected teams when learners access the scenario page via the provided link.', // TODO add translations
    'MursionPortal.Dashboard.Overview.NoAssociatedDataForSelectedContract': 'No associated data for the selected contract(s)', // TODO add translations
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.LevelOne': 'Level 1', // TODO add translations
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.LevelTwo': 'Level 2', // TODO add translations
    'MursionPortal.ScenarioBank.OutcomeLevelSelector.LevelThree': 'Level 3', // TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Title': 'Make sure you’re ready to take the session by testing your connection before joining.',// TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Button.Label': 'Test Connection',// TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Support.Link.Title': 'If any test fails, copy results at the end of the test and share them with our support team by',// TODO add translations
    'MursionPortal.Dashboard.UpcomingSessions.IVS.Support.Link': 'Submitting a Support ticket.',// TODO add translations
    'MursionPortal.Dashboard.OnDemand.Text':'Ready, set, practice on-the-go',
    'MursionPortal.Dashboard.OnDemand.Title':'Try On-Demand Simulations',
    'MursionPortal.Dashboard.OnDemand.SubTitle':'Get a first look at the future of skill-building at work - dynamic GenAI simulations available instantly, no scheduling required. Practice anytime and receive actionable feedback that helps you grow and master new skills.',
    'MursionPortal.Dashboard.OnDemand.SmallTitle':'Early access, real results—get ahead now.',
    'MursionPortal.Dashboard.OnDemand.Button.Label':'Go to Mursion On-Demand',
    'MursionPortal.Dashboard.OnDemand.Button.Hide.Label':'Hide this',
    'MursionPortal.Dashboard.OnDemand.Preview':'PREVIEW',
    'MursionPortal.Dashboard.OnDemand.Text1':'✓ Scalable, focused practice',
    'MursionPortal.Dashboard.OnDemand.Text2':'✓ Flexible access',
    'MursionPortal.Dashboard.OnDemand.Text3':'✓ Targeted practice for mastery',
    'MursionPortal.Dashboard.OnDemand.Text4':'✓ Guided self-reflection',
    'MursionPortal.Dashboard.OnDemand.Mursion':'Mursion',
  },
};
export default polishLangTranslations;
